<template>
  <finalise-onboarding type="packages-sign-up" is-modal v-if="isNewProfileEnabled"/>
  <div v-else class="packages-sign-up-modal" :class="{ 'modal-no-footer': currentStep !== 'add_payment_method' }">
    <modal :show-divider="false" :id="modalId" wrapperSize="flex" size="flex" ref="modal" :title="title"
           :is-closeable="true" @close="closeModal">
      <template v-slot:title>
        <div>
          <GuideProgress style="min-width: 550px" v-model="CURRENT_STEP" :completed-until="CURRENT_STEP" :steps="STEPS"/>
        </div>
      </template>
      <template v-slot:body>
        <div>
          <package-picker @packageSelected="updateChosenPackage"
                          v-if="currentStep === 'choose_plan'"/>
          <h4 class="h4" v-if="currentStep === 'add_payment_method'">Add coupon code or credit card information for account verification. Payment will be effective once you
            complete the registration.</h4>
          <br v-if="currentStep === 'add_payment_method'">
          <div v-show="currentStep === 'add_payment_method'" style="display: flex">
            <div class="promocode-validation__container">
              <h3 class="h3">Add a coupon code</h3>
              <ask-coupon-code v-model="couponCode"
                               @setCouponValidation="setCouponValidation"
                               :coupon-validation="couponValidation"
                               :payment-error="paymentError"
                               :updating-plan="updatingPlan"
                               :selected-package="chosenPackage"/>
            </div>
            <div class="payment-info__container">
              <ask-payment-method @updatePaymentMethod="updatePaymentMethod"
                                  v-show="registrationRequiresPayment"/>
              <div v-show="!registrationRequiresPayment">
                <h3 class="h3">Credit card not required because the
                  {{ useTrial ? 'trial' : 'package' }} is free</h3>
              </div>
              <div v-show="paymentMethodId">
                Your credit card information is verified.
              </div>
            </div>
          </div>
          <user-billing-information v-if="currentStep === 'billing_information'"
                                    :is-in-modal="true"
                                    @updateBillingInfo="updateUserBillingInfo"/>
          <personal-information
            :chosenPackage="chosenPackage"
            :paymentMethodId="paymentMethodId"
            :useTrial="useTrial"
            :billing-info="billingInfo"
            :couponCode="couponCode"
            v-if="currentStep === 'personal_details'"
          />
        </div>
      </template>
      <template v-slot:footer v-if="currentStep === 'add_payment_method'">
        <div style="text-align: center;">
          <div class="person_subscription__plan-cta" v-if="paymentError">
            <div v-if="paymentError && paymentError.route">
            <span>For security reasons, we need you to complete a secondary verification step, <a
              :href="paymentError.route">click here</a> to finalise your payment.</span>
            </div>
            <div v-else-if="paymentError && paymentError.paymentMethodId">
              Please enter the credit card number, expiry date, CVC code and postal code of your credit card
            </div>
            <div class="form-group__error" v-else-if="paymentError && paymentError.message">
              <span>Something went wrong while processing your payment, contact us so we can help you further.</span>
            </div>
            <div class="form-group__error" v-else-if="Object.keys(paymentError).length"
                 style="font-size: 14px">
              Something went wrong while updating your subscription:
              <div v-if="!Array.isArray(paymentError)">
                {{ paymentError }}
              </div>
              <div v-for="error in paymentError" v-else>
                {{ Array.isArray(error) ? error[0] : error }}
              </div>
            </div>
            <br>
          </div>
          <ds-button
            :label="updatingPlan ? $t('confirm_package_updating') : $t('confirm_package_register_plan')"
            v-if="paymentMethodId || !registrationRequiresPayment"
            variant="primary"
            @click="goToNextStep"
            :disabled="currentCouponCodeIsInvalid || updatingPlan || (!couponCode && !paymentMethodId)"/>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
  import MODAL_IDS from '../../constants/modal-ids'

  import Modal from './Modal.vue'
  import PackagePicker from './PackagesSignUpModal/PackagePicker.vue'
  import PersonalInformation from './PackagesSignUpModal/PersonalInformation.vue'
  import AskPaymentMethod from './PackagesSignUpModal/AskPaymentMethod.vue'
  import AskCouponCode from './PackagesSignUpModal/AskCouponCode.vue'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import UserBillingInformation from '../Profile/Subscription/UserBillingInformation.vue'
  import GuideProgress from '../ConceptSearchGuide/GuideProgress.vue'
  import FinaliseOnboarding from '../../pages/Onboarding/FinaliseOnboarding.vue'

  export default {
    name: 'PackagesSignUpModal.vue',
    data () {
      return {
        modalId: MODAL_IDS.PACKAGES_SIGN_UP,
        billingInfo: null,
        currentStep: 'choose_plan',
        defaultSteps: [
          'choose_plan',
          'add_payment_method',
          'billing_information',
          'personal_details',
        ],
        chosenPackage: {},
        useTrial: false,
        paymentMethodId: '',
        couponCode: '',
        couponValidation: '',
        paymentError: '',
        updatingPlan: false,
      }
    },
    computed: {
      isNewProfileEnabled () {
        return this.$store.getters.isNewProfileEnabled
      },
      steps () {
        if (this.couponValidation && this.couponValidation.valid && this.couponValidation.discount === 100) {
          return this.defaultSteps
        }

        if (!this.chosenPackage.id) {
          return this.defaultSteps
        }

        if (this.useTrial || !this.chosenPackage.monthly_price) {
          return ['choose_plan', 'billing_information', 'personal_details']
        }

        return this.defaultSteps
      },
      CURRENT_STEP: {
        get () {
          return this.steps.indexOf(this.currentStep) + 1
        },
        set (value) {
          this.currentStep = this.steps[value - 1]
        },
      },
      STEPS () {
        return this.steps.map((step, index) => {
          return {
            label: this.getTitleFromStep(step), value: index + 1,
          }
        })
      },
      currentCouponCodeIsInvalid () {
        return this.couponValidation && this.couponValidation.valid === false
      },
      registrationRequiresPayment () {
        return !this.couponValidation || !this.couponValidation.valid || this.couponValidation.discount !== 100
      },
      title () {
        if (this.chosenPackage.id) {
          if (this.useTrial) {
            return 'Free trial for ' + this.chosenPackage.title
          } else if (!this.couponValidation || this.currentCouponCodeIsInvalid) {
            return `${this.chosenPackage.title} for €${this.chosenPackage.monthly_price * 12} per year`
          } else {
            return `${this.chosenPackage.title} for €${this.chosenPackage.monthly_price * 12 / 100 * (100 - this.couponValidation.discount)} per year`
          }
        }
        return 'Choose your plan'
      },
      config () {
        return this.$store.state.config
      },
      onboardingLogo () {
        return this.config.onboarding_image_url
      },
    },
    methods: {
      getTitleFromStep (step) {
        switch (step) {
          case 'choose_plan':
            return 'Choose your plan'
          case 'add_payment_method':
            return 'Add payment method'
          case 'billing_information':
            return 'Add billing information'
          case 'personal_details':
            return 'Sign up'
          default:
            return 'Sign up'
        }
      },
      goToNextStep () {
        if (!this.billingInfo) {
          this.currentStep = 'billing_information'
        } else {
          this.currentStep = 'personal_details'
        }
      },
      updateUserBillingInfo (billingInfo) {
        this.billingInfo = billingInfo
        this.currentStep = 'personal_details'
      },
      closeModal () {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, this.modalId)
        // When closing the modal we reset to modal context.
        this.$store.commit('UI/SET_MODAL_CONTEXT', null)
      },
      setCouponValidation (value) {
        this.couponValidation = value
      },
      updateChosenPackage (chosenConfig) {
        this.chosenPackage = chosenConfig.selectedPackage
        this.useTrial = chosenConfig.useTrial

        // Move to the next step
        if (this.chosenPackage.monthly_price === 0 || chosenConfig.useTrial) {
          this.currentStep = 'billing_information'
        } else {
          this.currentStep = 'add_payment_method'
        }
      },
      updatePaymentMethod (paymentMethod) {
        this.paymentMethodId = paymentMethod.id
      },
    },
    mounted () {
      if (this.$store.state.ui.modalContext && this.$store.state.ui.modalContext.chosenPackageConfig) {
        this.updateChosenPackage(this.$store.state.ui.modalContext.chosenPackageConfig)
      }
    },
    components: {
      UserBillingInformation,
      PackagePicker,
      Modal,
      PersonalInformation,
      AskPaymentMethod,
      AskCouponCode,
      GuideProgress,
      FinaliseOnboarding,
    },
  }
</script>

<style scoped lang="scss">
  @import "../../../scss/_variables.scss";

  .promocode-validation__container {
    border-right: 1px solid $color-borders;
    padding-right: 15px;
    margin-right: 15px;
    width: 50%;
  }

  .payment-info__container {
    width: calc(50% - 15px);
  }

  :deep(.modal__header .divider) {
    max-width: 100%;
  }

  .modal-no-footer {
    :deep(.modal__body) {
      margin-bottom: 0;
    }
  }

  :deep(.modal__mask) {
    padding-top: 0
  }

  :deep(.modal__wrapper) {
    max-height: 90%;
  }

  :deep(fieldset) {
    width: 100%;
    display: inline-block;
  }

  .firstStep {
    display: flex;
  }

  .notFirstStep {
    display: flex;
    flex-direction: column-reverse;
  }
</style>
