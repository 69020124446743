<template>
  <tr @click="$emit('click', $event)">
    <td style="width: 50px;" v-if="displayExplorationColumn">
      <relevant-edit-popup
        v-model="actorRelevantInIds"
        :active-portfolio="activePortfolioId"
        placement="right-start"
        @relevant="handleActorRelevant"
        @not-relevant="handleActorNotRelevant"
      />
    </td>
    <td class="list-view__company-name">
      <div class="card__ribbon" v-if="ribbonClass" :style="ribbonStyle"></div>
      <div class="card__ribbon-after">
        <avatar :src="companyLogoUrl" :alt="company.name" variant="border"/>
        <span class="list-view__company-name__label">
          {{ company.name }}
        </span>
      </div>
    </td>
    <template v-for="column in columns">
      <td v-if="column.name == 'claimed'">
        <badge v-if="company.claimed" name="Claimed" variant="primary" size="extra-small"/>
      </td>
      <td v-else-if="column.name == 'custom_score' && company.custom_score > 0">
        <router-link :to="'/actors/' + company.id + '#info'">{{ company.custom_score }}</router-link>
      </td>
      <td v-else v-text="getContent(column.name)" :class="{'text-right' : column.name == 'completeness'}"></td>
    </template>
  </tr>
</template>

<script lang="ts">
  import numeral from 'numeral'

  import Avatar from '../Avatar/Avatar.vue'
  import Badge from '../Badge/Badge.vue'

  import CompanyMixin from '../../util/CompanyMixin.js'
  import UiMixin from '../../util/UiMixin.js'
  import { toAmount, toSymbol } from '../../util/currency.js'
  import { toDateStringDashed } from '../../util/date.js'
  import { actorTaxonomyProperties } from '../../constants/config.js'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import RelevantEditPopup from '../RelevantEditPopup/RelevantEditPopup.vue'
  import { updateActorRelevancy } from '../../api/exploration.js'
  import { isTaxonomyHierarchical, isTaxonomyValueAParentValue, taxonomyMapping } from '../../store/modules/taxonomies.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      company: null,
      withExplorationColumn: {
        type: Boolean,
        default: true,
      },
      columns: {
        type: Object,
        required: true,
      }
    },
    emits: ['click'],
    data () {
      return {
        actorRelevantInIds: [],
      }
    },
    computed: {
      filterableRelationships () {
        return this.$store.getters.filterableRelationships
      },
      activePortfolioId () {
        return this.$store.getters.activePortfolio.id
      },
      displayExplorationColumn () {
        return this.$store.getters.hasAccessToRelevancyUi && this.withExplorationColumn
      },
      /*columns () {
        return this.$parent.columns || {}
      },*/
      currentEmployeesSizeCode () {
        if (!this.company || !this.company.current_employees) {
          return ''
        }

        return this.company.current_employees.size_code
      },
      lastFundingRound () {
        return this.fundingRounds[0] || {}
      },
      fundingAmount () {
        // return `${toSymbol('EUR')} ${toAmount(this.company.funding_round.capital_raised)}`
        if (this.company.funding_round.capital_raised) {
          return numeral(this.company.funding_round.capital_raised).format('$0.00a')
        }

        return numeral(0).format('$0.00a')
      },
      investmentsCounter () {
        return this.company.funding_rounds.length
      },
      fundingTotal () {
        const c = this.company
        return (typeof c.total_funding === 'number' || typeof c.total_funding === 'string') && c.total_funding > 0 ? (c.total_funding && numeral(c.total_funding).format('$0.00a')) || 'Undisclosed amount' : ''
      },
      fundingDate () {
        return this.company.funding_round.date
      },
      isInFundingDashboard () {
        return this.$route.path === '/dashboards/funding-list'
      },
    },
    methods: {
      /* markActorRelevant (actor, isRelevant) {
        //const originalRelevant = actor.relevant
        this.isCompanyRelevant = isRelevant

        updateActorRelevancy(actor.id, isRelevant)
          .then(response => {
            var listFilters = inert(this.$store.getters.listFilterObject)
            this.$store.dispatch(ACTOR_ACTION_TYPES.FETCH_ACTORS_LIST, listFilters)
          })
          .catch(err => {
            //actor.relevant = originalRelevant
            console.log(err)
          })
      }, */
      getHierarchicalContent (columnName) {
        // Make sure to only return content if the column name and values are the same "level" (parent or child)
        // NOTE: we assume that this is a check on "generic" hierarchical taxonomy values:
        // sub_{taxonomy_name} where the key for the children is "children" in the taxonomy values
        const isColumnParent = !columnName.startsWith('sub_')

        var taxonomyName = columnName.replace('sub_', '')

        var content = []
        var taxonomyIds = this.company[taxonomyName]

        if (!Array.isArray(taxonomyIds)) {
          taxonomyIds = [taxonomyIds]
        }

        // Transform the taxonomy to the one that the state maintains and knows
        taxonomyName = taxonomyMapping()[taxonomyName] || taxonomyName

        taxonomyIds.forEach(taxonomyId => {
          if (isColumnParent !== isTaxonomyValueAParentValue(this.$store.state.taxonomies[taxonomyName], taxonomyId)) {
            return
          }

          var label = this.getLabelForTaxonomyValue(taxonomyName, taxonomyId, 'en')

          if (label) {
            content.push(label)
          }
        })

        return this.spaced(content)
      },
      getContent (columnName) {
        if (columnName.indexOf('report_field_') >= 0) {
          return this.company[columnName]
        }

        if (actorTaxonomyProperties.includes(columnName)) {
          // If we're dealing with a generic 2-level hierarchy, pick out the values (parent / child) correctly
          if (isTaxonomyHierarchical(columnName)) {
            return this.getHierarchicalContent(columnName)
          }

          var content = []
          var taxonomyIds = this.company[columnName]

          if (!Array.isArray(taxonomyIds)) {
            taxonomyIds = [taxonomyIds]
          }

          taxonomyIds.forEach(taxonomyId => {
            var label = this.getLabelForTaxonomyValue(columnName, taxonomyId, 'en')

            if (label) {
              content.push(label)
            }
          })

          return this.spaced(content)
        }

        switch (columnName) {
          case 'total_funding':
            return this.fundingTotal
            break
          case 'total_investment':
            return this.investmentsCounter
            break
          case 'current_employees':
            return this.currentEmployeesSizeCode
            break
          case 'funding_date':
            return this.fundingDate
            break
          case 'funding_type':
            return this.company.funding_round.type
            break
          case 'funding_amount':
            return this.fundingAmount
            break
          case 'founding_date':
            return this.company.founding_date || ''
            break
          case 'created_at':
            if (this.company.created_at) {
              return toDateStringDashed(this.company.created_at)
            }

            return ''
            break
          case 'updated_at':
            if (this.company.updated_at) {
              return toDateStringDashed(this.company.updated_at)
            }

            return ''
            break
          case 'patent_count':
            return this.company.patent_count || ''
            break
          case 'relationship_count':
            return this.company.relationship_count || ''
            break
          case 'product_relationship_count':
            return this.company.product_relationship_count || ''
            break
          case 'custom_score':
            return this.company.custom_score || '0'
            break
          case 'vacancy_count':
            return this.company.vacancy_count || '0'
            break
          case 'zip':
            return this.company.address && this.company.address.zip
            break
          case 'city':
            return this.company.address && this.company.address.city
            break
          case 'country':
            return this.company.address && (this.company.address.country || this.company.address.country_code)
            break
          case 'lastFundingType':
            return this.lastFundingRound.type || ''
            break
          case 'lastFundingDate':
            return (this.lastFundingRound.started_at || '').slice(0, 7)
            break
          case 'maturity':
            return this.company.maturity
            break
          case 'funding_stage':
            return this.company.funding_stage
            break
          case 'email':
            return this.company.email
            break
          case 'phone':
            return this.company.phone
            break
          case 'title':
            return this.company.employment && this.company.employment.title
            break
          case 'department':
            return this.company.employment && this.company.employment.role
            break
          case 'seniority':
            return this.company.employment && this.company.employment.seniority
            break
          case 'market':
            return this.spaced(this.company.market)
            break
          case 'sustainability_goal':
            return this.spaced(this.company.sustainability_goal)
            break
          case 'possible_duplicate':
            return this.boolean(this.company.possible_duplicate)
            break
          case 'is_zombie':
            return this.boolean(this.company.is_zombie)
            break
          case 'completeness':
            return this.company.completeness ? Math.round(100 * this.company.completeness) + '%' : ''
            break
          case 'claimed':
            return this.company.claimed || false
            break
          case 'industry_codes':
            const codes = []
            if (this.company && this.company.industry_codes) {
              this.company.industry_codes.forEach((nace) => {
                if (nace.code != null && nace.main === true) {
                  if (!codes.includes(nace.code)) {
                    codes.push(nace.code)
                  }
                }
              })

              return this.spaced(codes)
            }
            break
          case 'tags':
            const tags = []
            if (this.company && this.company.tags) {
              this.company.tags.forEach((tag) => {
                if (tag.label) {
                  if (!tags.includes(tag.label)) {
                    tags.push(tag.label)
                  }
                }
              })

              return this.spaced(tags)
            }
            break
          case 'private_tags':
            const privateTags = []
            if (this.company && this.company.private_tags) {
              this.company.private_tags.forEach((tag) => {
                if (tag.label) {
                  if (!privateTags.includes(tag.label)) {
                    privateTags.push(tag.label)
                  }
                }
              })

              return this.spaced(privateTags)
            }
            break
        }

        if (this.filterableRelationships.includes(columnName)) {
          if (!this.company[columnName] || this.company[columnName].length == 0) {
            return
          }

          return this.company[columnName].join(', ')
        }
      },
      getFundingRounds (company) {
        return company.funding_rounds.length ? company.funding_rounds.length : '0'
      },
      spaced (list) {
        return list && list.length ? list.join(', ') : ''
      },
      boolean (value) {
        return value ? 'Yes' : ''
      },
      setActorRelevantInIds (actor) {
        if (actor && actor.relevant_in) {
          this.actorRelevantInIds = actor.relevant_in.map(x => x.id)
        } else {
          this.actorRelevantInIds = []
        }
      },
      handleActorRelevant (conceptSearchId) {
        updateActorRelevancy(this.company.id, true, conceptSearchId)
      },
      handleActorNotRelevant (conceptSearchId) {
        updateActorRelevancy(this.company.id, false, conceptSearchId)
      },
      toAmount,
      toSymbol,
    },
    mounted () {
      this.setActorRelevantInIds(this.company)
    },
    watch: {
      company (v) {
        this.setActorRelevantInIds(v)
      },
    },
    mixins: [CompanyMixin, UiMixin, TranslationsMixin],
    components: {
      RelevantEditPopup,
      Avatar,
      Badge,
    },
  })
</script>
