<template>
  <div class="scrollable announcement-detail-new">
    <announcement-hero
        :title="$t('announcements_title')"
        :show-search="false" :is-event="checkOfTypeIsEvent"/>
    <div class="container">
      <div class="row">
        <template
            v-if="announcement && announcement.id && (announcement.id === $route.params.id || announcement.slug === $route.params.id)">
          <div class="col-xs-12 col-md-8">
            <announcement-card
                :available-actions="getAvailableActionsForAnnouncement(announcement)"
                :announcement="announcement"
                :key="'announcement-card-' + announcement.id"
                is-detail
                :comments="comments"
                @add-comment="addComment"
                @delete-comment="fetchComments"
                :errors="errorsComment"
                @clickActionButton="clickActionButton($event, announcement)"
            />
          </div>
          <div :class="{'col-xs-12 col-md-4': true, 'd-md-none': !isDetailPage}">
            <div class="announcements-info-container" v-if="checkOfTypeIsEvent && announcement.allow_registration">
              <div class="announcements-info-title">
                <icon class="announcements-guests-title-icon" name="ink-pen" size="18"/>
                &nbsp;&nbsp;{{ $t('event_attendees') }}
              </div>
              <div class="announcements-info-content">
                <div class="announcements-info-text avatars-text borderless" style="padding: 10px;">
                  <p class="announcements-info-text-content" v-if="registrationCount === 0">
                    {{ $t('event_registration_registered_no_people') }}
                  </p>
                  <p class="announcements-info-text-content" v-if="registrationCount === 1">
                    {{ $t('event_registration_registered_person', { attribute: registrationCount }) }}
                  </p>
                  <p class="announcements-info-text-content" v-if="registrationCount > 1">
                    {{ $t('event_registration_registered_multiple_persons', { attribute: registrationCount }) }}
                  </p>

                  <div class="announcements-info-text-avatars">
                    <div class="announcements-info-text-avatars-content-container">
                      <div class="announcements-info-text-avatars-content" v-for="user in lastRegisteredUsers">
                        <avatar :src="user.photo_url"/>
                      </div>
                      <p class="announcements-info-text-content" v-if="registrationCount > 5"> +{{
                          registrationCount - 5
                        }}</p>
                    </div>
                    <a v-if="isOwner && registrationCount > 0"
                       :href="'/api/event-registration/'+announcement.id+'/export'"
                       class="export-event-registration-users">Export</a>
                  </div>
                  <ds-button
                      class="sign-up__event-button"
                      @click="registerForEvent"
                      :label="hasRegisteredForEvent ? $t('event_registration_already_registered') : $t('event_registration_register')"
                      size="small"
                      variant="rounded"
                      :disabled="isRegistering || hasRegisteredForEvent"
                  />
                </div>
              </div>
            </div>
            <div
                class="announcements-info-container"
                v-for="(announcementInfoType, index) in announcementInfoTypes"
                :key="announcementInfoType.type + index">
              <div class="announcements-info-title">
                <icon
                    :name="announcementInfoType.icon"
                    v-if="announcementInfoType.icon"
                    :style="{fill: ecosystemColor}"
                />
                <img
                    :src="announcementInfoType.image" :alt="announcementInfoType.title"
                    v-else-if="announcementInfoType.image"
                    class="announcement-header-img"
                    style="margin: 0">
                &nbsp;&nbsp;{{
                  announcementInfoType.title
                }}
              </div>
              <div class="announcements-info-content">
                <div class="announcements-info-text borderless">
                  <ul
                      :style="'list-style-type: none;'"
                  >
                    <li
                        v-for="(contentItem, index2) in announcementInfoType.content"
                        :key="'content' + index + index2"
                    >
                      <a
                          v-if="contentItem.url" class="announcements-info-text"
                          :href="contentItem.url">{{ contentItem.label }}</a>
                      <template v-else>
                        <span class="announcements-info-text">{{ contentItem.label }}</span>
                      </template>
                    </li>
                  </ul>
                </div>

                <div
                    class="announcements-info-button-container"
                    v-if="announcementInfoType.buttonText">
                  <ds-button
                      size="extra-small" v-if="announcementInfoType.buttonUrl"
                      variant="rounded"
                      :label="announcementInfoType.buttonText"
                      :href="announcementInfoType.buttonUrl"/>
                  <ds-button
                      size="extra-small"
                      v-else-if="announcementInfoType.buttonAction"
                      variant="rounded"
                      :label="announcementInfoType.buttonText"
                      @click="announcementInfoType.buttonAction"/>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-else-if="announcementError && announcementError !== '' && !isLoggedIn">
          <h3 class="h3">{{ $t('actor_error_login_required') }}</h3>
          <ds-button style="margin-top: 15px;" variant="secondary" label="Login" @click.prevent="showSignIn"/>
          <ds-button style="margin-top: 15px;" variant="secondary" label="Register" @click="$router.push('/register')"/>
        </div>
        <div v-else-if="announcementError && announcementError !== '' && isLoggedIn">
          <h3 class="h3">{{ announcementError }}</h3>
        </div>
        <loading v-else style="color: var(--primary-community)"/>
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import { createComment, getComments, readNotifications, registerForEvent } from '../../api/notifications.js'

import TranslationsMixin from '../../util/TranslationsMixin.js'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
import { ACTION_TYPES as NOTIFICATION_ACTION_TYPES } from '../../store/modules/notifications.js'
import AnnouncementCard from '../../components/Simplified/AnnouncementCard.vue'
import AnnouncementMixin from '../../util/AnnouncementMixin.js'
import AnnouncementHero from '../../components/Simplified/AnnouncementHero.vue'
import { Conversations } from '../../api/conversations.js'
import Loading from '../../components/Dashboard/ConceptMap/Loading.vue'
import { ACTION_TYPES as CONVERSATION_ACTION_TYPES } from '../../store/modules/conversations.js'
import { trackHeapEvent, trackMatomoEvent } from '../../util/analytics.js'
import Avatar from '../../components/Avatar/Avatar.vue'
import MODAL_IDS from '../../constants/modal-ids.js'
import {
  MATOMO_EVENT_ACTIONS,
  MATOMO_EVENT_CATEGORIES,
  MATOMO_EVENT_NAMES
} from '../../constants/analytics-constants.js'
import { defineComponent } from 'vue'

declare interface BaseComponentData {
  errorsComment: object,
  comments: string[],
  shouldScrollToComments: boolean,
  isRegistering: boolean,
  lastRegisteredUsers: string[]
}

export default defineComponent({
  components: {
    Avatar,
    AnnouncementHero,
    AnnouncementCard,
    Loading,
  },
  data: (): BaseComponentData => {
    return {
      errorsComment: {},
      comments: [],
      shouldScrollToComments: false,
      isRegistering: false,
      lastRegisteredUsers: [],
    }
  },
  computed: {
    ecosystemColor() {
      return this.$store.state.config.primaryColor
    },
    checkOfTypeIsEvent() {
      if (['events', 'events-detail', 'events-simplified'].includes(this.$route.name) || this.announcement.is_event) {
        return true
      } else {
        return false
      }
    },
    announcementsBySameUser() {
      return (this.announcement && this.announcement.other_announcements) || []
    },
    announcementInfoTypes() {
      const announcementInfoContent = []

      if (this.announcement.actor_id) {
        if (this.announcement.claimedActor) {
          announcementInfoContent.push({
            label: this.$t('announcement_works_at', { actor: this.announcement.claimedActor }),
            url: `/actors/${this.announcement.actor_id}`,
          })
        } else if (this.announcement.actor_name) {
          announcementInfoContent.push({
            label: this.$t('announcement_works_at', { actor: this.announcement.actor_name }),
            url: `/actors/${this.announcement.actor_id}`,
          })
        }
      }

      let startedDateString = 'no data yet..'

      if (this.announcement.user && this.announcement.user.started_date) {
        startedDateString = new Date(this.announcement.user.started_date).toLocaleDateString()
      }

      announcementInfoContent.push({
        label: this.$t('announcement_member_since', {
          ecosystem: this.$store.getters.ecosystemDisplayName,
          startedDate: startedDateString.replaceAll('/', '-'),
          interpolation: { escapeValue: false },
        }),
      })

      const result = [
        {
          type: 'info',
          title: this.announcement.user ? this.announcement.user.name : this.$store.getters.ecosystemDisplayName,
          buttonText: this.canStartConversationWithUser ? this.$t('conversation_start') : '',
          image: (this.announcement.user && this.announcement.user.photo_url) ? this.announcement.user.photo_url : 'http://www.gravatar.com/avatar/?d=mp',
          content: announcementInfoContent,
          buttonUrl: (this.announcement.user && !this.announcement.user.is_deleted && this.announcement.user.conversation_id) ? `/conversations/${this.announcement.user.conversation_id}` : '',
          buttonAction: (this.announcement.user && !this.announcement.user.is_deleted && this.announcement.user.conversation_id) ? '' : this.startConversation,
        },
      ]

      if (this.announcementsBySameUser.length > 0) {
        const moreContentByUser = this.announcementsBySameUser.slice(0, 5).map(announcement => {
          return {
            label: announcement.title,
            url: `/announcements/${announcement.slug || announcement.id}`,
          }
        })

        result.push({
          type: 'other',
          title: this.$t('announcement_other', { user: this.announcement.user.name }),
          buttonText: '',
          icon: 'bullhorn',
          content: moreContentByUser,
        })
      }
      return result
    },
    canStartConversationWithUser() {
      return this.announcement.user && !this.announcement.user.is_deleted && this.$store.getters.userId != this.announcement.created_by && (this.$store.getters.canActorsBeContacted || this.isOwner) && this.$store.getters.isNewProfileEnabled
    },
    announcement() {
      return this.$store.state.notifications.detail.data
    },
    announcementError() {
      return this.$store.state.notifications.detail.error
    },
    isOwner() {
      return this.$store.getters.isOwner
    },
    isMember() {
      return this.$store.getters.isMember
    },
    config() {
      return this.$store.state.config
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    canUserEditAnnouncement() {
      return this.isOwner || (this.$store.getters.userId && this.$store.getters.userId === this.announcement.created_by)
    },
    hasAccessToAnnouncementsAndEventsForCommunities() {
      return this.$store.getters.hasAccessToAnnouncementsAndEventsForCommunities
    },
    hasRegisteredForEvent() {
      return this.announcement && this.announcement.event_registrations && this.announcement.event_registrations.filter((e) => e.id === this.$store.getters.userId).length > 0
    },
    registrationCount() {
      if (this.announcement && this.announcement.event_registrations) {
        return this.announcement.event_registrations.length
      }
      return 0
    },
  },
  methods: {
    transformLastRegisteredUsers() {
      if (!this.announcement.event_registrations) {
        this.lastRegisteredUsers = []

        return
      }

      this.lastRegisteredUsers = this.announcement.event_registrations.slice(-5)
    },
    showSignIn() {
      if (this.$store.getters.isPrivate) {
        this.$router.push('/login')
      } else {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
      }
    },
    registerForEvent() {
      if (!this.$store.getters.isLoggedIn) {
        window.redirect_uri = '/events/' + (this.announcement.slug || this.announcement.id)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
        return
      }

      this.isRegistering = true
      registerForEvent(this.announcement.id, this.announcement.title)
          .then(response => {
            this.fetchAnnouncement()
            trackMatomoEvent(MATOMO_EVENT_CATEGORIES.INTERACTIONS, MATOMO_EVENT_ACTIONS.BUTTON_CLICK, MATOMO_EVENT_NAMES.SIGN_UP_FOR_EVENT)
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            this.isRegistering = false
          })
    },
    startConversation() {
      trackHeapEvent('announcementDetail.clickConversation')
      Conversations.post({ announcement_id: this.announcement.id }).then((conversation) => {
        this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
        this.$router.push('/profile/conversations/' + conversation.id)
      })
    },
    refreshAnnouncement() {
      this.comments = []
      this.fetchComments()
      this.fetchAnnouncement()
    },
    fetchAnnouncement() {
      if (this.$route.params.id) {
        console.info('Route parameter valid: ' + this.$route.params.id)
        this.$store.dispatch(NOTIFICATION_ACTION_TYPES.FETCH_NOTIFICATION_DETAIL, this.$route.params.id).then(() => {
          this.markAnnouncementAsRead()
          this.fetchComments()
          this.isLikedByUser = this.announcement && this.announcement.user_like
          this.transformLastRegisteredUsers()
        })
      } else {
        console.error('Route parameter invalid: ' + this.$route.params.id)
        this.announcementError = 'An error occurred while fetching the announcement'
      }
    },
    fetchComments() {
      if (this.announcement.id) {
        getComments(this.announcement.id)
            .then(data => {
              this.comments = data.comments.reverse()
              this.commentsCount = data.count
            })
            .catch(err => {
              // log the error to sentry
            })
      }
    },
    addComment(newComment) {
      this.errorsComment = {}

      if (this.announcement.id) {
        createComment(this.announcement.id, newComment)
            .then(() => {
              this.newComment = ''
              this.fetchComments()

              trackMatomoEvent(MATOMO_EVENT_CATEGORIES.ANNOUNCEMENT_COMMENT, MATOMO_EVENT_ACTIONS.COMMENT, this.announcement.id)
            })
            .catch(errors => {
              this.errorsComment = errors
            })
      }
    },
    markAnnouncementAsRead() {
      if (!this.announcement.read) {
        readNotifications([this.announcement.id])
      }
    },
  },
  mounted: function () {
    if (this.$route.params.communityId && !this.hasAccessToAnnouncementsAndEventsForCommunities) {
      this.$router.push(this.$route.path.replace('/communities/' + this.$route.params.communityId, ''))
    }

    this.fetchAnnouncement()

    this.$bus.on('announcementUpdated', () => {
      this.fetchAnnouncement()
    })

    this.$bus.on('announcementDeleted', () => {
      // When coming from the community page, this should be redirected to the community page
      this.$router.push('/announcements')
    })

    this.$bus.on('announcementDeleteConfirmation', (modalContext) => {
      this.deleteAnnouncement(modalContext.announcement)
    })

    this.transformLastRegisteredUsers()
  },
  beforeUnmount() {
    this.$bus.off('announcementUpdated')
    this.$bus.off('announcementDeleted')

    this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
  },
  mixins: [TranslationsMixin, AnnouncementMixin],
  watch: {
    '$route.params.id': {
      handler() {
        if (this.$route.params.id !== this.announcement.id) {
          this.fetchAnnouncement()
        }
      },
      immediate: false,
    },
  },
})
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.announcement-detail-new {
  .container {
    margin: 40px auto;
  }
}

.announcements-guests-title-icon {
  fill: $color-primary;
  margin-right: 7px;
}

.sign-up__event-button {
  width: 100%;
  height: 36px;
  margin-bottom: 0;
}

.export-event-registration-users {
  text-decoration: underline;
  padding: 5px;

  &:hover {
    color: $color-secondary;
    cursor: pointer;
  }
}

.announcements-info-text-content {
  font-size: 14px;
  padding: 5px;
}

.announcements-info-text {
  p {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.announcements-info-text-avatars {
  justify-content: space-between;
  display: flex;
  position: relative;
  margin-left: 15px;
  margin-bottom: 10px;

  .announcements-info-text-avatars-content-container {
    display: flex;

    .avatar {
      margin-left: -15px;
      width: 30px;
      height: 30px;
      border: 1px solid $color-primary;
    }

    :deep(.avatar__img) {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
