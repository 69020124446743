<template>
  <div class="authentication-modal authentication-modal--large">
    <modal :id="modalId" ref="modal" :title="$t('forgot_password_change_password_title')" :enable-mask="false" :is-closeable="isCloseable">
      <template v-slot:body>
        <div>
          <form class="form" @submit.prevent="submit" v-if="!errors.token" style="max-width: 310px;">
            <fieldset>
              <div class="form-group" :class="{ error: emailErrors.length }">
                <label class="label" for="change-password-email">{{$t('forgot_password_email')}}</label>
                <input class="input" placeholder="mail@address.com" id="change-password-email" v-model="email" @input="emailErrors=[]" readonly disabled>
              </div>
              <p v-for="msg in emailErrors" v-text="msg" class="form-msg error"></p>

              <div class="form-group">
                <label class="label" for="change-password-password">{{ $t('forgot_password_password') }}</label>
                <input class="input" type="password" placeholder="Password" id="change-password-password" v-model="password" @input="passwordErrors=[]">
              </div>
              <div class="pw-strength" :class="'pw-strength' + passwordScore">
                <div class="pw-strength-line"></div>
              </div>
              <div class="form-group">
                <input class="input" type="password" :placeholder="$t('forgot_password_confirm_password')" v-model="passwordConfirmation">
              </div>
              <p v-for="msg in passwordErrors" v-text="msg" class="form-msg error"></p>
              <p v-if="message" v-text="message"></p>
            </fieldset>
            <fieldset class="action-buttons">
              <ds-button type="submit" variant="secondary" icon="chevron-right" :label="$t('forgot_password_change_password')" :disabled="! canSubmit"></ds-button>
            </fieldset>
          </form>
          <div v-else>
            <p>
              {{ errors.token[0] }}
            </p>
            <p>
              <ds-button variant="secondary" :label="$t('forgot_password_request_new_link')" @click="$router.push('/forgot-password?email=' + email)"/>
            </p>
          </div>

          <br>

          <ul class="links">
            <li><a href="#" @click.prevent="openLoginModal">Log In</a></li>
            <li><a href="#" @click.prevent="openRegisterModal" v-if="!$store.getters.isPrivate">{{$t('login_signup')}}</a></li>
          </ul>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
  import { loadChunk } from '../../util/chunk-loader'
  import { changePasswordRequest } from '../../api/user'

  import Modal from './Modal.vue'
  import AuthenticationModal from './AuthenticationModal.vue'
  import MODAL_IDS from '../../constants/modal-ids'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import { MUTATION_TYPES as USER_MUTATION_TYPES } from '../../store/modules/user-helpers.js'
  import { SiteTemplate } from '../../store/modules/config'

  export default {
    extends: AuthenticationModal,
    name: 'change-password-modal',
    props: {
      isCloseable: {
        type: Boolean,
        default: true
      },
    },
    data () {
      return {
        errors: window.errors || {},
        email: this.$route.query.email || window.email || '',
        emailErrors: [],
        message: null,
        modalId: MODAL_IDS.SIGN_UP,
        password: '',
        passwordConfirmation: '',
        passwordErrors: [],
        passwordScore: 0,
      }
    },
    computed: {
      canSubmit () {
        return this.password && this.email && this.passwordConfirmation && this.passwordScore >= 2
      },
    },
    methods: {
      submit () {
        this.emailErrors = []
        this.passwordErrors = []

        const data = {
          token: this.$route.params.token,
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
        }

        changePasswordRequest(data)
          .then(data => {
            if (data && data.id) {
              // We reload the window because of a conflict with the site template:
              // The user object is loaded in and then send to /explore, however in the router/index.js
              // We check if that user is allowed to do so, however at that point, the state does not yet know the user role
              // for some reason... resulting in the user accessing a view he shouldn't be viewing
              this.$store.commit(USER_MUTATION_TYPES.STORE_PROFILE, data)

              window.location.href = '/'
              window.location.reload()

              return
            } else {
              console.warn('ChangePasswordModal expected a user object')
              window.redirect_uri = window.redirect_uri || '/?user'
            }

            this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.LOGIN)

            if (this.$store.getters.activeSiteTemplate !== SiteTemplate.TRADITIONAL) {
              window.location.href = '/'
              window.reload()

              return
            }

            // Redirect
            if ((window.redirect_uri || '').indexOf('//') > -1) {
              window.location.href = window.redirect_uri
            } else {
              this.$router.push(window.redirect_uri || '/actors')
            }
          })
          .catch(data => {
            this.message = data.message
            this.emailErrors = data.email || []
            this.passwordErrors = data.password || []
          })
      },
      openLoginModal () {
        if (this.$route.name === 'change-password') {
          this.$router.push('/login')
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CHANGE_PASSWORD)
        }
      },
      openRegisterModal () {
        if (this.$route.name === 'change-password') {
          this.$router.push('/register')
        } else {
          //this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.SIGN_UP)
          if (this.$store.state.config.allowAdd) {
            if (this.$store.state.config.onboardingEnabled) {
              this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ONBOARDING)
            } else {
              this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ACTOR_SIGN_UP)
            }
          } else {
            this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.SIGN_UP)
          }
        }
      }
    },
    watch: {
      password (password) {
        return loadChunk('zxcvbn', () => {
          const score = window.zxcvbn(password).score

          if (password.length < 6 && score > 2) {
            this.passwordScore = 2
          } else {
            this.passwordScore = score
          }
        })
      }
    },
    components: {
      Modal
    }
  }
</script>
