<template>
  <tr class="row-curation" @click="preview">
    <td>{{ announcement.title }}</td>
    <td>{{ announcement.user_name }}</td>
    <td class="text-right" :title="date(announcement.created_at)">{{ date(announcement.created_at) }}</td>
    <td>
      <ds-button class="row-curation__verify" @click.stop="preview" label="Preview" />
    </td>
    <td>
      <ds-button class="row-curation__verify" icon="check" @click.stop="acceptAnnouncement" label="Approve" />
    </td>
    <td>
      <ds-button class="row-curation__verify" icon="trash" @click.stop="denyPublicAnnouncement" label="Remove" />
    </td>
    <td>
      <ds-button class="row-curation__verify" icon="edit" @click.stop="editAndAcceptAnnouncement" label="Edit & Approve" />
    </td>
  </tr>
</template>

<script>
  import { acceptPublicAnnouncement, denyPublicAnnouncement } from '../../api/notifications'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: ['announcement'],
    data () {
      return {
        message: ''
      }
    },
    methods: {
      preview () {
        this.$router.push('/announcements/' + (this.announcement.slug || this.announcement.id))
      },
      editAndAcceptAnnouncement () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { 'component': 'edit-announcement-panel', metaData: this.announcement })
      },
      acceptAnnouncement () {
        this.message = '...'

        acceptPublicAnnouncement(this.announcement.id)
          .then(() => {
            this.$bus.emit('acceptedAnnouncement')
          })
          .catch(errors => {
            this.message = errors && errors.message || `Could not approve the ${this.announcementLabel}.`
          })
      },
      denyPublicAnnouncement () {
        this.message = '...'

        denyPublicAnnouncement(this.announcement.id)
          .then(() => {
            this.$bus.emit('deniedAnnouncement')
          })
          .catch(errors => {
            this.message = errors && errors.message || 'Could not verify the claim.'
          })
      },
      date (str) {
        return str ? str.slice(0, 19).replace('T', ' ') : ''
      }
    },
    mixins: [TranslationsMixin],
    watch: {
      announcement () {
        // Clear the message when a new actor is assigned to this row
        this.message = ''
      }
    }
  })
</script>

<style>
  .row-curation td {
    line-height: 26px !important;
  }

  .row-curation .button {
    margin: -6px -10px !important;
    display: block;
    width: calc(100% + 20px);
    font-size: 12px;
  }

  .row-curation .row-curation__onhold {
    background: none;
    color: #999;
  }

  .row-curation__ok {
    width: 70px !important;
    margin-right: -10px !important;
    float: right;
  }

  .row-curation__remove {
    width: 36px !important;
    margin-right: -10px !important;
  }

  .row-curation--deleted {
    color: #f99;
  }
</style>
