<template>
  <component :is="panel" v-if="panel" ref="panel"/>
</template>

<script lang="ts">
  import Analytics from '../components/Dashboard/Analytics.vue'
  import Charts from '../components/Dashboard/Charts.vue'
  import Compare from '../components/Dashboard/Compare.vue'
  import Connectedness from '../components/Dashboard/Connectedness.vue'
  import Country from '../components/Dashboard/Country.vue'
  import Home from '../components/Dashboard/Home.vue'
  import HomeSimplified from '../components/Dashboard/HomeSimplified.vue'
  import BasicHomeSimplified from '../components/Dashboard/BasicHomeSimplified.vue'
  import StaticHomeSimplified from '../components/Dashboard/StaticHomeSimplified.vue'
  import StaticHomeKlik from '../components/Dashboard/StaticHomeKlik.vue'
  import StaticHomeInnovaud from '../components/Dashboard/StaticHomeInnovaud.vue'
  import StaticHomeFoodleap from '../components/Dashboard/StaticHomeFoodleap.vue'
  import StaticHomeCommunityEcosystem from '../components/Dashboard/StaticHomeCommunityEcosystem.vue'
  import HomeFoodleap from '../components/Dashboard/HomeFoodleap.vue'
  import HomeSpacewerx from '../components/Dashboard/HomeSpacewerx.vue'

  import News from '../components/Dashboard/News.vue'
  import DigitalFootprint from '../components/Dashboard/DigitalFootprint.vue'
  import BusinessSize from '../components/Dashboard/BusinessSize.vue'
  import Buzz from '../components/Dashboard/Buzz.vue'
  import MediaBuzz from '../components/Dashboard/MediaBuzz.vue'
  import Sankey from '../components/Dashboard/Sankey.vue'
  import ScoresList from '../components/Dashboard/ScoresList.vue'
  import FundingList from '../components/Dashboard/FundingRoundList.vue'
  import DynamicDashboard from '../components/Dashboard/DynamicDashboard.vue'
  import KnowledgeBase from '../components/Dashboard/KnowledgeBase.vue'
  import ConceptMap from '../components/Dashboard/ConceptMap.vue'
  import ExploreContent from '../components/Dashboard/ExploreContent.vue'
  import EmailReports from '../components/Dashboard/EmailReports.vue'
  import NewsOverview from '../components/Dashboard/NewsOverview.vue'

  import {MUTATION_TYPES as UI_MUTATION_TYPES} from '../store/modules/ui.js'
  import {SiteTemplate} from '../store/modules/config.js'
  import InstantMonitoring from '../components/Dashboard/InstantMonitoring/InstantMonitoring.vue'
  import HomeCommunities from '../components/Dashboard/HomeCommunities.vue'
  import {SECTIONS} from '../constants/homepage.js'
  import StaticHomeFoodleapAbout from '../components/Dashboard/StaticHomeFoodleapAbout.vue'
  import StaticHomeFoodleapContact from '../components/Dashboard/StaticHomeFoodleapContact.vue'
  import StaticHomeOpenCreatives from '../components/Dashboard/StaticHomeOpenCreatives.vue'
  import StaticHomeGreentex from '../components/Dashboard/StaticHomeGreentex.vue'
  import {defineComponent} from 'vue'
  import HomeAgritech from "@/components/Dashboard/HomeAgritech.vue";

  export default defineComponent({
    name: 'dashboards',
    computed: {
      isOwner() {
        return this.$store.getters.isOwner
      },
      isMember() {
        return this.$store.getters.isMember
      },
      isActor() {
        return this.$store.getters.isActor
      },
      isLoggedIn() {
        return this.$store.getters.isLoggedIn
      },
      shouldShowAdvancedCommunitiesHome() {
        if (!this.$store.state.config.homepageTemplate) {
          return false
        }

        if ((this.isOwner || this.isMember) && this.$store.getters.hasAccessToNewCommunities) {
          return this.$store.state.config.homepageTemplate.find(template => template.id === SECTIONS.COMMUNITY.id)
        }

        if ((this.isOwner || this.isMember) && this.$store.getters.hasAccessToNewSpottingAreas) {
          return this.$store.state.config.homepageTemplate.find(template => template.id === SECTIONS.SPOTTING_AREAS.id)
        }
      },
      shouldShowFoodleapStaticHome() {
        return this.$store.getters.isFoodleap && this.isActor
      },
      shouldShowAgriTechStaticHome() {
        return this.$store.getters.isAgriTech
      },
      shouldShowSpacewerxStaticHome() {
        return this.$store.getters.isSpacewerx
      },
      shouldShowGreentexStaticHome() {
        return this.$store.getters.isGreentex
      },
      shouldShowCommunitiesHome() {
        if (this.isOwner || this.isMember) {
          return false
        }

        if (!this.$store.state.config.homepageTemplate) {
          return this.$store.getters.hasAccessToNewCommunities
        }

        if (this.$store.getters.hasAccessToNewCommunities) {
          return this.$store.state.config.homepageTemplate.find(template => template.id === SECTIONS.COMMUNITY.id)
        }

        if (this.$store.getters.hasAccessToNewSpottingAreas) {
          return this.$store.state.config.homepageTemplate.find(template => template.id === SECTIONS.SPOTTING_AREAS.id)
        }
      },
      shouldShowBasicHomeSimplified() {
        return this.$store.state.config.siteTemplate === SiteTemplate.NEW_SIMPLIFIED
      },
      panel() {
        if (!/^[a-zA-Z-]*$/.test(this.$route.params.panel)) {
          return this.$nextTick(() => this.$router.push('/dashboards'))
        }

        if (this.$route.params.panel === 'competitive-landscape') {
          return this.$nextTick(() => this.$router.push('/dashboards/digital-footprint'))
        }

        if (this.$route.params.panel === 'analytics') {
          return 'Analytics'
        }

        if (this.$store.getters.isNewProfileEnabled && this.$route.params.panel === 'knowledge-base') {
          return 'knowledge-base'
        }

        if (this.$store.getters.isNewProfileEnabled && this.$route.params.panel === 'explore-content') {
          return 'explore-content'
        }

        if (this.$store.getters.isKlik && this.$store.state.config.allow_anonymous && !this.$store.getters.isLoggedIn) {
          return 'StaticHomeOpenCreatives'
        }

        if (this.$store.getters.isInnovaud && this.$store.state.config.allow_anonymous && !this.$store.getters.isLoggedIn) {
          return 'StaticHomeInnovaud'
        }

        if (this.$route.params.panel === 'home' && this.$store.getters.isCommunityEcosystem) {
          return 'StaticHomeCommunityEcosystem'
        }

        if (this.$store.getters.isFoodleap && this.$store.state.config.allow_anonymous && !this.$store.getters.isLoggedIn && this.$route.params.panel === 'home') {
          return 'StaticHomeFoodleap'
        }

        if (this.$store.getters.isFoodleap && this.$store.state.config.allow_anonymous && !this.$store.getters.isLoggedIn && this.$route.params.panel === 'foodleap-contact') {
          return 'StaticHomeFoodleapContact'
        }

        if (this.$store.getters.isFoodleap && this.$store.state.config.allow_anonymous && !this.$store.getters.isLoggedIn && this.$route.params.panel === 'foodleap-about') {
          return 'StaticHomeFoodleapAbout'
        }

        if (this.shouldShowAgriTechStaticHome && this.$route.params.panel === 'home') {
          return 'HomeAgritech'
        }

        if (this.shouldShowSpacewerxStaticHome && this.$route.params.panel === 'home') {
          return 'HomeSpacewerx'
        }

        if (this.shouldShowFoodleapStaticHome && this.$route.params.panel === 'home') {
          return 'HomeFoodleap'
        }

        if (this.shouldShowGreentexStaticHome && this.$store.state.config.allow_anonymous && !this.$store.getters.isLoggedIn && this.$route.params.panel === 'home') {
          return 'StaticHomeGreentex'
        }

        if (this.shouldShowCommunitiesHome) {
          return 'HomeCommunities'
        }

        if (this.shouldShowBasicHomeSimplified) {
          return 'BasicHomeSimplified'
        }

        if (this.$store.state.config.siteTemplate === 'SIMPLIFIED') {
          return 'HomeSimplified'
        }

        if (this.$route.params.panel === 'dynamic-dashboard' && !this.isOwner) {
          return 'home'
        }

        if (this.$route.params.panel === 'instant-monitoring' && !this.isOwner) {
          return 'InstantMonitoring'
        }

        if (this.$route.params.panel === 'home' && this.shouldShowAdvancedCommunitiesHome) {
          return 'HomeCommunities'
        }

        return this.$route.params.panel || 'home'
      },
    },

    beforeUnmount() {
      this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
    },
    beforeRouteLeave(to, from, next) {
      if (this.$refs.panel && this.$refs.panel.beforeRouteLeave !== undefined) {
        this.$refs.panel.beforeRouteLeave(to, from, next)
      } else {
        next()
      }
    },
    components: {
      Analytics,
      Charts,
      Compare,
      Connectedness,
      Country,
      Home,
      HomeSimplified,
      BasicHomeSimplified,
      StaticHomeSimplified,
      StaticHomeKlik,
      News,
      DigitalFootprint,
      BusinessSize,
      Buzz,
      MediaBuzz,
      Sankey,
      ScoresList,
      FundingList,
      KnowledgeBase,
      ConceptMap,
      ExploreContent,
      DynamicDashboard,
      EmailReports,
      NewsOverview,
      InstantMonitoring,
      HomeCommunities,
      StaticHomeInnovaud,
      StaticHomeCommunityEcosystem,
      StaticHomeFoodleap,
      StaticHomeFoodleapAbout,
      StaticHomeFoodleapContact,
      HomeFoodleap,
      HomeAgritech,
      HomeSpacewerx,
      StaticHomeOpenCreatives,
      StaticHomeGreentex
    },
  })
</script>
