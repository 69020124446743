<template>
  <tr class="row-curation" @click="preview">
    <td>{{ actor.name }}</td>
    <td class="text-right" :title="date(actor.created_at)">{{ timeAgo(actor.created_at) }}</td>
    <td class="text-right" v-if="actor.claimed_at">
      <template v-if="actor.claim_verified">
        Claimed by <a :href="'mailto:' + actor.claimed_email">{{ actor.claimed_name }}</a> at {{ date(actor.claimed_at) }}
      </template>
      <template v-else>
        {{ actor.claimed_name }} (<a :href="'mailto:' + actor.claimed_email">{{ actor.claimed_email }}</a>) requested a claim at {{ date(actor.claimed_at) }}
      </template>
    </td>
    <td class="text-right" v-else>
      Unclaimed
    </td>
    <td colspan="3" v-if="message">
      {{ message }}
      <ds-button class="row-curation__ok" icon="check" @click.stop="ok" label="Ok"/>
    </td>
    <template v-else-if="actor.deleted_at">
      <td>
        <ds-button class="row-curation__verify" @click.stop="deleteActor" icon="trash" label="Remove permanently"/>
      </td>
    </template>
    <template v-else-if="actor.draft">
      <td>
        <ds-button class="row-curation__verify" icon="check" @click.stop="accept" label="Approve"/>
      </td>
      <td>
        <ds-button class="row-curation__verify" @click.stop="showEditPanel(actor)" label="Edit"/>
      </td>
      <td width="36px">
        <ds-button class="row-curation__verify" @click.stop="remove" icon="trash" label="Remove"/>
      </td>
    </template>
    <template v-else-if="! actor.claim_verified">
      <td width="150px" colspan="2">
        <ds-button class="row-curation__verify" icon="check" @click.stop="acceptClaim" label="Accept claim"/>
      </td>
      <td width="150px">
        <ds-button class="row-curation__verify" icon="trash" @click.stop="denyClaim" label="Remove claim"/>
      </td>
    </template>
    <td v-else colspan="3">&nbsp;</td>
  </tr>
</template>

<script>
  import { timeAgo } from '../../util/date'
  import { removeActor, updateActor, updateClaim } from '../../api/actors'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: ['actor'],
    data () {
      return {
        message: '',
      }
    },
    methods: {
      showEditPanel (actor) {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'actor-edit', metaData: { id: actor.id }})
      },
      preview () {
        if (this.actor.deleted_at) {
          return
        }

        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'scores', metaData: this.actor.id })
      },
      acceptClaim () {
        this.message = '...'

        updateClaim(this.actor.id, this.actor.claimed_id, { verified: true })
          .then(() => {
            this.actor.claim_verified = true
            this.message = 'Claim is verified!'
          })
          .catch(errors => {
            this.message = errors && errors.message || 'Could not verify the claim.'
          })
      },
      denyClaim () {
        this.message = '...'

        updateClaim(this.actor.id, this.actor.claimed_id, { verified: false })
          .then(() => {
            this.actor.claim_verified = true
            this.message = 'Claim is removed!'
          })
          .catch(errors => {
            this.message = errors && errors.message || 'Could not verify the claim.'
          })
      },
      accept () {
        this.message = '...'

        this.actor.draft = 0
        updateActor({
          id: this.actor.id,
          data: { draft: 0 },
        })
          .then(actor => {
            this.actor.draft = actor.draft
            this.message = actor.draft ? 'Something went wrong.' : 'Actor is approved!'
          })
          .catch(() => {
            this.message = 'Could not approve the actor.'
            this.actor.draft = 1
          })
      },
      remove () {
        if (!window.confirm(this.actor.name + '\nAre you sure you want to remove this actor?')) {
          return
        }
        removeActor(this.actor.id, { hardDelete: 1 })
          .then(confirm => {
            this.actor.deleted_at = confirm.deleted ? new Date().toJSON() : null
            this.$store.commit('ACTORS/FLUSH_CACHE')

            this.message = 'Actor removed.'
          })
      },
      deleteActor () {
        removeActor(this.actor.id, { hardDelete: 1, fromCuration: 1 })
          .then(() => {
            this.message = 'The actor is permanently removed'
          })
          .catch(() => {
            this.message = 'Something went wrong while trying to remove this actor.'
          })
      },
      ok () {
        this.message = ''

        this.$emit('ok')
      },
      timeAgo,
      date (str) {
        return str ? str.slice(0, 19).replace('T', ' ') : ''
      },
    },
    watch: {
      actor () {
        // Clear the message when a new actor is assigned to this row
        this.message = ''
      },
    },
  })
</script>

<style>
  .row-curation td {
    line-height: 26px !important;
  }

  .row-curation .button {
    margin: -6px -10px !important;
    display: block;
    width: calc(100% + 20px);
    font-size: 12px;
  }

  .row-curation .row-curation__onhold {
    background: none;
    color: #999;
  }

  .row-curation__ok {
    width: 70px !important;
    margin-right: -10px !important;
    float: right;
  }

  .row-curation__remove {
    width: 36px !important;
    margin-right: -10px !important;
  }

  .row-curation--deleted {
    color: #f99;
  }
</style>
