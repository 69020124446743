<template>
  <div class="knowledge-base-simplified scrollable" ref="scrollable" :class="{'has-parent-page': isSecondTopbarDisplayed}">
    <knowledge-base-hero
      :title="$t('knowledge_base_header_title')"
    >
      <template v-slot:searchbar>
        <SearchBarWithSuggestions
          @onSubmitQuery="handleOnSearchBarSubmit"
          v-model:initial-query="search"
          :placeholder="$t('knowledge_base_header_search_placeholder')"
        />
      </template>
    </knowledge-base-hero>
    <div>
      <main :style="ui.isMobile ? `grid-template-columns: 1fr;` : `grid-template-columns: 2.5fr 1fr;`" class="container">
        <div class="resources">
          <div class="create-section" v-if="isLoggedIn && enableCreate">
            <img
              :src="this.$store.state.user.profile.photo_url"
              :alt="this.$store.state.user.profile.name"
              class="create-section__profile-picture"
              @click="createResource('resource')"
            >
            <div
              class="talkbubble" @click="createResource"
              v-tooltip.top="!communityId ? $t('add_resource', { user: this.$store.state.user.profile.name }) : $t('add_resource_cta', { user: this.$store.state.user.profile.name })"
            >
              {{ !communityId ? $t('add_resource', { user: this.$store.state.user.profile.name }) : $t('add_resource_cta', { user: this.$store.state.user.profile.name }) }}
            </div>
          </div>
          <SimpleHeading level="2">
            {{ $t('knowledge_base_main_title') }}
          </SimpleHeading>
          <loading v-if="fetching"/>
          <template v-else>
            <AnnouncementOrResourceCard
              v-for="item in knowledgeBaseItems"
              :data="item"
              :is-tag-active="isTagActive"
              :is-actor-active="isActorActive"
              @addTag="addTagToFilters"
              @removeTag="removeFilter"
              @toggleActor="toggleActor"
              @open-actor-side-panel="openActorSidePanel"
              @like-article="likeArticle"
              @resourceDeleted="resetResults"
              :prefix-url="prefixUrl"
            />
          </template>
        </div>
        <div class="filters-sticky" :class="isSimplifiedTemplateUsed ? 'is-simplified' : ''" v-if="!ui.isMobile">
          <SimpleHeading level="2">
            {{ $t('knowledge_base_filters_title') }}
          </SimpleHeading>
          <button
            class="clear-filters-button"
            v-if="this.filters.length > 0"
            @click="clearFilters"
          >
            {{
              $t('knowledge_base_filters_clear_filters_button', {
                count: this.filters.length,
              })
            }}
          </button>
          <FilterCategory
            icon="history"
            :maxItemsDisplayedAtOnce="6"
            :filters="formattedTimespanProperties"
            :title="$t('knowledge_base_filters_facets_timeline')"
            :key="'filtersFacetsTimespan'"
            @addFilter="setTimespan"
            @removeFilter="removeTimespan"
          />
          <FilterCategory
            v-if="displayContentTypeFilter"
            icon="layer-group"
            :maxItemsDisplayedAtOnce="6"
            :filters="formattedMediaTypeProperties"
            :title="$t('knowledge_base_filters_facets_media_types')"
            :key="'filtersFacetsMediaTypes'"
            @addFilter="addTagToFilters"
            @removeFilter="removeFilter"
          />
          <FilterCategory
            v-for="(facetField, index) in facetFields" :key="'filtersFacetField' + index"
            :icon="facetField.icon"
            :filters="mapFacetValuesToFilters(facetField.values)"
            :title="facetField.title"
            @addFilter="addTagToFilters"
            @removeFilter="removeFilter"
          />
          <FilterCategory
            v-if="otherFacets.length > 0"
            icon="bullseye"
            :filters="otherFacets"
            :title="$t('knowledge_base_filters_facets_significant_tags')"
            :key="'filtersFacetsSignificantTags'"
            @addFilter="addTagToFilters"
            @removeFilter="removeFilter"
          />
          <FilterCategory
            v-if="otherCommunities.length > 0 && isOwner && !communityId"
            icon="bullseye"
            :filters="otherCommunities"
            :title="'Communities'"
            :key="'filtersFacetsCommunities'"
            @addFilter="addTagToFilters"
            @removeFilter="removeFilter"
          />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
  import _throttle from 'lodash/throttle'
  import { toDateString } from '../../util/date'
  import { trackHeapEvent } from '../../util/analytics'

  import { Notifications } from '../../api/notifications'
  import { fetchKnowledgeBaseData, likeArticle } from '../../api/knowledge-base'

  import DsTextarea from '../Form/DsTextarea.vue'
  import Modal from '../Modals/Modal.vue'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import RadioButton from '../Form/RadioButton.vue'
  import KnowledgeBaseTopFilters from '../Filters/KnowledgeBaseTopFilters.vue'
  import Checkbox from '../Form/Checkbox.vue'
  import NewCard from '../NewCard/NewCard.vue'
  import KnowledgeBaseItem from '../KnowledgeBase/KnowledgeBaseItem.vue'
  import Container from '../Container/Container.vue'
  import Loading from './ConceptMap/Loading.vue'

  import { humanize } from '../../constants/properties'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { MUTATION_TYPES as FILTER_MUTATION_TYPES } from '../../store/modules/filters'
  import { MUTATION_TYPES as KNOWLEDGE_BASE_MUTATION_TYPES } from '../../store/modules/knowledgeBase'
  import MODAL_IDS from '../../constants/modal-ids'

  import UiMixin from '../../util/UiMixin'
  import ConfigMixin from '../../util/ConfigMixin'
  import { shortTitle } from '../../util/helpers.js'
  import { ActionTypes as ConceptSearchesAcionTypes } from '../../store/modules/concept-searches.js'
  import FiltersMixin from '../../util/FiltersMixin.js'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import SimpleHeading from '../Simplified/SimpleHeading.vue'
  import AnnouncementOrResourceCard from '../Simplified/AnnouncementOrResourceCard.vue'
  import FilterCategory from '../Simplified/FilterCategory.vue'
  import SearchBarWithSuggestions from '../SearchBarWithSuggestions/SearchBarWithSuggestions.vue'
  import { knowledgeBaseContentTypes } from '../../constants/config'
  import BasicHero from '../BasicHero/BasicHero.vue'
  import OnboardingMixin from '../../util/OnboardingMixin.js'
  import _isEmpty from 'lodash/isEmpty.js'
  import { Communities } from '../../api/communities'
  import KnowledgeBaseHero from '../Simplified/knowledgeBaseHero.vue'
  import { SiteTemplate } from '../../store/modules/config.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'knowledge-base-simplified',
    props: {
      enableBanner: {
        type: Boolean,
        default: true,
      },
      enableCreate: {
        type: Boolean,
        default: true,
      },
      enableConceptSearchFacets: {
        type: Boolean,
        default: true,
      },
      mlSupported: {
        type: Boolean,
        default: false,
      },
      prefixUrl: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        search: '',
        errors: {},
        fetching: false,
        endOfFeed: false,
        limit: 15,
        offset: 0,
        numberOfItemsInCollapsedCard: 5,
        filters: [],
        knowledgeBaseItems: [],
        collapsedTableSections: {},
        mediaTypeFacets: [],
        taxonomyFacets: [],
        patentTagFacets: [],
        actorFacets: [],
        sourceFacets: [],
        tagFacets: [],
        conceptSearchFacets: [],
        resourceToDelete: {},
        announcementToDelete: {},
        timespan: {},
        communities: [],
      }
    },
    computed: {
      communityId () {
        return this.$route.params.communityId
      },
      displayContentTypeFilter () {
        return this.formattedMediaTypeProperties.length > 0 && this.$store.getters.hasAccessToAllKnowledgeBaseContent
      },
      allConceptSearches () {
        return this.$store.state.conceptSearches.allConceptSearches || []
      },
      conceptSearches () {
        return this.allConceptSearches.map(result => {
          return {
            value: result.id,
            label: result.title,
          }
        })
      },
      conceptSearch () {
        return this.$store.state.knowledgeBase.conceptSearch
      },
      spottingArea () {
        return this.$store.state.knowledgeBase.spottingArea
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      isMember () {
        return this.$store.getters.isMember
      },
      isDeveloper () {
        return this.$store.getters.isDeveloper
      },
      isPortfolioMember () {
        return this.$store.getters.isPortfolioMember
      },
      maxScore () {
        return Math.max(...this.knowledgeBaseItems.map(r => r.raw_score))
      },
      config () {
        return this.$store.state.config
      },
      announcementTypes () {
        var types = [{ name: 'Featured Articles', value: 'featured' }, { name: 'Commented Articles', value: 'commented' }, {
          name: 'Liked Articles',
          value: 'liked',
        }, { name: 'Pinned Articles', value: 'pinned' }]

        return types
      },
      aspectTaxonomyEnabled () {
        return this.$store.getters.aspectTaxonomyEnabled
      },
      readinessTaxonomyEnabled () {
        return this.$store.getters.readinessTaxonomyEnabled
      },
      businessAspectTaxonomyEnabled () {
        return this.$store.getters.businessAspectTaxonomyEnabled
      },
      taxonomyProcessStepsEnabled () {
        return this.$store.getters.taxonomyProcessStepsEnabled
      },
      industries () {
        return this.$store.state.taxonomies.industries
      },
      product_features_a () {
        return this.$store.state.taxonomies.product_features_a
      },
      product_features_b () {
        return this.$store.state.taxonomies.product_features_b
      },
      product_features_c () {
        return this.$store.state.taxonomies.product_features_c
      },
      aspects () {
        return this.$store.state.taxonomies.aspects
      },
      domains () {
        return this.$store.state.taxonomies.domains
      },
      industriesOptions () {
        return this.industries.map(i => {
          return i.name
        })
      },
      subIndustries () {
        var subIndustries = []

        if (!this.industries) {
          return []
        }

        this.industries.forEach(item => {
          item.industries.forEach(subIndustry => {
            if (!subIndustries.includes(subIndustry.name)) {
              subIndustries.push(subIndustry.name)
            }
          })
        })

        return subIndustries || []
      },
      aspectOptions () {
        return this.aspects.map(i => {
          return i.name
        })
      },
      existingFields () {
        var fieldListOptions = []
        var mediaTypeOptions = []
        var actorOptions = []
        var sourceOptions = []

        /* fieldListOptions.map(option => {
          // The resource types are not part of the taxonomies and because of that we ignore them when attempting to fetch the counter values
          if (option.title == 'Resource types') {
            return
          }

          if (option.title == 'Top Actors') {
            return
          }

          if (option.title == 'Sources') {
            return
          }

          // We fetch the facet counter values and assign objects to the values array
          option.values = option.values.map(value => {
            return this.getFacetCounterValue(value)
          })

          // We filter out the taxonomies that have a count value of 0
          option.values = option.values.filter(value => value.count !== 0 && value.value !== null)

          // We sort the array based on the counter value
          option.values.sort(function (a, b) {
            return b.count - a.count
          })
        }) */

        this.mediaTypeFacets.forEach(mediaType => {
          // If it is in a private ecosystem we don't display the private resource item in the facet table
          if (!this.$store.getters.hasAccessToPublisher) { // && mediaType.value == 'private_resource') {
            // Do nothing
            return
          }

          if (mediaType.value == 'private_resource' || mediaType.value == 'public_resource') {
            mediaTypeOptions.push({ facet: 'media_types', value: mediaType.value, count: mediaType.counter })
          }
        })

        // Transform the facet values to options
        this.sourceFacets.forEach(sourceFacet => {
          sourceOptions.push({ facet: 'source', value: sourceFacet.value, label: sourceFacet.label, count: sourceFacet.count })
        })

        if (sourceOptions.length > 0) {
          fieldListOptions.unshift({ title: 'Sources', isCollapsed: false, values: sourceOptions })
        }

        this.actorFacets.forEach(actorFacet => {
          actorOptions.push({ facet: 'top_actor', value: actorFacet.value, label: actorFacet.label, count: actorFacet.count })
        })

        if (actorOptions.length > 0) {
          fieldListOptions.unshift({ title: 'Top Actors', isCollapsed: false, values: actorOptions })
        }

        if (mediaTypeOptions.length > 0) {
          fieldListOptions.unshift({ title: 'Resource types', isCollapsed: false, values: mediaTypeOptions })
        }

        if (this.patentTagFacets.length > 0) {
          fieldListOptions.unshift({ title: 'Patents', isCollapsed: false, values: this.patentTagFacets })
        }

        if (this.enableConceptSearchFacets && this.conceptSearchFacets.length > 0) {
          var conceptSearchOptions = this.conceptSearchFacets.map(search => {
            return {
              facet: 'concept-search-id',
              value: search.value,
              label: search.label,
              count: search.count,
            }
          })

          fieldListOptions.unshift({ title: 'Concept Searches', isCollapsed: false, values: conceptSearchOptions })
        }

        return fieldListOptions
      },
      formattedTimespanProperties () {
        var filters = [
          {
            value: 'day',
            title: this.$t('timespan_today'),
          },
          {
            value: 'week',
            title: this.$t('timespan_this_week'),
          },
          {
            value: 'month',
            title: this.$t('timespan_this_month'),
          },
          {
            value: 'year',
            title: this.$t('timespan_this_year'),
          },
        ]

        // Map the items, add the facet type + active state
        return filters.map(filter => {
          const val = { ...filter, facet: 'timespan' }

          return {
            facet: 'timespan',
            label: filter.title,
            value: val,
            active: (_isEmpty(filter.value) && _isEmpty(this.timespan.value)) || (this.timespan.value && this.timespan.value === filter.value),
          }
        })
      },
      formattedMediaTypeProperties () {
        var knowledgeBaseMediaData = []

        knowledgeBaseContentTypes.forEach(mediaType => {
          if (this.mediaTypeFacets.filter(item => item.value == mediaType).length > 0) {
            knowledgeBaseMediaData = knowledgeBaseMediaData.concat(this.mediaTypeFacets.filter(item => item.value == mediaType))
          }
        })

        knowledgeBaseMediaData = knowledgeBaseMediaData.map(item => ({ ...item, label: item.value }))

        var activeMediaTypeFilters = this.filters.filter(item => item.facet === 'media_types') || []
        var filterProperties = []

        knowledgeBaseMediaData.forEach((mediaType) => {
          mediaType.active = activeMediaTypeFilters.filter(activeFilter => activeFilter.value === mediaType.value).length > 0
          mediaType.value = { ...mediaType }

          // Don't show inactive, empty facets, this might occur because we passed it as a facet to count on, the back-end will always include facets that were passed
          // as filters, even if the count is 0x<
          if (mediaType.counter == 0 && !mediaType.active) {
            return
          }

          filterProperties.push(mediaType)
        })

        // We filter out items that contain a counter value of 0 or if the item is active
        return filterProperties
          .filter(item => item.counter !== 0 || item.active)
          .map(item => ({ ...item, label: this.getMediaTagLabel(item.value) }))
      },
      facetFields () {
        var facetFields = this.existingFields

        facetFields = facetFields.filter(facet => facet.title !== 'Resource types')

        return facetFields
          .map(facetField => {
            // Set a default for the icon value
            facetField.icon = ''

            switch (facetField.title) {
              case 'Top Actors':
                facetField.icon = 'building'
                facetField.title = this.$t('knowledge_base_filters_facets_top_actors')
                break
              case 'Sources':
                facetField.icon = 'rss'
                facetField.title = this.$t('knowledge_base_filters_facets_top_sources')
                break
            }

            return facetField
          })
          .filter(facetField => facetField.values && facetField.values.length > 0)
      },
      otherFacets () {
        var categories = []

        for (var key in this.categoryLabels) {
          if (this.categoryLabels.hasOwnProperty(key)) {
            var values = this.categoryLabels[key]
            categories = categories.concat(values)
          }
        }

        // Return the values that are not taxonomy values. Those values are already returned via the facetFields
        return this
          .tagFacets
          .filter((facet) => {
            return facet.type !== 'patent_cpc'
          })
          .map((facet) => {
            const facetValue = { ...facet }

            delete facetValue['text']

            const facetLabel = facet.count > 0 ? facet.label + ' (' + facet.count + ')' : facet.label

            return {
              ...facet,
              label: facetLabel,
              value: facetValue,
              active: this.isTagActive(facetValue),
            }
          })
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      otherCommunities () {
        var communities = []

        for (var key in this.communities) {
          if (this.communities.hasOwnProperty(key)) {
            var values = this.communities[key]
            if (values.active) {
              continue
            }
            communities = communities.concat(values)
          }
        }

        const filteredCommunities = this
          .communities
          .map((community) => {
            const communityValue = {
              ...community,
              facet: 'linked_community_id',
              value: community.id,
              label: community.name,
            }

            return {
              ...community,
              facet: 'linked_community_id',
              value: communityValue,
              label: community.name,
              active: this.isCommunityActive(communityValue),
            }
          })

        // If one is active only return that one
        const activeCommunities = []
        if (filteredCommunities.length > 0) {
          filteredCommunities.forEach((community) => {
            if (community.active) {
              activeCommunities.push(community)
            }
          })
        }

        if (activeCommunities.length > 0) {
          return activeCommunities
        }

        return filteredCommunities
      },
      isSimplifiedTemplateUsed () {
        return [SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(this.$store.getters.activeSiteTemplate)
      },
      isSecondTopbarDisplayed () {
        return this.$route.name === 'community-detail' || this.$route.name === 'spotting-area-detail'
      },
    },
    methods: {
      toDateString,
      humanize,
      likeArticle ({ resourceId, status }) {
        likeArticle(resourceId, status)
      },
      createResource (meta) {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'create-resource-panel',
          metaData: meta,
        })
      },
      isTagActive (tag) {
        return Boolean(this.filters.find(filter => filter.facet === 'tags' && filter.value === tag.value))
      },
      isActorActive (actor) {
        return Boolean(this.filters.find(filter => filter.facet === 'top_actor' && filter.value === actor.value))
      },
      isCommunityActive (community) {
        return Boolean(this.filters.find(filter => filter.facet === 'linked_community_id' && filter.value === community.id))
      },
      getMediaTagLabel (tag) {
        // We use this to change the displayed text in the media tags
        switch (tag.value) {
          case 'rss':
            return `Articles${tag.counter !== undefined ? ` (${tag.counter})` : ''}`
          case 'pr':
            return `Press Releases${tag.counter !== undefined ? ` (${tag.counter})` : ''}`
          case 'blog':
            return `Blogs${tag.counter !== undefined ? ` (${tag.counter})` : ''}`
          case 'crunchbase_pro':
            return `News aggregator${tag.counter !== undefined ? ` (${tag.counter})` : ''}`
          case 'announcement':
            return `${this.announcementsLabel}${tag.counter !== undefined ? ` (${tag.counter})` : ''}`
          case 'resource':
            return `Resources${tag.counter !== undefined ? ` (${tag.counter})` : ''}`
          case 'public_resource':
            return `Public Resource${tag.counter !== undefined ? ` (${tag.counter})` : ''}`
          case 'private_resource':
            return `Private resource${tag.counter !== undefined ? ` (${tag.counter})` : ''}`
          case 'vacancy':
            return `Vacancies${tag.counter !== undefined ? ` (${tag.counter})` : ''}`
          case 'patent':
            return `Patents${tag.counter !== undefined ? ` (${tag.counter})` : ''}`
          case 'product_service_offering':
            return `Products & Services${tag.counter !== undefined ? ` (${tag.counter})` : ''}`
          case 'evenement':
            return `Evenement${tag.counter !== undefined ? ` (${tag.counter})` : ''}`
          case 'periodiek':
            return `Periodiek${tag.counter !== undefined ? ` (${tag.counter})` : ''}`
          case 'kennistool':
            return `Kennistool${tag.counter !== undefined ? ` (${tag.counter})` : ''}`
          case 'publicatie':
            return `Publicatie${tag.counter !== undefined ? ` (${tag.counter})` : ''}`
          case 'themadossier':
            return `Thema dossier${tag.counter !== undefined ? ` (${tag.counter})` : ''}`
          case 'projectinfo':
            return `Project Info${tag.counter !== undefined ? ` (${tag.counter})` : ''}`
          default:
            return tag.value
        }
      },
      humanizeFacet (facet) {
        if (facet.facet == 'top_actor' || facet.facet == 'source') {
          return facet.label
        }

        if (facet.label) {
          return this.humanize(facet.label) || facet.label
        }

        return this.humanize(facet.value) || facet.value
      },
      humanizeFacetTitle (facetFieldTitle) {
        return this.humanize(facetFieldTitle)
      },
      mapFacetValuesToFilters (facetValues) {
        const filters = this.filters

        const isFacetActive = (facet) => {
          return Boolean(filters.find(filter => (!facet.facet || filter.facet === facet.facet) && filter.value === facet.value))
        }

        return facetValues
          .map((facet) => {
            return ({
              label: facet.count !== undefined && facet.count > 0 ? `${this.humanizeFacet(facet)} (${facet.count})` : this.humanizeFacet(facet),
              title: facet.type === 'patent_cpc' ? facet.text : '',
              value: facet,
              active: isFacetActive(facet),
            })
          })
      },
      fetch () {
        if (this.endOfFeed) {
          return
        }

        var filterOffset = this.offset
        this.offset += this.limit
        this.fetching = !filterOffset

        var formattedFilters = this.formatFilters(this.filters)
        var mlSupported = this.mlSupported
        var sortBy = 'date' //formattedFilters['query'] && formattedFilters['query'].length > 0 ? 'relevance' : 'date'

        formattedFilters = Object.assign(formattedFilters, { sortBy: sortBy, limit: this.limit, offset: filterOffset, 'ml-supported': mlSupported })

        // If the ecosystem has access to exploration / sensing and it's an owner add the conceptSearch filter if it's filled in
        if (this.$store.getters.hasAccessToExplorationOrSensing && this.isMember && this.conceptSearch) {
          formattedFilters['concept-search-id'] = this.conceptSearch
        }

        // Only apply spotting area filter when on that page
        if (this.isSpottingAreaDetailPage && this.spottingArea) {
          formattedFilters['spotting_area'] = this.spottingArea
        }

        // If there is a timespan filter selected
        if (!_isEmpty(this.timespan.value)) {
          formattedFilters['timespan'] = this.timespan.value
        }

        // If u fetch the knowledge base from a community detail
        if (this.communityId) {
          formattedFilters['linked_community_id'] = this.communityId
        }

        // Fetch knowledge base data
        fetchKnowledgeBaseData(formattedFilters)
          .then(({ data }) => {
            this.processContentTypeFacets(data.facets) // Process content type facet values
            this.processTaxonomyFacets(data.facets) // Process taxonomy facet values
            this.processActorFacets(data.facets)
            this.processSourceFacets(data.facets)
            this.processPatentTagFacets(data.facets)
            this.processOtherFacets(data.facets) // Process taxonomy facet values
            this.processConceptSearchFacets(data.facets)

            var items = data.results.map(item => {
              if (['rss', 'pr', 'blog', 'event'].includes(item.media_type) && item.sql_media_id) {
                item.detail_link = '/files/' + item.sql_media_id
              }

              if (item.meta && item.meta.kenniswest_type) {
                if (item.meta.hasOwnProperty('kenniswest_type')) {
                  delete item.meta['kenniswest_type']
                }
              }

              return item
            })

            if (filterOffset > 0) {
              this.knowledgeBaseItems = this.knowledgeBaseItems.concat(items)
            } else {
              this.knowledgeBaseItems = items
            }

            this.endOfFeed = data.results.length === 0
          })
          .catch(() => {
            this.error = 'Oops! Something went wrong while fetching data.'
            this.endOfFeed = true
          })
          .then(() => {
            this.fetching = false
          })
      },
      fetchConceptSearches () {
        this.$store.dispatch(ConceptSearchesAcionTypes.FETCH_ALL_CONCEPT_SEARCHES)
      },
      async fetchCommunities () {
        this.communities = await Communities.get('', { query: '' })
      },
      render () {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(this.actualRender(), 200)
      },
      actualRender () {
        if (this.$refs.columns) {
          this.columns = Math.floor(this.$refs.columns.scrollWidth / 300) || 1
        }
      },
      toggleFilter () {
        if (this.$store.state.filters.filter.visible) {
          this.$store.commit(FILTER_MUTATION_TYPES.TOGGLE_FILTER, false)
        } else {
          this.$store.commit(FILTER_MUTATION_TYPES.TOGGLE_FILTER, true)
        }
      },
      formatFilters (filters) {
        var filtersObject = {}

        filters.forEach(item => {
          switch (item.facet) {
            case 'tags':
            case 'media_types':
            case 'top_actor':
              // If the property does not exist in the "filtersObject" we add that property and its respective value as an array
              // else we just check if the property already contains the value in its array and if not its pushed into the array
              if (!filtersObject[item.facet]) {
                filtersObject = Object.assign(filtersObject, { [item.facet]: [item.value] })
              } else if (!filtersObject[item.facet].includes(item.value)) {
                filtersObject[item.facet].push(item.value)
              }
              break
            case 'free_text':
              // If the property does not exist in the "filtersObject" we add that property and its respective value as an array
              // else we just check if the property already contains the value in its array and if not its pushed into the array
              if (!filtersObject['query']) {
                filtersObject = Object.assign(filtersObject, { query: [item.value] })
              } else if (!filtersObject['query'].includes(item.value)) {
                filtersObject['query'].push(item.value)
              }
              break
            default:
              // Featured, commented, liked
              filtersObject = Object.assign(filtersObject, { [item.facet]: item.value })
              break
          }
        })

        return filtersObject
      },
      clearFilters () {
        this.search = ''
        this.filters = []
        this.timespan = {}
        this.offset = 0
        // Before we added the concept search filter, there was no state for the knowledge base, that's why we have 2 different "reset" operation
        this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.CLEAR)
        // NOTE: for some unknown reason, if you change the state by going from "Display all in short list" via the ConceptMap.vue
        // where you want to see the marked as relevant articles in the KB starting from the concept search
        // The "clear filters" does not reset the concept search to "null", if you however use the concept search filter starting from a "vanilla" KB
        // then the concept search filter is cleared using only the "CLEAR" mutation. This should be resolved if we move all of our filters to the state instead of
        // having them part in the state, part in the component
        this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.SET_CONCEPT_SEARCH, null)

        // ls('knowledgeBaseKeywords', this.filters)
      },
      handleOnSearchBarSubmit (query) {
        if (!query || !query.length) {
          let newFilters = [...this.filters]

          newFilters = newFilters.filter(item => item.facet !== 'free_text')

          this.filters = newFilters

          return
        }

        trackHeapEvent('knowledgeBaseSUI.searchFromSearchBar', { query })
        this.addFilter({ facet: 'free_text', value: query })
      },
      addFilter (filter, from) {
        var newFilters = [...this.filters]
        // We can only have one free text filter
        if (filter.facet == 'free_text') {
          newFilters = newFilters.filter(item => item.facet !== 'free_text')
        }

        // We can only have one timespan filter
        if (filter.facet == 'timespan') {
          newFilters = newFilters.filter(item => item.facet !== 'timespan')
        }

        // If either public_ or private_resource gets added, remove both before adding the filter again
        if (filter.value == 'private_resource' || filter.value == 'public_resource') {
          newFilters = newFilters.filter(item => item.value !== 'public_resource' && item.value !== 'private_resource')
        }

        newFilters = newFilters.filter(item => item.value !== filter.value)

        // If the filter is 'resource' we assign both the 'private_resource' and 'public_resource' to the filters
        if (filter.value == 'resource' && filter.facet != 'free_text') {
          // Only add public resource if the user is not a member (owner, team member, private guest) or portfolio member
          if (this.isMember || this.isPortfolioMember) {
            newFilters.push({ facet: 'media_types', value: 'private_resource' }, { facet: 'media_types', value: 'public_resource' })
          } else {
            newFilters.push({ facet: 'media_types', value: 'public_resource' })
          }
        } else {
          newFilters.push(filter)
        }

        this.filters = newFilters

        // ls('knowledgeBaseKeywords', this.filters)
      },
      removeFilter (filter) {
        var toBeRemovedFilters = [filter.value]

        // "resource" is a combination of 2 filters: private_resource and public_resource
        if (filter.value == 'resource') {
          toBeRemovedFilters = ['private_resource', 'public_resource']
        }

        this.filters = this.filters.filter(item => !toBeRemovedFilters.includes(item.value))

        // ls('knowledgeBaseKeywords', this.filters)
      },
      removeTimespan () {
        // Reset the selected value
        this.timespan = {}

        // Remove the facet from the filters
        const toBeRemovedFilters = ['timespan']

        this.filters = this.filters.filter(item => !toBeRemovedFilters.includes(item.facet))

        // ls('knowledgeBaseKeywords', this.filters)
      },
      setTimespan (timespan) {
        // set the selected value
        this.timespan = timespan

        // Add the facet tag to the filters
        this.addTagToFilters(timespan)
      },
      addTagToFilters (tag, source) {
        trackHeapEvent('knowledgeBaseSUI.addTag', { source, tag })
        // The 'private_resource' and 'public_resource' filters need a special treatment
        // They will not be set as 'tags' filters but instead as 'media_types'
        if (tag.facet == 'media_types') {
          tag.label = tag.value

          this.addFilter(tag)
          return
        }

        if (['timespan', 'top_actor', 'source', 'concept-search-id', 'linked_community_id'].includes(tag.facet)) {
          this.addFilter({ facet: tag.facet, value: tag.value, label: tag.label })

          return
        }

        var tagObject = { facet: 'tags', value: tag.value, label: tag.label, text: tag.text }

        this.addFilter(tagObject)
      },
      toggleActor (actor) {
        const topActor = { facet: 'top_actor', value: actor.id, label: actor.name }
        const actorFilterExists = this.isActorActive(topActor)

        if (actorFilterExists) {
          this.removeFilter(topActor)

          return
        }

        this.addFilter(topActor)
      },
      shortTitle,
      changeCollapseState (state, sectionName, from) {
        if (from == 'table-section') {
          trackHeapEvent('knowledgeBase.facetTable.changeCollapseState')
        }

        this.collapsedTableSections = Object.assign({}, this.collapsedTableSections, { [sectionName]: state })
      },
      stripHtml (text) {
        if (!text) {
          return
        }

        return text.replace(/<\/?[^>]+(>|$)/g, '')
      },
      setInitiallCollapsedObjectProperties () {
        if (this.existingFields.length) {
          if (Object.keys(this.collapsedTableSections).length == 0) {
            this.existingFields.forEach(field => {
              this.changeCollapseState(field.isCollapsed, field.title)
            })
          }
        }
      },
      processContentTypeFacets (mediaTypeData) {
        var mediaTypesArray = Object.entries(mediaTypeData.media_type_counts)
        var mediaTypesData = []

        mediaTypesArray.forEach(media_type => {
          mediaTypesData.push({ facet: 'media_types', value: media_type[0], counter: media_type[1] })
        })

        this.mediaTypeFacets = mediaTypesData
      },
      processTaxonomyFacets (facets) {
        this.taxonomyFacets = facets.tag_count
      },
      processPatentTagFacets (facets) {
        this.patentTagFacets = facets.tag_count.filter(value => value.type === 'patent_cpc')
      },
      processOtherFacets (facets) {
        this.tagFacets = facets.tag_count.slice(0, 25) || []
      },
      processConceptSearchFacets (facets) {
        this.conceptSearchFacets = facets.concept_search_count.slice() || []
      },
      processActorFacets (facets) {
        var topActors = facets.top_actors || []

        // Reset the top actor counts
        this.actorFacets = []

        topActors.forEach(item => {
          this.actorFacets.push({ facet: 'actor', label: item.name, value: item.id, count: item.count })
        })
      },
      processSourceFacets (facets) {
        var topSources = facets.top_sources || []

        // Reset the top actor counts
        this.sourceFacets = []

        topSources.forEach(item => {
          this.sourceFacets.push({ facet: 'source', label: item.display_name, value: item.id, count: item.count })
        })
      },
      getFacetCounterValue (facetValue) {
        // We cycle through each object in the array and assign the correspondent count value to the variable 'count'
        // Which is returned with the found count value
        var label = facetValue
        var count = 0
        var value = null
        var uri = null
        var type = null

        this.taxonomyFacets.forEach(facet => {
          if (facet.label.toLowerCase() == facetValue.toLowerCase()) {
            count = facet.count
            value = facet.value
            uri = facet.uri
            type = facet.type
          }
        })

        return { label, value, count, uri, type }
      },
      hidePreview () {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      showEditResourcePanel (resource) {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { 'component': 'edit-resource-panel', metaData: { resource: resource } })
      },
      deleteAnnouncement (announcement) {
        Notifications.delete(announcement.sql_media_id)
          .then(() => {
            setTimeout(() => {
              this.announcementToDelete = {}
              this.endOfFeed = false
              this.offset = 0
              this.fetch()
              this.$bus.emit('fetchNewAnnouncements')
              this.hidePreview()
            }, 500)
          })
      },
      resetResults () {
        this.endOfFeed = false
        this.offset = 0
        this.fetch()
        this.hidePreview()
      },
      showDeleteResourceConfirmationModal (resource) {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, { body: 'Do you want to delete this resource?', resource: resource, modalContextType: 'file' })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
      },
      showAnnouncementConfirmationModal (announcement) {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          body: `Do you want to delete this ${this.announcementLabel}?`,
          modalContextType: 'announcement',
          announcement: announcement,
        })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
      },
      showDeleteArticleConfirmationModal (article) {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, { body: 'Do you want to delete this article?', modalContextType: 'global-article', article: article })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
      },
      openActorSidePanel (actor) {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'scores', metaData: actor.actor_id })
      },
      openAnnouncementEditSidePanel (announcement) {
        // We need the "id" property to edit the resource.
        var copyOfAnnouncemnt = Object.assign({}, announcement, { id: announcement.sql_media_id })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'edit-announcement-panel', metaData: copyOfAnnouncemnt })
      },
      scrollKnowledgeBase: _throttle(function () {
        const elem = this.$refs.scrollable
        if (elem && elem.offsetHeight + elem.scrollTop > 0.95 * elem.scrollHeight - 1500) {
          this.fetch()
        }
      }, 600),
    },
    mounted: function () {
      this.$bus.on('fetchNewResources', () => {
        // After listening for the event, we have to wait a little while before fetching new data
        setTimeout(() => {
          this.endOfFeed = false
          this.offset = 0
          this.fetch()
        }, 500)
      })

      this.$bus.on('fetchNewResourcesWithLikes', () => {
        setTimeout(() => {
          this.endOfFeed = false
          this.offset = 0
          this.fetch()
        }, 100)
      })

      this.$emit('fetchNewResourcesWithLikes')

      // each time we leave the knowledge base the filters remain active so that the user does not have to start all over again when he/she returns
      // leaving filters active
      if (this.$store.state.knowledgeBase.knowledgeBaseFilters != null && this.$store.state.knowledgeBase.conceptSearch === null) {
        this.filters = this.$store.state.knowledgeBase.knowledgeBaseFilters
      } else if (this.$store.state.knowledgeBase.knowledgeBaseFilters != null && this.$store.state.knowledgeBase.conceptSearch != null) {
        // remove the existing filters when someone clicked the view content shortlist button in knowledge base
        this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.CLEAR)
      }

      // TODO: the entire knowledge base filters should work through a state, not half in the component, half in the knowledge base
      if (this.$store.state.knowledgeBase.actor) {
        this.addFilter(this.$store.state.knowledgeBase.actor)
      }

      if (this.$store.state.knowledgeBase.mediaType) {
        this.addFilter({ facet: 'media_types', value: this.$store.state.knowledgeBase.mediaType })
      }

      if (this.filters.length == 0 && this.$store.state.knowledgeBase.facetKeyword) {
        this.addFilter(this.$store.state.knowledgeBase.facetKeyword)
      }

      if (this.$store.state.knowledgeBase.conceptSearch && !isNaN(this.$store.state.knowledgeBase.conceptSearch)) {
        this.addFilter({ facet: 'concept-search-id', value: parseInt(this.$store.state.knowledgeBase.conceptSearch) })
      }

      this.fetch()

      if (this.$store.getters.hasAccessToExplorationOrSensing) {
        this.fetchConceptSearches()
      }

      this.actualRender()

      this.$refs.scrollable.addEventListener('scroll', this.scrollKnowledgeBase, { passive: true })

      if (this.existingFields.length > 0) {
        // Will add the initial collapse properties for each of the existing fields for the facet table
        this.setInitiallCollapsedObjectProperties()
      }

      if (this.$store.getters.hasAccessToNewCommunities && this.isOwner) {
        this.fetchCommunities()
      }

      // fetch the search value via facet type
      this.filters.map(v => {
        if (v.facet === 'free_text') {
          this.search = v.value
        }
      })
    },
    beforeUnmount () {
      this.$bus.off('fetchNewResources')
      this.$refs.scrollable.removeEventListener('scroll', this.scrollKnowledgeBase, { passive: true })
      this.hidePreview()
      // each time we leave the knowledge base the filters remain active so that the user does not have to start all over again when he/she returns
      this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.SET_KNOWLEDGE_BASE_FILTERS, this.filters)
    },
    mixins: [UiMixin, ConfigMixin, FiltersMixin, TranslationsMixin, OnboardingMixin],
    watch: {
      // TODO: move all filters of the knowledge base to the knowledge base state, and watch "filters" from the state, instead of the local "filters" of this component
      filters () {
        // Every time we update the filters we refetch data using the updated filters
        this.endOfFeed = false
        this.offset = 0
        this.fetch()
      },
    },
    components: {
      KnowledgeBaseHero,
      BasicHero,
      SearchBarWithSuggestions,
      Container,
      SimpleHeading,
      AnnouncementOrResourceCard,
      FilterCategory,
      KnowledgeBaseTopFilters,
      DsTextarea,
      Dropdown,
      Checkbox,
      Modal,
      RadioButton,
      NewCard,
      KnowledgeBaseItem,
      Loading,
    },
  })
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables";

  .knowledge-base-simplified {
    height: 100%;
    display: flex;
    flex-direction: column;

    .create-section .talkbubble {
      background: white;
      border-radius: 12px;
      display: flex;
      align-items: center;
      padding-left: 30px;
      color: var(--primary-community);
    }
  }

  main {
    flex: 1;
    display: grid;
    margin: 0 auto;
  }

  // other height is necessary for page sections on detail pages
  .has-parent-page {
    .filters-sticky {
      top: #{$top-bar-height};
      height: calc(100vh - #{$top-bar-height} - #{$second-top-bar-height});
    }
  }

  .filters-sticky {
    position: sticky;
    position: -webkit-sticky;
    width: 100%;
    height: calc(100vh - #{$top-bar-height});
    top: 5px;
    flex-direction: column;
    color: #fff;
    padding: 1rem 2rem;
    overflow-y: auto;

    &.is-simplified {
      height: calc(100vh - #{$top-bar-height} - #{$second-top-bar-height} - (#{$second-top-bar-height}));
    }
  }

  .resources,
  .filters {
    overflow-y: auto;
    padding: 1rem 2rem;

    @media (max-width: $screen-md) {
      padding: 1.5rem;
    }

    :deep(.svg-icon path ) {
      fill: var(--primary);
    }
  }

  .filters-title-container {
    display: flex;
    align-items: center;
  }

  .clear-filters-button {
    margin-bottom: 1rem;
    font-size: 0.75rem;
    font-family: inherit;
    color: var(--primary-community);
    background-color: transparent;
    border: 0;
    text-decoration: underline;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
    }
  }

  .container {

    .create-section {
      background-color: var(--primary-community-extra-lightest);
      border-radius: $default-border-radius;
      display: flex;
      margin-bottom: 1rem;

      > .talkbubble {
        cursor: pointer;

        &:hover {
          filter: drop-shadow(0 0 4px rgba(#000, 0.4));
        }
      }

      &.create-section {
        padding: 20px;

        .create-section__profile-picture {
          height: 45px;
          width: 45px;
          border-radius: 50%;
          margin-right: 32px;
          cursor: pointer;

          &:hover {
            // box-shadow: 0 0 4px rgba(#000, 0.4);
            filter: drop-shadow(0 0 4px rgba(#000, 0.4));
          }
        }
      }

      > .icon {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        padding: 0px 14px;
        border-radius: 12px;
        margin-left: 7px;

        &:hover {
          // box-shadow: 0 0 4px rgba(#000, 0.4);
          filter: drop-shadow(0 0 4px rgba(#000, 0.4));
        }

        > svg {
          color: var(--primary-community);
          font-size: 18px;
        }
      }
    }
  }

</style>
