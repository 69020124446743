<template>
  <transition name="from-right">
    <div
      v-if="typeOfPanel"
      class="side-panel__parent-element"
      :class="{ 'side-panel__push-left': hasAccessToRightNavigation, 'side-panel__large' : isLargePanel, 'side-panel__parent-element-collapsed': !isExpanded, 'side-panel__parent-element' : isExpanded }">
      <div class="side-panel">
        <card variant="actor-info" v-if="typeOfPanel === 'scores'">
          <!-- Moving the actor-scores panel with the other panels causes an error when editing actors after viewing and editing different ones on the list view -->
          <actor-scores-panel :actorId="typeof getData == 'string' ? getData : getData.actorId" :isPreview="typeof getData == 'string' ? false : getData.isPreview"/>
        </card>
        <card variant="actor-info" v-else>
          <actor-preview v-if="typeOfPanel === 'actor'"/>
          <invite-users-panel v-if="typeOfPanel === 'invite-users-panel'"/>
          <telex-side-panel v-if="typeOfPanel === 'telex'"/>
          <create-portfolio-panel v-if="typeOfPanel === 'create-portfolio'" :config="getData"/>
          <concept-search-panel v-if="typeOfPanel === 'concept-search-panel'" :conceptSearchId="getData.conceptSearchId" :allowManualSearch="getData.allowManualSearch"/>
          <edit-portfolio-panel v-if="typeOfPanel === 'edit-portfolio'" :config="getData"/>
          <actor-edit-panel v-if="typeOfPanel === 'actor-edit'" :actorId="getData.id" :openTab="getData.openTab"/>
          <posts-panel v-if="typeOfPanel === 'posts-panel'" :keywordData="getData"/>
          <edit-announcement-panel v-if="typeOfPanel === 'edit-announcement-panel'" :announcementData="getData"/>
          <actor-suggestions-panel v-if="typeOfPanel === 'actor-suggestions'" :actorId="getData"/>
          <actor-file-repository-panel v-if="typeOfPanel === 'actor-file-repository'" :actor="getData"/>
          <create-resource-panel :meta="getData" v-if="typeOfPanel === 'create-resource-panel'"/>
          <edit-resource-panel :meta="getData" v-if="typeOfPanel === 'edit-resource-panel'"/>
          <actor-connections-panel v-if="typeOfPanel === 'actor-connections-panel'"/>
          <agents-panel v-if="typeOfPanel === 'agents'" :data="getData"/>
          <agents-portfolio-panel :portfolioId="getData.id" v-if="typeOfPanel === 'portfolio-agents'"/>
          <user-panel v-if="typeOfPanel === 'user'" :userId="getData.userId" :allowDelete="getData.allowDelete"/>
          <actor-duplicates-panel v-if="typeOfPanel === 'actor-duplicates-panel'" :meta="getData"/>
          <actor-merge-panel v-if="typeOfPanel === 'actor-merge-panel'" :duplicateActorId="getData.actorId" :meta="getData"/>
          <similar-actors-panel v-if="typeOfPanel === 'similar-actors'" :actorId="getData"/>
          <similar-actors-exploration-panel v-if="typeOfPanel === 'similar-actors-exploration'" :actorId="getData"/>
          <manage-challenge-panel v-if="typeOfPanel === 'manage-challenge-panel'" :challengeData="getData"/>
          <manage-challenge-invitees v-if="typeOfPanel === 'manage-challenge-invitees'" :challengeData="getData"/>
          <chat-bot-side-panel v-if="typeOfPanel === 'chat-bot-side-panel'" />
          <user-information-side-panel v-if="typeOfPanel === 'user-information-side-panel'" :userId="getData.userId" />
        </card>
      </div>
      <ds-button icon="chevron-right" @click="togglePanel" variant="outline" size="small" class="side-panel__collapse-button"
        v-if="isExpanded && typeOfPanel === 'edit-portfolio'"/>
    </div>
  </transition>
</template>

<script>
  import ActorConnectionsPanel from './ActorConnectionsPanel.vue'
  import ActorDuplicatesPanel from './ActorDuplicatesPanel.vue'
  import ActorEditPanel from './ActorEditPanel.vue'
  import ActorFileRepositoryPanel from './ActorFileRepositoryPanel.vue'
  import ActorMergePanel from './ActorMergePanel.vue'
  import ActorPreview from './ActorPreview.vue'
  import ActorScoresPanel from './ActorScoresPanel.vue'
  import ActorSuggestionsPanel from './ActorSuggestionsPanel.vue'
  import AgentsPanel from './AgentsPanel.vue'
  import AgentsPortfolioPanel from './AgentsPortfolioPanel.vue'
  import Card from '../../components/Card/Card.vue'
  import CreatePortfolioPanel from './CreatePortfolioPanel.vue'
  import CreateResourcePanel from './CreateResourcePanel.vue'
  import ConceptSearchPanel from './ConceptSearchPanel.vue'
  import EditAnnouncementPanel from './EditAnnouncementPanel.vue'
  import EditPortfolioPanel from './EditPortfolioPanel.vue'
  import EditResourcePanel from './EditResourcePanel.vue'
  import SimilarActorsPanel from './SimilarActors/SimilarActorsPanel.vue'
  import InviteUsersPanel from './InviteUsersPanel.vue'
  import Keyword from '../../components/Keyword/Keyword.vue'
  import PostsPanel from './PostsPanel.vue'
  import TelexSidePanel from './TelexSidePanel.vue'
  import UserPanel from './UserPanel.vue'
  import ManageChallengePanel from './ManageChallengePanel.vue'
  import ManageChallengeInvitees from '../../Domain/Challenge/Components/ManageChallengeInvitees.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import KEYCODES from '../../constants/keycodes'
  import SimilarActorsExplorationPanel from './SimilarActors/SimilarActorsExplorationPanel.vue'
  import { defineComponent, inject } from 'vue'
  import ChatBotSidePanel from '@/Domain/Chat/Components/ChatBotSidePanel.vue'
  import UserInformationSidePanel from "@/components/SidePanel/UserInformationSidePanel.vue";

  export default defineComponent({
    name: 'side-panel',
    computed: {
      isDeveloper () {
        return this.$store.getters.isDeveloper
      },
      getData () {
        return this.$store.state.ui.sidePanel.metaData
      },
      isMember () {
        return this.$store.getters.isMember
      },
      typeOfPanel () {
        return this.$store.state.ui.sidePanel.selectedComponent
      },
      isExpanded () {
        return this.$store.state.ui.sidePanel.expanded
      },
      hasAccessToRightNavigation () {
        // Deprecated
        return false
      },
      isLargePanel () {
        return ['similar-actors', 'similar-actors-exploration', 'edit-announcement-panel', 'actor-edit', 'manage-challenge-panel', 'manage-challenge-invitees', 'agents'].includes(this.typeOfPanel)
      },
    },
    methods: {
      hidePreview () {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      togglePanel () {
        this.$store.commit(UI_MUTATION_TYPES.TOGGLE_SIDE_PANEL, false)
      },
      scrollToBottomOfChat() {
        const scrollTarget = document.getElementById("asideScrollTarget");

        // Scroll on the next tick to ensure the content has been rendered
        setTimeout(() => {
          if (scrollTarget) {
            scrollTarget.scrollTop = scrollTarget.scrollHeight;
          }
        }, 0);
      }
    },
    mounted () {
      this.$bus.on('scroll-to-bottom', this.scrollToBottomOfChat)
    },
    beforeUnmount () {
      this.$bus.off('scroll-to-bottom', this.scrollToBottomOfChat)
    },
    created () {
      window.addEventListener('keydown', (e) => {
        // Prevent closing a multiselect with escape from also closing the sidepanel
        if (document.activeElement && document.activeElement.classList.contains('multiselect')) {
          return
        }

        if (e.keyCode === KEYCODES.ESC && !this.$store.state.ui.visibleModals.length) {
          this.hidePreview()
        }
      })
    },
    components: {
      UserInformationSidePanel,
      ChatBotSidePanel,
      ActorConnectionsPanel,
      ActorDuplicatesPanel,
      ActorEditPanel,
      ActorFileRepositoryPanel,
      ActorMergePanel,
      ActorPreview,
      ActorScoresPanel,
      ActorSuggestionsPanel,
      AgentsPanel,
      AgentsPortfolioPanel,
      Card,
      ConceptSearchPanel,
      CreatePortfolioPanel,
      CreateResourcePanel,
      EditAnnouncementPanel,
      EditPortfolioPanel,
      EditResourcePanel,
      InviteUsersPanel,
      Keyword,
      PostsPanel,
      SimilarActorsPanel,
      SimilarActorsExplorationPanel,
      TelexSidePanel,
      UserPanel,
      ManageChallengePanel,
      ManageChallengeInvitees,
    },
  })
</script>
