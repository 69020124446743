<template>
  <div class="actor-suggestions">
    <div class="fixed-heading">
      <div class="card__general side-panel__header">
        <div class="card__title side-panel__actor-suggestions-card__title side-panel__actor-suggestions-header-card__title" style="min-width: 90%">{{ portfolio.name }} - Discovery
          agents
        </div>
        <div class="pull-right side-panel__announcement-header-button-container" style="text-align: right; min-width: 10%;">
          <ds-button icon="remove" variant="minimal" @click="hidePreview" size="small" class="side-panel__announcement-header-icon"/>
        </div>
      </div>
    </div>
    <div class="scrollable side-panel__scrollable__content has-padding" style="padding-bottom: 150px">
      <agents-info-block/>
      <!-- vue 3 doesn't allow syncing inside a v-for anymore, so v-for is not possible here unfortunately -->
      <agent-portfolio-card
        v-if="agents[0]"
        v-model:agent="agents[0]"
        :portfolio="portfolio"
        @toggleEnabled="toggleEnabled(agents[0])"
      />
      <!-- vue 3 doesn't allow syncing inside a v-for anymore, so v-for is not possible here unfortunately -->
      <agent-portfolio-card
        v-if="agents[1]"
        v-model:agent="agents[1]"
        :portfolio="portfolio"
        @toggleEnabled="toggleEnabled(agents[1])"
      />
      <!-- vue 3 doesn't allow syncing inside a v-for anymore, so v-for is not possible here unfortunately -->
      <agent-portfolio-card
        v-if="agents[2]"
        v-model:agent="agents[2]"
        :portfolio="portfolio"
        @toggleEnabled="toggleEnabled(agents[2])"
      />
      <!-- vue 3 doesn't allow syncing inside a v-for anymore, so v-for is not possible here unfortunately -->
      <agent-portfolio-card
        v-if="agents[3]"
        v-model:agent="agents[3]"
        :portfolio="portfolio"
        @toggleEnabled="toggleEnabled(agents[3])"
      />
      <!-- vue 3 doesn't allow syncing inside a v-for anymore, so v-for is not possible here unfortunately -->
      <agent-portfolio-card
        v-if="agents[4]"
        v-model:agent="agents[4]"
        :portfolio="portfolio"
        @toggleEnabled="toggleEnabled(agents[4])"
      />
      <!-- vue 3 doesn't allow syncing inside a v-for anymore, so v-for is not possible here unfortunately -->
      <agent-portfolio-card
        v-if="agents[5]"
        v-model:agent="agents[5]"
        :portfolio="portfolio"
        @toggleEnabled="toggleEnabled(agents[5])"
      />
      <!-- vue 3 doesn't allow syncing inside a v-for anymore, so v-for is not possible here unfortunately -->
      <agent-portfolio-card
        v-if="agents[6]"
        v-model:agent="agents[6]"
        :portfolio="portfolio"
        @toggleEnabled="toggleEnabled(agents[6])"
      />
    </div>
  </div>
</template>

<script>
  import AgentsInfoBlock from './Agents/AgentsInfoBlock.vue'
  import AgentPortfolioCard from './Agents/AgentPortfolioCard.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'

  import { fetchAgentPortfolioStatus, Portfolios } from '../../api/portfolios.js'

  export default {
    props: ['portfolioId'],
    data () {
      return {
        agentStatus: [],
        agents: [],
        interval: null,
        portfolio: {}
      }
    },
    computed: {
      //
    },
    methods: {
      hidePreview (evt) {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      toggleEnabled (agent) {
        agent.isEnabled = !agent.isEnabled

        // Set loading to true as a demo
        if (agent.isEnabled) {
          agent.isLoading = true
        }
      },
      prepareAgents () {
        this.agents.push({
          displayName: 'Administrator agent',
          name: 'administrator_agent',
          action: 'get_administrators',
          description: 'Start this agent to add actors that administer actors within this portfolio.',
          isEnabled: true,
          isLoading: false,
          loadingPercentage: null,
          status: 'idle',
          meta: {},
          suggestions: []
        })

        // Add the investor agent
        this.agents.push({
          displayName: 'Investor agent',
          name: 'investor_agent',
          action: 'get_investors',
          description: 'Start this agent to add actors that have invested in actors in this portfolio, as well as actors that have received investments from actors in this portfolio.',
          isEnabled: true,
          isLoading: false,
          loadingPercentage: null,
          status: 'idle',
          meta: {},
          suggestions: []
        })

        this.agents.push({
          displayName: 'Investments agent',
          name: 'investments_agent',
          action: 'get_investments',
          description: 'Start this agent to add actors that received investments from this actor.',
          isEnabled: true,
          isLoading: false,
          loadingPercentage: null,
          status: 'idle',
          meta: {},
          suggestions: []
        })

        // Add the founder agent
        this.agents.push({
          displayName: 'Founder agent',
          name: 'founder_agent',
          action: 'get_founders',
          description: 'Start this agent to add the founders of actors in this portfolio.',
          isEnabled: true,
          isLoading: false,
          loadingPercentage: null,
          status: 'idle',
          meta: {},
          suggestions: []
        })

        // Add the current team agent
        this.agents.push({
          displayName: 'Team agent',
          name: 'team_agent',
          action: 'get_team',
          description: 'Start this agent to add the team members of actors in this portfolio.',
          isEnabled: true,
          isLoading: false,
          loadingPercentage: null,
          status: 'idle',
          meta: {},
          suggestions: []
        })

        // Add the contacts agent
        this.agents.push({
          displayName: 'Contacts agent',
          name: 'contacts_agent',
          action: 'get_contacts',
          description: 'Start this agent to add personal contact information of actors in this portfolio.',
          isEnabled: true,
          isLoading: false,
          loadingPercentage: null,
          status: 'idle',
          meta: {},
          suggestions: []
        })

        // Add the acquisition agent
        this.agents.push({
          displayName: 'Acquisition agent',
          name: 'acquisition_agent',
          action: 'get_acquisitions',
          description: 'Start this agent to pull in companies that actors of this portfolio have acquired or that acquired companies in this portfolio.',
          isEnabled: true,
          isLoading: false,
          loadingPercentage: null,
          status: 'idle',
          meta: {},
          suggestions: []
        })

        this.agents.push({
          displayName: 'Projects agent',
          name: 'project_agent',
          action: 'get_projects',
          description: 'Start this agent to find projects & partners this actor is involved with.',
          isEnabled: true,
          isLoading: false,
          loadingPercentage: null,
          status: 'idle',
          meta: {},
          suggestions: []
        })

        this.agents.push({
          displayName: 'Portfolio agent',
          name: 'portfolio_agent',
          action: 'crawl_portfolio',
          description: 'Start this agent to search for partners/startups or collaborators from this actor',
          isEnabled: true,
          isLoading: false,
          loadingPercentage: null,
          status: 'idle',
          meta: {},
          suggestions: []
        })
      },
      updateAgents () {
        fetchAgentPortfolioStatus(this.portfolio.id)
          .then(response => {
            var updatedAgents = this.agents
            var statusses = response

            updatedAgents.forEach(agent => {
              if (!statusses[agent.action]) {
                return
              }

              var statusForAction = statusses[agent.action]

              // If the status is "started" and the new value is "not started", that means that there were no new actions dispatched
              // This is a special status, and quite frankly an edge case that might occur, but won't occur that frequently
              if (agent.status == 'no_new_actions' || (agent.status == 'started' && statusForAction.status == 'not_started')) {
                agent.status = 'no_new_actions'
              } else {
                agent.status = statusForAction.status
              }

              var percFinished = 0

              if (statusForAction.total > 0) {
                percFinished = (statusForAction.finished || 0) / statusForAction.total
                percFinished = Math.round((percFinished * 100), 2)
              }

              agent.meta = {
                total: statusForAction.total,
                percFinished: percFinished,
                finished: statusForAction.finished,
                running: statusForAction.running,
                queued: statusForAction.queued,
                actors_with_suggestions: statusForAction.actors_with_suggestions
              }
            })

            this.agents = updatedAgents
          })
          .catch()
      }
    },
    watch: {
      async portfolioId (v) {
        this.portfolio = {}
        this.agents = []
        this.agentStatus = []

        var portfolio = await Portfolios.get(this.portfolioId)

        this.portfolio = portfolio

        this.prepareAgents()
        this.updateAgents()
      }
    },
    async mounted () {
      var portfolio = await Portfolios.get(this.portfolioId)

      this.portfolio = portfolio

      this.prepareAgents()
      this.updateAgents()

      // Refresh the agents status every 5 seconds
      this.interval = setInterval(() => this.updateAgents(), 5000)
    },
    beforeUnmount () {
      // Make sure to kill the infinite loop function
      clearInterval(this.interval)
    },
    components: {
      AgentPortfolioCard,
      AgentsInfoBlock
    }
  }
</script>

<style lang="scss" scoped>
</style>
