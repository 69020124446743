export function prepareUserForEditing (profile) {
  const settings = profile.settings || {}

  return {
    email: profile.email,
    current_password: '',
    password: '',
    password_confirmation: '',
    settings: {
      newsletter: settings.newsletter || '',
      reports: settings.reports || '',
      updatesAndFeatures: settings.updatesAndFeatures || '',
      blogPosts: settings.blogPosts || '',
      eventInvites: settings.eventInvites || '',
      betaInvites: settings.betaInvites || '',
      dataScoutsNewsletter: settings.dataScoutsNewsletter || '',
      userNewsletter: settings.userNewsletter || '',
      weeklyDigest: settings.weeklyDigest || '',
      notifications: deepCopyNotifications(settings.notifications),
      curationDigest: settings.curationDigest || '',
      contentDigest: settings.contentDigest || '',
      hasClosedWelcomeText: settings.hasClosedWelcomeText || ''
    },
    name: profile.name,
    photo_url: profile.photo_url,
    phone: profile.phone,
    phone_published: profile.phone_published,
    bio: profile.bio,
    expertises: profile.expertises,
    motivations: profile.motivations,
    function: profile.function,
    department: profile.department,
    instagram_url: profile.instagram_url,
    twitter_url: profile.twitter_url,
    linkedin_url: profile.linkedin_url,
  }
}

function deepCopyNotifications (notifications) {
  if (!notifications) {
    return ''
  }

  return JSON.parse(JSON.stringify(notifications))
}
