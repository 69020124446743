<template>
  <div class="greentex scrollable">

    <div class="greentex-container primary">
      <div class="greentex__discover">
        <div class="greentex-watermark-container">
        </div>
        <div class="greentax-discover-container">
          <img class="greentex__logo__watermark" src="/images/static-home-page/greenTex/greentex-watermark.svg"
              alt="Event Image"/>
          <img class="greentex__logo" src="/images/static-home-page/greenTex/logo_regio_greentex_dark.png"
              alt="Event Image"/>
          <h1>Strengthening <br/> textile circularity <br/> in europe's regions</h1>
          <h4 class="greentex_discover-text">In the framework of the European Green Deal, <br/> RegioGreenTex is a
            project promoting <br/> the collaboration in research and
            development
            for the <br/>textile industry between the four major sectors <br/> of society - industry, government,
            research <br/> institutes, and the public - in establishing a
            <br/>
            systematic circular economy business model <br/>across the EU.
          </h4>
          <div class="greentex__button-container">
            <a href="/actors-simplified" class="greentex__button col-4">Discover the partners</a>
            <a href="/products-simplified" class="greentex__button col-4">Discover the projects</a>
          </div>
          <!--          <div class="greentex__button-container">
                      <a href="" target="_blank" class="greentex__button col-4">Contact us</a>
                    </div>-->
        </div>
      </div>
    </div>

    <div class="greentex-container pink">
      <div class="greentex__objectives">
        <h1>Objectives</h1>
        <div class="col-md-12 row greentex__objectives__data">
          <div v-for="objective in objectives" class="greentex_objective"
              :class="{ 'col-md-2': windowWidth > 1500, 'col-md-3': windowWidth < 1600 }">
            <div class="greentex_objective__image-container">
              <img :src="objective.logo" class="objective__image" :alt="objective.alt"/>
            </div>
            <p class="objective__text" v-html="objective.text"></p>
          </div>
        </div>
        <div class="greentex__button-container">
          <a href="/actors-simplified" class="greentex__button col">Discover the partners</a>
          <a href="/products-simplified" class="greentex__button col">Discover the projects</a>
        </div>
      </div>
    </div>

    <div class="greentex-container primary">
      <div class="greentex_regional_dimension">
        <h1>(INTER)REGIONAL DIMENSION</h1>
        <h4 class="greentex_discover-text">11 textile intensive regions in 8 European <br/> countries engaging to
          support their local SMEs:</h4>
        <div class="greentex_regional_dimension-map">
          <ul class="greentex_regional_locations-list">
            <li>
              <p>
                <icon name="map-marker"/>
                Flanders (BE)
              </p>
            </li>
            <li>
              <p>
                <icon name="map-marker"/>
                East Netherlands (NL)
              </p>
            </li>
            <li>
              <p>
                <icon name="map-marker"/>
                Hauts-de-France & Auvergne Rhone-Alpes (FR)
              </p>
            </li>
            <li>
              <p>
                <icon name="map-marker"/>
                Piedmont & Tuscany (IT)
              </p>
            </li>
            <li>
              <p>
                <icon name="map-marker"/>
                Norte Portugal (PT)
              </p>
            </li>
            <li>
              <p>
                <icon name="map-marker"/>
                North East Romania (RO)
              </p>
            </li>
            <li>
              <p>
                <icon name="map-marker"/>
                Catalunya & Valencia (ES)
              </p>
            </li>
            <li>
              <p>
                <icon name="map-marker"/>
                Västra Götaland (SE)
              </p>
            </li>
          </ul>
          <div class="greentex_regional_dimension-image-container">
            <div class="greentex_region_dimension-image-overlay"></div>
            <img src="/images/static-home-page/greenTex/greentex-locations-map.png"
                class="greentex_regional_dimension-image" alt="Greentex locations map">
          </div>
        </div>
        <div class="greentex__button-container">
          <a href="/actors-simplified" class="greentex__button">Discover the partners</a>
          <a href="/products-simplified" class="greentex__button">Discover the projects</a>
        </div>
      </div>
    </div>

    <div class="greentex-container white">
      <div class="greentex__investment-dimension">
        <h1>INVESTMENT DIMENSION</h1>
        <h4 class="greentex_discover-text">70% EU contribution 30% direct investment bottom up, <br/> market-led
          approach.</h4>
        <div class="greentex__investment-dimension-image">
          <img src="/images/static-home-page/greenTex/investment-dimension.png">
        </div>
        <div class="greentex__button-container">
          <a href="/actors-simplified" class="greentex__button">Discover the partners</a>
          <a href="/products-simplified" class="greentex__button">Discover the projects</a>
        </div>
      </div>
    </div>

    <div class="greentex-container primary">
      <div class="greentex__innovation-dimension">
        <h1>INNOVATION DIMENSION</h1>
        <div class="greentex__innovation-dimension-image">
          <img src="/images/static-home-page/greenTex/innovation-dimension.png">
        </div>
        <div class="greentex__button-container">
          <a href="/actors-simplified" class="greentex__button">Discover the partners</a>
          <a href="/products-simplified" class="greentex__button">Discover the projects</a>
        </div>
      </div>
    </div>

    <div class="greentex-container pink">
      <div class="greentex__region">
        <h1>#RegioGreenTex</h1>
        <h4>MORE INFORMATION:</h4>
        <p>Cristina Gatti<br/>
          PR & Communication Manager - EURATEX<br/>
          cristina.gatti@euratex.eu</p>
        <img src="/images/static-home-page/greenTex/co-funded-eu-under.png">
        <h4>Funded by the European Union. Views and opinions expressed are <br/> however those of the author(s) only and
          do not necessarily reflect <br/> those of the European
          Union or
          EISMEA.
          Neither the European Union <br/> nor the granting authority can be held responsible for them.</h4>
      </div>
    </div>
    <footer class="greentex-footer">
      <div class="greentex-logo">
        <img src="/images/static-home-page/greenTex/logo_regio_greentex_dark.png">
      </div>
      <div class="greentex-footer-policy-list">
        <a :href="termsOfUseUrl">Terms of use</a>
        <a :href="privacyPolicyUrl">Privacy Policy</a>
        <a :href="cookiePolicyUrl">Cookie Policy</a>
      </div>
      <div class="greentex-footer-supporters">
        <img src="/images/static-home-page/greenTex/I3-logo-positive.png">
        <img src="/images/static-home-page/greenTex/co-funded-eu-next.png">
      </div>
    </footer>
  </div>
</template>
<script>
import Container from '@/components/Container/Container.vue'
import { DEFAULT_URLS } from '@/constants/default-urls'

export default {
  name: 'StaticHomeGreentex',
  components: { Container },
  computed: {
    windowWidth () {
      return window.innerWidth
    },
    config () {
      return this.$store.state.config
    },
    termsOfUseUrl () {
      return this.config.termsOfUseUrl || DEFAULT_URLS.TERMS_OF_USE
    },
    privacyPolicyUrl () {
      return this.config.privacyPolicyUrl || DEFAULT_URLS.PRIVACY_POLICY
    },
    cookiePolicyUrl () {
      return this.config.cookiePolicyUrl || DEFAULT_URLS.COOKIE_POLICY
    },
    objectives () {
      return [
        {
          key: 1,
          logo: '/images/static-home-page/greenTex/icons/circularyeconomyicon.png',
          text: '<strong>Map the needs and potentiality in the implementation of a circular economy</strong> among European regions and clusters and their different levels of economic development.',
          alt: 'Circulary Economy Icon',
        },
        {
          key: 2,
          logo: '/images/static-home-page/greenTex/icons/ecosystemicon.png',
          text: 'Build a <strong>dynamic recycling textile ecosystem at European level</strong> by making demand and offer meet on a shared digital platform.',
          alt: 'Ecosystem icon',
        },
        {
          key: 3,
          logo: '/images/static-home-page/greenTex/icons/supporticon.png',
          text: 'Support SMEs pilot projects to <strong>accelerate the creation, development and use of new textile materials</strong> with increased recycled content by sharing technologies and methodologies.',
          alt: 'Support icon',
        },
        {
          key: 4,
          logo: '/images/static-home-page/greenTex/icons/hubicon.png',
          text: 'Create <strong>five regional textile recycling hubs</strong> in line with ReHubs Europe initiative and consistent with the EU textile strategy and with the needs of SMEs.',
          alt: 'Hub icon',
        },
        {
          key: 5,
          logo: '/images/static-home-page/greenTex/icons/fundingicon.png',
          text: 'Generate <strong>investment opportunities</strong> at regional level.',
          alt: 'Funding icon',
        },
      ]
    },
  },
}
</script>

<style scoped lang="scss">

$primaryGreentexColor: #9fd2c1;
$darkGreentexColor: #003f4d;
$pinkGreentexColor: #f7d1d7;

.greentex_regional_locations-list {
  padding-bottom: 100px;

  li {
    p {
      .svg-icon {
        :deep(path) {
          fill: white;
        }
      }
    }
  }
}

.greentex-container {
  position: relative;

  .greentex__logo__blanc {
    position: absolute;
    right: 0px;
    transform: translateX(49%);
    bottom: 0;
    z-index: 1;
  }
}

.greentex-container.primary {
  min-height: 100vh;
  position: relative;
  background-color: $primaryGreentexColor;
}

.greentex-container.pink {
  min-height: 100vh;
  position: relative;
  background-color: $pinkGreentexColor;
}

.greentex {
  width: 100vw;

  h1 {
    text-transform: uppercase;
    font-size: 45px;
  }

  p {
    font-size: 18px;
  }

  h1, h4 {
    color: $darkGreentexColor;

    margin: 0 auto;
  }

  h4 {
    font-size: 21px;
  }

  .greentex__button-container {
    margin: 30px auto 0px auto;
    padding-bottom: 20px;
    position: relative;
    z-index: 25;

    display: flex;
    justify-content: space-evenly;
    width: 50%;

    .greentex__button {
      text-transform: uppercase;
      font-weight: bolder;
      text-decoration: none;
      background-color: $darkGreentexColor;
      padding: 10px 10px 10px 10px;
      color: white;
      font-size: 22px;
      min-width: 350px;
      margin-bottom: 15px;
      text-align: center;

      &:hover {
        color: $darkGreentexColor;
        background-color: white;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .greentex__button-container {

    }
  }

  .greentex-container {
    width: 100vw;
    min-height: calc(100vh - 75px - 70px);
    height: auto;

    .greentex__logo {
      width: 30%;
      margin: 0 auto;
      margin-top: -30px;
      position: relative;
    }

    .greentex__discover {
      text-align: center;
      margin: 0 auto;
      position: relative;
      background: $primaryGreentexColor;

      .greentax-discover-container {
        z-index: 120;
        position: relative;
      }

      .greentex__logo__watermark {
        position: absolute;
        height: 83vh;
        right: -1px;
        opacity: 0.3;
        z-index: 1;

        :deep(path) {
          fill: white;
        }

      }

      /*.greentex-watermark-container {
        z-index: 1;

        :deep(path) {
          fill: white;
        }


      }*/


      h1 {
        margin-top: -40px;
        z-index: 2;
        position: relative;
      }

      h4 {
        margin-top: -40px;
        z-index: 2;
        position: relative;
      }

      .greentex_discover-text {
        margin-top: 30px;
        align-self: center;
      }
    }
  }

  .greentex__objectives {
    text-align: center;
    margin: 0 auto;
    position: relative;

    h1 {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .greentex__objectives__data {
      margin-top: 40px;
      width: 90%;

      margin: 0 auto;

      .greentex_objective {
        margin: 0 auto;
        margin-bottom: 30px;

        .greentex_objective__image-container {
          width: 160px;
          background-color: white;
          border-radius: 80px;
          margin: 0 auto;
          position: relative;
          margin-bottom: -80px;

          .objective__image {
            padding: 40px;
          }
        }

        .objective__text {
          font-size: 22px;
          background-color: white;
          padding: 75px 20px 0px 20px;
          height: 500px;
          max-width: 100%;
          width: 320px;
        }
      }
    }
  }

  .greentex_regional_dimension {
    h1, h4 {
      text-align: center;
    }

    h1 {
      padding-top: 50px;
    }

    h4 {
      padding-top: 40px;
      font-weight: 400;

    }

    .greentex_regional_dimension-map {
      margin: 0 auto;
      justify-content: center;
      display: flex;
      flex-direction: row;
      width: 100%;

      .greentex_regional_locations-list {
        justify-content: flex-end;
        display: flex;
        flex-direction: column;

        li {
          text-align: left;

          p {
            max-width: 100%;
            color: $darkGreentexColor;

            :deep(.path) {
              color: white;
            }
          }
        }
      }

      .greentex_regional_dimension-image-container {
        width: 30%;
        position: relative;

        .greentex_regional_dimension-image {
          width: 100%;
          z-index: 1;
        }

        .greentex_region_dimension-image-overlay {
          z-index: 5;
          background: linear-gradient(to bottom, $primaryGreentexColor 0%, $primaryGreentexColor 10%, transparent);
          position: absolute;
          width: 100%;
          height: 10%;
        }
      }
    }
  }


  .greentex__investment-dimension {
    width: 100%;
    position: relative;
    text-align: center;

    h1 {
      padding: 50px 0px 30px 0px;
      max-width: 100%;
    }

    h4 {
      font-weight: 400;
    }

    .greentex__investment-dimension-image {
      text-align: center;
      margin: 0 auto;
      width: 100%;

      img {
        width: 55%;
      }
    }
  }

  .greentex__innovation-dimension {
    width: 100%;
    position: relative;
    text-align: center;

    h1 {
      padding: 50px;
      max-width: 100%;
    }

    .greentex__innovation-dimension-image {
      text-align: center;
      margin: 0 auto;
      width: 100%;

      img {
        width: 65%;
      }
    }
  }

  .greentex__region {
    width: 100%;
    position: relative;
    text-align: center;

    h1 {
      padding: 50px;
      max-width: 100%;
    }

    h4 {
      font-weight: 400;
    }

    img {
      margin: 40px;
    }
  }
}

.greentex-footer {
  background-color: white;
  height: 75px;
  min-height: 75px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  img {
    height: 30%;
  }

  ul {
    display: flex;
    justify-content: space-between;
  }

  .greentex-logo {
    width: 25%;

    img {
      height: 100%;
    }
  }

  .greentex-footer-policy-list {
    line-height: 5;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 30%;

    a {
      color: $darkGreentexColor;
      font-size: 18px;

      text-decoration: none;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .greentex-footer-supporters {
    width: 25%;
    justify-content: space-evenly;
    display: flex;
    margin-top: 20px;

    img {
      height: 80%;
    }
  }
}

.greentex__region {
  padding-bottom: 20px;
}

@media only screen and (max-width: 1700px) {
  .greentex__button-container {
    width: 60% !important;
  }
}

@media only screen and (max-width: 1450px) {
  .greentex_regional_dimension-image-container {
    width: 100% !important;
  }

  .greentex-footer {
    flex-direction: column !important;

    .greentex-logo, .greentex-footer-policy-list, .greentex-footer-supporters {
      margin: 0 auto;
    }

    .greentex-footer-policy-list {
      line-height: 1;
      margin-top: -20px;
      margin-bottom: 30px;
    }

    .greentex-logo {
      text-align: center;

      img {
        width: 80% !important;
      }
    }

    .greentex-footer-supporters {
      height: 50% !important;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      img {
        height: 100%;
        padding: 5px;
        justify-content: space-between;
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .objective__text {
    font-size: 18px !important;
  }

  .greentex__logo {
    width: 25%;
  }
}

@media only screen and (max-width: 1450px) {
  .greentex__button-container {
    width: 80% !important;
  }
}

@media only screen and (max-width: 1440px) {
  .greentex__button-container {
    width: 40% !important;
    flex-direction: column !important;
  }

  .greentex_regional_dimension-map {
    flex-direction: column !important;
    margin: 0 auto !important;
    width: 50% !important;

    .greentex_regional_locations-list {
      margin-top: 24px;
    }

    img {
      width: 100% !important;
      margin: 24px auto !important;
    }
  }
}

@media only screen and (max-width: 1409px) {
  .greentex-footer {
    .greentex-footer-supporters {
      padding-bottom: 20px;

      img {
        height: 50px;
        margin-top: 5px;
      }
    }

    .greentex-footer-policy-list {
      flex-direction: column !important;
      text-align: center;

      a {
        font-size: 18px !important;
        padding: 10px;
      }
    }
  }
}

@media only screen and (max-width: 1070px) {

  .greentex__logo {
    width: 50% !important;
  }

  .greentex__investment-dimension-image {
    img {
      width: 100% !important;
    }
  }

  .greentex__innovation-dimension-image {
    img {
      width: 100% !important;
    }
  }

  .greentex_regional_dimension-map {
    width: 100% !important;

    .greentex_regional_locations-list {
      width: 50% !important;
      margin: 0 auto !important;
      margin-top: 30px !important;
    }

    .greentex_regional_dimension-image {
      img {
        width: 100% !important;
      }
    }
  }


  .greentex-footer-policy-list {
    line-height: 0 !important;
    margin-top: 0px !important;
  }

  .greentex__button-container {
    justify-content: space-between;

    a {
      font-size: 18px !important;
      width: 100% !important;
      min-width: fit-content !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .greentex-footer-policy-list {
    flex-direction: column !important;
    line-height: 1.5 !important;
    text-align: center !important;
  }

  .greentex-footer-supporters {
    flex-direction: column !important;
    text-align: center !important;

    img {
      width: 80%;
      margin: 0 auto;
    }
  }
}

@media only screen and (max-width: 757px) {
  .greentex_regional_dimension-map {
    .greentex_regional_locations-list {
      width: fit-content !important;
      margin: 0 auto !important;
      padding-top: 20px;
    }

    img {
      width: 100% !important;
      margin: 0 auto !important;
    }
  }
}

@media only screen and (max-width: 700px) {

  .greentex__logo {
    width: 70% !important;
  }

  .greentex__button-container {
    width: 60% !important;
  }
}

@media only screen and (max-width: 450px) {
  .greentex__button-container {
    width: 80% !important;
  }
}

</style>
