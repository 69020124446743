<template>
  <modal
    :id="modalId"
    ref="modal"
    class="add-actor-to-article__modal with-subtitle"
    @close="close"
    :title="$t('add_organisation_manually')"
    is-simplified-modal
  >
    <template v-slot:title>
      <h2 v-if="!showSuccessState" class="title">You are inviting a new community member
        <span
          class="mandatory-message"
        >{{
            $t('all_fields_with_asterisk_are_mandatory')
          }}</span>
      </h2>
      <h2 v-else class="title">
        Invitation Successful
      </h2>
    </template>
    <template v-slot:body>
      <form-group class="add-actor-to-article__modal-content" v-if="showSuccessState">
        {{ invitation.user.label }} was successfully invited to {{ community.name }}
      </form-group>
      <form-group v-if="!showSuccessState" label="Enter an email address or name" required class="add-actor-to-article__modal-content">
        <autocomplete-dropdown
          :required="true"
          is-simplified
          :custom-no-result-message="'No user found. Try again'"
          v-model="invitation.user"
          :options="displayedUserSuggestions"
          :do-search="autocompleteSearchTerm"
          :multiple="false"
          :allowClear="true"
          placeholder="Enter an email address or name"
          class="add-actor-to-article__dropdown"
          :margin-top="'0px'"
          @update:modelValue="verifyMember"
        />
        <p class="error">{{ error }}</p>
      </form-group>

      <form-group v-if="!showSuccessState" label="Personal message" class="add-actor-to-article__modal-content">
        <ds-textarea is-simplified v-model="invitation.personal_message" placeholder="Personal message"/>
      </form-group>
    </template>
    <template v-slot:footer>
      <div v-if="!showSuccessState" class="add-member-to-community__modal-footer">
        <ds-button
          class="add-actor-to-article__button"
          @click="submit"
          label="Invite community member"
          size="extra-small"
          variant="rounded"
          :disabled="isLoading || isDisabled"
        />
      </div>
    </template>
  </modal>
</template>


<script>
  import MODAL_IDS from '../../constants/modal-ids.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import Modal from './Modal.vue'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import AutocompleteDropdown from '../Dropdown/AutocompleteDropdown.vue'
  import FormGroup from '../Form/FormGroup.vue'
  import DsInput from '../Form/DsInput.vue'
  import { searchUser } from '../../api/config'
  import { addCommunityMember, verifyMember } from '../../api/communities'
  import DsTextarea from '../Form/DsTextarea.vue'

  export default {
    data () {
      return {
        modalId: MODAL_IDS.ADD_MEMBER_TO_COMMUNITY_MODAL,
        message: '',
        actor: [],
        allActorOptions: [],
        isLoading: false,
        linked_actor_elastic_ids: [],
        isDisabled: true,
        invitation: {
          user: null,
          personal_message: ''
        },
        error: '',
        displayedUserSuggestions: [],
        showSuccessState: false
      }
    },
    computed: {
      ecosystemDisplayName () {
        return this.$store.getters.ecosystemDisplayName
      },
      communityId () {
        return this.community.id
      },
      community () {
        return this.modalContext.community
      },
      modalContext () {
        return this.$store.state.ui.modalContext
      },
      config () {
        return this.$store.state.config
      }
    },
    methods: {
      verifyMember () {
        this.error = null
        this.isDisabled = true

        if (!this.invitation.user || !this.invitation.user.value) {
          return
        }

        verifyMember({ communityId: this.communityId, userId: this.invitation.user.value })
          .then(response => {
            this.isDisabled = false
            this.error = response.message
          })
          .catch(response => {
            this.isDisabled = true
            this.error = response.error
          })
      },
      close () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, this.modalId)
        this.$emit('close')
        this.showSuccessState = false
      },
      autocompleteSearchTerm (query) {
        return searchUser({
          query: query
        })
          .then(suggestions => {
            const options = []
            suggestions = this.removeDuplicates(suggestions)

            suggestions
              .forEach(function (user) {
                options.push({
                  label: user.name + ' (' + user.email + ')',
                  value: user.id
                })
              })

            return options
          })
          .catch(err => {
            console.log(err)
          })
      },
      removeDuplicates (suggestions) {
        return suggestions
      },
      submit () {
        this.error = null
        const data = {
          userId: this.invitation.user.value,
          personal_message: this.invitation.personal_message
        }

        addCommunityMember({ communityId: this.communityId, data: data })
          .then(response => {
            if (response.error) {
              this.error = response.error
              return
            }
            if (response.success) {
              this.showSuccessState = true
              this.$bus.emit('invited-community-member')
            }
            this.userId = null
            this.invitation.personal_message = null
          })
      }
    },
    mounted () {

    },
    components: {
      Modal,
      AutocompleteDropdown,
      FormGroup,
      DsInput,
      DsTextarea
    },
    mixins: [TranslationsMixin]
  }
</script>


<style lang="scss" scoped>
  @import '../../../scss/_variables.scss';

  .add-actor-to-article__modal-content {
    box-shadow: none;
    border-radius: 0;
    margin: 10px;
    padding: 10px;
  }

  .add-actor-to-article__modal-label {
    font-size: 12px;
  }

  .error {
    color: $color-error;
  }

  .with-subtitle.modal__simplified :deep(.modal__wrapper .modal__container .modal__body) {
    overflow: visible;
  }

  :deep(.multiselect__content-wrapper) {
    max-height: 84px !important;
  }

</style>
