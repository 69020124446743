<template>
  <div>
    <div class="fixed-heading">
      <ds-button
        class="side-panel__header__close-button"
        :class="[company.product ? 'side-panel__has-product-name' : '']"
        icon="remove"
        variant="minimal"
        @click="hidePreview"
      />
      <div class="card__general side-panel__header side-panel__actor-header">
        <div class="card__ribbon" v-if="ribbonClass" :style="ribbonStyle"></div>
        <div class="card__logo" :class="[company.product ? 'side-panel__has-product-name' : '']">
          <avatar :src="companyLogoUrl" :alt="company.name" variant="border"></avatar>
        </div>
        <div class="card__title side-panel__card__title" v-if="company.name">{{ company.name }}</div>
        <span class="side-panel__product-name" v-if="company.product">{{ company.product }}</span>
      </div>
      <ds-button variant="minimal"
        :icon="isLoading ? 'spinner' : 'trash'"
        size="extra-small"
        @click="isLoading ? '' : showDeleteConfirmationModal(company)"
        v-if="userCanDelete"
        :disabled="!company.id || isLoading"
        :class="[company.product ? 'side-panel__has-product-name' : '', userCanEdit ? 'side-panel__delete-button' : 'side-panel__edit-button']"
      />
      <ds-button
        class="side-panel__edit-button"
        :class="[company.product ? 'side-panel__has-product-name' : '']"
        v-if="userCanEdit"
        :icon="isLoading ? 'spinner' : 'edit'"
        size="extra-small"
        variant="minimal"
        :disabled="!company.id || isLoading"
        @click="isLoading ? '' : showEditPanel()"
      />
    </div>
    <div class="scrollable side-panel__scrollable__content">
      <!-- Commented until the side panel has more than 1 tab -->
      <!--<tabs :tabs="tabs" :saveToUrl="true" class="side-panel__tab-section"></tabs>-->
      <div class="side-panel__redirect-section" v-if="userCanClaim && ! isPreview && !isMember">
        <ds-button
          icon="chevron-right"
          size="extra-small"
          label="Full Page"
          variant="default"
          :disabled="! company.id"
          :to="redirectTo('#')"
          v-if="$store.getters.views.includes('actors/detail')"
        />
        <ds-button
          v-if="isMember"
          icon="chevron-right"
          size="extra-small"
          label="Notes"
          variant="default"
          :disabled="! company.id"
          :to="redirectTo('#notes')"
        />
        <ds-button
          icon="double-chevron-right"
          label="Next"
          size="extra-small"
          variant="default"
          :disabled="! company.id"
          @click="goToNext"
        />
      </div>
      <div class="side-panel__redirect-section" v-else-if="$store.getters.views.includes('actors/detail') || (hasScores && isMember) || isMember">
        <ds-button
          icon="chevron-right"
          size="extra-small"
          label="Full Page"
          variant="default"
          :to="redirectTo('#')"
          :disabled="! company.id"
          v-if="$store.getters.views.includes('actors/detail')"
        />
        <ds-button
          v-if="hasScores && isMember && ! isPreview"
          icon="chevron-right"
          size="extra-small"
          label="Scores"
          variant="default"
          :disabled="! company.id"
          :to="redirectTo('#info')"
        />
        <ds-button
          v-if="! isPreview"
          icon="chevron-right"
          size="extra-small"
          label="Notes"
          variant="default"
          :disabled="! company.id"
          :to="redirectTo('#notes')"
        />

        <ds-button
          icon="double-chevron-right"
          label="Next"
          size="extra-small"
          variant="default"
          v-if="! isPreview"
          :disabled="! company.id"
          @click="goToNext"
        />
      </div>
      <div class="side-panel__company-section">
        <div class="side-panel__badge-container" v-if="!isLoading && !$store.getters.isPrivate && isMember">
          <badge name="unclaimed" variant="secondary" v-if="isActorUnclaimed"/>
          <badge :name="claimedBadgeLabel" variant="secondary" v-if="!isActorUnclaimed"/>
          <badge name="published" variant="secondary" v-if="isActorPublished"/>
        </div>
        <div class="side-panel__card__cover" :style="coverStyling"></div>
        <div class="side-panel__company-info" v-if="company.id !== undefined">
          <div class="side-panel__company-info__competidor-type side-panel__company-info_text" v-if="company.category && company.category.length > 0">{{ company.category }}</div>
          <div class="side-panel__company-info__business-area side-panel__company-info_text" v-if="company.industry && company.industry.length > 0">{{ company.industry[0] }}</div>
          <div class="side-panel__company-info__small-title side-panel__company-info_text" v-if="company.type && company.type.length > 0">{{ company.type }}</div>
          <div class="side-panel__company-info__description side-panel__company-info_text" v-if="company.short_description && company.short_description.length > 0">
            {{ company.short_description }}
          </div>
        </div>
      </div>
      <div class="side-panel__company-section" v-if="showContactDetails">
        <div class="side-panel__company-info__social-information">
          <div class="side-panel__company-info__social-links">
            <card-info-social :company="company" class="icon--black" iconSize="14"/>
          </div>
          <div class="side-panel__company-info__social-information side-panel__company-info__urls">
            <div class="card-actor-info__links" style="padding: 0 0rem;">
              <div v-if="company.url">
                <icon name="world" size="small"/>
                <a :href="company.url" target="_blank"> {{ company.url }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isLoading" class="side-panel__company-section side-panel__contact-section">
        <div class="row">
          <div v-if="userCanClaim"
            :class="(! $store.getters.isPrivate && $store.getters.canActorsBeContacted == true && company.claimed == true) ? 'col-xs-6 col-sm-6 col-md-6 col-lg-6' : 'col-xs-12 col-sm-12 col-md-12 col-lg-12'">
            <ds-button
              class="side-panel__contact-button"
              size='extra-small'
              icon="email-outline"
              label="Claim"
              variant="secondary"
              :disabled="! company.id"
              @click="showClaimModal"
            />
          </div>
          <div :class=" userCanClaim ? 'col-xs-6 col-sm-6 col-md-6 col-lg-6' : 'col-xs-12 col-sm-12 col-md-12 col-lg-12'"
            v-if="! $store.getters.isPrivate && $store.getters.canActorsBeContacted == true && company.claimed == true">
            <div class="side-panel__contact-button">
              <div v-if="!isLoggedIn">
                <ds-button label="Contact" icon="email-outline" size="extra-small" variant="secondary" @click="showSignUpModal"/>
              </div>
              <div v-else>
                <ds-button label="Contact" icon="email-outline" size="extra-small" variant="secondary" @click="showContactModal"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="side-panel__card__info__columns" v-if="(showCompanyInfo || showCompanyFunding) && company.actor_type === 'LegalEntity'">
        <div class="side-panel__card__detail__info__column" v-if="showCompanyInfo">
          <b>employees</b><br><br><br><span>{{ companyEmployees }}</span>
        </div>
        <div class="side-panel__card__detail__info__column" v-if="showCompanyFunding">
          <b>Number of rounds</b><br><br><span>{{ getNumberOfRounds }}</span>
        </div>
        <div class="side-panel__card__detail__info__column" v-if="showCompanyInfo">
          <b>{{ financialMetricName }}</b><br><br><br><span>{{ financialMetric }}</span>
        </div>
        <div class="side-panel__card__detail__info__column" v-if="showCompanyFunding">
          <b>Total funding</b><br><br><br><span>{{ getTotalFunding }} EUR</span>
        </div>
      </div>
      <div class="side-panel__competition-section" v-if="hasCompetitionScores">
        <h4 class="side-panel__section-title">Competition</h4>
        <table class="table table--preview">
          <tbody>
          <tr>
            <td>Scores</td>
            <td class="text-right" v-for="date in companyScoresDates" v-text="toOnlyMonthString(date)" :title="date"></td>
          </tr>
          <tr v-for="(row, index) in companyScoresTable" :class="{ 'text-bold': !index }">
            <td v-text="row.score"></td>
            <td class="text-right" v-for="cell in row.values" v-text="cell"></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="side-panel__video-section" v-if="actorEmbeddedVideo">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 side-panel__video-conatiner">
          <ds-button
            class="side-panel__video-expand-button"
            size="small"
            icon="arrow-expand"
            variant="default"
            @click="showVideoModal(actorEmbeddedVideo)"
          />
          <iframe width="100%;" height="250px" :src="actorEmbeddedVideo" frameBorder="0">
            <p>Your browser does not support iframes.</p>
          </iframe>
        </div>
      </div>
      <div class="side-panel__significant-change-section" v-if="significantChange && significantChange.message && significantChange.actor_id == company.id">
        <h4 class="side-panel__section-title">Signicant Change</h4>
        <div>
          <icon name="arrow-graph"></icon>
        </div>
        <div class="side-panel__significant-change"> {{ significantChange.message }}</div>
      </div>
      <div class="side-panel__news-section" v-if="hasCompetitionScores && company.news && company.news.length > 0">
        <a v-if="company.news[0].url" :href="company.news[0].url" target="_blank">
          <h4 class="side-panel__section-title">News</h4>
          <section>
            <div class="side-panel__news-section__image" :style="newsImageStyle"></div>
          </section>
          <section>
            <div class="side-panel__news-section__article-info">
              <h5 class="side-panel__section-title">{{ latestNewsPublisher }}</h5>
              <h5 class="side-panel__news-date">{{ latestNewsDate }}</h5>
              <h5 class="side-panel__section-title">{{ latestNewsTitle }}</h5>
            </div>
          </section>
        </a>
      </div>
      <div class="side-panel__news-section" v-else-if="company.news && company.news.length > 0" v-for="(item,index) in Math.min(3, company.news.length)">
        <a v-if="company.news[index].url" :href="company.news[index].url" target="_blank">
          <h4 class="side-panel__section-title">News</h4>
          <section>
            <div class="side-panel__news-section__image" :style="newsImageStyle"></div>
          </section>
          <section>
            <div class="side-panel__news-section__article-info">
              <h5 class="side-panel__section-title">{{ company.news[index].publisher }}</h5>
              <h5 class="side-panel__news-date">{{ company.news[index].date }}</h5>
              <h5 class="side-panel__section-title">{{ company.news[index].title }}</h5>
            </div>
          </section>
        </a>
      </div>
      <!--<div class="side-panel__last-update-section">
        <h4 class="side-panel__section-title">Report Last Update</h4>
        <div>
          <icon name="tag"></icon>
        </div>
        <div> </div>
      </div>-->
    </div>
  </div>
</template>

<script>
  import ActorUrls from '../ActorInfo/ActorUrls.vue'
  import Avatar from '../Avatar/Avatar.vue'
  import Card from '../../components/Card/Card.vue'
  import CardInfoSocial from '../../components/Card/CardInfoSocial.vue'
  import Keyword from '../../components/Keyword/Keyword.vue'
  import Tabs from '../Tabs/Tabs.vue'
  import CompanyMixin from '../../util/CompanyMixin'
  import numeral from 'numeral'
  import Badge from '../../components/Badge/Badge.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { ACTION_TYPES as ACTORS_ACTION_TYPES, MUTATION_TYPES as ACTORS_MUTATION_TYPES } from '../../store/modules/actors'
  import KEYCODES from '../../constants/keycodes'
  import MODAL_IDS from '../../constants/modal-ids'
  import ICONS from '../../../icons/manifest.json'

  import { fetchSignificantChanges } from '../../api/heartbeat.js'
  import { removeActor } from '../../api/actors'

  import { toDateString, toOnlyMonthString } from '../../util/date'
  import { _intersect, inert } from '../../util/helpers'
  import { toAmount } from '../../util/currency'

  export default {
    name: 'actor-scores-panel',
    data () {
      return {
        ICONS,
        hasCompetitionScores: false,
        significantChange: {
          'actor_id': null,
          'message': null,
        },
      }
    },
    props: {
      actorId: String,
      isPreview: Boolean,
    },
    computed: {
      isLoading () {
        return !!this.$store.state.actors.detail.loading
      },
      showContactButton () {
        return !this.$store.getters.isPrivate && $store.getters.canActorsBeContacted == true && this.company.claimed == true
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      isProduct () {
        return this.company && this.company.actor_type === 'Product'
      },
      company () {
        if (this.isPreview) {
          return this.$store.getters.previewedActor
        }

        return this.$store.getters.detailActor
      },
      isMember () {
        return this.$store.getters.isMember
      },
      hasScores () {
        return this.$store.getters.hasScores
      },
      watchReports () {
        return this.company.id && this.company.current_report && this.company.current_report.length > 0
      },
      showContactDetails () {
        return this.company.twitter || this.company.facebook || this.company.linkedin || this.company.vimeo || this.company.youtube || this.company.instagram || this.company.url || this.company.crunchbase
      },
      companyScoresAll () {
        const actor = this.company

        if (actor !== undefined) {
          if (actor.won_lost_mentions_history) {
            return actor.won_lost_mentions_history || []
          }
        }
      },
      companyScoresDates () {
        if (this.companyScoresAll) {
          return this.companyScoresAll.map(c => Date.parse(c.date))
        }
      },
      companyScoresTable () {
        const scores = ['mentions', 'losses', 'wins', 'churned']
        const scoreMapping = {
          churned: 'Customer lost',
          losses: 'Deals lost',
          mentions: 'Mentioned in deals',
          wins: 'Deals won',
        }

        if (this.companyScoresAll && this.companyScoresAll.length > 0) {
          return scores.map(score => ({
            score: scoreMapping[score],
            values: this.companyScoresAll.map(s => s[score]),
          }))
        } else {
          this.hasCompetitionScores = false
        }
      },
      coverStyling () {
        if (this.featuredImage) {
          return { backgroundImage: `url(${this.featuredImage})` }
        }

        if (this.websiteScreenshot === this.getDefaultFallbackImageUrl()) {
          return { display: 'none' }
        }

        return { backgroundImage: `url(${this.websiteScreenshot})` }
      },
      newsImageStyle () {
        if (!this.company.news) {
          return
        }

        if (this.company.news[0].screenshot) {
          // return { backgroundImage: `url(${this.company.news[0].screenshot})` }
        }

        if (this.company.website_screenshot) {
          return { backgroundImage: `url(${this.company.website_screenshot})` }
        }

        if (this.company.logo) {
          return { backgroundImage: `url(${this.company.logo})` }
        }

        if (this.companyLogoUrl) {
          return { backgroundImage: `url(${this.companyLogoUrl})` }
        }
      },
      latestActorNewsList () {
        if (!this.company.news || !this.company.news[0]) {
          return
        }

        return this.company.news
      },
      latestActorNewsItem () {
        if (!this.company.news || !this.company.news[0]) {
          return
        }
        return this.company.news[0]
      },
      latestNewsDate () {
        if (!this.latestActorNewsItem || !this.latestActorNewsItem.date) {
          return
        }

        return toDateString(this.latestActorNewsItem.date)
      },
      latestNewsTitle () {
        if (!this.latestActorNewsItem || !this.latestActorNewsItem.title) {
          return
        }
        return this.latestActorNewsItem.title
      },
      latestNewsPublisher () {
        if (!this.latestActorNewsItem || !this.latestActorNewsItem.publisher) {
          return
        }
        return this.latestActorNewsItem.publisher
      },
      showCompanyInfo () {
        return this.company &&
          (this.company.employees &&
            this.company.employees.length > 0) ||
          (this.company.financials &&
            this.company.financials.length > 0)
      },
      showCompanyFunding () {
        return this.company && (
          this.company.total_funding > 0 ||
          (this.company.funding_rounds && this.company.funding_rounds.length > 0)
        )
      },
      companyEmployees () {
        if (!this.company.employees || this.company.employees.length == 0) {
          return 'Undisclosed '
        }

        var employeeCount = 0
        const currentEmployees = this.company.current_employees

        if (currentEmployees && currentEmployees.length > 0) {
          employeeCount = currentEmployees[0].upper_bound// (currentEmployees[0].lower_bound + currentEmployees[0].upper_bound) / 2;
        } else {
          employeeCount = this.company.employees[0].upper_bound
        }

        if (employeeCount > 10000) {
          return '10000+'
        }

        return '~ ' + employeeCount
      },
      getNumberOfRounds () {
        if (this.company.funding_rounds) {
          return this.company.funding_rounds.length
        }

        return 0
      },
      financialMetric () {
        if (!this.company.financials) return 'Undisclosed'

        var financials = this.company.financials.slice()
        financials.sort((a, b) => a.end_period < b.end_period)

        const lastYear = financials[0]

        if (!lastYear || (!lastYear.operating_income && !lastYear.turnover)) return 'Undisclosed'

        if (lastYear.operating_income) {
          return `${numeral(lastYear.operating_income).format('$0.00a')}`
        }

        if (lastYear.turnover) {
          return `${numeral(lastYear.turnover).format('$0.00a')}`
        }

        return 'Undisclosed'
      },
      financialMetricName () {
        if (!this.company.financials) return 'Revenue'

        var financials = this.company.financials.slice()
        financials.sort((a, b) => a.end_period < b.end_period)

        const lastYear = financials[0]

        if (!lastYear || (!lastYear.operating_income && !lastYear.turnover)) return 'Revenue'

        if (lastYear.operating_income) {
          return 'Revenue'
        }

        if (lastYear.turnover) {
          return 'Turnover'
        }

        return ''
      },
      getTotalFunding () {
        if (this.company.total_funding) {
          return toAmount(this.company.total_funding, 5)
        }
      },
      userCanDelete () {
        return this.$store.getters.isMember || this.userIsActorOwner || (this.$store.getters.hasAccessToPortfolioSharing && this.$store.getters.isPortfolioMember && _intersect(this.$store.getters.userContributionPortfolios, this.actorPortfolios).length > 0)
      },
      userIsActorOwner () {
        if (this.$store.getters.isActor && this.$store.getters.userClaimedIds && this.$store.getters.userClaimedIds.length > 0) {
          const claim = this.$store.getters.userClaimedIds.find(claim => claim.id === this.company.id) || {}

          return claim.claim_type && claim.claim_type === 'actor-owner' && claim.claim_verified
        }

        return this.$store.getters.userClaimedIds.includes(this.company.id)
      },
      claimedBadgeLabel () {
        if (this.userIsActorOwner) {
          return 'Claimed by you'
        }

        return 'Claimed'
      },
      actorEmbeddedVideo () {
        return this.company.embedded_video_url
      },
    },
    methods: {
      toOnlyMonthString,
      hidePreview (evt) {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      redirectTo (destination) {
        if (!this.company || !this.company.id) {
          return
        }

        if (destination == '#scores') {
          return '/actors/' + this.company.id + '/scores'
        } else {
          return '/actors/' + this.company.id + destination
        }
      },
      showClaimModal () {
        this.$store.commit(ACTORS_MUTATION_TYPES.SET_ACTOR_FOR_PREVIEW, this.company)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CLAIM)
      },
      showSignUpModal () {
        // this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.SIGN_UP)
        if (this.$store.state.config.allowAdd) {
          if (this.$store.state.config.onboardingEnabled) {
            this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ONBOARDING)
          } else {
            this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ACTOR_SIGN_UP)
          }
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.SIGN_UP)
        }
      },
      showContactModal () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CONTACT)
      },
      showVideoModal (embeddedVideo) {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, { type: 'video', actorName: this.company.name, url: embeddedVideo })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ACTOR_VIDEO)
      },
      showEditPanel () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'actor-edit', metaData: { id: this.company.id }})
      },
      goToNext () {
        const { data } = this.$store.state.actors.listData
        const next = 1 + data.findIndex(a => a.id === this.company.id)

        if (data[next]) {
          // The next actor is cached
          this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'scores', metaData: data[next].id })

          return
        }

        if (this.$store.state.filters.paging.offset < this.$store.getters.lastPageOffset) {
          // Fetch next page
          this.$store.commit('FILTERS/NEXT_PAGE')
        }

        return this.$store.dispatch('ACTORS/FETCH_ACTORS_LIST_NOW', this.$store.getters.listFilterObject)
          .then(() => {
            const { data } = this.$store.state.actors.listData
          })
      },
      showDeleteConfirmationModal (company) {
        var modalContextData = Object.assign(company, { modalContextType: 'actor' })

        this.$store.commit('UI/SET_MODAL_CONTEXT', modalContextData)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
      },
      denyActorRecommendation () {
        removeActor(this.company.id)
          .then(data => {
            if (!data.deleted) {
              throw new Error(data)
            }

            this.hidePreview()

            if (this.$route.path.startsWith('/actors/') && this.$route.params.id && this.company.id !== this.$route.params.id) {
              this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_DETAIL, this.$route.params.id)
            } else {
              this.$store.commit('ACTORS/FLUSH_CACHE')
            }

            var listFilters = inert(this.$store.getters.listFilterObject)
            this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_LIST, listFilters)

            const filters = this.$store.getters.mapFilterObject

            if (filters.tl) {
              this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_MAP, filters)
            }
          })
          .catch(errors => {
            console.warn('ActorEdit failed to delete actor', this.company.id, errors)
          })
      },
    },
    created () {
      this.$bus.on('actorDeleteConfirmation', (context) => {
        // Be absolutely sure that we remove the actor that we just got confirmation for to delete
        if (context.id && context.id == this.company.id) {
          this.denyActorRecommendation()
        }
      })

      window.addEventListener('keyup', (e) => {
        if (e.keyCode === KEYCODES.ESC) {
          this.hidePreview()
        }
      })

      if (!this.isPreview) {
        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_DETAIL, this.actorId)
      } else {
        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_FOR_PREVIEW, { id: this.actorId })
      }

      if (['pro', 'agency', 'standard'].includes(this.$store.getters.packageType) && this.isMember) {
        fetchSignificantChanges(this.actorId).then(data => {
          this.significantChange = {
            'actor_id': null,
            'message': null,
          }

          if (data.data && data.data.length > 0) {
            this.significantChange = data.data[0]
          }
        })
      }
    },
    beforeUnmount () {
      this.$bus.off('actorDeleteConfirmation')
    },
    watch: {
      actorId (val) {
        if (!this.isPreview) {
          this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_DETAIL, this.actorId)
        } else {
          this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_FOR_PREVIEW, { id: this.actorId })
        }

        if (['pro', 'agency', 'standard'].includes(this.$store.getters.packageType) && this.isMember) {
          // Refresh the significant change
          fetchSignificantChanges(this.actorId).then(data => {
            this.significantChange = {
              'actor_id': null,
              'message': null,
            }

            if (data.data && data.data.length > 0) {
              this.significantChange = data.data[0]
            }
          })
        }
      },
      companyScoresTable (val) {
        if (val) {
          this.hasCompetitionScores = true
        }
      },
    },
    mixins: [CompanyMixin],
    components: {
      ActorUrls,
      Avatar,
      Card,
      CardInfoSocial,
      Keyword,
      Tabs,
      Badge,
    },
  }
</script>
