<template>
  <div>
    <h3 class="h2">API tokens</h3>
    <div class="guide_text">
      Create or revoke API tokens that can be used to allow external clients to create, update and fetch actors from this ecosystem. API documentation can be accessed via <a target="_blank" href="https://public-api-docs.datascouts.eu/">this link</a>.
    </div>
    <br>
    <table class="table table--has-button">
      <thead>
        <tr>
          <th>Name</th>
          <th width="300px;">Token</th>
          <th>Access to</th>
          <th width="150px;">Created at</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="token in tokens">
          <td>{{ token.name }}</td>
          <td>{{ token.token }}</td>
          <td>{{ token.portfolios.join(', ') }}</td>
          <td>{{ toDatetimeString(token.created_at) }}</td>
          <td>
            <ds-button icon="trash" @click="remove(token.token)" />
          </td>
        </tr>
      </tbody>
    </table>

    <br>
    <br>

    <form class="panel panel-default" v-if="showAdd" @submit.prevent="add">
        <h3 class="h2">Create API token</h3>
        <form-group label="Name of the token" errors="name" :errors="errors.name" >
          <ds-input v-model="form.name" />
        </form-group>
        <ds-button variant="secondary" type="submit" :icon="adding ? 'spinner' : 'plus'" label="Create token" :disabled="adding"/>
        <ds-button variant="outline" label="Cancel" @click="showAdd = false" :disabled="adding"/>
      </form>
      <ds-button v-else icon="plus" label="Create a new token" @click="createToken" />
    </div>
</template>

<script>

import Dropdown from '../Dropdown/Dropdown.vue'

import { ApiTokens } from '../../api/config.js'

import { toDatetimeString } from '../../util/date'

export default {
  name: 'api-tokens',
  data () {
    return {
      tokens: [],
      // Form variables
      showAdd: false,
      adding: false,
      form : {
        name: "", // The form might see other properties later on
      },
      errors: {}
    }
  },
  methods: {
    fetch () {
      ApiTokens.get()
        .then(tokens => { this.tokens = tokens })
        .catch(errors => {})
    },
    add () {
      ApiTokens.post(this.form)
        .then(token => {
          // Reset the form fields
          this.showAdd = false
          this.form.name = ""

          this.fetch()
        })
        .catch(errors => { this.errors = errors })
    },
    remove (tokenName) {
      ApiTokens.delete(tokenName)
        .then(response => {
          this.fetch()
        })
        .catch()
    },
    createToken () {
      this.showAdd = true
    },
    toDatetimeString
  },
  created () {
    this.fetch()
  },
  components: {
    Dropdown,
  }
}
</script>
