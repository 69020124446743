<template>
  <div class="authentication-modal package-sign-up__credentials-step">
       <div class="row" style="margin-top: 15px;">
         <div class="col-xs-12" :class="allowAdd ? 'col-sm-6' : 'col-sm-12'">
           <h3 class="h3">Provide your contact information</h3>

           <form class="form personal-payment-info__form" @submit.prevent="register">
             <fieldset>
               <div class="form-group" :class="{ error: errors.name.length}">
                 <label class="label" for="register-name">Your name</label>
                 <input class="input" type="text" placeholder="John Doe"
                        id="register-name" v-model="name" @input="errors.name=[]"
                        autofocus>
                 <p v-for="msg in errors.name" v-text="msg" class="form-msg error"></p>
               </div>

               <div class="form-group" :class="{ error: errors.email.length}">
                 <label class="label" for="register-email">Your email address</label>
                 <input class="input" type="email" placeholder="john@example.com"
                        id="register-email" v-model="email" @input="errors.email=[]">
                 <p v-for="msg in errors.email" v-text="msg" class="form-msg error"></p>
               </div>

               <div class="form-group">
                 <label class="label" for="register-password">Password</label>
                 <input class="input" type="password"
                        placeholder="Choose a strong password" id="register-password"
                        v-model="password" @input="errors.password=[]">
               </div>

               <div class="pw-strength" :class="'pw-strength' + passwordScore">
                 <div class="pw-strength-line"></div>
               </div>

               <div class="form-group">
                 <input class="input" type="password" placeholder="Confirm your password"
                        v-model="passwordConfirmation">
                 <p v-for="msg in errors.password" v-text="msg"
                    class="form-msg error"></p>
               </div>

               <div class="form-group">
                 <input type="checkbox" v-model="acceptedTerms">
                   <span>&nbsp;I agree to the
                     <a :href="termsOfUseUrl" target="_blank">Terms of Use</a>
                       {{$t('and')}}
                     <a :href="privacyPolicyUrl" target="_blank">
                       {{$t('privacy_policy')}}
                     </a>
                   </span>
                 <p v-if="errors.accepted_terms.length > 0" class="form-msg error">
                   You need to accept the terms in order to sign up.
                 </p>
               </div>
             </fieldset>

             <p v-if="errors.message" class="form-group__error"
                v-text="errors.message"></p>
             <p v-if="signupDone" class="form-msg success">
               <template v-if="$store.getters.allowCuratedAdd && ! invitationCompleted">
                 Before you can log into this ecosystem, you will have to confirm your
                 email. We have sent you a confirmation email which should arrive shortly.<br>
                 If the email doesn’t appear, please check your spam / junk mail
                 folder<br>
                 After you have confirmed your email address, you still have to be
                 verified by an admin, because this is a private ecosystem.
               </template>
               <template v-else-if="invitationCompleted">
                 Your registration was successful, please log in.
               </template>
               <template v-else>
                 Your registration was successful. We have sent you an activation code,
                 please check your email.
                 <br>
                 If the email doesn't appear shortly, please check your spam / junk mail
                 folder.
               </template>
             </p>
             <fieldset class="action-buttons">
               <ds-button id="card-button" type="submit" variant="secondary"
                          icon="chevron-right" label="Sign Up" :disabled="signingUp"
                          v-if="! signupDone"/>
               <ds-button @click="openLoginModal" variant="secondary" icon="chevron-right"
                          label="Log in" v-if="signupDone && invitationCompleted"/>
             </fieldset>
           </form>
           <ul class="links" v-if="! invitationCompleted">
             <li><a href="#" @click.prevent="openLoginModal">Log In</a></li>
           </ul>
         </div>
         <div class="col-xs-12 col-sm-6" v-if="allowAdd">
           <fieldset>
             <h3>
               <checkbox class="checkbox-inline" :label="' ' + $t('signup_add_company_cta')" id="include-company" v-model="includeCompany" :model-value="includeCompany" style="margin-top: 0"/>
             </h3>

             <div class="form-group" :class="{ error: errors.company_name.length}">
               <label class="label" for="company-name">{{ $t('signup_company_name') }}</label>
               <input class="input" type="text" placeholder="DataScouts" id="company-name" v-model="company_name" @input="errors.company_name=[]" :disabled="! includeCompany">
               <p v-for="msg in errors.company_name" v-text="msg" class="form-msg error"></p>
             </div>

             <div class="form-group" :class="{ error: errors.company_url.length}">
               <label class="label" for="company-url">{{ $t('signup_company_url') }}</label>
               <input class="input" type="text" placeholder="https://datascouts.eu" id="company-url" v-model="company_url" @input="errors.company_url=[]" :disabled="! includeCompany">
               <p v-for="msg in errors.company_url" v-text="msg" class="form-msg error"></p>
             </div>

             <div class="form-group" :label="$t('signup_company_country')">
               <label class="label" for="company-country">{{ $t('signup_company_country') }}</label>
               <country-dropdown id="company-country" @update:modelValue="handleCountryChange" :model-value="jurisdiction" :disabled="! includeCompany"/>
             </div>

             <div class="form-group" :errors="errors.company_number ? errors.company_number : errors.jurisdiction ? errors.jurisdiction : null">
               <label class="label" for="company-number">{{ $t('signup_company_number') }}</label>
               <div class="company_number">
                 <dropdown :options="jurisdictionOptions" v-model="jurisdiction" width="50px" :search="true" :disabled="! includeCompany"/>
                 <ds-input placeholder="0650.599.388" name="actor-company-number" v-model="company_number" :disabled="! includeCompany"/>
               </div>
               <p v-for="msg in errors.jurisdiction" v-text="msg" class="form-msg error"></p>
               <p v-for="msg in errors.company_number" v-text="msg" class="form-msg error"></p>
             </div>
           </fieldset>
         </div>
       </div>
  </div>
</template>

<script>
  import { registerRequest } from '../../../api/user'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui'
  import MODAL_IDS from '../../../constants/modal-ids'
  import { loadChunk } from '../../../util/chunk-loader'
  import CountryDropdown from '../../Dropdown/CountryDropdown.vue'
  import { COUNTRIES } from '../../../constants/countries.js'
  import Checkbox from '../../Form/Checkbox.vue'
  import Dropdown from '../../Dropdown/Dropdown.vue'
  import { DEFAULT_URLS } from '../../../constants/default-urls.js'

  export default {
    name: 'PersonalAndPaymentInformation.vue',
    props: {
      chosenPackage: Object,
      paymentMethodId: String,
      useTrial: Boolean,
      couponCode: String,
      billingInfo: Object,
    },
    data () {
      return {
        // User information
        name: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        passwordScore: 0,
        acceptedTerms: false,
        invitationCompleted: false,
        includeCompany: true,
        company_name: '',
        company_url: '',
        company_number: '',
        jurisdiction: '',
        company_country: '',
        // Errors
        errors: {
          name: [],
          email: [],
          password: [],
          message: null,
          company_name: [],
          company_url: [],
          company_number: [],
          company_country: null,
          jurisdiction: null,
          accepted_terms: [],
        },
        signupDone: false,
        signingUp: false,
      }
    },
    computed: {
      jurisdictionOptions () {
        // Filter out the UK, it's not considered a legal jurisdiction
        return Object.keys(COUNTRIES).filter(countryCode => countryCode !== 'UK')
      },
      allowAdd () {
        return this.$store.state.config.allowAdd
      },
      config () {
        return this.$store.state.config
      },
      termsOfUseUrl () {
        return this.config.termsOfUseUrl || DEFAULT_URLS.TERMS_OF_USE
      },
      privacyPolicyUrl () {
        return this.config.privacyPolicyUrl || DEFAULT_URLS.PRIVACY_POLICY
      },
    },
    methods: {
      handleCountryChange (country) {
        this.jurisdiction = country.code
        this.company_country = country.name
      },
      async register () {
        this.signingUp = true

        this.performValidation()

        if (this.errors.name.length ||
        this.errors.email.length ||
        this.errors.password.length ||
        this.passwordScore < 2
        ) {
          this.signingUp = false
          return
        }

        let companyUrl = this.company_url
        if (!companyUrl.startsWith('https://') && !companyUrl.startsWith('http://')) {
          companyUrl = 'https://' + companyUrl
        }

        const data = {
          name: this.name,
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          invitationToken: window.invitationToken,
          accepted_terms: this.acceptedTerms,
          chosenPackage: this.chosenPackage.id,
          paymentMethodId: this.paymentMethodId,
          useTrial: this.useTrial,
          promoCode: this.couponCode,
          includeCompany: this.includeCompany,
          company_name: this.company_name,
          company_url: companyUrl,
          company_number: this.company_number,
          jurisdiction: this.jurisdiction,
        }

        if (this.billingInfo) {
          data.billingInfo = this.billingInfo
        }

        registerRequest(data)
          .then(data => {
            this.signingUp = false

            if (data.success && data.redirect_uri) {
              window.location.href = window.redirect_uri
            }

            this.signupDone = data.success

            if (data.invitationCompleted) {
              this.invitationCompleted = data.invitationCompleted
            }

            window.heap && window.heap.track('signUp', { name: this.name, email: this.email })
          })
          .catch(err => {
            this.signingUp = false

            if (err.email) {
              this.errors.email = [err.email]
            }

            if (err.company_name) {
              this.errors.company_name = err.company_name

              if (!Array.isArray(this.errors.company_name)) {
                this.errors.company_name = [err.company_name]
              }
            }

            if (err.company_url) {
              this.errors.company_url = err.company_url

              if (!Array.isArray(this.errors.company_url)) {
                this.errors.company_url = [err.company_url]
              }
            }

            if (err.accepted_terms) {
              this.errors.accepted_terms = [err.accepted_terms]
            }
          })
      },
      performValidation () {
        if (!this.name) {
          this.errors.name = ['The name field is required.']
        }

        if (!this.email) {
          this.errors.email = ['The email field is required.']
        }

        if (!this.password) {
          this.errors.password = ['The password field is required.']
        } else if (this.password.length < 6) {
          this.errors.password = [`The password must be at least 6 characters.`]
        } else if (this.passwordScore < 2) {
          this.errors.password = [`The password must be stronger.`]
        } else if (!this.passwordConfirmation) {
          this.errors.password = ['Please confirm your password.']
        } else if (this.password !== this.passwordConfirmation) {
          this.errors.password = [`Passwords don't match.`]
        }

        if (!this.paymentMethod || !this.paymentMethod.id) {
          this.paymentMethodError = 'Please provide your payment credentials'
        }
      },
      openLoginModal () {
        if (this.$route.name === 'sign-up') {
          this.$router.push('/login')
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
        }

        this.$router.push({
          query: { email: this.email },
        })
      },
    },
    mounted () {
      this.includeCompany = this.allowAdd
      if (this.allowAdd) {
        // If the setting provides a default country, then set the jurisdiction to that country
        if (this.$store.state.config && this.$store.state.config.signUpDefaultCountry) {
          this.jurisdiction = this.$store.state.config.signUpDefaultCountry
        }
      }
    },
    watch: {
      password (password) {
        return loadChunk('zxcvbn', () => {
          const score = window.zxcvbn(password).score

          if (password.length < 6 && score > 2) {
            this.passwordScore = 2
          } else {
            this.passwordScore = score
          }
        })
      },
    },
    components: {
      CountryDropdown,
      Dropdown,
      Checkbox,
    }
  }
</script>

<style scoped lang="scss">
  .package-sign-up__credentials-step {
    min-width: 350px;
  }

  .personal-payment-info__container {
    display: flex;
  }

  .personal-payment-info__cc-information {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
  }

  .personal-payment-info__form {
    input {
      max-width: 300px;
    }

    .pw-strength {
      width: 300px;
    }
  }
</style>
