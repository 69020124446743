<template>
  <div class="social-auth-buttons">
    <p>
      <template v-if="disabled">
        <!--<div class="button--twitter button--twitter-disabled">
          <icon name="twitter-logo"></icon>
          <span class="text">{{method}} Twitter</span>
        </div>-->
        <div class="button--facebook button--facebook-disabled">
          <icon name="facebook-logo"></icon>
          <span class="text">{{ method }} Facebook</span>
        </div>
        <div class="button--linkedin button--linkedin-disabled">
          <icon name="linkedin-logo"></icon>
          <span class="text">{{ method }} LinkedIn</span>
        </div>
      </template>
      <template v-else>
        <!--<a href="/auth/twitter" class="button--twitter">
          <icon name="twitter-logo"></icon>
          <span class="text">{{method}} Twitter</span>
        </a>-->

        <a href="/auth/facebook" @click="logMatomoEvent('facebook')" class="button--facebook">
          <icon name="facebook-logo"></icon>
          <span class="text">{{ method }} Facebook</span>
        </a>

        <a href="/auth/linkedin" @click="logMatomoEvent('linkedin')" class="button--linkedin">
          <icon name="linkedin-logo"></icon>
          <span class="text">{{ method }} LinkedIn</span>
        </a>
      </template>
    </p>
  </div>
</template>

<script>
  import { trackMatomoEvent } from '@/util/analytics.js'
  import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '@/constants/analytics-constants.js'

  export default {
    name: 'social-auth-buttons',
    props: {
      method: {
        type: String,
        default: 'Sign In'
      },
      disabled: {
        type: Boolean,
        defaultL: false
      }
    },
    methods: {
      logMatomoEvent (social) {
        trackMatomoEvent(MATOMO_EVENT_CATEGORIES.ONBOARDING, MATOMO_EVENT_ACTIONS.ONBOARDING_STEPS_SOCIAL_LOGIN, social)
      }
    }
  }
</script>
