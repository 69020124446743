<template>
  <div class="explore-frame">
    <div class="explore-content">
      <div class="explore__control-bar">
        <div>
          <h2 class="explore__control-bar__title">
            {{ title }}
          </h2>
          <p class="guide_text">Explore actors by clicking the top right filter button and enter keywords or use the
            drop downs to filter the directory of actors.</p>
        </div>
        <div class="explore__control-bar__controls">
          <form-group class="explore__controls__actor-types"
                      :style="{ width: ((actorTypes.length === 4 && !isEcosystemMember) ? 270 : 320) + 'px' }">
            <template v-for="(actorType, index) in actorTypes" :key="index">
              <checkbox
                  inline
                  multiple
                  :label="actorType.name"
                  :val="actorType.value"
                  :model-value="activeActorTypes"
                  @update:modelValue="updateActiveActorTypes"
              />
              <br v-if="index === 1 && actorTypes.length === 4 && !isEcosystemMember">
            </template>
          </form-group>
        </div>
        <div class="explore-content__filter-button-container"
             v-if="config && config.filterControls && config.filterControls.length > 0">
          <div class="explore-content__filter-indicator" v-if="filtersActive"></div>
          <ds-button variant="minimal" size="small" label="Filter" icon="filter-box"
                     class="explore-content__filter-button"
                     :class="{ 'explore-content__filter-button-active' : this.$store.state.filters.filter.visible }"
                     @click="toggleFilter"/>
        </div>
        <badge :name="total + ' Actors'" variant="primary" class="score-overview__actor-couter"/>
      </div>

      <top-filters @showGlobalSearch="this.$emit('showGlobalSearch')"/>

      <div class="explore-content__panes" ref="content_panes">
        <!--<div style="position: absolute">
          <legend-dropdown style="position: relative; z-index: 107;" tooltipText="" :style="legendBoxCoordinatesStyling" :draggable="onDragged"/>
        </div>-->

        <div ref="explore_pane_left" class="explore-pane explore-pane--left"
             :class="leftPanelMode ? 'explore-pane--shown' : 'explore-pane--hidden'">
          <map-view v-if="leftPanelMode === MODES.MAP"
                    clusterPosition="bottomleft"
                    searchWhileMovingPosition="bottomleft"
                    :data="mapData.data"
                    :get-map-data="getMapData"
                    ref="mapPane"
                    :mapEnabled="true"
          />
        </div>
        <div class="explore-pane explore-pane--right"
             :class="rightPanelMode ? 'explore-pane--shown' : 'explore-pane--hidden'">
          <div class="explore-pane__body">
            <sortable-list-view v-if="rightPanelMode === MODES.LIST" :actors="listData.data" @detail="goToActorDetail"
                                :user-columns="userColumns"/>
            <gallery-view v-if="rightPanelMode === MODES.GALLERY" :actors="listData.data" @detail="goToActorDetail"/>
          </div>
          <div class="explore-pane__footer">
            <page-items v-if="rightPanelMode" :paging="listData.paging"></page-items>
            <a v-if="$store.getters.isMember || $store.getters.isPortfolioMember"
               class="column-toggle a"
               @click="show.columns = !show.columns">
              Columns
            </a>
            <pagination v-if="rightPanelMode" :paging="listData.paging"/>
          </div>
        </div>
      </div>
    </div>

    <modal title="VISIBLE COLUMNS"
           v-if="show.columns"
           close-on-blur
           closeable
           @close="show.columns = false">
      <template v-slot:body>
        <div>
          <p class="guide_text">Drag the properties you like to display from the left to the right list. The order of
            the properties in the list of “Visible columns” defines the
            order of the columns.</p>
          <draggable-column-picker v-model="userColumns" :options="availableColumnOptions"/>
        </div>
      </template>
    </modal>
    <hover-overlay-button v-if="canAddActors" class="application-plus-btn" @openPanel="goToCreatePage"
                          label="Add Actor"
                          :children="overlayButtonChildren"
                          orientation="right"></hover-overlay-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// require styles
import 'swiper/swiper.min.css'
import SwiperCore, { Virtual } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
// For the drag and drop functionality of the legend
import _intersection from 'lodash/intersection.js'

import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../store/modules/actors.js'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../store/modules/ui.js'
import { MUTATION_TYPES as FILTER_MUTATION_TYPES } from '../store/modules/filters.js'

import DraggableColumnPicker from '../components/Form/DraggableColumnPicker.vue'
import TopFilters from '../components/Filters/TopFilters.vue'
import GalleryView from '../components/GalleryView/GalleryView.vue'
import Keyword from '../components/Keyword/Keyword.vue'
import SortableListView from '../components/ListView/SortableListView.vue'
import MapView from '../components/MapView/MapView.vue'
import Modal from '../components/Modals/Modal.vue'
import PageItems from '../components/PageItems/PageItems.vue'
import Pagination from '../components/Pagination/Pagination.vue'
import Badge from '../components/Badge/Badge.vue'
import FormGroup from '../components/Form/FormGroup.vue'
import Checkbox from '../components/Form/Checkbox.vue'
import LegendDropdown from '../components/Filters/LegendDropdown.vue'

import { countryFocus } from '../components/Filters/Filters.js'
import { viewListOptions } from '../constants/config.js'
import { getReportFieldByReportIdentifier, getReportFieldsByTypes, inert, isIE11 } from '../util/helpers.js'

import TranslationsMixin from '../util/TranslationsMixin.js'
import HoverOverlayButton from '../components/Overlay/HoverOverlayButton.vue'
import MODAL_IDS from '../constants/modal-ids.js'

SwiperCore.use([Virtual])
const MODES = {
  LIST: 'LIST',
  GALLERY: 'GALLERY',
  MAP: 'MAP',
  GRAPH: 'GRAPH',
}

const MAX_WINDOW_WIDTH_FOR_DOUBLE_PANEL_LAYOUT = 1040
export default defineComponent({
  name: 'Explore',
  components: {
    DraggableColumnPicker,
    TopFilters,
    GalleryView,
    Keyword,
    SortableListView,
    MapView,
    Modal,
    PageItems,
    Pagination,
    Badge,
    FormGroup,
    Checkbox,
    Swiper,
    SwiperSlide,
    LegendDropdown,
    HoverOverlayButton,
  },
  data() {
    return {
      show: {
        columns: false,
        double: this.doublePanelCheck(),
      },
      selectedActorType: [],
      showFilters: false,
      MODES,
      carrouselActors: [],
      swiperOption: {
        direction: 'vertical',
        slidesPerView: 4,
        spaceBetween: 30,
        slidesPerGroup: 1,
        allowTouchMove: false,
        loop: true,
        loopFillGroupWithBlank: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      legendBoxDefaultPosition: {},
    }
  },
  computed: {
    canAddActors() {
      return this.isLoggedIn && (this.isOwner || this.isMember)
    },
    canCreatePortfolios() {
      return this.isLoggedIn && (this.isOwner || this.isMember)// && this.$store.getters.views && this.$store.getters.views.includes('portfolios')));
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    isMember() {
      return this.$store.getters.isMember
    },
    overlayButtonChildren() {
      if ((this.$route.name === 'explore' || this.$route.name === 'portfolios') && this.canCreatePortfolios) {
        return [
          { tagText: 'Create Portfolio', redirection: 'create-portfolio', icon: 'edit' },
        ]
      } else {
        return []
      }
    },
    actorTypes() {
      var types = [
        { name: 'Organizations', value: 'LegalEntity' },
        { name: 'People', value: 'Person' },
        { name: this.productsLabel, value: 'Product' },
      ]

      // Keep only the ones that are allowed
      var r = types.filter((type) => this.$store.getters.viewActorTypes.includes(type.value))
      return r
    },
    activeActorTypes() {
      // Take a shallow copy of the state values as the checkbox component will change the value we pass to it
      // which is not allowed, vuex state can only be changed within the scope of the state
      return this.$store.state.filters.actorTypes.slice(0)
    },
    config() {
      return this.$store.state.config
    },
    keywords() {
      return this.$store.state.filters.keywords
    },
    isLoading() {
      return this.$store.state.actors.loading
    },
    leftPanelMode() {
      return this.enabledViews.includes(MODES.MAP) && !this.isIE11() ? MODES.MAP : false
    },
    rightPanelMode() {
      if (!this.show.double && this.leftPanelMode) {
        return false
      }

      return this.enabledViews.includes(MODES.LIST) ? MODES.LIST : this.enabledViews.includes(MODES.GALLERY) ? MODES.GALLERY : false
    },
    enabledViews() {
      var enabled = _intersection(this.viewDirectory, this.configuredViews, this.possibleViews)

      if (this.isIE11()) {
        enabled = enabled.filter(view => view !== 'MAP')
      }

      // If all views are disabled, return a default one based on the possible views the user can choose from
      var possibleViews = this.configuredViews.filter(r => r != false)

      var defaultView = MODES.GALLERY

      if (possibleViews && possibleViews.length > 0) {
        defaultView = possibleViews[0]
      }

      return enabled.length ? enabled : [defaultView]
    },
    configuredViews() {
      var views = [
        this.config.viewGallery && MODES.GALLERY,
        this.config.viewMap && MODES.MAP,
        this.config.viewList && MODES.LIST,
      ]

      if (this.isIE11()) {
        views = views.filter(view => view != 'MAP')
      }

      return views
    },
    possibleViews() {
      return this.configuredViews
    },
    isEcosystemMember() {
      return this.$store.getters.isActor
    },
    viewDirectory: {
      get() {
        var views = (this.$store.getters.userSettings.viewDirectory || '')
            .split(',')
            .filter(Boolean)

        if (this.isIE11()) {
          views = views.filter(view => view !== 'MAP')
        }

        return views.length ? views : this.configuredViews
      },
      set(v) {
        this.$store.commit('USER/STORE_SETTINGS', { viewDirectory: v.join(',') || MODES.LIST })
      },
    },
    activeActorType() {
      return this.$store.state.filters.actor_type
    },
    mapData() {
      return this.$store.state.actors.mapData
    },
    listData() {
      return this.$store.state.actors.listData
    },
    presentationModeData() {
      /* return this.listData.data.map(actor => {
        return {name: actor.name, logo: actor.logo, id: actor.id}
      }) */
    },
    total() {
      return this.listData.total || 0
    },
    filters() {
      return this.$store.state.filters
    },
    legendBoxProperties() {
      // We check first if there is values in the state belonging to the 'explore'
      // Otherwise, in the case of the user refreshing the browser, we fetch the value saved in the user settings
      // Simply fetching directly from the state wont work as the state can contain objects from other legend boxes
      // resulting in the reset of the position of the other legend boxes, so we search only for the values for the 'explore'
      var legendPropertiesArray = []
      if (this.$store.getters.legendBoxProperties.length > 0) {
        this.$store.getters.legendBoxProperties.forEach((item, index) => {
          if (item.name == 'explore') {
            legendPropertiesArray.push(item)
          }
        })
      }
      return legendPropertiesArray.length > 0 ? legendPropertiesArray : this.$store.getters.userSettings.legendBoxProperties !== undefined ? this.$store.getters.userSettings.legendBoxProperties : []
    },
    exploreLegendBoxProperties() {
      return this.legendBoxProperties && this.legendBoxProperties.filter(legend => legend.name == 'explore')
    },
    legendBoxCoordinatesStyling() {
      // This will set the left and top values for the legend box
      // If there is a value in the state for the coordinates then we apply that value to the styling
      // otherwhise we set the fallback value for the style
      var values = {
        left: '1em',
        top: '1em',
      }
      if (this.exploreLegendBoxProperties.length > 0) {
        // We check first if the coordinates are out of the visible bounds and if so we return values that enable the legend box to be placed in the visible area of the screen
        var checkedCoordinates = this.checkLegendBoxCoordinates(this.exploreLegendBoxProperties[0].coordinates)
        values.left = checkedCoordinates.x + 'px'
        values.top = checkedCoordinates.y + 'px'
      } else if (this.legendBoxDefaultPosition && this.legendBoxDefaultPosition.x) {
        // Setting the horizontal default position
        values.left = this.legendBoxDefaultPosition.x
      } else if (this.legendBoxDefaultPosition && this.legendBoxDefaultPosition.y) {
        // Setting the vertical default position
        values.top = this.legendBoxDefaultPosition.y
      }

      return `left: ${values.left}; top: ${values.top}`
    },
    countryFocus,
    title() {
      return 'DIRECTORY'
    },
    userColumns: {
      get() {
        const blackListedColumns = ['lastFundingRaised', 'is_zombie', 'possible_duplicate']

        var columns = (this.$store.getters.userSettings.viewList || '')
            .split(',')
            .filter(column => !blackListedColumns.includes(column))
            .filter(Boolean)

        // Filter out report fields if knowledgeSharing is not enabled
        if (!this.$store.getters.reportsEnabled) {
          columns = columns
              .filter(column => !column.startsWith('report_field_'))
              .filter(Boolean)
        } else {
          // Make sure no old report fields are in the columns
          columns = columns
              .filter(function (column) {
                if (!column.startsWith('report_field_')) {
                  return true
                }

                var reportLabel = getReportFieldByReportIdentifier(column)
                var reportFieldExists = reportLabel && reportLabel.label && reportLabel.label.length > 0

                return reportFieldExists
              })
              .filter(Boolean)
        }

        var result = columns.length ? columns : this.config.viewList ? this.config.viewList : viewListOptions

        return result
      },
      set(v) {
        this.$store.commit('USER/STORE_SETTINGS', { viewList: v.join(',') })
      },
    },
    filterableRelationships() {
      return this.$store.getters.filterableRelationships
    },
    availableColumnOptions() {
      var options = viewListOptions

      // Only display these options if the modal is displayed on the explore or spreadsheet route
      if (this.$route.name == 'explore' &&
          (this.$store.getters.isOwner || this.$store.getters.isMember) &&
          this.$store.getters.knowledgeSharing
      ) {
        var reportColumns = getReportFieldsByTypes(['number', 'options', 'date'])

        if (reportColumns && reportColumns.length > 0) {
          options = options.concat(reportColumns)
        }
      }

      // Filter out product relationship if products aren't enabled
      if (!this.$store.getters.isProductEnabled) {
        options = options.filter(option => {
          return !['product_relationship_count'].includes(option)
        })
      }

      if (!this.$store.getters.hasCustomScores) {
        options = options.filter(c => c != 'custom_score')
      }

      if (this.$store.getters.isOwner) {
        options = options.concat(this.filterableRelationships)
      }

      // If the we're not in the funding list dashboard, we filter out all possible columns that only belong to the funding list dashboard
      var fundingDashboardColumns = ['total_investment', 'funding_date', 'funding_type', 'funding_amount']

      options = options.filter(column => {
        return !fundingDashboardColumns.includes(column.name)
      })

      return options
    },
    isOwner() {
      return this.$store.getters.isOwner
    },
    reportFiltersActive() {
      if (!this.$store.getters.reportSettings || this.$store.getters.reportSettings.length === 0 || !this.$store.state.filters.reportFilters) {
        return false
      }

      var reportFilterEnabled = false

      for (var key in this.$store.state.filters.reportFilters) {
        if (this.$store.state.filters.reportFilters[key] && this.$store.state.filters.reportFilters[key].length > 0) {
          reportFilterEnabled = true
        }
      }

      return reportFilterEnabled
    },
    filtersActive() {
      return (this.keywords.length > 0 && this.keywords.find((f) => f.facet !== 'actor_type')) ||
          this.$store.state.filters.employeeRange.employeeLowerBound ||
          this.$store.state.filters.employeeRange.employeeUpperBound ||
          this.$store.state.filters.totalFunding.minTotalFunding ||
          this.$store.state.filters.totalFunding.maxTotalFunding ||
          this.$store.state.filters.patentCountRange.patentCountMin ||
          this.$store.state.filters.patentCountRange.patentCountMax ||
          this.$store.state.filters.relationshipCountRange.relationshipCountMin ||
          this.$store.state.filters.relationshipCountRange.relationshipCountMax ||
          this.$store.state.filters.revenueRange.revenueMin ||
          this.$store.state.filters.revenueRange.revenueMax ||
          this.$store.state.filters.dateRanges.foundingDateEnd ||
          this.$store.state.filters.dateRanges.foundingDateStart ||
          this.$store.state.filters.dateRanges.fundingDateEnd ||
          this.$store.state.filters.dateRanges.fundingDateStart ||
          this.$store.state.filters.dateRanges.createdAtStart ||
          this.$store.state.filters.dateRanges.createdAtEnd ||
          this.$store.state.filters.dateRanges.updatedAtStart ||
          this.$store.state.filters.dateRanges.updatedAtEnd ||
          this.$store.state.filters.customScoreRange.customScoreMin ||
          this.$store.state.filters.customScoreRange.customScoreMax ||
          this.$store.state.filters.portfolio ||
          this.reportFiltersActive
    },
  },
  methods: {
    goToCreatePage(destination, tab, meta) {
      switch (destination) {
        case 'member':
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
          break
        case 'create-portfolio':
          this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'create-portfolio' })
          break
      }
    },
    createPortfolio() {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'create-portfolio' })
    },
    isIE11,
    updateActiveActorTypes(actorTypes) {
      if (actorTypes) {
        this.$store.commit(FILTER_MUTATION_TYPES.SET_ACTIVE_ACTOR_TYPES, actorTypes)
      }
    },
    setSliderItemBackgroundImage(actorData) {
      return `background-image: url('${actorData.logo}');`
    },
    getData() {
      // This is temporary
      var listFilters = inert(this.$store.getters.listFilterObject)
      /* if (listFilters.portfolio && listFilters.actor_type === ('Person'|| 'Community')) {
        delete listFilters.portfolio
      } */

      if (this.leftPanelMode === MODES.MAP) {
        const filters = this.$store.getters.mapFilterObject
        if (filters.tl) {
          this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_MAP, filters)
        }
      } else if (this.leftPanelMode === MODES.GRAPH) {
        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_GRAPH, this.$store.getters.baseFilterObject)
      }

      this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_LIST, listFilters)
    },
    getMapData() {
      const filters = this.$store.getters.mapFilterObject
      if (filters.tl) {
        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_MAP, filters)
      }
    },
    handleChangeLeftPanel(panelMode) {
      // If the same mode is clicked and there is a right panel, we can safely hide the left panel
      if (this.viewDirectory.includes(panelMode)) {
        const removed = this.viewDirectory.filter(v => v !== panelMode)
        if (removed.length) {
          this.viewDirectory = removed
        }
      } else {
        this.viewDirectory = this.viewDirectory.concat(panelMode)
      }
      this.getData()
    },
    handleChangeRightPanel(panelMode) {
      // If the same mode is clicked and there is a left panel, we can safely hide the right panel
      if (this.rightPanelMode !== panelMode) {
        this.viewDirectory = this.viewDirectory.filter(v => v !== this.rightPanelMode).concat(panelMode)
      } else if (this.viewDirectory.includes(panelMode) && (this.leftPanelMode || !this.show.double)) {
        this.viewDirectory = this.viewDirectory.filter(v => v !== panelMode)
      }

      this.getData()
      this.$refs.mapPane && this.$refs.mapPane.invalidateSize()
    },
    goToActorDetail(actor) {
      if (!this.$store.getters.views.includes('actors/detail')) {
        this.viewDirectory = this.viewDirectory.concat(MODES.MAP)
        this.$store.dispatch('ACTORS/FETCH_ACTOR_FOR_PREVIEW', actor)
            .then(actor => {
              this.$refs.mapPane && this.$refs.mapPane.forceVisibleMarker(actor)
            })
      } else if (this.$store.getters.isMember) {
        this.$router.push('/actors/' + actor.id)
      } else {
        this.openSidepanel(actor)
      }
    },
    doublePanelCheck() {
      return window.innerWidth > MAX_WINDOW_WIDTH_FOR_DOUBLE_PANEL_LAYOUT
    },
    toggleFilter() {
      if (this.$store.state.filters.filter.visible) {
        this.$store.commit(FILTER_MUTATION_TYPES.TOGGLE_FILTER, false)
      } else {
        this.$store.commit(FILTER_MUTATION_TYPES.TOGGLE_FILTER, true)
      }
      this.setSwiperHeight()
    },
    setActorType(values) {
      this.$store.commit(FILTER_MUTATION_TYPES.SET_BY_FACET, { facet: 'actor_type', values })
    },
    openSidepanel(actor) {
      console.log(actor)
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'scores', metaData: actor.id })
    },
    setSwiperHeight() {
      // This method is used to set the height of the carrousel itself (container) and the height in the carrousel's properties

      // IMPORTANT: The height property cannot be set in the data properties, it seems that depending on the height value assigned in the data properties
      // the cards will have their height constantly shifting when changing pages on the application as the cards will not know which height to
      // handle, the height in the data property or the height that is assigned afterwards when mounting.
      // By setting the height only when mounting the cards will be more stable as they will have "one value to chose from"

      if (this.$refs.content_panes) {
        // Depending if the filters tab is enabled, we fetch the height of the pans container
        // Else we fetch the height of the pans container and add the height of the filters tab

        // The 'this.$refs.content_panes.clientHeight' when fetched doesn't seem to fetch the value at the right time which results in value not incluing the added space left from the filters tab. Because of this we add manualy the value of that space.
        if (this.$store.state.filters.filter.visible) {
          this.swiperOption.height = this.$refs.content_panes.clientHeight
        } else {
          this.swiperOption.height = this.$refs.content_panes.clientHeight + 100
        }
      }
    },
    checkLegendBoxCoordinates(coordinates) {
      var copyOfCoordinates = Object.assign({}, coordinates)
      // NOTE: Trying to solve the issue regarding the legend box positioning based on different screen sizes programaticaly wasn't working properly was costing too much time to fix. As such we are using hard coded values in order to properly position the legend box if it happens to be placed out of visible bounds
      var legendBoxDimenstions = {
        height: 40,
        width: 250,
      }
      var distanceTopBar = 185
      var topBarHeight = 44
      var totalDistanceToTop = distanceTopBar + topBarHeight
      var sideNavigationWidth = 40

      if (copyOfCoordinates.x >= window.innerWidth - sideNavigationWidth * 2) {
        copyOfCoordinates.x = window.innerWidth - legendBoxDimenstions.width - sideNavigationWidth * 2
      }
      if (copyOfCoordinates.x < 0) {
        copyOfCoordinates.x = sideNavigationWidth
      }
      if (copyOfCoordinates.y >= window.innerHeight - legendBoxDimenstions.height - totalDistanceToTop) {
        copyOfCoordinates.y = window.innerHeight - legendBoxDimenstions.height - totalDistanceToTop
      }
      if (copyOfCoordinates.y < legendBoxDimenstions.height - totalDistanceToTop) {
        copyOfCoordinates.y = -totalDistanceToTop + legendBoxDimenstions.height
      }

      return copyOfCoordinates
    },
    setLegendBoxDefaultPosition() {
      // If there is no Map view enabled and just the list/gallery view, or, If there a Map view and a list/gallery view enabled
      if (this.leftPanelMode == false || (this.leftPanelMode !== false && this.rightPanelMode !== false)) {
        this.legendBoxDefaultPosition = { x: this.$refs.content_panes ? this.$refs.content_panes.clientWidth - 360 + 'px' : 0, y: '-3em' }
        return
      }

      // If Map view is enabled, but not the list/gallery views
      if (this.rightPanelMode == false || this.leftPanelMode !== false) {
        this.legendBoxDefaultPosition = { x: this.$refs.content_panes ? this.$refs.content_panes.clientWidth - 260 + 'px' : 0, y: '1em' }
      }
    },
    setLegendBoxCoordinates(coordinates) {
      this.$store.commit(UI_MUTATION_TYPES.SET_LEGEND_COORDINATES, { explore: coordinates })
    },
    saveLegendBoxCoordinatesToUserSettings(coordinates) {
      // We only save the coordinates once the user has dropped the legend box.
      var legendBoxUSerSettingsCopy
      if (this.$store.getters.userSettings.legendBoxProperties == undefined) {
        this.$store.commit('USER/STORE_SETTINGS', { legendBoxProperties: [{ name: 'explore', coordinates: coordinates }] })
      } else {
        legendBoxUSerSettingsCopy = this.$store.getters.userSettings.legendBoxProperties.slice()
        legendBoxUSerSettingsCopy = legendBoxUSerSettingsCopy.filter(legendBox => legendBox.name !== 'explore')
        legendBoxUSerSettingsCopy.push({ name: 'explore', coordinates: coordinates })
        this.$store.commit('USER/STORE_SETTINGS', { legendBoxProperties: legendBoxUSerSettingsCopy })
      }
    },
    onDragged({ el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last }) {
      /*
        Definition
        el: The target element on which the diretive binds.
        deltaX: The change of the pointer (mouse/touch)'s x coordination from the last position.
        deltaY: The change of the pointer (mouse/touch)'s y coordination from the last position.
        offsetX: The change of the pointer (mouse/touch)'s x coordination from the starting position.
        offsetY: The change of the pointer (mouse/touch)'s y coordination from the starting position.
        clientX: Current x coordination of the pointer (mouse/touch).
        clientY: Current y coordination of the pointer (mouse/touch).
        first: A boolean to indicate whether it is the first move of the drag. (drag starts here).
        last: A boolean to indicate whether it is the last move of the drag. (drag ends here).
      */

      // The area ("explore-frame") in which the dragged object is contained.
      var boundingArea = this.$el.getBoundingClientRect()

      // The dragged object
      var draggedObjectArea = el.getBoundingClientRect()

      var insideLeftBorder = draggedObjectArea.left > boundingArea.left
      var insideRightBorder = draggedObjectArea.right < boundingArea.right
      var insideTopBorder = draggedObjectArea.top > boundingArea.top
      var insideBottomBorder = draggedObjectArea.bottom < boundingArea.bottom

      var l = +window.getComputedStyle(el)['left'].slice(0, -2) || 0
      var t = +window.getComputedStyle(el)['top'].slice(0, -2) || 0
      var coordinates = {
        x: this.exploreLegendBoxProperties.length > 0 ? this.exploreLegendBoxProperties[0].coordinates.x : 0,
        y: this.exploreLegendBoxProperties.length > 0 ? this.exploreLegendBoxProperties[0].coordinates.y : 0,
      }

      if (first) {
        this.dragged = true
        return
      }
      if (last) {
        this.dragged = false
        this.saveLegendBoxCoordinatesToUserSettings(coordinates)
        return
      }

      if (!insideLeftBorder) {
        coordinates.x = l + deltaX + 5
        this.setLegendBoxCoordinates(coordinates)
        return
      }
      if (!insideRightBorder) {
        coordinates.x = l + deltaX - 5
        this.setLegendBoxCoordinates(coordinates)
        return
      }
      if (!insideTopBorder) {
        coordinates.y = t + deltaY + 5
        this.setLegendBoxCoordinates(coordinates)
        return
      }
      if (!insideBottomBorder) {
        coordinates.y = t + deltaY - 5
        this.setLegendBoxCoordinates(coordinates)
        return
      }

      coordinates.x = l + deltaX
      coordinates.y = t + deltaY

      this.setLegendBoxCoordinates(coordinates)
    },
  },
  created() {
    this.$store.commit(FILTER_MUTATION_TYPES.REFRESH_KEYWORDS)
    this.getData()
  },
  mounted() {
    this.$bus.on('resize300', () => {
      this.show.double = this.doublePanelCheck()
      this.setLegendBoxDefaultPosition()
    })

    if (this.$refs.content_panes) {
      this.setSwiperHeight()
    }

    this.setLegendBoxDefaultPosition()
  },
  beforeUnmount() {
  },
  mixins: [TranslationsMixin],
  watch: {
    filters: {
      deep: true,
      handler(val) {
        this.getData()
      },
    },
    rightPanelMode() {
      this.setLegendBoxDefaultPosition()
    },
    leftPanelMode() {
      this.setLegendBoxDefaultPosition()
    },
  }
})
</script>

<style lang="scss">
a.column-toggle {
  padding: 0 1.5rem;
  font-size: .9em;
  line-height: 50px;
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.explore__control-bar__controls {
  .button .svg-icon + .button__label {
    padding-left: 24px;
  }
}
</style>
