<template>
  <div class="challenges-container" ref="scrollable">
    <basic-simplified-hero
        :title="onboardingTextConfig.challengesSimplifiedTitle"
        :subtitle="onboardingTextConfig.challengesSimplifiedSubtitle"></basic-simplified-hero>
    <Container style="flex-grow: 1">
      <div
          class="container" style="color: var(--primary)"
          v-if="isMember || viewableChallengeStatusses.length > 0 || accessibleChallengeStatusses.length > 0">
        <div style="display: flex; justify-content: flex-end" v-if="isLoggedIn">
          <div style="display: flex; align-items: center; margin-right: 0.5em;">
            {{ $t('challenges_show') }}:
          </div>
          <template v-if="isLoggedIn">
            <label-filter
                @updateFilter="toggleFilter('pinned', $event, option.label)"
                v-for="(option, index) in filters.pinned"

                :model-value="option.value"
                :text="`${option.label} ${challengesLabel}`"
                :is-enabled="activeFilters.pinned.indexOf(option.value) >= 0"
                :key="'pinned' + index"
            ></label-filter>
          </template>

          <label-filter
              @updateFilter="setFilter('isCreator', !activeFilters.isCreator, 'created')"
              :model-value="activeFilters.isCreator"
              v-if="isLoggedIn"
              :text="`${$t('challenges_my')} ${challengesLabel}`"
              :is-enabled="activeFilters.isCreator"
              :key="'isCreator'"
          ></label-filter>
        </div>
        <div class="challenges-box">
          <h2 class="h2">{{ $t('categories_all').toUpperCase() }} {{ challengesLabel.toUpperCase() }}<span
              v-if="totalChallengesCount"> ({{ totalChallengesCount }})</span></h2>
          <br>
          <ds-button icon="plus" :label="'Add ' + challengesLabel" @click="createChallenge" variant="primary"
              class="collapsable-panel__header__title" v-if="isLoggedIn"/>
        </div>
        <div class="filter-sentence-container">
          {{ $t('challenges_show_me') }}
          <div class="responsive-dropdown-container">
            <dropdown
                :options="allowVisibilityFilter ? availableStatusFilters.concat(filters.visibility, filters.status) : availableStatusFilters"
                multiple
                @update:modelValue="setStatusOrVisibilityFilter($event)"
                :model-value="activeFilters.status.concat(activeFilters.visibility)"
                :placeholder="$t('challenges_filter_all')"/>
          </div>
          {{ challengesLabel }}
          <div class="responsive-dropdown-container" v-if="displayedTags.length">
            {{ $t('challenges_with_or_which_includes') }}
            <dropdown
                :options="displayedTags" :model-value="activeFilters.tags" multiple
                v-if="allowVisibilityFilter" :placeholder="$t('challenges_filter_any')"
                :allow-clear="true" @update:modelValue="setFilter('tags', $event)"/>
            tag(s)

          </div>
        </div>
      </div>
      <div class="container" style="color: var(--primary)">
        <span v-if="fetching">{{ $t('loading') }}</span>
        <span v-else>&nbsp;</span>
      </div>
      <div v-if="!fetching && challenges.length === 0" class="container">
        <div v-if="contentIsFiltered">
          {{ $t('challenge_none_for_current_filters_1', { challenges: challengesLabel }) }}
          <label-filter
              @updateFilter="clearFilters"
              :model-value="!contentIsFiltered"
              text="here" :is-enabled="!contentIsFiltered"
          ></label-filter>
          {{ $t('challenge_none_for_current_filters_2') }}
        </div>
        <div v-else>
          {{ $t('challenge_none_available', { challenges: challengesLabel }) }}
        </div>
      </div>
      <div style="min-height: calc(100vh - 464px);">
        <challenge-card
            v-for="(challenge, index) in challenges"
            :key="'challenge' + index"
            :challenge="challenge"
            @set-filter="toggleFilter($event.target, $event.value)"
            :can-toggle-status-filter="allowStatusFilter"
            :can-toggle-visibility-filter="allowVisibilityFilter"
            @toggle-pin-status="togglePinStatus"
            :is-accessible-for-user="isAccessibleForUser">
        </challenge-card>
      </div>
    </Container>
    <SimplifiedFooter/>
    <!--    <hover-overlay-button class="application-plus-btn"-->
    <!--                          @openPanel="plusButtonClick"-->
    <!--                          v-if="canCreateChallenge && !sidePanelIsVisible"-->
    <!--                          :label="$t('add_challenge_panel_title', {challenge: challengeLabel})"-->
    <!--                          orientation="right"></hover-overlay-button>-->

  </div>
</template>

<script>
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../store/modules/ui'
import { getFilterableTags, updatePinStatus } from '../Domain/Challenge/Api/challenges.ts'
import SimplifiedHero from '../components/SimplifiedHero/SimplifiedHero.vue'
import Tag from '../components/Tag/Tag.vue'
import RichBodyContent from '../components/RichBodyContent/RichBodyContent.vue'
import _throttle from 'lodash/throttle.js'
import LabelFilter from '../components/Filters/LabelFilter.vue'
import ChallengeCard from '../components/Simplified/ChallengeCard.vue'
import TagsMixin from '../util/TagsMixin.js'
import {
  ACTION_TYPES as CHALLENGES_ACTION_TYPES,
  MUTATION_TYPES as CHALLENGE_MUTATION_TYPES,
} from '../store/modules/challenges.js'
import { darken } from 'polished'
import BasicSimplifiedHero from '../components/Simplified/BasicSimplifiedHero.vue'
import Container from '../components/Container/Container.vue'
import SimplifiedFooter from '../components/SimplifiedFooter/SimplifiedFooter.vue'
import { trackHeapEvent } from '../util/analytics.js'
import { ecosystemMemberHasAccessToChallenge } from '../util/helpers.js'
import HoverOverlayButton from '../components/Overlay/HoverOverlayButton.vue'
import Dropdown from '../components/Dropdown/Dropdown.vue'
import TranslationsMixin from '../util/TranslationsMixin.js'
import OnboardingMixin from '../util/OnboardingMixin.js'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Challenges.vue',
  data () {
    return {
      showMoreTags: false,
      showMoreSDGs: false,
      forcedRenders: 0,
      dynamicFilters: {
        tags: [],
        sustainability_goals: [],
        status: [],
      },
    }
  },
  methods: {
    createChallenge () {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'manage-challenge-panel' })
    },
    togglePinStatus (challenge) {
      challenge.is_pinned = !challenge.is_pinned

      updatePinStatus(challenge.id, challenge.is_pinned)
    },
    isAccessibleForUser (challenge) {
      if (this.isMember) {
        return true
      }
      return ecosystemMemberHasAccessToChallenge(challenge, this.userId, this.accessibleChallengeStatusses)
    },
    loadFilters () {
      getFilterableTags().then((filters) => {
        this.dynamicFilters.sustainability_goals = filters.sdgs || []
        this.dynamicFilters.tags = filters.tags || []
      })
    },
    removeEventListeners () {
      this.$bus.off('challengeUpdated')
      this.$bus.off('challengeDeleted')
      this.$bus.off('challengeCreated')
      this.$refs.scrollable.removeEventListener('scroll', this.onScrollChallenges, { passive: true })
    },
    setupEventListeners () {
      this.$bus.on('challengeUpdated', () => {
        this.resetChallenges()
        this.loadFilters()
        this.fetchChallenges()
      })

      this.$bus.on('challengeDeleted', () => {
        this.resetChallenges()
        this.loadFilters()
        this.fetchChallenges()
      })

      this.$bus.on('challengeCreated', () => {
        this.resetChallenges()
        this.loadFilters()
        this.fetchChallenges()
      })
    },
    clearFilters () {
      trackHeapEvent('challengesSimplified.resetFilters')
      this.$store.commit(CHALLENGE_MUTATION_TYPES.CLEAR_CHALLENGES_FILTER)
      this.resetChallenges()
      this.fetchChallenges()
    },
    limitVisibilityFilterToOneValue (value) {
      this.$store.commit(CHALLENGE_MUTATION_TYPES.TOGGLE_CHALLENGES_FILTER, {
        target: 'visibility',
        value: 'private',
      })

      this.$store.commit(CHALLENGE_MUTATION_TYPES.TOGGLE_CHALLENGES_FILTER, {
        target: 'visibility',
        value: 'public',
      })

      this.$store.commit(CHALLENGE_MUTATION_TYPES.TOGGLE_CHALLENGES_FILTER, {
        target: 'visibility',
        value,
      })
    },
    setStatusOrVisibilityFilter (value) {
      const statusFilters = value.filter(value => ['new', 'open', 'solved'].includes(value))
      const visibilityFilters = value.filter(value => ['public', 'private'].includes(value))
      this.setFilter('status', statusFilters)
      this.setFilter('visibility', visibilityFilters)
    },
    setFilter (target, value, label) {
      trackHeapEvent('challengesSimplified.toggleFilter', {
        filterName: target,
        label: label || value,
      })

      this.$store.commit(CHALLENGE_MUTATION_TYPES.SET_CHALLENGES_FILTER, {
        target,
        value,
      })

      this.resetChallenges()
      this.fetchChallenges()
    },
    toggleFilter (target, value, label) {
      trackHeapEvent('challengesSimplified.toggleFilter', {
        filterName: target,
        label: label || value,
      })

      this.$store.commit(CHALLENGE_MUTATION_TYPES.TOGGLE_CHALLENGES_FILTER, {
        target,
        value,
      })

      this.resetChallenges()
      this.fetchChallenges()
    },
    fetchChallenges () {
      if (this.fetching) {
        return
      }

      this.$store.dispatch(CHALLENGES_ACTION_TYPES.FETCH_CHALLENGES_LIST, {
        limit: this.fetchLimit,
        offset: this.flushCache ? 0 : this.challenges.length,
        filters: this.activeFilters,
      })
    },
    onScrollChallenges: _throttle(function () {
      const elem = this.$refs.scrollable
      if (elem && elem.offsetHeight + elem.scrollTop > 0.95 * elem.scrollHeight - 1500 && !this.endOfFeed) {
        this.fetchChallenges()
      }
    }, 1000),
    resetChallenges () {
      this.$store.commit(CHALLENGE_MUTATION_TYPES.FLUSH_CACHE)
    },
  },
  computed: {
    filters () {
      return {
        isCreator: false,
        sustainability_goals: [],
        tags: [],
        visibility: [
          { label: this.$t('challenges_public'), value: 'public' },
          { label: this.$t('challenges_private'), value: 'private' },
        ],
        a: 1,
        status: [
          { label: this.$t('challenges_new'), value: 'new' },
          { label: this.$t('challenges_open'), value: 'open' },
          { label: this.$t('challenges_solved'), value: 'solved' },
        ],
        pinned: [
          { label: this.$t('challenges_pinned'), value: true },
        ],
      }
    },
    sidePanelIsVisible () {
      return this.$store.state.ui.sidePanel.isVisible
    },
    availableStatusFilters () {
      return this.dynamicFilters.status.filter(option => {
        return this.isMember || (this.allowStatusFilter && this.viewableChallengeStatusses.includes(option.value))
      })
    },
    canCreateChallenge () {
      return this.$store.getters.canCreateChallenge
    },
    challengeList () {
      return this.$store.state.challenges.listData
    },
    challenges () {
      return this.challengeList.data
    },
    flushCache () {
      return this.challengeList.flushCache
    },
    fetchLimit () {
      return this.challengeList.fetchLimit
    },
    endOfFeed () {
      return this.challengeList.endOfFeed
    },
    fetching () {
      return this.challengeList.loading
    },
    totalChallengesCount () {
      return this.$store.state.challenges.challengesCount
    },
    allowStatusFilter () {
      return this.isMember || this.viewableChallengeStatusses.length > 1
    },
    allowVisibilityFilter () {
      return !!this.isLoggedIn
    },
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    },
    userId () {
      return this.$store.state.user.profile.id
    },
    accessibleChallengeStatusses () {
      return this.$store.getters.accessibleChallengeStatusses
    },
    viewableChallengeStatusses () {
      return this.$store.getters.viewableChallengeStatusses
    },
    displayedTags () {
      const displayedTags = this.dynamicFilters.tags.sort((tag1, tag2) => {
        return this.activeFilters.tags.indexOf(tag2.value) - this.activeFilters.tags.indexOf(tag1.value)
      })
      return this.showMoreTags ? displayedTags : displayedTags.slice(0, 12)
    },
    displayedSDGs () {
      return this.showMoreSDGs ? this.dynamicFilters.sustainability_goals : this.dynamicFilters.sustainability_goals.slice(0, 4)
    },
    contentIsFiltered () {
      return this.dynamicFilters.sustainability_goals.length > 0 ||
          this.dynamicFilters.tags.length > 0 ||
          this.activeFilters.visibility.length > 0 ||
          this.activeFilters.status.length > 0 ||
          this.activeFilters.pinned.length > 0
    },
    activeFilters () {
      return this.$store.state.challenges.challengesFilters
    },
    isOwner () {
      return this.$store.getters.isOwner
    },
    isMember () {
      // Is team member or ecosystem owner
      return this.$store.getters.isMember
    },
    localizedTitle () {
      return this.challengesLabel
    },
  },
  created () {
    this.fetchChallenges()
    this.loadFilters()

    this.setupEventListeners()
  },
  mounted () {
    this.$store.dispatch(CHALLENGES_ACTION_TYPES.FETCH_CHALLENGES_COUNT)
    this.$refs.scrollable.addEventListener('scroll', this.onScrollChallenges, { passive: true })

    if (this.$route.query.openModal) {
      this.createChallenge()
      this.$router.replace({ 'query': null })
    }
  },
  beforeUnmount () {
    this.removeEventListeners()

    this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
  },
  components: {
    Dropdown,
    HoverOverlayButton,
    SimplifiedFooter,
    Container,
    BasicSimplifiedHero,
    SimplifiedHero,
    Tag,
    ChallengeCard,
    RichBodyContent,
    LabelFilter,
  },
  mixins: [TagsMixin, TranslationsMixin, OnboardingMixin],
})
</script>

<style lang="scss" scoped>
@import "../../scss/variables.scss";

.challenges-container {
  height: 100%;
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;

  .container {
    margin: auto;

    .label-filters {
      font-size: 0;

      .show-more {
        text-decoration: underline;
        cursor: pointer;
      }

      :deep(span) {
        margin-bottom: 2px;
        font-size: initial;
        cursor: pointer;
      }
    }

    .challenges-box {
      display: flex;

      .button {
        margin-left: 15px;
        margin-bottom: 15px;
        margin-top: -5px;
      }
    }
  }

  :deep(.simplified-hero) {
    .simplified-hero__actions {
      background: rgba(0, 0, 0, 0.5);
      padding: 0
    }

    .challenge-actions-container {
      display: flex;
      height: 100%;

      .challenges-actions-title {
        display: flex;
        margin: auto;
        flex-direction: column;
        padding: 50px;
        text-align: left;

        h1, h3 {
          font-weight: 100;
        }
      }
    }
  }
}

.filter-sentence-container {
  line-height: 40px;
  margin-bottom: 5px;

  .responsive-dropdown-container {
    height: 100px;
    display: inline;

    & > div {
      width: auto;
      min-width: 55px;
    }
  }

  :deep(.multiselect) {
    width: auto !important;

    .multiselect__content-wrapper {
      width: auto;
    }
  }
}
</style>
