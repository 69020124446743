<template>
  <form-group :label="$t('actor_edit_taxonomy_quick_search')" class="col-xs-12">
    <dropdown
      label="Taxonomy quick search"
      :options="taxonomyQuickSearchOptions"
      :value-is-option="true"
      :search="true"
      @update:modelValue="updateTaxonomyViaQuickSearch"
      :customLabel="taxonomyQuickSearchOptionLabel"
    />
  </form-group>
</template>

<script>
  import Dropdown from '../../Dropdown/Dropdown.vue'
  import isNumber from 'lodash/isNumber'
  import { isRelevantForActor } from '../../../util/helpers'
  import TranslationsMixin from '../../../util/TranslationsMixin'
  import ConfigMixin from '../../../util/ConfigMixin'

  export default {
    name: 'TaxonomyQuickEdit',
    props: {
      modifiedActor: Object
    },
    computed: {
      taxonomyQuickSearchOptions () {
        var taxonomyQuickSearchOptions = []

        if (this.isRelevantForActor('activities', this.modifiedActor)) {
          taxonomyQuickSearchOptions = [...taxonomyQuickSearchOptions, ...this.prepareTaxonomyQuickSearchOptions('activities', this.activityValuesAndLabels)]
        }

        if (this.isRelevantForActor('membership', this.modifiedActor) && this.$store.getters.isMember) {
          taxonomyQuickSearchOptions = [...taxonomyQuickSearchOptions, ...this.prepareTaxonomyQuickSearchOptions('membership', this.membershipValuesAndLabels)]
        }

        if (this.isRelevantForActor('funnel_stage', this.modifiedActor) && this.$store.getters.isMember) {
          taxonomyQuickSearchOptions = [...taxonomyQuickSearchOptions, ...this.prepareTaxonomyQuickSearchOptions('funnel_stage', this.funnelStageValuesAndLabels)]
        }

        if (this.isRelevantForActor('category', this.modifiedActor)) {
          taxonomyQuickSearchOptions = [...taxonomyQuickSearchOptions, ...this.prepareTaxonomyQuickSearchOptions('category', this.categoryValuesAndLabels)]
        }

        if (this.isRelevantForActor('industries', this.modifiedActor)) {
          taxonomyQuickSearchOptions = [...taxonomyQuickSearchOptions, ...this.prepareTaxonomyQuickSearchOptions('industries', this.allMainIndustriesOptions)]
        }

        if (this.isRelevantForActor('subIndustries', this.modifiedActor)) {
          taxonomyQuickSearchOptions = [...taxonomyQuickSearchOptions, ...this.prepareTaxonomyQuickSearchOptions('industries', this.allSubIndustriesOptions)]
        }

        if (this.isRelevantForActor('domains', this.modifiedActor)) {
          taxonomyQuickSearchOptions = [...taxonomyQuickSearchOptions, ...this.prepareTaxonomyQuickSearchOptions('domains', this.allDomainsOptions)]
        }

        if (this.isRelevantForActor('technology', this.modifiedActor)) {
          taxonomyQuickSearchOptions = [...taxonomyQuickSearchOptions, ...this.prepareTaxonomyQuickSearchOptions('technology', this.genericTwoLevelTaxonomyOptions(this.technologies, 'technology'))]
        }

        if (this.isRelevantForActor('product_features_a', this.modifiedActor)) {
          taxonomyQuickSearchOptions = [...taxonomyQuickSearchOptions, ...this.prepareTaxonomyQuickSearchOptions('product_features_a', this.genericTwoLevelTaxonomyOptions(this.productFeaturesA, 'product_features_a'))]
        }

        if (this.isRelevantForActor('product_features_b', this.modifiedActor)) {
          taxonomyQuickSearchOptions = [...taxonomyQuickSearchOptions, ...this.prepareTaxonomyQuickSearchOptions('product_features_b', this.genericTwoLevelTaxonomyOptions(this.productFeaturesB, 'product_features_b'))]
        }

        if (this.isRelevantForActor('product_features_c', this.modifiedActor)) {
          taxonomyQuickSearchOptions = [...taxonomyQuickSearchOptions, ...this.prepareTaxonomyQuickSearchOptions('product_features_c', this.genericTwoLevelTaxonomyOptions(this.productFeaturesC, 'product_features_c'))]
        }

        return taxonomyQuickSearchOptions
      },
      allMainIndustriesOptions () {
        var options = []

        this.industries.forEach(i => {
          options.push({ value: { id: i.id, parent_id: null }, label: this.getLabelForTaxonomyValue('industries', i.id) })
        })

        return options
      },
      allSubIndustriesOptions () {
        var options = []

        this.industries.forEach(i => {
          if (i.industries && i.industries.length) {
            i.industries.forEach(subIndustry => {
              options.push({ value: { id: subIndustry.id, parent_id: i.id }, label: this.getLabelForTaxonomyValue('industries', subIndustry.id) })
            })
          }
        })

        return options
      },
      allDomainsOptions () {
        var options = []

        this.$store.state.taxonomies.domains.forEach(domain => {
          const parentTaxonomy = {
            id: domain.id,
            name: domain.name,
            parentId: domain.domain_id,
            offers: ['service'],
            remark: domain.remark
          }

          options.push({
            value: parentTaxonomy,
            label: this.getLabelForTaxonomyValue('domains', domain.id)
          })

          if (domain.domains && domain.domains.length) {
            domain.domains.forEach(subDomain => {
              options.push({
                value: {
                  id: subDomain.id,
                  name: domain.name,
                  parentId: subDomain.domain_id,
                  offers: ['service'],
                  remark: subDomain.remark,
                  parent: parentTaxonomy
                },
                label: this.getLabelForTaxonomyValue('domains', subDomain.id)
              })
            })
          }
        })

        return options
      },
      industries () {
        return this.$store.state.taxonomies.industries
      },
      productFeaturesA () {
        return this.$store.state.taxonomies.productFeaturesA
      },
      productFeaturesB () {
        return this.$store.state.taxonomies.productFeaturesB
      },
      productFeaturesC () {
        return this.$store.state.taxonomies.productFeaturesC
      },
      technologies () {
        return this.$store.state.taxonomies.technologies
      },
      categories () {
        return this.$store.state.taxonomies.categories
      }
    },
    methods: {
      isRelevantForActor,
      prepareTaxonomyQuickSearchOptions (actorTaxonomyProperty, taxonomyOptions) {
        if (!taxonomyOptions || !taxonomyOptions.length) {
          return []
        }

        // We have different approaches for values in our taxonomies unfortunately:
        // - technology, activities: [{value, label}]
        // - industries: list of industry IDs [{id, parent_id}]
        // - domains: list of domain taxonomy objects
        var options = [...taxonomyOptions].map(option => {
          var optionEnabled = false

          // Distinguish between single value actor taxonomy values and multi-value (i.e. category vs industries
          if (!Array.isArray(this.modifiedActor[actorTaxonomyProperty])) {
            optionEnabled = this.modifiedActor[actorTaxonomyProperty] && this.modifiedActor[actorTaxonomyProperty].value == option.value
          } else {
            optionEnabled = this.modifiedActor[actorTaxonomyProperty] && this.modifiedActor[actorTaxonomyProperty].find(taxonomyValue => {
              var outcome

              if (!taxonomyValue) {
                return
              }

              if (taxonomyValue.value && isNumber(taxonomyValue.value)) {
                outcome = option.value == taxonomyValue.value
              } else if (isNumber(taxonomyValue)) {
                outcome = option.value == taxonomyValue // industries
              }
              if (taxonomyValue.id && isNumber(taxonomyValue.id)) {
                outcome = option.value.id == taxonomyValue.id // domains
              }

              return outcome
            })
          }

          option.context = {
            taxonomy: actorTaxonomyProperty,
            prefix: (optionEnabled ? this.$t('actor_edit_taxonomy_quick_search_remove') : this.$t('actor_edit_taxonomy_quick_search_add')) + ': '
          }

          return option
        })

        return options
      },
      taxonomyQuickSearchOptionLabel (taxonomyOption) {
        return taxonomyOption.context.prefix + taxonomyOption.label
      },
      updateTaxonomyViaQuickSearch (taxonomyOption) {
        // NOTE: there's some one-off code for industries because of the one-off split between main/sub industries
        const taxonomy = taxonomyOption.context.taxonomy
        var taxonomyValue = {
          label: taxonomyOption.label,
          value: taxonomyOption.value
        }

        var taxonomyOptionParent = null

        // Category is a single value property and needs a different approach then multi-value taxonomy actor properties
        if (['category', 'funnel_stage'].includes(taxonomy)) {
          if (!this.modifiedActor[taxonomy] || !this.modifiedActor[taxonomy].value || this.modifiedActor[taxonomy].value != taxonomyValue.value) {
            this.modifiedActor[taxonomy] = taxonomyValue

            return
          }

          this.modifiedActor[taxonomy] = null

          return
        }

        if (['domains', 'product_features_a', 'product_features_b', 'product_features_c', 'industries', 'subIndustries', 'technology'].includes(taxonomy)) {
          taxonomyValue = taxonomyValue.value
          taxonomyOptionParent = { ...taxonomyOption.value.parent }
        }

        if (!this.modifiedActor[taxonomy]) {
          this.modifiedActor[taxonomy] = [taxonomyValue]

          return
        }

        const filtered = this.modifiedActor[taxonomy].filter(actorTaxonomyValue => {
          var outcome

          if (actorTaxonomyValue.value && isNumber(actorTaxonomyValue.value)) {
            outcome = actorTaxonomyValue.value != taxonomyValue.value
          } else if (isNumber(actorTaxonomyValue)) {
            outcome = actorTaxonomyValue != taxonomyValue.value // industries
          }
          if (actorTaxonomyValue.id && isNumber(actorTaxonomyValue.id)) {
            outcome = actorTaxonomyValue.id != taxonomyValue.id // domains and other 2 level values
          }

          return outcome
        })

        if (filtered.length === this.modifiedActor[taxonomy].length) {
          // If there was a parent value, and it's not yet enabled, enable it
          if (taxonomy === 'industries' || taxonomy === 'subIndustries') {
            if (taxonomyValue.parent_id && !this.modifiedActor['industries'].filter(actorTaxonomyValue => actorTaxonomyValue == taxonomyValue.parent_id).length) {
              this.modifiedActor['industries'] = [...this.modifiedActor['industries'], taxonomyValue]
            }

            this.modifiedActor['industries'] = [...this.modifiedActor['industries'], { id: taxonomyValue.parent_id, parent_id: null }]

            return
          }

          if (taxonomy === 'industries') {
            this.modifiedActor[taxonomy] = [...this.modifiedActor[taxonomy], taxonomyValue]
            return
          }

          if (taxonomyOptionParent && taxonomyOptionParent.id && !this.modifiedActor[taxonomy].filter(actorTaxonomyValue => actorTaxonomyValue.id == taxonomyOptionParent.id).length) {
            this.modifiedActor[taxonomy] = [...this.modifiedActor[taxonomy], taxonomyOptionParent]
          }

          this.modifiedActor[taxonomy] = [...this.modifiedActor[taxonomy], taxonomyValue]
        } else {
          this.modifiedActor[taxonomy] = filtered
        }
      },
      genericTwoLevelTaxonomyOptions (taxonomies, taxonomyName) {
        var options = []

        taxonomies.forEach(taxonomy => {
          const parentTaxonomy = {
            id: taxonomy.id,
            name: taxonomy.name,
            parent_id: taxonomy.parent_id,
            translations: taxonomy.translations
          }

          options.push({
            value: parentTaxonomy,
            label: this.getLabelForTaxonomyValue(taxonomyName, taxonomy.id)
          })

          if (taxonomy.children && taxonomy.children.length) {
            taxonomy.children.forEach(subTaxonomy => {
              options.push({
                value: {
                  id: subTaxonomy.id,
                  name: subTaxonomy.name,
                  parent_id: subTaxonomy.parent_id,
                  translations: subTaxonomy.translations,
                  parent: parentTaxonomy
                },
                label: this.getLabelForTaxonomyValue(taxonomyName, subTaxonomy.id)
              })
            })
          }
        })

        return options
      },
    },
    mixins: [TranslationsMixin, ConfigMixin],
    components: {
      Dropdown
    }
  }
</script>

<style scoped>

</style>
