<template>
  <div class="agent-card" :class="{ 'is-enabled': agent.isEnabled }">
    <div class="agent-card-body">
      <div class="agent-card__button">
        <ds-button icon="play_button" label="" @click="startAgent(agent)" style="background-color: transparent;" :disabled="agent.status == 'running' || agent.status == 'queued'"/>
      </div>
      <div class="agent-main">
        <h4 class="agent-name">
          {{ agent.displayName }}
        </h4>
        <p class="agent-description" :class="{ 'is-disabled': !agent.isEnabled }">
          {{ agent.description }}
        </p>
      </div>
    </div>

    <div
      class="agent-card-footer loading-indicator"
      v-if="agent.isEnabled && (agent.status == 'queued' || agent.status == 'running')"
    >
      <icon name="spinner"/>
      {{ agent.meta.percFinished }}% finished
    </div>
    <div
      class="agent-card-footer loading-indicator"
      v-else-if="agent.isEnabled && agent.status == 'started'"
    >
      <icon name="spinner"/>
      searching in progress...
    </div>
    <div
      class="agent-card-footer loading-indicator"
      v-else-if="agent.status == 'no_new_actions'"
    >
      All actors in the portfolio are already being enriched. - {{ getSuggestionCountMessage(agent) }}
    </div>
    <div
      class="agent-card-footer loading-indicator"
      v-else-if="agent.isEnabled && (agent.status == 'finished')"
    >
      {{ getFinishedMessage(agent) }} - {{ getSuggestionCountMessage(agent) }}
    </div>
  </div>
</template>

<script>
  import Checkbox from '../../Form/Checkbox.vue'
  import BadgeCounter from '../../Badge/BadgeCounter.vue'
  import AgentSuggestion from './AgentSuggestion.vue'

  import { runAgentForPortfolio } from '../../../api/portfolios.js'
  import { trackHeapEvent } from '../../../util/analytics'

  export default {
    data () {
      return {
        suggestionsVisible: false,
        toggleEnabled: Function,
        agentsWithMeta: [
          'get_founders',
          'get_team',
          'get_administrators',
          'get_investors',
          'get_investments',
          'get_acquisitions'
        ],
        agentsWithSuggestions: [
          'get_contacts'
        ]
      }
    },
    props: {
      agent: {
        type: Object
      },
      portfolio: {
        type: Object
      }
    },
    methods: {
      startAgent (agent) {
        runAgentForPortfolio(this.portfolio.id, agent.action)
          .then(response => {
            this.agent.status = 'started'
          })
          .catch()

        trackHeapEvent('agents.manualTrigger.' + agent.action)
      },
      getSuggestionCountMessage (agent) {
        if (!agent.meta.actors_with_suggestions) {
          return 'We found no new related actors.'
        }

        return 'For ' + agent.meta.actors_with_suggestions + (agent.meta.actors_with_suggestions > 1 ? ' actors' : ' actor') + ', we found new suggested relationships.'
      },
      getFinishedMessage (agent) {
        return 'Finished'
      }
    },
    components: {
      AgentSuggestion,
      BadgeCounter,
      Checkbox
    }
  }
</script>

<style lang="scss">
  @import "../../../../scss/variables";

  .agent-card__button {
    margin-left: -1em;
    margin-top: -0.8em;
  }

  .agent-card {
    border: 1px solid white;
    box-shadow: 0 0 3px rgba(0, 0, 0, .1);
    margin-bottom: 10px;
    position: relative;

    .counter__badge {
      box-shadow: 0 0 3px red;
      right: -8px;
      top: -8px;
    }

    &.is-enabled {
      border-color: $color-text-grey;
    }

    .agent-card-body {
      display: flex;
      padding: 10px;
    }

    .radio {
      margin-right: 5px;
      margin-top: 0;
    }

    .agent-name {
      margin-bottom: 3px;
      text-transform: uppercase;
    }

    .agent-description {
      font-style: italic;

      &.is-disabled {
        color: $color-text-grey-light;
      }
    }

    .footer-toggle {
      display: block;
    }

    .agent-card-footer {
      border-top: 1px solid $color-text-grey;
      padding: 5px;
      text-align: center;
    }

    .loading-indicator {
      align-items: center;
      display: flex;
      font-weight: 500;
      justify-content: center;

      .svg-icon {
        margin-left: -10px;
        margin-right: 3px;
      }
    }
  }
</style>
