<template>
  <tr class="row-curation" @click="preview">
    <td>{{ challenge.title }}</td>
    <td class="row-curation__breakable" @click.stop="mailto(challenge.user_email)">{{ challenge.user_email }}</td>
    <td class="text-right" :title="date(challenge.created_at)">{{ date(challenge.created_at) }}</td>
    <td>
      <ds-button class="row-curation__verify" @click.stop="preview" label="Preview" />
    </td>
    <td>
      <ds-button class="row-curation__verify" icon="check" @click.stop="acceptChallenge" label="Approve" />
    </td>
    <td>
      <ds-button class="row-curation__verify" icon="trash" @click.stop="denyChallenge" label="Remove" />
    </td>
    <td>
      <ds-button class="row-curation__verify" icon="edit" @click.stop="editAndAcceptChallenge" label="Edit & Approve" />
    </td>
  </tr>
</template>

<script>


  import { acceptChallenge, denyChallenge } from '../../Domain/Challenge/Api/challenges.ts'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: ['challenge'],
    data () {
      return {
        message: ''
      }
    },
    methods: {
      mailto (email) {
        window.open('mailto:' + email)
      },
      stopPropagation (event) {
        event.stopPropagation()
      },
      preview () {
        // TODO: TIM you'll have to make this route available, or at least double check that this is the correct way of visiting a challenge detail page
        this.$router.push('/challenges/' + this.challenge.id)
      },
      editAndAcceptChallenge () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {'component' : 'manage-challenge-panel', metaData: this.challenge})
      },
      acceptChallenge () {
        this.message = '...'

        acceptChallenge(this.challenge.id)
          .then(() => {
            this.$bus.emit('acceptedChallenge')
          })
          .catch(errors => {
            this.message = errors && errors.message || `Could not approve the ${this.challengeLabel}.`
          })
      },
      denyChallenge () {
        this.message = '...'

        denyChallenge(this.challenge.id)
          .then(() => {
            this.$bus.emit('deniedChallenge')
          })
          .catch(errors => {
            this.message = errors && errors.message || 'Something went wrong.'
          })
      },
      date (str) {
        return str ? str.slice(0, 19).replace('T', ' ') : ''
      }
    },
    mixins: [TranslationsMixin],
    watch: {
      challenge () {
        this.message = ''
      }
    }
  })
</script>

<style>
  .row-curation td {
    line-height: 26px !important;
  }

  .row-curation .button {
    margin: -6px -10px !important;
    display: block;
    width: calc(100% + 20px);
    font-size: 12px;
  }

  .row-curation .row-curation__onhold {
    background: none;
    color: #999;
  }

  .row-curation__ok {
    width: 70px !important;
    margin-right: -10px !important;
    float: right;
  }

  .row-curation__remove {
    width: 36px !important;
    margin-right: -10px !important;
  }

  .row-curation--deleted {
    color: #f99;
  }

  .row-curation__breakable {
    word-break: break-word;
  }
</style>
