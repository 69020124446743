<template>
  <finalise-onboarding type="actor-sign-up" :is-closeable="isCloseable" v-if="isNewProfileEnabled" is-modal/>
</template>
<script>
  import FinaliseOnboarding from '../../pages/Onboarding/FinaliseOnboarding.vue'

  export default {
    props: {
      isCloseable: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {}
    },
    methods: {},
    computed: {
      isNewProfileEnabled () {
        return this.$store.getters.isNewProfileEnabled
      },
    },
    mounted () {
    },
    components: {
      FinaliseOnboarding,
    },
  }
</script>
