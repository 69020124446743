<template>
  <div class="app-wrapper">
    <top-bar-static
        v-if="showStaticTopBar || showPnGTopBar "
        ref="topBar"
    />

    <top-bar-static-foodleap ref="topBar" v-else-if="showFoodleapHomepage"/>
    <TopBarOpenCreatives ref="topBar" v-else-if="showOpenCreativesTopBar"/>
    <TopBarStaticAgritech ref="topBar" v-else-if="showAgritechHomepage"/>
    <TopBarStaticSpacewerx ref="topBar" v-else-if="showSpacewerxHomepage"/>

    <top-bar
        v-else-if="!showKlikHomepage"
        :notifications="! isMobile"
        ref="topBar"
        @toggleMobileNavigationDrawerVisibility="handleToggleMobileNavigationDrawerVisibility"
        @toggleMobileSearchDrawerVisibility="handleToggleMobileSearchDrawerVisibility"
    />

    <SecondTopBar v-if="canShowSecondTopBar"/>
    <CallToActionTopBar v-if="canShowCallToActionTopBar"/>

    <MobileNavigationDrawer
        :isOpen="isMobileNavigationDrawerOpen"
        @close="closeMobileNavigationDrawer"
    />
    <MobileSearchDrawer
        :isOpen="isMobileSearchDrawerOpen"
        @close="isMobileSearchDrawerOpen = false"
    />
    <div
        class="viewport"
        :class="{ 'has-static-homepage': showStaticTopBar, 'has-PnG-homepage': showPnGTopBar, 'has-klik-homepage': showKlikHomepage, 'has-simplified-top-bar': canShowSecondTopBar || canShowCallToActionTopBar, 'has-open-creatives-top-bar': showOpenCreativesTopBar, 'has-simplified-top-bar-blurred': canShowSecondTopBar && hasBlurredSecondTopBar}">
      <navigation v-if="shouldShowNavigation"/>
      <ecosystem-switcher/>
      <div class="main-view" :class="{'with-navigation': shouldShowNavigation}">
        <router-view ref="view"></router-view>
      </div>
      <subnav-home-links v-if="isActor && canShowHomeLinks"/>

      <ds-button
          icon="chevron-right" @click="switchPanelState" variant="secondary"
          size="small"
          :class="[subnavEnabled ? 'side-panel__collapse-button-collapsed' : 'side-panel__collapse-button-collapsed-fixed-right']"
          v-if="!isExpanded && typeOfPanel == 'edit-portfolio'"/>
      <side-panel/>
    </div>
    <notifications
        group="alerts" class="pop-up__slot" position="bottom right"
        v-if="! isMobile">
      <template #body="props">
        <div
            class="pop-up__wrapper" @click="goToNotification(props.item)"
            v-if="props.item.text">
          <div class="pop-up__ribbon" :style="notificationColor(props.item.type)"></div>
          <div class="pop-up__notification">
            <icon
                class="pop-up__notification__icon"
                :class="notificationColorClass(props.item.type)"
                :name="notificationIconName(props.item.type, props.item.event)"></icon>
            <div class="pop-up__title">{{ props.item.title }}</div>
            <icon class="pop-up__close" @click.stop="props.close" name="remove"/>
          </div>
        </div>
        <div div class="pop-up__wrapper" @click="goToNotification(props.item)" v-else>
          <div class="pop-up__all-notification">
            <div>{{ $t('message_click_here_to_see_all_notifications') }}</div>
          </div>
        </div>
      </template>
    </notifications>
    <modals-container/>
    <transition name="fade">
      <!-- this html code was copied from the HTML of the vue-cookie-accept-decline vue 2 library. -->
      <div
          id="cookie-panel" v-if="userHasNotAcceptedCookies"
          class="cookie cookie__floating cookie__floating--bottom-left">
        <div class="cookie__floating__wrap">
          <div class="cookie__floating__content">
            <div>
              We use cookies to ensure you get the best experience on our platform. Review
              our <a href="https://datascouts.eu/cookie-policy/" target="_blank">Cookie
              Policy</a>
              for more information.
            </div>
          </div>
          <div class="cookie__floating__buttons">
            <button
                class="cookie__floating__buttons__button cookie__floating__buttons__button--accept"
                @click="cookieClickedAccept">
              <div>
                I accept all cookies
              </div>
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import TopBar from './components/TopBar/TopBar.vue'
import SecondTopBar from './components/TopBar/SecondTopBar.vue'
import CallToActionTopBar from './components/TopBar/CallToActionTopBar.vue'
import TopBarStatic from './components/TopBar/TopBarStatic.vue'
import Navigation from './components/Navigation/Navigation.vue'
import SubnavHomeLinks from './components/Navigation/SubnavHomeLinks.vue'
import EcosystemSwitcher from './components/EcosystemSwitcher/EcosystemSwitcher.vue'
import SidePanel from './components/SidePanel/SidePanel.vue'
import ModalsContainer from './components/Modals/ModalsContainer.vue'
import Icon from './components/Icon/Icon.vue'
import HoverOverlayButton from './components/Overlay/HoverOverlayButton.vue'
import MobileNavigationDrawer from './components/MobileNavigationDrawer/MobileNavigationDrawer.vue'
import MobileSearchDrawer from './components/MobileSearchDrawer/MobileSearchDrawer.vue'

import { notificationColor, notificationColorClass, notificationIconName } from './util/NotificationsMixin'
import UiMixin from './util/UiMixin'
import TranslationsMixin from './util/TranslationsMixin'

import { MUTATION_TYPES as UI_MUTATION_TYPES } from './store/modules/ui'
import { ACTION_TYPES as TAXONOMIES_ACTION_TYPES } from './store/modules/taxonomies'
import { ACTION_TYPES as ACTOR_RELATIONSHIP_ACTION_TYPES } from './store/modules/actor-relationships'
import { ACTION_TYPES as USER_ACTION_TYPES } from './store/modules/user.js'
import { MUTATION_TYPES as USER_MUTATION_TYPES } from './store/modules/user-helpers.js'
import { ACTION_TYPES as DEFAULT_DICTIONARY_ACTION_TYPES } from './store/modules/defaultDictionaries'
import { ActionTypes as ConceptSearchesActionTypes } from './store/modules/concept-searches'
import { ACTION_TYPES as COMMUNITY_ACTION_TYPES } from './store/modules/communities'
import { ACTION_TYPES as SPOTTING_AREAS_ACTION_TYPES } from '@/store/modules/spottingareas.js'

import MODAL_IDS from './constants/modal-ids'

import { trackHeapEvent } from './util/analytics.js'
import { acceptCookies, getPaymentInfo } from './api/user.js'
import ls from './util/ls'
import { SiteTemplate } from './store/modules/config'
import { DEFAULT_URLS } from './constants/default-urls.js'
import TopBarStaticFoodleap from './components/TopBar/TopBarStaticFoodleap.vue'
import TopBarOpenCreatives from './components/TopBar/TopBarOpenCreatives.vue'
import {
  ACTION_TYPES as LOCALIZATION_ACTION_TYPES,
  MUTATION_TYPES as LOCALIZATION_MUTATION_TYPES,
} from './store/modules/localization'
import TopBarStaticAgritech from '@/components/TopBar/TopBarStaticAgritech.vue'
import TopBarStaticSpacewerx from '@/components/TopBar/TopBarStaticSpacewerx.vue'

export default {
  name: 'app',
  data () {
    return {
      isMobileNavigationDrawerOpen: false,
      isMobileSearchDrawerOpen: false,
      hasClosedCookiePopup: false,
    }
  },
  computed: {
    isCommunityEcosystem () {
      return this.$store.getters.isCommunityEcosystem
    },
    shouldShowNavigation () {
      const routesWithoutNavigation = ['addActor', 'login', 'restricted', 'forgot-password', 'gdpr-terms', 'change-password']
      const routesWithoutNavigationWhenOnboardingIsEnabled = ['addActor', 'login', 'restricted', 'forgot-password', 'gdpr-terms', 'change-password', 'sign-up', 'log-in']

      if (this.showKlikHomepage || this.showAriadneHomepage || this.showFoodleapHomepage) {
        return false
      }

      if (this.$store.getters.isActor && [SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(this.$store.getters.activeSiteTemplate)) {
        return false
      }

      if (!this.$store.getters.isLoggedIn && [SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(this.$store.getters.activeSiteTemplate)) {
        return false
      }

      if (this.$route.name === 'spotting-areas' && !this.$store.state.ui.navigation.isVisible) {
        return false
      }

      if (this.isNewProfileEnabled) {
        return !routesWithoutNavigationWhenOnboardingIsEnabled.includes(this.$route.name)
      }

      return !routesWithoutNavigation.includes(this.$route.name)
    },
    hasBlurredSecondTopBar () {
      return false
    },
    canShowSecondTopBar () {
      return !this.showKlikHomepage &&
          !this.showPnGTopBar &&
          //!this.showAriadneHomepage &&
          !this.showFoodleapHomepage &&
          !this.showAgritechHomepage &&
          this.canShowBreadcrumbs &&
          !this.canShowCallToActionTopBar
    },
    canShowCallToActionTopBar () {
      return !this.$store.getters.isLoggedIn && this.isCommunityEcosystem && this.$route.path.startsWith('/dashboards/home')
    },
    isNewSimplified () {
      return this.config.siteTemplate === SiteTemplate.NEW_SIMPLIFIED
    },
    showKlikHomepage () {
      return this.$store.getters.isKlik && !this.$store.getters.isLoggedIn && this.$store.state.config.allow_anonymous && this.$route.path.startsWith('/dashboards/home')
    },
    showAriadneHomepage () {
      return this.$store.getters.isAriadne && !this.$store.getters.isLoggedIn && this.$store.state.config.allow_anonymous && this.$route.path.startsWith('/dashboards/home')
    },
    showFoodleapHomepage () {
      return this.$store.getters.isFoodleap && !this.$store.getters.isLoggedIn && this.$store.state.config.allow_anonymous && (this.$route.path.startsWith('/dashboards/home') || this.$route.path.startsWith('/dashboards/foodleap-about') || this.$route.path.startsWith('/dashboards/foodleap-contact'))
    },
    showAgritechHomepage () {
      return this.$store.getters.isAgriTech && !this.$store.getters.isLoggedIn && this.$store.state.config.allow_anonymous && this.$route.path.startsWith('/dashboards/home')
    },
    showSpacewerxHomepage () {
      return this.$store.getters.isSpacewerx && !this.$store.getters.isLoggedIn && this.$store.state.config.allow_anonymous && this.$route.path.startsWith('/dashboards/home')
    },
    config () {
      return this.$store.state.config
    },
    cookiePolicyUrl () {
      return this.config.cookiePolicyUrl || DEFAULT_URLS.COOKIE_POLICY
    },
    canOnlyCreateProducts () {
      return this.$store.getters.claimableActorTypes.includes('Product') && this.$store.getters.claimableActorTypes.length === 1
    },
    currentActivePage () {
      return this.$route.name
    },
    isMobile () {
      return this.$store.getters.isMobile
    },
    mobileRoute () {
      return this.$route.path.startsWith('/actors/') ||
          this.$route.path.startsWith('/login') ||
          this.$route.path.startsWith('/logout') ||
          this.$route.path.startsWith('/restricted') ||
          this.$route.path.startsWith('/register')
    },
    subnavEnabled () {
      return this.$store.getters.isOwner || this.$store.getters.isDeveloper
    },
    isNewProfileEnabled () {
      return this.$store.getters.isNewProfileEnabled
    },
    shouldShowFilters () {
      return this.$route.name === 'portfolio-detail' ||
          this.$route.name === 'portfolios' ||
          this.$route.path === '/datalab/spreadsheet' ||
          ((this.$route.name === 'explore' || [
            '/dashboards/connectedness',
            '/dashboards/scores-list',
            //'/dashboards/news',
          ].includes(this.$route.path)) && !this.$store.state.filters.portfolio) ||
          (this.$route.path === '/dashboards/analytics' && !this.$store.state.analytics.charts.expandedChart && !this.$store.state.filters.portfolio)
    },
    userCanCreate () {
      return this.$store.getters.userCanCreate
    },
    userCanJoin () {
      return !!this.$store.state.config.allowAdd && !this.$store.getters.isLoggedIn
    },
    userHasNotAcceptedCookies () {
      if (this.hasClosedCookiePopup) {
        return false
      }
      return !this.$store.getters.hasAcceptedCookies
    },
    showDisabledPlusCTA () {
      return this.$store.getters.hasAccessToEcosystemMemberPackages &&
          !this.userCanCreate &&
          this.$store.getters.isLoggedIn &&
          this.isRouteThatAllowsUpgrade &&
          this.isProductEnabled &&
          this.config.packageCTAs &&
          this.config.packageCTAs.product &&
          this.config.packageCTAs.product.enabled
    },
    isProductEnabled () {
      return this.$store.getters.viewActorTypes.includes('Product')
    },
    isRouteThatAllowsUpgrade () {
      const allowedRoutes = ['/dashboards/home', '/actors-simplified', '/products-simplified', '/intelligence-simplified', '/actors']

      for (const route of allowedRoutes) {
        if (this.$route.path.startsWith(route)) {
          return true
        }
      }

      return false
    },
    isExplorationOnly () {
      return this.$store.getters.isExplorationOnly
    },
    hidePlusButton () {
      return this.$route.path === '/settings/users' ||
          (this.$route.name === 'portfolio-detail' && this.$route.params.id !== 'create') ||
          this.$route.path === '/profile/update' ||
          this.$route.path.startsWith('/settings') ||
          this.$route.path.startsWith('/datalab') ||
          this.$route.name === 'gdpr-terms' ||
          ['announcements-simplified', 'announcements-detail', 'challenges', 'challenges-detail', 'actors-simplified', 'products-simplified', 'events-simplified'].includes(this.$route.name) ||
          (this.$route.name === 'actor-detail' && [SiteTemplate.SIMPLIFIED, SiteTemplate.NEW_SIMPLIFIED].includes(this.$store.state.config.siteTemplate)) ||
          !this.$store.getters.isLoggedIn ||
          this.isOnProductPage ||
          this.isExplorationOnly
    },
    isOnProductPage () {
      return this.$route.name === 'actor-detail' && this.$store.getters.detailActor && this.$store.getters.detailActor['actor_type'] === 'Product'
    },
    typeOfPanel () {
      return this.$store.state.ui.sidePanel.selectedComponent
    },
    isExpanded () {
      return this.$store.state.ui.sidePanel.expanded
    },
    isActor () {
      // To verify if the user is an ecosystem member
      return this.$store.getters.isActor
    },
    isMember () {
      return this.$store.getters.isMember
    },
    actorDetail () {
      return this.$store.getters.detailActor
    },
    homeLinks () {
      return window.config.homeLinks || []
    },
    canShowHomeLinks () {
      var hasHomeLinks = false

      this.homeLinks.forEach(item => {
        if (item.url.length > 0 && item.url !== ' ') {
          hasHomeLinks = true
        }
      })

      // The homelinks sub navigation should not be displayed on the home dashboard
      return hasHomeLinks && !this.$route.path.startsWith('/dashboards/home') && ![SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(this.$store.state.config.siteTemplate)
    },
    showPnGTopBar () {
      return this.$store.getters.isPnG && !this.$store.getters.isLoggedIn
    },
    showStaticTopBar () {
      return this.$store.getters.isAriadne && !this.$store.getters.isLoggedIn && this.$store.state.config.allow_anonymous
    },
    isFoodleap () {
      return this.$store.getters.isFoodleap
    },
    showOpenCreativesTopBar () {
      return this.$store.getters.isKlik && !this.$store.getters.isLoggedIn && this.$store.state.config.allow_anonymous
    },
  },
  methods: {
    handleToggleMobileNavigationDrawerVisibility () {
      this.isMobileNavigationDrawerOpen = !this.isMobileNavigationDrawerOpen
      this.isMobileSearchDrawerOpen = false
    },
    handleToggleMobileSearchDrawerVisibility () {
      this.isMobileSearchDrawerOpen = !this.isMobileSearchDrawerOpen
      this.isMobileNavigationDrawerOpen = false
    },
    notificationColor,
    notificationColorClass,
    notificationIconName,
    switchPanelState () {
      this.$store.commit(UI_MUTATION_TYPES.TOGGLE_SIDE_PANEL, true)
    },
    goToNotification (notification) {
      this.$notify({
        clean: true,
        group: 'alerts',
      })

      if (notification.text.startsWith('http')) {
        window.location.href = notification.text
      }

      if (!notification.text) {
        this.$router.push('/notifications')
      } else {
        this.$router.push(`/notifications/${notification.text}`)
      }
    },
    openRegisterModal () {
      if (this.$store.state.config.allowAdd) {
        if (this.$store.state.config.onboardingEnabled) {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ONBOARDING)
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ACTOR_SIGN_UP)
        }
      } else {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.SIGN_UP)
      }
    },
    goToUpgradePage () {
      this.$router.push('/profile/subscription#upgrade')
    },
    goToCreatePage (destination, tab, meta) {
      switch (destination) {
        case 'member':
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, { afterCreate: this.isMember ? 'addMore' : 'goToActor' })
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
          break
        case 'create-portfolio':
          this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'create-portfolio' })
          break
        case 'create-challenge':
          this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
            component: 'manage-challenge-panel',
            metaData: meta,
          })
          break
        case 'create-resource':
          this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
            component: 'create-resource-panel',
            metaData: meta,
          })
          break
        case 'actor-edit':
          var params = { id: this.actorDetail.id }
          // If the tab parameter is not undefined, we send it in order to be able to open the correct tab on the actor edit sid panel
          if (tab) {
            params = Object.assign(params, { openTab: tab })
          }
          this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
            component: 'actor-edit',
            metaData: params,
          })
          break
        case 'edit-connections':
          this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
            component: 'actor-connections-panel',
          })
          break
        case 'add-product':
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, meta.modalContext)
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
          break
        default:
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, { afterCreate: this.isMember ? 'addMore' : 'goToActor' })
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
          break
      }
    },
    closeMobileNavigationDrawer () {
      this.isMobileNavigationDrawerOpen = false
    },
    cookieClickedAccept () {
      this.hasClosedCookiePopup = true
      if (this.$store.getters.isLoggedIn) {
        acceptCookies()
            .then(response => {
              this.$store.commit(USER_MUTATION_TYPES.UPDATE_PROFILE, response.user)
            })
      } else {
        ls('userAcceptedCookies', true)
      }
    },
  },
  created () {
    if (this.$store.getters.isLoggedIn) {
      setTimeout(() => {
        this.$store.dispatch(ConceptSearchesActionTypes.FETCH_ALL_CONCEPT_SEARCHES)
      }, 3000)
    }
  },
  mounted () {
    this.$bus.on('showGlobalSearch', (value) => {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_GLOBAL_SEARCH)
      this.$refs.topBar.$refs.globalSearch.$refs.searchInput.focus()
    })

    trackHeapEvent('session.start')
    window.addEventListener('beforeunload', () => {
      trackHeapEvent('session.end')
    })

    this.$store.dispatch(SPOTTING_AREAS_ACTION_TYPES.FETCH_SPOTTING_AREA_HIGHLIGHT)

    if (this.$store.getters.isPublic || this.$store.getters.isLoggedIn) {
      this.$store.dispatch(TAXONOMIES_ACTION_TYPES.REFRESH_ALL_TAXONOMIES)
      this.$store.dispatch(ACTOR_RELATIONSHIP_ACTION_TYPES.FETCH_RELATIONSHIPS)
    }

    if (this.$store.getters.isLoggedIn) {
      this.$store.dispatch(ACTOR_RELATIONSHIP_ACTION_TYPES.FETCH_RELATIONSHIPS_META_DATA)
    }

    if (this.$store.getters.hasAccessToDictionaries && this.$store.getters.isLoggedIn) {
      this.$store.dispatch(DEFAULT_DICTIONARY_ACTION_TYPES.FETCH_DEFAULT_DICTIONARIES)
    }

    if (this.$store.getters.isLoggedIn && this.$store.getters.hasAccessToNewCommunities && !this.$store.state.communities.listData.loading) {
      this.$store.dispatch(COMMUNITY_ACTION_TYPES.FETCH_COMMUNITIES_LIST)
    }

    // Fetch the spotting areas for each visitor/user to be able to show the navigation item
    if (this.$store.getters.hasAccessToNewSpottingAreas && !this.$store.state.spottingAreas.listData.loading) {
      this.$store.dispatch(SPOTTING_AREAS_ACTION_TYPES.FETCH_SPOTTING_AREAS_LIST)
    }

    if (this.$store.getters.hasAccessToEcosystemMemberPackages && this.$store.getters.isLoggedIn && this.$store.getters.userRole === 'actor') {
      if (!this.$store.state.user.fetchPackageSubscriptionPromise) {
        this.$store.dispatch(USER_ACTION_TYPES.FETCH_PACKAGE_SUBSCRIPTION)
      }
      this.$store.state.user.fetchPackageSubscriptionPromise.then(() => {
        if (this.$store.getters.isActor && (!this.$store.state.user.profile.subscriptionInfo || !this.$store.state.user.profile.subscriptionInfo.package)) {
          this.$router.push('/dashboards/home')
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, { selectedPackage: null })
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CONFIRM_PACKAGE)
          getPaymentInfo()
              .then(response => {
                this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
                  paymentMethods: response,
                  isForced: true,
                })
              })
        }
      })
    }

    if (this.$store.state.user &&
        this.$store.state.user.profile &&
        this.$store.state.user.profile.id &&
        !this.$store.state.user.profile.settings.onboardingFinished &&
        this.$store.state.config.onboardingEnabled &&
        this.$route.path !== '/profile/update' &&
        this.$store.getters.userRole === 'actor'
    ) {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ONBOARDING)
    }

    this.$bus.on('toggleMobileSearchDrawerVisibility', () => {
      this.isMobileSearchDrawerOpen = !this.isMobileSearchDrawerOpen
      this.isMobileNavigationDrawerOpen = false
    })

    if (this.showOpenCreativesTopBar) {
      let locale = this.$store.getters.activeLocale
      if (!this.$store.getters.isLoggedIn) {
        locale = 'nl'
      }

      this.$store.dispatch(LOCALIZATION_ACTION_TYPES.UPDATE_ACTIVE_LOCALE, { locale: locale, i18n: this.$i18n })
    }

    if (document.querySelector('.global-search')) {
      document.querySelector('.global-search').style.transition = 'easein 300ms'
      document.querySelector('.global-search').style.opacity = 1
    }

    this.$store.commit(LOCALIZATION_MUTATION_TYPES.SET_ACTIVE_LOCALE, {
      locale: this.$store.getters.activeLocale,
      i18n: this.$i18n,
    })
  },
  watch: {
    subnavEnabled (val, old) {
      if (val && !old) {
        this.$root.collapsable.nav = true
      }
    },
    '$route.path' () {
      this.isMobileNavigationDrawerOpen = false
      this.isMobileSearchDrawerOpen = false
    },
  },
  beforeUnmount () {
    trackHeapEvent('pageExit', { to: 'close_tab' })
    this.$bus.off('toggleMobileSearchDrawerVisibility')
    this.$bus.off('showGlobalSearch')
  },
  mixins: [UiMixin, TranslationsMixin],
  components: {
    TopBarStaticSpacewerx,
    TopBarStaticAgritech,
    TopBarStaticFoodleap,
    TopBarOpenCreatives,
    SecondTopBar,
    CallToActionTopBar,
    MobileNavigationDrawer,
    MobileSearchDrawer,
    TopBar,
    TopBarStatic,
    Navigation,
    SubnavHomeLinks,
    EcosystemSwitcher,
    ModalsContainer,
    Icon,
    SidePanel,
    HoverOverlayButton,
  },
}
</script>
<style lang="scss">
@import "../scss/_variables.scss";

.mobile__logo {
  padding: 15px;
  margin: auto;

  img {
    width: 100%;
    max-width: 190px;
  }
}

.mobile__title {
  align-items: center;
  padding-left: 15px;
  font-size: 21px;
  font-family: 'Sofia Pro', 'Roboto', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  margin-bottom: 25px;
}

.mobile__tagline {
  text-transform: capitalize;
  font-family: 'Fira Sans', sans-serif;
  line-height: 1.5em;
  font-size: 1.25em;
  letter-spacing: 2px;
  padding-bottom: 15px;
  padding-left: 15px;
}

.mobile__cta {
  margin: auto !important;
  display: block !important;
  margin-bottom: 25px !important;
}

.mobile__description {
  line-height: 1em;
  font-size: 1em;
  font-family: 'Fira Sans', sans-serif;
  letter-spacing: 2px;
  padding-bottom: 15px;
  padding-left: 15px;
}

$pop-up-height: 40px;

.pop-up__notification {
  display: block;
  width: 270px;
  height: $pop-up-height;
  color: white;
  text-decoration: inherit;
  outline: none;
  background-color: $color-secondary;

  &:hover {

  }

  &:focus {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 0 2px $color-primary;
  }
}

.pop-up__all-notification {
  background-color: $color-secondary;
  color: white;
  display: block;
  height: $pop-up-height;
  line-height: 40px;
  outline: none;
  overflow: unset;
  text-align: center;
  text-decoration: inherit;
  width: 270px;
}

.pop-up__notification__icon {
  float: left;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: 15px;
  margin-top: 10px;
}

.pop-up__notification__content {
  margin-left: 5px;
  height: $pop-up-height;
}

.pop-up__title {
  width: 190px;
  display: inline-block;
  margin-left: 5px;
  margin-top: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.pop-up__ribbon {
  width: 5px;
  height: $pop-up-height;
  display: inline-block;
  position: absolute;
  left: 0;
}

.pop-up__close {
  cursor: pointer;

  .stroke-fg {
    stroke: $color-background-light-grey;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}

.pop-up__ribbon-after {
  position: relative;
  z-index: 5;
}

.pop-up__wrapper {
  cursor: pointer;
  margin-bottom: 5px;
}

.pop-up__slot {
  margin-bottom: 50px;
}

.application-plus-btn {
  right: 72px !important;
  bottom: 50px;
}

#cookie-panel {
  border: 1px solid #2A3646;
  border-radius: 0;
  font-family: "Fira Sans", sans-serif;
  font-size: 14px;

  .cookie__floating__buttons__button {
    background-color: $color-primary;
    color: white;
    border: none;

    &:hover {
      background-color: $color-primary-lighter;
    }
  }
}
</style>
