<template>
  <div class="page-spotting-area-detail scrollable spotting-areas" ref="scrollable">
    <SpottingAreaHero
      ref="spottingAreaHero"
      :collapse-hero="collapseHero"
      :spottingArea="spottingArea"
      :title="spottingArea.name"
      :showSubTitle="subNavSection !== 'map'"
    >
      <template v-slot:navigation>
        <div class="basic-hero-nav-bar">
          <div class="top-bar__links">
            <ds-button
              :class="{'second-top-bar__link-active': subNavSection === ''}"
              class="second-top-bar__link-item"
              @click="viewOverview"
              :label="$t('top_bar_overview')"
              variant="minimal"
            />
            <ds-button
              :class="{'second-top-bar__link-active': subNavSection === 'actors'}"
              class="second-top-bar__link-item"
              @click="viewActors"
              :label="$t('top_bar_actors')"
              variant="minimal"
            />
            <ds-button
              v-if="canAccessTrends"
              @click="viewTrends"
              :class="{'second-top-bar__link-active': subNavSection === 'trends' || subNavSection === 'trends-detail'}"
              class="second-top-bar__link-item"
              :label="$t('spotting_area_tab_trends')"
              variant="minimal"
            />
            <ds-button
              v-if="canAccessSuggestions"
              @click="viewActorSuggestions"
              :class="{'second-top-bar__link-active': subNavSection === 'suggestions', 'second-top-bar__link-item': true }"
              :label="$t('spotting_area_tab_suggestions')"
              variant="minimal"
            />
            <ds-button
              v-if="canAccessAnalytics"
              @click="viewSpottingAreaAnalytics"
              :class="{'second-top-bar__link-active': subNavSection === 'analytics', 'second-top-bar__link-item': true }"
              :label="$t('spotting_area_tab_analytics')"
              variant="minimal"
            />
          </div>
        </div>
      </template>
    </SpottingAreaHero>
    <div v-if="isLoading">
      <div v-show="isLoading" class="spider__loading">
        <icon name="loading"/>
      </div>
    </div>
    <div v-else :style="{'padding-top': collapseHero ? `${collapseHeight}px` : 0}">
      <div v-if="subNavSection === ''">
        <div class="container spotting-area-container">

          <!-- Statistics -->
          <router-link v-if="facetCounts.length" to="/" class="spotting-areas-title">
            <div>
              {{ $t('ecosystem_title_numbers', { ecosystem: spottingArea.name }) }}
            </div>
          </router-link>
          <facet-card
            :spotting-area-id="spottingAreaId"
            :source="'spotting-area'"
            :facet-counts="facetCounts"
            @click="facetCardClick"
          />

          <!-- Map view -->
          <div v-if="mapData.data && mapData.data.total > 0">
            <div class="container spotting-area-container statistics-container">
              <!-- Map view -->
              <div
                v-if="!isIE11() && mapData.data.total > 0"
                :class="{'statistics-container-card': true, 'spotting-area-detail__map-view': true}"
                style="width: 100%;"
              >
                <div style="height: 450px;" class="spotting-area-map__container">
                  <map-view
                    ref="mapPane"
                    :data="mapData.data"
                    :getMapData="getMapData"
                    :getData="getMapData"
                    :no-search-while-moving="true"
                    :disable-mouse-wheel-zoom="true"
                    :disable-expand-button="false"
                    style="width: 100%;"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Most relevant articles -->
          <template v-if="spottingAreaShowIntelligenceUpgradeCTA">
            <div class="spotting-areas-title">
              <icon name="lock"/>
              {{ $t('relevant_articles_title') }}
            </div>
            <div class="row spotting-area-detail__articles-container">
              <div class="col col-xs-12 col-sm-4 col-md-3 spotting-area-detail__article"
                   v-for="index in [1,2,3,4]"
                   :key="'fake-article' + index">
                <div class="carousel__slide-container">
                  <UnstyledLink target="_blank" class="inaccessible-article__cta-container">
                    <div class="intelligence-cta__overlay">
                      <h3 class="h3">
                        <router-link to="/profile/subscription#upgrade">{{ spottingAreaSplitCtaText[1] }}</router-link>
                      </h3>
                    </div>
                    <icon name="lock" class="lock-icon"/>
                    <MiniCard
                      class="inaccessible-article__cta"
                      title="inaccessible - upgrade required"
                      subtitle="inaccessible"
                      content="This content is not accessible because an upgrade to a higher-level package is required"
                    />
                  </UnstyledLink>
                </div>
              </div>
            </div>
          </template>

          <div v-if="!spottingAreaShowIntelligenceUpgradeCTA && canAccessTrends && articles && articles.length > 0">
            <div class="spotting-areas-title">
              {{ $t('relevant_articles_title') }}
            </div>

            <Carousel
              :settings="carouselSettings(2)"
              :breakpoints="carouselBreakpoints(2)"
            >

              <Slide
                v-for="(item, index) in articles"
                style="align-items: flex-start"
                :key="'article-' + index"
              >
                <div
                  style="max-width: 100%; padding: 10px; text-align: left; height: 100%;"
                >
                  <AnnouncementOrResourceCard
                    :key="'announcement-or-resource-card-' + item.id"
                    :override-url="isLoggedIn ? '' : loginUrl"
                    :data="item"
                    :preview-mode="false"
                    :show-image="false"
                    @like-article="likeArticle"
                    @addTag="addTagToFilters"
                    @resourceDeleted="fetchArticles()"
                  />
                </div>
              </Slide>
              <template #addons>
                <Navigation v-if="articles.length > 2"/>
              </template>
            </Carousel>
          </div>

          <!-- Most relevant actors -->
          <div
            style="margin-bottom: 30px;"
            v-if="relevantActors && relevantActors.length > 0"
          >
            <!--  class="report-data__container" -->
            <div class="spotting-areas-title">
              {{ $t('latest_updated_actors_title') }}
            </div>
            <Carousel
              :items-to-show="cardsPerCarouselPage"
              :mouse-drag="false"
            >
              <Slide
                v-for="(actor, index) in relevantActors"
                :key="'event' + index"
              >
                <div style="min-width: 100%; padding: 10px; text-align: left;">
                  <simplified-image-card
                    :name="actor.name"
                    :full-size="true"
                    :image="actor.featured_image_url || actor.website_screenshot"
                    :sub-title="localizedDisplayProperty(actor, 'activities_description') || localizedDisplayProperty(actor, 'description_stripped_tags') || localizedDisplayProperty(actor, 'short_description')"
                    :link="linkToActor(actor.id)"
                    :left-align-text="true"
                    @click="handleClickActorCard(actor)"
                    style="height: 100%; border-radius: 10px;"
                    class="spotting-area-simplified-image-card"
                  />
                </div>
              </Slide>
              <template #addons>
                <Navigation v-if="relevantActors.length > cardsPerCarouselPage"/>
              </template>
            </Carousel>
          </div>

          <!-- latest fund -->
          <div
            v-if="!latestFundingRounds.loading && latestFundingRounds && latestFundingRounds.data && latestFundingRounds.data.length > 0"
            style="margin-bottom: 30px;"
          >
            <div class="spotting-areas-title">
              {{ $t('latest_funding_rounds') }}
            </div>
            <Carousel
              :mouse-drag="false"
              :items-to-show="cardsPerCarouselPage"
            >
              <Slide
                v-for="(fundingRound, index) in latestFundingRounds.data"
                :key="'funding' + index"
              >
                <div style="min-width: 100%; padding: 10px; text-align: left;">
                  <simplified-image-card
                    :name="toDateString(fundingRound.started_at)"
                    :full-size="true"
                    :image="fundingRound.featured_image_url || fundingRound.website_screenshot || getDefaultFallbackImageUrl()"
                    :sub-title="`${fundingRound.name} raised ${toAmount(fundingRound.capital_raised)} ${fundingRound.currency} ${fundingRound.type !== 'other' ? `in ${fundingRound.type}` : ''}`"
                    :link="linkToActor(fundingRound.actorId)"
                    :left-align-text="true"
                    @click="handleClickActorCard(fundingRound.actorId)"
                    style="height: 100%; border-radius: 10px;"
                    class="spotting-area-simplified-image-card"
                  />
                </div>
              </Slide>
              <template #addons>
                <Navigation
                  v-if="latestFundingRounds.data.length > cardsPerCarouselPage"
                />
              </template>
            </Carousel>
          </div>
        </div>

        <!-- Total investments + Top investments + Map view -->
        <div
          v-if="false && hasInvestmentsData || hasInvestorsData"
          class="container spotting-area-container"
          style="margin-bottom: 30px;"
        >
          <div style="margin-bottom: 30px;">
            <div class="spotting-areas-title">
              {{ $t('total_investments') }} ({{ totalFunding }})
            </div>
          </div>
          <div class="row">
            <!-- Top investments -->
            <div
              v-if="hasInvestmentsData"
              class="col-xs-12 col-sm-12 col-md-6"
            >
              <SpottingAreaDetailInvestmentsCard
                :actors="funding.data.investments.top_investments"
              >
              </SpottingAreaDetailInvestmentsCard>
            </div>

            <!-- Top investors -->
            <div
              v-if="hasInvestorsData"
              class="col-xs-12 col-sm-12 col-md-6"
            >
              <SpottingAreaDetailInvestorsCard
                :actors="funding.data.investments.top_investors"
              >
              </SpottingAreaDetailInvestorsCard>
            </div>
          </div>
        </div>

      </div>
      <div
        v-if="subNavSection === '' && !isLoading && (facetCounts && facetCounts.length === 0) && (relevantActors && relevantActors.length === 0) && (articles && articles.length === 0) && (latestFundingRounds && latestFundingRounds.data.length === 0)">
        <div class="container spotting-area-container">
          <span class="spotting-area-empty-state">There is no information in this spotting area.</span>
        </div>
      </div>

      <template v-if="subNavSection === 'actors' && !$route.params.id">
        <ActorsSimplified
          style="padding-top: 20px"
          v-if="!loadingActors"
          :enable-banner="false"
          :card-class="'spotting-area-simplified-image-card'"
          :canAddActors="spottingArea.spotting_area_access === 'contributor'"
        />
        <loading v-else/>
      </template>
      <div
        class="spotting-area-actors scrollable"
        v-if="subNavSection === 'actors' && $route.params.id"
      >
        <SimplifiedActorDetail/>
      </div>

      <div
        class="spotting-area-signals"
        v-if="canAccessSignals && subNavSection === 'signals'"
      >
        <ProofPointsOverview/>
      </div>

      <div
        v-if="canAccessTrends && subNavSection === 'trends'"
        class="spotting-area-trends scrollable"
      >
        <SpottingAreaTrends
          :spotting-area-id="spottingAreaId"
          v-if="!loadingTrends"
          style="padding-top: 20px;"
        />
        <loading v-else/>
      </div>

      <div
        v-if="subNavSection === 'suggestions'"
        class="spotting-area-suggestions scrollable"
      >
        <SpottingAreaSuggestions
          :spotting-area-id="spottingAreaId"
          v-if="!loadingSuggestions"
          style="padding-top: 20px;"
        />
        <loading v-else/>
      </div>

      <div v-if="subNavSection === 'trends-detail' && file">
        <ArticleFileDetailSimplified
          :fileId="$route.params.id"
          :spottingAreaId="spottingAreaId"
          :file="file"
        ></ArticleFileDetailSimplified>
      </div>
      <div v-if="subNavSection === 'map'">
        <!--        moved to assets-instance -->
      </div>

      <div class="container" style="margin: 0 auto;" v-if="subNavSection === 'analytics'">
        <Analytics/>
      </div>
    </div>
  </div>
</template>

<script>
  import Avatar from '../../components/Avatar/Avatar.vue'
  import SpottingAreaHero from '../../components/Simplified/SpottingAreaHero.vue'
  import BasicHero from '../../components/BasicHero/BasicHero.vue'
  import CommunityDetailMembersCard from '../../components/Communities/CommunityDetailMembersCard.vue'
  import SpottingAreaDetailInvestmentsCard from '../../components/SpottingAreas/SpottingAreaDetailInvestmentsCard.vue'
  import SpottingAreaDetailInvestorsCard from '../../components/SpottingAreas/SpottingAreaDetailInvestorsCard.vue'
  import AnnouncementOrResourceCard from '../../components/Simplified/AnnouncementOrResourceCard.vue'
  import ActorsSimplified from '../../pages/Simplified/ActorsSimplified.vue'
  import SimplifiedImageCard from '../../components/Connection/SimplifiedImageCard.vue'
  import MapView from '../../components/MapView/MapView.vue'
  import CategoryCount from '../../components/CategoryCount/CategoryCount.vue'
  import Plotly from '../../components/Chart/Plotly.vue'
  import ArticlesPanel from '../../components/Dashboard/ConceptMap/ArticlesPanel.vue'
  import SimpleHeading from '../../components/Simplified/SimpleHeading.vue'
  import FilterCategory from '../../components/Simplified/FilterCategory.vue'
  import Loading from '../../components/Dashboard/ConceptMap/Loading.vue'
  import FacetCard from './FacetCard.vue'
  import ArticleFileDetailSimplified from '../FileDetail/ArticleFileDetailSimplified.vue'
  import Analytics from '../../components/Dashboard/Analytics.vue'
  import { getFileDetails } from '../../api/files.js'

  import { fetchLatestActors } from '../../api/spottingareas.js'
  import { trackHeapEvent } from '../../util/analytics.js'
  import { getCardsPerCarouselPageBasedOnWindowWidth, getDefaultFallbackImageUrl, isIE11 } from '../../util/helpers.js'

  import { toDateString } from '../../util/date.js'
  import { toAmount } from '../../util/currency.js'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import SpottingAreaMixin from '../../util/SpottingAreaMixin.js'
  import CarouselMixin from '../../util/CarouselMixin.js'
  import FiltersMixin from '../../util/FiltersMixin.js'
  import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../../store/modules/actors'
  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters'
  import { SiteTemplate } from '../../store/modules/config.js'
  import { ACTION_TYPES as SPOTTING_AREA_ACTION_TYPES } from '../../store/modules/spottingareas.js'
  import { fetchKnowledgeBaseData, likeArticle } from '../../api/knowledge-base.js'
  import { Carousel, Navigation, Slide } from 'vue3-carousel'

  import numeral from 'numeral'
  import { MUTATION_TYPES as KNOWLEDGE_BASE_MUTATION_TYPES } from '../../store/modules/knowledgeBase'
  import SimplifiedActorDetail from '../ActorDetail/SimplifiedActorDetail.vue'
  import { fetchLatestFundingRounds, fetchStatsData } from '../../api/homepage.js'
  import { getTranslationsForTaxonomyValue } from '../../store/modules/taxonomies.js'
  import SpottingAreaTrends from '../../components/SpottingAreas/SpottingAreaTrends.vue'
  import SpottingAreaSuggestions from '../../components/SpottingAreas/SpottingAreaSuggestions.vue'
  import IntroJsMixin from '../../util/IntroJsMixin.js'
  import _debounce from 'lodash/debounce.js'
  import ProofPointsOverview from '../../components/SpottingAreas/ProofPointsOverview.vue'
  import { defineComponent } from 'vue'
  import MiniCard from '@/components/MiniCard/MiniCard.vue'
  import UnstyledLink from '@/components/UnstyledLink/UnstyledLink.vue'

  export default defineComponent({
    components: {
      UnstyledLink, MiniCard,
      Analytics,
      SpottingAreaTrends,
      SpottingAreaSuggestions,
      FilterCategory,
      FacetCard,
      ArticleFileDetailSimplified,
      CommunityDetailMembersCard,
      SpottingAreaDetailInvestmentsCard,
      SpottingAreaDetailInvestorsCard,
      ActorsSimplified,
      AnnouncementOrResourceCard,
      BasicHero,
      Avatar,
      SpottingAreaHero,
      SimplifiedImageCard,
      MapView,
      CategoryCount,
      Plotly,
      ArticlesPanel,
      SimpleHeading,
      Loading,
      SimplifiedActorDetail,
      Carousel,
      Navigation,
      Slide,
      ProofPointsOverview,
    },
    data () {
      return {
        funding: {
          data: [],
          loading: false,
        },
        latestFundingRounds: {
          data: [],
          loading: false,
        },
        portfolioIds: {},
        portfolioSqlIds: {},
        simplifiedActors: [],
        relevantActors: [],
        articles: [],
        trends: [],
        facetCounts: [],
        loadingActors: false,
        loadingTrends: false,
        loadingSuggestions: false,
        fetchingContent: false,
        delayMapLoad: false,
        cardsPerCarouselPage: getCardsPerCarouselPageBasedOnWindowWidth(window.innerWidth),
        file: {},
        collapseHero: false,
        collapseHeight: 0,
        showMoreForActors: false,
        subLabel: '',
        isLoading: false,
      }
    },
    computed: {
      canAccessSignals () {
        return this.$store.getters.canSeeSpottingAreaMinervaFeatures &&
          this.$store.getters.hasAccessToNewSpottingAreas &&
          this.$store.state.spottingAreas.listData.data.length > 0
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      isTeamMember () {
        return this.$store.getters.isTeamMember
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      isMember () {
        return this.$store.getters.isMember
      },
      mapData () {
        return this.$store.state.actors.mapData
      },
      config () {
        return this.$store.state.config
      },
      availableFilterControls () {
        return this.config.simplifiedFilterControls || []
      },
      totalFunding () {
        if (this.funding !== null && this.funding.data !== null && this.funding.data.investments != null) {
          if (this.funding.data.investments.total_funding === 0) {
            return 0
          } else {
            return numeral(this.funding.data.investments.total_funding).format('$0.00a')
          }
        }
        return null
      },
      isSimplifiedTemplateUsed () {
        return [SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(this.$store.getters.activeSiteTemplate)
      },
      hasInvestmentsData () {
        return this.funding && this.funding.data.investments && this.funding.data.investments.top_investments && this.funding.data.investments.top_investments.length > 0
      },
      hasInvestorsData () {
        return this.funding && this.funding.data.investments && this.funding.data.investments.top_investors && this.funding.data.investments.top_investors.length > 0
      },
      spottingArea () {
        return this.$store.state.spottingAreas.detail.data
      },
      spottingAreaId () {
        return Number(this.$route.params.spottingAreaId)
      },
      actors () {
        return this.$store.state.actors.listData
      },
      subNavSection () {
        return this.$route.params.section
      },
      ecosystemDisplayName () {
        return this.$store.getters.ecosystemDisplayName
      },
      canAccessTrends () {
        // This should be a permission-based check, for now, we just return true, as the request was not clear enough at the time of writing this code
        // return this.isLoggedIn
        return true
      },
      canAccessAnalytics () {
        return this.$store.getters.isOwner || this.$store.getters.isTeamMember || (this.$store.getters.isPortfolioMember && (this.spottingArea.user_has_access && (this.spottingArea.spotting_area_access === 'contributor' || this.spottingArea.spotting_area_access === 'explorer')))
      },
      canAccessSuggestions () {
        return this.$store.getters.isOwner || this.$store.getters.isTeamMember || (this.$store.getters.isPortfolioMember && (this.spottingArea.user_has_access && this.spottingArea.spotting_area_access === 'contributor'))
      },
      loginUrl () {
        return `/login?redirect_uri=/spotting-areas/${this.spottingAreaId}`
      },
    },
    methods: {
      trackHeapEvent,
      getDefaultFallbackImageUrl,
      getLinkForFundingRound (fundingRound) {
        if (this.isOwner || this.isTeamMember || this.$store.getters.isPortfolioMember) {
          return `/spotting-areas/${this.spottingAreaId}/actors/${actorId}`
        }

        const accessibleSpottingArea = this.isSpottingAreaAccessibleForUser(this.spottingAreaId)

        if (!accessibleSpottingArea) {
          return this.loginUrl
        }

        return `/spotting-areas/${this.spottingAreaId}/actors/${fundingRound.actorId}`
      },
      toAmount,
      toDateString,
      linkToActor (actorId) {
        if (this.isOwner || this.isTeamMember || this.$store.getters.isPortfolioMember) {
          return `/spotting-areas/${this.spottingAreaId}/actors/${actorId}`
        }

        if (this.$store.getters.hasAccessToNewSpottingAreas) {
          const accessibleSpottingArea = this.isSpottingAreaAccessibleForUser(this.spottingAreaId)

          if (!accessibleSpottingArea) {
            return this.loginUrl
          }
        }

        return `/spotting-areas/${this.spottingAreaId}/actors/${actorId}`
      },
      formatMoney (amount) {
        return numeral(amount).format('$0.00a')
      },
      showActor (actorContainer) {
        this.$router.push('/spotting-areas/actors/' + actorContainer.id)
      },
      isIE11,
      facetCardClick (facet) {
        if (!this.isLoggedIn) {
          if (this.spottingArea.user_has_access) {
            this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)

            // Making sure that the spotting area is set when fetching the actors
            this.$store.commit(FILTERS_MUTATION_TYPES.SET_SPOTTING_AREA, this.spottingAreaId)
            this.$store.commit(FILTERS_MUTATION_TYPES.ADD_KEYWORD, {
              facet: facet.facetType,
              value: facet.value,
            })

            this.$router.push(`/spotting-areas/${this.spottingAreaId}/actors`)
            return
          }

          this.$router.push(this.loginUrl)
          return
        }

        this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)

        // Making sure that the spotting area is set when fetching the actors
        this.$store.commit(FILTERS_MUTATION_TYPES.SET_SPOTTING_AREA, this.spottingAreaId)
        this.$store.commit(FILTERS_MUTATION_TYPES.ADD_KEYWORD, {
          facet: facet.facetType,
          value: facet.value,
        })

        this.$router.push(`/spotting-areas/${this.spottingAreaId}/actors`)
      },
      viewOverview () {
        this.$store.dispatch(SPOTTING_AREA_ACTION_TYPES.FETCH_SPOTTING_AREA_DETAIL, this.spottingAreaId)

        this.fetchFacetCounts()
        this.fetchFunding()
        this.fetchLatestActors()
        this.getMapData()

        if (this.canAccessTrends) {
          this.fetchArticles()
        }

        this.$router.push(`/spotting-areas/${this.spottingAreaId}`)
      },
      viewActors () {
        if (!this.isLoggedIn) {
          if (this.hasAccessToNewSpottingAreas && this.spottingArea.user_has_access) {
            this.$router.push(`/spotting-areas/${this.spottingAreaId}/actors`)
            return
          }

          this.$router.push(this.loginUrl)
          return
        }

        // Making sure that the spotting area is set when fetching the actors
        this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)
        this.$store.commit(FILTERS_MUTATION_TYPES.SET_SPOTTING_AREA, this.spottingAreaId)

        this.$router.push(`/spotting-areas/${this.spottingAreaId}/actors`)
      },
      viewMap () {
        if (!this.isLoggedIn) {
          this.$router.push(this.loginUrl)
          return
        }
        this.$router.push(`/spotting-areas/${this.spottingAreaId}/map`)
      },
      viewTrends () {
        if (!this.isLoggedIn) {
          if (!this.spottingArea.user_has_access) {
            this.$router.push(this.loginUrl)
            return
          }
        }

        if (!this.canAccessTrends) {
          this.$router.push(`/spotting-areas/${this.spottingAreaId}`)
          return
        }

        this.$router.push(`/spotting-areas/${this.spottingAreaId}/trends`)
      },
      viewSpottingAreaAnalytics () {
        if (!this.isLoggedIn) {
          this.$router.push(this.loginUrl)
          return
        }

        this.$router.push(`/spotting-areas/${this.spottingAreaId}/analytics`)
      },
      viewActorSuggestions () {
        if (!this.isLoggedIn) {
          this.$router.push(this.loginUrl)
          return
        }

        this.$router.push(`/spotting-areas/${this.spottingAreaId}/suggestions`)
      },
      viewSignals () {
        if (!this.canAccessSignals) {
          return
        }

        this.$router.push(`/spotting-areas/${this.spottingAreaId}/signals`)
      },
      handleClickActorCard (actor) {
        trackHeapEvent('spotting-area.clickActorCard')
      },
      likeArticle ({ resourceId, status }) {
        likeArticle(resourceId, status)
      },
      addTagToFilters (tag) {
        if (!tag.value) {
          return
        }

        this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.CLEAR)
        this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.SET_FACET_KEYWORD, tag)

        if (this.$route.name && this.$route.name.indexOf('knowledge-base') <= 0) {
          this.$router.push('/dashboards/knowledge-base')
        }
      },
      fetchFacetCounts () {
        const parameters = {
          spotting_area: this.spottingAreaId,
        }

        fetchStatsData(parameters)
          .then(response => {
            const resultOrdered = response.data.stats.map(result => {
              const translations = getTranslationsForTaxonomyValue(response.data.property, result.value)
              let label = ''

              if (translations && translations[this.locale] && translations[this.locale].name) {
                label = translations[this.locale].name
              } else {
                label = translations['en'].name
              }

              result['label'] = label
              result['count'] = result.total
              result['facetName'] = response.data.property

              return result
            })

            // Only return the top 6
            this.facetCounts = resultOrdered
              .sort((a, b) => b.count - a.count)
              .slice(0, 6)
          })
          .catch(errors => {
            //
          })
      },
      fetchLatestActors () {
        fetchLatestActors({ spottingAreaId: this.spottingAreaId })
          .then(response => {
            if (response) {
              this.relevantActors = response
            }
          })
          .finally(() => {
            this.relevantActors.loading = false
          })
      },
      fetchFunding () {
        this.latestFundingRounds.loading = true

        fetchLatestFundingRounds({ spotting_area: this.spottingAreaId })
          .then(response => {
            this.latestFundingRounds.data = response
            this.latestFundingRounds.loading = false
          })
      },
      async loadContent () {
        if (!this.$store.getters.hasAccessToNewSpottingAreas) {
          this.$router.push('/')
        }

        if (this.subNavSection === '') {
          this.fetchFacetCounts()
          this.fetchFunding()
          this.fetchLatestActors()
          this.getMapData()

          if (this.canAccessTrends) {
            this.fetchArticles()
          }
        }

        // When the user doesn't have access to the trends section, show the "overview" section
        if (this.subNavSection === 'trends') {
          if (!this.canAccessTrends) {
            this.viewOverview()
          }
        }

        if (this.subNavSection === 'trends-detail') {
          if (!this.$route.params.id) {
            return
          }
          getFileDetails(this.$route.params.id)
            .then(response => {
              this.file = response
            })
        }

        await this.$store.dispatch(SPOTTING_AREA_ACTION_TYPES.FETCH_SPOTTING_AREA_DETAIL, this.spottingAreaId)
        setTimeout(() => {
          this.startIntroJs()
          // This timeout is required to wait for the simplifiedImageCard timeout
        })
      },
      getMapData () {
        // This makes it so that the actor filters are set with the spotting area ID, but ideally, things like the actor simplified know this already, or perhaps even better,
        // a different component (router?) keeps track of the required additional filters, like spotting area ID
        if (this.subNavSection !== 'actors') {
          this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_SPOTTING_AREA, this.spottingAreaId)
        }

        let filters
        if (this.$store.state.config && this.$store.state.config.viewActorTypes && this.$store.state.config.viewActorTypes.length) {
          filters = Object.assign({}, this.$store.getters.mapFilterObject, { actor_type: this.$store.state.config.viewActorTypes })
        } else {
          filters = Object.assign({}, this.$store.getters.mapFilterObject)
        }

        // if (filters.tl) {
        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_MAP, filters)
        // }
      },
      fetchArticles () {
        this.fetchingContent = true

        const filters = {
          media_types: ['rss', 'pr', 'blog'],
          spotting_area: this.spottingAreaId,
          'ml-supported': true,
          limit: 5, // We assume this amount will suffice to feed the content UI component on the detail page
          timespan: 'day',
        }

        // TODO: this needs to be written in a better way, preferably the endpoint can work out the most recent, most relevant ones
        // Just like we do with the home controller, but then with filters as support
        fetchKnowledgeBaseData(filters)
          .then(response => {
            this.articles = response.data.results || []
            this.fetchingContent = false

            if (this.articles.length > 1) {
              return
            }

            filters.timespan = 'week'

            fetchKnowledgeBaseData(filters)
              .then(response => {
                this.articles = this.articles.concat(response.data.results)
                this.fetchingContent = false

                if (this.articles.length > 1) {
                  return
                }

                filters.timespan = 'year'

                fetchKnowledgeBaseData(filters)
                  .then(response => {
                    this.articles = this.articles.concat(response.data.results)
                    this.fetchingContent = false
                  })
                  .catch(error => {
                    console.log(error)
                  })
              })
              .catch(error => {
                console.log(error)
              })
          })
          .catch(error => {
            console.log(error)
          })
      },

      startIntroJs () {
        if (this.seenIntros.includes('spottingAreasDetailPage')) {
          return
        }
        const config = this.buildIntroJsConfig(this.config, this.$store.getters.userRole)
        if (!config || !config.spottingAreasDetailPage || !config.spottingAreasDetailPage.steps) {
          return
        }

        config.spottingAreasDetailPage.steps = this.getIntroJsStepsConfig(config.spottingAreasDetailPage.steps)
        const intro = this.introJs().setOptions(config.spottingAreasDetailPage)
        this.currentIntro = intro

        const componentScope = this
        intro.onbeforechange(this.introJsBeforeStepCallback.bind(this))
        intro.onafterchange(this.introJsAfterStepCallback.bind(this))
        intro.oncomplete(function () {
          componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
        })
        intro.onexit(function () {
          componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
        })

        intro.start()
      },
      emitScrollEvent: _debounce(function (e) {
        this.$bus.emit('scrollToEndOfSpottingAreaPage')
      }, 200),
      onScroll (e) {
        if ((e.target.scrollTop + e.target.offsetHeight + 1000) > e.target.scrollHeight) {
          this.emitScrollEvent()
        }
        if (!this.$refs.scrollable || !this.$refs.spottingAreaHero || !this.$refs.spottingAreaHero.$el) {
          return
        }

        const scrollHeight = this.$refs.scrollable.scrollTop
        const headerHeight = this.$refs.spottingAreaHero.$el.clientHeight
        const navHeight = 70
        if (this.collapseHero) {
          if ((scrollHeight - this.collapseHeight + navHeight) >= 0) {
            this.collapseHero = true
          } else {
            this.collapseHero = false
            this.collapseHeight = 0
          }
          return
        }

        this.collapseHero = scrollHeight >= (headerHeight - navHeight)
        this.collapseHeight = headerHeight
      },
    },
    async mounted () {
      if (this.subNavSection && !this.isLoggedIn) {
        this.$router.push(this.loginUrl)
        return
      }

      this.isLoading = true
      await this.loadContent()
      this.isLoading = false
      this.$refs.scrollable.addEventListener('scroll', this.onScroll, { passive: true })

      if (this.spottingArea && this.spottingArea.user_has_access && window.config.spottingAreasFocus && window.config.spottingAreasFocus === 'actors') {
        this.viewActors()
      }

      if (!this.spottingArea.user_has_access) {
        this.$router.push('/spotting-areas/')
      }
    },
    beforeUnmount () {
      this.$refs.scrollable.removeEventListener('scroll', this.onScroll, { passive: true })
      // Swap back to the default legend property
      this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)
      this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.CLEAR)
      const legendProperty = this.$store.getters.defaultLegendProperty

      this.$store.commit(FILTERS_MUTATION_TYPES.SET_LEGEND_PROPERTY, {
        property: legendProperty,
        items: this.getLegendItems(legendProperty),
      })
    },
    mixins: [TranslationsMixin, SpottingAreaMixin, FiltersMixin, IntroJsMixin, CarouselMixin],
  })
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables.scss";

  .page-spotting-area-detail {
    height: 100%;
    display: flex;
    flex-direction: column;

    .statistics-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .statistics-container-cards {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        column-gap: 50px;
        padding-bottom: 15px;
      }
    }

    .spotting-area-detail__sub-nav {
      .active {
        text-decoration: underline;
      }
    }

    .button.is-beta-feature {
      display: flex;
      align-items: center;

      :deep(.button__sub-label) {
        margin-left: 10px;
        background-color: white; // #02B78B
        color: var(--primary);

        padding: 4px;
        border-radius: 4px;
        font-size: 8px;

        &:last-child {
          :deep(.button__sub-label) {
            margin-left: 10px;
            background-color: white; // #02B78B
            color: var(--primary);
            padding: 4px;
            border-radius: 4px;
            font-size: 8px;
          }
        }

      }
    }
  }

  .spotting-area-empty-state {
    display: block;
    text-align: center;
    width: 100%;
    margin-top: 150px;
  }

  .filters {
    overflow: hidden;
    overflow-y: auto;
    padding: 1.5rem 2rem;

    @media (max-width: $screen-md) {
      padding: 1.5rem;
    }
  }

  .filters-title-container {
    display: flex;
    align-items: center;
  }

  .page-spotting-area-detail {
    h1.h1 {
      font-size: 26px;
      text-transform: uppercase;
      font-family: $font-stack-secondary;
      font-weight: 200;
    }

    h4.h4 {
      font-family: $font-stack-secondary;
      font-weight: 200;
      font-size: 16px;
    }

    .spotting-areas-title {
      text-transform: uppercase;
      font-size: 1.5rem;
      color: black;
      text-decoration: none;
      display: block;
      margin-top: 2rem;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .spotting-areas-articles-card {
      margin-bottom: 15px;
    }
  }

  .report-data-map__container {
    position: relative;
    height: 100%;
    width: 100%;
    border: 1px solid $color-borders;

    @media (max-width: $screen-lg) {
      height: 290px;
    }
  }

  .report-data__container {
    width: 100%;
    padding: 2rem;
    margin-bottom: 2rem;
    border: 1px solid $color-borders;
    font-size: 12px;

    .horizontal-block {
      display: flex;
    }

    .vertical-block {
      display: flex;
      flex-direction: column;

      .vertical-block__fill {
        flex-grow: 1;
      }
    }
  }

  .report-data-stats__container {
    width: 50%;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;

    div:last-child {
      flex-grow: 1;

      :deep(.charts__plot) {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .has-padding {
    font-size: 0;
  }

  /*h3.h3 {
    font-size: 20px;
    line-height: 22px;

    &.clickable {
      cursor: pointer;
    }
  }*/

  h5.h5 {
    font-size: 16px;
    font-family: $font-stack-secondary;
    margin: 2rem 0 1rem 0;
    color: $color-text-grey-light;
  }

  .table {
    font-size: 16px;

    th, td {
      border-left: 0;
      padding: 10px;
    }

    th {
      height: 45px;
    }
  }

  .svg-icon {
    cursor: pointer;
  }

  @media print {
    .heading {
      display: none;
    }
  }

  a {
    cursor: pointer;
  }

  h6.h6 {
    font-size: 14px;
  }

  .dashboard-subtitle__container {
    display: flex;
    width: 100%;

    p {
      height: 100%;
      margin: auto 0;
      padding-right: 20px;
    }

    .select-quarter {
      width: 150px;
      margin-left: auto;
      display: flex;

      :deep(.multiselect__tags) {
        height: 100%;
      }
    }
  }

  .spotting-area-map__container {
    position: relative;
    height: 100%;

    @media screen and (max-width: $screen-xl) {
      height: 250px;
      margin-bottom: 1rem;
    }
  }

  .spotting-area-detail__statistics {
    flex-grow: 1;
  }

  .spotting-area-detail__map-view {
    // max-width: 600px;
    width: 100%;
  }

  .spotting-area-detail__top-investments__table {
    border: 0;
  }

  .spotting-area-detail__top-investments-row {
    height: 36px;
    border: 0;
    cursor: pointer;
  }

  .spotting-area-detail__top-investments {
    border-radius: $default-border-radius;
    box-shadow: $simplified-box-shadow-config;
    // height: 450px;
    padding: 10px;
  }

  .statistics-container-card {
    margin-bottom: 20px;
  }


  :deep(.inaccessible-article__cta-container) {
    position: relative;
    overflow-y: hidden;
    display: block;

    .inaccessible-article__cta {
      div {
        color: #0000001c;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      }
    }

    .lock-icon {
      position: absolute;
      right: 10px;
      top: 10px;
      fill: var(--primary)
    }

    &:hover,
    &:focus {
      .intelligence-cta__overlay {
        transform: translate3d(0, 0, 0);
      }
    }

  }

  .intelligence-cta__overlay {
    padding: 50px;
    margin: 1px;
    // padding: 0 5px;
    position: absolute;
    height: calc(100% - 1rem - 2px);
    width: calc(100% - 2px);
    background: rgba(255, 255, 255, 0.7);
    top: 0;
    transition: transform 300ms;
    transform: translate3d(0, 100%, 0);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

</style>
