<template>
  <div style="overflow:hidden;">
    <div class="heading">
      <h1 style="display:inline-block">Connections</h1>
      <div class="explore-content__filter-button-container">
        <div class="explore-content__filter-indicator" v-if="keywords.length > 0 && keywords.find((f) => f.facet !== 'actor_type')"></div>
        <ds-button variant="minimal" size="small" icon="filter-box" label="Filter" class="explore-content__filter-button"
          :class="{ 'explore-content__filter-button-active' : this.$store.state.filters.filter.visible }" @click="toggleFilter"/>
      </div>
      <p class="guide_text">Discover relationships between actors and stakeholders.</p>
    </div>
    <top-filters/>
    <div style="overflow:scroll; display: grid; height: 76vh;">
      <!--<div style="position: absolute; right: 18em">
        <legend-dropdown style="position: relative; z-index: 107;" tooltipText="" :style="legendBoxCoordinatesStyling" :draggable="onDragged"/>
      </div>-->
      <div style="position: absolute; right: 0px; z-index: 107; width: 225px;" v-if="legendValues && legendValues.length > 0">
        <h4 style="padding-top: 10px; padding-bottom: 10px;">Relationships</h4>
        <div>
          <ul>
            <li v-for="relation in legendValues" :key="relation.name">
              <div class="legend_dropdown__legend-text" v-if="relation.count > 0"><input type="checkbox" @click="handleLegendProperty(relation.name)"
                :value="relationshipIsVisible(relation.label)"/> {{ relation.label }}
                ({{ relation.count }})
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="spider scrollable" :class="{ 'd3-loading': loading }">
        <div class="spider__container">
          <transition name="fade">
            <div v-show="graphEnabled" class="spider__svg" :style="{ fontSize: fontSize + 'px' }"></div>
          </transition>
        </div>
        <div v-show="loading || ! dataReady" class="spider__loading">
          <icon name="loading"/>
        </div>
        <div v-show="!loading && dataReady && !graphEnabled" class="spider__empty">
          no relations to display
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import uniqBy from 'lodash/uniqBy.js'

  import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../../store/modules/actors.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import { MUTATION_TYPES as FILTER_MUTATION_TYPES } from '../../store/modules/filters.js'

  import { inert } from '../../util/helpers.js'
  import { loadChunk } from '../../util/chunk-loader.js'

  import FiltersMixin from '../../util/FiltersMixin.js'

  import TopFilters from '../../components/Filters/TopFilters.vue'
  import LegendDropdown from '../../components/Filters/LegendDropdown.vue'
  // For the drag and drop functionality of the legend
  import { fetchRelationships } from '../../api/actor-relationships.js'
  import { defineComponent } from 'vue'

  function fontSize (num) {
    if (num < 60) return 14
    if (num < 100) return 12
    if (num < 150) return 10
    if (num < 200) return 9
    if (num < 250) return 8
    if (num < 300) return 7
    return 6
  }

  export default defineComponent({
    data () {
      return {
        actor: '',
        count: {},
        fontSize: 8,
        // showBack: false,
        graphEnabled: false,
        dataReady: false,
        actorRelationships: [], // We don't use the vuex state here because it seems that the functions we create in the methods below are not responsive to state changes
        visibleRelationships: [],
        countedRelationships: [],
      }
    },
    computed: {
      fullActorRelationships () {
        // Return all relationships, with "forward" and "inverse" being individual objects in the return object
        var relationships = []

        if (!this.actorRelationships) {
          return relationships
        }

        this.actorRelationships.forEach(relationship => {
          relationships.push({
            name: relationship.name,
            label: relationship.label,
            inverse_name: relationship.inverse_name,
            inverse_label: relationship.inverse_label,
            is_forward_connection: true,
          })

          relationships.push({
            name: relationship.inverse_name,
            label: relationship.inverse_label,
            inverse_name: relationship.name,
            inverse_label: relationship.label,
            is_forward_connection: false,
          })
        })

        return relationships
      },
      filterableRelationships () {
        var relationshipNames = this.fullActorRelationships.filter(rel => !['main_company', 'subsidiaries'].includes(rel.name))

        return relationshipNames.map(rel => rel.name)
      },
      actors () {
        return this.$store.state.actors.graphData.data
      },
      loading () {
        return this.$store.state.actors.graphData.loading
      },
      hex () {
        return Object.values(this.$store.state.config.hexColours)
      },
      filters () {
        return this.$store.getters.baseFilterObject
      },
      keywords () {
        return this.$store.state.filters.keywords
      },
      defaultLegend () {
        // Investor as default category might also cause trouble
        // const c = this.legendItems[0].label || 'Company'
        const c = this.legendItems[0] || 'Organization'
        return !c || ['Accelerator', 'Investment Firm / Fund'].includes(c) ? 'Organization' : c
      },
      total () {
        return Object.keys(this.count).map(a => this.count[a]).reduce((a, b) => a + b) || 1
      },
      acc () {
        return this.actor && this.actor.accelerators && this.actor.accelerators.map(a => a.name).join('\n')
      },
      inv () {
        return this.actor && this.actor.investors && this.actor.investors.map(i => i.name).join('\n')
      },
      comp () {
        return this.actor && this.actor.companies && this.actor.companies.map(i => i.name).join('\n')
      },
      legendValues () {
        const relationships = []

        this.countedRelationships = []

        this.actorRelationships.forEach(relation => {
          this.actors.forEach(actor => {
            if (actor[relation.name]) {
              for (let i = 0; i < actor[relation.name].length; i++) {
                this.countedRelationships.push(relation.name)
              }
            } else if (actor[relation.inverse_name]) {
              for (let i = 0; i < actor[relation.inverse_name].length; i++) {
                this.countedRelationships.push(relation.inverse_name)
              }
            }
          })
        })

        this.actorRelationships.forEach(relation => {
          relationships.push({
            id: relation.id,
            name: relation.name,
            label: relation.label,
            count: this.countedRelationships.filter(relationships => relationships === relation.name).length,
          })

          relationships.push({
            id: relation.id,
            name: relation.inverse_name,
            label: relation.inverse_label,
            count: this.countedRelationships.filter(relationships => relationships === relation.inverse_name).length,
          })
        })

        return relationships.sort((a, b) => {
          return a.label - b.label
        })
      },
      /* colorMap () {
        var color = {}
        for (let i = 0; i < this.legendItems.length; ++i) {
          color[this.legendItems[i]] = this.legendColor(this.legendItems[i])
        }
        return color
      }, */
      textColorMap () {
        var color = {}

        for (let i = 0; i < this.legendItems.length; ++i) {
          color[this.legendItems[i].value] = this.legendColor(this.legendItems[i]) === '#fecc00' ? '#ddaa00' : this.legendColor(this.legendItems[i])
        }

        color.accelerated_by = this.hex[3]
        color.invested_by = this.hex[2]
        return color
      },
      legendBoxProperties () {
        // We check first if there is values in the state belonging to the 'connectedness'
        // Otherwise, in the case of the user refreshing the browser, we fetch the value saved in the user settings
        // Simply fetching directly from the state wont work as the state can contain objects from other legend boxes
        // resulting in the reset of the position of the other legend boxes, so we search only for the values for the 'connectedness'
        var legendPropertiesArray = []
        if (this.$store.getters.legendBoxProperties.length > 0) {
          this.$store.getters.legendBoxProperties.forEach((item, index) => {
            if (item.name == 'connectedness') {
              legendPropertiesArray.push(item)
            }
          })
        }
        return legendPropertiesArray.length > 0 ? legendPropertiesArray : this.$store.getters.userSettings.legendBoxProperties !== undefined ? this.$store.getters.userSettings.legendBoxProperties : []
      },
      connectednessLegendBoxProperties () {
        return this.legendBoxProperties.filter(legend => legend.name == 'connectedness')
      },
      legendBoxCoordinatesStyling () {
        // This will set the left and top values for the legend box
        // If there is a value in the state for the coordinates then we apply that value to the styling
        // otherwhise we set the fallback value for the style
        var values = {
          left: '1em',
          top: '1em',
        }
        if (this.connectednessLegendBoxProperties.length > 0) {
          // We check first if the coordinates are out of the visible bounds and if so we return values that enable the legend box to be placed in the visible area of the screen
          var checkedCoordinates = this.checkLegendBoxCoordinates(this.connectednessLegendBoxProperties[0].coordinates)
          values.left = checkedCoordinates.x + 'px'
          values.top = checkedCoordinates.y + 'px'
        }

        return `left: ${values.left}; top: ${values.top}`
        return `left: ${values.left};  top: ${values.top}`
      },
    },
    methods: {
      fetch (useDefaultFilters = true, filters) {
        if (useDefaultFilters) {
          this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_GRAPH, this.filters)
        } else {
          this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_GRAPH, filters)
        }
      },
      fetchRelationships () {
        return fetchRelationships()
      },
      toggleFilter () {
        if (this.$store.state.filters.filter.visible) {
          this.$store.commit(FILTER_MUTATION_TYPES.TOGGLE_FILTER, false)
        } else {
          this.$store.commit(FILTER_MUTATION_TYPES.TOGGLE_FILTER, true)
        }
      },
      relationshipIsVisible (relation) {
        return this.visibleRelationships.includes(relation)
      },
      async handleLegendProperty (relationshipLabel) {
        const filters = this.filters

        if (!this.visibleRelationships.includes(relationshipLabel)) {
          this.visibleRelationships.push(relationshipLabel)
          filters.visibleRelationships = this.visibleRelationships
        } else {
          this.visibleRelationships = this.visibleRelationships.filter(label => label !== relationshipLabel)
          filters.visibleRelationships = this.visibleRelationships.filter(label => label !== relationshipLabel)
        }

        setTimeout(() => {
          this.fetch(false, filters)
        }, 10)
      },
      toColor (rel) {
        if (!rel) {
          return
        }

        if (rel.legend && this.textColorMap[rel.legend.value]) {
          return this.textColorMap[rel.legend.value]
        }

        if (rel.category) {
          return this.textColorMap[rel.category]
        }
        if (rel.source && rel.target) {
          if (rel.source.accelerated_by && rel.target.accelerated) {
            return this.hex[3]
          }
          if (rel.source.invested_by && rel.target.invested) {
            return this.hex[2]
          }
        }
        if (rel.accelerated) {
          return this.hex[3]
        }
        if (rel.invested) {
          return this.hex[2]
        }

        if (rel.from) {
          return this.toColor(this.map[rel.from])
        }
        if (rel.to) {
          return this.toColor(this.map[rel.to])
        }

        return '#888'
      },
      pct (num) {
        return num ? (num * 100 / this.total) + '%' : 0
      },
      invalidateSize () {
        setTimeout(() => this.render(), 500)
      },
      render () {
        loadChunk('d3', this.actualRender)
      },
      actualRender () {
        var d3 = window.d3
        var vm = this

        // Clear previous content before rendering
        d3.select(vm.$el).select('.spider__svg').selectAll('*').remove()

        if (!this.actors || this.actors.length === 0) {
          vm.graphEnabled = false
          this.dataReady = true
          return
        }

        const actors = inert(this.actors) // Temporary workaround because actors is now passed in as a prop

        var diameter = 600
        var radius = diameter / 2
        var innerRadius = radius - 150

        var cluster = d3.layout.cluster()
          .size([360, innerRadius])
          .sort(null)
          .value(function (d) {
            return d.name && d.name.length
          })

        var bundle = d3.layout.bundle()

        var line = d3.svg.line.radial()
          .interpolate('bundle')
          .tension(0.5)
          .radius(function (d) {
            return d.y
          })
          .angle(function (d) {
            return d.x / 180 * Math.PI
          })

        var svg
        var link
        var node

        // Start render
        svg = d3.select(vm.$el).select('.spider__svg').append('svg')
          .attr('viewBox', `0 0 ${diameter} ${diameter}`)
          .append('g')
          .attr('transform', 'translate(' + radius + ',' + radius + ')')

        link = svg.append('g').selectAll('.d3-link')
        node = svg.append('g').selectAll('.d3-node')

        var nodes = cluster.nodes(packageHierarchy(actors))
        var { links, map } = actorRelationships(nodes)
        const edgeNodes = nodes.filter(n => !n.children)

        nodes = nodes.filter(Boolean)
        vm.map = map
        vm.graphEnabled = edgeNodes.length > 1 && links.length

        if (!vm.graphEnabled) {
          console.warn('SpiderGraph got', nodes.length, 'nodes and', links.length, 'links. That is not enough.')
          return
        }
        // console.debug('SpiderGraph got', nodes.length, 'nodes and', links.length, 'links.')

        const $elem = $(this.$el)
        vm.fontSize = fontSize(nodes.length)

        link = link
          .data(bundle(links))
          .enter().append('path')
          .each(function (d) {
            d.source = d[0]
            d.target = d[d.length - 1]
          })
          .attr('class', 'd3-link')
          .attr('d', line)

        node
          .data(edgeNodes)
          .enter().append('circle')
          .attr('r', nodes.length < 50 ? 3 : 2)
          .attr('transform', function (d) {
            return 'rotate(' + (d.x - 90) + ')translate(' + (d.y + 6) + ',0)' + (d.x < 180 ? '' : 'rotate(180)')
          })
          .style('fill', this.toColor)

        node = node
          .data(edgeNodes)
          .enter().append('text')
          .style('fill', 'black')
          .attr('class', 'd3-node')
          .attr('dy', '.31em')
          .attr('transform', function (d) {
            return 'rotate(' + (d.x - 90) + ')translate(' + (d.y + 12) + ',0)' + (d.x < 180 ? '' : 'rotate(180)')
          })
          .style('text-anchor', function (d) {
            return d.x < 180 ? 'start' : 'end'
          })
          .text(function (d) {
            return d.name
          })
          .on('mouseover', mouseovered)
          .on('mouseout', mouseouted)
          .on('click', click)

        function mouseovered (d) {
          vm.actor = d

          node.each(function (n) {
            n.target = n.source = false
          })

          /* eslint-disable */
          link
            .classed('link--target', function (l) {
              if (l.target === d) return l.source.source = true
            })
            .classed('link--source', function (l) {
              if (l.source === d) return l.target.target = true
            })
            .style('stroke', l => l.source === d ?
              vm.toColor(l.source) : l.target === d ?
                vm.toColor(l.target) : null)
            .filter(function (l) {
              return l.target === d || l.source === d
            })
            .each(function () {
              this.parentNode.appendChild(this)
            })
          /* eslint-enable */

          node
            .classed('node--target', function (n) {
              return n.target
            })
            .classed('node--source', function (n) {
              return n.source
            })
            .filter(function (n) {
              return n.source || n.target
            })
            .each(function () {
              this.parentNode.appendChild(this)
            })
          $elem.addClass('spider--active')
        }

        function mouseouted (d) {
          $elem.removeClass('spider--active')
          link
            .classed('link--target', false)
            .classed('link--source', false)

          node
            .classed('node--target', false)
            .classed('node--source', false)
        }

        // TODO: use Vuex store
        function click (d) {
          if (vm.filters.related_with == d.name) {
            // Clear the filter for the 'related with' if the actor that was selected previously is the same that was just clicked
            vm.$store.commit(FILTER_MUTATION_TYPES.CLEAR_BY_FACET, 'related_with')
          } else {
            vm.$store.commit(FILTER_MUTATION_TYPES.SET_BY_FACET, { facet: 'related_with', values: d.id })
          }
          vm.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'scores', metaData: d.id })
        }

        // d3.select(window.self.frameElement).style('height', diameter + 'px')

        // Lazily construct the package hierarchy from class names.
        function packageHierarchy (actors) {
          const map = {}
          const root = {
            name: 'root',
          }

          // Compute a map from id to node.
          /* actors = actors.filter(a =>
            (a.accelerated_by && a.accelerated_by.length) ||
            (a.has_employee && a.has_employee.length) ||
            (a.invested_by && a.invested_by.length) ||
            (a.spinoff_from && a.spinoff_from.length) ||
            (a.member_of && a.member_of.length) ||
            (a.is_account_manager_of && a.is_account_manager_of.length) ||
            (a.accelerated && a.accelerated.length) ||
            (a.invested && a.invested.length) ||
            (a.spinoffs && a.spinoffs.length) ||
            (a.members && a.members.length) ||
            (a.is_employee_of && a.is_employee_of.length) ||
            (a.collaborates_with && a.collaborates_with.length) ||
            (a.has_collaboration_with && a.has_collaboration_with.length) ||
            (a.is_founded_by && a.is_founded_by.length) ||
            (a.has_founded && a.has_founded.length) ||
            (a.has_account_manager && a.has_account_manager.length) ||
            (a.acquired && a.acquired.length) ||
            (a.acquired_by && a.acquired_by.length) ||
            (a.has_product && a.has_product.length) ||
            (a.is_product_of && a.is_product_of.length) ||
            (a.has_administrators && a.has_administrators.length) ||
            (a.is_administrator && a.is_administrator.length)) */

          var actorsWithRelationships = []

          for (var index = 0; index < actors.length; index++) {
            var actor = actors[index]
            for (var i = 0; i < vm.filterableRelationships.length; i++) {
              var relationship = vm.filterableRelationships[i]

              if (actor[relationship] && actor[relationship].length) {
                if (vm.visibleRelationships.length > 0) {
                  if (vm.relationshipIsVisible(relationship)) {
                    actorsWithRelationships.push(actor)
                  }
                } else {
                  actorsWithRelationships.push(actor)
                }
              }
            }
          }

          actors = actorsWithRelationships

          actors.forEach(a => {
            vm.filterableRelationships.forEach(relationship => {
              if (vm.visibleRelationships.length > 0) {
                if (vm.relationshipIsVisible(relationship)) {
                  constructHierarchy(a, relationship)
                }
              } else {
                constructHierarchy(a, relationship)
              }
            })
            /* constructHierarchy(a, 'accelerated_by', 'Accelerator')
            constructHierarchy(a, 'accelerated', 'Startup')

            constructHierarchy(a, 'invested_by', 'Investor')
            constructHierarchy(a, 'invested', 'Startup')

            constructHierarchy(a, 'spinoff_from')
            constructHierarchy(a, 'spinoffs')

            constructHierarchy(a, 'member_of')
            constructHierarchy(a, 'members')

            constructHierarchy(a, 'is_account_manager_of')
            constructHierarchy(a, 'has_account_manager')

            constructHierarchy(a, 'collaborates_with')
            constructHierarchy(a, 'has_collaboration_with')

            constructHierarchy(a, 'is_employee_of')
            constructHierarchy(a, 'has_employee')

            constructHierarchy(a, 'is_founded_by')
            constructHierarchy(a, 'has_founded')

            constructHierarchy(a, 'acquired')
            constructHierarchy(a, 'acquired_by')

            constructHierarchy(a, 'has_product')
            constructHierarchy(a, 'is_product_of')

            constructHierarchy(a, 'has_administrators')
            constructHierarchy(a, 'is_administrator') */
          })

          function constructHierarchy (a, field, legend) {
            if (a[field]) {
              a[field].forEach(function (i) {
                i.to_name && i.to && actors.push({
                  rel: field,
                  legend: i.legend || legend || field,
                  id: i.to,
                  name: i.to_name,
                })
              })
            }
          }

          actors = uniqBy(actors, 'id')

          actors.forEach(function (node) {
            if (!node.legend) {
              node.legend = vm.defaultLegend
            }
            if (!map[node.legend]) {
              map[node.legend] = { name: node.legend, children: [], parent: root }
            }
            map[node.legend].children.push(node)
            node.parent = map[node.legend]
          })

          vm.count = {}
          for (const x in map) {
            vm.count[x] = map[x].children.length
          }

          root.children = Object.values(map)
          return root
        }

        // Return a list of links for the given array of nodes.
        function actorRelationships (nodes) {
          const map = {}
          const links = []

          // Compute a map from id to node.
          nodes.forEach(function (d, i) {
            map[d.id || d.name] = d
          })

          // For each import, construct a link from the source to target node.
          nodes.forEach(function (d) {
            vm.actorRelationships.forEach(relationship => {
              if (vm.visibleRelationships.length > 0) {
                if (vm.relationshipIsVisible(relationship.name) || vm.relationshipIsVisible(relationship.inverse_name)) {
                  constructLinks(d, relationship.name, relationship.inverse_name)
                  constructLinks(d, relationship.inverse_name, relationship.name)
                }
              } else {
                constructLinks(d, relationship.name, relationship.inverse_name)
                constructLinks(d, relationship.inverse_name, relationship.name)
              }
            })

            /* constructLinks(d, 'invested_by', 'invested')
            constructLinks(d, 'accelerated_by', 'accelerated')
            constructLinks(d, 'member_of', 'members')
            constructLinks(d, 'spinoff_from', 'spinoffs')
            constructLinks(d, 'is_account_manager_of', 'has_account_manager')
            constructLinks(d, 'has_collaboration_with', 'collaborates_with')
            constructLinks(d, 'is_employee_of', 'has_employee')
            constructLinks(d, 'has_founded', 'is_founded_by')
            constructLinks(d, 'acquired', 'acquired_by')
            constructLinks(d, 'has_product', 'is_product_of')
            constructLinks(d, 'has_administrators', 'is_administrator')

            constructLinks(d, 'invested', 'invested_by')
            constructLinks(d, 'accelerated', 'accelerated_by')
            constructLinks(d, 'members', 'member_of')
            constructLinks(d, 'spinoffs', 'spinoff_from')
            constructLinks(d, 'has_account_manager', 'is_account_manager_of')
            constructLinks(d, 'collaborates_with', 'has_collaboration_with')
            constructLinks(d, 'has_employee', 'is_employee_of')
            constructLinks(d, 'is_founded_by', 'has_founded')
            constructLinks(d, 'acquired_by', 'acquired')
            constructLinks(d, 'is_product_of', 'has_product')
            constructLinks(d, 'is_administrator', 'has_administrators') */
          })

          function constructLinks (node, relation, inverse) {
            if (!node[relation]) {
              return
            }

            node[relation].forEach(function (i) {
              if (!map[i.to]) {
                return
              }
              if (!map[i.to][inverse]) {
                map[i.to][inverse] = []
              }
              map[i.to][inverse].push(node)
              if (map[node.id] && map[i.to]) {
                links.push({ rel: relation, source: map[node.id], target: map[i.to] })
              }
            })
          }

          vm.dataReady = true
          return { links, map }
        }
      },
      checkLegendBoxCoordinates (coordinates) {
        var copyOfCoordinates = Object.assign({}, coordinates)
        // NOTE: Trying to solve the issue regarding the legend box positioning based on different screen sizes programaticaly wasn't working properly was costing too much time to fix. As such we are using hard coded values in order to properly position the legend box if it happens to be placed out of visible bounds
        var legendBoxDimenstions = {
          height: 40,
          width: 250,
        }
        var distanceTopBar = 185
        var topBarHeight = 44
        var totalDistanceToTop = distanceTopBar + topBarHeight
        var sideNavigationWidth = 40

        if (copyOfCoordinates.x >= legendBoxDimenstions.width - sideNavigationWidth * 2) {
          copyOfCoordinates.x = window.innerWidth - legendBoxDimenstions.width - sideNavigationWidth * 2
        }
        if (copyOfCoordinates.x < -window.innerWidth + legendBoxDimenstions.width) {
          copyOfCoordinates.x = -window.innerWidth + legendBoxDimenstions.width + sideNavigationWidth * 2
        }
        if (copyOfCoordinates.y >= window.innerHeight - legendBoxDimenstions.height - totalDistanceToTop) {
          copyOfCoordinates.y = window.innerHeight - legendBoxDimenstions.height - totalDistanceToTop
        }
        if (copyOfCoordinates.y < legendBoxDimenstions.height - totalDistanceToTop) {
          copyOfCoordinates.y = -totalDistanceToTop + legendBoxDimenstions.height
        }

        return copyOfCoordinates
      },
      setLegendBoxCoordinates (coordinates) {
        this.$store.commit(UI_MUTATION_TYPES.SET_LEGEND_COORDINATES, { connectedness: coordinates })
      },
      saveLegendBoxCoordinatesToUserSettings (coordinates) {
        var legendBoxUSerSettingsCopy
        if (this.$store.getters.userSettings.legendBoxProperties == undefined) {
          this.$store.commit('USER/STORE_SETTINGS', { legendBoxProperties: [{ name: 'connectedness', coordinates: coordinates }] })
        } else {
          legendBoxUSerSettingsCopy = this.$store.getters.userSettings.legendBoxProperties.slice()
          legendBoxUSerSettingsCopy = legendBoxUSerSettingsCopy.filter(legendBox => legendBox.name !== 'connectedness')
          legendBoxUSerSettingsCopy.push({ name: 'connectedness', coordinates: coordinates })
          this.$store.commit('USER/STORE_SETTINGS', { legendBoxProperties: legendBoxUSerSettingsCopy })
        }
      },
      onDragged ({ el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last }) {
        /*
        Definition
          el: The target element on which the diretive binds.
          deltaX: The change of the pointer (mouse/touch)'s x coordination from the last position.
          deltaY: The change of the pointer (mouse/touch)'s y coordination from the last position.
          offsetX: The change of the pointer (mouse/touch)'s x coordination from the starting position.
          offsetY: The change of the pointer (mouse/touch)'s y coordination from the starting position.
          clientX: Current x coordination of the pointer (mouse/touch).
          clientY: Current y coordination of the pointer (mouse/touch).
          first: A boolean to indicate whether it is the first move of the drag. (drag starts here).
          last: A boolean to indicate whether it is the last move of the drag. (drag ends here).
        */

        // The area in which the dragged object is contained.
        var boundingArea = this.$el.getBoundingClientRect()

        // The dragged object
        var draggedObjectArea = el.getBoundingClientRect()

        var insideLeftBorder = draggedObjectArea.left > boundingArea.left
        var insideRightBorder = draggedObjectArea.right < boundingArea.right
        var insideTopBorder = draggedObjectArea.top > boundingArea.top
        var insideBottomBorder = draggedObjectArea.bottom < boundingArea.bottom

        var l = +window.getComputedStyle(el)['left'].slice(0, -2) || 0
        var t = +window.getComputedStyle(el)['top'].slice(0, -2) || 0
        var coordinates = {
          x: this.connectednessLegendBoxProperties.length > 0 ? this.connectednessLegendBoxProperties[0].coordinates.x : 0,
          y: this.connectednessLegendBoxProperties.length > 0 ? this.connectednessLegendBoxProperties[0].coordinates.y : 0,
        }

        if (first) {
          this.dragged = true
          return
        }
        if (last) {
          this.dragged = false
          this.saveLegendBoxCoordinatesToUserSettings(coordinates)
          return
        }

        if (!insideLeftBorder) {
          coordinates.x = l + deltaX + 5
          this.setLegendBoxCoordinates(coordinates)
          return
        }
        if (!insideRightBorder) {
          coordinates.x = l + deltaX - 5
          this.setLegendBoxCoordinates(coordinates)
          return
        }
        if (!insideTopBorder) {
          coordinates.y = t + deltaY + 5
          this.setLegendBoxCoordinates(coordinates)
          return
        }
        if (!insideBottomBorder) {
          coordinates.y = t + deltaY - 5
          this.setLegendBoxCoordinates(coordinates)
          return
        }

        coordinates.x = l + deltaX
        coordinates.y = t + deltaY

        this.setLegendBoxCoordinates(coordinates)
      },
    },
    async mounted () {
      try {
        this.actorRelationships = await this.fetchRelationships()
      } catch (e) {
        console.log(e)
      }

      this.fetch()
    },
    beforeUnmount () {
      this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      this.$store.commit(FILTER_MUTATION_TYPES.CLEAR_BY_FACET, 'related_with')
    },
    watch: {
      actors (v) {
        this.render()
      },
      filters: {
        deep: true,
        handler () {
          this.fetch()
        },
      },
    },
    mixins: [FiltersMixin],
    components: {
      TopFilters,
      LegendDropdown,
    },
  })
</script>
