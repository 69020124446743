<template>
  <div class="challenge-container"
       ref="challengeContainer"
       :class="{'vertical-card': isVertical, 'with-upgrade-overlay-cta': showChallengeUpgradeCTA}">
    <icon v-if="showChallengeUpgradeCTA" class="lock-icon" name="lock"
          size="large"></icon>
    <div class="challenge-cta__overlay" v-if="showChallengeUpgradeCTA">
      <h3 class="h3">
        <router-link to="/profile/subscription#upgrade">{{
            splitCtaText[1]
          }}
        </router-link>
      </h3>
    </div>
    <div class="challenge" :class="{'container': isContainer}">
      <div class="challenge-image"
           :style="{'background-image': `url('/api/challenges/${challenge.id}/image?updated_at=${challenge.updated_at}')`, height: fixedImageSize}">
        <div class="challenge-image-countdown"
             v-if="challenge.end_date && getChallengeTimeDifference(challenge.end_date, true) && challenge.status && challenge.status !== 'solved'">
          <span :key="countdownKey"
                v-html="getChallengeTimeDifference(challenge.end_date, true)"></span>
        </div>
        <div class="challenge-image-tags">
          <tag :label="String(challenge.comment_count) || '0'"
               icon="comments"
               v-if="isLoggedIn"
               @click="visitChallenge('challengeCard.commentClickDetail')"
               :clickable="!!isAccessibleForUser(challenge)"/>
          <tag :icon="challenge.is_pinned ? 'pin' : 'pin-outline'" :clickable="true"
               v-if="isLoggedIn && isAccessibleForUser(challenge)"
               @click="onTogglePinStatus"/>
          <tag :label="getLabelForChallengeStatus(challenge.status)" :clickable="canToggleStatusFilter"
               @click="onClickStatusTag"/>
          <tag :label="visibilityLabel.toUpperCase()"
               :clickable="canToggleVisibilityFilter"
               @click="onClickVisibilityTag"/>
        </div>
        <div class="challenge-image-solved"
             v-if="challenge.status && challenge.status === 'solved'">
          {{ $t('challenge_card_solved') }}
        </div>
      </div>
      <div class="challenge-text" :style="{height: isVertical ? 'auto' : fixedImageSize }"
           :class="isAccessibleForUser(challenge) ? '' : 'challenge-text-without-discover-button'">
        <h3 class="h3" @click="visitChallenge('challengeCard.titleClickDetail')"
            :class="{clickable: isAccessibleForUser(challenge)}">{{
            challenge.title
          }}</h3>
        <div class="challenge-image-tags challenge-image-tags-mobile">
          <div class="challenge-image-countdown">
            <template
                v-if="challenge.end_date && getChallengeTimeDifference(challenge.end_date, true)">
              <span :key="countdownKey"
                    v-html="getChallengeTimeDifference(challenge.end_date, true)"></span>
            </template>
          </div>
          <div style="display: flex">
            <tag :label="String(challenge.comment_count) || '0'"
                 icon="comments"
                 @click="visitChallenge('challengeCard.commentClickDetail')"
                 :clickable="!!isAccessibleForUser(challenge)"/>
            <tag :icon="challenge.is_pinned ? 'pin' : 'pin-outline'" :clickable="true"
                 v-if="isLoggedIn && isAccessibleForUser(challenge)"
                 @click="onTogglePinStatus"/>
            <tag :label="challenge.status.toUpperCase()"
                 :clickable="canToggleStatusFilter"
                 @click="onClickStatusTag"/>
            <tag :label="visibilityLabel.toUpperCase()"
                 :clickable="canToggleVisibilityFilter"
                 @click="onClickVisibilityTag"/>
          </div>
        </div>
        <p class="challenge-description"
           v-html="localizedDisplayProperty(challenge, 'shortDescription')"/>
        <div class="button-container" v-if="isAccessibleForUser(challenge)">
          <ds-button variant="primary"
                     @click="trackHeapEvent('challengeCard.discoverClickDetail')"
                     :to="'/challenges/' + challenge.id" :label="$t('challenge_cta')"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tag from '../Tag/Tag.vue'
import { trackHeapEvent } from '../../util/analytics.js'
import { getLabelForChallengeStatus, splitCtaText } from '../../util/helpers.js'
import { getChallengeTimeDifference } from '../../util/date.js'
import TranslationsMixin from '../../util/TranslationsMixin.js'

export default {
  name: 'challenge-card',
  props: {
    challenge: Object,
    isAccessibleForUser: Function,
    isVertical: Boolean,
    isContainer: {
      type: Boolean,
      default: true,
    },
    canToggleStatusFilter: Boolean,
    canToggleVisibilityFilter: Boolean,
  },
  data() {
    return {
      countdownKey: 0,
      endTimeInterval: null,
      fixedImageSize: 0,
    }
  },
  components: {
    Tag,
  },
  methods: {
    trackHeapEvent,
    getChallengeTimeDifference,
    getLabelForChallengeStatus,
    calculateImageHeight() {
      if (window.innerWidth < 992) {
        if (this.isVertical && this.$refs.challengeContainer) {
          this.fixedImageSize = `calc(0.28125 * ${getComputedStyle(this.$refs.challengeContainer).width})`
        } else {
          this.fixedImageSize = 'auto'
        }
        return
      }
      if (this.$refs.challengeContainer) {
        // aspect ratio 0.5625 == 16:9, divided by 2 or 4 because image is half of total width
        if (this.isVertical) {
          this.fixedImageSize = `calc(0.28125 * ${getComputedStyle(this.$refs.challengeContainer).width})`
        } else {
          this.fixedImageSize = `calc(0.140625 * ${getComputedStyle(this.$refs.challengeContainer).width})`
        }
      }
    },
    visitChallenge(eventName) {
      trackHeapEvent(eventName)
      if (this.isAccessibleForUser(this.challenge)) {
        this.$router.push('/challenges/' + this.challenge.id)
      }
    },
    onClickVisibilityTag() {
      if (this.canToggleVisibilityFilter) {
        this.$emit('set-filter', { target: 'visibility', value: this.visibilityValue })
      }
    },
    onClickStatusTag() {
      if (this.canToggleStatusFilter) {
        this.$emit('set-filter', { target: 'status', value: this.challenge.status })
      }
    },
    onTogglePinStatus() {
      trackHeapEvent('challengeCard.challengePin')
      this.$emit('toggle-pin-status', this.challenge)
    },
  },
  computed: {
    challengeCtaUpgradeText() {
      if (this.showChallengeUpgradeCTA) {
        return this.config.packageCTAs.challenge.text || this.$t('default_challenge_upgrade_cta', { challenge: this.challengeLabel })
      }
      return ''
    },
    splitCtaText() {
      return splitCtaText(this.challengeCtaUpgradeText)
    },
    showChallengeUpgradeCTA() {
      return this.isLoggedIn && !this.isAccessibleForUser(this.challenge) && this.config.packageCTAs && this.config.packageCTAs.challenge && this.config.packageCTAs.challenge.enabled
    },
    config() {
      return this.$store.state.config
    },
    visibilityValue() {
      return this.challenge.invite_only === 1 ? 'private' : 'public'
    },
    visibilityLabel() {
      return this.challenge.invite_only === 1 ? this.$t('challenge_private') : this.$t('challenge_public')
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
  },
  mounted() {
    this.endTimeInterval = setInterval(() => {
      this.countdownKey++
    }, 1000)
    this.calculateImageHeight()
    this.$bus.on('resize', () => {
      this.calculateImageHeight()
    })
  },
  mixins: [TranslationsMixin],
  beforeUnmount() {
    if (this.endTimeInterval) {
      clearInterval(this.endTimeInterval)
    }
    this.$bus.off('resize')
  },
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.challenge-container {
  &.with-upgrade-overlay-cta {
    overflow-y: hidden;
  }

  &:hover,
  &:focus {
    .challenge-cta__overlay {
      transform: translate3d(0, 0, 0);
    }

    &.with-upgrade-overlay-cta {
      overflow: hidden;

      .challenge {
        background: none;
      }
    }
  }
}

.lock-icon {
  position: absolute;
  top: 19px;
  right: 30px;
  fill: var(--primary);
  z-index: 1;
}

.challenge-cta__overlay {
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  top: 0;
  transition: transform 300ms;
  transform: translate3d(0, 100%, 0);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tag {
  background: var(--primary);
  color: white;
  border: 0;

  :deep(path) {
    fill: white;
  }
}
</style>
