<template>
  <div class="row actor-detail-profile" v-if="!hasSelectedReport">
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <AsideSection
          title="Personal info"
          v-if="actor.actor_type === 'Person' && this.personalInfo && this.personalInfo.length > 0">
        <table class="table table--minimal">
          <tbody>
          <tr v-for="row in personalInfo">
            <td>{{ row.term }}</td>
            <td style="text-align: right;">
              <template v-if="! row.url">
                {{ row.description }}
              </template>
              <template v-else>
                <a :href="row.url" target="_blank">{{ row.description }}</a>
              </template>
            </td>
          </tr>
          </tbody>
        </table>
      </AsideSection>

      <AsideSection
          :title="actor.actor_type == 'Person' ? 'Focus' : 'Company focus'"
          v-if="companyFocusDefinitionListEntries && companyFocusDefinitionListEntries.length > 0">
        <DefinitionList :entries="companyFocusDefinitionListEntries"/>
      </AsideSection>

      <AsideSection
          title="Sustainability Goals"
          v-if="sustainibilityGoals.length > 0 && challengesAreEnabled">
        <actor-detail-SDGs
            :selected-goals="sustainibilityGoals"
            target="traditional-actor"></actor-detail-SDGs>
      </AsideSection>

      <AsideSection title="Business focus" v-if="actor.activities_description">
        <div>{{ actor.activities_description }}</div>
      </AsideSection>

      <AsideSection
          title="State"
          v-if="(this.$store.getters.isPublisherEnabled && (isMember || userCanClaim || userIsActorOwner))
                      || (zombieFeatureFlagEnabled && isZombie)"
      >
        <template v-slot:titleAction v-if="userCanClaim">
          <ds-button
              size="small"
              icon="email-outline"
              label="Claim"
              variant="secondary"
              @click="handleShowClaimModal"
          />
        </template>

        <div>
          <Badge :name="'This actor has been contacted ' + getCTAButtonClicks + ' time(s).'"/>

          <Badge
              v-if="!isPrivate && (isMember || userIsActorOwner) && isActorPublished"
              name="published" variant="secondary"/>

          <Badge
              v-else-if="!isPrivate && (isMember || userIsActorOwner) && actor.deleted_at"
              name="hidden (deleted)" variant="secondary"/>

          <Badge
              v-else-if="isPrivate"
              name="hidden for visitors"/>

          <Badge
              v-if="isPrivate && (isMember || userIsActorOwner) && actor.deleted_at"
              name="deleted" variant="secondary"/>

          <Badge v-if="(isMember || userIsActorOwner) && !isActorClaimed" name="unclaimed"/>
          <Badge v-else :name="claimedBadgeLabel"/>

          <span v-if="zombieFeatureFlagEnabled && isZombie">
            <badge name="zombie" variant="secondary"/>
          </span>

          <ds-button
              v-if="highlightedSpottingArea.id && company.is_inside_highlighted_spotting_area"
              :style="{backgroundColor: highlightedSpottingArea.highlight_color}"
              class="highlight-spotting-area-button highlight-spotting-area-button-map"
              @click="goToHighlightedSpottingAreaDetail"
              :label="highlightedSpottingArea.name"
              icon="location-marker"
              icon-fill-color="#ffffff"
          ></ds-button>
        </div>
      </AsideSection>

      <AsideSection title="Completeness" v-if="userCanSeeChanges">
        <router-link
            v-if="actor.completeness" :to="'/actors/' + actor.id + '/changes'"
            @click="trackHeapEvent('actorDetail.info.clickLastUpdates')">
          <div class="row">
            <div class="col-sm-2"><small>{{
                Math.round(actor.completeness * 100)
              }}%</small></div>
            <div class="col-sm-10">
              <CompletionBar :completion="Math.round(actor.completeness * 100)"/>
            </div>
          </div>
        </router-link>
        <p style="margin-top: 10px; margin-bottom: 5px;">
          <small>
            Created on
            <Date :date="actor.created_at" format="DD/MM/YYYY"/>
            via {{ actorOrigin }}
          </small>
        </p>
        <p>
          <small>
            Last updated on
            <Date :date="actor.updated_at" format="DD/MM/YYYY"/>
          </small>
        </p>
      </AsideSection>
      <AsideSection title="Tags"
                    v-if="(actor.tags && actor.tags.length > 0) || (actor.private_tags && actor.private_tags.length > 0) || (actor.description_annotation_tags && actor.description_annotation_tags.length > 0) || (actor.seo_keywords && actor.seo_keywords.length > 0)">
        <Keyword
            v-for="(tag, index) in actor.tags" :key="'tag' + index" :label="tag.label"
            @click="setTagFilter(tag)"/>

        <Keyword
            v-for="(tag, index) in actor.private_tags" :key="'privtag' + index"
            :label="tag.label" variant="private" @click="setPrivateTagFilter(tag)"/>

        <Keyword
            v-for="(tag, index) in actor.description_annotation_tags" :key="'annotationtag' + index"
            :label="tag.label" variant="private" @click="setDescriptionAnnotationTagFilter(tag)"/>

        <Keyword
            v-for="(keyword, index) in actor.seo_keywords" :key="'seotag' + index"
            :label="keyword" @click="setSeoKeywordFilter(keyword)"/>

        <br>
      </AsideSection>

      <AsideSection title="Connections"
                    v-if="chunkedConnections.length && (userCanEdit || isPortfolioMember || canAccessConnections)">
        <template v-slot:titleAction>
          <a href="#" @click="clickViewAllConnections">
            <small>View all connections</small>
          </a>
        </template>

        <div class="row" v-for="connectionsRow in chunkedConnections">
          <div
              class="col-xs-6 col-sm-6 col-md-12 col-lg-6 flex"
              v-for="connection in connectionsRow">
            <Connection
                :actor="actor" :connection="connection" style="height: 100%"
                @click="trackHeapEvent('actorDetail.profile.clickConnection')"/>
          </div>
        </div>
      </AsideSection>
    </div>

    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
      <div class="row">
        <!-- If there are no files, video, images nor website screenshot -->
        <template v-if="layoutType == 1">
          <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
              style="margin-bottom: 1em;">
            <NewCard title="About the Company" v-if="sanitizedDescription">
              <div class="actor-description" v-html="sanitizedDescription"></div>
            </NewCard>
            <NewCard title="Product & Offering" v-if="sanitizedAdditionalInformation.length > 0">
              <div class="actor-description" v-html="sanitizedAdditionalInformation"></div>
            </NewCard>
          </div>
          <template v-if="userCanEdit && $store.getters.isNewKnowledgeBaseEnabled">
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
              <CallToActionCard>
                <template v-slot:callToActionSlot>
                  <div style="text-align: center">
                    <ds-button
                        variant="primary" label="Upload Files"
                        @click="openCreateResourcePanel"/>
                    <p style="margin-top: 0.5em; font-size: 12px;"> Maximum file size
                      5MB</p>
                  </div>
                </template>
              </CallToActionCard>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <CallToActionCard>
                <template v-slot:callToActionSlot>
                  <div style="text-align: center">
                    <image-input
                        buttonText="Upload Screenshot" variant="primary"
                        @update:modelValue="saveActorScreenshot" width="150" height="30"
                        style="    margin-top: 5px;"/>
                    <p style="margin-top: .7em; font-size: 12px;"> Maximum file size 5MB</p>
                  </div>
                </template>
              </CallToActionCard>
            </div>
          </template>
          <template v-else-if="userCanEdit">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <CallToActionCard>
                <template v-slot:callToActionSlot>
                  <div style="text-align: center">
                    <ds-button
                        variant="primary" label="Upload Files"
                        @click="openCreateResourcePanel()"/>
                    <p style="margin-top: 0.5em; font-size: 12px;"> Maximum file size
                      70MB</p>
                  </div>
                </template>
              </CallToActionCard>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <CallToActionCard>
                <template v-slot:callToActionSlot>
                  <div style="text-align: center">
                    <image-input
                        buttonText="Upload Screenshot" variant="primary"
                        @update:modelValue="saveActorScreenshot" width="150" height="30"
                        style="    margin-top: 5px;"/>
                    <p style="margin-top: .7em; font-size: 12px;"> Maximum file size 5MB</p>
                  </div>
                </template>
              </CallToActionCard>
            </div>
          </template>
        </template>

        <!-- If there are no images, video, website screenshot but there are files -->
        <template v-else-if="layoutType == 4">
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <NewCard fillAvailableHeight title="files" ref="fileContainer">
              <template v-slot:headerRight>
                <div>
                  <template v-if="userCanEdit">
                    <ds-button
                        size="small" icon="plus" variant="default"
                        @click="openCreateResourcePanel()"
                        style="margin-right: 5px"
                        class="actor-detail-profile__file-container-edit-button"/>
                  </template>
                  <template v-if="userCanEdit">
                    <ds-button
                        size="small" icon="arrow-expand" variant="default"
                        @click="openFileRepositorySidepanel"
                        class="actor-detail-profile__file-container-expand-button"/>
                  </template>
                </div>
              </template>
              <template v-for="(file,index) in actorFiles">
                <FileThumbnail
                    v-if="index < numberOfVisibleFiles"
                    :filename="file.filename"
                    :mimeType="file.mimeType"
                    :path="file.path"
                    :preview="file.preview"
                    :isPrivate="file.isPrivate"
                />
              </template>
            </NewCard>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            <CallToActionCard :text="!userCanEdit ? 'No website screenshot' : ''">
              <template v-slot:callToActionSlot>
                <div style="text-align: center">
                  <image-input
                      v-if="userCanEdit" buttonText="Upload Screenshot"
                      variant="primary" @update:modelValue="saveActorScreenshot" width="150"
                      height="30" style="margin-top: 5px;"/>
                  <p v-if="userCanEdit" style="margin-top: .7em; font-size: 12px;"> Maximum
                    file size 5MB</p>
                </div>
              </template>
            </CallToActionCard>
          </div>
        </template>

        <!-- If there are no files, website screenshot but there are images or video -->
        <template v-else-if="layoutType == 2">
          <div
              :class="userCanEdit ? 'col-lg-8 col-md-8' : 'col-lg-8 col-md-8'"
              class="col-sm-12 col-xs-12 actor-detail-profile">
            <Carousel
                :items-to-show="1"
                :paginationEnabled="false"
                :class="galleryHasOnlyOneItem ? 'actor-detail-profile__gallery-height-override' : ''">
              <slide v-for="slide of layout2Slides" :key="slide.key">
                <template v-if="slide.key === 'layout_2_main_video'">
                  <div class="embed-responsive" v-html="mainVideo"/>
                </template>
                <template v-else>
                  <div
                      class="actor-detail__carousel-image"
                      :style="{ backgroundImage: 'url(' + slide.preview + ')' }"
                      @click="showImageDetail(slide.preview, slide.title)"/>
                </template>
              </slide>
              <template #addons>
                <Navigation v-if="!galleryHasOnlyOneItem"/>
              </template>
            </Carousel>
          </div>
          <div v-if="userCanEdit" class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div
                v-if="actorFiles.length === 0"
                class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <CallToActionCard :text="!userCanEdit ? 'No files' : ''">
                <template v-slot:callToActionSlot>
                  <div style="text-align: center">
                    <ds-button
                        v-if="userCanEdit" variant="primary" label="Upload Files"
                        @click="openCreateResourcePanel"/>
                    <p v-if="userCanEdit" style="margin-top: 0.5em; font-size: 12px;">
                      Maximum file size 7MB</p>
                  </div>
                </template>
              </CallToActionCard>
            </div>
            <NewCard
                v-else class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                fillAvailableHeight title="files" ref="fileContainer">
              <template v-slot:headerRight>
                <div>
                  <template v-if="userCanEdit">
                    <ds-button
                        size="small" icon="plus" variant="default"
                        @click="openCreateResourcePanel"
                        style="margin-right: 5px"
                        class="actor-detail-profile__file-container-edit-button"/>
                  </template>
                  <template v-if="userCanEdit">
                    <ds-button
                        size="small" icon="arrow-expand" variant="default"
                        @click="openFileRepositorySidepanel"
                        class="actor-detail-profile__file-container-expand-button"/>
                  </template>
                </div>
              </template>
              <template v-for="(file,index) in actorFiles">
                <FileThumbnail
                    v-if="index < numberOfVisibleFiles"
                    :filename="file.filename"
                    :mimeType="file.mimeType"
                    :path="file.path"
                    :preview="file.preview"
                    :isPrivate="file.isPrivate"
                />
              </template>
            </NewCard>
          </div>
        </template>

        <!-- If there are no files but there are images or video and website screenshot -->
        <template v-else-if="layoutType == 3">
          <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 actor-detail-profile">
            <Carousel
                :items-to-show="1"
                :class="galleryHasOnlyOneItem ? 'actor-detail-profile__gallery-height-override' : ''">
              <slide v-for="slide in layout3Slides" :key="slide.key">
                <template v-if="slide.key === 'layout_3_main_video'">
                  <div class="embed-responsive" v-html="mainVideo"/>
                </template>
                <template v-else-if="slide.key === 'layout_3_website_screenshot'">
                  <div
                      class="actor-detail__carousel-image"
                      :style="{ backgroundImage: 'url(' + actor.website_screenshot + ')' }"
                      @click="showImageDetail(actor.website_screenshot, 'Website Screenshot')"/>
                </template>
                <template v-else>
                  <div
                      class="actor-detail__carousel-image"
                      :style="{ backgroundImage: 'url(' + slide.preview + ')' }"
                      @click="showImageDetail(slide.preview, slide.title)"/>
                </template>
              </slide>
              <template #addons>
                <Navigation v-if="!galleryHasOnlyOneItem"/>
              </template>
            </Carousel>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" v-if="userCanEdit">
            <div
                v-if="actorFiles.length === 0"
                class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <CallToActionCard>
                <template v-slot:callToActionSlot>
                  <div style="text-align: center">
                    <ds-button
                        variant="primary" label="Upload Files"
                        @click="openCreateResourcePanel"/>
                    <p style="margin-top: 0.5em; font-size: 12px;"> Maximum file size
                      7MB</p>
                  </div>
                </template>
              </CallToActionCard>
            </div>
            <NewCard
                v-else class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                fillAvailableHeight title="files" ref="fileContainer">
              <template v-slot:headerRight>
                <div>
                  <ds-button
                      size="small" icon="plus" variant="default"
                      @click="openCreateResourcePanel('image')"
                      style="margin-right: 5px"
                      class="actor-detail-profile__file-container-edit-button"/>
                  <ds-button
                      size="small" icon="arrow-expand" variant="default"
                      @click="openFileRepositorySidepanel"
                      class="actor-detail-profile__file-container-expand-button"/>
                </div>
              </template>
              <FileThumbnail
                  v-if="actor.website_screenshot"
                  filename="Website Screenshot"
                  mimeType="image"
                  :path="actor.website_screenshot"
                  :preview="actor.website_screenshot"
              />
              <template v-for="(file,index) in actorFiles">
                <FileThumbnail
                    v-if="index < numberOfVisibleFiles"
                    :filename="file.filename"
                    :mimeType="file.mimeType"
                    :path="file.path"
                    :preview="file.preview"
                    :isPrivate="file.isPrivate"
                />
              </template>
            </NewCard>
          </div>
        </template>
      </div>

      <NewCard
          :key="'actor-about-company'"
          v-if="(actorImages.length > 0 || hasWebsiteScreenShot || actorFiles.length > 0 || mainVideo) && (sanitizedDescription)"
          title="About the Company">
        <div class="actor-description" v-html="sanitizedDescription"></div>
      </NewCard>

      <NewCard
          :key="'actor-product-and-offering'"
          v-if="(actorImages.length > 0 || hasWebsiteScreenShot || actorFiles.length > 0 || mainVideo) && (sanitizedAdditionalInformation)"
          title="Product & Offering">
        <div class="actor-description" v-html="sanitizedAdditionalInformation"></div>
      </NewCard>

      <NewCard
          :key="'actor-reports'"
          v-if="userCanAccessReport && availableReportTemplatesForCurrentActor && availableReportTemplatesForCurrentActor.length > 0"
          title="Reports"
      >
        <Carousel
            :items-to-show="cardsPerCarouselPage"
            class="actor-detail__reports-carousel">
          <Slide
              v-for="(reportTemplate, index) in availableReportTemplatesForCurrentActor"
              :key="'report_templates_' + index"
              style="min-height: 100%; padding: 0 1.5rem"
              :style="{width: `calc(100% / ${cardsPerCarouselPage})`}">
            <div class="actor-detail__report">
              <div style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
                {{ reportTemplate.name }}&nbsp;
                <span
                    :key="'report-template-copy-link-' + reportTemplate.id"
                    @click="copyActorReportLink(getReportTemplateUrl(reportTemplate, true))"
                    v-tooltip.right="copiedActorReportLink ? '✓ Copied' : 'Copy actor report link'"
                    style="cursor: pointer"
                >
                  <!-- Remove link here -->
                  <icon name="link"/>
                </span>
              </div>
              <div v-if="reportTemplate.is_call" style="margin-top: 7px">
                <tag :label="getOpenCallStatus(actor, reportTemplate)"/>
              </div>
              <div class="actor-detail__report-actions">
                <ds-button
                    icon="chevron-right"
                    :label="'VIEW REPORT'"
                    size="small" variant="secondary"
                    :disabled="isOpenCallEmptyAndExpired(actor.current_report, reportTemplate)"
                    @click="selectReportTemplate(reportTemplate)"
                    style="margin-top: 7px;"
                />
                <div v-if="reportTemplate.is_call && reportTemplate.due_date"
                     class="actor-detail__report-submission-date">
                  {{ new Date(reportTemplate.due_date).toLocaleDateString() }}
                </div>
              </div>
            </div>
          </Slide>
          <template #addons>
            <Navigation v-if="availableReportTemplatesForCurrentActor.length > cardsPerCarouselPage"/>
          </template>
        </Carousel>
      </NewCard>

      <!-- Latest announcements -->
      <NewCard
          v-if="shouldShowAnnouncements"
          :key="'latest-announcements'"
          title="Latest announcements, vacancies, product releases & collaboration opportunities"
      >
        <template v-slot:headerRight v-if="canUserCreateAnnouncement">
          <ds-button
              size="extra-small" variant="outline" icon="plus"
              @click="handleClickCreateAnnouncement('announcement')"/>
        </template>
        <p v-if="chunkedAnnouncements.length === 0"><small>Nothing to report yet!</small>
        </p>
        <div class="row" v-for="row in chunkedAnnouncements">
          <div class="col-sm-6" v-for="announcement in row">
            <ArticlePreview
                style="height: 100%;"
                :image="getImageForAnnouncement(announcement)"
                :title="announcement.title"
                :body="announcement.html"
                :createdAt="announcement.created_at"
                :tags="announcement.tags"
                :author="announcement.author"
                :article-link="'/announcements/' + (announcement.slug || announcement.id)"
                :notificationId="announcement.id"
            />
          </div>
        </div>
      </NewCard>

      <!-- Latest events -->
      <NewCard
          v-if="shouldShowAnnouncements"
          title="Latest events"
      >
        <template v-slot:headerRight v-if="canUserCreateAnnouncement">
          <ds-button
              size="extra-small" variant="outline" icon="plus"
              @click="handleClickCreateAnnouncement('event')"/>
        </template>
        <p v-if="chunkedEvents.length === 0"><small>Nothing to report yet!</small>
        </p>
        <div class="row" v-for="row in chunkedEvents">
          <div class="col-sm-6" v-for="event in row">
            <ArticlePreview
                style="height: 100%;"
                :image="getImageForAnnouncement(event)"
                :title="event.title"
                :body="event.html"
                :createdAt="event.created_at"
                :tags="event.tags"
                :author="event.author"
                :article-link="'/events/' + (event.slug || event.id)"
                :notificationId="event.id"
            />
          </div>
        </div>
      </NewCard>

      <!-- Show Latest News -->
      <NewCard
          :key="'actor-latest-news'"
          :title="'Latest News Mentioning ' + actor.name"
      >
        <!-- section inner navigation -->
        <div class="" style="margin-bottom: 15px;">
          <div class="">
            <radio-button
                v-if="hasDataForTelexType(MAIN_CONTENT_TYPES.NEWS)"
                inline
                name="telexFilters"
                label="Show 10 Latest Resources"
                checked="MAIN_CONTENT_TYPES.NEWS"
                :val="MAIN_CONTENT_TYPES.NEWS"
                :model-value="telexType"
                @update:modelValue="selectFilter(MAIN_CONTENT_TYPES.NEWS)"
            />
          </div>
        </div>

        <!-- Show knowledge base resources -->
        <div v-if="telexType === 'NEWS'" class="" ref="news">
          <ActorDetailProfileTrends
              :actor-id="actor.id"
          />
        </div>

        <!-- Show other news -->
        <div class="row news__row" ref="columns" style="margin-top: 15px;">
          <div v-for="column in columns" class="col-md-6 news__column">
            <template
                v-for="item in mainContent.filter(v => v.type == telexType).filter((v, i) => i % columns === column - 1)">
              <div v-if="telexType != 'NEWS'">
                <ActorDetailProfileMainContentBlock
                    v-if="telexType == item.type"
                    class="feed_card"
                    :accentColor="telexType == 'NEWS' ? cardNewsBorderColor : cardBorderColor"
                    :type="item.type"
                    :data="item"
                />
              </div>
            </template>
          </div>
        </div>
      </NewCard>

    </div>
  </div>
  <div v-else>
    <ActorDetailReport :actor="company"/>
  </div>
</template>

<script>
import _chunk from 'lodash/chunk'
import _intersect from 'lodash/intersection'
import { Carousel, Navigation, Slide } from 'vue3-carousel'

import getVideoId from 'get-video-id'

import MODAL_IDS from '../../constants/modal-ids'

import AvatarList from '../../components/Avatar/AvatarList.vue'
import Badge from '../../components/Badge/Badge.vue'
import Keyword from '../../components/Keyword/Keyword.vue'
import AsideSection from '../../components/AsideSection/AsideSection.vue'
import CompletionBar from '../../components/CompletionBar/CompletionBar.vue'
import NewCard from '../../components/NewCard/NewCard.vue'
import Connection from '../../components/Connection/Connection.vue'
import DefinitionList from '../../components/DefinitionList/DefinitionList.vue'
import ActorDetailProfileMainContentBlock from './ActorDetailProfileMainContentBlock.vue'
import CardNews from '../../components/Card/CardNews.vue'
import ArticlePreview from '../../components/ArticlePreview/ArticlePreview.vue'
import FileThumbnail from '../../components/FileThumbnail/FileThumbnail.vue'
import FileInput from '../../components/Form/FileInput.vue'
import FormGroup from '../../components/Form/FormGroup.vue'
import RadioButton from '../../components/Form/RadioButton.vue'
import ImageInput from '../../components/Form/ImageInput.vue'
import CallToActionCard from '../../components/CallToActionCard/CallToActionCard.vue'
import Modal from '../../components/Modals/Modal.vue'

import { ACTION_TYPES as ACTOR_ACTION_TYPES } from '../../store/modules/actors'
import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'

import { trackHeapEvent, trackMatomoEvent } from '../../util/analytics'
import CompanyMixin from '../../util/CompanyMixin'
import ConfigMixin from '../../util/ConfigMixin'

import {
  _unique,
  copyToClipboard,
  EMBED_SERVICE_HANDLERS,
  getImageForAnnouncement,
  getSmallCardsPerCarouselPageBasedOnWindowWidth
} from '../../util/helpers'
import ICONS from '../../../icons/manifest.json'

import { deleteClaim } from '../../api/actors'
import { fetchProfile } from '../../api/user.js'

import DOMPurify from 'dompurify'
import ActorDetailSDGs from './ActorDetailSDGs.vue'
import NaceCodePicker from '../../components/NaceCodePicker/NaceCodePicker.vue'
import ReportMixin from '../../util/ReportMixin.js'
import ActorDetailReport from './ActorDetailReport.vue'
import { toDateString } from '../../util/date.js'
import Tag from '../../components/Tag/Tag.vue'
import _groupBy from 'lodash/groupBy'
import { Conversations } from '../../api/conversations.js'
import { ACTION_TYPES as CONVERSATION_ACTION_TYPES } from '../../store/modules/conversations'
import Avatar from '../../components/Avatar/Avatar.vue'
import AnnouncementOrResourceCard from '../../components/Simplified/AnnouncementOrResourceCard.vue'
import { likeArticle } from '../../api/knowledge-base'
import Loading from '../../components/Dashboard/ConceptMap/Loading.vue'
import ActorDetailProfileTrends from './ActorDetailProfileTrends.vue'
import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '@/constants/analytics-constants.js'

const MAX_AMOUNT_OF_CONNECTIONS_IN_SIDEBAR = 8

export default {
  name: 'ActorDetailProfile',
  props: {
    actor: Object,
    mainContentData: Object,
    connections: Array,
  },
  data() {
    return {
      columns: 0,
      MAIN_CONTENT_TYPES: {
        NEWS: 'NEWS',
      },
      fetchingKnowledgeBaseItems: true,
      knowledgeBaseItems: [],
      telexType: 'NEWS',
      cardBorderColor: '#f5a623',
      cardNewsBorderColor: 'var(--primary-light)',
      numberOfVisibleFiles: 8, // Will control how many files are displayed in the container depending on the width of that same container
      galleryArrows: {
        previous: ICONS['chevron-right'], // The icon will be rotated through CSS
        next: ICONS['chevron-right'],
      },
      windowWidth: window.innerWidth,
      copiedActorReportLink: false,
      copiedActorReportName: false,
    }
  },
  computed: {
    highlightedSpottingArea() {
      return this.$store.state.spottingAreas.highlightedSpottingArea.data
    },
    getCTAButtonClicks() {
      return this.actor.cta_button_visits || 0
    },
    layout2Slides() {
      const result = []
      if (this.mainVideo) {
        result.push({ key: 'layout_2_main_video' })
      }
      return [...result, ...this.actorImages.map((actorImage, index) => {
        return { ...actorImage, key: 'layout_2_actor_image' + index }
      })]
    },
    layout3Slides() {
      const result = []
      if (this.mainVideo) {
        result.push({ key: 'layout_3_main_video' })
      }
      if (this.actor.website_screenshot) {
        result.push({ key: 'layout_3_website_screenshot' })
      }
      return [...result, ...this.actorImages.map((actorImage, index) => {
        return { ...actorImage, key: 'layout_3_actor_image' + index }
      })]
    },
    userId() {
      return this.$store.getters.userId
    },
    cardsPerCarouselPage() {
      return Math.max(getSmallCardsPerCarouselPageBasedOnWindowWidth(this.windowWidth) - 3, 2)
    },
    mainVideo() {
      // The video to display on top of the page
      if (!this.actor.files) {
        return
      }

      const videoFiles = this.actor.files.filter(file => file.mimetype === 'video_link')

      if (videoFiles.length === 0) {
        return
      }

      const videoName = videoFiles[0].originalName

      return this.embedHtml(videoName)
    },
    sanitizedDescription() {
      if (!this.actor.description) {
        return ''
      }

      const additionalInfo = this.actor.description

      // Sanitize the html, but allow our own type of iframes
      return DOMPurify.sanitize(additionalInfo, {
        ADD_TAGS: ['iframe', 'a'],
        ALLOWED_ATTR: ['src', 'frameborder', 'allowfullscreen', 'href', 'target', 'data-mention-id', 'class'],
      })
    },
    sanitizedAdditionalInformation() {
      if (!this.actor.additional_information) {
        return ''
      }

      const additionalInfo = this.actor.additional_information

      if (additionalInfo === '<p></p>') {
        return ''
      }

      // Sanitize the html, but allow our own type of iframes
      return DOMPurify.sanitize(additionalInfo, {
        ADD_TAGS: ['iframe', 'a'],
        ALLOWED_ATTR: ['src', 'frameborder', 'allowfullscreen', 'href', 'target', 'data-mention-id', 'class'],
      })
    },
    zombieFeatureFlagEnabled() {
      return ['Workero', 'Satisco', 'own', 'salesenablement', 'local'].includes(this.$store.getters.ecosystemName)
    },
    company() {
      return this.$store.getters.detailActor
    },
    isPublic() {
      return this.$store.getters.isPublic
    },
    isOwner() {
      return this.$store.getters.isOwner
    },
    isMember() {
      return this.$store.getters.isMember
    },
    isTeamMember() {
      return this.$store.getters.isTeamMember
    },
    isPortfolioMember() {
      return this.$store.getters.isPortfolioMember
    },
    canAccessConnections() {
      return this.$store.state.config.publisher && this.$store.state.config.publisher.canViewActorConnections
    },
    isPrivate() {
      return this.$store.getters.isPrivate
    },
    isActorClaimable() {
      // anno 2023: please use the userCanClaim check, as the claimable property should now be computed
      return true
      // return this.actor.claimable
    },
    isActorClaimed() {
      // the "claimed" computed property should be coming from the transformed actor object
      return this.actor && this.actor.claimed
    },
    isActorPublished() {
      return this.actor.published
    },
    userCanSeeChanges() {
      return this.isOwner || this.$store.getters.isMember
    },
    claimedBadgeLabel() {
      if (this.userIsActorOwner) {
        return 'Claimed by you'
      }

      return 'Claimed'
    },
    personalInfo() {
      // Return an object only containing information for persons

      let employment = {}

      if (this.actor.employment) {
        employment = this.actor.employment
      }

      return [
        {
          term: 'Seniority',
          description: employment.seniority,
        },
        {
          term: 'Position',
          description: employment.title,
        },
        {
          term: 'Department',
          description: employment.role,
        },
        {
          term: 'Company',
          description: employment.name,
          url: employment.domain,
        },
        {
          term: 'Email',
          description: this.actor.email,
        },
        {
          term: 'Phone',
          description: this.actor.phone,
        },
        {
          term: 'Birth date',
          description: this.actor.founding_date,
        },
      ].filter(entry => entry.description)
    },
    companyFocusDefinitionListEntries() {
      const actor = this.actor

      let actorMarkets = []

      if (actor.market && actor.market.length > 0) {
        actorMarkets = _unique(actor.market)
      }

      const result = [
        {
          term: this.getTaxonomyAlias('category', false, 'Category'),
          description: this.fetchSimpleSingleTaxonomyLabel(actor.category),
        },
        {
          term: 'Business Type:',
          description: actor.type,
        },
        {
          term: (actor.industries || actor.subIndustries) && this.industriesLabel,
          descriptions: this.transformHierarchicalTaxonomy('industries', 'industry_id'),
        },
        {
          term: actor.domains && this.domainsLabel,
          descriptions: this.domainDescriptions,
        },
        {
          term: actor.activities && this.activitiesLabel,
          descriptions: this.fetchSimpleTaxonomyLabels(actor.activities),
        },
        {
          term: actor.market && `Target ${actor.market && actor.market.length === 1 ? 'Market' : 'Markets'}:`,
          descriptions: actorMarkets,
        },
        {
          term: this.technologyLabel,
          descriptions: this.transformHierarchicalTaxonomy('technology'),
        },
        {
          term: this.expertiseLabel,
          descriptions: this.fetchSimpleTaxonomyLabels(actor.expertises),
        },
        {
          term: this.motivationsLabel,
          descriptions: this.fetchSimpleTaxonomyLabels(actor.motivations),
        },
        {
          term: this.membershipsLabel,
          descriptions: this.fetchSimpleTaxonomyLabels(actor.membership),
        },
      ]

      if (!this.challengesAreEnabled) {
        result.push({
          term: 'Sustainability Goals',
          descriptions: this.sustainibilityGoals,
        })
      }

      return result.filter(entry => entry.description || (entry.descriptions && entry.descriptions.length))
    },
    domainDescriptions() {
      const actor = this.actor

      if (!actor.domains || (actor.domains && actor.domains.length === 0)) {
        return []
      }

      const domainsGroupedByParentId = _groupBy(actor.domains, 'parentId')

      // Map the root domains and add their children
      let domains = []

      if (domainsGroupedByParentId && domainsGroupedByParentId['null']) {
        domains = domainsGroupedByParentId['null'].map((domain) => {
          const descriptions = []

          if (domain.remark) {
            descriptions.push({ name: domain.remark })
          }

          return {
            description: domain.name,
            subDescriptions: (domainsGroupedByParentId[domain.id] || [])
                .concat(descriptions)
                .map((subdomain) => {
                  return {
                    'description': subdomain.name,
                    'subDescriptions': subdomain.remark ? [subdomain.remark] : [],
                  }
                })
                .sort(function (a, b) {
                  if (a.description < b.description) {
                    return -1
                  }

                  if (a.description > b.description) {
                    return 1
                  }

                  return 0
                }),
          }
        })
      }

      const domainDescriptions = []

      domains
          .sort(function (a, b) {
            if (a.term < b.term) {
              return -1
            }

            if (a.term > b.term) {
              return 1
            }

            return 0
          })
          .forEach(function (domain) {
            const domainDescription = { description: domain.description, descriptions: [] }

            domainDescription.subDescriptions = domain.subDescriptions
            domainDescription.subDescriptions.sort()

            domainDescriptions.push(domainDescription)
          })

      return domainDescriptions
    },
    challengesAreEnabled() {
      return this.$store.state.config.challengesAreEnabled
    },
    messageBoardsAreEnabled() {
      return this.$store.getters.areMessageBoardsEnabled
    },
    sustainibilityGoals() {
      let sustainabilityGoals = []

      if (this.actor.sustainability_goal && this.actor.sustainability_goal.length > 0) {
        sustainabilityGoals = _unique(this.actor.sustainability_goal)
      }
      return sustainabilityGoals
    },
    industriesLabel() {
      if (this.doesTaxonomyAliasExist('industries')) {
        return this.getTaxonomyAlias('industries') + ':'
      }

      return `Target ${this.actor['industries'].length === 1 ? 'Industry' : 'Industries'}:`
    },
    domainsLabel() {
      if (this.doesTaxonomyAliasExist('domains')) {
        return this.getTaxonomyAlias('domains', false, this.$t('actor_edit_domains')) + ':'
      }

      return 'Domains'
    },
    activitiesLabel() {
      if (this.doesTaxonomyAliasExist('activities')) {
        return this.getTaxonomyAlias('activities') + ':'
      }

      return 'Business ' + (this.actor.activities && this.actor.activities.length === 1 ? 'Activity' : 'Activities') + ':'
    },
    technologyLabel() {
      if (this.doesTaxonomyAliasExist('technology')) {
        return this.getTaxonomyAlias('technology') + ':'
      }

      return 'Technology Focus:'
    },
    expertiseLabel() {
      if (this.doesTaxonomyAliasExist('expertises')) {
        return this.getTaxonomyAlias('expertises')
      }

      return 'Expertise'
    },
    motivationsLabel() {
      if (this.doesTaxonomyAliasExist('motivations')) {
        return this.getTaxonomyAlias('motivations')
      }

      return 'Motivations'
    },
    membershipsLabel() {
      if (this.doesTaxonomyAliasExist('membership')) {
        return this.getTaxonomyAlias('membership')
      }

      return 'Membership'
    },
    chunkedConnections() {
      return _chunk(this.connections.filter(c => c.name && c.name.length > 0).slice(0, MAX_AMOUNT_OF_CONNECTIONS_IN_SIDEBAR), 2)
    },
    mainContent() {
      let news = []

      if (this.actor.news && this.actor.news.length > 0) {
        news = this.actor.news.map(newsData => {
          return Object.assign(newsData, {
            type: this.MAIN_CONTENT_TYPES.NEWS,
            actorName: this.actor.name,
            logo: this.actor.logo,
            id: this.actor.id,
          })
        })
      }

      if (this.mainContentData.rss && this.mainContentData.rss.length > 0) {
        const rssNews = this.mainContentData.rss.map(rss => {
          return Object.assign(rss, { type: this.MAIN_CONTENT_TYPES.NEWS })
        })

        news = news.concat(rssNews)
      }

      if (news.length > 0) {
        this.telexType = this.MAIN_CONTENT_TYPES.NEWS
      }

      return news
    },
    announcements() {
      if (!this.actor || !this.actor.announcements) {
        return []
      }

      return this.actor.announcements.filter(announcement => {
        return announcement.is_event === false
      }) || []
    },
    events() {
      if (!this.actor || !this.actor.announcements) {
        return []
      }

      return this.actor.announcements.filter(announcement => {
        return announcement.is_event
      }) || []
    },
    chunkedAnnouncements() {
      if (!this.announcements || this.announcements.length === 0) {
        return []
      }

      return _chunk(this.announcements, 2)
    },
    chunkedEvents() {
      if (!this.events || this.events.length === 0) {
        return []
      }

      return _chunk(this.events, 2)
    },
    canUserCreateAnnouncement() {
      if (this.$store.getters.isActor && !this.$store.getters.canCreateAnnouncement) {
        return false
      }

      return (this.$store.getters.isMember && this.$store.state.config.allowEdit == true) ||
          this.$store.getters.userClaimedIds.includes(this.actor.id) ||
          (_intersect(this.$store.getters.userContributionPortfolios, this.actorPortfolios).length > 0 && this.$store.getters.hasAccessToPortfolioSharing && this.$store.getters.isPortfolioMember) ||
          this.$store.getters.isOwner
    },
    actorAllFiles() {
      if (!this.actor.files || (this.actor.files && this.actor.files.length === 0)) {
        return []
      }

      return this.actor.files
    },
    actorFiles() {
      if (!this.actor.files || (this.actor.files && this.actor.files.length === 0)) {
        return []
      }

      return this.actor.files
          .slice(0, 8)
          .map(file => {
            let path = file.url
            if (file.mimetype === 'link' || file.mimetype === 'video_link') {
              path = file.originalName
            }
            return {
              filename: file.title && file.title.length > 0 && file.title !== 'File' ? file.title : file.originalName,
              mimeType: file.mimetype,
              path: path,
              preview: file.url || file.preview_w766 || file.preview_w320 || file.preview_w50,
              isPrivate: file.is_private,
            }
          })
    },
    actorImages() {
      return this.actorFiles.filter(file => file.mimeType.includes('image'))
    },
    actorFilesFileTypes() {
      if (!this.actor.files || (this.actor.files && this.actor.files.length === 0)) {
        return []
      }

      return this.actor.files.filter(file => !file.mimetype.includes('image'))
    },
    hasWebsiteScreenShot() {
      return this.actor.website_screenshot
    },
    hasDataForAtLeastTwoTelexTypes() {
      return _unique(this.mainContent.map(content => content.type)).length > 1
    },
    shouldShowAnnouncements() {
      return this.$store.getters.canUsePublicAnnouncements
    },
    galleryHasOnlyOneItem() {
      return (this.mainVideo && this.actorImages.length === 0) ||
          (!this.mainVideo && this.actorImages.length === 1) ||
          (!this.mainVideo && this.actorImages.length === 0 && (this.actor.website_screenshot && this.actor.website_screenshot.length > 0))
    },
    hasGalleryContent() {
      // Return true if there is content to be displayed in the gallery (i.e. uploaded images, youtube video, website screenshot)
      return this.actorImages.length > 0 || this.hasWebsiteScreenShot || this.mainVideo
    },
    hasAnyTypeOfContent() {
      return this.actorFilesFileTypes.length > 0 || this.mainVideo || this.actorImages.length > 0 || this.hasWebsiteScreenShot
    },
    layoutType() {
      if (!this.hasAnyTypeOfContent) {
        // No content whatsoever
        return 1
      }

      // Only a video
      if (this.mainVideo && this.actorFilesFileTypes.length === 0 && this.actorImages.length === 0 && !this.hasWebsiteScreenShot) {
        return 2
      }

      // The actor has content that we can display in the gallery
      if (this.hasGalleryContent) {
        return 3
      }

      // The actor only has only non-visual uploaded content
      return 4
    },
    actorNaceCodes() {
      return this.actor.nace_codes || { codes: [], main_code: '' }
    },
  },
  methods: {
    goToHighlightedSpottingAreaDetail() {
      trackMatomoEvent(MATOMO_EVENT_CATEGORIES.HIGHLIGHTED_SPOTTING_AREA, MATOMO_EVENT_ACTIONS.ADVANCED_ACTOR_DETAIL_PAGE, this.highlightedSpottingArea.id)
      this.$router.push(`/spotting-areas/${this.highlightedSpottingArea.id}`)
    },
    copyToClipboard,
    getImageForAnnouncement,
    toDateString,
    copyActorReportLink(link) {
      this.copyToClipboard(link)
      this.copiedActorReportLink = true

      setTimeout(() => {
        this.copiedActorReportLink = false
      }, 800)
    },
    copyActorReportName(link) {
      this.copyToClipboard(link)
      this.copiedActorReportName = true

      setTimeout(() => {
        this.copiedActorReportName = false
      }, 800)
    },
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    transformHierarchicalTaxonomy(property, parentKeyName = 'parent_id') {
      if (!this.actor[property]) {
        return
      }

      var descriptions = []

      this.actor[property].forEach((taxonomy) => {
        if (taxonomy[parentKeyName]) {
          return
        }

        const taxonomyDescription = {
          description: taxonomy.name,
          subDescriptions: [],
        }

        var children = this.actor[property].filter(tax => tax[parentKeyName] && tax[parentKeyName] == taxonomy.id)

        if (children && children.length > 0) {
          var childDescriptions = []
          children.forEach(function (child) {
            childDescriptions.push(child.name)
          })

          childDescriptions.sort()

          taxonomyDescription.subDescriptions = childDescriptions
        }

        descriptions.push(taxonomyDescription)
      })

      return descriptions
    },
    showImageDetail(image, name) {
      if (image) {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          detailImage: image,
          detailImageName: name || 'Image',
        })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.IMAGE_DETAIL)
      }
    },
    // Remove this function, only for testing
    handleChangedNaceCodes(value) {
      this.actor.nace_codes = value.codes
    },
    trackHeapEvent,
    embedHtml(url) {
      const videoId = getVideoId(url)

      if (EMBED_SERVICE_HANDLERS[videoId.service]) {
        return EMBED_SERVICE_HANDLERS[videoId.service](videoId.id)
      } else {
        return EMBED_SERVICE_HANDLERS.fallback(url)
      }
    },
    clickViewAllConnections(e) {
      trackHeapEvent('actorDetail.profile.clickViewAllConnections')
      e.preventDefault()
      this.$emit('clickViewAllConnections')
    },
    handleClickCreateAnnouncement(type) {
      trackHeapEvent('actorDetail.profile.clickCreateAnnouncement')
      this.$emit('clickCreateAnnouncement', type)
    },
    setTagFilter(tag) {
      trackHeapEvent('actorDetail.profile.clickTag')
      this.clearKeywords()
      const tagFilter = { facet: 'tags', value: tag.value }
      this.$store.commit(FILTERS_MUTATION_TYPES.ADD_KEYWORD, tagFilter)
      this.redirectToDirectory()
    },
    setPrivateTagFilter(tag) {
      let tagFilter
      trackHeapEvent('actorDetail.profile.clickPrivateTag')
      this.clearKeywords()

      if (this.isPublic && (this.isOwner || this.isTeamMember)) {
        tagFilter = { facet: 'private_tags', value: tag.value }
        this.$store.commit(FILTERS_MUTATION_TYPES.ADD_KEYWORD, tagFilter)
        this.redirectToDirectory()
      } else if (this.isOwner || this.isTeamMember) {
        tagFilter = { facet: 'private_tags', value: tag.value }
        this.$store.commit(FILTERS_MUTATION_TYPES.ADD_KEYWORD, tagFilter)
        this.redirectToDirectory()
      }
    },
    setDescriptionAnnotationTagFilter(tag) {
      trackHeapEvent('actorDetail.profile.clickPrivateTag')
      this.clearKeywords()

      let tagFilter

      if (this.isPublic && (this.isOwner || this.isTeamMember)) {
        tagFilter = { facet: 'description_annotation_tags', value: tag.value }
        this.$store.commit(FILTERS_MUTATION_TYPES.ADD_KEYWORD, tagFilter)
        this.redirectToDirectory()
      } else if (this.isOwner || this.isTeamMember) {
        tagFilter = { facet: 'description_annotation_tags', value: tag.value }
        this.$store.commit(FILTERS_MUTATION_TYPES.ADD_KEYWORD, tagFilter)
        this.redirectToDirectory()
      }
    },
    setSeoKeywordFilter(keyword) {
      trackHeapEvent('actorDetail.profile.clickSeoKeyword')
      this.clearKeywords()

      const tagFilter = { facet: 'seo_keywords', value: keyword }
      this.$store.commit(FILTERS_MUTATION_TYPES.ADD_KEYWORD, tagFilter)
      this.redirectToDirectory()
    },
    redirectToDirectory() {
      this.$router.push('/actors/')
    },
    clearKeywords() {
      this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR_KEYWORDS)
    },
    saveActorScreenshot(data) {
      const change = {
        website_screenshot: data,
      }
      this.$store.dispatch(ACTOR_ACTION_TYPES.UPDATE_ACTOR, {
        id: this.actor.id,
        data: change,
      })
          .then(() => {
            trackHeapEvent('actorDetail.saveActorScreenshot')
            this.fetchNewActorData()
          })
          .catch(errors => {
            this.errors = errors || {}
          })
    },
    selectFilter(filter) {
      trackHeapEvent('actorDetail.profile.changeTelexFilter', { filter })
      this.telexType = filter
    },
    fetchNewActorData() {
      this.$store.dispatch('ACTORS/FETCH_ACTOR_DETAIL', true)
    },
    likeArticle({ resourceId, status }) {
      likeArticle(resourceId, status)
    },
    hasDataForTelexType(type) {
      return this.mainContent.filter(category => category.type === type).length > 0
    },
    actualRender() {
      if (this.$refs.columns) {
        this.columns = Math.floor(this.$refs.columns.scrollWidth / 300) || 1
      }
    },
    openCreateResourcePanel(visibleMediaInputField = null) {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
        component: 'create-resource-panel',
        metaData: { actor_id: this.actor.id, visibleMediaInputField: 'resource' },
      })
    },
    openFileRepositorySidepanel() {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
        component: 'actor-file-repository',
        metaData: this.actor,
      })
    },
    resetNumberOfFilesInFileContainer() {
      if (this.actorFiles.length > 7) {
        if (this.$refs.fileContainer) {
          if (this.$refs.fileContainer.$el.clientWidth < 195) {
            this.numberOfVisibleFiles = 1
          } else if (this.$refs.fileContainer.$el.clientWidth < 202) {
            this.numberOfVisibleFiles = 2
          } else if (this.$refs.fileContainer.$el.clientWidth < 290) {
            this.numberOfVisibleFiles = 4
          } else if (this.$refs.fileContainer.$el.clientWidth < 380) {
            this.numberOfVisibleFiles = 6
          } else {
            this.numberOfVisibleFiles = 8
          }
        }
      } else {
        if (this.$refs.fileContainer) {
          if (this.$refs.fileContainer.$el.clientWidth < 195) {
            this.numberOfVisibleFiles = 1
          } else if (this.$refs.fileContainer.$el.clientWidth < 202) {
            this.numberOfVisibleFiles = 2
          } else if (this.$refs.fileContainer.$el.clientWidth < 290) {
            this.numberOfVisibleFiles = 4
          } else if (this.$refs.fileContainer.$el.clientWidth < 390) {
            this.numberOfVisibleFiles = 6
          } else {
            this.numberOfVisibleFiles = 8
          }
        }
      }
    },
    handleShowClaimModal() {
      this.$emit('showClaimModal')
    },
    removeClaim() {
      // Deleting a claim should now go via the Contributors section
      return false

      // Display a pop-up that will delete the actor claim if the user clicks "confirm"
      const modalContextData = Object.assign({
        name: this.actor.name,
        actorId: this.actor.id,
        modalContextType: 'claim',
      })

      this.$store.commit('UI/SET_MODAL_CONTEXT', modalContextData)
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
    },
    deleteClaim() {
      // Deleting a claim should now go via the Contributors section
      return false

      const isUserActorOwner = this.userIsActorOwner

      deleteClaim(this.actor.id)
          .then(response => {
            this.fetchDetail()

            if (isUserActorOwner) {
              fetchProfile()
                  .then(profile => {
                    this.$store.commit('USER/UPDATE_PROFILE', profile)

                    // Go to profile page
                    this.$router.push('/actors')
                  })
            }
          })
    },
    fetchDetail() {
      this.$store.dispatch(ACTOR_ACTION_TYPES.FETCH_ACTOR_DETAIL, this.actor.id)
    },
    startConversation($event, actorId, actorName, userId) {
      $event.stopPropagation()

      if (userId == this.userId) {
        return
      }

      Conversations.post({ actor_id: actorId, recipient_id: userId }).then((conversation) => {
        this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
        this.$router.push('/profile/conversations/' + conversation.id).then(() => {
            this.$store.commit (UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
              component: 'user-information-side-panel',
              metaData: {
                userId: userId,
                allowDelete: false,
              },
            })
          })
      })
    },
  },
  mounted() {
    this.showOrHideReport()
    this.actualRender()
    this.resetNumberOfFilesInFileContainer()

    this.$bus.on('resize', () => {
      this.actualRender()
      this.resetNumberOfFilesInFileContainer()
      this.handleResize()
    })

    this.$bus.on('claimDeleteConfirmation', (context) => {
      if (context.actorId === this.actor.id) {
        this.deleteClaim()
      }
    })
  },
  beforeUnmount() {
    this.$bus.off('claimDeleteConfirmation')
    this.$bus.off('resize')
  },
  watch: {
    '$route.params.id': {
      handler() {
        // This is being called before the component is destroyed (i.e. user goes to a different page than the actor profile) so we need to check if we're on the applicable route
        if (this.$route.path.startsWith('/actors')) {
          this.resetNumberOfFilesInFileContainer()
        }
      },
      immediate: false, // calls the handler on component mount/create as well, so no need to call fn on mounted again
    },
  },
  mixins: [CompanyMixin, ConfigMixin, ReportMixin],
  components: {
    Avatar,
    AnnouncementOrResourceCard,
    AvatarList,
    NaceCodePicker,
    ActorDetailProfileMainContentBlock,
    ArticlePreview,
    AsideSection,
    Badge,
    CallToActionCard,
    CardNews,
    Carousel,
    CompletionBar,
    Connection,
    DefinitionList,
    FileInput,
    FileThumbnail,
    FormGroup,
    ImageInput,
    Keyword,
    NewCard,
    Modal,
    RadioButton,
    Slide,
    Navigation,
    ActorDetailSDGs,
    ActorDetailReport,
    Tag,
    Loading,
    ActorDetailProfileTrends,
  },
}
</script>

<style lang="scss" scoped>

</style>
