<template>
  <finalise-onboarding type="forgot-password" :is-closeable="isCloseable" v-if="isNewProfileEnabled" is-modal/>
  <forgot-password-modal-old v-else @close="$emit('close')" :is-closeable="isCloseable"/>
</template>
<script>
  import FinaliseOnboarding from '../../pages/Onboarding/FinaliseOnboarding.vue'
  import ForgotPasswordModalOld from './ForgotPasswordModalOld.vue'

  export default {
    props: {
      isCloseable: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {}
    },
    methods: {},
    computed: {
      isNewProfileEnabled () {
        return this.$store.getters.isNewProfileEnabled
      },
    },
    mounted () {
    },
    components: {
      ForgotPasswordModalOld,
      FinaliseOnboarding,
    },
  }
</script>
