import { _intersect, _unique, _without, convertToVimeoEmbed, convertToYoutubeEmbed, getDefaultFallbackImageUrl, inert, toBounds, truncate } from './helpers'
import { toAmount } from './currency'
import { actorLogo } from './actor'

function fix (base, handle) {
  if (!handle) {
    return handle
  }
  if (handle.charAt(0) === '@') {
    handle = base + handle.slice(1)
  } else if (handle.indexOf('://') === -1) {
    handle = base + handle
  }
  return handle
}

export default {
  computed: {
    datascoutsScore () {
      return typeof this.company.current_score === 'number' ? this.company.current_score : '-'
    },
    seoScore () {
      return typeof this.company.seo_score === 'number' ? this.company.seo_score : '-'
    },
    socialScore () {
      return typeof this.company.social_score === 'number' ? this.company.social_score : '-'
    },
    tractionScore () {
      return typeof this.company.traction_score === 'number' ? this.company.traction_score : '-'
    },
    businessSizeScore () {
      return typeof this.company.business_size_score === 'number' ? this.company.business_size_score : '-'
    },

    actorHasSocialItems () {
      return this.company.url ||
        (this.company.twitter && this.company.twitter.length > 0) ||
        (this.company.facebook && this.company.facebook.length > 0) ||
        (this.company.reddit && this.company.reddit.length > 0) ||
        (this.company.linkedin && this.company.linkedin.length > 0) ||
        (this.company.vimeo && this.company.vimeo.length > 0) ||
        (this.company.crunchbase && this.company.crunchbase.length > 0) ||
        (this.company.youtube && this.company.youtube.length > 0) ||
        (this.company.instagram && this.company.instagram.length > 0) ||
        (this.company.rss_feed && this.company.rss_feed.length > 0)
    },
    // Converted company properties
    addressLine () {
      var a = this.company.address
      if (!a || !a.city) {
        return
      }
      return (a.street ? a.street + (a.number ? ' ' + a.number : '') + ', ' : '') + (a.zip || '') + '&nbsp;' + a.city
    },
    legendValue () {
      var legendValue = this.company.legend

      if (this.$store.state.filters.legendProperty && this.company[this.$store.state.filters.legendProperty]) {
        legendValue = this.company[this.$store.state.filters.legendProperty]
      }

      // If the legend value is an array, then use the first item to use as the legend value
      if (Array.isArray(legendValue)) {
        legendValue = legendValue[0]
      }

      if (!legendValue) {
        return ''
      }

      if (legendValue.value) {
        return legendValue.value
      }

      return legendValue
    },
    legendProperty () {
      return this.$store.state.filters.legendProperty
    },
    legendMapping () {
      return this.$store.state.config.legendMapping
    },
    hexColours () {
      return this.$store.getters.hexColours
    },
    shortDescription () {
      return truncate(this.company.short_description, 140)
    },
    ribbonColor () {
      if (this.ribbonClass) {
        return this.hexColours[this.legendMapping[this.legendProperty][this.legendValue]]
      }

      return '#bbb'
    },
    ribbonClass () {
      return this.legendValue && this.legendMapping[this.legendProperty] && this.legendMapping[this.legendProperty][this.legendValue] && this.hexColours[this.legendMapping[this.legendProperty][this.legendValue]] !== undefined
    },
    ribbonStyle () {
      if (this.ribbonClass) {
        return 'background-color:' + this.hexColours[this.legendMapping[this.legendProperty][this.legendValue]]
      }

      return 'background-color: #bbb'
    },
    companyTags: {
      get () {
        return this.company && this.company.tags || []
      },
      set (t) {
        this.company.tags = _unique(t || [])
      },
    },
    exitDate: {
      get () {
        return this.company.exit_date && this.company.exit_date.slice(0, 10) || ''
      },
      set (d) {
        this.company.exit_date = d
      },
    },
    foundingDate: {
      get () {
        return this.company.founding_date && this.company.founding_date.slice(0, 10) || ''
      },
      set (d) {
        this.company.founding_date = d
      },
    },
    employeesArray: {
      get () {
        return this.company.employees || []
      },
      set (v) {
        this.company.employees = v || []
      },
    },
    employeeCount: {
      get () {
        if (!this.company.employees) {
          return
        }

        const last = this.employeeBounds

        if (!last) {
          return
        }

        // It's a range
        if (last.lower_bound && last.upper_bound && last.lower_bound !== last.upper_bound) {
          return last.lower_bound + '-' + last.upper_bound
        }

        // It's just a number
        return last.lower_bound || last.upper_bound || ''
      },
      set (d) {
        const bounds = toBounds(d)
        if (!bounds || !bounds.lower_bound) {
          return
        }
        bounds.date = new Date().toJSON().slice(0, 10)

        // Set first employee count
        if (!this.company.employees || !this.company.employees.length) {
          this.company.employees = [bounds]
          return
        }

        // Replace todays employee count
        for (let i = this.company.employees.length - 1; i >= 0; i--) {
          if (this.company.employees[i].date === bounds.date) {
            this.$set(this.company.employees, i, bounds)
            return
          }
        }

        // Add a new employee count for today
        this.company.employees.push(bounds)
      },
    },
    employeesSorted () {
      return inert(this.company.employees || []).sort((a, b) => (b.date || '').localeCompare(a.date || ''))
    },
    employeeBounds () {
      return this.employeesSorted[0] || {}
    },
    fundingRounds () {
      return (this.company.funding_rounds || [])
        .slice()
        .sort((a, b) => (b.started_at || '').localeCompare(a.started_at || ''))
    },
    grants () {
      return (this.company.grants || [])
        .slice()
        .sort((a, b) => (b.started_at || '').localeCompare(a.started_at || ''))
    },
    financials () {
      if (!this.company.financials || !this.company.financials.length) {
        return
      }

      const financialMap = {
        turnover: 'Turnover',
        opreating_income: 'Revenue',
        added_value: 'Value Add',
      }

      return Object.keys(financialMap).map(function (f) {
        return this.company.financials[0][f] && {
          key: financialMap[f],
          value: this.company.financials[0][f],
        }
      }).filter(Boolean)
    },
    httpUrl () {
      if (!this.company.url) {
        return ''
      }
      var proto = this.company.url.substr(0, 6)
      return proto === 'http:/' || proto === 'https:' ? this.company.url : 'http://' + this.company.url
    },
    industryString () {
      return this.company.industries ? window.INDUSTRIES.filter(i => this.company.industries.includes(i.id)).map(i => i.name).join(', ') : ''
    },
    updated () {
      return this.company.updated_at && this.company.updated_at.replace('T', ' ').slice(0, 10)
    },
    companyLogoUrl () {
      return this.logoUrl(this.company)
    },
    companyDomains () {
      const domains = this.company.domains || []

      // All parent ids
      let parents = _unique(domains.map(d => d.parentId || d.id))

        // Map to domain objects
        .map(id => {
          return inert(domains.find(d => d.id === id) || this.$store.state.taxonomies.domains.find(d => d.id === id) || {})
        })

      // When coming from list view, the domains are strings...
      parents = parents.filter(p => typeof p === 'object')

      // Add child domains
      parents.forEach(parent => {
        parent.children = domains.filter(d => d.parentId === parent.id)
      })

      return parents
    },
    companyTechnologies () {
      return this.company.technology || []
    },
    companyActivities () {
      return this.company.activities || []
    },
    companyFiles () {
      return this.company.files ? this.company.files.filter(f => !f.deleted_at) : []
    },
    personExpertise () {
      return []
    },
    websiteScreenshot () {
      return this.company.website_screenshot ||
        this.company.website_screenshot_upload ||
        this.getDefaultFallbackImageUrl()
    },
    featuredImage () {
      return this.company.featured_image_url || ''
    },
    hasUserClaimedActor () {
      if (this.userClaimedIds && this.userClaimedIds.length > 0) {
        return this.userClaimedIds.includes(this.actor.id)
      }

      return false
    },
    userClaimedIds () {
      return this.$store.getters.userClaimedIds
    },
    isActorClaimable () {
      // anno 2023: please use the userCanClaim check, as the claimable property should now be computed
      return true
      // return this.company && this.company.claimable
    },
    isActorUnclaimed () {
      // the "claimed" computed property should be coming from the transformed actor object
      return this.company && (!this.company.claimed)
    },
    isActorPublished () {
      return this.company.published
    },
    userCanClaim () {
      if (this.company.actor_type === 'Product') {
        return false
      }

      if (this.company.actor_type === 'Subsidiary') {
        return false
      }

      return this.$store.getters.publisher &&
        (
          (this.$store.getters.isMember && this.isActorUnclaimed) ||
          (
            // The user needs to be an ecosystem member, has to be able to claim the actor taking into account claimable actor types and has either no claims, or not yet claimed this profile
            this.$store.getters.isActor && this.isActorClaimable && this.$store.getters.claimableActorTypes.includes(this.company.actor_type) &&
            (!this.$store.getters.userClaimedIds || !this.$store.getters.userClaimedIds.includes(this.company.id))
          )
        )
    },
    // Determine if the user can add new actors via relationship input (i.e. new founders, new investors)
    userCanAddNewActors () {
      return this.$store.getters.isMember
    },
    userCanEdit () {
      // Actors cannot be edited on exploration-only platforms (aka scouting platform)
      if (this.$store.getters.isExplorationOnly) {
        return false
      }

      return (this.$store.getters.isMember && this.$store.state.config.allowEdit == true) ||
        this.$store.getters.userClaimedIds.includes(this.company.id) ||
        (_intersect(this.$store.getters.userContributionPortfolios, this.actorPortfolios).length > 0 && this.$store.getters.hasAccessToPortfolioSharing && this.$store.getters.isPortfolioMember) ||
        this.$store.getters.isOwner
    },
    userCanAccessReport () {
      const ks = this.$store.state.config.knowledgeSharing || {}

      return ks.enabled &&
        this.hasReportTemplate && this.actor.current_report &&
        (this.userCanEdit || this.isMember || (this.$store.state.config.viewReportOfActorTypes && this.$store.state.config.viewReportOfActorTypes.includes(this.company.actor_type))) &&
        (
          !this.$store.getters.isPortfolioMember ||
          (this.$store.getters.isPortfolioMember && this.$store.state.config.allowReportEdit)
        )
    },
    userCanAccessNotes () {
      return this.isOwner || this.$store.getters.isTeamMember
    },
    userCanAccessSustainability () {
      return ['sustainability', 'local'].includes(this.$store.getters.ecosystemName) && this.$store.getters.hasAccessToScores
    },
    actorHasKeywords () {
      return this.company.scraped_keywords && this.company.scraped_keywords.length > 0
    },
    userCanEditPortfolio () {
      return (this.$store.getters.isMember && this.$store.state.config.allowEdit == true) ||
        (_intersect(this.$store.getters.userContributionPortfolios, this.actorPortfolios).length > 0 && this.$store.getters.hasAccessToPortfolioSharing && this.$store.getters.isPortfolioMember) ||
        this.$store.getters.isOwner
    },
    // The portfolios that the actor is part of
    actorPortfolios () {
      return this.company.includedPortfolios || []
    },
    userIsActorOwner () {
      if (this.$store.getters.isActor) {
        const claim = this.$store.state.user.profile.claims.find(claim => claim.id === this.company.id) || {}
        return claim.claim_type === 'actor-owner' && claim.claim_verified
      }

      return this.$store.getters.userClaimedIds.includes(this.company.id)
    },
    isZombie () {
      return this.company && this.company.is_zombie == true
    },
    // Agents
    canAdministratorAgentBeUsed () {
      return (this.$store.getters.isOwner || this.$store.getters.isTeamMember) &&
        this.company.company_number &&
        this.company.company_number.length > 0 &&
        (
          ['BE', 'NZ'].includes(this.company.jurisdiction) ||
          (this.company.address && this.company.address.country_code && ['BE', 'NZ'].includes(this.company.address.country_code))
        )
    },
    canInvestorAgentBeUsed () {
      return (this.$store.getters.isOwner || this.$store.getters.isTeamMember) &&
        (
          (
            this.company.company_number &&
            this.company.company_number.length > 0 &&
            (
              ['BE', 'NZ'].includes(this.company.jurisdiction) ||
              (this.company.address && this.company.address.country_code && ['BE', 'NZ'].includes(this.company.address.country_code))
            )
          ) ||
          (this.company.url && this.company.url.length > 0) ||
          (this.company.crunchbase && this.company.crunchbase.length > 0)
        )
    },
    canProjectAgentBeUsed () {
      return (this.$store.getters.isOwner || this.$store.getters.isTeamMember) &&
        (
          (
            this.company.company_number &&
            this.company.company_number.length > 0
          ) ||
          (
            this.company.vat_number &&
            this.company.vat_number.length > 0
          ) ||
          (this.company.cordis_id && this.company.cordis_id > 0) ||
          (this.company.url && this.company.url.length > 0) ||
          (this.company.crunchbase && this.company.crunchbase.length > 0) ||
          (this.company.address && this.company.address.country_code && this.company.address.country_code.length > 0)
        )
    },
    canFoundingTeamAgentBeUsed () {
      return (this.$store.getters.isOwner || this.$store.getters.isTeamMember) && this.company.crunchbase && this.company.crunchbase.length > 0
    },
    canCurrentTeamAgentBeUsed () {
      return (this.$store.getters.isOwner || this.$store.getters.isTeamMember) && this.company.crunchbase && this.company.crunchbase.length > 0
    },
    canLookalikeAgentBeUsed () {
      return (this.$store.getters.isOwner || this.$store.getters.isTeamMember) && this.company.description && this.company.description.length > 0 && !this.$store.getters.isExplorationOnly // Exploration already has "similar actors" so no need to add this into an agent
    },
    canPortfolioCrawlerAgentBeUsed () {
      return (this.$store.getters.isOwner || this.$store.getters.isMember) && this.company.url && this.company.url.length > 0 && ['advanced', 'full'].includes(this.$store.getters.dataPackage)
    },
    canContactsAgentBeUsed () {
      // Contacts are being pulled in via Hunter.io, which is only available in the advanced data package
      return this.$store.getters.dataPackage == 'advanced' && this.company.url && this.company.url.length > 0
    },
    canQaStaffAgentBeUsed () {
      // QA staff agents is an agent that fetches employee / staff suggestions from news content, suggested by the QA agent
      // TODO: only make it available in production if there's news linked to the company, fetched via the telex endpoint
      return (this.$store.getters.ecosystemName === 'VOPAK-monitoring' || this.$store.getters.ecosystemName == 'colruyt-actormapping' || this.$store.getters.ecosystemName === 'local') && this.$store.getters.isDeveloper && this.company.wikipedia && this.company.wikipedia.length > 0
    },
    canAcquisitionAgentBeUsed () {
      return (this.$store.getters.isOwner || this.$store.getters.isTeamMember) && this.company.crunchbase && this.company.crunchbase.length > 0
    },
    actorOrigin () {
      if (!this.company.origin) {
        return 'the DataScouts web application'
      }

      if (this.company.origin == 'Agent') {
        return 'a DataScouts Agent'
      }

      if (this.company.origin.startsWith('Google')) {
        return 'the Google Spreadsheet'
      }

      if (this.company.origin.startsWith('Online')) {
        return 'the configured CRM tool'
      }

      if (this.company.origin.startsWith('Platform')) {
        return 'the platform API'
      }

      if (this.company.origin.startsWith('Portfolio')) {
        return 'the portfolio sync'
      }

      return 'the DataScouts web application'
    },
  },
  methods: {
    getDefaultFallbackImageUrl,
    convertToVimeoEmbed,
    convertToYoutubeEmbed,
    addTag (s) {
      var tags = _unique(this.companyTags.slice(0).concat(s).filter(Boolean))

      if (tags.length <= 5) {
        this.companyTags = tags
        this.newTag = ''
      }
    },
    rmTag (tag) {
      this.companyTags = _without(this.companyTags, tag)
    },
    fixUrl () {
      if (this.company) {
        this.company.url = fix('http://', this.company.url)
      } else {
        this.CompanyUrl = fix('http://', this.CompanyUrl)
      }
    },
    fixTwitter () {
      if (this.company) {
        this.company.twitter = fix('https://twitter.com/', this.company.twitter)
      } else {
        this.CompanyTwitter = fix('http://twitter.com/', this.CompanyTwitter)
      }
    },
    fixFacebook () {
      if (this.company) {
        this.company.facebook = fix('https://facebook.com/', this.company.facebook)
      } else {
        this.CompanyFacebook = fix('http://facebook.com/', this.CompanyFacebook)
      }
    },
    fixLinkedin () {
      if (this.company) {
        this.company.linkedin = fix('https://www.linkedin.com/in/', this.company.linkedin)
      } else {
        this.CompanyLinkedin = fix('http://www.linkedin.com/in/', this.CompanyLinkedin)
      }
    },
    fetchSimpleTaxonomyLabels (taxonomyValues) {
      if (!taxonomyValues || taxonomyValues.length == 0) {
        return []
      }

      var labels = taxonomyValues.map(taxonomy => taxonomy.label)

      return _unique(labels)
    },
    fetchSimpleSingleTaxonomyLabel (taxonomyValue) {
      if (!taxonomyValue || !taxonomyValue) {
        return ''
      }

      return taxonomyValue.label
    },
    containsPerson (relationship) {
      // Return true if the relationship of the actor contains a Person actor
      if (!this.company[relationship] || (this.company[relationship] && this.company[relationship].length == 0)) {
        return false
      }

      var relatedPersons = this.company[relationship].filter(actor => actor.actor_type == 'Person')

      return relatedPersons.length > 0
    },
    logoUrl: actorLogo,
  },
  filters: {
    toAmount,
  },
}
