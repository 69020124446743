<template>
  <div class="image-inp" :style="inputStyle" :class="classes">
    <div class="image-inp__ratio" :style="ratioStyle">
      <img class="image-inp__image fill-absolute" v-if="validImage" :src="modelValue" @error="onError">
      <div class="image-input__action fill-absolute">
        <div v-if="buttonText" class="button" :class="buttonVariant">{{buttonText}}</div>
        <div v-else-if="allowRemove && modelValue" class="button clickable" :class="buttonVariant" @click="removeImage">{{$t('image_upload_remove')}}</div>
        <div v-else="buttonText" class="button" :class="buttonVariant">{{buttonText}}Upload</div>
        <div v-if="placeholder" class="image-input__placeholder">{{placeholder}}</div>
      </div>
      <input @change="previewThumbnail" class="image-inp__input fill-absolute" :class="{'not-clickable' : allowRemove && modelValue}" type="file" accept="image/x-png,image/png,image/gif,image/jpeg" style="width: 100%">
    </div>
  </div>
</template>

<script>
export default {
  props: ['modelValue', 'placeholder', 'width', 'height', 'buttonText', 'variant', 'allowRemove', 'isSimplified'],
  emits: ['update:modelValue'],
  data () {
    return {
      error: null
    }
  },
  computed: {
    classes () {
      return {
        'image-inp--empty': !this.validImage,
        'image-inp--valid': this.validImage,
        'is-simplified': this.isSimplified
      }
    },
    inputStyle () {
      return {
        width: this.width + 'px',
      }
    },
    ratioStyle () {
      return {
        'padding-bottom': (100 * this.height / this.width) + '%'
      }
    },
    validImage () {
      return this.modelValue && this.modelValue !== this.error && this.modelValue !== 'images/logos/undefined'
    },
    buttonVariant () {
      return this.variant && this.variant.length > 0 ? `button--${this.variant}` : 'button--secondary'
    }
  },
  methods: {
    removeImage () {
      this.$emit('remove')
    },
    previewThumbnail (event) {
      const input = event.target || event.srcElement

      if (input.files && input.files[0]) {
        const reader = new window.FileReader()

        reader.onload = (e) => {
          this.$emit('update:modelValue', e.target.result)
        }

        reader.readAsDataURL(input.files[0])
      }
    },
    onError () {
      console.warn('ImageInput received an invalid image:', this.modelValue)
      this.error = this.modelValue
    }
  }
}
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";
.image-inp {
  position: relative;
  max-width: 100%;
  background: #eee;
}
.image-inp--empty {
  border: 4px dashed #ccc;
  background: white;
}
.image-inp__ratio {
  width: 100%;
}
.image-inp__image {
  margin: auto;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  background: white;
  transition: opacity .3s;
  .image-inp:hover & {
    opacity: .4;
  }
}
.image-input__action {
  pointer-events: none;
  transition: opacity .3s;
  .image-inp--valid & {
    opacity: 0;
  }
  .image-inp:hover & {
    opacity: 1;
  }
  .clickable {
    pointer-events: all;
  }
}
.image-inp__input {
  opacity: 0;
  cursor: pointer;
  pointer-events: all;
  &.not-clickable {
    pointer-events: none;
  }
}
.fill-absolute {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.image-input__placeholder {
  margin-top: 8px;
}

.image-inp.is-simplified {
  border-radius: $simplified-input-border-radius;
  border-color: $simplified-input-border-color;
  border-width: 2px;

  .fill-absolute {
    border-radius: $simplified-input-border-radius;
    flex-direction: column;
  }
  .image-input__placeholder {
    color: var(--primary-community);
  }

  .button {
    background: var(--primary-community-lightest);
    color: var(--primary-community);
    border-radius: $default-border-radius;
  }
}
</style>
