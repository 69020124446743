import introJs from 'intro.js'
import { MUTATION_TYPES as INTRO_MUTATION_TYPES } from '../store/modules/introJs.js'
import { MUTATION_TYPES as USER_MUTATION_TYPES } from '../store/modules/user-helpers.js'
import { SECTIONS } from '../constants/homepage.js'
import TranslationsMixin from './TranslationsMixin.js'
import OnboardingMixin from './OnboardingMixin.js'

export const introTypes = {
  'default_settings_configuration_exploration_owner_and_member': 'default_settings_configuration_exploration_owner_and_member',
  'default_settings_configuration_monitoring_owner_and_member': 'default_settings_configuration_monitoring_owner_and_member',
  'default_homepage_configuration_monitoring_owner_and_member': 'default_homepage_configuration_monitoring_owner_and_member',
  'default_homepage_configuration_monitoring_portfolio_member_and_actor': 'default_homepage_configuration_monitoring_portfolio_member_and_actor',
  'default_homepage_configuration_exploration_owner_and_member': 'default_homepage_configuration_exploration_owner_and_member',
  'default_concept_map_configuration_exploration': 'default_concept_map_configuration_exploration',
  'default_explore_content_configuration_exploration': 'default_explore_content_configuration_exploration',
  'default_personal_home_configuration_monitoring_all_users': 'default_personal_home_configuration_monitoring_all_users',
  'default_actor_profile_configuration_monitoring_all_users': 'default_actor_profile_configuration_monitoring_all_users',
  'default_spotting_areas_configuration_monitoring_all_users': 'default_spotting_areas_configuration_monitoring_all_users',
  'default_message_board_configuration_monitoring_all_users': 'default_message_board_configuration_monitoring_all_users',
  'default_communities_configuration_monitoring_all_users': 'default_communities_configuration_monitoring_all_users',
  'default_communities_home_configuration_monitoring_all_users': 'default_communities_home_configuration_monitoring_all_users',
  'default_communities_detailpage_configuration_monitoring_all_users': 'default_communities_detailpage_configuration_monitoring_all_users',
  'default_spotting_areas_detailpage_configuration_monitoring_all_users': 'default_spotting_areas_detailpage_configuration_monitoring_all_users',
  'default_monitoring_configuration_exploration_owner': 'default_monitoring_configuration_exploration_owner',
}

export const defaultAllowedIntros = [
  introTypes.default_settings_configuration_exploration_owner_and_member,
  introTypes.default_settings_configuration_monitoring_owner_and_member,
  introTypes.default_homepage_configuration_monitoring_owner_and_member,
  introTypes.default_homepage_configuration_monitoring_portfolio_member_and_actor,
  introTypes.default_homepage_configuration_exploration_owner_and_member,
  introTypes.default_concept_map_configuration_exploration,
  introTypes.default_explore_content_configuration_exploration,
  introTypes.default_personal_home_configuration_monitoring_all_users,
  introTypes.default_actor_profile_configuration_monitoring_all_users,
  introTypes.default_spotting_areas_configuration_monitoring_all_users,
  introTypes.default_message_board_configuration_monitoring_all_users,
  introTypes.default_communities_configuration_monitoring_all_users,
  introTypes.default_communities_home_configuration_monitoring_all_users,
  introTypes.default_communities_detailpage_configuration_monitoring_all_users,
  introTypes.default_monitoring_configuration_exploration_owner,
  introTypes.default_spotting_areas_detailpage_configuration_monitoring_all_users,
]

export default {
  data: function () {
    return {
      shouldRunSettingsIntroOnLoad: false,
    }
  },
  computed: {
    config () {
      return this.$store.state.config
    },
    seenIntros () {
      return this.$store.state.user.profile.settings.seenIntros ? this.$store.state.user.profile.settings.seenIntros.split(',') : []
    },
    isExplorationOnly () {
      return this.$store.getters.isExplorationOnly
    },
    isNewUser () {
      const INTROJS_RELEASE_DATE = new Date(this.config.introJsAutoTriggeredUsers).getTime() || 1614298689722
      return new Date(this.$store.state.user.profile.created_at).getTime() > INTROJS_RELEASE_DATE
    },
    hasAccessToMonitoring () {
      return this.$store.getters.hasAccessToMonitoring
    },
    hasAccessToCommunities () {
      return this.$store.getters.hasAccessToNewCommunities
    },
    ecosystemDisplayName () {
      return this.$store.getters.ecosystemDisplayName
    },
    ecosystemEmail () {
      return this.$store.state.config.email
    },
    currentIntro: {
      get () {
        if (this.$store.state.introJs.introIsActive) {
          return window.currentIntro
        }
        return null
      },
      set (intro) {
        // assign to window to allow calling refresh() on the object directly
        window.currentIntro = intro
        this.$store.commit(INTRO_MUTATION_TYPES.SET_INTRO_IS_ACTIVE, !!intro)
      },
    },
    explorationHomePageIsRequired () {
      return this.$store.getters.isOwner || this.$store.getters.isMember
    },
    homepageTemplateIsExploration () {
      return this.$store.state.config.homepageTemplate && this.$store.state.config.homepageTemplate.find(template => template.id === SECTIONS.EXPLORATION.id)
    },
  },
  methods: {
    introJs,
    shouldForceDefaultExplorationConfig (config, forTopBar) {
      if (this.explorationHomePageIsRequired && !this.homepageTemplateIsExploration) {
        // no tutorial if not on exploration homepage
        return false
      }

      if (forTopBar) {
        return this.isExplorationOnly
      }

      return (this.isNewUser || config.introJsAutoTriggeredUsers === 'all') && this.isExplorationOnly
    },
    shouldForceDefaultMonitoringConfig (config, forTopBar) {
      if (forTopBar) {
        return this.hasAccessToMonitoring
      }

      return (this.isNewUser || config.introJsAutoTriggeredUsers === 'all') && this.hasAccessToMonitoring
    },
    buildIntroJsConfig (config, userRole, forTopBar) {
      let result = {}

      if (userRole === undefined) {
        userRole = 'visitor'
      }

      if (config.introJsCustomConfig) {
        result = JSON.parse(config.introJsCustomConfig)
      } else if ((config.introJsDefaultSelection && config.introJsDefaultSelection.length > 0) || this.shouldForceDefaultExplorationConfig(config, forTopBar) || this.shouldForceDefaultMonitoringConfig(config, forTopBar)) {
        let allowedIntros = []

        // if no selection is made
        if (this.shouldForceDefaultExplorationConfig(config, forTopBar) && (!config.introJsDefaultSelection || config.introJsDefaultSelection.length === 0)) {
          allowedIntros = [introTypes.default_homepage_configuration_exploration_owner_and_member, introTypes.default_concept_map_configuration_exploration, introTypes.default_explore_content_configuration_exploration, introTypes.default_monitoring_configuration_exploration_owner]
        }

        // if no selection is made
        if (this.shouldForceDefaultMonitoringConfig(config, forTopBar) && (!config.introJsDefaultSelection || config.introJsDefaultSelection.length === 0)) {
          allowedIntros = [introTypes.default_homepage_configuration_monitoring_owner_and_member, introTypes.default_settings_configuration_monitoring_owner_and_member, introTypes.default_homepage_configuration_monitoring_portfolio_member_and_actor]
        }

        if (config.introJsDefaultSelection && config.introJsDefaultSelection.length && (forTopBar || config.introJsAutoTriggeredUsers === 'all' || this.isNewUser)) {
          allowedIntros = config.introJsDefaultSelection
        }

        result = allowedIntros.reduce((acc, key) => {
          let introToAdd
          // @formatter:off --> prevent invalid JSON when copying due to quotes and comma's
          switch (key) {
            case introTypes.default_homepage_configuration_exploration_owner_and_member:
              introToAdd = {
                'page': 'home',
                'keyboardNavigation': false,
                'scrollToElement': false,
                'roles': ['owner'],
                'steps': [
                  {
                    'title': config.introTitleLoggedIn,
                    'intro': config.tagline
                  }, {
                    'element': '#introjs-latest-updated-actors',
                    'title': 'Homepage',
                    'intro': 'Find the news of your ecosystem'
                  }, {
                    'element': '#introjs-message-board',
                    'title': 'Message Board',
                    'intro': 'Find out what happened while you were away'
                  }, {
                    'element': '#introjs-explore-your-world',
                    'title': 'Explore',
                    'intro': 'Find the latest researches with one click'
                  }, {
                    'element': '#introjs-knowledge-base',
                    'title': 'Find relevant news',
                    'intro': 'Explore hundreds of articles related to your ecosystem'
                  }, {
                    'element': '#introjs-list-view',
                    'title': 'List view',
                    'intro': 'Find all your actors to explore & analyse their data'
                  }, {
                    'element': '#introjs-gallery-view',
                    'title': 'Gallery view',
                    'intro': 'Filter through hundreds of actors and find the ones that fits best your research scope'
                  }, {
                    'element': '#introjs-map-view',
                    'title': 'Map view',
                    'intro': 'Filter through hundreds of actors and find the ones that fits best your research scope'
                  }, {
                    'element': '#introjs-portfolio-view',
                    'title': 'Portfolio',
                    'intro': 'Organize, categorize and benchmark actor profiles'
                  }, {
                    'element': '#introjs-analytics-connectedness',
                    'title': 'Connections',
                    'intro': 'Discover relationships between actors and stakeholders'
                  }, {
                    'element': '#introjs-analytics-funding-list',
                    'title': 'Funding List',
                    'intro': 'Find and analyse latest investments'
                  }
                  // }, {
                  //  'element': '#introjs-analytics-news',
                  //  'title': 'Social Media',
                  //  'intro': 'Click on trending hashtags or discover key influencers'
                  // }
                ]
              }
              break
            case introTypes.default_explore_content_configuration_exploration:
              introToAdd = {
                'page': 'exploreContent',
                'scrollToElement': false,
                'keyboardNavigation': false,
                'roles': ['owner'],
                'steps': [{
                  'element': '#introjs-create-a-new-concept-search',
                  'position': 'left',
                  'title': 'Create a new concept search',
                  'intro': 'Start your first concept search',
                  'disableInteraction': 'true',
                  'clickAfter': 'true'
                }, {
                  'element': '#introjs-create-concept-guide',
                  'title': 'Add relevant keywords',
                  'intro': 'Choose relevant keywords that are as generic as possible',
                  'disableInteraction': 'true',
                  'conceptSearchStep': '1'
                }, {
                  'element': '#introjs-create-concept-guide',
                  'title': 'Research Scope',
                  'intro': 'Describe as much as possible the keywords in order to give a strong definition to your research',
                  'conceptSearchStep': '2',
                  'disableInteraction': 'true',
                }, {
                  'element': '#introjs-create-concept-guide',
                  'title': 'Keyword interpretation',
                  'disableInteraction': 'true',
                  'conceptSearchStep': '3',
                  'intro': 'We will help you in defining your research scope with keyword interpretation, feel free to make some changes on the definitions to fit your need',
                }, {
                  'element': '#introjs-create-concept-guide',
                  'title': 'Report type',
                  'disableInteraction': 'true',
                  'conceptSearchStep': '4',
                  'intro': 'Choose between a report focussed on startup or gather information about your market environment ',
                }, {
                  'element': '#introjs-create-concept-guide',
                  'title': 'Provide market context',
                  'disableInteraction': 'true',
                  'conceptSearchStep': '5',
                  'intro': 'Provide a title to your research and add some filters',
                }, {
                  'element': '#introjs-create-concept-guide',
                  'title': 'Explore the platform',
                  'conceptSearchStep': '6',
                  'intro': 'Explore the results while we are completing the search',
                }]
              }
              break
            case introTypes.default_concept_map_configuration_exploration:
              introToAdd = {
                'page': 'conceptMap',
                'keyboardNavigation': false,
                'scrollToElement': false,
                'roles': ['member', 'actor', 'portfolio_member', 'owner', 'visitor'],
                'steps': [
                  {
                    'element': '.article-item__container .relevant-button',
                    'title': 'Increase relevance',
                    'intro': 'Mark articles as relevant to increase relevance of the results',
                  }, {
                    'element': '#introjs-refine-research-scope',
                    'title': 'Refine your research scope',
                    'intro': 'Add keywords to refine your research scope',
                  },
                  {
                    'element': '#introjs-rank',
                    'title': 'Sort content',
                    'intro': 'Sort your content by date or by relevance',
                  },
                ]
              }
              break
            case introTypes.default_homepage_configuration_monitoring_owner_and_member:
              introToAdd = {
                'page': 'home',
                'keyboardNavigation': false,
                'scrollToElement': false,
                'roles': ['owner'],
                'steps': [
                  {
                    'title': config.introTitleLoggedIn,
                    'intro': config.tagline
                  }, {
                    'element': '#introjs-user-profile',
                    'title': 'Ecosystem configuration',
                    'intro': 'Start configuring your ecosystem for you and your members',
                    'clickAfter': 'true'
                  }, {
                    'element': '#introjs-user-dropdown-configuration',
                    'title': 'Ecosystem configuration',
                    'intro': 'Start configuring your ecosystem for you and your members',
                    'position': 'left',
                    'clickAfter': 'true'
                  }
                ]
              }
              break
            case introTypes.default_settings_configuration_exploration_owner_and_member:
            case introTypes.default_settings_configuration_monitoring_owner_and_member:
              introToAdd = {
                'page': 'settings',
                'keyboardNavigation': false,
                'roles': ['owner'],
                'steps': [
                  {
                    'element': '#introjs-tab-Branding',
                    'title': 'Customize your platform',
                    'intro': 'Customize the look and feel (title, logo) of the platform',
                    'clickBefore': 'true',
                  }, {
                    'element': '#introjs-tab-Views',
                    'title': 'Manage settings of the list view',
                    'intro': 'Choose criteria that match your needs',
                    'clickBefore': 'true',
                  }, {
                    'element': '#introjs-tab-Homepage',
                    'title': 'Set up your homepage',
                    'intro': 'Identify what is the most relevant content to be displayed',
                    'clickBefore': 'true',
                  }, {
                    'element': '#introjs-tab-Filters',
                    'title': 'Identify relevant filters',
                    'intro': 'Set up relevant filters to sort your actors and articles',
                    'clickBefore': 'true',
                  }, {
                    'element': '#introjs-tab-Directory',
                    'title': 'Present results',
                    'intro': 'Show your findings on a certain topic to your colleagues',
                    'clickBefore': 'true',
                  }, {
                    'element': '#introjs-tab-Navigation',
                    'title': 'Add personalized links',
                    'intro': 'Insert a document, a website link, or an email address',
                    'clickBefore': 'true',
                  }, {
                    'element': '#introjs-tab-Custom_areas',
                    'title': 'Sort your results',
                    'intro': 'By adding a geographical context',
                    'clickBefore': 'true',
                  }, {
                    'element': '#introjs-tab-Advanced',
                    'title': 'Personalize your ecosystem',
                    'intro': 'By choosing the best ecosystem color, or adding personized filters',
                    'clickBefore': 'true',
                  }, {
                    'element': '#introjs-config-tab-Settings',
                    'title': 'Enrich your database',
                    'intro': 'Choose the frequency of enrichment and the data type',
                  }, {
                    'element': '#introjs-config-tab-Taxonomies',
                    'title': 'Taxonomies',
                    'intro': 'Set up your personalized classification for the ecosystem',
                  }, {
                    'element': '#introjs-config-tab-Relationships',
                    'title': 'Relationships',
                    'intro': 'Manage graph visualisations',
                  }, {
                    'element': '#introjs-config-tab-Emails',
                    'title': 'Customized emails',
                    'intro': 'Send personnalized email to your members',
                  }, {
                    'element': '#introjs-config-tab-Reporting',
                    'title': 'Interact with your members',
                    'intro': 'Create shared reports and analyze results',
                  }, {
                    'element': '#introjs-config-tab-Ecosystem_Tools',
                    'title': 'Ecosystem tool',
                    'intro': 'Manage your actors, export spreadsheets and add data sources',
                    'clickBeforeChild': 'true',
                  }, {
                    'element': '#introjs-config-tab-Billing_Methods',
                    'title': 'Billing methods',
                    'intro': 'Find the list of relevant data providers',
                    'clickBeforeChild': 'true',
                  }, {
                    'element': '#introjs-config-tab-Members_And_Permissions',
                    'title': 'Members & Permissions',
                    'intro': 'Add / delete users and manage permissions',
                    'clickBeforeChild': 'true',
                  }, {
                    'element': '#introjs-config-tab-Ecosystem_Reporting',
                    'title': 'Analytics',
                    'intro': 'Analyse your ecosystem activities and your data consumption',
                    'clickBeforeChild': 'true',
                  }, {
                    'element': '#introjs-config-tab-Connecte_Services',
                    'title': 'Integrations',
                    'intro': 'Integrate third party applications',
                  },
                ],
              }
              break
            case introTypes.default_homepage_configuration_monitoring_portfolio_member_and_actor:
              introToAdd = {
                'page': 'home',
                'keyboardNavigation': false,
                'roles': ['actor', 'portfolio_member', 'member', 'visitor'],
                'steps': [
                  {
                    'title': config.introTitleLoggedIn,
                    'intro': config.tagline
                  }, {
                    'element': '#introjs-latest-updated-actors',
                    'title': 'Homepage',
                    'intro': 'Find the news of your ecosystem'
                  }, {
                    'element': '#introjs-message-board',
                    'title': 'Message Board',
                    'intro': 'Find out what happened while you were away'
                  }, {
                    'element': '#introjs-explore-your-world',
                    'title': 'Explore',
                    'intro': 'Find the latest researches with one click'
                  }, {
                    'element': '#introjs-knowledge-base',
                    'title': 'Find relevant news',
                    'intro': 'Explore hundreds of articles related to your ecosystem'
                  }, {
                    'element': '#introjs-list-view',
                    'title': 'List view',
                    'intro': 'Find all your actors to explore & analyse their data'
                  }, {
                    'element': '#introjs-gallery-view',
                    'title': 'Gallery view',
                    'intro': 'Filter through hundreds of actors and find the ones that fits best your research scope'
                  }, {
                    'element': '#introjs-map-view',
                    'title': 'Map view',
                    'intro': 'Filter through hundreds of actors and find the ones that fits best your research scope'
                  }, {
                    'element': '#introjs-portfolio-view',
                    'title': 'Portfolio',
                    'intro': 'Organize, categorize and benchmark actor profiles'
                  }, {
                    'element': '#introjs-analytics-connectedness',
                    'title': 'Connections',
                    'intro': 'Discover relationships between actors and stakeholders'
                  }, {
                    'element': '#introjs-analytics-funding-list',
                    'title': 'Funding List',
                    'intro': 'Find and analyse latest investments'
                  }
                  // }, {
                  //  'element': '#introjs-analytics-news',
                  //  'title': 'Social Media',
                  //  'intro': 'Click on trending hashtags or discover key influencers'
                  // }
                ],
              }
              break
            case introTypes.default_personal_home_configuration_monitoring_all_users:
              introToAdd = {
                'page': 'personalHome',
                'keyboardNavigation': false,
                'roles': ['actor'],
                'disableInteraction': 'true',
                'steps': [
                  {
                    'title': `Welcome to ${this.ecosystemDisplayName}!`,
                    'intro': `Would you like to learn how to get the most out of ${this.ecosystemDisplayName}? Then follow these steps`
                  },
                  {
                    'title': 'Manage your account',
                    'intro': 'You are now on your personal home page. Here, you can customise your personal and actor (organisation) profile. You can also change your notification and security settings and add your interests.'
                  },
                  {
                    'element': '#introjs-after-onboarding-faq',
                    'title': 'How can we help you?',
                    'intro': `Find the FAQ and a feedback function underneath the question mark. Cannot find what you are looking for? Contact us: ${this.ecosystemEmail}`
                  },
                  {
                    'element': '#introjs-after-onboarding-secondtopbar',
                    'title': 'Here you can find the navigation bar',
                    'intro': 'The leftmost button brings you to the platform homepage, the first page you will see when you log in next time.  '
                  },
                  {
                    'element': '#introjs-after-onboarding-secondtopbar-spotting-areas',
                    'title': this.spottingAreasLabel,
                    'intro': `Here you can find an overview of market intelligence, associated actors (organisations), new developments and insights. The ${this.spottingAreasLabel} are a collection of data centred on topics mostly related to the communities. `,
                  },
                  {
                    'element': '#introjs-after-onboarding-secondtopbar-next-to-products',
                    'title': this.productsLabel,
                    'intro': `Here you can find and share research equipment, facilities and technologies to speed up innovation and maximise productivity. ${this.productsLabel} are fast, sustainable and cost-effective and is an initiative of Foodvalley NL and Wageningen University and Research.`
                  },
                  { 'element': '#introjs-after-onboarding-message-board',
                    'title': 'where does most interaction take place?',
                    'intro': `Here most of the interaction on ${this.ecosystemDisplayName} takes place to learn and connect and inspire others. Use the wisdom of the crowd: share your challenges, queries, news and events and respond to inspire other users.`
                  },
                  {
                    'element': '#introjs-after-onboarding-intelligence',
                    'title': 'Intelligence',
                    'intro': `Here you can find market trends preselected from over 70,000 sources globally. Create your market forecast monitor by selecting your ${this.spottingAreasLabel}.`
                  },
                  {
                    'element': '#introjs-after-onboarding-knowledge-base',
                    'title': 'Knowledge base',
                    'intro': 'Here you can find relevant resources, like reports, slides, and recordings.'
                  },
                  {
                    'element': '#introjs-after-onboarding-icon-user-profile',
                    'title': 'Return to your account?',
                    'intro': 'Please click the arrow down'
                  },
                  {
                    'element': '#introjs-after-onboarding-personal-home-interact',
                    'title': 'Inspire, Interact, Inform',
                    'intro': 'Here you can find relevant portfolios of actors, the latest announcements, interact with other users and customise your keywords.'
                  },
                  {
                    'element': '#introjs-after-onboarding-actors',
                    'title': 'Actor Profile organization',
                    'intro': `Here you can find your actor (organisation) profile, which is linked to your account. Please click on your actor profile to view and edit it. If your organisation shares ${this.productLabel} via ${this.ecosystemDisplayName} you can find this below.`,
                    'clickAfter': 'true'
                  },
                ]
              }
              break
            case introTypes.default_actor_profile_configuration_monitoring_all_users:
              introToAdd = {
                'page': 'simplifiedActorDetail',
                'keyboardNavigation': false,
                'disableInteraction': 'true',
                'roles': ['actor'],
                'steps': [
                  {
                    'title': 'Welcome to your claimed actor page',
                    'intro': `Please update this page to make sure your organisation is well represented on ${this.ecosystemDisplayName}.`
                  },
                  {
                    'element': '#introjs-claimed-actor-profile-manage-contributors',
                    'title': 'Actor Profile (organization)',
                    'intro': 'Here you can find users connected to the same actor(organisation) profile, such as profiles of colleagues and/or organisation members or just your profile.'
                  },
                  {
                    'element': '#introjs-claimed-actor-profile-edit_button',
                    'title': 'Actor Profile (organization)',
                    'intro': 'Press this button to edit and complete you actor (organisation) profile.'
                  },
                  {
                    'element': '#introjs-claimed-actor-profile-connections',
                    'title': 'Shared facility',
                    'intro': `If you share a facility via ${this.ecosystemDisplayName}, it is shown here. Press this button to edit and complete you shared facility profile.`
                  },
                  {
                    'element': '#introjs-after-onboarding-secondtopbar-spotting-areas',
                    'title': this.spottingAreasLabel,
                    'intro': `Here you can find an overview of market intelligence, associated actors (organisations), new developments and insights. The ${this.spottingAreasLabel} are a collection of data centred on topics mostly related to the communities. `,
                    'clickAfter': 'true'
                  },
                ]
              }
              break
            case introTypes.default_spotting_areas_configuration_monitoring_all_users:
              introToAdd = {
                'page': 'spottingAreasHome',
                'keyboardNavigation': false,
                'disableInteraction': 'true',
                'roles': ['actor', 'visitor'],
                'steps': [
                  {
                    'element': '#introjs-all-spotting-areas',
                    'title': this.spottingAreaLabel + ' example',
                    'intro': 'Here you see the focus areas, choose a ' + this.spottingAreaLabel + ' to see more info.',
                  },
                ]
              }
              break
            case introTypes.default_spotting_areas_detailpage_configuration_monitoring_all_users:
              introToAdd = {
                'page': 'spottingAreasDetailPage',
                'keyboardNavigation': false,
                'roles': ['actor', 'visitor'],
                'steps': [
                  {
                    'title': this.spottingAreasLabel,
                    'intro': `${this.spottingAreasLabel} give an overview of all data on the platform that is relevant for specific topics. The data contains market intelligence, associated actors (organisations) and insights.`,
                  },
                  {
                    'element': '#introjs-after-onboarding-message-board',
                    'title': this.announcementsLabel,
                    'intro': `To continue your tutorial, please click on ${this.announcementsLabel}`,
                    'clickAfter': 'true',
                  }
                ]
              }
              break
            case introTypes.default_message_board_configuration_monitoring_all_users:
              introToAdd = {
                'page': 'messageBoard',
                'keyboardNavigation': false,
                'disableInteraction': 'true',
                'roles': ['actor', 'visitor'],
                'steps': [
                  {
                    'title': this.announcementsLabel,
                    'intro': `Here most of the interaction on ${this.ecosystemDisplayName} takes place. Use the wisdom of the crowd: share your challenges, queries, news and events and respond to inspire other users. To post a message, press the megaphone (create announcement) or the calendar symbol (create event).. If you are part of a community, the community forum is located on the right-hand side of your screen.`,
                  },
                  {
                    'element': '#introjs-message-board-post',
                    'title': this.announcementsLabel,
                    'intro': `Jump start your ${this.ecosystemDisplayName}! Post a message to introduce your organisation and share a project, share an event, or ask a question.`
                  },
                  {
                    'element': '#introjs-message-board-home-secondtopbar',
                    'title': 'Homepage',
                    'intro': `Once you are done, please head over to the homepage, and then select one of the communities to learn more about this vital part of ${this.ecosystemDisplayName}.`,
                    'clickAfter': 'true',
                  },
                ]
              }
              break
            case introTypes.default_communities_configuration_monitoring_all_users:
              introToAdd = {
                'page': 'homeCommunities',
                'keyboardNavigation': false,
                'disableInteraction': 'true',
                'roles': ['actor', 'visitor'],
                'steps': [
                  {
                    'element': '#introjs-communities',
                    'title': 'Home Communities',
                    'intro': 'Continue to the communities homepage',
                    'clickAfter': 'true'
                  },
                ]
              }
              break
            case introTypes.default_communities_home_configuration_monitoring_all_users:
              introToAdd = {
                'page': 'communitiesOverviewPage',
                'keyboardNavigation': false,
                'disableInteraction': 'true',
                'roles': ['actor', 'visitor'],
                'steps': [
                  {
                    'title': 'Home Communities',
                    'intro': 'Please select a community below',
                  },
                  {
                    'element': '#introjs-all-communities',
                    'title': 'Home Communities',
                    'intro': 'Here you see your communities, choose one to see more info',
                  },
                ]
              }
              break
            case introTypes.default_communities_detailpage_configuration_monitoring_all_users:
              introToAdd = {
                'page': 'communitiesDetailPage',
                'keyboardNavigation': false,
                'roles': ['actor', 'visitor'],
                'steps': [
                  {
                    'title': 'Welcome to your community',
                    'intro': 'You are on the homepage of one of our communities, a safe environment where you can partner up on your ideas and find inspiration from other community partners. A community is mix of cross-sectoral organisations working on the same topic.',
                  },
                  {
                    'element': '#introjs-communities-detailpage',
                    'title': 'Community members',
                    'intro': 'Here you can find all actors that are part of this community.'
                  },
                  { 'element': '#introjs-communities-detailpage-message-board',
                    'title': 'Message board',
                    'intro': 'Here you can interact with other members of the community. Use the wisdom of the crowd: share your challenges, queries, news and events and respond to inspire other users. Posts within a community are only visible for other community members.'
                  },
                  {
                    'element': '#introjs-communities-detailpage-knowledge-base',
                    'title': 'Knowledge base',
                    'intro': 'Here you can find resources, like reports, slides, and recordings relevant for your community.'
                  },
                ]
              }
              break
            case introTypes.default_monitoring_configuration_exploration_owner:
              introToAdd = {
                'page': 'conceptMapMonitoring',
                'keyboardNavigation': false,
                'roles': ['owner'],
                'steps': [
                  {
                    'element': '#introjs-share-button',
                    'title': 'Share',
                    'intro': 'Share the most relevant articles with your colleagues'
                  },
                  {
                    'element': '#introjs-trash-button',
                    'title': 'Remove Article',
                    'intro': 'Remove article from the top 100 results'
                  },
                  {
                    'element': '#introjs-star-button',
                    'title': 'Increase relevancy',
                    'intro': 'Increase result relevancy by indicating most relevant articles'
                  }
                ]
              }
              break
            default:
              break
            // @formatter:on
          }

          if (introToAdd && introToAdd.roles.includes(userRole)) {
            acc[introToAdd.page] = introToAdd
          }
          return acc
        }, {})
      }
      for (const page in result) {
        if (result.hasOwnProperty(page)) {
          result[page].prevLabel = '<div class="svg-icon svg-icon--regular svg-icon--chevron-left"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path fill="#C4C4C4" fill-rule="evenodd" d="M7 10.455c0 .078.03.146.09.206l4.16 4.16a.282.282 0 00.41 0l.447-.446a.282.282 0 000-.41l-3.509-3.51 3.51-3.509a.282.282 0 000-.41l-.447-.447a.282.282 0 00-.411 0L7.09 10.25a.282.282 0 00-.09.205z" clip-rule="evenodd"></path></svg></div>'
          result[page].nextLabel = '<div class="svg-icon svg-icon--regular svg-icon--chevron-left"><svg style="transform: rotate(180deg); margin-top: 3px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path fill="#C4C4C4" fill-rule="evenodd" d="M7 10.455c0 .078.03.146.09.206l4.16 4.16a.282.282 0 00.41 0l.447-.446a.282.282 0 000-.41l-3.509-3.51 3.51-3.509a.282.282 0 000-.41l-.447-.447a.282.282 0 00-.411 0L7.09 10.25a.282.282 0 00-.09.205z" clip-rule="evenodd"></path></svg></div>'
        }
      }
      return result
    },
    getIntroJsStepsConfig (steps) {
      return steps.reduce((acc, step) => {
        const element = document.querySelector(step.element)

        if ((element || !step.element) && step.intro) {
          acc.push({
            title: step.title,
            clickBefore: step.clickBefore,
            clickBeforeChild: step.clickBeforeChild,
            clickAfter: step.clickAfter,
            elementSelector: step.element,
            conceptSearchStep: step.conceptSearchStep,
            disableInteraction: step.disableInteraction,
            element,
            position: step.position,
            tooltipClass: step.intro.length > 500 ? 'introjs-tooltip-large' : '',
            intro: step.intro,
          })
        }

        return acc
      }, [])
    },
    introJsBeforeStepCallback () {
      if (!this._currentStep || !this._introItems || !this._introItems[this._currentStep]) {
        return
      }
      if (this._introItems[this._currentStep].conceptSearchStep) {
        const conceptSearchStep = Number(this._introItems[this._currentStep].conceptSearchStep)
        this.$bus.emit('set-introjs-concept-step', conceptSearchStep)
      }

      const currentItem = this._introItems[this._currentStep]

      if (currentItem.clickBefore && currentItem.element) {
        currentItem.element.focus()
        currentItem.element.click()
      }

      if (currentItem.clickBeforeChild && currentItem.element && currentItem.element.firstElementChild) {
        currentItem.element.firstElementChild.focus()
        currentItem.element.firstElementChild.click()
      }
    },
    introJsAfterStepCallback () {
      if (!this._currentStep || !this._introItems || !this._introItems[this._currentStep]) {
        return
      }

      const previousItem = this._introItems[this._currentStep - 1]

      if (previousItem && previousItem.clickAfter && previousItem.element) {
        previousItem.element.focus()
        previousItem.element.click()
      }
    },
    introJsOnCompleteCallback (options, currentStep, componentScope) {
      if (options.steps.length === currentStep + 1 && options.steps[currentStep] && options.steps[currentStep].clickAfter && options.steps[currentStep].element) {
        // trigger 'clickAfter' of last step
        // timeout to make sure the intro is stopped before clicking (avoid 2 instances of intro)
        options.steps[currentStep].element.click()
      }
      componentScope.$bus.emit('set-introjs-concept-step', null)
      componentScope.$bus.emit('stop-intro', options.page)
    },
  },
  created () {
    this.$bus.on('stop-intro', (type) => {
      this.currentIntro = false
      if (!this.seenIntros.includes(type)) {
        this.$store.commit(USER_MUTATION_TYPES.STORE_SETTINGS, { seenIntros: [...this.seenIntros, type].join(',') })
      }
    })
  },
  beforeUnmount () {
    this.$bus.off('stop-intro')
  },
  mixins: [TranslationsMixin, OnboardingMixin],
}
