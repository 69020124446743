<template>
  <div class="ecosystem-member-communities has-action-bar">
    <div v-if="isOwner" class="communities-announcements-permission">
      <checkbox v-model="toggleAnnouncementsPermissionForCommunities" :label="`Enable ${announcementsAndEventsLabel}`"
                :disabled="!areMessageBoardsEnabled"/>
      <button @click="updateAnnouncementsPermissionForCommunities" class="button button--secondary">
        {{ labelSaveCommunitiesAnnouncementsAddOn }}
      </button>
    </div>
    <div v-if="fetchingContent" style="display: flex; justify-content: center;">
      <icon name="spinner"/>
    </div>
    <div v-else>
      <div class="pull-right side-panel__communities-header-button-container" style="text-align: right;">
        <div class="communities-list-container">
          <dropdown
              new-prepend-icon="fort-awesome"
              new-append-icon="chevron-down"
              icon-fill="white"
              v-model="selectedDropdownOption"
              :options="availableCommunities"
              style="width: 100%"
              v-if="availableCommunities.length > 0"
          />
        </div>
        <ds-button label="Create New Community" icon="plus" @click="displayCreateCommunityModal()" v-if="isOwner"/>
        <ds-button icon="trash" @click="destroy(selectedCommunity)"
                   v-if="isOwner && selectedCommunity && selectedCommunity.id > 0"/>
      </div>

      <div v-if="communities.length < 1">
        <span v-if="isOwner">Before you can start managing communities, please create one.</span>
        <span v-else>You don't yet have any communities to manage.</span>
      </div>

      <div v-else>
        <div v-if="selectedCommunity.id !== -1">
          <h3 class="h3">Community Information</h3>
          <div class="ecosystem-member-communities__general-container" style="width: 40%">
            <div class="ecosystem-member-communities__general-info">
              <div>
                <form-group label="Community Name *" class="ecosystem-member-communities__general-input">
                  <ds-input required v-model="selectedCommunity.name"/>
                  <div v-if="errors" class="form-group__error">
                    <div v-if="errors.name">
                      <span>{{ errors.name[0] }}</span>
                    </div>
                  </div>
                </form-group>

                <form-group label="Community Description *" class="ecosystem-member-communities__general-input">
                  <ds-textarea placeholder="Provide a description of your community" required
                               v-model="selectedCommunity.description" class="communities__description"/>
                  <div v-if="errors" class="form-group__error">
                    <div v-if="errors.description">
                      <span>{{ errors.description[0] }}</span>
                    </div>
                  </div>
                </form-group>
              </div>
              <form-group v-if="isOwner" class="ecosystem-member-communities__general-input">
                <label style=" color: #555; font-size: 12px;">Community Manager * <span
                    style="color: grey; font-size: 14px;">(Only team members can be assigned as a community manager)</span></label>
                <autocomplete-dropdown
                    is-simplified
                    :placeholder="$t('communities_search_for_users')"
                    :suggestions="displayedUserSuggestions"
                    :allow-clear="true"
                    :do-search="autocompleteSearchTerm"
                    v-model="selectedCommunity.managers"
                    :multiple="false"
                    marginTop="10px"
                />
              </form-group>

              <form-group label="Community Picture" class="ecosystem-member-communities__general-input">
                <image-input
                    :allow-remove="true"
                    @remove="selectedCommunity.image = ''"
                    v-model="selectedCommunity.image"
                    @input="selectedCommunity.image_name = $event"
                    width="512"
                    height="288"
                    :placeholder="$t('community_image_placeholder')"
                    :is-simplified="true"
                    secondLabel="test"
                />
              </form-group>

              <form-group label="Choose a primary colour for your community" style="width:650px;">
                <color-picker :colors="{}" @updatePrimary="updatePrimaryColor" title="Community Colour"
                              :initial-value="selectedCommunity.color"/>
              </form-group>
            </div>
          </div>

          <action-bar :editing="isEditingCommunities">
            <ds-button variant="primary" label="Save Community" @click="updateCommunity()" :disabled="saving"/>
            <span class="action-bar__message" v-if="errors && errors.message">{{ errors.message }}</span>
            <span class="action-bar__message" v-else-if="saved && !saving">Community has been updated</span>
          </action-bar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deleteCommunity, ManageCommunities, updateCommunitiesAnnouncementsAddOn } from '../../api/communities'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
import { searchUser } from '../../api/config'
import MODAL_IDS from '../../constants/modal-ids'
import Dropdown from '../Dropdown/Dropdown.vue'
import ActionBar from '../Form/ActionBar.vue'
import ImageInput from '../Form/ImageInput.vue'
import Checkbox from '../Form/Checkbox.vue'
import AutocompleteDropdown from '../Dropdown/AutocompleteDropdown.vue'
import DsTextarea from '../../components/Form/DsTextarea.vue'

import isEqual from 'lodash/isEqual'
import LabelFilter from '../Filters/LabelFilter.vue'
import ColorPicker from '../Form/ColorPicker.vue'
import TranslationsMixin from '../../util/TranslationsMixin'
import { ACTION_TYPES as CONFIG_ACTION_TYPES } from '../../store/modules/config'
import {
  ACTION_TYPES as COMMUNITY_ACTION_TYPES,
  MUTATION_TYPES as COMMUNITY_MUTATION_TYPES
} from '../../store/modules/communities'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ManageCommunities',
  data() {
    return {
      fetchingContent: false,
      savingCommunitiesAnnouncementsAddOn: false,
      savedCommunitiesAnnouncementsAddOn: false,
      communitiesAnnouncementsAddOnEnabled: null,
      communities: [],
      selectedCommunity: {
        id: -1,
        name: null,
        description: '',
        managers: [],
        members: [],
        permissions: {},
        originalImage: null,
        image: null,
        image_path: null,
        ecosystem_id: 0,
        color: '',
      },
      updatedCommunityObject: this.selectedCommunity,
      saving: false,
      errors: {},
      saved: false,
      userSuggestions: [],
      minTitleLength: 2,
      minBodyLength: 10,
    }
  },
  computed: {
    areMessageBoardsEnabled() {
      return this.$store.getters.areMessageBoardsEnabled
    },
    labelSaveCommunitiesAnnouncementsAddOn() {
      if (this.savingCommunitiesAnnouncementsAddOn) {
        return 'Saving...'
      }

      if (this.savedCommunitiesAnnouncementsAddOn) {
        return 'Saved!'
      }

      return 'Save'
    },
    toggleAnnouncementsPermissionForCommunities: {
      get() {
        return this.communitiesAnnouncementsAddOnEnabled || this.$store.getters.hasAccessToAnnouncementsAndEventsForCommunities
      },
      set(value) {
        this.communitiesAnnouncementsAddOnEnabled = value
      }
    },
    isOwner() {
      return this.$store.getters.isOwner
    },
    isMember() {
      return this.$store.getters.isMember
    },
    isEditingCommunities() {
      return !isEqual(this.updatedCommunityObject, this.selectedCommunity)
    },
    displayedUserSuggestions() {
      return this.userSuggestions
    },
    isSelectedCommunityNew() {
      return this.selectedCommunity && !this.selectedCommunity.id
    },
    availableCommunities() {
      if (!this.communities || this.communities.length === 0) {
        return []
      }

      return this.communities.map(Community => { // package is a reserved JS word
        return {
          value: Community.id,
          label: Community.name,
        }
      })
    },
    selectedDropdownOption: {
      get() {
        if (!this.selectedCommunity || !this.selectedCommunity.id) {
          return null
        }

        return {
          value: this.selectedCommunity.id,
          label: this.selectedCommunity.name,
        }
      },
      set(communityId) {
        const community = this.communities.find(p => p.id == communityId)
        this.selectedCommunity = community

        if (this.selectedCommunity.image) {
          this.selectedCommunity.image = window.location.protocol + '//' + window.location.hostname + '/api/communities/' + this.selectedCommunity.id + '/image'
        }
        this.selectedCommunity.description = this.selectedCommunity.description.replace('<p>', '').replace('</p>', '')
        if (this.selectedCommunity.manager_id != null) {
          this.selectedCommunity.manager = community.manager_data.name + ' (' + community.manager_data.email + ')'
        }
        this.errors = {}
      },
    },
  },
  methods: {
    updatePrimaryColor(newValue) {
      this.selectedCommunity.color = newValue
    },
    displayCreateCommunityModal() {
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CREATE_COMMUNITY)
    },
    removeDuplicates(suggestions) {
      return suggestions
    },
    confirmDeleteCommunity(communityId) {
      deleteCommunity({ communityId: communityId })
          .then(response => {
            setTimeout(() => {
              this.$bus.emit('communityDeleted')
            }, 1000)
          })
          .catch(error => {
            console.log(error)
          })
    },
    updateCommunity() {
      this.saving = true
      this.saved = false
      this.errors = {}

      var communityId = this.selectedCommunity.id

      ManageCommunities
          .post(this.selectedCommunity)
          .then(response => {
            if (response.success) {
              this.$bus.emit('communityUpdated', { communityId: communityId })

              this.saved = true
              this.saving = false
            }
          })
          .catch(errors => {
            this.errors = errors
            this.saving = false
          })
    },
    destroy(Community) {
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
        name: Community.title,
        resource: Community,
        modalContextType: 'community',
      })

      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
    },
    updateAnnouncementsPermissionForCommunities() {
      this.savingCommunitiesAnnouncementsAddOn = true
      updateCommunitiesAnnouncementsAddOn(this.communitiesAnnouncementsAddOnEnabled)
          .then(response => {
            this.savingCommunitiesAnnouncementsAddOn = false

            if (response.updated) {
              this.savingCommunitiesAnnouncementsAddOn = false
              this.savedCommunitiesAnnouncementsAddOn = true
              this.$store.dispatch(CONFIG_ACTION_TYPES.REFRESH_CONFIG)

              setTimeout(() => {
                this.savedCommunitiesAnnouncementsAddOn = false
              }, 1000)
            }
          })
          .catch(error => {
            this.savingCommunitiesAnnouncementsAddOn = false
            console.log(error)
          })
    },
    fetchCommunities(selectFirst = false, last = false) {
      this.fetchingContent = true

      this.$store.dispatch(COMMUNITY_ACTION_TYPES.FETCH_COMMUNITIES_LIST)

      ManageCommunities
          .get()
          .then(response => {
            this.communities = response.map(c => {
              let community = { ...c }

              if (c.managers) {
                community = {
                  ...community,
                  managers: community.managers.map(m => ({
                    value: m.id,
                    label: m.email,
                  })),
                }
              }

              return community
            })

            if (last && !selectFirst && this.communities.length > 0) {
              this.communities.sort((a, b) => b.id - a.id)

              this.selectedDropdownOption = this.communities[0].id
              this.selectedCommunity = this.communities[0]

              if (this.selectedCommunity.image) {
                this.selectedCommunity.image_name = this.selectedCommunity.image
                this.selectedCommunity.image = window.location.protocol + '//' + window.location.hostname + '/api/communities/' + this.selectedCommunity.id + '/image'
              }
            }

            if (!last && selectFirst && this.communities.length > 0) {
              this.selectedDropdownOption = this.communities[0].id
              this.selectedCommunity = this.communities[0]

              if (this.selectedCommunity.image) {
                this.selectedCommunity.image_name = this.selectedCommunity.image
                this.selectedCommunity.image = window.location.protocol + '//' + window.location.hostname + '/api/communities/' + this.selectedCommunity.id + '/image'
              }
            }

            this.fetchingContent = false
          })
          .catch(errors => {
            console.log(errors)
          })
    },
    autocompleteSearchTerm(query) {
      return searchUser({
        query: query,
        role: 'member',
      })
          .then(suggestions => {
            const options = []
            suggestions = this.removeDuplicates(suggestions)

            suggestions
                .forEach(function (user) {
                  options.push({
                    label: user.name + ' (' + user.email + ')',
                    value: user.id,
                  })
                })

            return options
          })
          .catch(err => {
            console.log(err)
          })
    },
  },
  mixins: [TranslationsMixin],
  components: {
    LabelFilter,
    AutocompleteDropdown,
    Dropdown,
    ActionBar,
    ImageInput,
    Checkbox,
    DsTextarea,
    ColorPicker,
  },
  mounted() {
    if (!this.$store.getters.hasAccessToNewCommunities) {
      this.$router.push('/')
    }

    this.fetchCommunities(true)

    this.$bus.on('communityCreated', () => {
      this.$store.commit(COMMUNITY_MUTATION_TYPES.FLUSH_CACHE)

      this.fetchCommunities(false, true)
    })

    this.$bus.on('communityUpdated', (context) => {
      this.$store.commit(COMMUNITY_MUTATION_TYPES.FLUSH_CACHE)

      this.fetchCommunities(true)
    })

    this.$bus.on('communityDeleted', (context) => {
      this.$store.commit(COMMUNITY_MUTATION_TYPES.FLUSH_CACHE)

      this.fetchCommunities(true)
    })

    this.$bus.on('communityDeleteConfirmation', (context) => {
      this.confirmDeleteCommunity(context.resource.id)
    })
  },
  beforeUnmount() {
    this.$bus.off('communityCreated')
    this.$bus.off('communityUpdated')
    this.$bus.off('communityDeleted')
  },
})
</script>

<style lang="scss">
@import "../../../scss/variables";

.communities-announcements-permission {
  position: absolute;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  right: 10px;
  top: 20px;
  width: 350px;
  display: flex;

  label {
    margin-right: 10px;
  }
}

.ecosystem-member-communities {
  .side-panel__communities-header-button-container {
    display: flex;
  }

  .communities__description {
    min-height: 75px;
    margin-bottom: 20px;
  }

  .communities-list-container {
    display: flex;
    align-items: center;
    color: white;
    background-color: var(--primary);
    margin-right: 10px;

    .icon-button {
      margin: 0px 10px;
      color: white;
    }

    .multiselect.multiselect--datascouts .multiselect__tags {
      border: 0;
      color: white;
      background-color: var(--primary);

      span {
        color: white;
        background-color: var(--primary);
      }
    }
  }
}
</style>
