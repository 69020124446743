<template>
  <div class="community-detail-members__container">
    <table class="table community-detail-member__table">
      <tr style="border: none">
        <td style="border: 0">
          {{ $t('community_member') }}
        </td>
      </tr>
      <tr
        v-for="(actor, index) in actors" class="community-detail-member__member-row" :key="'community-detail-member__' + index" @click="goToActorDetailPage(actor.elastic_id)">
        <td style="border: 0;">
          <div>
            <avatar :src="actor.logo" :alt="actor.name" variant="border" style="margin-right: 0.5rem;"/>
            {{ actor.name }}
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
  import Avatar from '../Avatar/Avatar.vue'

  import { actorLogo } from '../../util/actor'
  import { capitalize } from '../../util/string.ts'
  import { Conversations } from '../../api/conversations'
  import { ACTION_TYPES as CONVERSATION_ACTION_TYPES } from '../../store/modules/conversations'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'CommunityDetailMembersCard',
    props: ['actors', 'users'],
    methods: {
      capitalize,
      actorLogo,
      goToActorDetailPage (actorId) {
        this.$router.push({
          params: { section: 'members', id: actorId },
        })
      },
      startConversationWithCommunityMember (memberId) {
        if (memberId === this.loggedInUserId) {
          return
        }

        this.$emit('click-contact-button')
        Conversations.post({ recipient_id: memberId })
          .then((conversation) => {
            this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
            this.$router.push('/profile/conversations/' + conversation.id)
          })
      },
    },
    computed: {
      members () {
        const members = []

        this.actors.map(actor => {
          if (actor.contributors.length > 0) {
            actor.contributors.map(contributor => {
              members.push({ ...contributor, actor })
            })
          } else {
            const contributor = {
              'user_name': 'No contributors',
              'photo_url': 'http://www.gravatar.com/avatar/?d=mp',
            }
            members.push({ ...contributor, actor })
          }
        })

        return members.slice(0, 5)
      },
      formattedUsers () {
        const users = []
        this.users.map(user => {
          if (user.claimed_community_actors !== '') {
            users.push(user)
          }
        })
        return users.slice(0, 5)
      },
    },
    components: {
      Avatar,
    },
  })
</script>

<style lang="scss" scoped>
  .community-detail-member__table {
    border: 0;
  }

  .community-detail-member__member-row {
    height: 36px;
    border: 0;
    cursor: pointer;

    &:nth-child(even) {
      background-color: #F2F2F2;
    }
  }

  // for long list claimed community actors
  .community-detail-member__table_row {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 350px;
  }
</style>
