<template>
  <div class="scrollable simplified-actor-detail" :style="textStyle" ref="scrollable">
    <template v-if="company && company.id">
      <SimplifiedHero
        @next="goToNext"
        class="container"
        :banner-style="bannerStyle"
        :sub-banner-style="subBannerStyle"
        :has-subtitle="!!localizedActivitiesDescription"
        :keep-aspect-ratio="true"
        style="padding: 0; margin: 0 auto;"
        :imgUrl="bannerImage"
      >

        <template v-slot:actions>
          <div class="sdg-actions-container">
            <actor-detail-SDGs
              target="simplified-actor"
              :selected-goals="company.sustainability_goal"
            ></actor-detail-SDGs>
          </div>
        </template>
        <template v-slot:title>
          <span
            style="margin-right: 10px;cursor: pointer; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            @click="showNotes = false; hideReport()"
          >{{ actor.name }}
          </span>
          <div
            style="display: flex; justify-content: left; align-items: center; margin-right: 10px; flex-grow: 1"
          >
            <DsButton
              v-if="userCanEdit"
              :label="$t('actor_edit')"
              variant="primary"
              icon="edit"
              @click="openEditSidePanel(actor.id)"
              size="extra-small"
              id="intros-claimed-actor-profile-edit_button"
            />
            <DsButton
              v-if="userCanClaim"
              :label="$t('actor_claim')"
              variant="primary"
              icon="email-outline"
              @click="onClickClaimActor"
              size="extra-small"
            />
            <ds-button
              label="Notes"
              @click="toggleNotesVisibility()"
              class="tabs__navigation__item--link-report"
              type="button"
              icon="reporting"
              size="extra-small"
              variant="primary"
              v-if="userCanAccessNotes"
            />
            <DsButton
              v-if="displayManageContributors"
              @click="showContributorModal"
              variant="primary"
              style="line-height: 21px"
              size="extra-small"
              :label="'+ ' + $t('actor_view_contributors')"
              id="introjs-claimed-actor-profile-manage-contributors"
            />
            <DsButton
              v-if="userCanEdit && spottingAreaId && (spottingAreaRole === 'contributor' || (isOwner || isMember))"
              label="Remove from spotting area"
              variant="primary"
              icon="trash"
              @click="showConfirmRemoveModal(actor.id)"
              size="extra-small"
              id="intros-claimed-actor-profile-edit_button"
            />
          </div>
          <CardInfoSocial :company="actor" iconSize="14" showWebsiteUrl/>
        </template>
        <template v-slot:subtitle v-if="localizedActivitiesDescription">
          {{ localizedActivitiesDescription }}
        </template>
        <template v-slot:map>
          <simple-map
            :location="company.location" :legend="legendValue"
            :offices="company.subsidiaries"
            :use-pin-point-icon="company.is_inside_highlighted_spotting_area"
          />
        </template>
      </SimplifiedHero>
      <div
        class="actor__report_call_countdown_container container"
        v-if="showOpenCallBannerOnCurrentActorProfile && !hasSelectedReport && selectedReportTemplateForHeadline"
      >
        <span class="home__report_call_countdown" v-html="callTimeDifference"></span><span
        class="home__report_call_text"
      >{{ headlineText }}</span>
        <ds-button
          :icon="applyButtonIcon" :label="applyButtonLabel"
          @click="selectReportTemplate(selectedReportTemplateForHeadline)"
          :disabled="isOpenCallEmptyAndExpired(actor.current_report, selectedReportTemplateForHeadline)"
          variant="primary"
          class="home__report_call_button" size="small"
        />
      </div>

      <div
        class="container"
        v-if="!hasSelectedReport && !showNotes && !loading"
        style="padding: 0"
      >
        <!-- row container: contact details (isActor) -->
        <div class="colored-container" v-if="!isProduct">
          <div
            class="row"
            style="display: flex; flex-direction: row; justify-content: center;"
          >
            <div class="col-xs-12 col-sm-5 simplified-hero-logo__container">
              <div
                :style="{backgroundImage: `url(${actor.logo})`}" :src="actor.logo"
                class="simplified-hero-logo" alt="" v-if="!isProduct && actor.logo"
              />
              <div class="simplified-hero-logo" v-else-if="!isProduct">
                <span v-if="actor.name">{{ actor.name }}</span>
              </div>
            </div>
            <div class="col-xs-12 col-sm-7" style="display: flex; flex-direction: column">
                <span
                  v-if="!isProduct" class="simplified-contact-details__title"
                >
                        {{ $t('actor_detail_contact_details').toUpperCase() }}
                </span>
              <div class="simplified-contact-details__container">
                <div
                  class="simplified-actor-detail__taxonomies"
                  v-if="showAddress || showPhoneNumber || showContactContributors"
                >
                  <ActorContactDetails
                    v-if="show.avatars" :avatars="show.avatars"
                    :show-address="showAddress" :show-phone-number="showPhoneNumber"
                    :show-contact-contributors="showContactContributors"
                  />
                </div>
                <div v-else style="margin-top: 10px;">
                  {{ $t('actor_detail_not_defined') }}
                </div>
              </div>
            </div>
          </div>

          <div class="cta-buttons">
            <ds-button
              v-if="highlightedSpottingArea.id && company.is_inside_highlighted_spotting_area"
              :style="{backgroundColor: highlightedSpottingArea.highlight_color}"
              class="highlight-spotting-area-button highlight-button"
              icon="location-marker"
              icon-fill-color="#ffffff"
              @click="goToHighlightedSpottingAreaDetail"
              :label="highlightedSpottingArea.name"
            ></ds-button>
            <div
              v-tooltip.top="getLinkHelperTextContactActor"
              v-if="show.avatars.list && show.avatars.list.length > 0"
              class="cta-button"
            >
              <ds-button
                :label="$t('contact_details_chat_button')" class="contact-detail-button"
                variant="primary"
                @click="handleClickContactActor(eventCategories.ACTOR_LETS_CHAT)"
                :disabled="userId === show.avatars.list[0].id"
                v-if="$store.getters.canActorsBeContacted && selectedActorCanBeContacted"
                icon="chat"
              />
            </div>
            <div
              class="cta-button"
              v-if="actor.is_email_published"
            >
              <ds-button
                class="contact-email-button" :label="$t('contact_details_email_button')"
                @click="handleClickSendEmail(sendEmailToActor)"
                icon="email"
              />
            </div>
            <div
              v-if="actor.show_contact_cta_url && actor.contact_cta_url"
              class="cta-button"
            >
              <ds-button
                :label="contactLabel"
                class="custom-cta-button"
                style="width: 100%; max-width: 300px; margin: 0 auto; text-align: center;"
                v-if="actor.show_contact_cta_url && actor.contact_cta_url"
                @click="handleClickContactActorUrl"
                icon="hand-pointer"
              />
              <!--              <p v-if="isOwner">This button has been clicked {{ getCTAButtonClicks }} time(s).</p>-->
            </div>
          </div>
        </div>

        <!-- row container: contact details (isProduct) -->
        <div class="colored-container" v-if="isProduct">
          <div
            class="row"
            style="display: flex; flex-direction: row; justify-content: center;"
          >
            <div
              class="col-xs-12 col-sm-6 simplified-hero-logo__container"
              v-if="hasOfferingActor" @click="goToOfferByActor"
            >
              <div
                :style="{backgroundImage: `url(${offeredByActor.logo})`}"
                :src="offeredByActor.logo"
                style="cursor: pointer;"
                class="simplified-hero-logo" alt="" v-if="offeredByActor.logo"
              >
                <div class="hover-text">
                  <span class="hover-text__title">View Actor Profile</span>
                </div>
              </div>
              <div
                class="simplified-hero-logo"
                style="cursor: pointer;"
                v-else
              >
                <span v-if="offeredByActor.name">{{ offeredByActor.name }}</span>
                <div class="hover-text">
                  <span class="hover-text__title">View Actor Profile</span>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6" style="display: flex; flex-direction: column">
              <div
                class="simplified-actor-detail__taxonomies"
                :style="hasOfferingActor ? 'text-align: left;' : 'text-align: center;'"
                v-if="hasOfferingActor || showAddress || showPhoneNumber || showContactContributors"
              >
                <ActorContactDetails
                  v-if="show.avatars" :avatars="show.avatars" :show-address="showAddress"
                  :show-phone-number="showPhoneNumber"
                  :show-contact-contributors="showContactContributors"
                />
              </div>
              <div v-else>
                {{ $t('actor_detail_not_defined') }}
              </div>
            </div>
          </div>
          <div class="cta-buttons">
            <div
              v-tooltip.top="getLinkHelperTextContactActor"
              v-if="show.avatars.list && show.avatars.list.length > 0"
              class="cta-button"
            >
              <ds-button
                :label="$t('contact_details_chat_button')" class="contact-detail-button"
                variant="primary"
                @click="handleClickContactActor(eventCategories.ACTOR_LETS_CHAT)"
                :disabled="userId === show.avatars.list[0].id"
                v-if="selectedActorCanBeContacted"
                icon="chat"
              />
            </div>
            <div
              class="cta-button"
              v-if="actor.is_email_published && actor.email"
            >
              <ds-button
                class="contact-email-button" :label="$t('contact_details_email_button')"
                @click="handleClickSendEmail(sendEmailToActor)"
                icon="email"
              />
            </div>
            <div
              v-if="actor.show_contact_cta_url && actor.contact_cta_url"
              class="cta-button"
            >
              <ds-button
                :label="contactLabel"
                class="custom-cta-button"
                style="width: 100%; max-width: 300px; margin: 0 auto; text-align: center;"
                v-if="actor.show_contact_cta_url && actor.contact_cta_url"
                icon="hand-pointer"
                @click="handleClickContactActorUrl"
              />
            </div>
          </div>
        </div>

        <!-- row container: contacts (isActor)  -->
        <div
          class="colored-container"
          v-if="showContactContributors && !isProduct && ((!this.isLoggedIn && contributors.list.length > 0) || (this.isLoggedIn))"
        >
          <h2 class="h2">Contacts
            <div class="title-count">
              {{ contributors.list.length }}
            </div>
          </h2>
          <div>
            <ActorDetailContributors
              :contributors="contributors"
              @track-event="trackEvent(eventCategories.ACTOR_LETS_CHAT)"
              :show-user-department="true"
              :show-user-communities="false"
              :show-user-email="false"
              :show-user-role="false"
            />
          </div>
        </div>

        <!-- row container: about info (isActor + not mobile)  -->
        <div class="colored-container" v-if="!isProduct">
          <template v-if="!isProduct && !isMobile">
            <h2 class="h2">{{ $t('actor_detail_about') }} {{ actor.name }}</h2>
            <RichBodyContent
              v-if="localizedDescription" :html="localizedDescription"
              style="margin-bottom: 0"
            />
            <div v-else>{{ $t('actor_detail_insufficient_info') }}</div>
            <br>
          </template>
          <div class="row">
            <div class="col-md-4 col-xs-6">
              <div class="simplified-actor-detail__taxonomies">
                <div class="simplified-actor-detail__taxonomies_title">
                  {{ $t('actor_detail_stage') }}:
                </div>
                <div class="simplified-actor-detail__taxonomies_value">
                  {{
                    (actor.stage && actor.stage.label) ? actor.stage.label : $t('actor_detail_not_defined')
                  }}
                </div>
              </div>
              <div class="simplified-actor-detail__taxonomies">
                <div class="simplified-actor-detail__taxonomies_title">
                  {{ $t('actor_detail_founding_date') }}:
                </div>
                <div class="simplified-actor-detail__taxonomies_value">
                  {{ actor.founding_date || $t('actor_detail_not_defined') }}
                </div>
              </div>
            </div>
            <div class="col-md-4 col-xs-6">
              <div class="simplified-actor-detail__taxonomies">
                <div class="simplified-actor-detail__taxonomies_title">
                  {{ $t('actor_detail_maturity') }}:
                </div>
                <div class="simplified-actor-detail__taxonomies_value">
                  {{ actor.maturity || $t('actor_detail_not_defined') }}
                </div>
              </div>
              <div
                class="simplified-actor-detail__taxonomies"
                v-if="isMember || (publicFieldsForRole && publicFieldsForRole.includes('funding_rounds'))"
              >
                <div class="simplified-actor-detail__taxonomies_title">
                  {{ $t('actor_detail_last_funding_round') }}:
                </div>
                <div
                  class="simplified-actor-detail__taxonomies_value"
                  v-if="actor.funding_rounds && actor.funding_rounds.length"
                >
                  {{
                    actor.funding_rounds[actor.funding_rounds.length - 1].started_at || $t('actor_detail_not_defined')
                  }}
                </div>
                <div class="simplified-actor-detail__taxonomies_value" v-else>
                  {{ $t('actor_detail_not_defined') }}
                </div>
              </div>
            </div>
            <div class="col-md-4 col-xs-6">
              <div class="simplified-actor-detail__taxonomies">
                <div class="simplified-actor-detail__taxonomies_title">
                  {{ $t('actor_detail_employees') }}:
                </div>
                <div class="simplified-actor-detail__taxonomies_value">
                  {{ employeeSize }}
                </div>
              </div>
              <div
                class="simplified-actor-detail__taxonomies"
                v-if="isMember || (publicFieldsForRole && publicFieldsForRole.includes('total_funding'))"
              >
                <div class="simplified-actor-detail__taxonomies_title">
                  {{ $t('actor_detail_funding') }}:
                </div>
                <div
                  class="simplified-actor-detail__taxonomies_value"
                  v-if="actor.total_funding"
                >
                  {{ actorTotalFunding }}
                </div>
                <div class="simplified-actor-detail__taxonomies_value" v-else>
                  {{ $t('actor_detail_not_defined') }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- row container: about info (isActor + mobile) -->
        <div class="colored-container" v-if="isMobile && !isProduct">
          <h2 class="h2">{{ $t('actor_detail_about') }} {{ actor.name }}</h2>
          <RichBodyContent
            v-if="localizedDescription" :html="localizedDescription"
            style="margin-bottom: 0"
          />
          <div v-else>{{ $t('actor_detail_insufficient_info') }}</div>
        </div>


        <!-- row container: facets & taxonomies (isActor + isProduct) -->
        <div
          class="colored-container"
          v-if="actorFacetsWithoutEmpty && actorFacetsWithoutEmpty.length"
        >
          <div class="facet-filters" v-if="isMobile">
            <div class="facet-filter show-all">
              <div class="facet-filter__facet" style="width: 100%">
                <div style="display: inline-block">
                  <ul class="category-list">
                    <template v-for="(actorFacet, taxIndex) in actorFacetsWithoutEmpty">
                      <template v-for="(facet, facetIndex) in actorFacet.options">
                        <li
                          class="category"
                          v-if="actorFacet.values && actorFacet.values.includes(facet.id) && (getReportFieldLabel(actorFacet) || getLabelForTaxonomyValue(actorFacet.name, facet.id) || facet.text)"
                          :key="'mobile-facet' + taxIndex + facetIndex"
                        >
                          <button
                            @click="showActorsWithFacet(actorFacet.name, facet)"
                          >
                            {{
                              (getLabelForTaxonomyValue(actorFacet.name, facet.id) || facet.text).split('>').slice(-1)[0].trim()
                            }}
                          </button>
                        </li>
                      </template>
                    </template>
                    <template v-if="actor.tags && actor.tags.length">
                      <li
                        class="category" v-for="(tag, index) of actor.tags"
                        :key="'mobile-tag' + index"
                      >
                        <button @click="showActorsWithFacet('tag', tag)">
                          <icon name="tag"/>
                          {{ tag.label }}
                        </button>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="facet-filters" v-else>
            <div
              class="facet-filter" :class="{'show-all': showAll[taxonomy.name]}"
              v-for="(taxonomy, index) in actorFacetsWithoutEmpty"
              :key="'taxonomy' + index"
            >
              <div
                class="facet-filter__title"
                :title="facetLabel(taxonomy)"
              >
                {{
                  facetLabel(taxonomy)
                }}
              </div>

              <div class="facet-filter__facet">
                <div style="display: inline-block">
                  <ul
                    class="category-list"
                    :ref="taxonomy.parent_id ? taxonomy.name + taxonomy.parent_id : taxonomy.name"
                  >
                    <template
                      v-for="(facet, index) in taxonomy.options" :key="'facet' + index"
                    >
                      <li
                        class="category"
                        v-if="taxonomy.name.startsWith('report_field_') || (taxonomy.values && taxonomy.values.includes(facet.id) && (getLabelForTaxonomyValue(taxonomy.name, facet.id) || facet.text))"
                      >
                        <button @click="showActorsWithFacet(taxonomy.name, facet)">
                          {{
                            (getLabelForTaxonomyValue(taxonomy.name, facet.id) || facet.text || facet).split('>').slice(-1)[0].trim()
                          }}
                        </button>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
              <div
                class="facet-filter__show-more-container"
                v-if="hasExceededLengthLimit(taxonomy.name, 52, taxonomy.parent_id)"
                :key="windowWidth + componentKey"
              >
                <a
                  class="facet-filter__show-more-link" href="#"
                  @click="toggleShowAll(taxonomy.name)"
                >{{
                    showAll[taxonomy.name] ? $t('actor_detail_show_less') : $t('actor_detail_show_all')
                  }}</a>
              </div>
            </div>

            <div class="facet-filter">
              <div
                class="facet-filter__title"
                title="tag" v-if="actor.tags && actor.tags.length"
              >
                {{ $t('add_actor_tags') }}
              </div>
              <div class="facet-filter__facet" v-if="actor.tags && actor.tags.length">
                <div style="display: inline-block">
                  <ul class="category-list">
                    <li
                      class="category" v-for="(tag, index) of actor.tags"
                      :key="'tag' + index"
                    >
                      <button @click="showActorsWithFacet('tag', tag)">
                        <icon name="tag"/>
                        {{ tag.label }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- row container: connections (isActor + mobile) -->
        <div
          class="colored-container actor-detail__connections"
          v-if="(userCanEdit || displayableConnections.length) && !isProduct"
        >
          <h2 class="h2">{{ $t('actor_detail_connections') }}
            <div class="title-count">
              {{ displayableConnections.length }}
            </div>
            <template v-if="userCanEdit">
              <ds-button
                icon="arrow-expand" :label="$t('actor_edit_connections')"
                variant="primary" @click="openConnectionsSidepanel"
                size="small"
              />
            </template>
          </h2>
          <Carousel
            v-if="displayableConnectionsWithAddButtons.length"
            :class="{'carousel--minicards': true, 'has-navigation': (displayableConnectionsWithAddButtons.length > Math.min(displayableConnectionsWithAddButtons.length, connectionCardsPerCarouselPage))}"
            :items-to-show="Math.min(displayableConnectionsWithAddButtons.length, connectionCardsPerCarouselPage)"
            :mouse-drag="false"
            :style="{width: connectionsWidth}"
          >
            <template
              v-for="(connection, index) in displayableConnectionsWithAddButtons"
              :key="'connection' + index"
            >
              <Slide>
                <WithHoverActions>
                  <div
                    v-if="connection.type && connection.type === 'add-more'"
                    :class="{'disabled': !userCanEdit}"
                    class="connection__add-more"
                    @click="userCanEdit ? openConnectionsSidepanel() : ''"
                  >+
                  </div>
                  <simplified-image-card
                    :actor="actor"
                    :connection="connection"
                    :is-connection="true"
                    :subject-name="connection.name"
                    :image="connection.logo"
                    :link="'/actors/' + connection.id"
                    v-else
                    style="height: 100%"
                    @click="trackHeapEvent('actorDetailSimplified.clickConnection')"
                  />
                </WithHoverActions>
              </Slide>
            </template>
            <template #addons>
              <Navigation
                v-if="displayableConnectionsWithAddButtons.length > connectionCardsPerCarouselPage"
              />
            </template>
          </Carousel>
          <div v-else-if="userCanEdit">
            {{ $t('actor_add_connection') }}
          </div>
        </div>

        <!-- row container: products (offers) (isActor + mobile) -->
        <div
          class="colored-container actor-detail__connections"
          id="introjs-claimed-actor-profile-connections"
          v-if="!isProduct && (userCanEdit || displayableProductTypes.total || localizedProductionDescription)"
        >
          <h2 class="h2">{{ $t('actor_detail_offering') }}
            <div class="title-count">
              {{ displayableProductTypes.total }}
            </div>
            <template v-if="userCanEdit && userCanAddProduct">
              <ds-button
                icon="plus" :label="$t('add_new_product', {product: productLabel})"
                variant="primary" @click="addProduct"
                size="small"
              />
            </template>
          </h2>

          <RichBodyContent
            v-if="localizedProductionDescription"
            :html="localizedProductionDescription"
            style="margin-bottom: 0;"
          />
          <div v-else-if="userCanEdit">
            {{ $t('actor_detail_empty_offering_description_edit', { product: productLabel }) }}
          </div>
          <div v-else>
            {{ $t('actor_detail_empty_offering_description', { product: productLabel }) }}
          </div>

          <template v-if="displayableProductTypes.productTypes.length">
            <template v-for="productType of displayableProductTypes.productTypes">
              <br>
              <h2 class="h2">{{ productType.name }} <span
                class="title-count"
              >{{ productType.count }}</span></h2>
              <Carousel
                v-if="productType.connections.length"
                :class="{'carousel--minicards': true, 'has-navigation': (productType.connections.length > productCardsPerCarouselPage)}"
                :items-to-show="productCardsPerCarouselPage"
                :mouse-drag="false"
                :style="{width: getProductsWidth(productType)}"
              >
                <template
                  v-for="(connection, index) in productType.connections"
                  :key="'product' + index"
                >
                  <Slide>
                    <WithHoverActions>
                      <div
                        v-if="connection.type && connection.type === 'add-more'"
                        :class="{'disabled': !userCanEdit || !userCanAddProduct, 'with-upgrade-overlay-cta': showProductUpgradeCTA}"
                        class="connection__add-more"
                        @click="(userCanEdit && userCanAddProduct) ? addProduct() : ''"
                      >
                        +
                        <div class="add-more-cta__overlay" v-if="showProductUpgradeCTA">
                          <h3 class="h3">
                            <!-- adding a space or newlien between }} and </router-link> will cause it to be visible-->
                            <!-- @formatter:off-->
                            <router-link to="/profile/subscription#upgrade">{{ splitCtaText[1] }}</router-link>
                            <!-- @formatter:on-->
                          </h3>
                        </div>
                        <icon name="lock" class="lock-icon" v-if="showProductUpgradeCTA"/>

                      </div>
                      <simplified-image-card
                        :name="connection.name"
                        :full-size="true"
                        :subject-name="connection.name"
                        :image="connection.featured_image_url || connection.website_screenshot"
                        :link="'/actors/' + connection.id"
                        v-else
                        style="height: 100%"
                        @click="trackHeapEvent('actorDetailSimplified.clickConnection')"
                      />
                    </WithHoverActions>
                  </Slide>
                </template>
                <template #addons>
                  <Navigation
                    v-if="productType.connections.length > productCardsPerCarouselPage"
                  />
                </template>
              </Carousel>
            </template>
          </template>
        </div>

        <!-- Offering -->
        <div class="colored-container actor-detail__connections" v-if="isProduct">
          <h2 class="h2">{{ $t('actor_detail_offering') }}</h2>

          <RichBodyContent
            v-if="localizedDescription"
            :html="localizedDescription"
            style="margin-bottom: 0;"
          />

          <div
            v-if="productFeatures && productFeatures.length > 0"
            style="margin-top: 20px;"
          >
            <h4 class="h4">Additional remarks:</h4>
            <div style="border: 2px dashed; margin-top: 5px;">
              <div v-for="(feature, key) in productFeatures" v-bind:key="key">
                <p v-if="feature.remark" style="padding: 5px;">{{ feature.name }}:
                  {{ feature.remark }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Report -->
        <Container
          v-if="isProduct && hasReportTemplate && userCanAccessReport && (userCanEdit || companyReportTemplates.length === 1) && hasFirstSelectedReport"
          class="actor-detail__report"
          style="padding: 40px 40px 0px; color: black; background-color: var(--primary-extra-lightest)"
        >
          <ActorDetailReport
            :actor="company"
            :use-header-title="true"
            :enable-read-more="true"
            :show-back-button="false"
          />
        </Container>
        <div
          class="colored-container"
          v-else-if="hasReportTemplate && userCanAccessReport && (userCanEdit || companyReportTemplates.length > 0)"
        >
          <h2 class="h2">{{ reportLabel }}
            <div class="title-count">
              {{ companyReportTemplates ? companyReportTemplates.length : 0 }}
            </div>
          </h2>
          <div
            class="simplified-actor__images-container"
          >
            <div
              :style="{
                width: `calc(${100 / productCardsPerCarouselPage}%)`,
              }"
              style="padding: 0 16px; font-size: 0; margin-bottom: 32px"
              v-for="(companyReport, index) of companyReportTemplates"
              :key="'file' + index"
            >
              <simplified-image-card
                :name="companyReport.name"
                :full-size="true"
                :disabled="!!isOpenCallEmptyAndExpired(actor.current_report, companyReport)"
                :tag="companyReport.is_call ? getOpenCallStatus(actor, companyReport) : ''"
                :banner-text="companyReport.is_call ? callTimeDifference : ''"
                @click-link="selectReportTemplate(companyReport)"
                logo-name="file-report"
              />
            </div>
          </div>
        </div>

        <!-- Announcements -->
        <div
          class="colored-container actor-detail__connections"
          v-if="!isProduct && (canUserCreateActorAnnouncement || (announcements && announcements.length))"
        >
          <h2 class="h2"> {{ announcementsLabel }}

            <div class="title-count">
              {{ announcements ? announcements.length : 0 }}
            </div>
            <template v-if="canUserCreateActorAnnouncement">
              <ds-button
                icon="plus"
                :label="$t('actor_add_announcement', {announcement: announcementLabel})"
                variant="primary" size="small"
                @click="handleClickCreateAnnouncement('announcement')"
              />
            </template>
          </h2>
          <Carousel
            :class="{'carousel--minicards': true, 'has-navigation': (announcements.length > 1)}"
            :items-to-show="Math.min(announcementsWithAddButtons.length, productCardsPerCarouselPage)"
            :mouse-drag="false"
            :style="{width: reportsWidth}"
          >
            <Slide
              v-for="(announcement, index) in announcementsWithAddButtons"
              :key="'announcement' + index"
            >
              <WithHoverActions>
                <div
                  v-if="announcement.type && announcement.type === 'add-more'"
                  :class="{'disabled': !canUserCreateActorAnnouncement }"
                  class="connection__add-more"
                  @click="canUserCreateActorAnnouncement ? handleClickCreateAnnouncement('announcement') : ''"
                >
                  +
                </div>
                <simplified-image-card
                  v-else
                  :name="announcement.title"
                  :full-size="true"
                  :image="announcement.image ? `/notifications/${announcement.id}/image` : ''"
                  :link="'/announcements/' + (announcement.slug || announcement.id)"
                  :sub-title="announcement.shortDescription"
                  style="height: 100%"
                  @click="trackHeapEvent('actorDetailSimplified.clickAnnouncement')"
                />
              </WithHoverActions>
            </Slide>
            <template #addons>
              <Navigation
                v-if="announcementsWithAddButtons.length > Math.min(announcementsWithAddButtons.length, productCardsPerCarouselPage)"
              />
            </template>
          </Carousel>
        </div>

        <!-- Events -->
        <div
          class="colored-container actor-detail__connections"
          v-if="!isProduct && (canUserCreateActorAnnouncement || (events && events.length))"
        >
          <h2 class="h2"> {{ eventsLabel }}

            <div class="title-count">
              {{ events ? events.length : 0 }}
            </div>
            <template v-if="canUserCreateActorAnnouncement">
              <ds-button
                icon="plus"
                :label="$t('actor_add_announcement', {announcement: eventLabel})"
                variant="primary" size="small"
                @click="handleClickCreateAnnouncement('event')"
              />
            </template>
          </h2>
          <Carousel
            :class="{'carousel--minicards': true, 'has-navigation': (events.length > 1)}"
            :items-to-show="Math.min(eventsWithAddButtons.length, eventCardsPerCarouselPage)"
            :mouse-drag="false"
            :style="{width: reportsWidth}"
          >
            <Slide
              v-for="(event, index) in eventsWithAddButtons"
              :key="'announcement-event' + index"
            >
              <WithHoverActions>
                <div
                  v-if="event.type && event.type === 'add-more'"
                  :class="{'disabled': !canUserCreateActorAnnouncement }"
                  class="connection__add-more"
                  @click="canUserCreateActorAnnouncement ? handleClickCreateAnnouncement('event') : ''"
                >
                  +
                </div>
                <event-card
                  v-else
                  :event="event"
                  automatically-calculate-image-height
                  class="event-simplified-image-card has-background-white"
                  :link="'/events/' + (event.slug || event.id)"
                />
              </WithHoverActions>
            </Slide>
            <template #addons>
              <Navigation
                v-if="eventsWithAddButtons.length > Math.min(eventsWithAddButtons.length, eventCardsPerCarouselPage)"
              />
            </template>
          </Carousel>
        </div>

        <!-- Challenges -->
        <div
          class="colored-container actor-detail__connections"
          v-if="canShowChallengesSection"
        >
          <h2 class="h2">Challenges
            <div class="title-count">
              {{ actor.challenges ? actor.challenges.length : 0 }}
            </div>
          </h2>
          <Carousel
            :class="{'carousel--minicards': true, 'has-navigation': (challengesWithAddButtons.length > Math.min(challengesWithAddButtons.length, productCardsPerCarouselPage))}"
            :items-to-show="Math.min(challengesWithAddButtons.length, productCardsPerCarouselPage)"
            :mouse-drag="false"
            :style="{width: getProductsWidth()}"
          >
            <Slide
              v-for="(challenge, index) in challengesWithAddButtons"
              :key="'challenge' + index"
            >
              <WithHoverActions>
                <div
                  v-if="challenge.type && challenge.type === 'add-more'"
                  :class="{'disabled': !userCanCreateActorChallenge }"
                  class="connection__add-more"
                  @click="userCanCreateActorChallenge ? openCreateChallengePanel() : ''"
                >
                  +

                </div>
                <simplified-image-card
                  :name="challenge.title"
                  :full-size="true"
                  :image="challenge.image ? `/api/challenges/${challenge.id}/image` : ''"
                  :link="'/challenges/' + challenge.id"
                  :sub-title="challenge.shortDescription"
                  v-else
                  style="height: 100%"
                  @click="trackHeapEvent('actorDetailSimplified.clickChallenge')"
                />
              </WithHoverActions>
            </Slide>
            <template #addons>
              <Navigation
                v-if="challengesWithAddButtons.length > Math.min(challengesWithAddButtons.length, productCardsPerCarouselPage)"
              />
            </template>
          </Carousel>
        </div>

        <!-- Files -->
        <div class="colored-container">
          <h2 class="h2">Files
            <div class="title-count">
              {{ actorFiles ? actorFiles.length : 0 }}
            </div>
            <ds-button
              icon="plus"
              label="Add files"
              variant="primary"
              v-if="userCanEdit"
              @click="openCreateResourcePanel()"
              size="small"
            />
            <ds-button
              icon="arrow-expand"
              label="Manage files"
              v-if="userCanEdit"
              variant="primary"
              @click="openFileRepositorySidepanel"
              size="small"
            />
          </h2>
          <div
            class="simplified-actor__images-container"
          >
            <div
              :style="{
                width: `calc(${100 / productCardsPerCarouselPage}%)`,
                lineHeight: (file.type === 'add-more' || file.mimetype === ('application/pdf')) ? 'normal' : '0'
              }"
              style="padding: 0 16px; font-size: 0; margin-bottom: 32px"
              v-for="(file, index) of actorFilesWithAddButtons" :key="'file' + index"
            >
              <div
                v-if="file.type && file.type === 'add-more'"
                :class="{'disabled': !userCanEdit}"
                style="margin-bottom: 32px;"
                class="connection__add-more"
                @click="userCanEdit ? openCreateResourcePanel() : ''"
              >
                +

              </div>
              <simplified-image-card
                :name="file.title"
                :full-size="true"
                @click-link="openPdfLink(file)"
                :logo-name="file.mimetype === 'application/pdf' ? 'file-pdf' : 'file'"
                v-else-if="file.mimetype === 'application/pdf' || file.mimetype.includes('presentation')"
              />
              <WithHoverActions
                v-else-if="file.mimetype !== 'video_link'"
                style="line-height: normal;"
              >
                <template v-slot:actions v-if="userCanEdit">
                  <ds-button
                    class="featured-image-button" icon="bookmark-filled"
                    :label="$t('actor_image_featured')" variant="primary"
                    size="small"
                    v-if="isFeaturedImage(file)"
                  />
                  <ds-button
                    icon="bookmark" :label="$t('actor_image_feature')"
                    variant="outline" size="small"
                    class="featured-image-button featured-image-button-empty"
                    :disabled="updatingFeaturedImage"
                    @click="featureImage(file)"
                    v-else
                  />
                </template>
                <simplified-image-card
                  :name="file.title" :image="file.url"
                  :full-size="true"
                  v-if="getImageUrl(file)"
                  @click-link="showImageDetail(file.url, file.title)"
                />
              </WithHoverActions>
              <div
                class="actor-detail__video-container"
                v-html="embedHtml(file.originalName)" v-else
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="container" style="height: calc(100vh - 235px - 44px); padding: 0"
        v-if="actor.id !== $route.params.id || loading"
      >
        <div class="colored-container" style="padding-bottom: 70px">
          <loading/>
        </div>
      </div>

      <div
        class="actor-detail__next-actors-list"
        style="background: var(--primary-extra-lightest); padding-top: 0; margin-top: 3rem;"
        v-if="similarAndNextActors.length > 0 && !hasSelectedReport && !showNotes && actor.id === $route.params.id && !loading"
      >
        <div class="container">
          <div class="colored-container next-actors-container">
            <h3>{{ $t('more_actor_profiles') }}</h3>
            <br>
            <div class="row">
              <div
                class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
                v-for="similarActor in similarAndNextActors.slice(0, 3)"
              >
                <simplified-image-card
                  :full-size="true"
                  @click="handleClickActorCard(similarActor)"
                  :name="similarActor.name"
                  :image="similarActor.featured_image_url || similarActor.website_screenshot || getDefaultFallbackImageUrl()"
                  style="margin-bottom: 20px;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Container
        v-if="userCanAccessNotes && showNotes"
        style="padding: 40px 40px; color: black; background-color: var(--primary-extra-lightest)"
      >
        <ActorDetailNotes :actor="company"/>
      </Container>

      <Container
        v-if="userCanAccessReport && hasSelectedReport"
        class="actor-detail__report"
        style="padding: 40px 40px 0px; color: black; background-color: var(--primary-extra-lightest)"
      >
        <ActorDetailReport :actor="company"/>
      </Container>

    </template>
    <template v-else>
      <div class="actor-detail actor-detail--empty">
        <div v-if="loading">
          <h3 class="h3">{{ $t('loading') }}</h3>
        </div>
        <div v-else-if="actorClaimInProgress">
          <h3 class="h3">{{ $t('actor_error_claim_processing') }}</h3>
          <ds-button
            style="margin-top: 15px;" variant="secondary"
            :label="$t('go_to_homepage')" @click="$router.push('/')"
          />
        </div>
        <div v-else-if="! isLoggedIn">
          <h3 class="h3">{{ $t('actor_error_login_required') }}</h3>
          <ds-button style="margin-top: 15px;" variant="secondary" :label="'Login'" @click.prevent="showSignIn"/>
          <ds-button
            style="margin-top: 15px;" variant="secondary" :label="$t('action_register')"
            @click="$router.push('/register')"
          />
        </div>
        <div v-else>
          <h3 class="h3">{{ $t('actor_error_permissions') }}</h3>
          <ds-button
            style="margin-top: 15px;" variant="secondary"
            :label="$t('go_to_homepage')" @click="$router.push('/')"
          />
          <ds-button
            :href="'mailto:' + emailAddress" style="margin-top: 15px;"
            variant="secondary" :label="$t('get_in_touch')" v-if="emailAddress"
          />
        </div>
      </div>
    </template>
    <SimplifiedFooter style="margin-top: 0"/>
  </div>
</template>

<script>
  import { Carousel, Navigation, Slide } from 'vue3-carousel'
  import getVideoId from 'get-video-id'

  import Avatar from '../../components/Avatar/Avatar.vue'
  import AvatarList from '../../components/Avatar/AvatarList.vue'
  import SimpleMap from '../../components/SimpleMap/SimpleMap.vue'
  import SimpleSocialItems from '../../components/Card/SimpleSocialItems.vue'
  import WithHoverActions from '../../components/WithHoverActions/WithHoverActions.vue'
  import SimplifiedHero from '../../components/SimplifiedHero/SimplifiedHero.vue'
  import Container from '../../components/Container/Container.vue'
  import RichBodyContent from '../../components/RichBodyContent/RichBodyContent.vue'
  import SimpleArticlePreview from '../../components/SimpleArticlePreview.vue'
  import SimpleCard from '../../components/SimpleCard/SimpleCard.vue'

  import MODAL_IDS from '../../constants/modal-ids.js'
  import { ACTION_TYPES as ACTORS_ACTION_TYPES, MUTATION_TYPES as ACTORS_MUTATION_TYPES, } from '../../store/modules/actors.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'

  import CompanyMixin from '../../util/CompanyMixin.js'
  import UiMixin from '../../util/UiMixin.js'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import ReportMixin from '../../util/ReportMixin.js'
  import {
    EMBED_SERVICE_HANDLERS,
    getConnectionsForActor,
    getImageForAnnouncement,
    getReportFieldByReportIdentifier,
    getSmallCardsPerCarouselPageBasedOnWindowWidth,
    inert,
    splitCtaText,
  } from '../../util/helpers.js'
  import { trackHeapEvent, trackMatomoEvent } from '../../util/analytics.js'
  import _intersect from 'lodash/intersection.js'
  import ICON_MANIFEST from '../../../icons/manifest.json'
  import ActorDetailSDGs from './ActorDetailSDGs.vue'
  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters.js'
  import { SiteTemplate } from '../../store/modules/config.js'
  import ConfigMixin from '../../util/ConfigMixin.js'
  import ActorDetailReport from './ActorDetailReport.vue'
  import HoverOverlayButton from '../../components/Overlay/HoverOverlayButton.vue'
  import { fetchContributors, updateActor, updateContactableContributors } from '../../api/actors.js'
  import Modal from '../../components/Modals/Modal.vue'
  import { getCallTimeDifference, toFullMonth } from '../../util/date.js'
  import KnowledgeReport from '../../components/KnowledgeSharing/KnowledgeReport.vue'
  import ActorDetailNotes from './ActorDetailNotes.vue'
  import { searchSimilarLocalActors } from '../../api/exploration.js'
  import _groupBy from 'lodash/groupBy.js'
  import SimplifiedImageCard from '../../components/Connection/SimplifiedImageCard.vue'
  import SimplifiedFooter from '../../components/SimplifiedFooter/SimplifiedFooter.vue'
  import FiltersMixin from '../../util/FiltersMixin.js'
  import Loading from '../../components/Dashboard/ConceptMap/Loading.vue'
  import { toAmount } from '../../util/currency'
  import EventCard from '../../components/Simplified/EventCard.vue'
  import SimplifiedAnnouncementPreview from '../../components/Simplified/SimplifiedAnnouncementPreview.vue'
  import { Conversations } from '../../api/conversations.js'
  import { ACTION_TYPES as CONVERSATION_ACTION_TYPES } from '../../store/modules/conversations'
  import ActorContactDetails from '../../components/ActorContactDetails/ActorContactDetails.vue'
  import Checkbox from '../../components/Form/Checkbox.vue'
  import CardInfoSocial from '../../components/Card/CardInfoSocial.vue'
  import IntroJsMixin from '../../util/IntroJsMixin.js'
  import AnnouncementMixin from '../../util/AnnouncementMixin.js'
  import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES, MATOMO_EVENT_NAMES } from '../../constants/analytics-constants'
  import { fetchSpottingArea } from '../../api/spottingareas'
  import { defineComponent } from 'vue'
  import ActorDetailContributors from '@/pages/ActorDetail/ActorDetailContributors.vue'
  import { ACTION_TYPES as SPOTTING_AREAS_ACTION_TYPES } from '@/store/modules/spottingareas'

  const AMOUNT_OF_ACTORS_IN_NEXT_ACTORS_SECTION = 3

  export default defineComponent({
    name: 'SimplifiedActorDetail',
    props: {
      actorContributors: Array,
    },
    data () {
      return {
        confirmedRemovedActor: false,
        actor: inert(this.company || {}),
        updatingFeaturedImage: false,
        windowWidth: 0,
        isInitialLoad: false,
        show: {
          contributors: false,
          avatars: {},
          report: null,
        },
        similarLocalActors: [],
        showAll: {},
        callTimeDifference: '',
        callTimeInterval: null,
        remarkLength: 0,
        componentKey: 0,
        modifiedActor: {},
        canBeContacted: true,
        eventCategories: MATOMO_EVENT_CATEGORIES,
        spottingArea: null,
        spottingAreaRole: null,
        contributors: {},
      }
    },
    computed: {
      highlightedSpottingArea () {
        return this.$store.state.spottingAreas.highlightedSpottingArea.data
      },
      MATOMO_EVENT_NAMES () {
        return MATOMO_EVENT_NAMES
      },
      MATOMO_EVENT_CATEGORIES () {
        return MATOMO_EVENT_CATEGORIES
      },
      spottingAreaId () {
        return this.$route.params.spottingAreaId
      },
      actorId () {
        if (this.$route.params.section === 'actors') {
          return this.$route.params.id
        }

        return null
      },
      showAddress () {
        return !!(this.actor.address && (this.actor.address.street || this.actor.address.city || this.actor.address.zip || this.actor.address.country))
      },
      showPhoneNumber () {
        return !!(this.actor.phone && this.actor.is_phone_published)
      },
      showContactContributors () {
        return !!(this.show.avatars && this.show.avatars.list && this.show.avatars.list.length && this.$store.getters.canActorsBeContacted)
      },
      selectedActorCanBeContacted () {
        return this.show.avatars && this.show.avatars.list && this.show.avatars.list.length && this.$store.getters.canActorsBeContacted
      },
      buttonStyle () {
        if (this.selectedActorCanBeContacted && this.actor.is_email_published && this.actor.show_contact_cta_url && this.isProduct) {
          return 'margin: 0px 42% 0px 48%;'
        }
        if (this.selectedActorCanBeContacted && this.actor.is_email_published && this.actor.show_contact_cta_url && !this.isProduct) {
          return 'margin: 0px 45% 0px 45%;'
        }
        if (this.selectedActorCanBeContacted && this.actor.is_email_published && !this.actor.show_contact_cta_url) {
          return 'margin: 0px 46% 0px 47%;'
        }
        if (!this.selectedActorCanBeContacted && this.actor.is_email_published && this.actor.show_contact_cta_url) {
          return 'margin: 0px 46% 0px 41%'
        }
        if (this.selectedActorCanBeContacted && !this.actor.is_email_published && this.actor.show_contact_cta_url) {
          return 'margin: 0px 53% 0px 43%;'
        }
        if (this.selectedActorCanBeContacted && !this.actor.is_email_published && !this.actor.show_contact_cta_url) {
          return 'justify-content: center;'
        }
        if (!this.selectedActorCanBeContacted && this.actor.is_email_published && this.actor.show_contact_cta_url) {
          return 'justify-content: center;'
        }
        if (!this.selectedActorCanBeContacted && !this.actor.is_email_published && !this.actor.show_contact_cta_url) {
          return 'justify-content: center;'
        }
      },
      actorsCanBeContacted () {
        return this.$store.getters.canActorsBeContacted
      },
      displayManageContributors () {
        return this.userIsActorOwner && this.actor.id && this.actor.actor_type !== 'Product'
      },
      isCommunityOrSpottingAreaDetailPage () {
        return this.$route.name !== 'community-detail' && this.$route.name !== 'spotting-area-detail'
      },
      userId () {
        return this.$store.getters.userId
      },
      hasSocialMediaHandles () {
        return this.actor.facebook || this.actor.twitter || this.actor.reddit || this.actor.linkedin || this.actor.vimeo || this.actor.youtube || this.actor.instagram || this.actor.rss_feed || this.actor.crunchbase || this.actor.url
      },
      getLinkHelperTextContactActor () {
        if (this.show.avatars && this.show.avatars.list && this.show.avatars.list.length) {
          if (this.userId === this.show.avatars.list[0].id) {
            return this.$t('conversation_not_allowed_with_yourself')
          }

          return this.$t('conversation_start')
        }
      },
      sendEmailToActor () {
        return 'mailto:' + this.actor.email
      },
      getLinkContactActor () {
        if (this.actor.contact_cta_url) {
          return this.actor.contact_cta_url
        }
        return ''
      },
      hasOfferingActor () {
        return this.offeredByActor && this.offeredByActor.name
      },
      offeredByActor () {
        var offeredBy = this.connections && this.connections.filter(connection => connection.type === 'is_product_of')

        if (!offeredBy || !offeredBy.length) {
          var memberOf = this.connections && this.connections.filter(connection => connection.type === 'member_of')

          if (!memberOf || !memberOf.length) {
            return
          }

          return {
            id: memberOf[0].id,
            name: memberOf[0].name,
            logo: memberOf[0].logo,
          }
        }

        return {
          id: offeredBy[0].id,
          name: offeredBy[0].name,
          logo: offeredBy[0].logo,
        }
      },
      headlineText () {
        return this.localizedDisplayProperty(this.config, 'headlineText')
      },
      isNewProfileEnabled () {
        return this.$store.getters.isNewProfileEnabled
      },
      splitCtaText () {
        return splitCtaText(this.productCtaUpgradeText)
      },
      productCtaUpgradeText () {
        if (this.showProductUpgradeCTA) {
          return this.config.packageCTAs.product.text || this.$t('default_product_upgrade_cta', { product: this.localizedDisplayProperty(this.$store.state.config, 'productAlias') || 'Product' })
        }
        return ''
      },
      showProductUpgradeCTA () {
        return this.isLoggedIn && !this.userCanAddProduct && this.userCanEdit && this.config.packageCTAs && this.config.packageCTAs.product && this.config.packageCTAs.product.enabled
      },
      isMobile () {
        return this.ui.isMobile
      },
      userCanAddProduct () {
        return this.$store.getters.userCanCreate && this.$store.getters.claimableActorTypes && this.$store.getters.claimableActorTypes.includes('Product')
      },
      userCanCreateActorChallenge () {
        return this.userIsActorOwner && this.canCreateChallenge
      },
      canShowChallengesSection () {
        if (!this.challengesAreEnabled || this.isProduct) {
          return false
        }

        if (this.userIsActorOwner) {
          return this.canCreateChallenge || (this.actor.challenges && this.actor.challenges.length)
        }

        return this.actor.challenges && this.actor.challenges.length
      },
      canCreateChallenge () {
        return this.$store.getters.canCreateChallenge
      },
      actorTotalFunding () {
        if (this.actor.total_funding) {
          return '€ ' + toAmount(this.actor.total_funding, 5)
        }

        return this.$t('actor_detail_not_defined')
      },
      publicFieldsForRole () {
        return this.$store.getters.publicFieldsForRole
      },
      employeeSize () {
        if (!this.actor.current_employees || !this.actor.current_employees.length) {
          return this.$t('actor_detail_not_defined')
        }

        var currentEmployees = this.actor.current_employees[0]

        if (currentEmployees.lower_bound == currentEmployees.upper_bound) {
          return currentEmployees.lower_bound
        }

        return currentEmployees.lower_bound + ' - ' + currentEmployees.upper_bound
      },
      isSimplifiedTemplateUsed () {
        return [SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(this.$store.getters.activeSiteTemplate)
      },
      firstOffersRelation () {
        if (this.connections.find(connection => connection.type === 'is_product_of')) {
          return this.connections.find(connection => connection.type === 'is_product_of')
        }

        if (this.connections.find(connection => connection.type === 'member_of')) {
          return this.connections.find(connection => connection.type === 'member_of')
        }

        return false
      },
      isProduct () {
        const result = this.actor && this.actor['actor_type'] === 'Product'
        // hack to fix problem with store/modules/filters.js where the current page can't be tracked correctly without an external reference when clicking "next"
        window.isProductPage = !!result
        return result
      },
      subBannerStyle () {
        return {
          backgroundColor: 'var(--primary-darkest)',
        }
      },
      bannerStyle () {
        return {
          backgroundColor: 'var(--primary-extra-darkest)',
        }
      },
      textStyle () {
        const primaryColor = this.$store.getters.primaryColor

        return {
          //color: darken(0.30, primaryColor), // darken seems to be broken
          color: primaryColor
        }
      },
      showNotes: {
        get () {
          return this.$route.hash === '#notes'
        },
        set (showNotes) {
          if (showNotes) {
            this.$router.push({ hash: '#notes' })
          } else {
            this.$router.push({ hash: '' })
          }
        },
      },
      displayableChallenges () {
        if (!this.actor.challenges) {
          return []
        }

        return this.actor.challenges
      },
      challengesWithAddButtons () {
        const result = [...this.displayableChallenges]
        if (this.displayableChallenges.length < this.productCardsPerCarouselPage) {
          for (let i = 0; i < this.productCardsPerCarouselPage - this.displayableChallenges.length; i++) {
            result.push({ type: 'add-more' })
          }
        }
        return result
      },
      companyReportTemplates () {
        if (!this.actor.reports) {
          return []
        }

        if (!this.availableReportTemplatesForCurrentActor || !this.availableReportTemplatesForCurrentActor.length) {
          return []
        }

        if (this.userCanEdit) {
          return this.availableReportTemplatesForCurrentActor
        } else if (this.actor.current_report && this.actor.current_report.length) {
          return this.availableReportTemplatesForCurrentActor.filter(reportTemplate => {
            return !!this.actor.current_report.find(reportField => {
              return !!reportTemplate.reportFields.find(reportTemplateField => reportTemplateField.id === reportField.ecosystem_report_field_id)
            })
          })
        }
        return []
      },
      ecosystemRelationships () {
        return this.$store.getters.fullActorRelationships
      },
      userCanAddActor () {
        if (this.isNewProfileEnabled) {
          return this.$store.getters.userCanCreate && !this.canOnlyCreateProducts
        }
        return this.$store.getters.userCanCreate
      },
      canOnlyCreateProducts () {
        return this.$store.getters.claimableActorTypes.includes('Product') && this.$store.getters.claimableActorTypes.length === 1
      },
      config () {
        return this.$store.state.config
      },
      actorFacetsWithoutEmpty () {
        if (!this.actorFacets) {
          return []
        }

        return [...this.actorFacets, ...this.actorReportFieldFilters]
          .filter(facet => {
            return facet && facet.values && facet.values.length
          })
      },
      actorFacets () {
        const productControls = this.isSimplifiedTemplateUsed ? this.config.simplifiedFilterControlsProducts : this.config.filterControlsProducts
        const actorControls = this.isSimplifiedTemplateUsed ? this.config.simplifiedFilterControls : this.config.filterControls

        return this.getTaxonomyValuesForFilterControls(this.isProduct ? productControls : actorControls)
      },
      actorReportFieldFilters () {
        const currentReport = this.actor.current_report

        if (!currentReport || !currentReport.length) {
          return []
        }

        const filterableReportFieldIdentifiers = this.filterableReportFieldsForActor.map((field) => 'report_field_' + field.id)

        const actorReportFilters = []

        currentReport
          .map((actorReportItem) => {
            if (!actorReportItem.value || !actorReportItem.value.length) {
              return
            }

            // Get the report field and check if it's an enabled filter
            const reportFieldId = 'report_field_' + actorReportItem.ecosystem_report_field_id

            if (filterableReportFieldIdentifiers.includes(reportFieldId)) {
              actorReportFilters.push({
                name: 'report_field_' + actorReportItem.ecosystem_report_field_id,
                values: Array.isArray(actorReportItem.value) ? actorReportItem.value : [actorReportItem.value],
                options: Array.isArray(actorReportItem.value) ? actorReportItem.value : [actorReportItem.value],
              })
            }
          })

        return actorReportFilters
      },
      reportsWidth () {
        // if (this.companyReports.length < this.productCardsPerCarouselPage) {
        //   return `calc(${100 / this.productCardsPerCarouselPage * this.companyReports.length}% + ${this.companyReports.length / this.productCardsPerCarouselPage * 32}px)`
        // }
        return 'calc(100% + 32px)'
      },
      connectionsWidth () {
        // if (this.connections.length < this.connectionCardsPerCarouselPage) {
        //   return `calc(${100 / this.connectionCardsPerCarouselPage * this.connections.length}% + ${this.connections.length / this.connectionCardsPerCarouselPage * 32}px)`
        // }
        return 'calc(100% + 64px)'
      },
      connections () {
        return getConnectionsForActor(this.actor, this.ecosystemRelationships)
      },
      displayableConnectionsWithAddButtons () {
        const result = [...this.displayableConnections]
        if (this.displayableConnections.length < this.connectionCardsPerCarouselPage) {
          for (let i = 0; i < this.connectionCardsPerCarouselPage - this.displayableConnections.length; i++) {
            result.push({ type: 'add-more' })
          }
        }
        return result
      },
      displayableConnections () {
        if (!this.connections) {
          return []
        }

        if (this.isProduct) {
          return this.connections.filter(c => c.name && c.name.length > 0)
        }

        return this.connections.filter(c => c.name && c.name.length > 0 && c.actorType !== 'Product')
      },
      displayableProductTypes () {
        const productConnections = this.connections
          .filter(c => c.name && c.name.length > 0 && c.actorType === 'Product')
          //.filter(c => (c.actorType === 'Product' || c.type === 'has_product'))
          .map(c => {
            if (!c.legend) {
              c.legend = { label: this.$t('actor_detail_other'), value: null }
            }
            return c
          })
        const groupedProductConnections = _groupBy(productConnections, 'legend.label')
        const mappedProductConnections = Object.keys(groupedProductConnections).reduce(
          (result, type) => {
            const connectionsCount = groupedProductConnections[type].length
            if (groupedProductConnections[type].length < this.productCardsPerCarouselPage) {
              for (let i = 0; i < this.productCardsPerCarouselPage - connectionsCount; i++) {
                groupedProductConnections[type].push({ type: 'add-more' })
              }
            }
            result.push({
              name: type,
              count: connectionsCount,
              connections: groupedProductConnections[type],
            })

            return result
          },
          [],
        )
        return {
          total: productConnections.length,
          productTypes: mappedProductConnections,
        }
      },
      eventCardsPerCarouselPage () {
        return 4
      },
      productCardsPerCarouselPage () {
        if (this.cardsPerCarouselPage >= 3) {
          return this.cardsPerCarouselPage - 2
        }
        return this.cardsPerCarouselPage
      },
      connectionCardsPerCarouselPage () {
        return this.cardsPerCarouselPage
      },
      cardsPerCarouselPage () {
        return getSmallCardsPerCarouselPageBasedOnWindowWidth(this.windowWidth)
      },
      challengesAreEnabled () {
        return this.$store.state.config.challengesAreEnabled
      },
      emailAddress () {
        return this.$store.state.config.email
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      bannerImage () {
        if (this.actor.banner_image_url) {
          return this.actor.banner_image_url// + '?type=banner'
        }
      },
      actorFilesWithAddButtons () {
        const result = [...this.actorFiles]
        const nrOfCardsOnLastRow = this.actorFiles.length % this.productCardsPerCarouselPage
        const nrOfExtraButtons = this.productCardsPerCarouselPage - nrOfCardsOnLastRow
        if (nrOfCardsOnLastRow !== 0 || this.actorFiles.length === 0) {
          for (let i = 0; i < nrOfExtraButtons; i++) {
            result.push({ type: 'add-more' })
          }
        }
        return result
      },
      actorFiles () {
        let files = []

        if (this.actor.files) {
          files = this.actor.files.slice()
        }

        if (this.actor.website_screenshot) {
          files.push({
            mimetype: 'image/png',
            title: 'Website Screenshot',
            url: this.actor.website_screenshot,
            path: this.actor.website_screenshot,
            preview_w50: this.actor.website_screenshot,
            preview_w320: this.actor.website_screenshot,
            preview_w766: this.actor.website_screenshot,
          })
        }

        return files.slice(0, 12)
      },
      announcementsWithAddButtons () {
        const result = [...this.announcements]
        if (this.announcements.length < this.productCardsPerCarouselPage) {
          for (let i = 0; i < this.productCardsPerCarouselPage - this.announcements.length; i++) {
            result.push({ type: 'add-more' })
          }
        }
        return result
      },
      eventsWithAddButtons () {
        const result = [...this.events]
        if (this.events.length < this.eventCardsPerCarouselPage) {
          for (let i = 0; i < this.eventCardsPerCarouselPage - this.events.length; i++) {
            result.push({ type: 'add-more' })
          }
        }
        return result
      },
      announcements () {
        return this.actor.announcements.filter(announcement => {
          return announcement.is_event === false || announcement.is_event === 0
        }) || []
      },
      events () {
        return this.actor.announcements.filter(announcement => {
          return announcement.is_event || announcement.is_event === 1
        }) || []
      },
      highlightedAnnouncement () {
        return this.actor.announcements && this.actor.announcements[0]
      },
      similarAndNextActors () {
        if (this.isCommunityOrSpottingAreaDetailPage) {
          return this.similarLocalActors
        }
        if (this.isProduct && this.isSimplifiedTemplateUsed) {
          return this.similarLocalActors.concat(this.nextProducts)
        }
        return this.similarLocalActors.concat(this.nextActors)
      },
      nextProducts () {
        const productList = this.$store.state.actors.productData.data
        const productProfile = productList.findIndex(actor => actor.id === this.actor.id)

        if (productProfile === -1) {
          return []
        }

        if (productProfile === productList.length - 1) {
          return productList.slice(0, AMOUNT_OF_ACTORS_IN_NEXT_ACTORS_SECTION)
        }

        return productList.slice(productProfile + 1, productProfile + 1 + AMOUNT_OF_ACTORS_IN_NEXT_ACTORS_SECTION)
      },
      nextActors () {
        const actorsList = this.$store.state.actors.listData.data
        const indexOfCurrentActor = actorsList.findIndex(actor => actor.id === this.actor.id)

        if (indexOfCurrentActor === -1) {
          return []
        }

        if (indexOfCurrentActor === actorsList.length - 1) {
          return actorsList.slice(0, AMOUNT_OF_ACTORS_IN_NEXT_ACTORS_SECTION)
        }

        return actorsList.slice(indexOfCurrentActor + 1, indexOfCurrentActor + 1 + AMOUNT_OF_ACTORS_IN_NEXT_ACTORS_SECTION)
      },
      canUserCreateActorAnnouncement () {
        if (this.$store.getters.isActor && !this.$store.getters.canCreateAnnouncement) {
          return false
        }

        return (this.$store.getters.isMember && this.$store.state.config.allowEdit == true) ||
          this.$store.getters.userClaimedIds.includes(this.actor.id) ||
          (_intersect(this.$store.getters.userContributionPortfolios, this.actorPortfolios).length > 0 && this.$store.getters.hasAccessToPortfolioSharing && this.$store.getters.isPortfolioMember) ||
          this.$store.getters.isOwner
      },
      userIsActorOwner () {
        // if (this.$store.state.user && this.$store.state.user.profile && this.$store.state.user.profile.id && this.actor.claimed_by) {
        //   return this.$store.state.user.profile.id === this.actor.claimed_by
        // }

        // Verify if an actor-owner claim exists
        if (this.profile && this.profile.claims) {
          var claimIds = this.profile.claims.filter(claim => claim.claim_type === 'actor-owner' && claim.claim_verified).map(claim => claim.id)

          return claimIds.includes(this.$route.params.id)
        }
      },
      profile () {
        return this.$store.state.user && this.$store.state.user.profile
      },
      actorClaimInProgress () {
        if (!this.profile || !this.profile.claims) {
          return false
        }

        var claimIds = this.profile.claims.map(claim => claim.id)

        return claimIds.includes(this.$route.params.id)
      },
      isActorClaimed () {
        // the "claimed" computed property should be coming from the transformed actor object
        return this.actor && this.actor.claimed
      },
      contactLabel () {
        if (this.actor.contact_cta_text) {
          return this.actor.contact_cta_text
        }

        return this.$t('get_in_touch')
      },
      displayContactButton () {
        // Display the "contact button" if the option is turned on and if the user isn't the one who has claimed the profile
        const defaultBehaviour = (this.$store.getters.publisher && (this.selectedActorCanBeContacted || this.actor.show_contact_cta_url) && this.$store.getters.canActorsBeContacted)
        const claimedBehaviour = (!this.hasUserClaimedActor && this.isActorClaimed)

        if (!this.$store.getters.isLoggedIn || this.hasUserClaimedActor) {
          return defaultBehaviour
        }

        return defaultBehaviour && claimedBehaviour
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      loading () {
        return this.isInitialLoad && this.$store.state.actors.detail.loading
      },
      company () {
        return this.$store.getters.detailActor
      },
      isMember () {
        return this.$store.getters.isMember
      },
      localizedDescription () {
        return this.localizedDisplayProperty(this.actor, 'description')
      },
      localizedActivitiesDescription () {
        return this.localizedDisplayProperty(this.actor, 'activities_description')
      },
      localizedProductionDescription () {
        return this.localizedDisplayProperty(this.actor, 'additional_information')
      },
      carouselIcons () {
        return {
          navigationLeft: ICON_MANIFEST['chevron-left'],
          navigationRight: ICON_MANIFEST['chevron-right'],
        }
      },
      hasAtLeastOneReportWithImage () {
        const allReports = this.actor.reports
        if (!allReports) {
          return false
        }

        return allReports.find(report => !!this.getFirstImageUrlFromReport(report))
      },
      firstImageOfReport () {
        return this.getFirstImageUrlFromReport(this.actor.reports.find(report => !!this.getFirstImageUrlFromReport(report)))
      },
      originalProfile () {
        return this.$store.state.user.profile
      },
      nonProductClaims () {
        if (!this.originalProfile || !this.originalProfile.claims) {
          return []
        }
        return this.originalProfile.claims.filter(claim => claim.actor_type !== 'Product')
      },
      productFeatures () {
        const productFeaturesArray = []

        const features = productFeaturesArray.concat(this.actor.product_features_a,
          this.actor.product_features_b,
          this.actor.product_features_c)

        const productFeatures = []
        features.forEach((feature) => {
          if (feature && feature.pivot.remark !== null) {
            if (feature.pivot.remark !== '') {
              productFeatures.push({ name: feature.name, remark: feature.pivot.remark })
            }
          }
        })

        return productFeatures
      },
      getCTAButtonClicks () {
        return this.actor.cta_button_visits || 0
      },
    },
    methods: {
      goToHighlightedSpottingAreaDetail () {
        if (this.$route.name === 'spotting-area-detail') {
          trackMatomoEvent(MATOMO_EVENT_CATEGORIES.HIGHLIGHTED_SPOTTING_AREA, MATOMO_EVENT_ACTIONS.SPOTTING_AREA_SIMPLIFIED_ACTOR_DETAIL_PAGE, this.highlightedSpottingArea.id)
        } else {
          trackMatomoEvent(MATOMO_EVENT_CATEGORIES.HIGHLIGHTED_SPOTTING_AREA, MATOMO_EVENT_ACTIONS.SIMPLIFIED_ACTOR_DETAIL_PAGE, this.highlightedSpottingArea.id)
        }

        if (window.config.spottingAreasFocus && window.config.spottingAreasFocus === 'actors') {
          this.$router.push(`/spotting-areas/${this.highlightedSpottingArea.id}/actors`)

          return;
        }

        this.$router.push(`/spotting-areas/${this.highlightedSpottingArea.id}`)
      },
      facetLabel (facet) {
        if (facet.name.startsWith('report_field_')) {
          return this.getReportFieldLabel(facet)
        }

        return facet.parent_id ? this.getLabelForTaxonomyValue(facet.name, facet.parent_id) : this.getTaxonomyAliasWithDefault(facet.name)
      },
      getReportFieldLabel (facet) {
        const reportField = getReportFieldByReportIdentifier(facet.name)

        if (reportField) {
          return reportField.label
        }

        return ''
      },
      handleClickContactActorUrl () {
        window.open(this.getLinkContactActor, '_blank')

        trackMatomoEvent(MATOMO_EVENT_CATEGORIES.ACTOR_GET_IN_TOUCH, MATOMO_EVENT_ACTIONS.CLICK, this.actorId)
      },
      handleClickSendEmail (href) {
        window.open(href, '_blank')

        trackMatomoEvent(MATOMO_EVENT_CATEGORIES.ACTOR_SEND_EMAIL, MATOMO_EVENT_ACTIONS.CLICK, this.actorId)
      },
      fetchSpottingArea () {
        if (this.spottingAreaId) {
          fetchSpottingArea({ spottingAreaId: this.spottingAreaId })
            .then((response) => {
              this.spottingArea = response
              this.spottingAreaRole = response.spotting_area_access
            })
        }
      },
      updateContactableContributors,
      showConfirmRemoveModal () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          spottingArea: this.spottingAreaId,
          actorId: this.actorId,
        })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.REMOVE_ACTOR_FROM_SPOTTING_AREA_MODAL)
      },
      showContributorModal () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_CONTRIBUTOR_MODAL)
      },
      showLoginModal () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
      },
      showSignIn () {
        if (this.$store.getters.isPrivate) {
          this.$router.push('/login')
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
        }
      },
      onClickClaimActor () {
        if (this.nonProductClaims.length === 0 && this.isProduct) {
          this.showRestrictedModal()
        } else {
          this.showClaimModal()
        }
      },
      getImageForAnnouncement,
      getCallTimeDifference,
      goToOfferByActor () {
        this.$router.push('/actors/' + this.offeredByActor.id)
      },
      toggleNotesVisibility () {
        this.hideReport()
        this.showNotes = !this.showNotes
      },
      openCreateChallengePanel () {
        trackHeapEvent('actorDetailSimplified.ctaCreateChallenge')
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'manage-challenge-panel' })
      },
      getTaxonomyValuesForFilterControls (filterControls) {
        const actorFacets = []

        if (!filterControls) {
          return actorFacets
        }

        let taxonomyFacets

        for (const facet of filterControls) {
          // this is hacky/hardcoded because of discrepancies between filter controls and actual taxonomy values / facet values
          switch (facet) {
            case 'category':
            case 'stage':
              if (this.actor[facet]) {
                actorFacets.push({
                  name: facet,
                  values: [this.actor[facet].value],
                  options: [{
                    id: this.actor[facet].value,
                    text: this.actor[facet].label,
                  }],
                })
              }
              break
            case 'activities':
              if (this.actor[facet]) {
                actorFacets.push({
                  name: facet,
                  values: this.actor[facet].map(f => f.value),
                  options: this.actor[facet].map(f => {
                    return { id: f.value, text: f.label }
                  }),
                })
              }
              break
            // NOTE: refactoring domains and industries to generic 2-level hierarchy taxonomies would be a great refactor
            case 'domains':
              if (!this.actor[facet]) {
                break
              }

              taxonomyFacets = this.parseHierarchicalTaxonomyFacetValues(facet, 'parentId')

              if (taxonomyFacets.length > 0) {
                taxonomyFacets.forEach((facet) => {
                  actorFacets.push(facet)
                })
              }

              break
            case 'industries':
              if (!this.actor[facet]) {
                break
              }

              taxonomyFacets = this.parseHierarchicalTaxonomyFacetValues(facet, 'industry_id')

              if (taxonomyFacets.length > 0) {
                taxonomyFacets.forEach((facet) => {
                  actorFacets.push(facet)
                })
              }
              break
            case 'technology':
            case 'product_features_a':
            case 'product_features_b':
            case 'product_features_c':
              if (!this.actor[facet]) {
                break
              }

              taxonomyFacets = this.parseHierarchicalTaxonomyFacetValues(facet)

              if (taxonomyFacets.length > 0) {
                taxonomyFacets.forEach((facet) => {
                  actorFacets.push(facet)
                })
              }

              break
            // Filtered properties that are not a taxonomy
            case 'maturity':
              if (this.actor[facet]) {
                actorFacets.push({
                  name: facet,
                  values: [this.actor[facet]],
                  options: [{
                    id: this.actor[facet],
                    text: this.actor[facet],
                  }],
                })
              }

              break
            default:
              break
          }
        }

        return actorFacets
      },
      parseHierarchicalTaxonomyFacetValues (facet, parentKey = 'parent_id') {
        var parentFacets = this.actor[facet].filter(f => !f[parentKey])
        var parentIds = parentFacets.map(f => f.id) || []

        const taxonomyFacetValues = []

        taxonomyFacetValues.push({
          name: facet,
          displayName: facet,
          values: parentFacets.map(feature => feature.id),
          options: this.getOptionsForFacet(facet),
        })

        const additionalParentFacets = []

        parentIds.forEach((parentId) => {
          const facetValue = {
            name: facet,
            displayName: parentFacets.find(f => f.id == parentId).name,
            values: this.actor[facet].filter(f => f[parentKey] == parentId).map(f => f.id),
            options: this.getOptionsForFacet(facet, true),
          }

          facetValue['parent_id'] = parentId

          additionalParentFacets.push(facetValue)
        })

        additionalParentFacets
          .sort((a, b) => a.displayName.localeCompare(b.displayName))
          .forEach((additionalFacet) => {
            taxonomyFacetValues.push(additionalFacet)
          })

        return taxonomyFacetValues
      },
      getOptionsForFacet (productFeature, children = false) {
        switch (productFeature) {
          case 'product_features_a':
            if (children) {
              return this.productFeaturesAChildrenOptions
            }
            return this.productFeaturesAOptions
          case 'product_features_b':
            if (children) {
              return this.productFeaturesBChildrenOptions
            }
            return this.productFeaturesBOptions
          case 'product_features_c':
            if (children) {
              return this.productFeaturesCChildrenOptions
            }
            return this.productFeaturesCOptions
          case 'technology':
            if (children) {
              return this.technologyChildrenOptions
            }
            return this.technologyOptions
          case 'domains':
            if (children) {
              return this.domainsChildrenOptions
            }
            return this.domainsOptions
          case 'industries':
            if (children) {
              return this.industriesChildrenOptions
            }
            return this.industriesOptions
          default:
            return []
        }
      },
      canShowFacet (facet, facetName) {
        const hasContent = facet && facet.length > 0
        if (!hasContent || facetName === 'sustainability_goal') {
          return false
        }

        return this.availableFilterControls.includes(facetName)
      },
      hasExceededLengthLimit (facetName, customHeight, parentId) {
        const maxHeight = customHeight || 52

        var refName = facetName

        if (parentId) {
          refName += parentId
        }

        if (this.$refs[refName] && this.$refs[refName][0]) {
          return this.$refs[refName][0].offsetHeight > maxHeight
        }
      },
      toggleShowAll (facetName) {
        this.showAll[facetName] = !this.showAll[facetName]
        this.$forceUpdate()
      },
      openPdfLink (file) {
        window.open(file.url, '_blank')
      },
      getProductsWidth (productType) {
        // if (productType.connections.length < this.productCardsPerCarouselPage) {
        //   return `calc(${100 / this.productCardsPerCarouselPage * productType.connections.length}% + ${productType.connections.length / this.productCardsPerCarouselPage * 32}px)`
        // }
        return 'calc(100% + 32px)'
      },
      getFileReportFieldIdsFromTemplate (reportTemplate) {
        return reportTemplate.reportFields
          .filter(reportField => reportField.type === 'file')
          .map(reportField => reportField.id)
      },
      getFirstImageUrlFromReport (report) {
        if (!report || !report.reports) {
          return ''
        }
        const reportTemplate = this.getTemplateForReport(report)
        const imageFieldIds = this.getFileReportFieldIdsFromTemplate(reportTemplate)
        const allFileReportsIds = report.reports
          .filter(report => imageFieldIds.includes(report.ecosystem_report_field_id))
          .map(report => report.value)
        const firstReportedImageFileOfActor = (this.actor.files || []).find(file => allFileReportsIds.includes(String(file.id)) && file.mimetype.startsWith('image'))
        if (!firstReportedImageFileOfActor) {
          return this.actor.featured_image_url || this.actor.website_screenshot || ''
        }
        return firstReportedImageFileOfActor.url
      },
      toFullMonth,
      fetchContributorsAvatars () {
        // Reset the object
        this.show.avatars = { email: '', list: [], errors: {}, message: null }
        this.contributors = { list: [], loading: true }

        fetchContributors(this.$route.params.id, { includeOwners: true })
          .then(list => {
            var options = []
            var optionsCards = []

            Object.keys(list)
              .forEach(key => {
                if (list[key].allow_conversation) {
                  options.push({
                    id: list[key].user_id,
                    name: list[key].user_name,
                    avatar: list[key].photo_url,
                  })

                  optionsCards.push(list[key])
                }
              })

            const optionsWithoutDuplicates = []
            for (const option of options) {
              if (!optionsWithoutDuplicates.find(unDuplicatedOption => option.id === unDuplicatedOption.id)) {
                optionsWithoutDuplicates.push(option)
              }
            }

            Object.assign(this.show.avatars, { list: optionsWithoutDuplicates })
            Object.assign(this.contributors, { list: optionsCards, loading: false })
          })
          .catch(this.handleContributorError)
      },
      handleContributorError (errors) {
        this.contributors.loading = false

        console.error(errors)
        if (errors && errors.message === 'Unexpected error') {
          errors.message = 'You can only add contributors to published profiles.'
        }
      },
      showImageDetail (image, name) {
        if (image) {
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
            detailImage: image,
            detailImageName: name || 'Image',
          })
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.IMAGE_DETAIL)
        }
      },
      addProduct () {
        const modalContext = {
          prefilled: {
            prefilledParentId: this.actor.id,
          },
          successCallback: actor => {
            this.$bus.emit('productAddedViaOverlay', actor)
          },
        }

        if (this.$store.getters.detailActor && this.$store.getters.detailActor.address && (this.$store.getters.detailActor.address.street || this.$store.getters.detailActor.address.city)) {
          modalContext.prefilled.address = {
            ...this.$store.getters.detailActor.address,
          }
          modalContext.prefilled.parentName = this.$store.getters.detailActor.name
        }

        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, modalContext)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_PRODUCT)
      },
      addActor () {
        const actorModalContext = {
          afterCreate: 'goToActor',
        }

        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, actorModalContext)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
      },
      showActorsWithFacet (facetName, feature) {
        trackHeapEvent(this.isProduct ? 'productDetail.showProductsWithFeature' : 'actorDetail.showProductsWithFeature')

        this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)

        if (facetName === 'tag') {
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_NON_TAXONOMY_FACET, {
            facet: facetName,
            value: feature,
          })
        } else if (facetName.startsWith('report_field_')) {
          this.$store.commit(FILTERS_MUTATION_TYPES.UPDATE_REPORT_FIELD, {
            reportId: facetName,
            value: feature,
          })
        } else {
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
            facet: facetName,
            values: [feature.id || feature.value],
          })
        }

        const simplifiedPage = this.isProduct ? '/products-simplified' : '/actors-simplified'
        const advancedPage = this.isProduct ? '/product-gallery' : '/actors'
        const targetPage = this.isSimplifiedTemplateUsed ? simplifiedPage : advancedPage
        this.$router.push(targetPage)
      },
      trackHeapEvent,
      handleCarouselPageChange (page, type) {
        // @deprecated via the new carousel slider
        trackHeapEvent(`actorDetailSimplified.${type}Carousel`, { page })
      },
      openConnectionsSidepanel () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'actor-connections-panel',
        })
      },
      handleResize () {
        this.windowWidth = window.innerWidth
      },
      goToNext () {
        const { data } = this.isProduct ? this.$store.state.actors.productData : this.$store.state.actors.listData
        const next = 1 + data.findIndex(a => a.id === this.$route.params.id)

        this.suggestions = []
        this.hasAgentSuggestions = false

        trackHeapEvent('actorDetailSimplified.clickNext')

        if (data[next]) {
          // The next actor is cached
          return this.viewActor(data[next])
        }

        if (this.$store.state.filters.paging.offset < this.$store.getters.lastPageOffset && data.length > 0) {
          // Fetch next page
          this.$store.commit('FILTERS/NEXT_PAGE')
        }

        return this.$store.dispatch((this.isProduct ? ACTORS_ACTION_TYPES.FETCH_PRODUCTS_LIST_NOW : ACTORS_ACTION_TYPES.FETCH_ACTORS_LIST_NOW),
          Object.assign({},
            this.$store.getters.listFilterObject,
            { actor_type: this.isProduct ? ['Product'] : ['LegalEntity', 'Person'] },
          ))
          .then(() => {
            const { data } = this.isProduct ? this.$store.state.actors.productData : this.$store.state.actors.listData
            // Load first actor on the page
            if (data && data.length) {
              if (data[0].id !== this.$route.params.id) {
                this.viewActor(data[0])
              } else if (data.length > 1) {
                this.viewActor(data[1])
              }
            }
          })
      },
      viewActor (actor) {
        if (this.$route.name === 'community-detail') {
          return this.$router.push(`/communities/${this.$route.params.communityId}/members/${actor.id}`)
        }

        if (this.$route.name === 'spotting-area-detail') {
          return this.$router.push(`/spotting-areas/${this.$route.params.spottingAreaId}/actors/${actor.id}`)
        }

        return this.$router.push('/actors/' + actor.id)
      },
      getImageUrl (image) {
        if (image.preview_w766) {
          return image.preview_w766
        }

        if (image.preview_w320) {
          return image.preview_w320
        }

        if (image.url) {
          return image.url
        }
      },
      embedHtml (url) {
        const videoId = getVideoId(url)

        if (EMBED_SERVICE_HANDLERS[videoId.service]) {
          return EMBED_SERVICE_HANDLERS[videoId.service](videoId.id)
        } else {
          return EMBED_SERVICE_HANDLERS.fallback(url)
        }
      },
      isEmbeddable (url) {
        return true
      },
      isFeaturedImage (image) {
        return this.actor.featured_image_url && [
          image.url,
          image.banner,
          image.preview_w50,
          image.preview_w320,
          image.preview_w766,
        ].includes(this.actor.featured_image_url)
      },
      async featureImage (image) {
        try {
          this.updatingFeaturedImage = true
          await this.$store.dispatch(ACTORS_ACTION_TYPES.UPDATE_ACTOR, {
            id: this.actor.id,
            data: { featured_image_url: image.url },
          })
          this.updatingFeaturedImage = false
        } catch (errors) {
          this.updatingFeaturedImage = false
          console.error(errors)
        }
      },
      openCreateResourcePanel () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'create-resource-panel',
          metaData: {
            actor_id: this.actor.id,
            visibleMediaInputField: 'resource',
          },
        })
      },
      openFileRepositorySidepanel () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'actor-file-repository',
          metaData: this.actor,
        })
      },
      handleClickActorCard (actor) {
        trackHeapEvent('actorDetailSimplified.clickSimilarActor')
        this.$router.push(`/actors/${actor.id}`)
      },
      showClaimModal () {
        trackHeapEvent('actorDetailSimplified.showClaimModal')
        this.$store.commit(ACTORS_MUTATION_TYPES.SET_ACTOR_FOR_PREVIEW, this.company)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CLAIM)
      },
      async fetch (force) {
        this.isInitialLoad = !force

        const fetchActorPromise = this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_DETAIL, force === true || this.$route.params.id)

        if (fetchActorPromise) {
          fetchActorPromise.then(() => {
            if (this.isProduct && !this.nextProducts.length) {
              this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_PRODUCTS_LIST_NOW, Object.assign({}, this.$store.getters.listFilterObject, { actor_type: 'Product' }))
            } else if (!this.isProduct && !this.nextActors.length) {
              this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_LIST_NOW, this.$store.getters.listFilterObject)
            }
          }).finally(async () => {
            // re-calculate 'show more' button display after fetching / updating actor details
            this.componentKey++

            this.fetchContributorsAvatars()

            if (this.isInitialLoad) {
              trackHeapEvent('actorDetailSimplified.load', {
                actorType: this.actor.actor_type,
                id: this.actor.id,
                name: this.actor.name,
                category: this.actor.category ? this.actor.category.label : '',
              })
            }
            this.isInitialLoad = false

            this.loadFirstReportTemplateOnPage()
            this.showOrHideReport()
            this.startIntroJs()
          })
        } else {
          this.showOrHideReport()
          this.fetchContributorsAvatars()
          this.startIntroJs()
        }

        this.fetchSpottingArea()
      },
      openEditSidePanel (actorId) {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'actor-edit',
          metaData: { id: actorId },
        })
      },
      trackEvent (eventCategory) {
        const obj = {
          name: this.actor.name,
          id: this.actor.id,
          type: this.actor.actor_type === 'LegalEntity' ? 'Actor' : 'Product',
          type_official: this.actor.actor_type,
          is_logged_in: !!this.$store.getters.isLoggedIn,
        }

        trackMatomoEvent(eventCategory, MATOMO_EVENT_ACTIONS.BUTTON_CLICK, JSON.stringify(obj))
        trackMatomoEvent(eventCategory, MATOMO_EVENT_ACTIONS.OPEN_CONVERSATION, JSON.stringify(obj))
      },
      handleClickContactActor (eventCategory) {
        if (this.isNewProfileEnabled) {
          if (this.$store.getters.isLoggedIn) {
            Conversations.post({ recipient_id: this.show.avatars.list[0].id }).then((conversation) => {
              this.$router.push('/profile/conversations/' + conversation.id)
            })
          } else {
            this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
          }
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CONTACT)
        }
        this.trackEvent(eventCategory)
      },
      handleClickCreateAnnouncement (type) {
        this.showCreateModal(type, this.$route.params.id)
      },
      hideSidePanel () {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      trackAnnouncementClick () {
        trackHeapEvent('actorDetailSimplified.clickAnnouncement')
      },
      handleFileCarouselPageChange (page) {
        trackHeapEvent('actorDetailSimplified.fileCarousel', { page })
      },
      getSimilarActors () {
        if ((this.$store.getters.hasAccessToExploration || this.$store.getters.hasAccessToMonitoring) && !this.isCommunityOrSpottingAreaDetailPage) {
          searchSimilarLocalActors(this.$route.params.id).then((result) => {
            if (result && result['same-name'] && result['same-name'].length) {
              this.similarLocalActors = result['same-name']
            }
          })
        }
      },
      loadFirstReportTemplateOnPage () {
        if (this.companyReportTemplates.length === 1) {
          // this.setFirstReportTemplate(this.companyReportTemplates[0])
        }
      },
      startConversation ($event, actorId, actorName, userId) {
        $event.stopPropagation()

        if (userId == this.userId) {
          return
        }

        Conversations.post({
          actor_id: actorId,
          recipient_id: userId,
        }).then((conversation) => {
          this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
          this.$router.push('/profile/conversations/' + conversation.id)
        })
      },
      showRestrictedModal () {
        trackHeapEvent('restrictedModal')
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.RESTRICTED_MODAL)
      },
      startIntroJs () {
        if (this.userIsActorOwner) {
          if (this.seenIntros.includes('simplifiedActorDetail')) {
            return
          }

          const config = this.buildIntroJsConfig(this.config, this.$store.getters.userRole)
          if (!config || !config.simplifiedActorDetail || !config.simplifiedActorDetail.steps) {
            return
          }

          config.simplifiedActorDetail.steps = this.getIntroJsStepsConfig(config.simplifiedActorDetail.steps)
          const intro = this.introJs().setOptions(config.simplifiedActorDetail)
          this.currentIntro = intro

          const componentScope = this
          intro.onbeforechange(this.introJsBeforeStepCallback.bind(this))
          intro.onafterchange(this.introJsAfterStepCallback.bind(this))
          intro.oncomplete(function () {
            componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
          })
          intro.onexit(function () {
            componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
          })

          intro.start()
        }
      },
    },
    mounted () {
      this.$bus.on('removeActorConfirmed', () => {
        this.confirmedRemovedActor = true
        this.company.deleted_at = 'now'
        // the 'watch' will be triggered again
      })

      this.$bus.on('actorUpdate', (id) => {
        if (id === this.actor.id) {
          this.fetch(true)
        }
      })

      this.$bus.on('selectReportTemplate', () => {
        if (this.$refs && this.$refs.scrollable && this.$refs.scrollable.scrollTop) {
          this.$refs.scrollable.scrollTop = 0
        }
      })

      this.$bus.on('productAddedViaOverlay', (actor) => {
        const relation = [...this.actor['has_product']] || []

        relation.push({
          to: actor.id,
          to_name: actor.name,
          start: null,
          end: null,
        })

        updateActor({
          id: this.actor.id,
          data: {
            'has_product': relation,
          },
        }).then(() => {
          this.fetch(true)
        })
      })

      this.$bus.on('resize300', () => {
        this.handleResize()
      })

      if (this.callCountdownTemplate) {
        this.callTimeInterval = setInterval(() => {
          this.callTimeDifference = this.getCallTimeDifference(this.callCountdownTemplate.due_date)
        }, 1000)
      }
      this.$bus.on('updateContributors', this.fetchContributorsAvatars)

      this.$bus.on('announcementCreated', () => {
        this.fetch(true)
      })
    },
    async created () {
      if (this.$store.getters.hasAccessToNewSpottingAreas && !this.$store.state.spottingAreas.listData.loading) {
        this.$store.dispatch(SPOTTING_AREAS_ACTION_TYPES.FETCH_SPOTTING_AREAS_LIST)
      }

      // When entering the actor profile we close any side panel that might be open
      this.hideSidePanel()

      this.fetch()
      this.getSimilarActors()
    },
    beforeUnmount () {
      this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)

      this.$bus.off('actorUpdate')
      this.$bus.off('productAddedViaOverlay')
      this.$bus.off('selectReportTemplate')
      this.$bus.off('resize300')
      this.$bus.off('updateContributors')

      if (this.callTimeInterval) {
        clearInterval(this.callTimeInterval)
      }
    },
    watch: {
      company (val) {
        this.actor = val

        if (this.confirmedRemovedActor) {
          // Don't call this.fetch as this will give an infinite loop!
          this.goToNext()
        }

        this.$nextTick(() => {
          // recalculate 'show more' conditions after loading actor
          this.windowWidth = window.innerWidth
        })
      },
      async '$route.params.id' (to, from) {
        if (to !== from && (this.$route.path.startsWith('/actors') || this.$route.path.startsWith('/communities') || this.$route.path.startsWith('/spotting-areas'))) {
          this.fetch()
          if (this.$refs && this.$refs.scrollable && this.$refs.scrollable.scrollTop) {
            this.$refs.scrollable.scrollTop = 0
          }

          // Reset data props
          this.similarLocalActors = []
          this.confirmedRemovedActor = false
          this.getSimilarActors()
        }
      },
      async '$route.params.spottingAreaId' () {
        this.fetchSpottingArea()
      },
    },
    mixins: [
      CompanyMixin,
      UiMixin,
      AnnouncementMixin,
      TranslationsMixin,
      ConfigMixin,
      ReportMixin,
      FiltersMixin,
      IntroJsMixin,
    ],
    components: {
      ActorDetailContributors,
      Checkbox,
      Avatar,
      AvatarList,
      Loading,
      EventCard,
      SimplifiedAnnouncementPreview,
      KnowledgeReport,
      HoverOverlayButton,
      SimplifiedImageCard,
      SimpleSocialItems,
      WithHoverActions,
      SimpleCard,
      SimpleArticlePreview,
      SimplifiedHero,
      Container,
      RichBodyContent,
      SimpleMap,
      Carousel,
      Slide,
      Navigation,
      ActorDetailSDGs,
      Modal,
      ActorDetailReport,
      ActorDetailNotes,
      SimplifiedFooter,
      ActorContactDetails,
      CardInfoSocial,
    },
  })
</script>

<style lang="scss">
  @import "../../../scss/variables";
  @import "../../../scss/mixins/icon-color";

  .simplified-actor-detail {
    padding-top: 20px;

    @media (max-width: $screen-md) {
      padding-top: 0;
    }

    .simple-article-preview__community-stats {
      display: none;
    }

    .category-list {
      @media (max-width: $screen-sm) {
        max-height: 60px;
        overflow: hidden;
      }
    }

    .container {
      padding: 0 40px;
      margin: 0 auto;
    }

    .featured-image-button-empty {
      background-color: white;
    }

    .featured-image-button {
      opacity: 1;
    }

    .simple-article-preview__body {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;

      p {
        font-size: 14px;
      }
    }

    .next-actors-card {
      background: white;
      padding: 15px;
      margin-bottom: 15px;
      height: calc(100% - 30px)
    }

    .next-actors-container {
      background-color: transparent;
      padding-left: 0;
      padding-right: 0
    }

    .title-count {
      background: white;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: inline-flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }

    .simplified-hero__title-container {
      padding: 0 40px;
      width: 100%;
      // color social media icons on the right
      @include iconColor(#fff);

      @media (max-width: $screen-md) {
        padding: 0;
      }
    }

    .simplified-hero__subtitle-container {
      padding: 0 40px;
      width: 100%;

      @media (max-width: $screen-md) {
        padding: 0;
      }
    }

    .report-image-container {
      width: calc(50% - 5px);
      height: 300px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      cursor: pointer;
      margin-right: 5px;
    }

    h2.h2 {
      font-size: 18px;
      text-transform: uppercase;
      margin-bottom: 30px;

      .button {
        margin-left: 30px;
      }
    }

    .open-report__container {
      width: 100%;
      position: relative;
      overflow: hidden;
      display: block;

      &:hover {
        .open-report__overlay {
          transform: translate3d(0, 0, 0);
        }
      }
    }

    .open-report__overlay {
      padding: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      background: rgba(255, 255, 255, 0.7);
      top: 0;
      transition: transform 300ms;
      transform: translate3d(0, 100%, 0);
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 4;
    }

    .connection__add-more {
      width: 100%;
      height: 100%;
      color: var(--primary-lightest);
      border: 2px dashed var(--primary);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 120px;
      cursor: pointer;
      background-color: white;
      position: relative;

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &.with-upgrade-overlay-cta {
        cursor: auto;
      }

      .add-more-cta__overlay {
        position: absolute;
        font-size: 14px;
        height: calc(100% + 1px);
        width: 100%;
        background: rgba(255, 255, 255, 0.7);
        top: 0;
        transition: transform 300ms;
        transform: translate3d(0, 100%, 0);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .lock-icon {
        position: absolute;
        top: 19px;
        right: 30px;
        fill: var(--primary);
        z-index: 1;
      }

      &:hover,
      &:focus {
        .add-more-cta__overlay {
          transform: translate3d(0, 0, 0);
        }
      }
    }

    .simplified-actor-detail__taxonomies {
      margin-bottom: 10px;

      .simplified-actor-detail__taxonomies_title {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 14px;
      }

      .simplified-actor-detail__taxonomies_value {
        font-size: 14px;
      }
    }

    .simplified-actor__images-container {
      width: calc(100% + 32px);
      margin-left: -16px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -32px
    }

    .facet-filters {
      display: flex;
      flex-direction: column;
    }

    .facet-filter {
      display: flex;
      height: 30px;
      overflow: hidden;

      .facet-filter__facet {
        display: flex;
        height: 30px;
        width: calc(100% - 270px);
      }

      &.facet-filter-sdg {
        height: 50px;

        .facet-filter__title {
          line-height: 50px;
        }
      }

      &.show-all {
        height: auto;
        overflow-y: visible;

        .facet-filter__facet {
          height: auto;
        }

        .facet-filter-sdg {
          height: 50px;
        }
      }

      .facet-filter__title {
        white-space: nowrap;
        padding-right: 5px;
        overflow: hidden;
        width: 200px;
        min-width: 200px;
        text-overflow: ellipsis;
        line-height: 30px;
        color: black;
        font-size: 14px;
      }
    }

    .facet-filter__show-more-container {
      width: 70px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 31px;
      margin-left: auto;
      padding-left: 5px;
      margin-top: auto;
    }

    .facet-filter__show-more-link {
      width: 100%;
      line-height: 31px;
      text-align: right;
      color: black;
      font-size: 12px;
    }

    .category {
      display: inline-block;
      margin-right: 0.25rem;
      margin-bottom: 0.25rem;
      line-height: 30px;

      &:last-child {
        margin-right: 0;
      }

      button {
        font-size: 11px;
        padding: 0.2rem 0.5rem;
        border: 0;
        font-family: $font-stack-primary;
        opacity: 1;
        transition: all 0.2s;
        background-color: white;
        color: black;
        border-radius: $default-border-radius-narrow;;
        cursor: pointer;

        svg path {
          fill: black;
        }

        &:focus {
          outline: none;
        }

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

      }

      &:focus button {
        outline: none;
      }

      .svg-icon {
        height: 14px;
        margin-left: -4px;

        svg {
          top: 2px;
        }
      }
    }

    .actor-detail__report {
      .actor-detail__report-container {
        color: var(--primary);
        background: transparent;

        .content-block {
          border: 0;
        }

        .content-block__header {
          &.has-bordered-bottom {
            border-bottom: 3px solid white;
          }
        }

        .content-block__body {
          .kr__field .form-group__label__text, .kr__field .range-slider__title, .ks__field .form-group__label__text, .search-actor-list, .kr__field .ks__subtitle, .kr__field .kr__subtitle, .ks__field .ks__subtitle, .ks__field .kr__subtitle, .ks__field .range-slider__title, .ks__field .range-slider__range-label, .ks__field .radio__label {
            color: $color-text-grey;
          }
        }
      }
    }

    .actor__report_call_countdown_container {
      display: flex;
      background: #FF3D00;
      color: white;
      padding: 6px 40px;
      line-height: 32px;

      .home__report_call_countdown {
        width: 33.33%;

        span {
          font-weight: 500;
        }
      }

      .home__report_call_text {
        width: 33.33%;
        text-align: center;
      }

      .home__report_call_submit_count {
        width: 33.33%;
        text-align: right;
      }

      .home__report_call_button {
        margin-left: auto;
        border: 1px solid white;
        height: 32px;
      }
    }
  }

  .actor-detail__image {
    height: 300px;
    background-size: cover;
    background-position: center;

    @media (max-width: $screen-md) {
      margin-bottom: 1rem;
    }
  }

  .actor-detail__next-actors-list {
    margin-top: 2rem;
    padding: 2rem 0;
  }

  .simplified-actor-social-icons {
    display: flex;
    align-items: center;

    .simple-social-item {
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .svg-icon--facebook-logo,
    .svg-icon--twitter-logo,
    .svg-icon--linkedin-logo {
      position: relative;
      top: -3px;
    }

    svg {
      path {
        fill: $color-primary !important;
      }
    }
  }

  .featured-image-button {
    #production-bookmark {
      fill: #fff;
    }
  }

  .actor-detail__pdf-container {
    max-width: 100%;
    height: 300px;
    text-align: center;

    .actor-detail__pdf-link {
      text-decoration: none;
      text-align: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;
    }

    .actor-detail__pdf-name {
      padding: 20px 0;
      font-size: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
      max-width: 100%;
    }

    .svg-icon {
      width: 50%;
      height: 100%;
    }
  }

  .actor-detail__video-container {
    position: relative;
    width: 100%;
    height: 100%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .simplified-hero {
    .sdg-actions-container {
      display: flex;
      position: absolute;
      bottom: 20px;
    }
  }

  .simplified-hero-logo__container {
    display: flex;
    justify-content: right;

    .simplified-hero-logo {
      position: relative;
      border-radius: 16px;
      margin: 0 0 0 auto;

      z-index: 3;
      height: 175px;
      width: 175px;
      background-color: white;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      color: black;
      display: flex;
      justify-content: center;
      align-items: center;

      // for hover effect
      transition: 0.5s;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        line-height: 14px;
        text-align: center;
        max-height: 56px;
        width: 100%;
      }

      .hover-text {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .5s ease;
        background-color: black;
        border-radius: 16px;
      }
    }
  }

  .simplified-contact-details__title {
    margin-top: 10px;
  }

  @media screen and (max-width: $screen-md) {
    .simplified-hero-logo__container {
      justify-content: center;

      .simplified-hero-logo {
        margin: 0 auto;
      }
    }
    .simplified-contact-details__container {
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
    }
    .simplified-contact-details__title {
      text-align: center;
    }
  }

  .simplified-hero-logo:hover .hover-text {
    opacity: 1;
  }

  .hover-text__title {
    color: white;
    font-size: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .container__added-padding {
    padding-bottom: 3rem !important;
  }

  .actor-detail__connections {
    .VueCarousel-inner {
      width: 100%
    }

    .carousel--minicards {
      width: calc(100%);
      margin-left: -32px;
    }

    .VueCarousel-slide {
      flex-direction: column;
      overflow: hidden;
    }

    .with-hover-actions {
      margin: 0 16px;
      height: 100%;
      width: 100%;
      max-width: 220px;
    }

    .with-hover-actions__actions {
      left: 1rem;
      right: auto;
    }
  }

  .actor-contributors {
    margin-left: auto;
    margin-right: 10px;
    padding: 10px;
    display: flex;
  }

  .cta-button {
    .contact-detail-button {
      border-radius: 8px;

      .svg-icon {
        // left toevoegen
        left: 12px;
        fill: white;
      }

      .button__label {
        padding-left: 28px !important;
      }
    }
  }

  .cta-button {
    .contact-email-button {
      background-color: white;
      color: var(--primary);
      border-radius: 8px;

      &:hover {
        background-color: #F7F7F7;

        .svg-icon path {
          fill: var(--primary);
        }
      }

      .svg-icon {
        fill: var(--primary);
        left: 12px;
      }

      .button__label {
        color: var(--primary);
        padding-left: 28px !important;
      }
    }
  }

  .cta-buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $screen-md) {
      flex-wrap: wrap;
    }

    .cta-button {
      display: inline-block;

      @media (max-width: $screen-md) {
        margin-bottom: 10px;
      }

      &:nth-child(2) {
        margin-left: 20px;
      }

      &:nth-child(3) {
        margin-left: 20px;
      }
    }

    .custom-cta-button {
      border-radius: 8px;
      border: 3px solid var(--primary);
      background-color: var(--primary-extra-lightest);

      &:hover {
        background-color: var(--primary-extra-lightest);
        color: var(--primary);

        .svg-icon {
          path {
            fill: var(--primary);
          }
        }
      }

      .svg-icon {
        left: 12px;
      }

      .button__label {
        color: black;
        padding-left: 28px !important;

        &:hover {
          color: var(--primary);
        }
      }
    }

  }

</style>
