<template>
  <div class="edit-resource-panel">
    <div class="fixed-heading">
      <div class="card__general side-panel__header">
        <div class="card__title side-panel__announcement-card__title side-panel__announcement-header-card__title">{{ $t('edit_resource_panel_title') }}</div>
        <div class="pull-right side-panel__announcement-header-button-container" style="text-align: right;">
          <ds-button
            v-if="! resourceCreated" icon="remove" variant="minimal" @click="showCancelActorEditConfirmationModal" size="small"
            class="side-panel__announcement-header-icon"
          />
          <ds-button v-else icon="remove" variant="minimal" @click="hidePreview()" size="small" class="side-panel__announcement-header-icon"/>
        </div>
      </div>
    </div>
    <div class="scrollable side-panel__scrollable__content has-padding">
      <div class="side-panel__announcement-form-container">
        <form>
          <form-group :label="$t('create_resource_title')" :errors="errors.title">
            <ds-textarea v-model="resource.title" autoresize :disabled="meta.resource.isWebsiteScreenshot"/>
          </form-group>
          <form-group :label="$t('create_resource_description')" :errors="errors.description" v-if="!['rss', 'blog', 'pr'].includes(meta.resource.media_type)">
            <ds-textarea v-model="resource.description" autoresize :disabled="meta.resource.isWebsiteScreenshot"/>
          </form-group>
          <form-group :label="$t('create_resource_tags')" :errors="errors.tags" v-if="!meta.resource.isWebsiteScreenshot">
            <AutocompleteTagInput
              :placeholder="$t('create_resource_tags_placeholder')" @input:raw="updateTags" :tags="this.resource.tags" @tagChanged="handleTagChanged"
              :options="richTagOptions"
              :allow-adding-to-dictionary="true"
            />
          </form-group>
          <form-group label="Linked Actors" v-if="canShowLinkedActors" :errors="errors.communities">
            <autocomplete-dropdown
              :model-value="actors"
              :options="allActorOptions"
              :do-search="fetchActorsDebounced"
              @update:modelValue="updateActors"
              :multiple="true"
              :allowClear="true"
              placeholder="Search for actors"
            />
          </form-group>

          <template v-if="!['rss', 'blog', 'pr'].includes(meta.resource.media_type)">
            <form-group :label="$t('create_resource_accessibility')" v-if="accessibilitySectionIsVisible">
              <radio-button
                v-model="resource.is_private"
                :disabled="featured || banner"
                name="reach"
                :label="$t('create_resource_accessibility_private')"
                :val="true"
              />

              <radio-button
                v-model="resource.is_private"
                name="reach"
                :label="$t('create_resource_accessibility_public')"
                :val="false"
              />
            </form-group>
            <form-group v-if="isFeaturable" :label="$t('create_resource_profile')">
              <checkbox v-model="featured" :label="$t('create_resource_featured_image')" inline style="width: 100%;"/>
              <span class="featured-image__help-text" v-if="isFeaturable">{{ $t('create_resource_featured_image_help') }}</span>
              <checkbox v-model="banner" :label="$t('create_resource_banner_image')" inline style="width: 100%;"/>
              <span class="featured-image__help-text" v-if="isFeaturable">{{ $t('create_resource_banner_image_help') }}</span>
            </form-group>
          </template>

          <!--<form-group label="Related actors" v-if="['rss', 'blog', 'pr'].includes(meta.resource.media_type) && !meta.resource.isWebsiteScreenshot">
            <AutocompleteActorInput
              @actorsChanged="updateMentionedActors"
              :actors="resource.mentions"
              @tagChanged="handleMentionChanged"
              placeholder="Mention actors"
              :options="actorOptions"
              v-if="isMember"
            />
          </form-group>-->

          <!-- <div class="side-panel-text-devider"><span> Resource content </span></div>
          <form-group v-if="resourceContentType !== false" :label="humanize(resourceContentType)">
            <div v-if="resourceContentType == 'image'">{{}}</div>
            <div v-if="resourceContentType == 'file'">{{}}</div>
            <div v-if="resourceContentType == 'link'">{{}}</div>
            <div v-if="resourceContentType == 'video_link'">{{}}</div>
          </form-group> -->
        </form>
      </div>
      <div>
        <FileThumbnail
          :filename="file.filename"
          :mimeType="file.mimeType"
          :path="file.path"
          :preview="file.preview"
          :isPrivate="file.isPrivate"
          :largePreview="true"
          v-if="this.file && this.file.filename && file.mimeType"
        />
      </div>
    </div>
    <action-bar editing="1" class="side-panel__announcement-bottom-section">
      <template v-if="! resourceCreated">
        <ds-button variant="secondary" size="extra-small" :label="$t('edit_resource_edit')" :disabled="busy" @click="save(resource)" v-if="! showApproveSaveButton"/>
        <ds-button variant="secondary" size="extra-small" label="Update & Approve resource" :disabled="busy" @click="saveAndApprove(resource)" v-else/>
      </template>
      <ds-button v-else class="create-resource-panel__success-button" variant="primary" size="extra-small" label="Ok" @click="hidePreview()"/>
      <span class="action-bar__message" v-if="resourceCreated">{{ $t('edit_resource_success') }}</span>
    </action-bar>
  </div>
</template>

<script>
  import Card from '../../components/Card/Card.vue'
  import ActionBar from '../../components/Form/ActionBar.vue'
  import Checkbox from '../Form/Checkbox.vue'
  import RadioButton from '../../components/Form/RadioButton.vue'
  import ImageInput from '../../components/Form/ImageInput.vue'
  import DsTextarea from '../../components/Form/DsTextarea.vue'
  import FileInput from '../../components/Form/FileInput.vue'
  import FileThumbnail from '../../components/FileThumbnail/FileThumbnail.vue'
  import AutocompleteTagInput from '../../components/Form/AutocompleteTagInput.vue'
  import AutocompleteActorInput from '../../components/Form/AutocompleteActorInput.vue'
  import AutocompleteDropdown from '../Dropdown/AutocompleteDropdown.vue'

  import { updateActorFileMetaData, updateEcosystemFileMetaData } from '../../api/files.js'
  import { actorNameAutoComplete } from '../../api/actors.js'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import KEYCODES from '../../constants/keycodes'
  import MODAL_IDS from '../../constants/modal-ids'
  import { humanize } from '../../constants/properties'

  import TagsMixin from '../../util/TagsMixin'
  import diffObjects from '../../util/diff-objects'

  import { ACTION_TYPES as ACTOR_ACTION_TYPES } from '../../store/modules/actors'
  import { getSearchResultsForQueryType } from '../../api/general-search.js'
  import { mapNewGlobalSearchResultToLegacyFormat } from '../../util/helpers'

  export default {
    name: 'edit-resource-panel',
    props: ['meta'],
    data () {
      return {
        originalResource: {
          tags: [],
        },
        resource: {
          tags: [],
          is_private: true,
        },
        resourceCreated: false,
        errors: {},
        busy: false,
        actorOptions: [],
        featured: false,
        banner: false,
        allActorOptions: [],
        actors: [],
      }
    },
    computed: {
      canShowLinkedActors () {
        return this.$route.name !== 'actor-detail' && this.isMember
      },
      accessibilitySectionIsVisible () {
        return !this.meta.resource.isWebsiteScreenshot && this.$store.getters.isPublisherEnabled
      },
      showApproveSaveButton () {
        return this.$store.getters.isMember && !(this.resource.approved === undefined || this.resource.approved)
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      isMember () {
        return this.$store.getters.isMember
      },
      isDeveloper () {
        return this.$store.getters.isDeveloper
      },
      resourceId () {
        return this.meta.resource.sql_media_id
      },
      actorDetail () {
        return this.$store.getters.detailActor
      },
      metaLink () {
        // Try to fetch the "url" from the meta properties
        var metaLink = ''

        this.meta.resource.meta.forEach(property => {
          if (property.key == 'url') {
            metaLink = property.value
          }
        })

        return metaLink
      },
      file () {
        if (!this.resource || !this.resource.title) {
          return {}
        }

        var file = {}

        file['filename'] = this.resource.title
        file['mimeType'] = this.resource.mimetype || this.resourceContentType
        file['path'] = this.resource.url || this.metaLink
        file['isPrivate'] = this.resource.is_private
        file['is_private'] = this.resource.is_private

        if (this.resource.preview) {
          file['preview'] = this.resource.url || this.resource.preview
        } else if (this.resource.meta && this.resourceContentType === 'image') {
          var previews = {
            preview_w50: '',
            preview_w320: '',
            preview_w766: '',
          }
          this.resource.meta.forEach(item => {
            switch (item.key) {
              case 'file_preview_w50':
                previews.preview_w50 = item.value
                break
              case 'file_preview_w320':
                previews.preview_w320 = item.value
                break
              case 'file_preview_w766':
                previews.file_preview_w766 = item.value
                break
            }
            file['preview'] = this.resource.url || previews.preview_w766 || previews.preview_w320 || previews.preview_w50
          })
        }

        return file
      },
      resourceContentType () {
        var resourceType = false

        this.meta.resource.meta.forEach(property => {
          if (property.key == 'file_type') {
            if (property.value == 'video_link') {
              resourceType = 'video_link'
            }
            if (property.value == 'link') {
              resourceType = 'link'
            }
            if (property.value.indexOf('image') > -1) {
              resourceType = 'image'
            }
            if (property.value.indexOf('application') > -1) {
              resourceType = 'file'
            }
          }
        })

        return resourceType
      },
      actorId () {
        const actorId = (this.meta.actor && this.meta.actor.id) || (this.meta.resource.actor_id && this.meta.resource.actor_id[0])

        if (!actorId) {
          return
        }

        if (typeof actorId === 'string') {
          return actorId
        }

        return actorId.id
      },
      isFeaturable () {
        return this.meta.isFromFileRepositoryPanel &&
          this.meta.resource.mimetype &&
          this.meta.resource.mimetype.startsWith('image') &&
          this.actorId &&
          this.meta.resource.preview &&
          !this.showApproveSaveButton
      },
    },
    methods: {
      async fetchActorsDebounced (query) {
        this.allActorOptions = []
        const results = []
        const data = await getSearchResultsForQueryType({ query, type: 'actors' })
        const combinedActorsWithoutDuplicates = mapNewGlobalSearchResultToLegacyFormat([...data.actors, ...data.products])

        combinedActorsWithoutDuplicates.forEach(actor => {
          const actorObject = {
            label: actor.name,
            value: actor.id,
          }
          results.push(actorObject)
        })

        this.allActorOptions = results
        return results
      },
      updateActors (actors) {
        this.actors = actors.map(item => {
          if (item.optionValue) {
            return item.optionValue
          }

          return {
            label: item.label,
            value: item.value,
          }
        })
      },
      checkVisibility () {
        if (this.featured || this.banner) {
          if (this.accessibilitySectionIsVisible) {
            this.resource.is_private = false
          }
        }
      },
      humanize,
      hidePreview (evt) {
        if (this.meta.isFromFileRepositoryPanel) {
          // isFromFileRepositoryPanel is a property sent from the file repository side panel which enables us to switch between side panels when editing the resources
          // If the user opened this side panel from the file respository side panel then we need to go back to that side panel once the editing is complete
          // We need the 'actorDetail' computed property in order to pass the updated actor into the respository side panel
          this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'actor-file-repository', metaData: this.actorDetail })
        } else {
          this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
        }
      },
      resourceDiff () {
        return diffObjects(this.resource, this.originalResource)
      },
      showCancelActorEditConfirmationModal () {
        if (this.resourceDiff()) {
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, { body: 'You have unsaved changes, are you sure you want to cancel?' })
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CONFIRMATION)
        } else {
          this.hidePreview()
        }
      },
      updateMentionedActors (mentionedActors) {
        this.resource.actor_id = mentionedActors
      },
      handleMentionChanged (mention) {
        this.actorOptions = []

        actorNameAutoComplete(mention)
          .then(suggestions => {
            this.actorOptions = []

            if (suggestions && suggestions.length > 0) {
              suggestions.forEach(suggestion => {
                this.actorOptions.push({
                  id: suggestion.id,
                  name: suggestion.name,
                })
              })
            }
          })
          .catch()
      },
      updateTags (tags) {
        this.resource.tags = tags.map(item => {
          if (item.optionValue) {
            return item.optionValue
          }

          // New keywords are identified by having both their value and text the same value
          return {
            label: item.text,
            value: item.text,
            text: item.text, // The autocomplete input library only supports "text", map it to the object that it expects, while also keeping the model our internal logic expects (=label)
          }
        })
      },
      handleTagChanged (tag) {
        // Update the options according to the given tag
        this.updateRichTagOptions(tag)
      },
      saveAndApprove (formObject) {
        this.errors = {}
        this.busy = true

        if (!this.resourceId) {
          return
        }

        updateEcosystemFileMetaData(this.resourceId, formObject)
          .then(value => {
            // This save and approve will only occur in the curation panel
            this.busy = false
            this.$bus.emit('refreshCurationResults')
            this.resourceCreated = true
          })
          .catch(errors => {
            this.errors = errors
            this.hidePreview()
          })
      },
      async save (formObject) {
        this.errors = {}
        this.busy = true

        if (!this.resourceId && !this.meta.resource.isWebsiteScreenshot) {
          this.busy = false
          return
        }

        if (this.actors.length > 0) {
          const selectedActorids = []

          this.actors.forEach(actor => {
            selectedActorids.push({ id: actor.value })
          })

          formObject.linked_actor_id = selectedActorids
          this.resource.linked_actor_id = selectedActorids
        }

        if (!['rss', 'blog', 'pr'].includes(this.meta.resource.media_type) && this.actorId) {
          try {
            if (!this.meta.resource.isWebsiteScreenshot) {
              await updateActorFileMetaData(this.actorId, this.resourceId, this.resource)
            }

            // If the resource is marked as featured, and the url's between the current image and this image don't match, update the featured image
            if (this.featured && this.meta.resource.preview !== this.actorDetail.featured_image_url) {
              this.setFeaturedImage(this.meta.resource.preview)
            } else if (!this.featured && this.meta.resource.preview === this.actorDetail.featured_image_url) {
              this.setFeaturedImage(null)
            }

            if (this.banner && this.meta.resource.preview !== this.actorDetail.banner_image_url) {
              this.setBannerImage(this.meta.resource.preview)
            } else if (!this.banner && this.meta.resource.preview === this.actorDetail.banner_image_url) {
              this.setBannerImage(null)
            }

            if (this.meta.actor) {
              await this.$store.dispatch('ACTORS/FETCH_ACTOR_DETAIL', true)
            }

            this.$bus.emit('fetchNewResources')

            this.busy = false
            this.resourceCreated = true
          } catch (errors) {
            this.errors = errors
          }
        } else {
          updateEcosystemFileMetaData(this.resourceId, formObject)
            .then(value => {
              this.$bus.emit('fetchNewResources')
              this.resourceCreated = true
            })
            .catch(errors => {
              this.errors = errors
            })
            .then(() => {
              this.busy = false
            })
        }
      },
      async setBannerImage (filePath) {
        const change = {
          banner_image_url: filePath,
        }

        try {
          await this.$store.dispatch(ACTOR_ACTION_TYPES.UPDATE_ACTOR, { id: this.actorId, data: change })
        } catch (errors) {
          this.errors = errors
        }
      },
      async setFeaturedImage (filePath) {
        const change = {
          featured_image_url: filePath,
        }

        try {
          await this.$store.dispatch(ACTOR_ACTION_TYPES.UPDATE_ACTOR, { id: this.actorId, data: change })
        } catch (errors) {
          this.errors = errors
        }
      },
    },
    beforeUnmounted () {
      this.$bus.off('confirmAction')
    },
    mounted () {
      this.$bus.on('confirmAction', () => {
        this.hidePreview()
      })

      window.addEventListener('keyup', (e) => {
        if (e.keyCode === KEYCODES.ESC) {
          this.hidePreview()
        }
      })

      // We create copies of the 'meta' property object in order to be able to edit the values
      this.originalResource = Object.assign({}, this.meta.resource)
      this.resource = Object.assign({}, this.meta.resource)

      // We remove irrelevant properties that cannot be used when saving the edited resource
      delete this.resource.date
      delete this.resource.media_type
      delete this.resource.sql_media_id
      delete this.resource.score

      // Non-actor related content can hold actor_id properties
      if (this.resource.actor_name && !['rss', 'blog', 'pr'].includes(this.resource.media_type)) {
        delete this.resource.actor_id
        delete this.resource.actor_name
      }

      // RSS article's body can't be changed
      if (['rss', 'blog', 'pr'].includes(this.resource.media_type)) {
        delete this.resource.body
      }

      // We get the value from the media_type in order to determine if the resource is private or public.
      // The value can either be 'private_resource' or 'public_resource'
      if (this.meta.resource.media_type == 'private_resource') {
        this.originalResource.is_private = true
        this.resource.is_private = true
      } else if (this.meta.resource.media_type == 'public_resource') {
        this.originalResource.is_private = false
        this.resource.is_private = false
      }

      // To make the tags property responsive, make sure it's defined on the object
      if (!this.resource.tags) {
        this.resource.tags = []
      }

      // The property for the description has to be 'description' and not 'body'
      if (this.originalResource.body) {
        // If there is the a 'body' property we add the 'description' with the same value and then delete the 'body' property
        this.originalResource.description = this.meta.resource.body
        delete this.originalResource.body
      }

      if (this.resource.body) {
        // If there is the a 'body' property we add the 'description' with the same value and then delete the 'body' property
        this.originalResource.description = this.meta.resource.body
        delete this.originalResource.body
        delete this.resource.body
      }

      if (this.isFeaturable) {
        this.featured = (this.meta.resource.preview === this.actorDetail.featured_image_url)
        this.banner = (this.meta.resource.preview === this.actorDetail.banner_image_url)
      }

      if (this.resource.linked_actor_id && this.resource.linked_actor_id.length > 0) {
        this.actors = this.resource.linked_actor_id.map(item => {
          if (item.optionValue) {
            return item.optionValue
          }

          return {
            label: item.name,
            value: item.id,
          }
        })
      }
    },
    watch: {
      featured () {
        this.checkVisibility()
      },
      banner () {
        this.checkVisibility()
      },
    },
    mixins: [TagsMixin],
    components: {
      ActionBar,
      Card,
      DsTextarea,
      FileInput,
      FileThumbnail,
      ImageInput,
      RadioButton,
      AutocompleteTagInput,
      AutocompleteActorInput,
      Checkbox,
      AutocompleteDropdown,
    },
  }
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  .featured-image__help-text {
    display: flex;
    font-size: 12px;
    color: $color-text-grey-light;
  }
</style>
