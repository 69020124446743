<template>
  <tr class="row-curation" @click="preview">
    <td>{{ content.title }}</td>
    <td>{{ content.description }}</td>
    <td>{{ content.user }}</td>
    <td class="text-right" :title="date(content.created_at)">{{ date(content.created_at) }}</td>
    <td>
      <ds-button class="row-curation__verify" icon="check" @click.stop="acceptContent" label="Approve" />
    </td>
    <td>
      <ds-button class="row-curation__verify" icon="trash" @click.stop="denyContent" label="Remove" />
    </td>
    <td>
      <ds-button class="row-curation__verify" icon="edit" @click.stop="editAndAcceptContent" label="Edit & Approve" />
    </td>
  </tr>
</template>

<script>


  import { acceptContent, denyContent } from '../../api/knowledge-base.js'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: ['content'],
    data () {
      return {
        message: ''
      }
    },
    methods: {
      preview () {
        // Not used
        //this.$router.push('/announcements/' + this.content.id)
      },
      editAndAcceptContent () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {'component' : 'edit-resource-panel', metaData: {resource: this.content}});
      },
      acceptContent () {
        this.message = '...';

        acceptContent(this.content.id)
          .then(() => {
            this.$bus.emit('refreshCurationResults');
          })
          .catch(errors => {
            this.message = errors && errors.message || 'Could not approve the content.';
          })
      },
      denyContent () {
        this.message = '...';

        denyContent(this.content.id)
          .then(() => {
            this.$bus.emit('refreshCurationResults');
          })
          .catch(errors => {
            this.message = errors && errors.message || 'Could not verify the claim.';
          })
      },
      date (str) {
        return str ? str.slice(0, 19).replace('T', ' ') : '';
      }
    },
    watch: {
      content () {
        // Clear the message when a new actor is assigned to this row
        this.message = '';
      }
    }
  })
</script>

<style>
  .row-curation td {
    line-height: 26px !important;
  }

  .row-curation .button {
    margin: -6px -10px !important;
    display: block;
    width: calc(100% + 20px);
    font-size: 12px;
  }

  .row-curation .row-curation__onhold {
    background: none;
    color: #999;
  }

  .row-curation__ok {
    width: 70px !important;
    margin-right: -10px !important;
    float: right;
  }

  .row-curation__remove {
    width: 36px !important;
    margin-right: -10px !important;
  }

  .row-curation--deleted {
    color: #f99;
  }
</style>
