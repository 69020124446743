<template>
  <div class="page-sign-in" v-if="closeable && !isPnG">
    <finalise-onboarding type="login" :is-closable="closeable" :is-modal="true" @close="handleClose"/>
  </div>
  <div v-else-if="isPnG" style="height: 100%; overflow-y: auto">
    <static-home-PnG/>
  </div>
  <finalise-onboarding v-else type="login" :is-closeable="closeable" :is-modal="true" @close="handleClose"/>
  <div class="page-sign-up page-authentication" :style="style" v-else>
    <div :style="backgroundStyle">
      <div class="home__titlebar">
        <div class="home__logo">
          <img :src="$store.state.config.logo_url">
        </div>
        <div class="home__title" style="margin-top:1.7em; margin-left:1em;">
          {{ $store.state.config.title }}
        </div>
        <login-modal :is-closeable="closeable" @close="$router.push('/')"></login-modal>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  import LoginModal from '../components/Modals/LoginModal.vue'
  import { hexToRgb } from '../util/color.js'
  import StaticHomePnG from '../components/Dashboard/StaticHomePnG.vue'
  import FinaliseOnboarding from './Onboarding/FinaliseOnboarding.vue'

  export default defineComponent({
    components: {
      StaticHomePnG,
      LoginModal,
      FinaliseOnboarding
    },
    methods: {
      handleClose() {
        this.$router.push('/')
      }
    },
    computed: {
      isLoggedIn() {
        return this.$store.getters.isLoggedIn
      },
      isNewProfileEnabled() {
        return this.$store.getters.isNewProfileEnabled
      },
      isPnG() {
        return this.$store.getters.isPnG
      },
      // Return the style for the sign up page when the sign up page is taking up the entire screen (i.e. part of OAuth or private ecosystem Log In)
      style() {
        var cover = this.$store.state.config.coverSrc ? this.$store.state.config.coverSrc : '/images/covers/skyline.jpeg'

        return 'background-image: url(' + cover + '); background-color: #F7F7F7; background-repeat: no-repeat; background-size: cover;'
      },
      // Return the overlay style with the primary colour based on the ecosystem
      backgroundStyle() {
        return 'background: rgba(' + hexToRgb(this.$store.state.config.primaryColor) + ', 0.5); overflow: hidden; height: 100%; z-index: 2;'
      },
      closeable() {
        // The modal is not closeable if either the ecosystem does not allow anonymous users
        // or the login is part of an oauth request being resolved
        return this.$store.getters.hasAccessToPublisher && !!this.$store.state.config.allow_anonymous && !window.oauth_request
      }
    },
    mounted() {
      if (this.isLoggedIn) {
        this.$router.push('/')
      }
    },
  })
</script>
<style lang="scss" scoped>
</style>
