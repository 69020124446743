<template>
  <div class="actor-suggestions">
    <div class="fixed-heading">
      <div class="card__general side-panel__header">
        <div class="card__title side-panel__actor-suggestions-card__title side-panel__actor-suggestions-header-card__title">Discovery agents</div>
        <div class="pull-right side-panel__announcement-header-button-container" style="text-align: right;">
          <ds-button icon="remove" variant="minimal" @click="hidePreview" size="small" class="side-panel__announcement-header-icon"/>
        </div>
      </div>
    </div>
    <div class="scrollable side-panel__scrollable__content has-padding" style="padding-bottom: 150px">
      <template v-if="!data.agents || data.agents.length === 0">
        <agents-info-block/>
      </template>
      <template v-for="agent of agents" :key="agent.name">
        <agent-card
          :agent="agent"
          :actor="actor"
          :suggestions="agent.suggestions"
          @update:suggestions="agent.suggestions = $event"
          @toggleEnabled="toggleEnabled(agent)"
        />
      </template>
    </div>
  </div>
</template>

<script>
  import AgentsInfoBlock from './Agents/AgentsInfoBlock.vue'
  import AgentCard from './Agents/AgentCard.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../../store/modules/actors'

  import { fetchAgentStatus } from '../../api/actors.js'

  import CompanyMixin from '../../util/CompanyMixin'

  export default {
    data () {
      return {
        agentStatus: [],
        agents: [],
        interval: null,
      }
    },
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      actor () {
        return this.$store.state.actors.detail.data
      },
      company () {
        // Required for the company mixin, we need to do a small refactor once there's time
        return this.actor
      },
    },
    methods: {
      hidePreview (evt) {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      toggleEnabled (agent) {
        agent.isEnabled = !agent.isEnabled

        // Set loading to true as a demo
        if (agent.isEnabled) {
          agent.isLoading = true
        }
      },
      prepareAgents () {
        // Add the administrator agent
        if (this.canAdministratorAgentBeUsed) {
          this.agents.push({
            displayName: 'Administrator agent',
            name: 'administrator_agent',
            action: 'get_administrators',
            description: 'Start this agent to add actors that administer this actor.',
            isEnabled: true,
            isLoading: false,
            loadingPercentage: null,
            status: 'idle',
            meta: {},
            suggestions: [],
          })
        }

        // Add the investor agent
        if (this.canInvestorAgentBeUsed) {
          const description = 'Start this agent to add actors that have invested in this actor.' // description = 'Start this agent to add actors that have invested in this actor.';
          const descriptionInvestments = 'Start this agent to add actors that received investments from this actor.'

          this.agents.push({
            displayName: 'Investor agent',
            name: 'investor_agent',
            action: 'get_investors',
            description: description,
            isEnabled: true,
            isLoading: false,
            loadingPercentage: null,
            status: 'idle',
            meta: {},
            suggestions: [],
          })

          this.agents.push({
            displayName: 'Investments agent',
            name: 'investments_agent',
            action: 'get_investments',
            description: descriptionInvestments,
            isEnabled: true,
            isLoading: false,
            loadingPercentage: null,
            status: 'idle',
            meta: {},
            suggestions: [],
          })
        }

        // Add the founder agent
        if (this.canFoundingTeamAgentBeUsed) {
          this.agents.push({
            displayName: 'Founder agent',
            name: 'founder_agent',
            action: 'get_founders',
            description: 'Start this agent to add the founders of this actor.',
            isEnabled: true,
            isLoading: false,
            loadingPercentage: null,
            status: 'idle',
            meta: {},
            suggestions: [],
          })
        }

        // Add the projects agent
        if (this.canProjectAgentBeUsed) {
          this.agents.push({
            displayName: 'Projects agent',
            name: 'project_agent',
            action: 'get_projects',
            description: 'Start this agent to find projects & partners this actor is involved with.',
            isEnabled: true,
            isLoading: false,
            loadingPercentage: null,
            status: 'idle',
            meta: {},
            suggestions: [],
          })
        }

        // Add the current team agent
        if (this.canCurrentTeamAgentBeUsed) {
          this.agents.push({
            displayName: 'Team agent',
            name: 'team_agent',
            action: 'get_team',
            description: 'Start this agent to add the team members of this actors.',
            isEnabled: true,
            isLoading: false,
            loadingPercentage: null,
            status: 'idle',
            meta: {},
            suggestions: [],
          })
        }

        // Add the lookalike agent
        if (this.canLookalikeAgentBeUsed) {
          this.agents.push({
            displayName: 'Look-alike agent',
            name: 'lookalike_agent',
            action: 'get_look_a_likes',
            description: 'Find look-alikes based on:',
            isEnabled: true,
            isLoading: false,
            loadingPercentage: null,
            status: 'idle',
            meta: {},
            suggestions: [],
          })
        }

        // Add the contacts agent
        if (this.canContactsAgentBeUsed) {
          this.agents.push({
            displayName: 'Contacts agent',
            name: 'contacts_agent',
            action: 'get_contacts',
            description: 'Start this agent to add personal contact information to this actor.',
            isEnabled: true,
            isLoading: false,
            loadingPercentage: null,
            status: 'idle',
            meta: {},
            suggestions: [],
          })
        }

        // Add the QA staff agent, available under a feature flag
        if (this.canQaStaffAgentBeUsed && this.$store.getters.isDeveloper) {
          this.agents.push({
            displayName: 'News agent',
            name: 'news_agent',
            action: 'get_news_info',
            description: 'Extract information from news content.',
            isEnabled: true,
            isLoading: false,
            loadingPercentage: null,
            status: 'idle',
            meta: {},
            suggestions: [],
          })
        }

        if (this.canAcquisitionAgentBeUsed) {
          this.agents.push({
            displayName: 'Acquisition agent',
            name: 'acquisition_agent',
            action: 'get_acquisitions',
            description: 'Start this agent to search for companies that the actor acquired or the company that acquired this actor.',
            isEnabled: true,
            isLoading: false,
            loadingPercentage: null,
            status: 'idle',
            meta: {},
            suggestions: [],
          })
        }

        if (this.canPortfolioCrawlerAgentBeUsed) {
          this.agents.push({
            displayName: 'Portfolio agent',
            name: 'portfolio_agent',
            action: 'crawl_portfolio',
            description: 'Start this agent to search for partners, startups and collaborators from this actor',
            isEnabled: true,
            isLoading: false,
            loadingPercentage: null,
            status: 'idle',
            meta: {},
            suggestions: [],
          })
        }

        const filteredAgents = this.data.agents
        if (filteredAgents && filteredAgents.length > 0) {
          this.agents = this.agents.filter(agent => {
            return filteredAgents.filter(filteredAgentName => {
              return filteredAgentName === agent.name
            }).length > 0
          })
        }
      },
      updateAgents () {
        fetchAgentStatus(this.actor.id)
          .then(response => {
            var updatedAgents = this.agents
            var statusses = response

            var updateActorDetail = false

            updatedAgents.forEach(agent => {
              if (!statusses[agent.action]) {
                return
              }

              if (
                agent.status != 'finished' && statusses[agent.action].status == 'finished' &&
                statusses[agent.action].meta &&
                statusses[agent.action].meta.relationships_changed &&
                statusses[agent.action].meta.relationships_changed > 0
              ) {
                updateActorDetail = true
              }

              agent.status = statusses[agent.action].status
              agent.updated_at = statusses[agent.action].updated_at
              agent.meta = statusses[agent.action].meta
              agent.added_count = statusses[agent.action].added_count

              // All agents that add new actors will do so in the form of suggestions
              var suggestions = this.transformAgentMetaToSuggestions(agent, agent.meta)

              var oldSuggestions = agent.suggestions || []

              // Update the new suggestions
              agent.suggestions = suggestions || []

              if (oldSuggestions && oldSuggestions.length != suggestions.length) {
                updateActorDetail = true
              }
            })

            this.agents = updatedAgents

            if (updateActorDetail && this.$route.params.id) {
              this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_DETAIL, this.$route.params.id)
            }
          })
          .catch()
      },
      transformAgentMetaToSuggestions (agent, meta) {
        const uniqueArray = []
        const uniqueNameArray = []
        meta.map(function (crawledMeta) {
          var value = crawledMeta.value || {}
          var meta = crawledMeta.meta || {}

          var description = ''

          if (agent.name == 'contacts_agent' && value.description && value.description.length > 0) {
            description += value.description
          } else if (agent.name == 'contacts_agent' && value.employment && (value.employment.title || value.employment.role)) {
            var department = ''
            var title = ''

            if (value.employment.title && value.employment.title.length > 0) {
              description += 'Title: ' + value.employment.title + ','
            }

            if (value.employment.role && value.employment.role.length > 0) {
              description += ' Department: ' + value.employment.role
            }
          } else if (value.description) {
            description += ' ' + value.description
          } else {
            description = ''
          }

          description = description.replace(/(^,)|(,$)/g, '')

          let path = (meta && meta.path) || ''
          path = path.replace(/^\/+/g, '')

          let url = (meta && meta.url) || ''
          url = url.replace(/\/$/, '')

          const crawledObject = {
            name: value.name,
            suggestionId: crawledMeta.id,
            description: description,
            state: 'suggested',
            website: agent === 'crawl_portfolio' ? value.url : '',
            linkedin: value.linkedin ? value.linkedin : null,
            file_id: meta[0] && meta[0].file_id ? meta[0].file_id : null,
            source: meta.url ? url + '/' + path : '',
            isAdded: value.isAdded,
            actorId: value.actorId,
            added_count: agent.added_count,
          }

          if (!uniqueNameArray.includes(value.name)) {
            uniqueNameArray.push(value.name)
            uniqueArray.push(crawledObject)
          }
        })

        return uniqueArray
      },
    },
    mounted () {
      this.prepareAgents()
      this.updateAgents()

      // Refresh the agents status every 20 seconds
      this.interval = setInterval(() => this.updateAgents(), 20000)
    },
    beforeUnmount () {
      // Make sure to kill the infinite loop function
      clearInterval(this.interval)
    },
    mixins: [CompanyMixin],
    components: {
      AgentCard,
      AgentsInfoBlock,
    },
  }
</script>
