export const propertyGroups = {
  'Minimum': [
    // 'activities_description',
    'actor_type',
    'category',
    'claim',
    'claimable',
    'company_type',
    'deleted',
    'description',
    'featured_image_url',
    'id',
    'location',
    'logo',
    'name',
    'twitter_bio',
    'website_screenshot',
    'website_screenshot_upload',
  ],
  'Base': [
    'address',
    'company_number',
    'main_company',
    'maturity',
    'membership',
    'other_locations',
    'other_urls',
    'subsidiaries',
    'technology',
    'url',
    'vat_number',
  ],
  'AdditionalInformation': [
    'additional_information',
  ],
  'Contact': [
    'crunchbase',
    'facebook',
    'instagram',
    'twitter',
    'youtube',
    'linkedin',
    'vimeo',
    'youtube',
    'embedded_video_url',
  ],
  'ContactDetails': [
    'email',
    'phone',
  ],
  'Business Admin': [
    'exit',
    'exit_date',
    'founding_date',
    'juridical_form',
    'juridical_situation',
    'legal_form',
    'stage',
    'status',
    'stop_date',
  ],
  'Business Activity': [
    'employees',
    'industries',
    'industry_codes',
    'is_sme',
    'nace_codes',
    'not_for_profit',
    'tags',
    'domains',
    'sustainability_goal',
    'activities',
    'subIndustries',
    'expertises',
    'motivations',
    'readiness_levels',
    'business_aspects',
    'aspects',
    'process_steps',
    'product_features_a',
    'product_features_b',
    'product_features_c',
  ],
  'Service Offering': [
    'type',
    'market_geo',
    'market_verticals',
    'services',
  ],
  'Offering': [
    'activities_description',
    'market',
    'offerings',
    'patent_count',
  ],
  'Person': [
    // 'expertise',
    // 'function',
    'role',
    'seniority',
  ],
  'Product / Project / Publication': [
    'product',
    'project',
    'publication',
  ],
  'Financials': [
    'assets',
    'financials',
    'rona',
  ],
  'Funding': [
    'funding_rounds',
    'funding_structure',
    'funding_stage',
    'grants',
    'investors',
    'accelerated',
    'accelerated_by',
    'invested',
    'invested_by',
    'original_total_funding',
    'original_total_funding_currency',
    'total_funding',
  ],
  'Competitive Scores': [
    'business_size_score',
    'current_score',
    'previous_score',
    'country_scores',
    'seo_score',
    'traction_score',
    'twitter_details',
  ],
  /* 'Notes': [
    'quick_notes'
  ], */ // Deprecated
  'CRM': [
    'funnel_stage',
  ],
  'Scores': [
    'custom_score',
  ],
}

export const simplifiedFilterControlOptions = [
  {
    value: 'category',
    label: 'Category',
  }, {
    value: 'membership',
    label: 'Memberships',
  }, {
    value: 'stage',
    label: 'Growth Stage',
  }, {
    value: 'funnel_stage',
    label: 'CRM Stage',
  }, {
    value: 'industries',
    label: 'Industries picker',
  }, {
    value: 'technology',
    label: 'Technology picker',
  }, {
    value: 'sustainability_goal',
    label: 'Sustainability Goals',
  }, {
    value: 'domains',
    label: 'Domain picker',
  }, {
    value: 'activities',
    label: 'Activities',
  }, {
    value: 'expertises',
    label: 'Expertises',
  }, {
    value: 'motivations',
    label: 'Motivations',
  }, {
    value: 'product_features_a',
    label: 'Product Feature A',
  }, {
    value: 'product_features_b',
    label: 'Product Feature B',
  }, {
    value: 'product_features_c',
    label: 'Product Feature C',
  }, {
    value: 'maturity',
    label: 'Maturity',
  },
]

export const filterControlOptions = [
  /* {
    value: 'portfolio',
    label: 'Portfolio'
  }, */ {
    value: 'category',
    label: 'Category',
  }, {
    value: 'membership',
    label: 'Memberships',
  }, {
    value: 'country-focus',
    label: 'Market focus',
  }, {
    value: 'stage',
    label: 'Growth Stage',
  }, {
    value: 'funnel_stage',
    label: 'CRM Stage',
  }, {
    value: 'custom_score',
    label: 'Custom Score',
  }, {
    value: 'industries',
    label: 'Industries picker',
  }, {
    value: 'technology',
    label: 'Technology picker',
  }, {
    value: 'sustainability_goal',
    label: 'Sustainability Goals',
  }, {
    value: 'market',
    label: 'Target markets',
  }, {
    value: 'domains',
    label: 'Domain picker',
  }, /* {
  value: 'company_type',
  label: 'Office type'
// }, {
//   value: 'keyword',
//   label: 'Search keywords'
  }, */{
    value: 'mentions',
    label: 'Mentions',
  }, /* {
  value: 'score',
  label: 'DataScouts Score'
  }, */{
    value: 'funding',
    label: 'Funding quarter',
  }, {
    value: 'exit_round',
    label: 'Exit Round',
  }, {
    value: 'founding_date',
    label: 'Founding year',
  }, {
    value: 'reports',
    label: 'Reports',
  }, {
    value: 'employees',
    label: 'Employees',
  }, {
    value: 'activities',
    label: 'Activities',
  }, {
    value: 'expertises',
    label: 'Expertises',
  }, {
    value: 'motivations',
    label: 'Motivations',
  }, {
    value: 'readiness_levels',
    label: 'Readiness Levels',
  }, {
    value: 'business_aspects',
    label: 'Business Aspects',
  }, {
    value: 'process_steps',
    label: 'Process Steps',
  }, {
    value: 'aspects',
    label: 'Aspects',
  }, {
    value: 'type',
    label: 'Type',
  },
  {
    value: 'maturity',
    label: 'Maturity',
  },
  {
    value: 'relationship_count',
    label: 'Total Connections',
  },
  {
    value: 'revenue',
    label: 'Revenue',
  },
  {
    value: 'possible_duplicate',
    label: 'Duplicate',
  },
  {
    value: 'product_features_a',
    label: 'Product Feature A',
  },
  {
    value: 'product_features_b',
    label: 'Product Feature B',
  },
  {
    value: 'product_features_c',
    label: 'Product Feature C',
  },
  /* {
    value: 'is_zombie',
    label: 'Zombie'
  }, */
  /* {
    value: 'claimed',
    label: 'Claimed',
  }, */
]

export const viewAnalyticsOptions = [
  'accelerator',
  'activities',
  'sustainability_goal',
  'category',
  'cities',
  'countries',
  // 'domains',
  'employees_size_code',
  'financials_ebitda',
  'financials_equity',
  'financials_operating_income',
  'funding',
  'histogram',
  'industries',
  'investor',
  'maturity',
  'patent_count',
  'stop_date',
  'subIndustries',
  'type',
  'vacancy_count',
]

export const viewDashboardOptions = [
  { value: 'home', icon: 'home', label: 'Home' },
  { value: 'scores-list', icon: '1', label: 'Scores list' },
  { value: 'analytics', icon: 'view-dashboard', label: 'Analytics' },
  // { value: 'news', icon: 'rss', label: 'Social Media Monitoring' },
  { value: 'connectedness', icon: 'view-graph', label: 'Connections' },
  { value: 'sankey', icon: 'shuffle-variant', label: 'Funnel' },
  { value: 'compare', icon: 'chart-gantt', label: 'Compare' },
  { value: 'digital-footprint', icon: 'digital-footprint', label: 'Digital Footprint' },
  { value: 'business-size', icon: 'business-size', label: 'Business Size' },
  // { value: 'buzz', icon: 'buzz', label: 'Social Buzz' },
  { value: 'media-buzz', icon: 'buzz', label: 'Continuous Monitoring' },
  { value: 'funding-list', icon: 'funding-icon', label: 'Funding List' },
  { value: 'knowledge-base', icon: 'repository-icon', label: 'Knowledge Base' },
  { value: 'explore-content', icon: 'tag', label: 'Exploration' },
  { value: 'message_dashboard', icon: 'comment', label: 'Announcements & Events' },
  { value: 'challenges', label: 'Challenges' },
  { value: 'dynamic-dashboard', icon: 'chart-pie', label: 'Dynamic Dashboard' },
  // { value: 'instant-monitoring', faIcon: 'tag', label: 'Instant Monitoring' },
]

export const viewListOptions = [
  'activities',
  'category',
  'city',
  'created_at',
  'completeness',
  'country',
  'claimed',
  'domains',
  'founding_date',
  'funding_date',
  'current_employees',
  'custom_score',
  'vacancy_count',
  'funding_stage',
  'funnel_stage',
  'industries',
  // 'is_zombie',
  'lastFundingDate',
  'lastFundingType',
  'maturity',
  'market',
  'membership',
  'industry_codes',
  'tags',
  'private_tags',
  'patent_count',
  'relationship_count',
  'product_relationship_count',
  // 'possible_duplicate',
  'stage',
  'subIndustries',
  'sustainability_goal',
  'product_features_a',
  'sub_product_features_a',
  'product_features_b',
  'sub_product_features_b',
  'product_features_c',
  'sub_product_features_c',
  'technology',
  'total_funding',
  'updated_at',
  'zip',
]

export const actorTypeNav = [
  { actor_type: 'LegalEntity', icon: 'domain', label: 'Organizations' },
  { actor_type: 'Person', icon: 'user', label: 'People' },
  // { actor_type: 'Community', icon: 'community', label: window.config.name === 'wear' ? 'Projects' : 'Communities' }
]

export const marketOptions = [
  'Global',
  'North America',
  'Canada',
  'the United States of America',
  'Mexico',
  'Latin America and The Carribean',
  'Latin America',
  'The Caribbean',
  'EMEA',
  'Europe',
  'Middle East',
  'Africa',
  'Asia Pacific and Japan',
  'Asia Pacific',
  'Japan',
]

export const marketCountryOptions = [
  'Australia',
  'Austria',
  'Belgium',
  'Bulgaria',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Ireland',
  'Italy',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Netherlands',
  'New Zealand',
  'Poland',
  'Portugal',
  'Romania',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
]

export const seniorityOptions = [
  'Director',
  'Executive',
  'Junior',
  'Manager',
  'Senior',
]

export const departmentOptions = [
  'Communications',
  'Consulting',
  'Customer Service',
  'Education',
  'Engineering',
  'Executive',
  'Finance',
  'Human Resources',
  'Information Technology',
  'IT',
  'Legal',
  'Management',
  'Marketing',
  'Operations',
  'Product',
  'Public Relations',
  'Recruiting',
  'Recruiting',
  'Research',
  'Sales',
  'Support',
]

export const taxonomyOptions = [
  'category',
  'membership',
  'activities',
  'technology',
  'domains',
  'product_features_a',
  'product_features_b',
  'product_features_c',
  'stage',
  'funnel_stage',
  'industries',
  'expertises',
  'motivations',
  'readiness_levels',
  'process_steps',
  'business_aspects',
  'aspects',
]

export const actorTaxonomyProperties = [
  'category',
  'membership',
  'activities',
  'technology',
  'domains',
  'product_features_a',
  'product_features_b',
  'product_features_c',
  'sub_product_features_a',
  'sub_product_features_b',
  'sub_product_features_c',
  'stage',
  'funnel_stage',
  'industries',
  'subIndustries',
  'sub_industries',
  'expertises',
  'motivations',
  'readiness_levels',
  'process_steps',
  'business_aspects',
  'aspects',
]

export const announcementTaxonomyProperties = [
  'announcement_categories',
  'event_categories',
]

export const knowledgeBaseContentTypes = [
  'rss',
  'pr',
  'blog',
  'crunchbase_pro',
  'announcement',
  'resource',
  'public_resource',
  'private_resource',
  'vacancy',
  'patent',
  'product_service_offering',
  'evenement',
  'periodiek',
  'kennistool',
  'publicatie',
  'themadossier',
  'projectinfo',
]

export const sustainabilityGoals = [
  'SDG 1: No poverty',
  'SDG 2: Zero hunger',
  'SDG 3: Good health and well-being for people',
  'SDG 4: Quality education',
  'SDG 5: Gender equality',
  'SDG 6: Clean water and sanitation',
  'SDG 7: Affordable and clean energy',
  'SDG 8: Decent work and economic growth',
  'SDG 9: Industry; Innovation; and Infrastructure',
  'SDG 10: Reducing inequalities',
  'SDG 11: Sustainable cities and communities',
  'SDG 12: Responsible consumption and production',
  'SDG 13: Climate action',
  'SDG 14: Life below water',
  'SDG 15: Life on land',
  'SDG 16: Peace; justice and strong institutions',
  'SDG 17: Partnerships for the goals',
]
export const sustainabilityGoalsNL = [
  'SDG 1: Uitbannen van alle vormen van (extreme) armoede',
  'SDG 2: Einde aan honger, zorgen voor voedselzekerheid en duurzame landbouw',
  'SDG 3: Gezondheidszorg voor iedereen',
  'SDG 4: Inclusief, gelijkwaardig en kwalitatief onderwijs voor iedereen',
  'SDG 5: Gelijke rechten voor mannen en vrouwen en empowerment van vrouwen en meisjes',
  'SDG 6: Schoon water en sanitaire voorzieningen voor iedereen',
  'SDG 7: Toegang tot betaalbare en duurzame energie voor iedereen',
  'SDG 8: Inclusieve, economische groei, werkgelegenheid en fatsoenlijk werk voor iedereen',
  'SDG 9: Infrastructuur voor duurzame industrialisatie',
  'SDG 10: Verminderen ongelijkheid binnen en tussen landen',
  'SDG 11: Maak steden veilig, veerkrachtig en duurzaam',
  'SDG 12: Duurzame consumptie en productie',
  'SDG 13: Aanpak klimaatverandering',
  'SDG 14: Beschermen en duurzaam gebruik van de oceanen en zeeën',
  'SDG 15: Beschermen van ecosystemen, bossen en biodiversiteit',
  'SDG 16: Bevorderen van veiligheid, publieke diensten en recht voor iedereen',
  'SDG 17: Versterken van het mondiaal partnerschap om doelen te bereiken',
]
