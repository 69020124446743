<template>
  <dropdown-button
    :icon="icon"
    :size="size"
    :options="dictionaryDropdownOptions"
    @on-click-button="updateOptions"
    @select="handleSelect"
  />
</template>

<script lang="ts">
  import DropdownButton from '../DropdownButton/DropdownButton.vue'
  import DictionaryMixin from '../../util/DictionaryMixin.js'
  import { Dictionary } from '../../api/exploration.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    mixins: [DictionaryMixin],
    props: {
      keyword: {
        type: Object,
      },
      size: {
        type: String,
      },
      icon: {
        type: String,
        default: 'tag',
      }
    },
    computed: {
      dictionaryDropdownOptions () {
        // Disable the dictionary options that contain the keyword of this component
        return this
          .dictionaryOptions
          .map((dictionary) => {
            if (!dictionary.included_keywords) {
              return
            }

            const allDictionaryKeywords = {}

            dictionary.included_keywords.forEach((keyword) => {
              allDictionaryKeywords[keyword.id] = ''
            })

            const dictionaryContainsKeyword = allDictionaryKeywords[this.keyword.value] !== undefined

            const label = dictionaryContainsKeyword ? dictionary.label + ' (already in this lexicon)' : dictionary.label

            return {
              value: dictionary.value,
              label: label,
              disabled: dictionary.disabled || dictionaryContainsKeyword,
              included_keywords: dictionary.included_keywords,
            }
          })
          .filter(r => r)
      }
    },
    methods: {
      updateOptions () {
        this.fetchDictionaries()
      },
      handleSelect (value) {
        const dictionary = this.mixinDictionaries.find(d => d.id === value)

        if (!dictionary) {
          console.error(`No such lexicon: ${value}`)
          return
        }

        dictionary.included_keywords.push({
          id: this.keyword.value,
          label: this.keyword.label,
          value: this.keyword.value,
          uri: this.keyword.uri,
          type: this.keyword.type,
        })

        Dictionary.post(dictionary).catch(error => console.error(error))
      },
    },
    components: {
      DropdownButton,
    },
  })
</script>
