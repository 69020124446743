<template>
  <div class="authentication-modal authentication-modal--small">
    <modal :id="modalId" ref="modal" :enable-mask="false">
      <template v-slot:body>
        <div>
          <form class="form">
            <fieldset class="delete-confirmation-message">
              <p v-if="name && modalContextType === 'claim' && modalContextClaimType === 'owner'">
                Are you sure you want to remove the owner claim on &laquo; {{ name }} &raquo;?
              </p>
              <p v-else-if="name && modalContextType === 'claim'">
                Are you sure you want to remove your claim on &laquo; {{ name }} &raquo;?
              </p>
              <p v-else-if="modalContextType === 'claim'">
                Are you sure you want to remove the claim on this actor?
              </p>
              <p v-else-if="modalContextType === 'community'">
                Are you sure you want to remove this community?
              </p>
              <p v-else-if="modalContextType === 'spotting-area'">
                Are you sure you want to remove this {{ spottingAreaLabel }}?
              </p>
              <p v-else-if="modalContextType === 'concept-search'">
                When deleting this search, you will lose all actors and all articles that
                are not indicated as being relevant. Do you want to continue?
              </p>
              <p v-else-if="modalContextType === 'countable-entity'">
                This {{ modalContext.countableEntityName }} is used {{ modalContext.count }} time{{ modalContext.count > 1 ? 's' : '' }} in your ecosystem. Are you sure you want to
                remove {{ name }}?
              </p>
              <p v-else-if="modalContextType === 'ecosystem-member-package'">
                Removing this package will not affect existing subscriptions, but will allow you to create a new package that users can subscribe to.
              </p>
              <p v-else-if="modalContextType === 'grant'">
                Are you sure you want to remove this grant?
              </p>
              <p v-else-if="modalContextType === 'reportField'">
                Are you sure you want to remove the report field "{{ modalContext.field.label }}"? It is used in {{ modalContext.count }} actor reports.
              </p>
              <p v-else-if="modalContextType === 'reportTemplate'">
                Are you sure you want to remove the report template "{{ modalContext.report.name }}"? All related fields will be removed as well.
              </p>
              <p v-else-if="modalContextType === 'article'">
                Are you sure you want to remove this article titled {{ name }}
                from the concept search?
              </p>
              <p v-else-if="modalContextType === 'global-article'">
                Are you sure you want to remove this article?
              </p>
              <p v-else-if="modalContextType === 'article-detail'">
                Are you sure you want to remove this article?
              </p>
              <p v-else-if="modalContextType === 'actor-contributor'">
                Are you sure you want to delete {{ name }} as a contributor?
              </p>
              <p v-else-if="name">
                Are you sure you want to remove &laquo; {{ name }} &raquo;?
              </p>
              <p v-else>
                {{ $t('confirm_remove_this_content') }}
              </p>
            </fieldset>
            <fieldset class="action-buttons" style="text-align:center">
              <ds-button
                variant="secondary" :label="$t('confirm_delete')"
                @click="accept"></ds-button>
              <ds-button
                variant="outline" :label="$t('confirm_cancel')"
                @click="closeModal"></ds-button>
            </fieldset>
          </form>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
  import Modal from './Modal.vue'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import TranslationsMixin from '../../util/TranslationsMixin.js'

  export default {
    name: 'delete-confirmation-modal',
    props: {
      isCloseable: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        modalId: 'DELETE_CONFIRMATION',
      }
    },
    computed: {
      modalContext () {
        return this.$store.state.ui.modalContext
      },
      name () {
        // 'this.modalContext.originalName' is for when the user is deleting files
        if (this.modalContext.name) {
          return this.modalContext.name
        }

        return this.modalContext.title && this.modalContext.title.length && this.modalContext.title !== 'File' ? this.modalContext.title : this.modalContext.originalName
      },
      modalContextType () {
        return this.modalContext.modalContextType
      },
      modalContextClaimType () {
        return this.modalContext.modalContextClaimType
      }
    },
    methods: {
      accept () {
        // The 'modalContextType' is added to the modal context object on each of the components that call the 'deleteConfirmationModal'
        // The 'modalContextType' is used so that we can distinguish what is being deleted and what events to trigger
        switch (this.modalContext.modalContextType) {
          case 'file':
            this.$bus.emit('fileDeleteConfirmation', this.modalContext)
            break
          case 'actor':
            this.$bus.emit('actorDeleteConfirmation', this.modalContext)
            break
          case 'message':
            this.$bus.emit('messageDeleteConfirmation', this.modalContext)
            break
          case 'claim':
            this.$bus.emit('claimDeleteConfirmation', this.modalContext)
            break
          case 'announcement':
            this.$bus.emit('announcementDeleteConfirmation', this.modalContext)
            break
          case 'community':
            this.$bus.emit('communityDeleteConfirmation', this.modalContext)
            break
          case 'spotting-area':
            this.$bus.emit('spottingAreaDeleteConfirmation', this.modalContext)
            break
          case 'comment':
            this.$bus.emit('commentDeleteConfirmation', this.modalContext)
            break
          case 'challenge':
            this.$bus.emit('challengeDeleteConfirmation', this.modalContext)
            break
          case 'countable-entity':
            this.$bus.emit('confirmDeleteCountableEntity', this.modalContext)
            break
          case 'ecosystem-member-package':
            this.$bus.emit('confirmDeleteEcosystemMemberPackage', this.modalContext)
            break
          case 'concept-search':
            this.$bus.emit('conceptSearchDeleteConfirmation', this.modalContext)
            break
          case 'subscription':
            this.$bus.emit('cancelPlanDeleteConfirmation', this.modalContext)
            break
          case 'grant':
            this.$bus.emit('deleteGrantConfirmation', this.modalContext)
            break
          case 'reportField':
            this.$bus.emit('deleteReportFieldConfirmation', this.modalContext)
            break
          case 'reportTemplate':
            this.$bus.emit('deleteReportTemplateConfirmation', this.modalContext)
            break
          case 'article':
            this.$bus.emit('articleDeleteConfirmation', this.modalContext)
            break
          case 'global-article':
            this.$bus.emit('globalArticleDeleteConfirmation', this.modalContext)
            break
          case 'article-detail':
            this.$bus.emit('articleDetailDeleteConfirmation', this.modalContext)
            break
          case 'actor-contributor':
            this.$bus.emit('actorContributorDeleteConfirmation', this.modalContext)
            break
          default:
            console.error('"modalContextType" is not defined')
            break
        }

        this.closeModal()
      },
      closeModal () {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, this.modalId)
        // When closing the modal we reset to modal context.
        this.$store.commit('UI/SET_MODAL_CONTEXT', null)
      },
    },
    components: {
      Modal,
    },
    mixins: [TranslationsMixin],
  }
</script>
