<template>
  <div class="container--members">
    <div v-if="label">
      {{ label }}
    </div>
    <div class="members-list">
      <a
        v-for="member in members"
        @click="startConversation($event, member.actor_id, member.actor_name, member.id)"
        v-tooltip.top="canStartConversationWithUser(member) ? member.name : (userId === member.id ? 'You' : false)"
        :class="{'members-list--item': true, 'clickable': canStartConversationWithUser(member)}"
      >
        <avatar :src="member.avatar" :alt="member.name"/>
      </a>
    </div>
  </div>
</template>

<script>
  import Avatar from './Avatar.vue'
  import { Conversations } from '../../api/conversations'
  import { ACTION_TYPES as CONVERSATION_ACTION_TYPES } from '../../store/modules/conversations'
  import { isUserARemovedUser } from '../../util/helpers.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from "@/store/modules/ui";

  export default {
    name: 'avatar-list',
    props: {
      members: {
        type: Array,
      },
      label: {
        type: String,
      },
      tooltipPrefixLabel: {
        type: String,
      },
      canStartConversation: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      userId () {
        return this.$store.state.user.profile.id
      },
    },
    methods: {
      canStartConversationWithUser (user) {
        if (user.id === this.userId) {
          return false
        }

        if (isUserARemovedUser(user)) {
          return false
        }

        return true
      },
      startConversation ($event, actorId, actorName, userId) {
        if (this.canStartConversation) {
          $event.stopPropagation()

          if (userId === this.userId) {
            return false
          }

          Conversations.post({ actor_id: actorId, recipient_id: userId }).then((conversation) => {
            this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
            this.$router.push('/profile/conversations/' + conversation.id).then(() => {
              this.$store.commit (UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
                component: 'user-information-side-panel',
                metaData: {
                  userId: user.id,
                  allowDelete: false,
                },
              })
            })
          })
        }
      },
      actorId (user) {
        return user.actor_id
      },
      actorName (user) {
        return user.actor_name
      },
      memberName (user) {
        if (user.claimed_by_name) {
          return user.claimed_by_name
        }

        if (user.name) {
          return user.name
        }

        if (user.user_name) {
          return user.user_name
        }
      },
      memberId (user) {
        if (user.id) {
          return user.id
        }

        if (user.user_id) {
          return user.user_id
        }
      },
    },
    components: {
      Avatar,
    },
  }
</script>

<style lang="scss" scoped>
  .container--members {
    margin-right: 10px;
    display: flex;
    align-items: center;

    .members-list {
      margin-left: 10px;
      display: flex;
      flex-wrap: wrap;

      &--item {
        display: flex;
        left: 18px;
        z-index: 20;

        &:hover {
          z-index: 90;
        }

        &:last-child {
          left: 0px;

          .avatar {
          }
        }
      }
    }

    .avatar--default {
      -webkit-box-shadow: 0 0 0 2px var(--primary-extra-darkest);
      box-shadow: 0 0 0 2px var(--primary-extra-darkest);
    }

    .avatar--border {
      -webkit-box-shadow: 0 0 0 2px var(--primary-lighter);
      box-shadow: 0 0 0 2px var(--primary-lighter);

      &:hover {
        -webkit-box-shadow: 0 0 0 2px var(--primary);
        box-shadow: 0 0 0 2px var(--primary);
      }
    }

    &:hover {
      .clickable {
        cursor: pointer;

        .avatar {
          &:hover {
            -webkit-box-shadow: 0 0 0 2px var(--primary);
            box-shadow: 0 0 0 2px var(--primary);
            transform: scale(1.15);
          }
        }

      }
    }
  }
</style>
