<template>
  <div class="create-resource-panel" :style="isStaging ? 'position: relative;height: 100%;' : ''">
    <div class="fixed-heading" :style="isStaging ? 'height: 59px;' : ''">
      <div class="card__general side-panel__header">
        <div class="card__title side-panel__announcement-card__title side-panel__announcement-header-card__title">
          {{ title }}
        </div>
        <div class="pull-right side-panel__announcement-header-button-container" style="text-align: right;">
          <ds-button v-if="! resourceCreated" icon="remove" variant="minimal"
                     @click="showCancelActorEditConfirmationModal" size="small"
                     class="side-panel__announcement-header-icon"/>
          <ds-button v-else icon="remove" variant="minimal" @click="hidePreview()" size="small"
                     class="side-panel__announcement-header-icon"/>
        </div>
      </div>
    </div>
    <div class="scrollable side-panel__scrollable__content has-padding"
         :style="isStaging ? 'background-color: transparent; max-height: 80%;' : ''">
      <div class="side-panel__announcement-form-container">
        <form>
          <form-group v-if="!isArticle" :label="$t('create_resource_title')" :errors="errors.title">
            <ds-textarea v-model="resource.title" autoresize ref="titleInput"/>
          </form-group>
          <form-group v-if="!isArticle" :label="$t('create_resource_description')" :errors="errors.description">
            <ds-textarea v-model="resource.description" autoresize/>
          </form-group>
          <form-group v-if="!isArticle" :label="$t('create_resource_tags')" :errors="errors.tags">
            <AutocompleteTagInput :placeholder="$t('create_resource_tags_placeholder')" @input:raw="updateTags"
                                  :tags="this.resource.tags" @tagChanged="handleTagChanged"
                                  :options="richTagOptions"/>
          </form-group>

          <form-group :label="$t('create_resource_linked_communities_title')"
                      v-if="$store.getters.hasAccessToNewCommunities && isOwner && routeName !== 'actor-detail' && !isArticle"
                      :errors="errors.communities">
            <autocomplete-dropdown
                :is-simplified="communityId"
                :model-value="communities"
                :options="allCommunityOptions"
                :do-search="handleCommunityChanged"
                @update:modelValue="updateCommunities"
                :multiple="true"
                :allowClear="true"
                placeholder="Link to communities"
            />
          </form-group>

          <form-group label="Linked Actors" v-if="isOwner && routeName !== 'actor-detail' && !isArticle"
                      :errors="errors.actors">
            <autocomplete-dropdown
                :model-value="actors"
                :options="allActorOptions"
                :do-search="fetchActorsDebounced"
                @update:modelValue="updateActors"
                :multiple="true"
                :allowClear="true"
                placeholder="Search for actors"
            />
          </form-group>

          <form-group v-if="accessibilitySectionIsVisible && !isArticle"
                      :label="$t('create_resource_accessibility')">
            <radio-button
                @update:modelValue="updateIsPrivate"
                :disabled="featured || banner"
                :model-value="resource.is_private"
                name="reach"
                :label="$t('create_resource_accessibility_private')"
                :val="true"
            />
            <radio-button
                @update:modelValue="updateIsPrivate"
                :model-value="resource.is_private"
                name="reach"
                :label="$t('create_resource_accessibility_public')"
                :val="false"
            />
          </form-group>

          <form-group v-if="meta.actor_id"
                      :label="$t('create_resource_profile') + ' (complete with \'' + $t('create_resource_add_image') + '\')'">
            <checkbox v-model="featured" :label="$t('create_resource_featured_image')"
                      :disabled="visibleMediaInputField !== 'image'" inline
                      style="width: 100%;"/>
            <span class="featured-image__help-text"
                  v-if="visibleMediaInputField == 'image'">{{ $t('create_resource_featured_image_help') }}</span>
            <checkbox v-model="banner" :label="$t('create_resource_banner_image')"
                      :disabled="visibleMediaInputField !== 'image'" inline
                      style="width: 100%;"/>
            <span class="featured-image__help-text"
                  v-if="visibleMediaInputField == 'image'">{{ $t('create_resource_banner_image_help') }}</span>
          </form-group>

          <div class="side-panel-text-devider"><span> {{ $t('create_resource_complete_with_divider') }} </span></div>

          <div class="collapsable-tab__offering-card-container" v-if="!meta.imageOnly">
            <div v-if="! disabledFields.url" class="collapsable-tab__offering-card"
                 @click="visibleMediaInputField = 'url'">
              <span>{{ $t('create_resource_add_url') }}</span>
            </div>
            <div class="collapsable-tab__offering-card collapsable-tab__offering-card-disabled" v-else>
              <span>{{ $t('create_resource_add_url') }}</span>
            </div>

            <div v-if="! disabledFields.files" class="collapsable-tab__offering-card"
                 @click="visibleMediaInputField = 'files'">
              <span>{{ $t('create_resource_add_file') }}</span>
            </div>
            <div class="collapsable-tab__offering-card collapsable-tab__offering-card-disabled" v-else>
              <span>{{ $t('create_resource_add_file') }}</span>
            </div>

            <div v-if="! disabledFields.link" class="collapsable-tab__offering-card"
                 @click="visibleMediaInputField = 'link'">
              <span>{{ $t('create_resource_add_video') }}</span>
            </div>
            <div class="collapsable-tab__offering-card collapsable-tab__offering-card-disabled" v-else>
              <span>{{ $t('create_resource_add_video') }}</span>
            </div>

            <div v-if="! disabledFields.image" class="collapsable-tab__offering-card"
                 @click="visibleMediaInputField = 'image'">
              <span>{{ $t('create_resource_add_image') }}</span>
            </div>
            <div class="collapsable-tab__offering-card collapsable-tab__offering-card-disabled" v-else>
              <span>{{ $t('create_resource_add_image') }}</span>
            </div>

            <div v-if="! disabledFields.article" class="collapsable-tab__offering-card" @click="onSelectArticleOption">
              <span>{{ $t('create_resource_add_article') }}</span>
            </div>
            <div class="collapsable-tab__offering-card collapsable-tab__offering-card-disabled" v-else>
              <span>{{ $t('create_resource_add_article') }}</span>
            </div>
          </div>

          <div v-if="visibleMediaInputField !== null" class="create-resource-panel__media-input-fields-container">
            <form-group v-if="visibleMediaInputField == 'url'" :label="$t('create_resource_url_title')"
                        :errors="resource.link ? '' : errors.link">
              <ds-input @update:modelValue="insertUrl" :disabled="disabledFields.url"/>
            </form-group>

            <form-group v-if="visibleMediaInputField == 'article'" :label="$t('create_resource_url_title')"
                        :errors="resource.article ? '' : errors.link">
              <div class="create-resource-panel__article-input-text" v-if="verifyingArticle">
                <icon name="spinner"/>
                <p>
                  Verifying article
                </p>
              </div>
              <p :class="articleMessageError ? 'form-group__error' : 'form-group__success'" v-if="articleMessage">
                {{ articleMessage }}
              </p>
              <ds-input @update:modelValue="insertArticle" :disabled="disabledFields.article"/>
            </form-group>

            <form-group v-if="visibleMediaInputField == 'files'" :label="$t('create_resource_file_title')"
                        :errors="errors.files">
              <file-input
                  window-drop
                  :url="fileInputUrl"
                  label="Upload"
                  :multiple="false"
                  :noIcon="false"
                  @upload="addFileToList($event, 'file')"
                  v-if="!disabledFields.files && !uploadButtonDisabled"
              />
              <ds-button :label="$t('create_resource_upload_file')" :disabled="uploadButtonDisabled" v-else/>
            </form-group>

            <form-group v-if="visibleMediaInputField == 'link'" :label="$t('create_resource_video')"
                        :errors="resource.url ? '' : errors.link">
              <ds-input :placeholder="$t('create_resource_video_help')" @update:modelValue="insertLink"
                        :disabled="disabledFields.link "/>
            </form-group>

            <template v-if="visibleMediaInputField == 'image'">
              <form-group :label="$t('create_resource_image_title')" :errors="errors.image">
                <file-input
                    window-drop
                    :url="fileInputUrl"
                    label="Upload"
                    :multiple="false"
                    :noIcon="false"
                    @upload="addFileToList($event, 'image')"
                    v-if="!disabledFields.image && !uploadButtonDisabled"
                />
                <span class="featured-image__help-text">{{ $t('create_resource_image_normal_help') }}</span>
              </form-group>
            </template>
            <!--<template v-else>
              <form-group>
                <ds-button label="Upload files" :disabled="uploadButtonDisabled"/>
              </form-group>
            </template>-->
            <p v-if="fileUploadSuccessful" class="create-resource-panel__media-input-fields-success-message">
              {{ fileUploadSuccessful }}</p>
          </div>
        </form>
      </div>
    </div>
    <action-bar :editing="true" class="side-panel__announcement-bottom-section"
                :style="isStaging ? 'background-color: white;bottom: 20px;' : ''">
      <span class="action-bar__message form-group__success" v-if="resourceCreated">
        {{
          (resourceApproved || isOwner) ? $t('create_resource_success_message') : $t('create_resource_success_being_curated_message')
        }}
      </span>
      <div>
        <ds-button variant="secondary" size="extra-small" :label="$t('create_resource_add')"
                   :disabled="busy || !requiredFieldsFilled" @click="save()"/>
        <ds-button variant="outline" size="extra-small" :label="$t('create_resource_close')" @click="hidePreview()"/>
      </div>
    </action-bar>
  </div>
</template>

<script>
import Card from '../../components/Card/Card.vue'
import ActionBar from '../../components/Form/ActionBar.vue'
import RadioButton from '../../components/Form/RadioButton.vue'
import ImageInput from '../../components/Form/ImageInput.vue'
import DsTextarea from '../../components/Form/DsTextarea.vue'
import FileInput from '../../components/Form/FileInput.vue'
import AutocompleteTagInput from '../../components/Form/AutocompleteTagInput.vue'
import Checkbox from '../Form/Checkbox.vue'
import AutocompleteDropdown from '../Dropdown/AutocompleteDropdown.vue'

import { createFileRequest } from '../../api/create-request.js'
import {
  deleteActorFile,
  deleteEcosystemFile,
  updateActorFileMetaData,
  updateEcosystemFileMetaData,
  verifyArticle
} from '../../api/files.js'

import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
import KEYCODES from '../../constants/keycodes'
import MODAL_IDS from '../../constants/modal-ids'
import diffObjects from '../../util/diff-objects'
import TagsMixin from '../../util/TagsMixin'

import { ACTION_TYPES as ACTOR_ACTION_TYPES } from '../../store/modules/actors'
import { Communities } from '../../api/communities'
import { getSearchResultsForQueryType } from '../../api/general-search.js'
import { mapNewGlobalSearchResultToLegacyFormat } from '@/util/helpers'

export default {
  name: 'create-resource-panel',
  props: ['meta'],
  data () {
    return {
      originalResource: this.newEmptyResource(),
      resource: this.newEmptyResource(),
      featured: false,
      banner: false,
      resourceCreated: false,
      resourceApproved: false,
      errors: {},
      busy: false,
      disabledFields: {
        url: false,
        files: false,
        link: false,
        image: false,
        article: false
      },
      uploadButtonDisabled: false,
      visibleMediaInputField: this.meta.visibleMediaInputField || null,
      fileUploadSuccessful: '',
      allCommunityOptions: [],
      allActorOptions: [],
      actors: [],
      communities: [],
      isArticle: false,
      verifyingArticle: false,
      articleIsValid: false,
      articleMessage: '',
      articleMessageError: false
    }
  },
  computed: {
    title () {
      if (this.meta.imageOnly) {
        return this.$t('create_resource_panel_image_only_title')
      }

      return this.$t('create_resource_panel_title')
    },
    accessibilitySectionIsVisible () {
      return this.$store.getters.isPublisherEnabled && !this.meta.imageOnly && !this.isActor
    },
    isActor () {
      return this.$store.getters.isActor
    },
    isOwner () {
      return this.$store.getters.isOwner
    },
    isDeveloper () {
      return this.$store.getters.isDeveloper
    },
    isStaging () {
      return ['local', 'own'].includes(this.$store.getters.ecosystemName.toLowerCase())
    },
    fileInputUrl () {
      if (this.meta.actor_id) {
        return '/api/actors/' + this.meta.actor_id + '/files'
      } else if (this.communityId) {
        return '/api/files?linked_community_id=' + this.selectedCommunityIds
      } else {
        return '/api/files'
      }
    },
    requiredFieldsFilled () {
      return this.articleIsValid || (this.resource.title && this.resource.title.length > 0 &&
          this.resource.description && this.resource.description.length > 0 &&
          (
              this.disabledFields.files ||
              this.disabledFields.image ||
              this.disabledFields.link ||
              this.disabledFields.url ||
              this.disabledFields.article
          ))
    },
    communityId () {
      return this.$route.params.communityId
    },
    selectedCommunityIds () {
      const selectedCommunityIds = []
      this.communities.forEach(community => {
        if (!selectedCommunityIds.includes(community.value)) {
          selectedCommunityIds.push(community.value)
        }
      })
      return selectedCommunityIds.toString()
    },
    displayCommunitiesDropdown () {
      return this.$store.getters.hasAccessToNewCommunities && this.allCommunityOptions.length > 0 && this.$store.getters.isOwner
    },
    routeName () {
      return this.$route.name
    }
  },
  methods: {
    onSelectArticleOption () {
      this.visibleMediaInputField = 'article'
      this.isArticle = true
    },
    async fetchActorsDebounced (query) {
      this.allActorOptions = []
      const results = []
      const data = await getSearchResultsForQueryType({ query, type: 'actors' })
      const combinedActorsWithoutDuplicates = mapNewGlobalSearchResultToLegacyFormat([...data.actors, ...data.products])

      combinedActorsWithoutDuplicates.forEach(actor => {
        const actorObject = {
          label: actor.name,
          value: actor.id,
        }
        results.push(actorObject)
      })

      this.allActorOptions = results
      return results
    },
    suggestionsInputPlaceholder () {
      if (this.isVatSuggestionEnabled === true) {
        return this.$t('add_actor_company_name_by_vat_placeholder')
      } else {
        return this.$t('add_actor_company_name_placeholder')
      }
    },
    selectDefaultForSingleCommunityMembers () {
      // If there's a new announcement to be made and the user is in the context of a community, set the community value
      if (this.communityId) {
        const community = this.allCommunityOptions.find(community => community.id == this.communityId)

        if (!community) {
          return
        }

        this.communities = [{
          label: community.name,
          value: community.id
        }]
      }
    },
    updateCommunities (communities) {
      this.communities = communities.map(item => {
        if (item.optionValue) {
          return item.optionValue
        }

        return {
          label: item.label,
          value: item.value
        }
      })
    },
    updateActors (actors) {
      this.actors = actors.map(item => {
        if (item.optionValue) {
          return item.optionValue
        }

        return {
          label: item.label,
          value: item.value
        }
      })
    },
    handleCommunityChanged (community) {
      return Communities
          .get('', { query: community })
          .then(response => {
            return this.updateCommunityOptions(response)
          })
    },
    updateCommunityOptions (communityOptions) {
      return communityOptions
          .map(option => {
            return {
              label: option.name,
              value: option.id
            }
          })
    },
    checkVisibility () {
      if (this.featured || this.banner) {
        if (this.accessibilitySectionIsVisible) {
          this.resource.is_private = false
        }
      }
    },
    newEmptyResource () {
      return {
        is_private: !this.$store.getters.isPublisherEnabled,
        tags: [],
        title: ''
      }
    },
    clearForNewResource () {
      this.originalResource = this.newEmptyResource()
      this.resource = this.newEmptyResource()
      this.featured = false
      this.errors = {}
      this.actors = []
      this.allActorOptions = []
      this.communities = []
      this.allCommunityOptions = []
      this.disabledFields = {
        url: false,
        files: false,
        link: false,
        image: false
      }
      this.uploadButtonDisabled = false
      this.fileUploadSuccessful = ''
      this.$refs.titleInput.focus()
    },
    updateIsPrivate (val) {
      this.resource.is_private = val
    },
    hidePreview () {
      this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      if (this.meta.closeCallback) this.meta.closeCallback()
    },
    updateTags (tags) {
      this.resource.tags = tags.map(item => {
        if (item.optionValue) {
          return item.optionValue
        }

        // New keywords are identified by having both their value and text the same value
        return {
          label: item.text,
          value: item.text,
          text: item.text // The autocomplete input library only supports "text", map it to the object that it expects, while also keeping the model our internal logic expects (=label)
        }
      })
    },
    handleTagChanged (tag) {
      // Update the options according to the given tag
      // this.updateTagOptions(tag);
      this.updateRichTagOptions(tag)
    },
    addFileToList (files, whereFrom) {
      this.fileUploadSuccessful = ''
      if (this.resource.files == undefined) {
        this.resource.files = files
      } else {
        this.resource.files = this.resource.files.concat(files)
      }

      if (whereFrom == 'file') {
        this.disabledFields.files = false
        this.disabledFields.image = true
        this.fileUploadSuccessful = `${files[0].originalName ? files[0].originalName : 'File'} ` + this.$t('create_resource_success')
      }

      if (whereFrom == 'image') {
        this.disabledFields.files = true
        this.disabledFields.image = false
        this.fileUploadSuccessful = `${files[0].originalName ? files[0].originalName : 'Image'} ` + this.$t('create_resource_success')
      }

      this.disabledFields.url = true
      this.disabledFields.link = true
      this.uploadButtonDisabled = true

      // trigger save
      //this.save()
    },
    insertUrl (data) {
      if (data.length > 0) {
        this.resource.url = data
        this.disabledFields.files = true
        this.disabledFields.url = false
        this.disabledFields.link = true
        this.disabledFields.image = true
        this.disabledFields.article = true
      } else {
        delete this.resource.url
        this.disabledFields.files = false
        this.disabledFields.url = false
        this.disabledFields.link = false
        this.disabledFields.image = false
        this.disabledFields.article = false
      }
    },
    insertLink (data) {
      if (data.length > 0) {
        this.resource.link = data
        this.disabledFields.files = true
        this.disabledFields.url = true
        this.disabledFields.link = false
        this.disabledFields.image = true
        this.disabledFields.article = true
      } else {
        delete this.resource.link
        this.disabledFields.files = false
        this.disabledFields.url = false
        this.disabledFields.link = false
        this.disabledFields.image = false
        this.disabledFields.article = false
      }
    },
    insertArticle (data) {
      if (data.length > 0) {
        this.resource.link = data
        this.disabledFields.files = true
        this.disabledFields.url = true
        this.disabledFields.link = true
        this.disabledFields.image = true
        this.disabledFields.article = false
      } else {
        delete this.resource.link
        this.disabledFields.files = false
        this.disabledFields.url = false
        this.disabledFields.link = false
        this.disabledFields.image = false
        this.disabledFields.article = false
      }

      this.verifyArticle(data)
    },
    verifyArticle (data) {
      this.articleMessage = ''
      if (data.length > 0) {
        this.articleIsValid = false
        this.verifyingArticle = true
        this.articleMessageError = false
        verifyArticle({ url: data })
            .then((result) => {
              this.verifyingArticle = false
              if (result.eventRegistryId) {
                this.articleMessage = 'Article is valid'
                this.articleIsValid = true
                this.articleMessageError = false
                this.resource = result
                this.resource.link_type = 'article'
              } else if (result.error) {
                this.articleMessage = result.error
                this.articleMessageError = true
              } else {
                this.articleMessage = ''
              }
            })
            .catch((err) => {
              console.log(err)
              this.verifyingArticle = false
              this.articleMessageError = false
            })
      }
    },
    resourceDiff () {
      return diffObjects(this.resource, this.originalResource)
    },
    showCancelActorEditConfirmationModal () {
      if (this.resourceDiff()) {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          body: 'Do you want to delete this resource?',
          resource: this.resource,
          modalContextType: 'file'
        })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
      } else {
        this.hidePreview()
      }
    },
    deleteEcosystemFiles () {
      if (this.resource.files) {
        this.resource.files.forEach(file => {
          deleteEcosystemFile(file.id)
              .catch(errors => {
                this.errors = errors
              })
              .then(() => {
                this.hidePreview()
              })
        })
      }
    },
    deleteActorFiles () {
      if (this.resource.files) {
        this.resource.files.forEach(file => {
          deleteActorFile(this.meta.actor_id, file.id)
              .catch(errors => {
                this.errors = errors
              })
              .then(() => {
                this.hidePreview()
              })
        })
      }
    },
    updateEcosystemFile (fileId) {
      this.busy = true
      var copyOfForm = Object.assign({}, this.resource)

      // We delete all other irrelevant properties
      delete copyOfForm.link
      delete copyOfForm.files
      delete copyOfForm.url
      delete copyOfForm.image

      updateEcosystemFileMetaData(fileId, copyOfForm)
          .then(value => {
            this.$bus.emit('fetchNewResources')
            this.clearForNewResource()

            this.resourceCreated = true
            if (value.file) {
              this.resourceApproved = !!value.file.approved
            }
          })
          .catch(errors => {
            this.errors = errors
          })
          .then(() => {
            this.busy = false
          })
    },
    updateActorFile (fileId) {
      this.busy = true
      var copyOfForm = Object.assign({}, this.resource)

      // We delete all other irrelevant properties
      delete copyOfForm.link
      delete copyOfForm.files
      delete copyOfForm.url
      delete copyOfForm.image

      if (copyOfForm.tags && copyOfForm.tags.length > 0) {
        copyOfForm.tags = copyOfForm.tags.map(tag => tag.value)
      }

      updateActorFileMetaData(this.meta.actor_id, fileId, copyOfForm)
          .then(value => {
            this.$store.dispatch('ACTORS/FETCH_ACTOR_DETAIL', true)
            this.$bus.emit('fetchNewResources')
            this.clearForNewResource()

            this.resourceCreated = true
            this.resourceApproved = !!value[0].approved
          })
          .catch(errors => {
            this.errors = errors
          })
          .then(() => {
            this.busy = false
          })
    },
    save () {
      this.errors = {}

      if (this.busy) {
        return
      }

      this.busy = true

      var urlPath = '/api/files' // Default path to create files on an ecosystem level

      if (this.meta.actor_id) {
        urlPath = '/api/actors/' + this.meta.actor_id + '/files' // For when its an actor resource that we are trying to create
      }

      // If the user is an ecosystem member, the is_private is false
      if (this.isActor && !this.meta.actor_id) {
        this.resource.is_private = false
      }

      // If there are files added, instead of creating a resource we will instead update the resource
      // When uploading files, the files are automaticaly uploaded to the ecosystem
      // which in this case we need to add further meta-data into them by doing an update
      if (this.resource.files && this.resource.files.length > 0) {
        this.resource.files.forEach(file => {
          if (this.meta.actor_id) {
            this.updateActorFile(file.id)

            let fileUrl = file.banner || file.preview_w766 || file.url

            if (fileUrl.startsWith('actor/')) {
              fileUrl = window.config.url + '/' + 'api/actors/' + this.meta.actor_id + '/files/' + file.id
            }

            if (this.featured) this.setFeaturedImage(fileUrl || null)
            if (this.banner) this.setBannerImage(fileUrl || null)
          } else {
            this.updateEcosystemFile(file.id)
          }
        })
        return
      }

      const form = new window.FormData()
      var formProperties = Object.entries(this.resource)

      formProperties.forEach(property => {
        // We apppend the key and values to the form
        var key = property[0]
        var value = property[1]
        var checkForProtocol = /^(f|ht)tps?:\/\//i // Used to check if the string link contains the protocols http, https and ftp

        if (key == 'files') {
          return
        }

        // In case of adding an URL
        // we set the link_type to link
        // The value of the link must contain the protocol
        if (key == 'url' && value && value.length > 0) {
          form.append('link_type', 'link')
          if (checkForProtocol.test(value)) {
            form.append('link', value)
          } else {
            form.append('link', 'http://' + value)
          }
          return
        }

        if (key == 'tags') {
          value.forEach(tag => form.append('tags[]', tag.value))
          return
        }
        // In case of adding an link for youtube or vimeo
        // we set the link_type to video_link
        // The value of the link must contain the protocol
        if (key == 'link' && value && value.length > 0) {
          form.append('link_type', 'video_link')
          if (checkForProtocol.test(value)) {
            form.append(key, value)
          } else {
            form.append(key, 'http://' + value)
          }
          return
        }

        if (key == 'is_private') {
          form.append(key, value ? '1' : '0')
          return
        }

        // Other properties are added here
        form.append(key, value)
      })

      if (this.communityId || this.communities.length > 0) {
        const selectedCommunityIds = []
        this.communities.forEach(community => {
          if (!selectedCommunityIds.includes(community.value)) {
            selectedCommunityIds.push(community.value)
          }
        })
        form.append('linked_community_id', selectedCommunityIds.toString())
      }

      if (this.actors.length > 0) {
        const selectedActorids = []
        this.actors.forEach(actor => {
          if (!selectedActorids.includes(actor.value)) {
            selectedActorids.push(actor.value)
          }
        })
        form.append('linked_actor_id', selectedActorids.toString())
      }

      createFileRequest({
        url: urlPath,
        method: 'POST',
        body: form
      })
          .then(value => {
            this.resourceCreated = true
            this.resourceApproved = !!value[0].approved

            if (this.meta.actor_id) {
              this.$store.dispatch('ACTORS/FETCH_ACTOR_DETAIL', true)
            }

            this.$bus.emit('fetchNewResources')
            this.clearForNewResource()
          })
          .catch(errors => {
            this.errors = errors
          })
          .then(() => {
            this.busy = false
          })
    },
    async setBannerImage (filePath) {
      const questionIndex = filePath.indexOf('?')
      const finalPath = questionIndex >= 0 ? filePath.substring(0, questionIndex) : filePath

      var change = {
        banner_image_url: finalPath
      }

      try {
        await this.$store.dispatch(ACTOR_ACTION_TYPES.UPDATE_ACTOR, { id: this.meta.actor_id, data: change })

        // this.$store.dispatch('ACTORS/FETCH_ACTOR_DETAIL', true)
      } catch (errors) {
        this.errors = errors
      }
    },
    async setFeaturedImage (filePath) {
      const questionIndex = filePath.indexOf('?')
      const finalPath = questionIndex >= 0 ? filePath.substring(0, questionIndex) : filePath

      var change = {
        featured_image_url: finalPath
      }

      try {
        await this.$store.dispatch(ACTOR_ACTION_TYPES.UPDATE_ACTOR, { id: this.meta.actor_id, data: change })

        // this.$store.dispatch('ACTORS/FETCH_ACTOR_DETAIL', true)
      } catch (errors) {
        console.log(errors)
        this.errors = errors
      }
    }
  },
  async mounted () {
    // WARNING: since "SidePanel" is a part of the main application, the destroy will never be called. Take this into account when working with bus events
    this.$bus.on('fileDeleteConfirmation', (context) => {
      // When a "create content" action is cancelled, make sure the already uploaded files of the content action are removed as well
      // To make sure we remove the correct resource, check for both title and description matches
      if (context.resource.title != this.resource.title ||
          context.resource.description != this.resource.description ||
          !context.resource.files ||
          !this.resource.files ||
          context.resource.files.length == 0 ||
          this.resource.files.length == 0 ||
          context.resource.files[0].id != this.resource.files[0].id
      ) {
        this.hidePreview()

        return
      }

      if (context.resource.files) {
        // If the content is uploaded for an actor, remove the file on the actor, if not, treat it as an ecosystem file
        if (this.meta.actor_id) {
          this.deleteActorFiles()
        } else {
          this.deleteEcosystemFiles()
        }
      } else {
        this.hidePreview()
      }
    })

    window.addEventListener('keyup', (e) => {
      if (e.keyCode === KEYCODES.ESC) {
        this.hidePreview()
      }
    })

    if (this.$store.getters.hasAccessToNewCommunities) {
      try {
        this.allCommunityOptions = await Communities.get('', { query: '' })
        this.selectDefaultForSingleCommunityMembers()
      } catch (error) {
        console.log(error)
      }
    }

    this.$refs.titleInput.focus()
  },
  watch: {
    featured () {
      this.checkVisibility()
    },
    banner () {
      this.checkVisibility()
    },
    visibleMediaInputField () {
      if (this.visibleMediaInputField !== 'article') {
        this.isArticle = false
      }
    }
  },
  mixins: [TagsMixin],
  components: {
    Card,
    ActionBar,
    RadioButton,
    ImageInput,
    DsTextarea,
    FileInput,
    AutocompleteTagInput,
    Checkbox,
    AutocompleteDropdown
  }
}

function renderActorSuggestionResults (response) {
  console.log(response)
  return this.actors
}
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";

.featured-image__help-text {
  display: flex;
  font-size: 12px;
  color: $color-text-grey-light;
}

.action-bar__message.form-group__success {
  margin-bottom: 15px;
  font-size: 14px;
}

.create-resource-panel__article-input-text {
  display: flex;
}
</style>
