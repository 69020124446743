<template>
  <div class="onboarding-modal sign-up-modal">
    <modal :id="modalId" ref="modal" :customTitle="partOfOAuth" :enable-mask="false" :is-closeable="isCloseable">
      <template v-slot:body>
        <div>
          <div class="row onboarding-modal-header-navigation">
            <p>Sign up</p>
            <ds-button variant="minimal" v-if="currentSlide !== 1 && currentSlide !== 2" class="onboarding-modal-close-button" icon="remove" @click="closeModal"/>
          </div>
          <div>
            <div class="onboarding-modal-section-1" v-if="currentSlide == 0">
              <div class="row">
                <div :class="smallModal ? 'col-xs-12 col-sm-6 onboarding-section-1--small' : 'col-xs-12 col-sm-6'">
                  <form class="form" @submit.prevent="register">
                    <fieldset>
                      <form-group class="col-xs-12" label="Your name" style="padding: 0;">
                        <input class="input" type="text" placeholder="John Doe" id="register-name" v-model="name" @input="errors.name=[]" autofocus>
                        <p v-for="msg in errors.name" v-text="msg" class="form-msg error"></p>
                      </form-group>
                      <form-group class="col-xs-12" label="Your email address" style="padding: 0;">
                        <input class="input" type="email" placeholder="john@example.com" id="register-email" v-model="email" @input="errors.email=[]">
                        <p v-for="msg in errors.email" v-text="msg" class="form-msg error"></p>
                      </form-group>
                    </fieldset>
                  </form>
                </div>
                <div :class="smallModal ? 'col-xs-12 col-sm-6 onboarding-section-1--small' : 'col-xs-12 col-sm-6'">
                  <p v-if="errors.message" class="form-group__error" v-text="errors.message"></p>
                  <p v-if="signupDone" class="form-msg success">
                    <template v-if="$store.getters.allowCuratedAdd">
                      Before you can join this ecosystem, you will have to confirm your email. To do this, you will get a confirmation email.<br><br>

                      If the email doesn’t appear shortly, please be sure to check your spam / junk mail folder<br><br>

                      After you confirmed your email address, you still have to be verified by the owner of the ecosystem because this is a private ecosystem.

                    </template>
                    <template v-else>
                      Your registration was successful. We have sent you an activation code, please check your email.
                      <br><br>
                      If the email doesn't appear shortly, please be sure to check your spam / junk mail folder
                    </template>
                  </p>
                </div>
              </div>
              <div class="row">
                <div :class="smallModal ? 'col-xs-12 col-sm-6 onboarding-section-1--small' : 'col-xs-12 col-sm-6'">
                  <div class="onboarding-modal-devider">
                    <span> Create your password </span>
                  </div>
                  <form-group class="col-xs-12" label="Password" style="padding: 0;">
                    <input class="input" type="password" placeholder="Choose a strong password" id="register-password" v-model="password" @input="errors.password=[]">
                    <div class="pw-strength" :class="'pw-strength' + passwordScore" style="margin-top:1em">
                      <div class="pw-strength-line"></div>
                    </div>
                  </form-group>
                  <form-group class="col-xs-12" label="Confirm Password" style="padding: 0;">
                    <input class="input" type="password" placeholder="Confirm your password" v-model="passwordConfirmation">
                    <p v-for="msg in errors.password" v-text="msg" class="form-msg error"></p>
                  </form-group>
                </div>
                <div class="col-xs-12 col-sm-6" v-if="! partOfInvitation && ! socialLoginDisabled">
                  <div class="onboarding-modal-devider">
                    <span> or sign up with </span>
                  </div>
                  <social-auth-buttons :disabled="!acceptedTerms" :method="$t('signup_social_start_text')"></social-auth-buttons>
                </div>
              </div>
            </div>
            <div class="onboarding-modal-section-2" v-if="currentSlide == 1">
              <div class="onboarding-modal-section__container">
                <div class="onboarding-modal-section__texts-container">
                  <Icon name="information-outline"/>
                  <div v-if="onboardingTitle" class="onboarding-modal-section__texts-container-title">
                    {{ onboardingTitle }}
                  </div>
                  <div v-else class="onboarding-modal-section__texts-container-title">
                    WELCOME TO THE EXPERT COMMUNITY OF PIONEERS IN ETHICAL AND SUSTAINABLE WEARABLE TECH
                  </div>
                  <br>
                  <div v-if="onboardingSubtitle" class="onboarding-modal-section__texts-container-description-small">
                    {{ onboardingSubtitle }}
                  </div>
                  <div v-else class="onboarding-modal-section__texts-container-description-small">
                    By creating you profile you engage in the open dialogue with peers, exploring and co-creating novel approaches to solve common problems as they might turn out
                    to be the next disruptive innovation in wearable technology.
                  </div>
                  <br>
                  <!-- <div class="onboarding-modal-section__texts-container-description">
                    Be yourself! Be found for who you really are and how you can best help others in their innovation journey.
                  </div> -->
                </div>
                <br>
                <ol>
                  <li>
                    <p>Expert</p>
                    <div class="onboarding-modal-section__texts-container-description-extra-small-1">
                      Create your personal profile and present the expertise and skills you like to share with peers.
                    </div>
                  </li>
                  <li>
                    <p>Organisation</p>
                    <div class="onboarding-modal-section__texts-container-description-extra-small-1">
                      Create a company profile and present the products and services you offer as well as the expertise you want to share.
                    </div>
                  </li>
                  <li>
                    <p>Project</p>
                    <div class="onboarding-modal-section__texts-container-description-extra-small-1">
                      Create a team profile, present your joint project, your shared ambition and the challenges ahead.
                    </div>
                  </li>
                </ol>
              </div>
            </div>
            <div class="onboarding-modal-section-3" v-if="currentSlide == 2">
              <div class="row onboarding-modal-section__container">
                <div class="col-xs-12 col-sm-12 onboarding-modal-section__texts-container">
                  <div class="onboarding-modal-section__counter-image-container" :style="onboardingCounterImage(1)"></div>
                  <div class="onboarding-modal-section__texts-inner-container">
                    <div class="onboarding-modal-section__texts-container-title">
                      TELL US MORE ABOUT YOU
                    </div>
                    <div class="onboarding-modal-section__texts-container-description-small">Present yourself, who you are and why peers should get in touch with you.</div>
                    <div class="onboarding-modal-section__texts-container-description-small">Your personal profile acts as a filter on the platform to personalise the content to
                      your preferences.
                    </div>
                    <br>
                  </div>
                </div>
                <div class="form-group col-xs-12 col-sm-6" style="padding-left: 0px;">
                  <label v-if="onboardingQuestions && onboardingQuestions.question_1" class="label" for="user-expertise">{{ onboardingQuestions.question_1 }}</label>
                  <label v-else class="label" for="user-expertise">Select the expertise that fits you best skills:</label>
                  <keyword v-for="(tag, index) in expertisesOptions" :key="index" id="user-expertise" :label="tag" :class="{'keyword-selected' : actor.expertises.includes(tag)}"
                           @click="addToExpertise('actor', tag)"/>
                </div>
                <div class="form-group col-xs-12 col-sm-6">
                  <label v-if="onboardingQuestions && onboardingQuestions.question_2" class="label" for="user-motivations">{{ onboardingQuestions.question_2 }}</label>
                  <label v-else class="label" for="user-motivations">Select what motivates you most:</label>
                  <keyword v-for="(tag, index) in motivationsOptions" :key="index" id="user-motivations" :label="tag"
                           :class="{'keyword-selected' : actor.motivations.includes(tag)}" @click="addToMotivations('actor',tag)"/>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-xs-12 col-sm-6">
                  <label v-if="onboardingQuestions && onboardingQuestions.question_3" class="label" for="user-more-information">{{ onboardingQuestions.question_3 }}</label>
                  <label v-else class="label" for="user-more-information">Some information about yourself:</label>
                  <ds-textarea :rows="6" id="user-more-information" v-model="actor.description"/>
                </div>
              </div>
            </div>
            <div class="onboarding-modal-section-4" v-if="currentSlide == 3">
              <div class="row onboarding-modal-section__container">
                <div class="col-xs-12 col-sm-12 onboarding-modal-section__texts-container">
                  <div class="onboarding-modal-section__counter-image-container" :style="onboardingCounterImage(2)"></div>
                  <div class="onboarding-modal-section__texts-inner-container">
                    <div class="onboarding-modal-section__texts-container-title">
                      TELL US MORE ABOUT YOUR ORGANISATION
                    </div>
                    <div class="onboarding-modal-section__texts-container-description-small">
                      Present your organisation, the products and services you offers and why peers should reach out. Your company profile acts as a filter on the platform to
                      personalise the content to your preferences.
                    </div>
                  </div>
                  <br>
                </div>
                <div class="form-group col-xs-12 col-sm-6" :class="{ error: errors.addingActorName && errors.addingActorName.length}" style="padding-left: 0">
                  <label class="label" for="company-name">Your organisation</label>
                  <suggestion-input
                    v-model="company_name"
                    autofocus="autofocus"
                    ref="suggestionInput"
                    icon="search"
                    placeholder="Company name"
                    :options="actorSuggestionSettings"
                    :render-results="actorSuggestionSettings.renderResults"
                    :clear-on-select="false"
                    @on-select="handleSelectSuggestion"
                  />
                  <p v-if="errors.addingActorName && errors.addingActorName.length" v-text="errors.addingActorName" class="form-msg error"></p>
                </div>
                <div class="form-group col-xs-12 col-sm-6">
                  <label class="label" for="company-name">Website URL</label>
                  <input class="input" type="text" placeholder="example.com" v-model="company_url" @input="errors.addingActorUrl=null"/>
                  <div class="onboarding-modal-section__texts-container-description-extra-small">
                    Based upon your company website, the platform will create your company profile. Feel free to update your company profile upon finalising the registration
                    process.
                  </div>
                  <p v-if="errors.addingActorUrl && errors.addingActorUrl.length" v-text="errors.addingActorUrl" class="form-msg error"></p>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-xs-12 col-sm-6">
                  <label v-if="onboardingQuestions && onboardingQuestions.question_4" class="label" for="company-expertise">{{ onboardingQuestions.question_4 }}</label>
                  <label v-else class="label" for="company-expertise">Expertise of your organisation:</label>
                  <keyword v-for="(tag, index) in expertisesOptions" :key="index" id="company-expertise" :label="tag"
                           :class="{'keyword-selected' : organisation.expertises.includes(tag)}" @click="addToExpertise('organisation',tag)"/>
                </div>
                <div class="form-group col-xs-12 col-sm-6">
                  <label v-if="onboardingQuestions && onboardingQuestions.question_5" class="label" for="company-motivations">{{ onboardingQuestions.question_5 }}</label>
                  <label v-else class="label" for="company-motivations">Drivers of your organisation:</label>
                  <keyword v-for="(tag, index) in motivationsOptions" :key="index" id="company-motivations" :label="tag"
                           :class="{'keyword-selected' : organisation.motivations.includes(tag)}" @click="addToMotivations('organisation',tag)"/>
                </div>
              </div>
            </div>
            <div class="onboarding-modal-section-5" v-if="currentSlide == 4">
              <div class="row onboarding-modal-section__container">
                <div class="col-xs-12 col-sm-12 onboarding-modal-section__texts-container">
                  <div class="onboarding-modal-section__counter-image-container" :style="onboardingCounterImage(3)"></div>
                  <div class="onboarding-modal-section__texts-inner-container">
                    <div class="onboarding-modal-section__texts-container-title">
                      TELL US MORE ABOUT YOUR PROJECT
                    </div>
                    <div class="onboarding-modal-section__texts-container-description-small">
                      Present your project, the impact you like to generate, your team members and the challenges ahead. Get access to the curated content, best practices, mentors
                      and hubs that might be valuable for you to explore.
                    </div>
                    <div class="onboarding-modal-section__texts-container-description-small">
                      Your project profile will treated confidentially. Only you and your team members (upon invitation) will have access to the project profile.
                    </div>
                  </div>
                  <br>
                </div>
                <div class="form-group col-xs-12 col-sm-6" :class="{ error: errors.project_name && errors.project_name.length}" style="padding-left: 0">
                  <label v-if="onboardingQuestions && onboardingQuestions.question_6" class="label" for="project-teammates">{{ onboardingQuestions.question_6 }}</label>
                  <label v-else class="label" for="project-teammates">What is your project name?</label>
                  <input class="input onboarding-modal-section__project_name_input" type="text" placeholder="Project name" v-model="project.name" @input="errors.project_name=[]"/>
                </div>
                <div class="form-group col-xs-12 col-sm-6" :class="{ error: errors.contributors && errors.contributors.length}">
                  <label v-if="onboardingQuestions && onboardingQuestions.question_7" class="label" for="project-teammates">{{ onboardingQuestions.question_7 }}</label>
                  <label v-else class="label" for="project-teammates">Who else is on the team?</label>
                  <email-tag-input class="email_input" id="project-teammates" v-model="project.teammates" placeholder="Email 1, Email 2, Email 3"/>
                  <p v-for="msg in errors.contributors" v-text="msg" class="form-msg error" style="clear: both;"></p>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-xs-12 col-sm-6" :class="{ error: errors.company_name && errors.company_name.length}">
                  <label v-if="onboardingQuestions && onboardingQuestions.question_8" class="label" for="project-info">{{ onboardingQuestions.question_8 }}</label>
                  <label v-else class="label" for="project-info">Describe your project here:</label>
                  <ds-textarea :rows="6" id="project-info" v-model="project.description"/>
                  <p v-for="msg in errors.company_name" v-text="msg" class="form-msg error"></p>
                </div>
                <div class="form-group col-xs-12 col-sm-6">
                  <label v-if="onboardingQuestions && onboardingQuestions.question_9" class="label" for="project-expertise">{{ onboardingQuestions.question_9 }}</label>
                  <label v-else class="label" for="project-expertise">Select the expertise that best fits your skills:</label>
                  <keyword v-for="(tag, index) in expertisesOptions" :key="index" id="project-expertise" :label="tag"
                           :class="{'keyword-selected' : project.expertises.includes(tag)}" @click="addToExpertise('project',tag)"/>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-xs-12 col-sm-6">
                  <label v-if="onboardingQuestions && onboardingQuestions.question_10" class="label" for="project-motivations">{{ onboardingQuestions.question_10 }}</label>
                  <label v-else class="label" for="project-motivations">Select what motivates you most:</label>
                  <keyword v-for="(tag, index) in motivationsOptions" :key="index" id="project-motivations" :label="tag"
                           :class="{'keyword-selected' : project.motivations.includes(tag)}" @click="addToMotivations('project',tag)"/>
                </div>
              </div>
            </div>
            <div class="onboarding-modal-section-6" v-if="currentSlide == 5">
              <div class="row onboarding-modal-section__container">
                <div class="col-xs-12 col-sm-12 onboarding-modal-section__texts-container">
                  <div class="onboarding-modal-section__counter-image-container" :style="onboardingCounterImage(3)"></div>
                  <div class="onboarding-modal-section__texts-inner-container">
                    <div class="onboarding-modal-section__texts-container-title">
                      TELL US MORE ABOUT YOUR PROJECT<br>
                      WHAT ARE YOUR CHALLENGES ?
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-xs-12 col-sm-6">
                  <label v-if="onboardingQuestions && onboardingQuestions.question_11" class="label" for="challenges-process">{{ onboardingQuestions.question_11 }}</label>
                  <label v-else class="label" for="challenges-process">Process:</label>
                  <keyword v-for="(tag, index) in processStepsOptions" :key="index" id="challenges-process" :class="{'keyword-selected' : project.process_steps.includes(tag)}"
                           :label="tag" @click="addToProcessSteps('project',tag)"/>
                  <ds-textarea placeholder="Describe the process steps of your project" v-model="project.process_step_description" :rows="6"/>
                </div>
                <div class="form-group col-xs-12 col-sm-6">
                  <label v-if="onboardingQuestions && onboardingQuestions.question_12" class="label"
                         for="challenges-business-challenges">{{ onboardingQuestions.question_12 }}</label>
                  <label v-else class="label" for="challenges-business-challenges">Rank the business challenges you are facing:</label>
                  <keyword v-for="(tag, index) in businessAspectOptions" :key="index" id="challenges-business-challenges" :label="tag"
                           :class="{'keyword-selected' : project.business_aspects.includes(tag)}" @click="addToBusinessAspects('project',tag)"/>
                  <ds-textarea placeholder="Describe the aspects of your business" v-model="project.business_aspect_description" :rows="6"/>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-xs-12 col-sm-6">
                  <label v-if="onboardingQuestions && onboardingQuestions.question_13" class="label" for="challenges-dev-process">{{ onboardingQuestions.question_13 }}</label>
                  <label v-else class="label" for="challenges-dev-process">Where are you in the development process?</label>
                  <div id="challenges-dev-process">
                    <checkbox v-for="(readinessLevel, index) in readinessLevelsOptions" :key="index" v-model="project.readiness_levels" :label="readinessLevel"
                              :val="readinessLevel" multiple class="checkbox-input"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="onboarding-modal-section-8" v-if="currentSlide == 6">
              <div class="onboarding-modal-section__container">
                <div class="onboarding-modal-section__image-container" :style="onboardingCompleteImage"></div>
                <div class="onboarding-modal-section__texts-container">
                  <div v-if="onboardingFinalTitle" class="onboarding-modal-section__texts-container-title" style="font-size: 18px">
                    {{ onboardingFinalTitle }}
                  </div>
                  <div v-else class="onboarding-modal-section__texts-container-title" style="font-size: 18px">
                    CONGRATULATIONS YOUR PROFILE IS CREATED.
                  </div>
                  <div v-if="onboardingFinalSubtitle" class="onboarding-modal-section__texts-container-description-small" v-html="onboardingFinalSubtitle"
                       style="font-size: 14px"></div>
                  <div v-else class="onboarding-modal-section__texts-container-description-small">
                    Your personal profile will be published upon curation by the WEAR expert team.
                    <br>
                    You will hear from us soon!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div>
          <fieldset class="action-buttons">
            <template v-if="currentSlide == 0">
              <input type="checkbox" v-model="acceptedTerms">
              <span>&nbsp;I agree to the
              <a :href="termsOfUseUrl" target="_blank">
                Terms of Use
              </a> and
              <a target="_blank" :href="privacyPolicyUrl">
                Privacy Policy
              </a>
            </span>
              <p v-if="errors.accepted_terms.length > 0" class="form-msg error">
                You need to accept the terms in order to sign up.
              </p>
              <br>
            </template>
            <a href="#" class="onboarding-modal__skip" v-if="currentSlide == 4 || currentSlide == 5" @click.prevent="nextSlide('skip')">Skip this step</a>
            <ds-button v-if="currentSlide == 0" @click="register" block variant="secondary" icon="chevron-right" :label="signupDone ? 'OK!' : 'Next step'"
                       :disabled=" ! acceptedTerms || busy"/>
            <ds-button v-else-if="currentSlide == 1" block label="Create your profile" variant="secondary" @click="nextSlide"/>
            <ds-button v-else-if="currentSlide == 2" block label="Next step" variant="secondary"
                       @click="validateActor({ name: userData.name, email: userData.email, actor_type: 'Person'})"
                       :disabled="busy || ! actor.description || actor.description.length < 10"/>
            <ds-button v-else-if="currentSlide == 3" block label="Next step" variant="secondary"
                       @click="validateActor({ name: company_name ? company_name : name, url: company_url, actor_type: 'LegalEntity'})"
                       :disabled="busy || company_name.length < 2 || ! company_url || ! validUrl(company_url)"/>
            <ds-button v-else-if="currentSlide == 4" block label="Next step" variant="secondary" @click="nextSlide" :disabled="busy || ! project.name || project.name.length < 2"/>
            <template v-if="currentSlide == 5">
              <ds-button v-if="errors.contributors" block label="Invite contributors" variant="secondary"
                         @click="inviteContributor(project.teammates, contributorActorData.id, true)"/>
              <ds-button v-else block label="Finish" variant="secondary"
                         @click="validateActor({ name: project.name ? project.name : company_name.length ? company_name : name, actor_type: 'Community'})" :disabled="busy"/>
            </template>
            <div v-if="currentSlide == 0">
              <p style="font-size:12px;opacity:.6;margin-top:1em;">
                <span>You already have an account? <a href="#" @click.prevent="openLoginModal">Sign in</a></span>
              </p>
            </div>
          </fieldset>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
  import { loadChunk } from '../../util/chunk-loader'
  import diffObjects from '../../util/diff-objects'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'

  import { fetchProfile, registerRequest } from '../../api/user'
  import { claimActor, createActor, inviteContributor, validateActor } from '../../api/actors'

  import Dropdown from '../Dropdown/Dropdown.vue'
  import CountryDropdown from '../Dropdown/CountryDropdown.vue'
  import Checkbox from '../Form/Checkbox.vue'
  import Modal from './Modal.vue'
  import AuthenticationModal from './AuthenticationModal.vue'
  import Tag from '../Tag/Tag.vue'
  import DsTextarea from '../Form/DsTextarea.vue'
  import DsInput from '../Form/DsInput.vue'
  import SuggestionInput from '../SuggestionInput/SuggestionInput.vue'
  import SocialAuthButtons from '../SocialAuthButtons/SocialAuthButtons.vue'
  import EmailTagInput from '../Form/EmailTagInput.vue'
  import FormGroup from '../Form/FormGroup.vue'
  import Keyword from '../../components/Keyword/Keyword.vue'
  import Avatar from '../Avatar/Avatar.vue'

  import MODAL_IDS from '../../constants/modal-ids'

  import { COUNTRIES } from '../../constants/countries'
  import { trackHeapEvent, trackMatomoEvent } from '../../util/analytics'
  import escapeHTML from '../../util/escapeHTML'
  import { DEFAULT_URLS } from '../../constants/default-urls.js'
  import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '@/constants/analytics-constants.js'

  export default {
    extends: AuthenticationModal,
    name: 'actor-sign-up-modal',
    data () {
      return {
        email: window.email || this.$route.query.email || '',
        modalId: MODAL_IDS.ONBOARDING,
        expertisesOptions: this.expertiseLabels || [],
        motivationsOptions: this.motivationLabels || [],
        readinessLevelsOptions: this.readinessLevelLabels || [],
        businessAspectOptions: this.businessAspectLabels || [],
        processStepsOptions: this.processStepLabels || [],
        actor: {
          expertises: [],
          motivations: [],
          description: '',
        },
        organisation: {
          expertises: [],
          motivations: [],
        },
        project: {
          name: '',
          description: '',
          teammates: [],
          expertises: [],
          motivations: [],
          readiness_levels: [],
          process_step_description: '',
          business_aspects: [],
          business_aspect_description: '',
          process_steps: []
        },
        userData: {},
        contributorActorData: {},
        teammatesSuccessfullyInvited: [],
        name: '',
        password: '',
        passwordConfirmation: '',
        passwordScore: 0,
        acceptedTerms: false,
        includeCompany: true,
        company_name: '',
        company_url: '',
        company_logo: '',
        company_number: '',
        company_type: '',
        jurisdiction: '',
        founding_date: '',
        company_country: '',
        errors: {
          name: [],
          email: [],
          password: [],
          message: null,
          company_name: [],
          company_url: [],
          company_number: [],
          company_country: null,
          jurisdiction: null,
          accepted_terms: [],
          contributors: null,
          addingActorName: null,
          addingActorAlreadyExists: null,
          addingActorUrl: null,
          claim: null,
          project_name: []
        },
        contributorsAdded: 0,
        signupDone: false,
        currentSlide: 0,
        busy: false,
        actorSuggestionSettings: {
          apiSettings: {
            url: '/actors/autocomplete?query={query}',
            onResponse: data => ({
              results: Object.values(data).map(({ name, logo, domain, jurisdiction, company_number, founding_date }) => ({
                name,
                logo,
                url: domain,
                jurisdiction,
                company_number,
                founding_date
              }))
            })
          },
          showNoResults: false,
          renderResults (response) {
            return response.results.map(r => {
              if (r.logo) {
                return `
                <a class="result company-suggestion">
                  <img class="company-suggestion__avatar" src="${r.logo}" />
                  <div class="company-suggestion__info">
                    <div class="company-suggestion__title">${escapeHTML(r.name)}</div>
                    <div class="company-suggestion__domain">${escapeHTML(r.url)}</div>
                  </div>
                </a>
              `
              }

              if (r.company_number) {
                return `
                <a class="result company-suggestion">
                  <div class="company-suggestion__info">
                    <div class="company-suggestion__title">${escapeHTML(r.name)}</div>
                    <div class="company-suggestion__domain">${escapeHTML(r.jurisdiction)}${escapeHTML(r.company_number)}</div>
                  </div>
                </a>
              `
              }

              return `
                <a class="result company-suggestion">
                  <div class="company-suggestion__info">
                    <div class="company-suggestion__title">${escapeHTML(r.name)}</div>
                  </div>
                </a>
              `
            })
          }
        }
      }
    },
    props: {
      isCloseable: {
        type: Boolean,
        default: true
      },
    },
    computed: {
      smallModal () {
        return this.partOfInvitation || this.socialLoginDisabled
      },
      socialLoginDisabled () {
        return this.$store.state.config.socialLoginDisabled == true
      },
      partOfInvitation () {
        var field = 'invitation'
        var url = window.location.href

        if (url.indexOf('?' + field + '=') != -1) {
          return true
        } else if (url.indexOf('&' + field + '=') != -1) {
          return true
        }

        return false
      },
      termsOfUseUrl () {
        return this.config.termsOfUseUrl || DEFAULT_URLS.TERMS_OF_USE
      },
      privacyPolicyUrl () {
        return this.config.privacyPolicyUrl || DEFAULT_URLS.PRIVACY_POLICY
      },
      jurisdictionOptions () {
        // Filter out the UK, it's not considered a legal jurisdiction
        return Object.keys(COUNTRIES).filter(countryCode => countryCode !== 'UK')
      },
      partOfOAuth () {
        return window.oauth_request
      },
      title () {
        // Determine the title of the modal, it it's not part of the oauth authorization request
        // then just return the "login" title, if it is, then make a custom title explaining to the user
        // why the login is required
        if (this.partOfOAuth) {
          return '<h2 style="display: inline;">SIGN UP</h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;with&nbsp;&nbsp;<img src="/images/logos/datascouts-one-line-logo.svg" style="height: 15px; vertical-align: middle;">'
        }

        return 'SIGN UP'
      },
      onboardingCompleteImage () {
        return { backgroundImage: `url(/images/onborading-complete.png)` }
      },
      hasChangedPerson () {
        var copyOfActor = Object.assign({}, this.actor)
        return diffObjects(this.actor, copyOfActor)
      },
      hasNotChangedOrganisation () {
        var copyOfOrganisation = Object.assign({}, this.organisation)
        return diffObjects(this.organisation, copyOfOrganisation)
      },
      hasNotChangedProject () {
        var copyOfProject = Object.assign({}, this.project)
        return diffObjects(this.project, copyOfProject)
      },
      hasPersonTypeActor () {
        if (this.userData.claims) {
          return this.userData.claims.filter(actor => actor.actor_type == 'Person').length > 0
        }
        return false
      },
      hasCommunityTypeActor () {
        if (this.userData.claims) {
          return this.userData.claims.filter(actor => actor.actor_type == 'Community').length > 0
        }
        return false
      },
      hasLegalEntityTypeActor () {
        if (this.userData.claims) {
          return this.userData.claims.filter(actor => actor.actor_type == 'LegalEntity').length > 0
        }
        return false
      },
      userSettings () {
        return this.$store.getters.userSettings
      },
      config () {
        return this.$store.state.config
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      onboardingTitle () {
        return this.config.onboardingTitle
      },
      onboardingFinalTitle () {
        return this.config.onboardingFinalTitle
      },
      onboardingSubtitle () {
        return this.config.onboardingSubtitle
      },
      onboardingFinalSubtitle () {
        return this.config.onboardingFinalSubtitle
      },
      onboardingQuestions () {
        return this.config.onboarding ? this.config.onboarding.questions : {}
      },
      actorDetail () {
        return this.$store.getters.detailActor
      },
      isActorClaimed () {
        if (this.actorDetail && this.actorDetail.id) {
          return this.actorDetail.claimed
        }
      },
    },
    methods: {
      handleCountryChange (country) {
        this.jurisdiction = country.code
        this.company_country = country.name
      },
      performValidation () {
        if (!this.name) {
          this.errors.name = ['The name field is required.']
        }

        if (!this.email) {
          this.errors.email = ['The email field is required.']
        }

        if (!this.password) {
          this.errors.password = ['The password field is required.']
        } else if (this.password.length < 6) {
          this.errors.password = [`The password must be at least 6 characters.`]
        } else if (this.passwordScore < 2) {
          this.errors.password = [`The password must be stronger.`]
        } else if (!this.passwordConfirmation) {
          this.errors.password = ['Please confirm your password.']
        } else if (this.password !== this.passwordConfirmation) {
          this.errors.password = [`Passwords don't match.`]
        }
      },
      register () {
        this.performValidation()

        if (this.errors.name.length || this.errors.email.length || this.errors.password.length || this.passwordScore < 2) {
          return
        }

        const data = {
          name: this.name,
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          invitationToken: window.invitationToken,
          includeCompany: false,
          accepted_terms: this.acceptedTerms
        }
        this.busy = true

        registerRequest(data)
          .then(data => {
            if (data.success && data.redirect_uri) {
              window.location.href = window.redirect_uri
            }

            this.signupDone = data.success
            // Update user profile
            fetchProfile()
              .then(profile => {
                this.userData = profile
                // In order to update the user profile with the 'onboardingFinished' flag
                // We need to have the user profile set and updated in the state in order to do so
                // By using 'USER/STORE_PROFILE'mutation we are storing the fetched profile in the state
                // This both solves the problem with the setting of the flag
                // as well as not needing to refresh the browser in order to have the profile updated
                this.$store.commit('USER/STORE_PROFILE', this.userData)
                this.busy = false
                this.nextSlide()
              })
            trackHeapEvent('onboarding.signUp', { name: this.name, email: this.email })
          })
          .catch(errors => {
            this.busy = false
            Object.assign(this.errors, errors)
          })
      },
      openLoginModal () {
        if (this.$route.name === 'sign-up') {
          this.$router.push('/login')
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
        }
        this.$router.push({
          query: { email: this.email }
        })
        trackHeapEvent('onboarding.openLogin', true)
      },
      nextSlide (isSkipped) {
        // If someone clicked the skip button, track the action via Heap
        if (isSkipped == 'skip') {
          trackHeapEvent('onboarding.clickedSkip', true)
          trackMatomoEvent(MATOMO_EVENT_CATEGORIES.ONBOARDING, MATOMO_EVENT_ACTIONS.ONBOARDING_STEPS_SKIP, this.currentSlide)
        }

        // If someone skips making a project, then skip all the way to the end
        if (this.currentSlide == 4 && isSkipped == 'skip') {
          this.currentSlide = 6
        }

        if (this.currentSlide < 6) {
          this.currentSlide += 1
        }
        trackHeapEvent('onboarding.clickedNext', true)
        trackMatomoEvent(MATOMO_EVENT_CATEGORIES.ONBOARDING, MATOMO_EVENT_ACTIONS.ONBOARDING_STEPS_NEXT, this.currentSlide)
      },
      onboardingCounterImage (number) {
        return { backgroundImage: `url(/images/onboarding-${number}.png)` }
      },
      claimActor (actorId) {
        this.busy = true

        claimActor(actorId)
          .then(response => {
            this.busy = false
            this.nextSlide()
            this.setOnboardingFinishedFlag()
            trackHeapEvent('onboarding.actorClaimedSuccessfuly', true)
          })
          .catch(errors => {
            this.errors.claim = errors || {}
            this.busy = false
            this.nextSlide()
            this.setOnboardingFinishedFlag()
            trackHeapEvent('onboarding.actorClaimedUnsuccessfuly', true)
          })
      },
      validUrl (url) {
        if (typeof url !== 'string') {
          return
        }

        // Fix protocol
        if (!url.includes('://')) {
          url = 'https://' + url
        }

        // Fix html code
        if (url.includes('src=')) {
          url = /src=["']([^"']+)["']/.exec(url)[1]
        }

        // Double check
        if (/^https:\/\/[a-zA-Z0-9.\-_]+\..*$/.test(url)) {
          return url
        }
      },
      addToExpertise (type, tag) {
        trackHeapEvent('onboarding.expertiseTagClicked', { type: type })
        switch (type) {
          case 'actor':
            if (this.actor.expertises.includes(tag)) {
              this.actor.expertises.splice(this.actor.expertises.indexOf(tag), 1)
            } else {
              this.actor.expertises.push(tag)
            }
            break
          case 'organisation':
            if (this.organisation.expertises.includes(tag)) {
              this.organisation.expertises.splice(this.organisation.expertises.indexOf(tag), 1)
            } else {
              this.organisation.expertises.push(tag)
            }
            break
          case 'project':
            if (this.project.expertises.includes(tag)) {
              this.project.expertises.splice(this.project.expertises.indexOf(tag), 1)
            } else {
              this.project.expertises.push(tag)
            }
            break
        }
      },
      addToMotivations (type, tag) {
        trackHeapEvent('onboarding.motivationTagClicked', { type: type })
        switch (type) {
          case 'actor':
            if (this.actor.motivations.includes(tag)) {
              this.actor.motivations.splice(this.actor.motivations.indexOf(tag), 1)
            } else {
              this.actor.motivations.push(tag)
            }
            break
          case 'organisation':
            if (this.organisation.motivations.includes(tag)) {
              this.organisation.motivations.splice(this.organisation.motivations.indexOf(tag), 1)
            } else {
              this.organisation.motivations.push(tag)
            }
            break
          case 'project':
            if (this.project.motivations.includes(tag)) {
              this.project.motivations.splice(this.project.motivations.indexOf(tag), 1)
            } else {
              this.project.motivations.push(tag)
            }
            break
        }
      },
      addToProcessSteps (type, tag) {
        trackHeapEvent('onboarding.processStepTagClicked', { type: type })
        switch (type) {
          case 'actor':
            if (this.actor.process_steps.includes(tag)) {
              this.actor.process_steps.splice(this.actor.process_steps.indexOf(tag), 1)
            } else {
              this.actor.process_steps.push(tag)
            }
            break
          case 'organisation':
            if (this.organisation.process_steps.includes(tag)) {
              this.organisation.process_steps.splice(this.organisation.process_steps.indexOf(tag), 1)
            } else {
              this.organisation.process_steps.push(tag)
            }
            break
          case 'project':
            if (this.project.process_steps.includes(tag)) {
              this.project.process_steps.splice(this.project.process_steps.indexOf(tag), 1)
            } else {
              this.project.process_steps.push(tag)
            }
            break
        }
      },
      addToBusinessAspects (type, tag) {
        trackHeapEvent('onboarding.businessAspectTagClicked', { type: type })
        switch (type) {
          case 'actor':
            if (this.actor.business_aspects.includes(tag)) {
              this.actor.business_aspects.splice(this.actor.business_aspects.indexOf(tag), 1)
            } else {
              this.actor.business_aspects.push(tag)
            }
            break
          case 'organisation':
            if (this.organisation.business_aspects.includes(tag)) {
              this.organisation.business_aspects.splice(this.organisation.business_aspects.indexOf(tag), 1)
            } else {
              this.organisation.business_aspects.push(tag)
            }
            break
          case 'project':
            if (this.project.business_aspects.includes(tag)) {
              this.project.business_aspects.splice(this.project.business_aspects.indexOf(tag), 1)
            } else {
              this.project.business_aspects.push(tag)
            }
            break
        }
      },
      handleSelectSuggestion (actor) {
        // Apply in next tick to fix v-model issue
        this.$nextTick(() => {
          this.company_name = actor.name
          this.company_url = actor.url
          this.company_logo = actor.logo
          this.jurisdiction = actor.jurisdiction
          this.founding_date = actor.founding_date
        })
      },
      addActor () {
        this.busy = true
        var parameters = []
        this.errors.addingActorUrl = null
        this.errors.addingActorName = null

        switch (this.currentSlide) {
          case 2:
            this.company_type = 'Person'
            break
          case 3:
            this.company_type = 'LegalEntity'
            break
          case 4:
          case 5:
            this.company_type = 'Community'
            break
          default:
            this.company_type = 'LegalEntity'
            break
        }

        // These are the default settings when creating a new actor.
        // New default settings can be added, removed or modified when necessary.
        var defaultSettings = {
          logo: null,
          is_email_published: true
        }

        var actorData = {
          name: this.company_name,
          actor_type: this.company_type,
          jurisdiction: this.jurisdiction,
          founding_date: this.founding_date && this.founding_date.length ? this.founding_date : null,
        }

        switch (this.company_type) {
          case 'LegalEntity':
            actorData = Object.assign(actorData, {
              name: this.company_name && this.company_name.length > 0 ? this.company_name : this.name,
              url: this.company_url,
              expertises: this.organisation.expertises,
              motivations: this.organisation.motivations,
            })
            break
          case 'Person':
            actorData = Object.assign(actorData, {
              name: this.userData.name,
              email: this.userData.email,
              description: this.actor.description,
              expertises: this.actor.expertises,
              motivations: this.actor.motivations,
            })
            break
          case 'Community':
            // In case the user skips the creation of a 'LegalEntity' actor, which is where 'company_name' is filled
            // We assign the name of the user as a name for the actor. The name property is required in order to create the actor.
            // The user can later edit and change the name of the actor.
            actorData = Object.assign(actorData, {
              name: this.project.name && this.project.name.length > 0 ? this.project.name : this.company_name && this.company_name.length > 0 ? this.company_name : this.name,
              description: this.project.description,
              expertises: this.project.expertises,
              motivations: this.project.motivations,
              readiness_levels: this.project.readiness_levels,
              process_step_description: this.project.process_step_description,
              business_aspects: this.project.business_aspects,
              business_aspect_description: this.project.business_aspect_description,
              process_steps: this.project.process_steps,
            })
            break
        }

        parameters = Object.assign({}, actorData, defaultSettings)

        createActor(parameters)
          .catch(error => {
            if (error.url) {
              this.errors.addingActorUrl = error.url.join(' ')
            }

            if (error.name) {
              if (this.currentSlide == 2 && error.name.join(' ').indexOf('A similar actor') > -1) {
                this.errors.addingActorAlreadyExists = 'A similar actor with this name already exists'
              } else {
                this.errors.addingActorName = error.name.join(' ')
              }
            }
            this.busy = false
            if (error && error.id) {
              if (!this.isMember) {
                // Go to profile page
                this.$router.push('/actors/' + error.id)
                throw new Error()
              }
            }

            throw error
          })
          .then(actor => {
            if (!actor || !actor.id) {
              throw new Error('finishCompletion expected an actor as return value')
            }

            trackHeapEvent('onboarding.addActor', { name: actor.name, id: actor.id })

            if (!this.isMember) {
              // Update user profile
              fetchProfile()
                .then(profile => {
                  this.userData = profile
                  // In order to update the user profile with the 'onboardingFinished' flag
                  // We need to have the user profile set and updated in the state in order to do so
                  // By using 'USER/STORE_PROFILE'mutation we are storing the fetched profile in the state
                  // This both solves the problem with the setting of the flag
                  // as well as not needing to refresh the browser in order to have the profile updated
                  this.$store.commit('USER/STORE_PROFILE', this.userData)
                })
            }

            if (this.company_type == 'Community' && this.project.teammates.length > 0) {
              this.contributorActorData = actor
              // If its a Community type actor and there are contributor emails to add, we add every single email
              this.project.teammates.forEach(email => {
                this.inviteContributor(email, actor.id)
              })
            } else {
              this.nextSlide()
            }
          })
          .then(() => {
            this.busy = false
          })
      },
      inviteContributor (email, actorId, force) {
        this.errors.contributors = null
        if (force) {
          var copyOfEmails = email.slice()
          // We filter out from all of the emails in the project.teammates that were already successfully invited
          // so that we can reinvite the ones that weren't this should avoid multiple invitations per email.
          copyOfEmails = copyOfEmails.filter(item => !this.teammatesSuccessfullyInvited.includes(item))

          copyOfEmails.forEach(item => {
            inviteContributor({ email: item, actor_id: actorId })
              .then(data => {
                // We go through every email before going to the nex slide
                this.contributorsAdded += 1
                if (this.project.teammates.length == this.contributorsAdded) {
                  this.nextSlide()
                }
              })
              .catch(error => {
                this.errors.contributors = error
                this.currentSlide = 4
                this.showInviteTeammates = true
              })
          })
        } else {
          inviteContributor({ email, actor_id: actorId })
            .then(data => {
              // We go through every email before going to the nex slide
              this.contributorsAdded += 1

              // We keep track of which emails were successfuly invited
              // so that we can later filter out the emails that were already invited from the ones were not
              // in the case where an error related with the invitation of a contributor went wrong
              this.teammatesSuccessfullyInvited.push(email)
              if (this.project.teammates.length == this.contributorsAdded) {
                this.nextSlide()
              }
            })
            .catch(error => {
              this.errors.contributors = error
              this.currentSlide = 4
            })
        }
      },
      validateActor (actorProperties) {
        this.busy = true

        // We search for actors that match the properties sent ex. name, email, url...
        validateActor(Object.assign({}, actorProperties))
          .then(response => {
            // If the validation is successful and no actors were found we create the actor
            this.addActor()
          })
          .catch(errors => {
            this.busy = false

            // If an actor already exists we claim that actor
            if (errors && errors.id && Array.isArray(errors.id)) {
              try {
                this.claimActor(errors.id[0])
              } catch (err) {
              }
            } else {
              // The claimActor will trigger a nextSlide() action, if that action doesn't happen, do it here
              this.nextSlide()
            }
          })
      },
      closeModal () {
        trackHeapEvent('onboarding.closeOnboarding', true)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, this.modalId)
        this.$router.push('/')
      },
      setOnboardingFinishedFlag () {
        if (!this.userSettings.onboardingFinished) {
          var userSettingsCopy = Object.assign({}, this.userSettings, { onboardingFinished: true })
          this.$store.commit('USER/STORE_SETTINGS', userSettingsCopy)
        }
      },
      checkIfOnBoardingComplete () {
        if (!this.userData.claims) {
          return
        }
        // When loading we check if the user has already claims, if so we set the onboarding as complete
        if (this.userData.claims.length > 0 && !this.userSettings.onboardingFinished) {
          var userSettingsCopy = Object.assign({}, this.userSettings, { onboardingFinished: true })
          this.$store.commit('USER/STORE_SETTINGS', userSettingsCopy)
        }
      }
    },
    mounted () {
      // If the setting provides a default country, then set the jurisdiction to that country
      if (this.config && this.config.signUpDefaultCountry) {
        this.jurisdiction = this.$store.state.config.signUpDefaultCountry
      }

      if (!this.isLoggedIn) {
        return
      }

      // If the user is logged in and has not completed the onboarding
      // We display the onboarding modal
      if (this.$store.state.user && this.$store.state.user.profile && this.$store.state.user.profile.id && !this.$store.state.user.profile.settings.onboardingFinished) {
        this.currentSlide = 1
      }

      // We check if we have user data
      if (this.$store.state.user && this.$store.state.user.profile && this.$store.state.user.profile.id) {
        this.userData = this.$store.state.user.profile
        this.name = this.userData.name
        if (this.userData.claims && this.userData.claims.length > 0) {
          this.userData.claims.forEach(claim => {
            if (claim.actor_type == 'LegalEntity') {
              this.company_name = claim.name
            }
          })
        }
      }
      this.checkIfOnBoardingComplete()
    },
    beforeUnmount () {
      window.location.reload()
    },
    watch: {
      password (password) {
        return loadChunk('zxcvbn', () => {
          const score = window.zxcvbn(password).score

          if (password.length < 6 && score > 2) {
            this.passwordScore = 2
          } else {
            this.passwordScore = score
          }
        })
      },
      currentSlide (slideNumber) {
        switch (slideNumber) {
          case 0:
            // If the user is logged in we redirect the next section of the onboarding and don't display the register form
            // This could usualy happen when logging through the social auth buttons
            if (this.isLoggedIn) {
              this.currentSlide = 1
            }
            break
          case 1:
            if (this.hasPersonTypeActor) {
              this.currentSlide = 3
            }
            break
          case 3:
            if (this.hasLegalEntityTypeActor) {
              this.currentSlide = 4
            }
            break
          case 4:
          case 5:
            if (this.hasCommunityTypeActor && !this.errors.contributors) {
              this.currentSlide = 6
            }
            break
          case 6:
            // if ((this.hasNotChangedOrganisation || this.hasNotChangedOrganisation == undefined) || (this.hasNotChangedProject || this.hasNotChangedProject == undefined)) {
            //   this.closeModal()
            // }
            break
        }
      },
      'project.process_steps' () {
        trackHeapEvent('onboarding.processStepChanged', true)
      },
      'userData.claims' (claims) {
        // If there is at least one claim we set the onboardingFinished flag
        if (claims.length > 0) {
          this.setOnboardingFinishedFlag()
        }
      }
    },
    components: {
      Modal,
      Dropdown,
      CountryDropdown,
      Checkbox,
      Tag,
      DsTextarea,
      DsInput,
      SuggestionInput,
      SocialAuthButtons,
      EmailTagInput,
      FormGroup,
      Keyword,
      Avatar
    }
  }
</script>

<style lang="scss">
  .action-buttons {
    margin-top: 15px;
  }

  .checkbox-input {
    box-sizing: unset !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    margin-top: 0px !important;
  }

  .checkbox-group {
    margin-bottom: 6px !important;
  }
</style>
