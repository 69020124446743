<template>
  <div class="personal-home">
    <div class="ph__card ph__welcome-text" v-if="!$store.state.user.profile.settings.hasClosedWelcomeText">
      <button class="onboarding-close-button" @click="closeWelcomeText">×</button>
      <h4 class="h4">
        {{ onboardingTextConfig.welcomeTitle }}
      </h4>
      <p>
        {{ onboardingTextConfig.welcomeSubtitle }}
      </p>
    </div>
    <div
      class="ph__card with-tabs"
      id="introjs-after-onboarding-personal-home-interact"
      v-if="inspireTabs && inspireTabs.length && finishedLoadingInitialData"
    >
      <tabs :tabs="inspireTabs" is-simplified :key="componentKey" onboarding-translation-tab-keys>
        <template v-slot:inspireTitle>
          <div class="ph__tabs-content">
            <p>
              {{ onboardingTextConfig.inspireDescription }}
            </p>
            <div class="row">
              <div
                v-if="!hasAccessToNewSpottingAreas"
                class="ph__card-container col-sm-4 col-xs-12"
                v-for="(portfolio, index) in availablePortfolios"
              >
                <div
                  class="ph__card ph__action-card"
                  :key="'portfolio' + index"
                >
                  <img
                    :src="getImageForPortfolio(portfolio)"
                    :alt="portfolio.name"
                    v-if="ui.isMobile"
                  >
                  <div v-else :style="`background-image: url(${getImageForPortfolio(portfolio)})`" style="height: 67.22px" class="ph__action-card-image"/>
                  <div class="ph__card-title" :title="portfolio.name">
                    {{ portfolio.name }}
                  </div>
                  <div class="ph__card-description">
                    <p v-if="portfolio.shortDescription">
                      {{ portfolio.shortDescription }}
                    </p>
                  </div>
                  <hr />
                  <div class="ph__relevant-yes-no">
                    {{ $t('personal_home_portfolio_relevant') }}
                    <br>
                    <span
                      @click="markPortfolioFavourite(portfolio)"
                      :class="{'is-active': portfolio.liked === true}"
                    >{{
                        $t('personal_home_relevant_yes')
                      }}</span>
                    <span
                      @click="markPortfolioUnFavourite(portfolio)"
                      :class="{'is-active': portfolio.liked === false}"
                    >{{
                        $t('personal_home_relevant_no')
                      }}</span>
                  </div>
                  <ds-button
                    variant="rounded" :label="$t('personal_home_portfolio_view')"
                    size="small" @click="viewPortfolio(portfolio)"
                  />
                </div>
              </div>
              <div
                v-if="hasAccessToNewSpottingAreas"
                class="ph__card-container col-sm-4 col-xs-12"
                v-for="(spottingArea, index) in availableSpottingAreas"
              >
                <div
                  class="ph__card ph__action-card"
                  :key="'spotting-area' + index"
                >
                  <img
                    :src="getImageForSpottingArea(spottingArea)"
                    :alt="spottingArea.name"
                    v-if="ui.isMobile"
                  >
                  <div v-else :style="`background-image: url(${getImageForSpottingArea(spottingArea)})`" style="height: 67.22px" class="ph__action-card-image"/>
                  <div class="ph__card-title" :title="spottingArea.name">
                    {{ spottingArea.name }}
                  </div>
                  <div class="ph__card-description">
                    <p v-if="spottingArea.shortDescription">
                      {{ spottingArea.shortDescription }}
                    </p>
                  </div>
                  <hr />
                  <div class="ph__relevant-yes-no">
                    {{ $t('personal_home_spotting_area_relevant') }}
                    <br>
                    <span
                      @click="markSpottingAreaFavourite(spottingArea)"
                      :class="{'is-active': spottingArea.liked === true}"
                    >{{
                        $t('personal_home_relevant_yes')
                      }}</span>
                    <span
                      @click="markSpottingAreaUnFavourite(spottingArea)"
                      :class="{'is-active': spottingArea.liked === false}"
                    >{{
                        $t('personal_home_relevant_no')
                      }}</span>
                  </div>
                  <ds-button
                    variant="rounded" :label="$t('personal_home_spotting_area_view')"
                    size="small" @click="viewSpottingArea(spottingArea)"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:informTitle>
          <div class="ph__tabs-content">
            <p>
              {{ onboardingTextConfig.informDescription }}
            </p>
            <div class="row" v-if="availableConceptSearches.length">
              <div
                class="ph__card-container col-sm-4 col-xs-12"
                v-for="(conceptSearch, index) in availableConceptSearches"
                :key="'concept-search' + index"
              >
                <div
                  class="ph__card ph__action-card"
                >
                  <img
                    :src="conceptSearch.image" :alt="conceptSearch.name"
                    v-if="conceptSearch.image && ui.isMobile"
                  >
                  <div v-else-if="conceptSearch.image" :style="`background-image: url(${conceptSearch.image})`" style="height: 67.22px" class="ph__action-card-image"/>
                  <div class="ph__card-title" :title="conceptSearch.name">
                    {{ conceptSearch.name }}
                  </div>
                  <div class="ph__card-description">
                    <p v-if="conceptSearch.shortDescription">
                      {{ conceptSearch.shortDescription }}
                    </p>
                  </div>
                  <hr />
                  <div class="ph__relevant-yes-no">
                    {{ $t('personal_home_resource_relevant') }}
                    <br>
                    <span
                      @click="followConceptSearch(conceptSearch)"
                      :class="{'is-active': conceptSearch.liked === true}"
                    >{{
                        $t('personal_home_relevant_yes')
                      }}</span>
                    <span
                      @click="unfollowConceptSearch(conceptSearch)"
                      :class="{'is-active': conceptSearch.liked === false}"
                    >{{
                        $t('personal_home_relevant_no')
                      }}</span>
                  </div>
                  <ds-button
                    variant="rounded"
                    :label="$t('personal_home_concept_search_view')" size="small"
                    @click="viewConceptSearch(conceptSearch)"
                  />
                </div>
              </div>
            </div>
            <div class="row" v-else>
              <div
                class="ph__card-container col-sm-4 col-xs-12"
                v-for="(resource, index) in availableResources"
                :key="'resource' + index"
              >
                <div class="ph__card ph__action-card">
                  <div class="ph__resource-title" :title="resource.title">
                    {{ resource.title }}
                  </div>
                  <div class="ph__card-description resource-description">
                    <p v-if="resource.description">
                      {{ resource.description }}
                    </p>
                  </div>
                  <hr />
                  <div class="ph__relevant-yes-no">
                    {{ $t('personal_home_resource_relevant') }}
                    <br>
                    <span
                      @click="likeResource(resource)"
                      :class="{'is-active': resource.is_liked_by_user === true}"
                    >{{
                        $t('personal_home_relevant_yes')
                      }}</span>
                    <span
                      @click="unlikeResource(resource)"
                      :class="{'is-active': resource.is_liked_by_user === false}"
                    >{{
                        $t('personal_home_relevant_no')
                      }}</span>
                  </div>
                  <ds-button
                    variant="rounded"
                    :label="$t('personal_home_resource_view')" size="small"
                    :href="getExternalLinkFromItem(resource) ? getExternalLinkFromItem(resource) : getInternalFileUrlForResource(resource)"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:interactTitle>
          <div class="ph__tabs-content">
            <p>
              {{ onboardingTextConfig.interactDescription }}
            </p>
            <div class="row">
              <div
                class="ph__card-container col-sm-4 col-xs-12"
                v-for="(announcement, index) in availableAnnouncements"
                :key="'announcement' + index"
              >
                <div class="ph__card ph__action-card">
                  <div class="ph__announcement-creator">
                    <avatar :src="announcement.user.photo_url"/>
                    <div
                      class="ph__announcement-creator-name"
                      :title="announcement.user.name"
                    >
                      {{ announcement.user.name }}
                    </div>
                  </div>

                  <div class="ph__card-title">
                    {{ announcement.title }}
                  </div>
                  <div class="ph__card-description announcement-description">
                    {{ announcement.shortDescription }}
                  </div>
                  <ds-button
                    variant="rounded"
                    :label="$t('personal_home_announcement_view', {announcement: announcementLabel})"
                    size="small" @click="viewAnnouncement(announcement)"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </tabs>
    </div>

    <div style="display: flex; margin-bottom: 0.5rem; justify-content: center;" class="ph__card with-tabs">
      <div class="ph__allow-personalisation-container">
        <div>
          {{ $t('personal_home_allow_personalised_content_toggle', {ecosystemDisplayName: ecosystemDisplayName}) }}
        </div>

        <div style="margin-left: 1rem;">
          <toggle-button
            color="success"
            :enabled="doesUserAllowPersonalisedContent"
            @update:modelValue="updateAllowPersonalisedContentSetting"
            :typeBold="false"
            :disabled="updatingAllowPersonalisedContentOption"
          />
        </div>
      </div>
    </div>

    <div class="ph__card with-tabs">
      <tabs :tabs="keywordTabs" is-simplified translate-tab-keys>
        <template v-slot:personal_home_tab_relevant_actors>
          <div class="ph__tabs-content">
            <p>
              {{ onboardingTextConfig.relevantActorsDescription }}
            </p>
            <dropdown
              multiple
              class="dropdown-without-border"
              v-model="acceptedTaxonomies"
              :options="[]"
              :suggestions="relevantTaxonomies.slice(0, 10)"
              @suggestion:accept="acceptTaxonomy($event)"
              @suggestion:decline="denyTaxonomy($event)"
              @remove="denyTaxonomy($event)"
            />
          </div>
        </template>

        <template v-slot:personal_home_tab_relevant_keywords>
          <div class="ph__tabs-content">
            <p>
              {{ onboardingTextConfig.relevantKeywordsDescription }}
            </p>
            <dropdown
              multiple
              v-model="acceptedTags"
              :options="[]"
              :suggestions="relevantTags.slice(0, 10)"
              @suggestion:accept="acceptTag($event)"
              @suggestion:decline="denyTag($event)"
              @remove="denyTag($event)"
            />
          </div>
        </template>
      </tabs>
    </div>
  </div>
</template>

<script>
  import OnboardingMixin from '../../util/OnboardingMixin.js'
  import { getExternalLinkFromItem, getImageForSpottingArea, getImageForPortfolio, getInternalFileUrlForResource } from '../../util/helpers.js'
  import {
    fetchProfile,
    getRelevantTags,
    getRelevantTaxonomies,
    updatePersonalisedContentSetting,
    updateProfile,
    updateRelevantTags,
    updateRelevantTaxonomies,
  } from '../../api/user.js'
  import { prepareUserForEditing } from '../../util/user-settings.js'
  import Tabs from '../Tabs/Tabs.vue'
  import { fetchPersonalisation, markPortfolioFavourite, markPortfolioUnFavourite, markSpottingAreaFavourite, markSpottingAreaUnFavourite } from '../../api/onboarding.js'
  import { SiteTemplate } from '../../store/modules/config.js'
  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters.js'
  import { followConceptSearch, unfollowConceptSearch } from '../../api/exploration.js'
  import { ACTION_TYPES as NOTIFICATION_ACTION_TYPES } from '../../store/modules/notifications.js'
  import { likeNotification } from '../../api/notifications.js'
  import Avatar from '../Avatar/Avatar.vue'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import { fetchKnowledgeBaseData, likeArticle } from '../../api/knowledge-base.js'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import { MUTATION_TYPES as USER_MUTATION_TYPES } from '../../store/modules/user-helpers.js'
  import UiMixin from '../../util/UiMixin.js'
  import IntroJsMixin from '../../util/IntroJsMixin.js'

  import ToggleButton from '../Form/ToggleButton.vue'

  export default {
    data () {
      return {
        updatedUserObject: this.currentUser(),
        componentKey: 1,
        portfolios: [],
        spottingAreas: [],
        finishedLoadingInitialData: false,
        conceptSearches: [],
        availableResources: [],
        relevantTags: [],
        acceptedTags: [],
        relevantTaxonomies: [],
        acceptedTaxonomies: [],
        updatingAllowPersonalisedContentOption: false
      }
    },
    computed: {
      ecosystemDisplayName () {
        return this.$store.getters.ecosystemDisplayName
      },
      availableAnnouncements () {
        return this.$store.state.notifications.listData.data.slice(0, 3)
      },
      inspireTabs () {
        const result = []

        if (!this.hasAccessToNewSpottingAreas) {
          if (this.portfolios && this.portfolios.length) {
            result.push('inspireTitle')
          }
        } else {
          if (this.spottingAreas && this.spottingAreas.length) {
            result.push('inspireTitle')
          }
        }

        if (this.$store.getters.canUsePublicAnnouncements && this.availableAnnouncements && this.availableAnnouncements.length) {
          result.push('interactTitle')
        }
        if (this.hasAccessToKnowledgeBase && this.availableConceptSearches && this.availableConceptSearches.length) {
          result.push('informTitle')
        }
        return result
      },
      keywordTabs () {
        return [
          'personal_home_tab_relevant_actors',
          'personal_home_tab_relevant_keywords',
        ]
      },
      availableConceptSearches () {
        return this.conceptSearches.slice(0, 3)
      },
      availablePortfolios () {
        return this.portfolios.slice(0, 3)
      },
      availableSpottingAreas () {
        return this.spottingAreas.slice(0, 3)
      },
      isSimplifiedTemplateUsed () {
        return [SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(this.$store.getters.activeSiteTemplate)
      },
      hasAccessToKnowledgeBase () {
        return this.$store.getters.hasAccessToKnowledgeBase
      },
      hasAccessToNewSpottingAreas () {
        return this.$store.getters.hasAccessToNewSpottingAreas
      },
      activeLocale () {
        return this.$store.state.localization.activeLocale
      },
      doesUserAllowPersonalisedContent () {
        if (!this.$store.state.user.profile || !this.$store.state.user.profile.settings) {
          return false
        }

        if (!this.$store.state.user.profile.settings.hasOwnProperty('allowPersonalisedContent')) {
          return true
        }

        return !!this.$store.state.user.profile.settings.allowPersonalisedContent
      }
    },
    methods: {
      getImageForSpottingArea,
      getImageForPortfolio,
      resetUserObjects () {
        this.updatedUserObject = this.currentUser()
      },
      getInternalFileUrlForResource,
      getExternalLinkFromItem,
      async updateAllowPersonalisedContentSetting (personalisedContentEnabled) {
        try {
          this.updatingAllowPersonalisedContentOption = true

          await updatePersonalisedContentSetting(personalisedContentEnabled)

          fetchProfile()
            .then(profile => {
              this.$store.commit(USER_MUTATION_TYPES.STORE_PROFILE, profile)

              this.updatingAllowPersonalisedContentOption = false
            })
            .catch((error) => {
              console.log(error)

              this.updatingAllowPersonalisedContentOption = false
            })
        } catch (error) {
          console.log(error)

          this.updatingAllowPersonalisedContentOption = false
        }
      },

      acceptTaxonomy (taxonomy) {
        const acceptedTaxonomy = this.relevantTaxonomies.find(relevantTaxonomy => relevantTaxonomy.value === taxonomy)
        this.relevantTaxonomies = this.relevantTaxonomies.filter(relevantTaxonomy => relevantTaxonomy.value !== taxonomy)
        this.acceptedTaxonomies.push(taxonomy)

        updateRelevantTaxonomies({
          taxonomy_id: taxonomy,
          taxonomy: acceptedTaxonomy.taxonomy,
          liked: true,
        })
      },
      denyTaxonomy (taxonomy) {
        const deniedTaxonomy = this.relevantTaxonomies.find(relevantTaxonomy => relevantTaxonomy.value === taxonomy)
        this.relevantTaxonomies = this.relevantTaxonomies.filter(relevantTaxonomy => relevantTaxonomy.value !== taxonomy)
        updateRelevantTaxonomies({
          taxonomy_id: taxonomy,
          taxonomy: deniedTaxonomy.taxonomy,
          liked: false,
        })
      },
      acceptTag (tag) {
        this.relevantTags = this.relevantTags.filter(relevantTag => relevantTag.value !== tag)
        this.acceptedTags.push(tag)
        updateRelevantTags({ id: tag, liked: true })
      },
      denyTag (tag) {
        this.relevantTags = this.relevantTags.filter(relevantTag => relevantTag.value !== tag)
        updateRelevantTags({ id: tag, liked: false })
      },
      unlikeResource (resource) {
        resource.is_liked_by_user = false
        this.saveLikeForResouce(resource)
      },
      saveLikeForResouce (resource) {
        likeArticle(resource.sql_media_id, resource.is_liked_by_user).then(() => {
          this.loadProfileData()
        })
      },
      likeResource (resource) {
        resource.is_liked_by_user = true
        this.saveLikeForResouce(resource)
      },
      toggleAnnouncementLike (announcement, like) {
        likeNotification(announcement.id, like)
          .then(() => {
            this.$store.dispatch(NOTIFICATION_ACTION_TYPES.FETCH_NOTIFICATIONS_LIST, {
              limit: this.$store.state.notifications.listData.fetchLimit,
              offset: 0,
            })
          })
      },
      viewAnnouncement (announcement) {
        this.$router.push('/announcements/' + (announcement.slug || announcement.id))
      },
      viewPortfolio (portfolio) {
        this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)
        this.$store.commit(FILTERS_MUTATION_TYPES.SET_PORTFOLIO, portfolio.id)
        if (this.isSimplifiedTemplateUsed) {
          if (portfolio.product_only) {
            this.$store.commit(FILTERS_MUTATION_TYPES.SET_ACTIVE_ACTOR_TYPES, ['Product'])
            this.$router.push('/products-simplified')
          } else {
            this.$store.commit(FILTERS_MUTATION_TYPES.SET_ACTIVE_ACTOR_TYPES, ['LegalEntity'])
            this.$router.push('/actors-simplified')
          }
        } else {
          this.$router.push('/actors')
        }
      },
      viewSpottingArea (spottingArea) {
        this.$router.push('/spotting-areas/' + spottingArea.id)
      },
      followConceptSearch (conceptSearch) {
        followConceptSearch(conceptSearch.id).then(() => {
          this.loadProfileData()
        })
      },
      unfollowConceptSearch (conceptSearch) {
        unfollowConceptSearch(conceptSearch.id).then(() => {
          this.loadProfileData()
        })
      },
      viewConceptSearch (conceptSearch) {
        if (this.isSimplifiedTemplateUsed) {
          this.$router.push(`/intelligence-simplified/${conceptSearch.id}`)
        } else {
          this.$router.push(`/dashboards/concept-map/${conceptSearch.id}`)
        }
      },
      markPortfolioFavourite (portfolio) {
        portfolio.liked = true
        markPortfolioFavourite(portfolio).then(() => {
          this.loadProfileData()
        })
      },
      markPortfolioUnFavourite (portfolio) {
        portfolio.liked = false
        markPortfolioUnFavourite(portfolio).then(() => {
          this.loadProfileData()
        })
      },
      markSpottingAreaFavourite (spottingArea) {
        spottingArea.liked = true
        markSpottingAreaFavourite(spottingArea).then(() => {
          this.loadProfileData()
        })
      },
      markSpottingAreaUnFavourite (spottingArea) {
        spottingArea.liked = false
        markSpottingAreaUnFavourite(spottingArea).then(() => {
          this.loadProfileData()
        })
      },
      currentUser () {
        const profile = this.$store.state.user.profile
        return prepareUserForEditing(profile)
      },
      closeWelcomeText () {
        this.updatedUserObject.settings.hasClosedWelcomeText = 'yes'
        this.$store.commit(USER_MUTATION_TYPES.UPDATE_PROFILE, this.updatedUserObject)
        this.$bus.emit('updateUserProfile')
        updateProfile(this.updatedUserObject).then((data) => {
          this.$store.commit(USER_MUTATION_TYPES.UPDATE_PROFILE, data)
          this.$bus.emit('updateUserProfile')
        })
      },
      loadProfileData () {
        fetchPersonalisation().then((result) => {
          this.finishedLoadingInitialData = true
          this.portfolios = result.portfolios

          if (this.hasAccessToNewSpottingAreas) {
            this.spottingAreas = result.spotting_areas
          }

          if (this.hasAccessToKnowledgeBase) {
            if (result.concept_searches && result.concept_searches.length) {
              this.conceptSearches = result.concept_searches
              setTimeout(() => {
                this.startIntroJs()
              })
            } else {
              const formattedFilters = {
                media_types: 'public_resource',
                limit: 3,
              }
              fetchKnowledgeBaseData(formattedFilters).then((result) => {
                this.availableResources = result.data.results
              }).finally(() => {
                setTimeout(() => {
                  this.startIntroJs()
                })
              })
            }
          }
        })

        getRelevantTags().then((result) => {
          this.relevantTags = result.filter(tag => !tag.liked)
          this.acceptedTags = result.filter(tag => tag.liked)
        })

        getRelevantTaxonomies().then(this.mapRelevantTaxonomies)
      },
      mapRelevantTaxonomies (result) {
        if (!result) {
          return
        }
        const flattenedTaxonomies = Object.entries(result).reduce((acc, taxonomyCount) => {
          if (taxonomyCount[1].length) {
            acc = [...acc, ...taxonomyCount[1].map((d) => {
              return { ...d, taxonomy: taxonomyCount[0] }
            })]
          }

          return acc
        }, [])
        this.relevantTaxonomies = flattenedTaxonomies.filter(tag => !tag.liked)
        this.acceptedTaxonomies = flattenedTaxonomies.filter(tag => tag.liked)
      },
      startIntroJs () {
        if (this.seenIntros.includes('personalHome')) {
          return
        }
        const config = this.buildIntroJsConfig(this.config, this.$store.getters.userRole)
        if (!config || !config.personalHome || !config.personalHome.steps) {
          return
        }

        config.personalHome.steps = this.getIntroJsStepsConfig(config.personalHome.steps)
        const intro = this.introJs().setOptions(config.personalHome)
        this.currentIntro = intro

        const componentScope = this
        intro.onbeforechange(this.introJsBeforeStepCallback.bind(this))
        intro.onafterchange(this.introJsAfterStepCallback.bind(this))
        intro.oncomplete(function () {
          componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
        })
        intro.onexit(function () {
          componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
        })

        intro.start()
      },
    },
    mounted () {
      this.$bus.on('updateUserProfile', this.resetUserObjects)
      this.loadProfileData()
      this.$store.dispatch(NOTIFICATION_ACTION_TYPES.FETCH_NOTIFICATIONS_LIST, {
        limit: this.$store.state.notifications.listData.fetchLimit,
        offset: 0,
      })
    },
    beforeUnmount () {
      this.$bus.off('updateUserProfile')
    },
    mixins: [OnboardingMixin, TranslationsMixin, UiMixin, IntroJsMixin],
    components: {
      Tabs,
      Avatar,
      Dropdown,
      ToggleButton
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  .ph__allow-personalisation-container {
    display: flex;
    height: 3rem;
    align-items: center;
    justify-content: center;
  }

  .ph__card-container {
    @media (max-width: $screen-lg) {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .ph__card {
    box-shadow: $simplified-box-shadow-config;
    border-radius: $default-border-radius;
    position: relative;
    margin-bottom: 20px;

    &.ph__action-card {
      margin-bottom: 0;
      padding: 10px;
      display: flex;
      flex-direction: column;

      &:last-child {
        margin-right: 0;
      }

      .ph__action-card-image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100%;
      }
    }

    &.with-tabs {
      background-color: var(--primary-extra-lightest);
    }
  }

  .personal-home {
    max-width: 700px;
    margin-left: 0;
    padding: 10px;
    @media (max-width: $screen-lg) {
      margin: 0 auto;
      max-width: 570px;
    }
    @media (max-width: $screen-xl) and (min-width: $screen-lg) {
      max-width: 600px;
    }
  }

  .ph__welcome-text {
    padding: 20px 70px;
  }

  h4.h4 {
    margin-bottom: 10px;
    text-align: center;
  }

  p {
    margin-bottom: 20px;
    line-height: 15px;
    letter-spacing: 0.5px;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .ph__tabs-content {
    padding: 20px;
  }

  :deep(.button__label) {
    overflow-x: hidden;
    display: block;
    text-overflow: ellipsis;
    text-align: center;
  }

  .ph__card-title {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    height: 16px;
    font-size: 14px;
    text-overflow: ellipsis;
    margin: 10px 0;
  }

  .ph__card-description {
    $lineHeight: 15px;
    $nrOfLines: 3;
    display: -webkit-box;
    width: 100%;
    height: $nrOfLines*$lineHeight;
    -webkit-line-clamp: $nrOfLines;
    line-height: $lineHeight;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    margin-bottom: 15px;

    &.announcement-description {
      $lineHeight: 15px;
      $nrOfLines: 6;
      height: $nrOfLines*$lineHeight;
      -webkit-line-clamp: $nrOfLines;
    }

    &.resource-description {
      $lineHeight: 15px;
      $nrOfLines: 6;
      height: $nrOfLines*$lineHeight;
      -webkit-line-clamp: $nrOfLines;
    }

    p {
      text-align: left;
    }
  }

  hr {
    flex-grow: 1;
    border: 1px solid var(--primary-extra-lightest);
    height: 0;
  }

  .ph__relevant-yes-no {
    margin: 10px 0;
    font-size: 12px;

    span {
      line-height: 10px;
      border: 1px solid var(--primary);
      border-radius: $default-border-radius-narrow;
      margin: 5px 5px 10px 0;
      cursor: pointer;
      padding: 5px 10px;
      display: inline-block;

      &.is-active {
        background: var(--primary-extra-lightest);
      }
    }
  }

  .ph__announcement-creator {
    display: flex;

    .ph__announcement-creator-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      line-height: 22px;
    }
  }

  .ph__resource-title {
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  :deep(.multiselect.multiselect--datascouts) {
    .multiselect__tags {
      padding: 0;
      border: 0
    }

    &.multiselect--active {
      .multiselect__tags {
        box-shadow: none !important; // important to override important in _focus-state.scss
      }
    }

    .multiselect__content-wrapper {
      display: none;
    }

    .multiselect__placeholder {
      display: none;
    }
  }
  .ph__resource-description {
    $lineHeight: 16px;
    $nrOfLines: 2;
    margin-bottom: 10px;
    margin-top: 5px;
    $lineHeight: 16px;
    $nrOfLines: 6;
    display: -webkit-box;
    width: 100%;
    height: $nrOfLines*$lineHeight;
    -webkit-line-clamp: $nrOfLines;
    line-height: $lineHeight;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }
</style>
