<template>
  <div class="static-home-community-ecosystem communities scrollable" ref="scrollable">
    <SimpleHero/>

    <!-- first wrapped container -->
    <div class="container communities-container section--service-cards">
      <!-- skeleton cards + loading -->
      <template v-if="atLeastOneElementIsLoading">
        <div class="row upcoming-events-card-list">
          <div v-for="skeleton in [0,1,2]" class="col-sm-12 col-xs-12 col-md-12 col-lg-4">
            <skeleton-card :wrapper-class="'analysis-card__skeleton'"/>
          </div>
        </div>

        <div class="container communities-container">
          <div class="community-home-loading">
            <Loading/>
          </div>
        </div>
      </template>

      <template v-if="!atLeastOneElementIsLoading">
        <!-- Cards -->
        <div class="row">
          <div class="section--service-cards__outer-container col-md-4 col-xs-6"
               v-for="card in cardData"
          >
            <template v-if="card.href === '' && card.keyName && card.keyName === 'events-with-category'">
              <a @click="goToAnnouncementsWithPredefinedCategory" class="section--service-cards__inner-container">
                <img v-if="card.image" :src="card.image" :alt="card.title">
                <h2>{{ card.title }}</h2>
                <p v-html="card.description"></p>
              </a>
            </template>
            <template v-else>
              <router-link :to="card.href" class="section--service-cards__inner-container">
                <img v-if="card.image" :src="card.image" :alt="card.title">
                <h2>{{ card.title }}</h2>
                <p v-html="card.description"></p>
              </router-link>
            </template>
          </div>
        </div>

        <!-- Recent questions -->
        <template v-if="displayAnnouncements && recentQuestions.length">
          <a @click="goToAnnouncementsWithPredefinedCategory" class="communities-title"
          >
            <div style="font-size: 18px;font-weight:bold;">
              Recent questions
            </div>
          </a>
          <div
            v-if="displayAnnouncements"
            class="communities-announcements-simple-cards row"
            style="display: flex; justify-content: space-between;"
          >
            <div
              class="vertical-challenge-container col-xs-12 col-sm-12 col-md-12"
              v-for="(announcement, index) in recentQuestions"
              :key="'announcement' + index"
            >
              <announcement-card
                :key="'announcementFeatured'"
                @clickActionButton="clickActionButton($event, announcement)"
                :available-actions-for-community-ecosystem="getAvailableActionsForCommunityEcosystemForAnnouncement(announcement)"
                :announcement="announcement"
                :enable-comment="false"
                :full-card-clickable="true"
                :show-read-more="false"
                :show-image="false"
                :show-tags="false"
              />
            </div>
          </div>
          <div class="row text-center">
            <div class="col-md-12 col-xs-12">
              <ds-button
                variant="secondary"
                :label="'View all Questions'"
                style="display:flex; width: 100%; text-align: center; justify-content: center;"
                @click="goToAnnouncementsWithPredefinedCategory"
              />
            </div>
          </div>
        </template>
      </template>
    </div>

    <!-- full-width container: Call to action -->
    <template v-if="!atLeastOneElementIsLoading && !isLoggedIn">
      <div class="static-community-call-to-action">
        <Container style="display: flex; padding: 0 0 10px 0; align-items: center;">
          <div class="col-xs-12 col-md-6 col-lg-7" style="display: flex; justify-content: center;">
            <img src="/images/static-home-page/community-service-icon-join.svg" alt="Join the DataScouts community">
          </div>
          <div class="col-xs-12 col-md-6 col-lg-5 static-community-call-to-action--content">
            <h1>Join the DataScouts community</h1>
            <p>Join an exclusive network of 1.000+ global peers and unlock your full potential as an ecosystem thinker.</p>
            <ds-button
              variant="primary"
              :label="'Join now'"
              style="font-size: 16px;"
              class="btn-join-the-community"
              @click.prevent="openLoginModal"
            />
          </div>
        </Container>
      </div>
    </template>

    <!-- second wrapped container -->
    <template v-if="!atLeastOneElementIsLoading">
      <div class="container communities-container">
        <!-- Latest announcements -->
        <template v-if="displayAnnouncements && latestFilteredAnnouncements.length">
          <a @click="goToAnnouncements" class="communities-title">
            <div style="font-size: 18px;font-weight:bold;">
              {{ $t('latest_ecosystem_announcements') }}
            </div>
          </a>
          <div
            class="communities-announcements row"
            style="display: flex; justify-content: space-between;"
          >
            <div
              class="vertical-challenge-container col-xs-12 col-sm-12 col-md-6"
              v-for="(announcement, index) in latestFilteredAnnouncements"
              :key="'announcement' + index"
            >
              <simplified-announcement-preview
                :hide-read-more-button="true"
                :show-image-actions="false"
                :is-vertical="true"
                :is-simplified="true"
                :is-container="false"
                :announcement="announcement"
              />
            </div>
          </div>
          <div class="row text-center">
            <div class="col-md-12 col-xs-12">
              <ds-button
                variant="secondary"
                :label="'View all ' + announcementsLabel"
                style="display:flex; width: 100%; text-align: center; justify-content: center;"
                @click="goToAnnouncements"
              />
            </div>
          </div>
        </template>

        <!-- Latest news & updates -->
        <template v-if="displayAnnouncements && displayEvents && events.length > 0">
          <router-link to="/events" class="communities-title">
            <div style="font-size: 18px;font-weight:bold;">
              {{ $t('announcements_upcoming_events_title') }}
            </div>
          </router-link>
          <div class="communities-events">
            <Carousel
              :items-to-show="eventCardsPerCarouselPage"
              :mouse-drag="false"
              :settings="carouselSettings(eventCardsPerCarouselPage)"
              :breakpoints="carouselBreakpoints(eventCardsPerCarouselPage)"
            >
              <Slide
                v-for="(event, index) in events"
                :key="'event' + index"
              >
                <div style="min-width: 100%; padding: 10px; text-align: left;">
                  <event-card
                    :event="event"
                    automatically-calculate-image-height
                    class="event-simplified-image-card"
                    :link="'/events/' + (event.slug || event.id)"
                  />
                </div>
              </Slide>
              <template #addons>
                <Navigation v-if="events.length > eventCardsPerCarouselPage"/>
              </template>
            </Carousel>
          </div>
          <div class="row text-center">
            <div class="col-md-12 col-xs-12">
              <ds-button
                variant="secondary"
                :label="'View all ' + eventsLabel"
                style="display:flex; width: 100%; text-align: center; justify-content: center;"
                @click="goToEvents"
              />
            </div>
          </div>
        </template>

      </div>
    </template>

    <SimplifiedFooter/>
  </div>
</template>
<script>
  import ReportMixin from '../../util/ReportMixin.js'
  import FiltersMixin from '../../util/FiltersMixin'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import AnnouncementMixin from '../../util/AnnouncementMixin.js'
  import OnboardingMixin from '../../util/OnboardingMixin.js'
  import CarouselMixin from '../../util/CarouselMixin'
  import FacetCard from '../../pages/Simplified/FacetCard.vue'
  import BasicSimplifiedHero from '../Simplified/BasicSimplifiedHero.vue'
  import { getCardsPerCarouselPageBasedOnWindowWidth, isIE11 } from '../../util/helpers.js'
  import CommunitiesCard from '../Communities/CommunitiesCard.vue'
  import SimplifiedImageCard from '../Connection/SimplifiedImageCard.vue'
  import { Carousel, Navigation, Slide } from 'vue3-carousel'
  import SimplifiedAnnouncementPreview from '../Simplified/SimplifiedAnnouncementPreview.vue'
  import AnnouncementCard from '../Simplified/AnnouncementCard.vue'
  import { fetchPublicNotifications } from '../../api/notifications.js'
  import AnnouncementOrResourceCard from '../Simplified/AnnouncementOrResourceCard.vue'
  import EventCard from '../Simplified/EventCard.vue'
  import SimplifiedFooter from '../SimplifiedFooter/SimplifiedFooter.vue'
  import Loading from './ConceptMap/Loading.vue'
  import Container from '../Container/Container.vue'
  import SimpleHero from '../SimpleHero/SimpleHero.vue'
  import IntroJsMixin from '../../util/IntroJsMixin.js'
  import NavigationMixin from '../../util/NavigationMixin.js'
  import SkeletonCard from '../Skeleton/SkeletonCard.vue'
  import { ACTION_TYPES as NOTIFICATION_ACTION_TYPES, MUTATION_TYPES as NOTIFICATION_MUTATION_TYPES } from '@/store/modules/notifications'

  export default {
    props: {
      manager: Object,
    },
    data: () => {
      return {
        atLeastOneElementIsLoading: true,
        cardsPerCarouselPage: getCardsPerCarouselPageBasedOnWindowWidth(window.innerWidth),
        featuredNotifications: [],
        events: [],
        resources: [],
        facetCounts: [],
        nonFeaturedAnnouncements: [],
        preFilteredAnnouncementCategory: {},
        submittedReportsCount: null,
        callTimeDifference: '',
        latestFilteredAnnouncements: [],
      }
    },
    computed: {
      cardData () {
        let cardData = []

        if (this.displayConsultants) {
          cardData = [...cardData,
            {
              title: `${this.onboardingTextConfig.actorsSecondTopBarTitle}`,
              description: this.onboardingTextConfig.actorsSimplifiedSubtitle ?? 'Looking for consultants that can analyse your ecosystem? Find them here.',
              image: '/images/static-home-page/community-service-icon-hand-shake.svg',
              href: '/actors-simplified',
            },
          ]
        }

        if (this.displayProducts) {
          cardData = [...cardData,
            {
              title: `${this.productsLabel}`,
              description: this.onboardingTextConfig.productsSimplifiedSubtitle ?? 'Interested in how others use our platform to manage their ecosystem? Discover them here.',
              image: '/images/static-home-page/community-service-icon-people.svg',
              href: '/products-simplified',
            },
          ]
        }

        if (this.displayAnnouncements) {
          cardData = [...cardData,
            {
              title: `${this.announcementsLabel}`,
              description: this.onboardingTextConfig.announcementsSimplifiedSubtitle ?? 'Latest news and updates, and the best content from the Datascouts Team.',
              image: '/images/static-home-page/community-service-icon-puzzle.svg',
              href: '/announcements',
            },
          ]

          const announcementCategory = this.preFilteredAnnouncementCategory

          if (announcementCategory && announcementCategory.id) {
            cardData = [...cardData,
              {
                keyName: 'events-with-category',
                title: `${announcementCategory.name}`,
                description: 'Not sure how to do something in DataScouts? Ask&nbsp;the&nbsp;community.',
                image: '/images/static-home-page/community-service-icon-team.svg',
                href: '',
              },
            ]
          }

          cardData = [...cardData,
            {
              title: `${this.eventsLabel}`,
              description: this.onboardingTextConfig.eventsSimplifiedSubtitle ?? 'Looking to meet our community of ecosystem thinkers? Participate in one of our Webinars here.',
              image: '/images/static-home-page/community-service-icon-steering-wheel.svg',
              href: '/events',
            },
            // {
            //   title: `${this.challengesLabel}`,
            //   description: this.onboardingTextConfig.challengesSimplifiedSubtitle ?? 'Looking for someone to help your business? Create&nbsp;or&nbsp;Find them here.',
            //   image: '/images/static-home-page/community-service-icon-light-bubble.svg',
            //   href: '/challenges',
            // },
          ]
        }

        if (this.displayKnowledgeBase) {
          cardData = [...cardData,
            {
              title: `${this.knowledgeBaseLabel}`,
              description: this.onboardingTextConfig.knowledgeBaseSimplifiedSubtitle ?? 'Not sure how to do something in Datascouts? Try&nbsp;searching here.',
              image: '/images/static-home-page/community-service-icon-team.svg',
              href: '/dashboards/knowledge-base',
            },
          ]
        }

        return cardData
      },
      headlineText () {
        return this.localizedDisplayProperty(this.config, 'headlineText')
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      isTeamMember () {
        return this.$store.getters.isTeamMember
      },
      isActor () {
        return this.$store.getters.isActor
      },
      userId () {
        return this.$store.getters.userId
      },
      eventCardsPerCarouselPage () {
        return 4
      },
      config () {
        return this.$store.state.config
      },
      displayProducts () {
        return this.$store.getters.viewActorTypes.includes('Product')
      },
      displayConsultants () {
        return this.isLoggedIn
      },
      displayAnnouncements () {
        return this.$store.getters.canUsePublicAnnouncements && this.$store.getters.areMessageBoardsEnabled
      },
      displayEvents () {
        return this.displayAnnouncements
      },
      displayKnowledgeBase () {
        return this.$store.getters.hasAccessToKnowledgeBase && this.config.viewDashboard && this.config.viewDashboard.includes('knowledge-base') && this.$store.getters.isNewProfileEnabled
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      ecosystemDisplayName () {
        return this.$store.getters.ecosystemDisplayName
      },
      isMember () {
        return this.$store.getters.isMember
      },
      recentQuestions () {
        return [...this.featuredNotifications, ...this.nonFeaturedAnnouncements].slice(0, 3)
      },
      recentAnnouncements () {
        return [...this.featuredNotifications, ...this.nonFeaturedAnnouncements].slice(0, 2)
      },
    },
    methods: {
      openLoginModal () {
        this.$router.push('/login')
      },
      isIE11,
      fetchUpdated (id) {
        this.$store.dispatch(NOTIFICATION_ACTION_TYPES.FETCH_UPDATED_NOTIFICATION, {
          id: id,
        })
      },
      fetchPublicNotifications () {
        if (!this.$store.getters.canUsePublicAnnouncements) {
          this.atLeastOneElementIsLoading = false

          return {}
        }

        fetchPublicNotifications(3, 0, { featured: true, is_event: false })
          .then(items => {
            this.featuredNotifications = items || []
            this.atLeastOneElementIsLoading = false
          })
        fetchPublicNotifications(3, 0, { featured: false, is_event: false })
          .then(items => {
            this.nonFeaturedAnnouncements = items || []
            this.atLeastOneElementIsLoading = false
          })
      },
      fetchLatestAnnouncements (offset = 0, count = 0) {
        this.latestFilteredAnnouncements = []
        fetchPublicNotifications(10, offset, { featured: false, is_event: false })
          .then(items => {
            this.atLeastOneElementIsLoading = false
            this.latestFilteredAnnouncements = items.filter(item => !item.announcement_categories.includes(this.preFilteredAnnouncementCategory.id)).slice(0, 2)

            if (this.latestFilteredAnnouncements.length < 2 && count < 5) {
              this.fetchLatestAnnouncements(offset + 10, count + 1)
            }
          })
      },
      fetchPreFilteredAnnouncementCategory () {
        const announcementCategory = this.availableAnnouncementCategories.filter(category => category.name === 'Product Q&A')

        if (!announcementCategory) {
          return {}
        }

        this.preFilteredAnnouncementCategory = announcementCategory[0]
      },
      goToAnnouncements () {
        this.$store.commit(NOTIFICATION_MUTATION_TYPES.RESET_ACTIVE_ANNOUNCEMENT_CATEGORIES_FILTER)

        this.$router.push('/announcements')
      },
      goToEvents () {
        this.$router.push('/events')
      },
      goToAnnouncementsWithPredefinedCategory () {
        const announcementCategory = this.preFilteredAnnouncementCategory

        if (!announcementCategory || !announcementCategory.id) {
          this.$router.push('/announcements')

          return
        }

        this.$store.commit(NOTIFICATION_MUTATION_TYPES.TOGGLE_ACTIVE_ANNOUNCEMENT_CATEGORIES_FILTER, { announcementCategory: announcementCategory, overwriteExists: true })

        this.$router.push('/announcements')
      },
      fetchEvents () {
        if (!this.$store.getters.canUsePublicAnnouncements) {
          this.atLeastOneElementIsLoading = false

          return {}
        }

        return fetchPublicNotifications(4, 0, { is_event: true, upcoming_events: true })
          .then(response => {
            this.events = response
          })
          .catch(error => {
            console.log(error)
          })
      },
      startIntroJs () {
        if (this.seenIntros.includes('homeCommunities')) {
          return
        }

        const config = this.buildIntroJsConfig(this.config, this.$store.getters.userRole)

        if (!config || !config.homeCommunities || !config.homeCommunities.steps) {
          return
        }

        config.homeCommunities.steps = this.getIntroJsStepsConfig(config.homeCommunities.steps)
        const intro = this.introJs().setOptions(config.homeCommunities)
        this.currentIntro = intro

        const componentScope = this
        intro.onbeforechange(this.introJsBeforeStepCallback.bind(this))
        intro.onafterchange(this.introJsAfterStepCallback.bind(this))
        intro.oncomplete(function () {
          componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
        })
        intro.onexit(function () {
          componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
        })

        intro.start()
      },
    },
    async mounted () {
      this.fetchPublicNotifications()
      this.$bus.on('resize', () => {
        this.cardsPerCarouselPage = getCardsPerCarouselPageBasedOnWindowWidth(window.innerWidth)
      })

      this.fetchEvents()
      this.fetchPreFilteredAnnouncementCategory()
      this.fetchLatestAnnouncements()

      this.$bus.on('announcementUpdated', (response) => {
        // Update the announcement card
        this.fetchUpdated(response.id)
      })
    },
    created () {
      /*if (!this.$store.state.communities.listData.loading && this.$store.getters.isLoggedIn) {
        this.$store.dispatch(COMMUNITY_ACTION_TYPES.FETCH_COMMUNITIES_LIST).then(() => {
          setTimeout(() => {
            this.startIntroJs()
          })
        })
      }

      if (!this.$store.state.spottingAreas.listData.loading) {
        this.$store.dispatch(SPOTTING_AREAS_ACTION_TYPES.FETCH_SPOTTING_AREAS_LIST)
      }*/
    },
    beforeUnmount () {
      if (document.querySelector('.has-headline .headline') && document.querySelector('.global-search')) {
        document.querySelector('.global-search').style.opacity = 1
      }

      this.$bus.off('resize')
    },
    mixins: [CarouselMixin, FiltersMixin, AnnouncementMixin, OnboardingMixin, TranslationsMixin, IntroJsMixin, ReportMixin, NavigationMixin],
    components: {
      SkeletonCard,
      SimpleHero,
      FacetCard,
      Loading,
      AnnouncementOrResourceCard,
      EventCard,
      BasicSimplifiedHero,
      CommunitiesCard,
      SimplifiedImageCard,
      SimplifiedAnnouncementPreview,
      AnnouncementCard,
      Slide,
      Carousel,
      Navigation,
      SimplifiedFooter,
      Container,
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables.scss";

  body {
    h1, h2, h3, h4, h5, h6, p, div, button {
      color: #323441;
    }
  }

  .section--service-cards {
    margin-top: 25px !important;
  }

  .community-home-loading {
    height: 50px;
  }

  .section--service-cards__outer-container {
    display: flex;
    text-align: center;
    min-height: 250px;

    @media (max-width: $screen-md) {
      flex-grow: 1;
    }
  }

  .section--service-cards__inner-container {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    flex-direction: column;
    border: 1px solid #B7B7BC;
    border-radius: 10px;
    padding: 10px 35px 20px 35px;
    margin-bottom: 30px;
    transition: all .3s ease-in-out;
    overflow: hidden;
    text-decoration: none;
    width: 100%;

    @media (max-width: $screen-md) {
      width: 100%;
    }

    &:hover {
      cursor: pointer;
      border-color: #00EFC3;

      img {
        transform: scale(1.1);
      }
    }

    img {
      max-height: 80px;
      transition: transform 0.3s;
      transform: scale(1);
      margin: 10px auto 25px auto;

      @media (max-width: $screen-md) {
        // max-width: 150px;
      }
    }

    p {
      margin-top: 10px;
    }
  }

  .static-home-community-ecosystem {
    .communities-title {
      margin-top: 3rem;
      margin-bottom: .5rem;
      height: 21px;

      &:hover {
        cursor: pointer;
      }
    }

    .communities-events {
      margin: 0px -10px;
    }
  }

  .communities-announcements-simple-cards {
    margin-bottom: 0px !important;
  }

  .static-community-call-to-action {
    background-color: #EBEBEC;
    margin: 50px 0;

    .container {
      flex-direction: row;

      @media (max-width: $screen-md) {
        flex-direction: column;
      }
    }

    img {
      max-height: 350px;
    }

    &--content {
      padding: 40px 0;
    }

    .btn-join-the-community {
      color: white;
      border-radius: 8px;
      background-color: var(--primary);
      padding: 2px 15px;
    }

    img {
      @media (max-width: $screen-md) {
        max-width: 200px;
      }
    }
  }

  // Custom button styling
  .button {
    font-family: $font-stack-primary;
    text-transform: none !important;

    &.button--secondary {
      font-weight: 600;
      border-radius: 8px;
      background-color: #EBEBEC;

      &:hover {
        color: #323441;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        background-color: #EBFEFA; //var(--primary-extra-lightest);
      }
    }
  }
</style>
