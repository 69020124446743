<template>
  <card variant="actor-info">
    <div class="card__general">
      <div class="card__ribbon" v-if="ribbonClass" :style="ribbonStyle"></div>
      <div class="card__title">{{ company.name }}<span class="card_product-name">{{ companyProduct }}</span></div>
      <div class="card__sub-title" v-if="company.type || company.category || industryString">{{ `${company.type || ''} ${company.category || ''} - ${industryString || ''}` }}</div>
      <div class="card__logo">
        <avatar :src="companyLogoUrl" :alt="company.name" variant="border"></avatar>
      </div>
    </div>

    <div class="card__cover" :style="coverStyling"></div>

    <div class="card__info">
      <div class="card__description">{{ shortDescription }}</div>
      <div class="card-actor-info__links">
        <div v-if="company.url">
          <icon name="world" size="small" />
          <a :href="company.url" target="_blank">{{ company.url }}</a>
        </div>
        <actor-urls :urls="company.other_urls" />
        <div v-if="company.email">
          <icon name="email-outline" size="small" />
          <a :href="`mailto:${company.email}`" target="_blank">{{company.email}}</a>
        </div>
        <div v-if="company.phone">
          <icon name="phone" size="small" />
          <a :href="`tel:${company.phone}`" target="_blank">{{company.phone}}</a>
        </div>
      </div><br>
      <card-info-social :company="company" />
      <template v-if="(! $store.getters.isPrivate || publisherActive) && $store.getters.canActorsBeContacted == true && company.claimed == true">
        <div v-if="!isLoggedIn" class="card-actor__contact-btn">
          <ds-button label="Contact" icon="email-outline" size="small" variant="secondary" @click="showSignUpModal" />
        </div>
        <div v-else class="card-actor__contact-btn">
          <ds-button label="Contact" icon="email-outline" size="small" variant="secondary" @click="showContactModal" />
        </div>
      </template>
      <p class="card__info__tags" v-if="company.tags">
        <keyword v-for="(tag, index) in company.tags" :key="index" :label="tag" />
      </p>
      <template v-if="isOwner || isTeamMember">
        <p class="card__info__tags" v-if="company.private_tags">
          <keyword v-for="(tag, index) in company.private_tags" :key="index" :label="tag"/>
        </p>
      </template>
      <div v-if="(showCompanyInfo || showCompanyFunding) && company.actor_type === 'LegalEntity'">
        <div class="card__info__columns__column__row">
          <div class="card__info__columns__stacked__column card__info__columns__employees" v-if="showCompanyInfo">
             {{companyEmployees}}<br>
            employees
          </div>
          <div class="card__info__columns__stacked__column card__info__columns__employees" v-if="showCompanyFunding">
             {{getNumberOfRounds}}<br>
            Number of rounds
          </div>
        </div>
        <div class="card__info__columns__column__row">
          <div class="card__info__columns__stacked__column card__info__columns__revenue" v-if="showCompanyInfo">
            {{companyRevenue}} <br>
            revenue
          </div>
          <div class="card__info__columns__stacked__column card__info__columns__revenue" v-if="showCompanyFunding">
            {{getTotalFunding}} EUR<br>
            Total funding
          </div>
        </div>
      </div>
      <dl class="card__info__financial-info" v-if="company.actor_type === 'Person' && company.employment">
        <dt v-if="company.employment && company.employment.role">Role</dt>
        <dd v-if="company.employment && company.employment.role">{{ company.employment.role }}</dd>

        <dt v-if="company.employment && company.employment.seniority">Seniority</dt>
        <dd v-if="company.employment && company.employment.seniority">{{ company.employment.seniority }}</dd>
      </dl>

      <dl class="card__info__financial-info" v-if="company.actor_type === 'LegalEntity'">
        <template v-if="company.stage && isOwner">
          <dt>Stage:</dt>
          <dd>{{ company.stage }}</dd>
        </template>

        <dt v-if="company.founding_date">Founded</dt>
        <dd v-if="company.founding_date">{{company.founding_date}}</dd>

        <dt v-if="company.maturity">Maturity</dt>
        <dd v-if="company.maturity">{{company.maturity}}</dd>

        <dt v-if="company.funding_stage">Funding stage</dt>
        <dd v-if="company.funding_stage">{{company.funding_stage}}</dd>

        <dt v-if="company.legal_form">Juridical form</dt>
        <dd v-if="company.legal_form">{{company.legal_form}}</dd>

        <dt v-if="company.juridical_situation">Juridical situation:</dt>
        <dd v-if="company.juridical_situation" style="font-size: small;">{{company.juridical_situation}}</dd>

        <dt v-if="company.status">Juridical status:</dt>
        <dd v-if="company.status">{{company.status}}</dd>

        <dt v-if="company.company_number">Company number:</dt>
        <dd v-if="company.company_number">{{ (company.jurisdiction || '').toUpperCase() }} {{ company.company_number }}</dd>

        <dt v-if="company.is_sme">Is SME:</dt>
        <dd v-if="company.is_sme">{{ company.is_sme ? 'Yes' : 'No' }}</dd>

        <dt v-if="company.not_for_profit">Is not-for-profit:</dt>
        <dd v-if="company.not_for_profit">Yes</dd>

        <template v-if="displayOffices">
          <dt>{{ (company.subsidiaries || []).length + 1 }} office{{ (company.subsidiaries || []).length ? 's' : '' }}<span class="hidden-sm-down">:</span></dt>
          <dd @click="toggleOffices" class="dd--toggle hidden-sm-down">{{ $store.state.ui.showOffices ? 'Hide' : 'Show' }}</dd>
        </template>
      </dl>
    </div>
  </card>
</template>

<script>
  import ActorUrls from './ActorUrls.vue'
  import Avatar from '../Avatar/Avatar.vue'
  import Card from '../../components/Card/Card.vue'
  import CardInfoSocial from '../../components/Card/CardInfoSocial.vue'
  import Keyword from '../../components/Keyword/Keyword.vue'
  import CompanyMixin from '../../util/CompanyMixin'
  import numeral from 'numeral'
  import { toAmount } from '../../util/currency'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'

  // Deprecated component
  export default {
    name: 'actor-info-card',
    replace: false,
    props: {
      actor: {
        type: Object,
        required: true
      }
    },
    computed: {
      displayOffices () {
        // Only the display offices button if the actor is a headquarter and
        // the company has subsidiaries OR
        // the company has an address
        return this.company.company_type === 'Headquarter'
          && (
            (this.company.subsidiaries && this.company.subsidiaries.length > 0)
            || (this.company.address)
          )
      },
      publisherActive () {
        return this.$store.getters.publisher
      },
      companyProduct () {
         if (this.company.product) {
          return ' - ' + this.company.product
        }
      },
      isLoggedIn () {
         return this.$store.getters.isLoggedIn
      },
      company () {
        return this.actor || {}
      },
      companyEmployees () {
        if (!this.company.current_employees || this.company.current_employees.length == 0) {
          return 'Undisclosed '
        }
        if(this.company.current_employees[0] !== undefined) {
          var employeeCount = this.company.current_employees[0].upper_bound

          if (employeeCount > 10000) {
            return '10000+'
          }

          return '~ ' + employeeCount
        }
      },
      companyRevenue () {
        if (!this.company.financials) return 'Undisclosed'

        const lastYear = this.company.financials[this.company.financials.length - 1]

        if (!lastYear || !lastYear.operating_income) return 'Undisclosed'

        return `${numeral(lastYear.operating_income).format('$ 0.00 a')}`
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      isTeamMember () {
        return this.$store.getters.isTeamMember
      },
      showCompanyInfo () {
        return this.company &&
          (this.company.employees &&
          this.company.employees.length > 0) ||
          (this.company.financials &&
          this.company.financials.length > 0)
      },
      showCompanyFunding () {
        return this.company && (
          this.company.total_funding > 0 ||
            (this.company.funding_rounds && this.company.funding_rounds.length > 0)
          )
      },
      coverStyling () {
        if (this.websiteScreenshot === '/images/ds-fallback-1920.png') {
          return { display: 'none' }
        }
        return { backgroundImage: `url(${this.websiteScreenshot})` }
      },
      getNumberOfRounds () {
        if (this.company.funding_rounds) {
          return this.company.funding_rounds.length
        }

        return 0
      },
      getTotalFunding () {
        if (this.company.total_funding) {
          return toAmount(this.company.total_funding, 5)
        }
      }
    },
    methods: {
      toggleOffices () {
        this.$store.commit('UI/TOGGLE_OFFICES')
        this.$emit('toggle-offices')
      },
      showSignUpModal () {
        //this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.SIGN_UP)
        if (this.$store.state.config.allowAdd) {
          if (this.$store.state.config.onboardingEnabled) {
            this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ONBOARDING)
          } else {
            this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ACTOR_SIGN_UP)
          }
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.SIGN_UP)
        }
      },
      showContactModal () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CONTACT)
      },
      spaced (list) {
        return list && list.length ? list.join(', ') : ''
      },
    },
    mixins: [CompanyMixin],
    components: {
      ActorUrls,
      Avatar,
      Card,
      CardInfoSocial,
      Keyword
    }
  }
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";

.dd--toggle {
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: $color-primary;
    text-decoration: none;
  }
}
</style>
