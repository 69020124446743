import { Communities } from '../../api/communities.js'
import { assertArray } from '../../util/helpers.js'

const state = {
  detail: {
    data: {},
    loading: false,
    error: null,
    errorStatusCode: null,
  },
  listData: {
    fetchLimit: 10,
    loading: false,
    data: [],
    error: null,
    endOfFeed: false,
    flushCache: false,
  },
}

export const ACTION_TYPES = {
  FETCH_COMMUNITIES_LIST: 'COMMUNITIES/FETCH_COMMUNITIES_LIST',
  FETCH_COMMUNITY_DETAIL: 'COMMUNITIES/FETCH_COMMUNITY_DETAIL',
  RESET_COMMUNITY_DETAIL: 'COMMUNITIES/RESET_COMMUNITY_DETAIL',
}

export const MUTATION_TYPES = {
  FLUSH_CACHE: 'COMMUNITIES/FLUSH_CACHE',
  FETCH_COMMUNITIES_LIST_PENDING: 'COMMUNITIES/FETCH_COMMUNITIES_LIST_PENDING',
  FETCH_COMMUNITIES_LIST_SUCCESS: 'COMMUNITIES/FETCH_COMMUNITIES_LIST_SUCCESS',
  FETCH_COMMUNITIES_LIST_FAILURE: 'COMMUNITIES/FETCH_COMMUNITIES_LIST_FAILURE',
  FETCH_COMMUNITY_DETAIL_PENDING: 'COMMUNITIES/FETCH_COMMUNITY_DETAIL_PENDING',
  FETCH_COMMUNITY_DETAIL_SUCCESS: 'COMMUNITIES/FETCH_COMMUNITY_DETAIL_SUCCESS',
  FETCH_COMMUNITY_DETAIL_FAILURE: 'COMMUNITIES/FETCH_COMMUNITY_DETAIL_FAILURE',
  RESET_COMMUNITY_DETAIL: 'COMMUNITIES/RESET_COMMUNITY_DETAIL'
}

export const actions = {
  [ACTION_TYPES.FETCH_COMMUNITIES_LIST]: ({ commit }) => {
    return Communities.get()
      .then(response => {
        commit(MUTATION_TYPES.FETCH_COMMUNITIES_LIST_SUCCESS, response)
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_COMMUNITIES_LIST_FAILURE, errors)
      })
  },
  [ACTION_TYPES.FETCH_COMMUNITY_DETAIL] ({ commit }, id) {
    if (!id) {
      console.warn('The community ID is expected to be a number')

      return
    }

    const cachedCommunity = reuseFromListData(id)
    if (cachedCommunity) {
      commit(MUTATION_TYPES.FETCH_COMMUNITY_DETAIL_PENDING, id)
      commit(MUTATION_TYPES.FETCH_COMMUNITY_DETAIL_SUCCESS, cachedCommunity)
    }

    if (state.detail.loading === id) {
      console.log('already loading detail', id)
      return
    }

    if (state.detail.errorId === id) {
      console.log('already tried loading detail', id)
      return
    }

    commit(MUTATION_TYPES.FETCH_COMMUNITY_DETAIL_PENDING, id)

    return Communities.get(id)
      .then(community => {
        commit(MUTATION_TYPES.FETCH_COMMUNITY_DETAIL_SUCCESS, community)
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_COMMUNITY_DETAIL_FAILURE, { message: ': An error occurred while fetching the community.', statusCode: errors.statusCode })
      })
  },
  [ACTION_TYPES.RESET_COMMUNITY_DETAIL] ({ commit }) {
    commit(MUTATION_TYPES.RESET_COMMUNITY_DETAIL)
  }
}

function reuseFromListData (id) {
  return state.listData.data.find(a => a.id === id)
}

function isStillOnRequestedPage (id) {
  return state.detail.loading === id
}

export const mutations = {
  [MUTATION_TYPES.FETCH_COMMUNITY_DETAIL_SUCCESS] (state, community) {
    if (isStillOnRequestedPage(community.id)) {
      state.detail.data = community
    }

    state.detail.error = null
    state.detail.loading = false
    state.detail.errorId = null
    state.detail.errorStatusCode = null
  },
  [MUTATION_TYPES.FETCH_COMMUNITY_DETAIL_FAILURE] (state, error) {
    state.detail.data = {}
    state.detail.errorId = state.detail.loading
    state.detail.error = error.message
    state.detail.loading = false
    state.detail.errorStatusCode = error.statusCode
  },
  [MUTATION_TYPES.FETCH_COMMUNITY_DETAIL_PENDING] (state, id) {
    state.detail.loading = Number(id)
  },
  [MUTATION_TYPES.FETCH_COMMUNITIES_LIST_PENDING] (state) {
    state.listData.loading = true
  },
  [MUTATION_TYPES.FETCH_COMMUNITIES_LIST_SUCCESS] (state, data) {
    state.listData.endOfFeed = data.length < state.listData.fetchLimit
    if (state.listData.flushCache) {
      state.listData.data = assertArray(data)
    } else {
      for (const community of assertArray(data)) {
        if (!state.listData.data.find(existingCommunity => existingCommunity.id === community.id)) {
          state.listData.data.push(community)
        }
      }
    }

    state.listData.flushCache = false
    state.listData.loading = false
  },
  [MUTATION_TYPES.FLUSH_CACHE] (state) {
    state.listData.endOfFeed = false
    state.listData.fetching = false
    state.listData.flushCache = true
  },
  [MUTATION_TYPES.FETCH_COMMUNITIES_LIST_FAILURE] (state, error) {
    state.listData.error = error
    state.listData.endOfFeed = true
    state.listData.loading = false
  },
  [MUTATION_TYPES.RESET_COMMUNITY_DETAIL] (state) {
    state.detail.data = {}
    state.detail.errorId = null
    state.detail.error = null
    state.detail.loading = false
    state.detail.errorStatusCode = null
  }
}

export default {
  state,
  mutations,
  actions,
}
