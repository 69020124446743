import { createJSONRequest, createResource } from './create-request'

export const ManageCommunities = createResource('/api/settings/communities')
export const Communities = createResource('/api/communities')

export const fetchCommunity = ({ communityId }) => createJSONRequest({
  url: `/api/communities/${communityId}`,
  method: 'GET',
})

export const fetchLatestActors = ({ communityId }) => createJSONRequest({
  url: `/api/communities/${communityId}/latest-actors`,
  method: 'GET',
})

export const updateCommunityMembers = ({ communityId, data }) => createJSONRequest({
  url: `/api/communities/${communityId}/members`,
  method: 'PUT',
  data,
})

export const updateCommunitiesAnnouncementsAddOn = (data) => createJSONRequest({
  url: `/api/settings/communities-update-announcements-add-on`,
  method: 'PUT',
  data: { addOnEnabled: data },
})

export const deleteCommunity = ({ communityId }) => createJSONRequest({
  url: `/api/settings/communities/${communityId}/destroy`,
  method: 'DELETE',
})

export const addCommunityMember = ({ communityId, data }) => createJSONRequest({
  url: `/api/communities/${communityId}/add-member`,
  method: 'POST',
  data,
})

export const fetchMembers = ({ communityId }) => createJSONRequest({
  url: `/api/communities/${communityId}/latest-members`,
  method: 'GET',
})

export const verifyMember = ({ communityId, userId }) => createJSONRequest({
  url: `/api/communities/${communityId}/verify-member/${userId}`,
  method: 'GET',
})

export const removeMember = ({ communityId, userId }) => createJSONRequest({
  url: `/api/communities/${communityId}/remove-member`,
  method: 'POST',
  data: { userId: userId },
})
