<template>
  <div class="file-list" v-if="modelValue.length">
    <div class="file-list__file" :class="{'file-list__file-background' : backgroundImage, 'file-list__file-hidden' : publisher && privateFilesIds.includes(file.id)}"
         v-for="file in modelValue">
      <badge v-if="publisher && backgroundImage" :name="privateFilesIds.includes(file.id) ? 'Hidden' : 'Visible'" variant="secondary" size="extra-small"
             class="file-list__visibility-badge" :class="{ 'file-list__visibility-badge-isPrivate' : privateFilesIds.includes(file.id)}" @click="toggleFileVisibility(file)"/>
      <a :href="file.url" target="_blank" @click="showGallery($event, file)">
        <div :class="{'file-list__file-background-items': backgroundImage}">
          <template v-if="backgroundImage">
            <template v-if="isImage(file)">
              <div class="file-list__file-background-image" style="position: absolute; height: 100%; width: 100%; top:0px;left:0px" :style="fileBackgroundImage(file)"></div>
            </template>
            <div class="file-list__icon-file-container">
              <icon class="file-list__icon file-list__icon-file" name="file" size="14"/>
            </div>
            <div class="file-list__date" :title="file.created_at">
              <template v-if="showDate">
                {{ toDatetimeString(file.created_at) }} &nbsp;&middot;&nbsp;
                {{ toAmount(file.size) }}B
              </template>
              <!--<span v-if="featureable && file.mimetype.startsWith('image') && userCanEdit">
                <template v-if="showDate">
                  &nbsp;&middot;&nbsp;
                </template>
                <span class="file-list__feature hidden-sm-down" tabindex="0" @click.prevent.stop="$emit('feature', file.banner === featured ? null : file)"
                      v-text="file.banner === featured ? 'Unset featured' : 'Set as featured'"
                />
              </span>-->
              <p class="file-list__void-filler" v-else></p>
            </div>
          </template>
          <template v-else>
            <icon class="file-list__icon" name="file" size="14"/>
          </template>
          <div class="file-list__info">
            <div class="file-list__name">
              <template v-if="backgroundImage">
                {{ fileName(file.title ? file.title && file.title.length && file.title !== 'File' : file.originalName, 8) }}
              </template>
              <template v-else>
                {{ shortFilename(file.title && file.title.length && file.title !== 'File' ? file.title : file.originalName) }}
              </template>
              <span class="file-list__remove" v-if="canUserDeleteFile(file)" @click.stop.prevent="showDeleteConfirmationModal(file)">&times;</span>
            </div>
            <div v-if="!backgroundImage" class="file-list__date" :title="file.created_at">
              <template v-if="showDate">
                {{ toDatetimeString(file.created_at) }} &nbsp;&middot;&nbsp;
                {{ toAmount(file.size) }}B
              </template>
              <!--<span v-if="featureable && file.mimetype.startsWith('image') && userCanEdit">
                <template v-if="showDate">
                  &nbsp;&middot;&nbsp;
                </template>
                <span class="file-list__feature hidden-sm-down" tabindex="0" @click.prevent.stop="$emit('feature', file.banner === featured ? null : file)"
                      v-text="file.banner === featured ? 'Unset featured' : 'Set as featured'"
                />
              </span>-->
            </div>
          </div>
        </div>
      </a>
    </div>
    <image-gallery :images="images" :show="show" @update:show="show = $event"/>
  </div>
</template>

<script>
  import { createFileRequest } from '../../api/create-request.js'
  import { makeFilePrivate, makeFilePublic } from '../../api/files.js'

  import ImageGallery from '../ImageGallery/ImageGallery.vue'
  import Badge from '../Badge/Badge.vue'

  import { toAmount } from '../../util/currency'
  import { toDatetimeString } from '../../util/date'

  import { clipFileName, shortFilename } from '../../util/string.ts'

  import MODAL_IDS from '../../constants/modal-ids'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { _intersect } from '../../util/helpers'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      featureable: Boolean,
      featured: String,
      modelValue: Array,
      allowEdit: {
        type: Boolean,
        default: true
      },
      showDate: {
        type: Boolean,
        default: true
      },
      backgroundImage: {
        type: Boolean,
        default: false
      },
      parentData: null, // Can be used if extra properties are needed (i.e the actor data )
      parentId: null, // Can be used if the file belongs to a property with a unique identifier (i.e. offerings)
      privateFilesIds: {
        type: Array,
        default: () => []
      },
    },
    emits: ['change', 'fileRemoved', 'privateFileListUpdate'],
    data () {
      return {
        show: null
      }
    },
    computed: {
      publisher () {
        return this.$store.getters.publisher
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      isTeamMember () {
        return this.$store.getters.isTeamMember

      },
      isProfileOwner () {
        if (this.parentData.claimed_by !== undefined) {
          return this.parentData.claimed_by == this.$store.getters.userId
        }
      },
      userContributorClaims () {
        return window.user.claims.map(claim => {
          if (claim.claim_type == 'actor-contributor') {
            return claim.id
          }
        })
      },
      actorPortfolios () {
        return this.parentData.includedPortfolios || []
      },
      userCanEdit () {
        return (this.$store.getters.isMember && this.$store.state.config.allowEdit == true)
          || this.$store.getters.userClaimedIds.includes(this.parentData.id)
          || (_intersect(this.$store.getters.userContributionPortfolios, this.actorPortfolios).length > 0 && this.$store.getters.hasAccessToPortfolioSharing && this.$store.getters.isPortfolioMember)
          || this.$store.getters.isOwner
      },
      images () {
        return this.modelValue
          .filter(file => file.mimetype.startsWith('image'))
          .map(file => Object.assign({
            src: file.preview_w766 || file.preview_w320 || file.preview_w50,
          }, file))
      }
    },
    methods: {
      canUserDeleteFile (file) {
        return this.userCanEdit && file.deletable && this.allowEdit
      },
      shortFilename,
      clipFileName,
      toAmount,
      showDeleteConfirmationModal (file) {
        var modalContextData = Object.assign(file, { modalContextType: 'file' });

        this.$store.commit('UI/SET_MODAL_CONTEXT', modalContextData);
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION);
      },
      remove (file) {
        createFileRequest({
          method: 'DELETE',
          url: file.url
        }).then(() => {
          this.$emit('fileRemoved', file, this.parentId)
          this.$emit('change');
        })
      },
      showGallery (event, file) {
        if (file.mimetype.startsWith('image')) {
          this.show = file.id

          event.preventDefault()
        }
      },
      isImage (file) {
        if (file.mimetype.indexOf('image') > -1) {
          return true
        }
        return false
      },
      fileBackgroundImage (file) {
        if (!this.backgroundImage) {
          return
        }

        if (this.privateFilesIds.includes(file.id)) {
          return `background-image: url('${file ? file.preview_w320 || file.preview_w50 || file.url : null}');background-size: cover;background-position: center; opacity: 0.5`
        }

        return `background-image: url('${file ? file.preview_w320 || file.preview_w50 || file.url : null}');background-size: cover;background-position: center;`
      },
      toggleFileVisibility (file) {
        // In order to update the visibility of the file we need the actor id and file id
        // The actor id can be passed through the 'parentData' property
        if (this.parentData.id) {
          if (this.privateFilesIds.includes(file.id) == true) {
            makeFilePublic(this.parentData.id, file.id).then(state => {
              this.$emit('privateFileListUpdate', file.id)
            }).catch(error => {
              console.log(error, 'File could not be made Public')
            })
          } else {
            makeFilePrivate(this.parentData.id, file.id).then(state => {
              this.$emit('privateFileListUpdate', file.id)
            }).catch(error => {
              console.log(error, 'File could not be made Private')
            })
          }
        }
      },
      fileName (string, stringLength) {
        if (!string) {
          return string;
        }

        // Will return a cliped file name so that the close button doesn't get pushed out of sight
        return clipFileName(string, stringLength)
      },
      toDatetimeString
    },
    created () {
      this.$bus.on('fileDeleteConfirmation', (data) => {
        this.remove(data);
      })
    },
    beforeUnmount () {
      this.$bus.off('fileDeleteConfirmation');
    },
    components: {
      ImageGallery,
      Badge
    }
  })
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  .file-list {
    margin-top: 1em;
    line-height: 1.2em;
  }

  .file-list__file {
    display: block;
    color: inherit;

    &:hover {
      .file-list__icon {
        opacity: 1;
      }
    }

    + .file-list__file {
      margin-top: 1rem;
    }

    a {
      color: $color-text-grey;
    }
  }

  .file-list__icon {
    float: left;
    opacity: .5;

    .fill-fg {
      fill: $color-secondary;
    }
  }

  .file-list__info {
    margin-left: 22px;
    padding: 0.25rem;
  }

  .file-list__date {
    opacity: .5;
    font-size: 12px;
    margin-top: 5px;
  }

  .file-list__remove {
    display: inline-block;
    vertical-align: middle;
    margin: -.5em .25em;
    padding: .5em;
    cursor: pointer;
    user-select: none;

    &:hover {
      background: #FD916D;
      color: white;
    }
  }

  .file-list__file-hidden {
    border: 2px dashed $color-private-data !important;

    .file-list__file-background-items {
      background-color: white !important;

      .file-list__info {
        background-color: $color-private-data !important;
      }

      .file-list__icon-file-container {
        .file-list__icon-file {
          svg {
            path {
              fill: $color-private-data !important;
            }
          }
        }
      }
    }
  }

  .file-list__file-background {
    position: relative;

    .file-list__file-background-image {
      opacity: 0.5 !important;
    }

    .file-list__file-background-items {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: black;

      .file-list__info {
        background-color: $color-secondary;
      }

      .file-list__void-filler {
        margin: 17px 0;
      }

      .file-list__remove {
        padding: .1em .2em;
      }

      .file-list__icon-file-container {
        display: block;
        height: 60%;
        width: 35%;

        .file-list__icon-file {
          position: relative;
          width: 100%;
          height: auto;
          top: 50%;
          display: block !important;
          opacity: 1 !important;

          svg {
            path {
              fill: white;
            }
          }
        }

        .file-list__icon-file-visible {
          display: block !important;

          .file-list__icon-file {
            display: block;
            width: 35%;
            height: 60%;
          }
        }
      }
    }

    .file-list__info {
      margin-left: unset;
      position: relative;
      z-index: 2;

      .file-list__name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: white;
        position: relative;
        z-index: 1;
      }
    }

    .file-list__date {
      opacity: 0;
      position: relative;
      z-index: 1;
      width: 100%;
      text-align: center;
      background-color: rgba(256, 256, 256, 0.7);
    }

    .file-list__visibility-badge {
      position: absolute;
      top: -8px;
      left: 5px;
      border-radius: 5px;
      background-color: $color-secondary;
      z-index: 999;
      cursor: pointer;
      font-size: 8px;
      line-height: 10px;
      min-height: 15px;

      svg {
        width: 10px;
        height: 10px;
        margin-left: 2px;
      }

      .fill-fg {
        fill: white;
      }
    }

    .file-list__visibility-badge-isPrivate {
      background-color: $color-background-light-grey;
      color: $color-secondary;
      font-weight: 500;
    }

    a {
      display: inline-flex;
      align-items: center;
      width: 100%;
      height: 100%;
      color: $color-text-grey;
    }
  }

  .file-list__file-background:hover {
    .file-list__info {
      .file-list__name {
        opacity: 1;
      }
    }

    .file-list__date {
      opacity: 1;
    }
  }
</style>
