<template>
  <modal :id="modalId"
         ref="modal"
         :title="$t('community_create')"
         @close="close"
         is-simplified-modal
  >
    <!-- Modal header tabs -->
    <template v-slot:body>
      <div class="community-edit__title-container">
        <div class="mandatory-message">
          <i>{{ $t('all_fields_with_asterisk_are_mandatory') }}</i>
        </div>

        <ds-input
          v-model="community.name"
          :placeholder="$t('community_edit_title') + ' *'"
          :maxLength="30"
        />
        <p v-if="fieldErrors && fieldErrors.name != null" style="display: inline-block; margin-right: 1em;" class="guide_text form-group__error">{{ fieldErrors.name[0] }}</p>
      </div>

      <div class="community-edit__content-container">

        <form-group label="Community Manager *"
                    class="ecosystem-member-communities__general-input">
          <autocomplete-dropdown
            is-simplified
            :placeholder="$t('communities_search_for_users')"
            :suggestions="displayedUserSuggestions"
            :allow-clear="true"
            :do-search="autocompleteSearchTerm"
            v-model="community.managers"
            :multiple="false"
          />
        </form-group>
        <br>

        <form-group label="Description *">
          <ds-textarea
            placeholder="Provide a description of your community"
            v-model="community.description"
            class="communities__description"
          />
        </form-group>
        <br>
        <!--<image-input v-model="community.originalImage"
                     @input="community.image = $event"
                     width="510"
                     height="131.443298969"
                     :is-simplified="true"/-->
      </div>
    </template>
    <template v-slot:footer>
      <div style="width: 100%;">
        <ds-button
          variant="primary"
          @click="submit()"
          :label="$t('community_create')"
          size="extra-small"
          :disabled="!canSubmit"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import DsTextarea from '../Form/DsTextarea.vue'
  import ImageInput from '../Form/ImageInput.vue'
  import AutocompleteDropdown from '../Dropdown/AutocompleteDropdown.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'

  import { ManageCommunities } from '../../api/communities'
  import { searchUser } from '../../api/config'

  export default {
    name: 'CreateCommunityModal',
    data () {
      return {
        modalId: MODAL_IDS.CREATE_COMMUNITY,
        community: {
          name: '',
          description: '',
          image: '',
          managers: [],
          members: [],
        },
        fieldErrors: {},
        userSuggestions: [],
        errors: {},
        saving: false
      }
    },
    computed: {
      displayedUserSuggestions () {
        return this.userSuggestions
      },
      canSubmit () {
        return this.community.name &&
          this.community.name.length > 2 &&
          (this.community.managers && this.community.managers.value) &&
          this.community.description.length >= 10 &&
          !this.saving
      },
    },
    methods: {
      autocompleteSearchTerm (query) {
        return searchUser({
          query: query,
          role: 'member'
        })
          .then(suggestions => {
            const options = []
            suggestions = this.removeDuplicates(suggestions)

            suggestions
              .forEach(function (user) {
                options.push({
                  label: user.name + ' (' + user.email + ')',
                  value: user.id
                })
              })

            return options
          })
          .catch(err => {
            console.log(err)
          })
      },
      removeDuplicates (suggestions) {
        return suggestions
      },
      submit () {
        this.saving = true
        this.fieldErrors = null
        this.errors = null

        ManageCommunities
          .post(this.community)
          .then(response => {
            setTimeout(() => {
              this.$bus.emit('communityCreated')

              this.success()
            }, 1000)
          })
          .catch(errors => {
            this.saving = false
            this.fieldErrors = errors
            this.errors = Object.keys(errors).filter(key => key !== 'statusCode')
          })
      },
      success () {
        this.saving = false

        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          message: this.$t('community_create_portfolio_success')
        })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.SUCCESS)
      },
      close () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.CREATE_COMMUNITY)
        this.$emit('close')
      }
    },
    components: {
      DsTextarea,
      ImageInput,
      Modal,
      AutocompleteDropdown
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  .community-edit__title-container {
    background: var(--primary-extra-lightest);
    padding: 20px;

    :deep(input) {
      border: 0;
      border-radius: $default-border-radius-narrow;

      &::placeholder {
        color: var(--primary);
      }
    }
  }

  .communities__description {
    min-height: 75px;
    margin-bottom: 20px;
  }

  .community-edit__content-container {
    padding: 20px 20px 17px 20px;

    :deep(input) {
      border: 1;
      border-radius: $default-border-radius-narrow;
      border-color: var(--primary-extra-lightest) !important;

      &::placeholder {
        color: var(--primary);
      }
    }
  }

  .modal__footer {
    width: 100%;

    .button {
      width: 100%;
      border-radius: $default-border-radius-narrow;
    }
  }

  .d-flex {
    display: flex;
  }

  .form-group {
    margin-bottom: 0px;
  }
</style>
