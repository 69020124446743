<template>
  <div class="static-home-open-creatives scrollable" @scroll="updateSelectedPage">
    <div class="static-home-open-creatives__slider" id="first">
      <Carousel :autoplay="10000" :wrap-around="true">
        <Slide v-for="slide in images" :key="slide">
          <div :style="{ 'background-image': 'url(' + slide.image + ')' }" class="carousel__item banner_image"></div>
          <div class="image-timer" :style="{ width: progressWidth}"></div>
        </Slide>
      </Carousel>
      <div class="go-to-next-section_button">
        <a href="#second">
          <icon name="circle-down" size="22"/>
        </a>
      </div>
      <div class="static-home-open-creatives__content">
        <div class="static-home-open-creatives__title">
          <h1>Platform voor de creatieve- en cultuursector in en rond Gent</h1>
        </div>
        <div class="static-home-open-creatives__buttons">
          <ds-button href="#second" to="#second" target="_self" label="Voor creatievelingen" variant="secondary"/>
          <ds-button href="#third" to="#third" target="_self" label="Voor organisaties" variant="secondary"/>
        </div>
        <div class="navigation_circles">
          <a href="#first">
            <div :class="{'active': selectedPage === 'first'}"></div>
          </a>
          <a href="#second">
            <div :class="{'active': selectedPage === 'second'}"></div>
          </a>
          <a href="#third">
            <div :class="{'active': selectedPage === 'third'}"></div>
          </a>
        </div>
      </div>
    </div>
    <div class="second-banner__image" id="second">
      <div class="static-home-open-creatives__second-title">
        <h1>Vind en word gevonden! Connecteer, interageer, deel en creëer in onze creatieve community. Naar wat ben jij op zoek?</h1>
      </div>
      <div class="static-home-open-creatives__second-tab-buttons">
        <ds-button to="/actors-simplified" label="Community"/>
        <ds-button to="/products-simplified" label="Ruimtes"/>
        <ds-button to="/events-simplified" label="Events"/>
        <ds-button to="/announcements-simplified" label="Kansen"/>
      </div>
      <div class="static-home-open-creatives__register-button">
        <ds-button @click="openSignupModal" label="Maak een account en word gevonden"/>
      </div>
      <div class="go-to-next-section_button">
        <a href="#third">
          <icon name="circle-down" size="22"/>
        </a>
      </div>
    </div>
    <div class="third-banner__image" id="third">
      <div class="static-home-open-creatives__second-title">
        <h1>Word als organisatie gevonden, plaats je ruimte online of voeg vacatures en andere kansen toe.</h1>
      </div>
      <div class="static-home-open-creatives__third-tab-buttons">
        <ds-button to="/actors-simplified" label="Ontdek de community"/>
        <ds-button @click="openSignupModal" label="Maak een account aan"/>
      </div>
      <div class="go-to-next-section_button">
        <a href="#first">
          <icon name="circle-up" size="22"/>
        </a>
      </div>
    </div>
    <SimplifiedFooterOpenCreatives style="position: relative;"/>
  </div>
</template>


<script lang="ts">
  import SimplifiedFooterOpenCreatives from '../SimplifiedFooter/SimplifiedFooterOpenCreatives.vue'
  import { Carousel, Navigation, Slide } from 'vue3-carousel'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import { trackHeapEvent } from '../../util/analytics.js'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import {defineComponent} from "vue";
  export default defineComponent({
    components: {
      SimplifiedFooterOpenCreatives,
      Carousel,
      Slide,
      Navigation,
    },
    data () {
      return {
        progressWidth: null,
        progressInterval: null,
        selectedPage: 'first',
        images: [
          {
            image: '/images/static-home-page/openCreatives/static-home-opencreatives-first-image.png',
          },
          {
            image: '/images/static-home-page/openCreatives/static-home-opencreatives-second-image.png',
          },
          {
            image: '/images/static-home-page/openCreatives/static-home-opencreatives-third-image.png',
          },
          {
            image: '/images/static-home-page/openCreatives/static-home-opencreatives-fourth-image.png',
          },
        ],
      }
    },
    computed: {},
    methods: {
      updateSelectedPage (event) {
        if (event.target.scrollTop > (event.target.scrollHeight - 91) * 2 / 3) {
          this.selectedPage = 'third'
        } else if (event.target.scrollTop > (event.target.scrollHeight - 91) / 3) {
          this.selectedPage = 'second'
        } else {
          this.selectedPage = 'first'
        }
      },
      openSignupModal () {
        trackHeapEvent('staticHomePage.topBarRegister')
        if (this.$store.getters.hasAccessToEcosystemMemberPackages) {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.PACKAGES_SIGN_UP)
        } else if (this.$store.state.config.allowAdd) {
          if (this.$store.state.config.onboardingEnabled) {
            this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ONBOARDING)
          } else {
            this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ACTOR_SIGN_UP)
          }
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.SIGN_UP)
        }
      },
      imageTimer () {
        let startDate = new Date().getTime()
        let newDate = new Date().getTime()
        this.progressInterval = setInterval(() => {
          newDate = new Date().getTime()
          const difference = newDate - startDate
          if (difference < 10000) {
            this.progressWidth = `${difference / 100}%`
          } else {
            startDate = new Date().getTime()
          }
        }, 10)
      },
    },
    mounted () {
      this.imageTimer()
    },
    beforeUnmount () {
      clearInterval(this.progressInterval)
    },
  })
</script>


<style lang="scss" scoped>
  @import "resources/assets/scss/variables";

  .static-home-open-creatives {
    scroll-behavior: smooth;

    h1, p, a, li {
      font-family: Segoe UI, sans-serif;
    }

    .static-home-open-creatives__slider {
      position: relative;


      .image-timer {
        background-color: #fff;
        opacity: 0.3;
        height: 10px;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    .banner_image {
      width: 100%;
      height: calc(100vh - 100px);
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
    }

    .go-to-next-section_button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 20px;
      left: calc(50% - 25px);

      :deep(.svg-icon) {
        width: 50px;
        height: 50px;

        path {
          fill: rgba(255, 255, 255, 0.4);
        }
      }
    }

    .static-home-open-creatives__content {
      position: absolute;
      bottom: 30%;
      width: 100%;
    }

    .navigation_circles {
      display: flex;
      flex-direction: column;
      position: fixed;
      z-index: 1;
      left: calc(100% - 70px);
      bottom: calc(50vh - 50px);

      div {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.4);
        margin-bottom: 10px;
      }

      .active {
        background-color: white;
      }
    }

    .static-home-open-creatives__title {

      h1 {
        color: white;
        text-align: center;
        width: 41%;
        margin: auto;
        font-size: 60px;
      }
    }

    .static-home-open-creatives__buttons {
      display: flex;
      justify-content: center;
      margin-top: 40px;


      a:nth-child(1) {

      }

      .button:nth-child(1) {
        border-radius: 4px;
        border: 3px solid #102774;
        background-color: rgba(16, 39, 116, 0.8);

      }

      .button:nth-child(2) {
        border-radius: 4px;
        border: 3px solid #46BA7F;
        background-color: rgba(70, 186, 127, 0.8);

      }

      .button {
        margin-right: 20px;
        text-transform: lowercase;
      }

      :deep(.button__label) {
        color: #fff;
      }

      :deep(.button__label:first-letter) {
        text-transform: capitalize !important;
      }
    }

    .second-banner__image {
      background-image: url("/images/static-home-page/openCreatives/static-home-opencreatives-home-first-image.png");
      width: 100%;
      height: calc(100vh - 100px);
      background-size: cover;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      position: relative;

    }


    .static-home-open-creatives__second-title h1 {
      color: #fff;
      width: 63%;
      margin: auto;
      font-size: 50px;
      text-align: center;
    }


    .static-home-open-creatives__second-tab-buttons {
      display: flex;
      justify-content: center;
      margin-top: 40px;


      .button {
        border-radius: 4px;
        border: 3px solid #102774;
        background-color: rgba(16, 39, 116, 0.8);

        :deep(.button__label) {
          color: white;
          text-transform: capitalize;
        }
      }
    }

    .static-home-open-creatives__register-button {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .button {
        border-radius: 4px;
        border: 3px solid #46BA7F;
        background-color: rgba(70, 186, 127, 0.8);


        :deep(.button__label) {
          color: white;
          text-transform: lowercase;
          font-family: Segoe UI, sans-serif;
        }

        :deep(.button__label:first-letter) {
          text-transform: capitalize;
        }
      }
    }


    .third-banner__image {
      background-image: url("/images/static-home-page/openCreatives/static-home-opencreatives-home-second-image.png");
      width: 100%;
      height: calc(100vh - 100px);
      background-size: cover;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      position: relative;

    }


    .static-home-open-creatives__third-tab-buttons {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;


      .button {
        margin-bottom: 1rem;
        margin-right: 5px;
        text-transform: lowercase;
      }


      .button:nth-child(1) {
        border-radius: 4px;
        border: 3px solid #102774;
        background-color: rgba(16, 39, 116, 0.8);
      }

      .button:nth-child(2) {
        border-radius: 4px;
        border: 3px solid #46BA7F;
        background-color: rgba(70, 186, 127, 0.8);
      }


      :deep(.button__label) {
        color: white;
        font-family: Segoe UI, sans-serif;
      }

      :deep(.button__label:first-letter) {
        text-transform: capitalize !important;
      }
    }

    @media(max-width: $screen-lg) {
      .static-home-open-creatives__title, .static-home-open-creatives__second-title {
        h1 {
          width: 80%;
        }
      }

    }

    @media(max-width: $screen-md) {
      .static-home-open-creatives__title, .static-home-open-creatives__second-title {
        h1 {
          width: 63%;
          font-size: 25px;
        }
      }

      .navigation_circles {
        display: none;
      }

      .static-home-open-creatives__buttons {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        align-items: center;
        text-align: center;
        justify-content: center;

        .button {
          margin-right: 0;
        }

        a {
          margin-bottom: 15px;
        }
      }

      .static-home-open-creatives__second-tab-buttons {
        width: 43%;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        .button {
          margin-right: 0;
        }

        a {
          margin-bottom: 15px;
        }
      }
    }
    @media(max-width: $screen-xs) {
      .static-home-open-creatives__second-tab-buttons {
        gap: 0rem;
        width: 51%;
      }
    }
    @media(max-width: $screen-xs-max) {
      .static-home-open-creatives__title, .static-home-open-creatives__second-title {
        h1 {
          width: 63%;
          font-size: 20px;
        }
      }
      .static-home-open-creatives__second-tab-buttons {
        width: 53%;
      }
    }
  }
</style>
