<template>
  <div class="users-list fixed-heading" :class="{ scrollable: fullScroll }">
    <div class="heading">
      <h1>User management</h1>
      <p class="guide_text">Add or remove user profiles.</p>
      <ds-input
        v-model="filters.q" placeholder="Search by username, email or claim..."
        style="margin-top:9px"
      />
      <br>
      <div class="users__cta-container">
        <ds-button variant="primary" icon="plus" size="small" label="Add an ecosystem member" @click="showAddUserModal" v-if="canAddEcosystemMembers"/>
        <ds-button variant="primary" icon="plus" size="small" label="Invite admins" v-if="isNewProfileEnabled && isOwner" @click="showAddUserPanel"/>
        <ds-button v-if="isOwner" size="small" icon="chevron-down" label="Export user list" @click="exportCsv"/>

        <community-dropdown v-if="hasAccessToNewCommunities" :initial-community-id="filters.community" @selected-community-changed="updateCommunityFilter"/>

        <span style="margin-left:1rem;">Results:
        {{ numberOfResults !== null ? ' ' + numberOfResults + ' users' : '' }}
        {{ filtersAsString }}
        <template v-if="filtersAsString">
          - <span @click="clearFilters" class="a">clear filters</span>
        </template>
      </span>
      </div>
    </div>
    <div v-if="loading">
      <loading/>
    </div>
    <div :class="fullScroll ? 'not-scrollable' : 'scrollable'" style="overflow-x: auto;" v-else>
      <table class="table" style="border: 0px; word-break: break-all;">
        <thead>
        <tr>
          <th-sort by="name" :options="filterOptions.verified" v-model="filters.verified">
            Name
          </th-sort>
          <th-sort by="role" :options="filterOptions.role" v-model="filters.role">Role
          </th-sort>
          <th-sort by="email">Email</th-sort>
          <th>Actions</th>
          <th v-if="canStartConversation && canShowConversations">Conversation</th>
          <th style="white-space: nowrap">Subscribed to</th>
          <th-sort by="subscribed_plan" v-if="hasAccessToEcosystemMemberPackages">Package
            Plan
          </th-sort>
          <th-sort by="last_activity">Last login</th-sort>
          <th-sort by="content_activity" v-if="hasAccessToKnowledgeBase">Content</th-sort>
          <th-sort by="announcement_activity">{{ announcementsLabel }}</th-sort>
          <th-sort by="actor_activity">Actors</th-sort>
          <th-sort by="created_at">Last invitation sent</th-sort>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="user in users" @click="openUserSidePanel(user)"
          style="cursor: pointer;"
        >
          <template v-if="! user.invited_user">
            <td>
              {{ user.name }}
              <badge
                v-if="user.team_verified === false" name="Needs Curation"
                size="extra-small" style="float:right;"
              />
              <badge
                v-if="! user.verified" name="Unverified Email" size="extra-small"
                style="float:right;margin-right:5px"
              />
            </td>
            <td>
              <template v-if="! canEditUser(user)">
                {{ normaliseRole(user.role) }}
              </template>
              <a v-else href="#" @click.stop="showUserEditModal(user, 'edit')">{{
                  normaliseRole(user.role)
                }}</a>
            </td>
            <td><a :href="'mailto:' + user.email">{{ user.email }}</a></td>
            <td>
              <template v-if="canEditUser(user)">
                <ds-button
                  v-if="user.verified === false"
                  @click.stop="showUserEditModal(user, 'resend-confirmation')"
                  icon="email-outline" size="extra-small" label="resend verify email"
                />
                <ds-button
                  v-if="user.team_verified !== false"
                  @click.stop="showUserEditModal(user, 'remove')" icon="remove"
                  size="extra-small" label="remove"
                />
                <template v-else>
                  <ds-button
                    @click.stop="showUserEditModal(user, 'accept')" icon="check"
                    size="extra-small" label="accept"
                  />
                  <ds-button
                    @click.stop="showUserEditModal(user, 'deny')"
                    size="extra-small" label="deny" variant="secondary"
                  />
                </template>
              </template>
            </td>
            <td v-if="canStartConversation && canShowConversations">
              <ds-button
                v-if="hasExistingConversation(user)" size="extra-small"
                label="open conversation"
                @click="openConversation($event, user)"
              />
              <ds-button
                v-else-if="user.id != userId" size="extra-small"
                label="start conversation"
                @click="startConversation($event, user)"
              />
            </td>
            <td>{{ subscriptionsOf(user) }}</td>
            <td v-if="hasAccessToEcosystemMemberPackages">{{ user.subscribed_plan }}</td>
            <td>{{ user.last_activity }}</td>
            <td v-if="hasAccessToKnowledgeBase">{{ user.content_activity }}</td>
            <td>{{ user.announcement_activity }}</td>
            <td>{{ user.actor_activity }}</td>
            <td></td>
          </template>
          <template v-else>
            <td>
              invited user
            </td>
            <td>
              {{ normaliseRole(user.role) }}
            </td>
            <td><a :href="'mailto:' + user.email">{{ user.email }}</a></td>
            <td>
              <template v-if="canEditInvitation(user)">
                <ds-button
                  @click="showUserEditModal(user, 'cancel-invitation')"
                  icon="remove" size="extra-small" label="cancel"
                />
                <ds-button
                  @click="showUserEditModal(user, 'resend-invitation')"
                  icon="email-outline" size="extra-small" label="resend"
                />
              </template>
            </td>
            <td v-if="canStartConversation && canShowConversations">
            </td>
            <td v-if="hasAccessToEcosystemMemberPackages"></td>
            <td>
            </td>
            <td v-if="hasAccessToKnowledgeBase"></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{ timeDifference(user.created_at) }}</td>
            <!-- as the user object is coming from the invitations, use the created_at value to display the last invitation sent date-->

          </template>
        </tr>
        </tbody>
      </table>
    </div>
    <overlay-plus-button @openPanel="showAddUserPanel" :hasIconTransition="true" transitionIcon="invite-user" label="Invite users" orientation="right" v-if="!isNewProfileEnabled"/>
    <pagination class="simple-pagination--bottom" :paging="paging" @change="setOffset"/>
  </div>
</template>

<script>
  import _throttle from 'lodash/throttle'

  import Checkbox from '../Form/Checkbox.vue'
  import Pagination from '../Pagination/SimplePagination.vue'
  import ThSort from './ThSort.vue'
  import Badge from '../../components/Badge/Badge.vue'
  import OverlayPlusButton from '../../components/Overlay/OverlayPlusButton.vue'

  import { fetchUserDetail, fetchUsers } from '../../api/config'
  import querify from '../../util/querify'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'

  import { Conversations } from '../../api/conversations.js'
  import { asyncConfig } from '../../api/config.js'
  import { ACTION_TYPES as CONVERSATION_ACTION_TYPES } from '../../store/modules/conversations.js'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import moment from 'moment'
  import CommunityDropdown from '../Communities/CommunityDropdown.vue'
  import Loading from '../Dashboard/ConceptMap/Loading.vue'
  import { ConversationsMixin } from '../../util/ConversationsMixin.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    data () {
      return {
        fullScroll: false,
        users: [],
        form: {},
        paging: {},
        numberOfResults: null,
        loading: false,
        filters: {
          limit: 100,
          offset: null,
          q: null,
          verified: null,
          claimed: null,
          order: null,
          role: null,
          community: this.$route && this.$route.query && this.$route.query.community,
        },
        orderByProperty: null,
        orderByDirection: '',
      }
    },
    methods: {
      normaliseRole (selectedRole) {
        switch (selectedRole) {
          case 'actor':
            return 'Ecosystem Member'
          case 'portfolio_member':
            return 'Portfolio Member'
          case 'owner':
            return 'Owner'
          case 'member':
            return 'Team Member'
          default :
            return ''
        }
      },
      updateCommunityFilter (community) {
        if (!community) {
          this.filters.community = null

          return
        }

        this.filters.community = community.value
      },
      showUpdateModal (user, $event) {
        $event.stopPropagation()
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, { user })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.MANAGE_USER)
      },
      showAddUserPanel () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'invite-users-panel' })
      },
      showAddUserModal () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.MANAGE_USER)
      },
      hasExistingConversation (user) {
        return !!user.conversation_id
      },
      openConversation ($event, user) {
        $event.stopPropagation()
        this.$router.push('/profile/conversations/' + user.conversation_id)
      },
      startConversation ($event, user) {
        $event.stopPropagation()
        Conversations.post({ recipient_id: user.id }).then((conversation) => {
          this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
          this.$router.push('/profile/conversations/' + conversation.id).then(() => {
            this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
              component: 'user-information-side-panel',
              metaData: {
                userId: user.id,
                allowDelete: false,
              },
            })
          })
        })
      },
      canStartConversation () {
        return this.$store.getters.isOwner
      },
      canEditUser (user) {
        if (this.isOwner) {
          return (this.$store.getters.isDeveloper ||
            this.$store.getters.isMember
          ) && user.id !== this.$store.getters.userId
        } else if (this.isMember) {
          return (this.$store.getters.isDeveloper ||
              this.$store.getters.isMember
            ) && user.id !== this.$store.getters.userId &&
            user.role !== 'member' && user.role !== 'owner'
        }
      },
      canEditInvitation (invitation) {
        return (this.$store.getters.isDeveloper ||
          this.$store.getters.isOwner
        )
      },
      subscriptionsOf (user) {
        return user.settings ? Object.keys(user.settings).map(key => {
          return user.settings[key] && key
        })
          .filter(key => ['newsletter', 'reports', 'weeklyDigest'].includes(key))
          .map(key => key === 'weeklyDigest' ? 'Weekly Digest' : key)
          .map(key => key === 'newsletter' ? 'Newsletter' : key)
          .join(', ') : ''
      },
      showUserEditModal (user, action) {
        let promise = Promise.resolve(user)

        if (action === 'remove') {
          promise = fetchUserDetail(user.id, 'user-management')
        }

        promise.then(user => {
          this.$store.commit('UI/SET_MODAL_CONTEXT', { user: user, action })
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, action === 'remove' ? MODAL_IDS.REMOVE_USER_FROM_ECOSYSTEM : MODAL_IDS.USER_EDIT)
        }).catch(() => {
          this.$store.commit('UI/SET_MODAL_CONTEXT', { user, action })
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, action === 'remove' ? MODAL_IDS.REMOVE_USER_FROM_ECOSYSTEM : MODAL_IDS.USER_EDIT)
        })
      },
      timeDifference (createdAtDate) {
        if (!createdAtDate) {
          return
        }

        const start = moment(createdAtDate, 'YYYY/MM/DD')
        const end = moment()

        const diffInDays = end.diff(start, 'days')

        if (diffInDays === 0) {
          return this.$t('timespan_today')
        }

        if (diffInDays === 1) {
          return this.$t('yesterday')
        }

        return this.$t('approx_days_ago', { total: diffInDays })
      },
      exportCsv () {
        document.location = `/api/settings/users?${querify(this.filters)}&export=true`
      },
      clearFilters () {
        this.filters.verified = null
        this.filters.claimed = null
        this.filters.q = null
        this.filters.role = null
      },
      setOffset (offset) {
        this.filters = Object.assign({}, this.filters, { offset })
      },
      fetch () {
        this.loading = true

        fetchUsers(this.filters)
          .then((response) => {
            this.loading = false
            this.users = response.data
            this.paging = response.paging
            this.numberOfResults = response.total
          })
          .catch(error => {
            this.loading = false
          })

        this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
      },
      orderBy (property) {
        if (this.orderByProperty === property) {
          this.orderByDirection = (this.orderByDirection === '') ? '-' : ''
        } else {
          this.orderByProperty = property
        }

        this.filters.order = this.orderByDirection + this.orderByProperty
      },
      openActorSidePanel (actorId) {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'scores',
          metaData: actorId,
        })
      },
      openUserSidePanel (user) {
        // Only open the side panel if it's a registered user, not an invitation
        if (!user.invited_user) {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
            component: 'user-information-side-panel',
            metaData: {
              userId: user.id,
              allowDelete: true,
            }
          })
        }
      },
    },
    computed: {
      isMember () {
        return this.$store.getters.isMember
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      hasAccessToNewCommunities () {
        return this.$store.getters.hasAccessToNewCommunities
      },
      isNewProfileEnabled () {
        return this.$store.getters.isNewProfileEnabled
      },
      canAddEcosystemMembers () {
        return this.$store.getters.isPublisherEnabled
      },
      conversations () {
        return this.$store.state.conversations.listData.data
      },
      canShowConversations () {
        if (!this.hasAccessToConversations) {
          return false
        }

        return this.conversations.length > 0 || !this.$store.state.conversations.listData.loading
      },
      userId () {
        return this.$store.getters.userId
      },
      hasAccessToEcosystemMemberPackages () {
        return this.$store.getters.hasAccessToEcosystemMemberPackages
      },
      isDeveloper () {
        return this.$store.getters.isDeveloper
      },
      filterOptions () {
        return {
          role: [{
            'text': 'Show all',
            'value': null,
          }].concat(this.userRoles || []),
          verified: [
            { 'text': 'Show all', 'value': null },
            { 'text': 'Is verified', 'value': true },
            { 'text': 'Is unverified', 'value': false },
          ],
          claimed: [
            { 'text': 'Show all', 'value': null },
            { 'text': 'Has claimed', 'value': true },
            { 'text': 'Has not claimed', 'value': false },
          ],
        }
      },
      filtersAsString () {
        const aFilters = []

        if (this.filters.verified !== null) {
          aFilters.push(this.filters.verified ? 'Is verified: yes' : 'Is verified: no')
        }
        if (this.filters.claimed !== null) {
          aFilters.push(this.filters.claimed ? 'Has claimed: yes' : 'Has claimed: no')
        }
        if (this.filters.role !== null) {
          const roleMapping = this.allowedRoles.filter(map => map.value == this.filters.role)

          if (roleMapping.length == 1) {
            aFilters.push('Role: ' + roleMapping[0].text)
          }
        }
        if (this.filters.q !== null && this.filters.q !== '') {
          aFilters.push('Search: ' + this.filters.q)
        }

        if (aFilters.length === 0) {
          return ''
        }

        return ' - ' + aFilters.join(', ')
      },
      hasAccessToKnowledgeBase () {
        return this.$store.getters.hasAccessToKnowledgeBase && this.config.viewDashboard && this.config.viewDashboard.includes('knowledge-base')
      },
      config () {
        return this.$store.state.config
      },
    },
    mounted () {
      this.fetch()
      this.$router.replace({}) // The users page can have some query params that are being used as on-load filters, after fetching data we reset those query params
      this.$bus.on('userUpdated', () => this.fetch())
      this.$bus.on('userDeleted', () => this.fetch())
      this.$bus.on('invitationsAdded', () => this.fetch())
    },
    mixins: [asyncConfig, ConversationsMixin, TranslationsMixin],
    beforeUnmount () {
      this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      this.$bus.off('userUpdated')
      this.$bus.off('userDeleted')
      this.$bus.off('invitationsAdded')
    },
    watch: {
      filters: {
        deep: true,
        handler: _throttle(function () {
          this.fetch()
        }, 300, {
          trailing: true,
        }),
      },
    },
    components: {
      Loading,
      CommunityDropdown,
      Badge,
      ThSort,
      Checkbox,
      Pagination,
      OverlayPlusButton,
    },
  })
</script>

<style scoped>
  .users__cta-container {
    display: flex;
  }

  .users-list {
    padding-bottom: 49px;
  }

  @-webkit-keyframes expandAnimation {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @-moz-keyframes expandAnimation {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @-o-keyframes expandAnimation {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes expandAnimation {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @-webkit-keyframes collapseAnimation {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  @-moz-keyframes collapseAnimation {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  @-o-keyframes collapseAnimation {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  @keyframes collapseAnimation {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  .panelExpanded {
    -webkit-animation: expandAnimation .5s forwards;
    -moz-animation: expandAnimation .5s forwards;
    -o-animation: expandAnimation .5s forwards;
    animation: expandAnimation .5s forwards;
  }

  .panelCollapsed {
    -webkit-animation: collapseAnimation .5s forwards;
    -moz-animation: collapseAnimation .5s forwards;
    -o-animation: collapseAnimation .5s forwards;
    animation: collapseAnimation .5s forwards;
  }
</style>
