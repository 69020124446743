<template>
  <with-configuration-tabs>
    <div class="spreadsheet fixed-heading spreadsheet-page">
      <div class="heading" ref="heading">
        <div class="explore__control-bar" style="border-bottom: none; padding: 0;">
          <h1 style="display:inline-block">Spreadsheet</h1>
          <div class="explore__control-bar__controls">
            <form-group class="explore__controls__actor-types">
              <template v-if="actorTypes.length > 1">
                <checkbox
                  v-for="(actorType, index) in actorTypes"
                  :key="index"
                  inline
                  multiple
                  :label="actorType.name"
                  :val="actorType.value"
                  :model-value="activeActorTypes"
                  @update:modelValue="updateActiveActorTypes"
                />
              </template>
            </form-group>
          </div>
          <div class="explore-content__filter-button-container">
            <div class="explore-content__filter-indicator"
              v-if="keywords.length > 0 && keywords.find((f) => f.facet !== 'actor_type')"></div>
            <ds-button variant="minimal" size="small" label="Filter" icon="filter-box"
              class="explore-content__filter-button"
              :class="{ 'explore-content__filter-button-active' : this.$store.state.filters.filter.visible }"
              @click="toggleFilter"/>
          </div>
          <badge :name="total + ' Actors'" variant="primary"
            class="score-overview__actor-couter"/>
        </div>

        <div class="spreadsheet-control">

          <ds-button v-if="$store.getters.isOwner"
            class="pull-right"
            variant="outline"
            label="Export financials"
            @click="show.financialExport = true"
          />
          <ds-button v-if="$store.getters.isOwner"
            class="pull-right"
            variant="outline"
            label="Export"
            @click="show.export = true"
          />
          <ds-button
            class="pull-right"
            variant="outline"
            label="Columns"
            @click="show.columns = true"
          />
          <ds-button
            :variant="changedCells.length ? 'secondary' : 'outline'"
            :icon="isSaving ? 'spinner' : 'check'"
            :label="'Save ' + (changedCells.length ? changedCells.length + ' cells' : '')"
            @click="save"
          />
          <ds-button
            :variant="revision ? 'secondary' : 'outline'"
            icon="undo"
            label="Undo"
            @click="undo"
          />
          <ds-button
            v-if="domainsSelection"
            variant="secondary"
            icon="plus"
            :label="'Add domain to ' + domainsSelection.count + ' ' + $store.getters.actorStrings"
            @click="addDomains"
          />
          <ds-button
            v-if="relationSelection"
            variant="secondary"
            icon="plus"
            :label="'Add relation to ' + relationSelection.count + ' ' + $store.getters.actorStrings"
            @click="addRelations"
          />
          <p class="form-group__error" style="margin-top: 1em" v-if="errors.message">
            {{ errors.message }}</p>
        </div>
      </div>
      <top-filters style="margin-bottom:1.5em;"/>
      <modal title="VISIBLE COLUMNS"
        v-if="show.columns"
        close-on-blur
        closeable
        @close="show.columns = false">
        <template v-slot:body>
          <div>
            <p class="guide_text">Drag the properties you like to display from the left to
              the right list. The rows will be sorted based on the name of the actor.</p>
            <draggable-column-picker v-model="spreadsheetProperties"
              :options="allProperties"/>
          </div>
        </template>
      </modal>

      <modal title="CONFIGURATION EXPORT"
        v-if="show.export"
        close-on-blur
        closeable
        @close="closeExportModal">
        <template v-slot:body>
          <div>
            <p class="guide_text">Drag the properties you like to export from the left to
              the right list. The order of the properties in the list of “Available
              properties” defines the order of the
              columns.</p>
            <draggable-column-picker v-model="exportProperties"
              :options="exportableProperties"
              label-in="Exported columns"
              label-out="Available columns"
            />
          </div>
        </template>
        <template v-slot:footer>
          <div>
            <ds-button variant="secondary" label="Export" @click="startExport"
              v-if="show.exportStatus === 'idle'"/>
            <p v-if="show.exportStatus == 'success'">An export will be created and emailed
              to you soon!</p>
            <p v-if="show.exportStatus == 'failed'">Uh oh. The export has failed, contact
              us!</p>
          </div>
        </template>
      </modal>

      <modal title="FINANCIAL REPORT EXPORT"
        v-if="show.financialExport"
        close-on-blur
        closeable
        @close="closeFinancialExportModal">
        <template v-slot:body>
          <div>
            <p class="guide_text">A financial metrics report with following properties will be generated.
              Only actors with financials will be available in the report.</p>
            <ul>
              <li>Name</li>
              <li>Start period</li>
              <li>End period</li>
              <li>Ebitda</li>
              <li>Employees (FTE)</li>
              <li>Operating income</li>
              <li>Other operating income</li>
              <li v-if="hasEstimatedRevenue">Estimated Operating Income - Based On FTE</li>
              <li>Equity</li>
            </ul>
          </div>
        </template>
        <template v-slot:footer>
          <div>
            <ds-button variant="secondary" label="Export financial report" @click="startFinancialExport"
              v-if="show.financialExportStatus === 'idle'"/>
            <p v-if="show.financialExportStatus == 'success'">An export will be created and emailed
              to you soon!</p>
            <p v-if="show.financialExportStatus == 'failed'">Uh oh. The export has failed, contact
              us!</p>
          </div>
        </template>
      </modal>

      <div class="spreadsheet-hot" ref="hot"></div>
    </div>
  </with-configuration-tabs>
</template>

<script lang="ts">
  import _uniqBy from 'lodash/uniqBy.js'
  import Raven from 'raven-js'

  import { exportSpreadsheet, fetchActorsSpreadsheet, updateBulkActor } from '../../api/actors.js'

  import { ArrayEditor, fromArray, toArray } from './ArrayEditor.js'
  import { fromDomains, getDomainsMenu } from './DomainsEditor.js'
  // import { fromAspects, getAspectsMenu } from './DomainsEditor';
  import { fromIndustries } from './IndustriesEditor.js'
  import { fromSimpleSingleTaxonomy, fromSimpleTaxonomy } from './SimpleTaxonomiesEditor.js'
  import { fromRelation, getRelationMenu } from './RelationEditor.js'

  import DraggableColumnPicker from '../Form/DraggableColumnPicker.vue'
  import Modal from '../Modals/Modal.vue'
  import TopFilters from '../Filters/TopFilters.vue'
  import FormGroup from '../Form/FormGroup.vue'
  import Checkbox from '../Form/Checkbox.vue'

  import { exportableProperties, humanize } from '../../constants/properties.js'
  import { MUTATION_TYPES as FILTER_MUTATION_TYPES } from '../../store/modules/filters.js'

  import ConfigMixin from '../../util/ConfigMixin.js'
  import { loadChunk } from '../../util/chunk-loader.js'
  import { getReportFieldById, getReportFieldsByTypes, inert } from '../../util/helpers.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import Badge from '../../components/Badge/Badge.vue'
  import WithConfigurationTabs from '../../pages/WithConfigurationTabs/WithConfigurationTabs.vue'
  import { defineComponent } from 'vue'
  import { trackMatomoEvent } from '../../util/analytics.js'
  import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '../../constants/analytics-constants.js'

  // Actor property transformers

  const fromActorPropertyMapping = {
    accelerated_by: fromRelation,
    accelerated: fromRelation,
    invested_by: fromRelation,
    invested: fromRelation,
    members: fromRelation,
    member_of: fromRelation,
    collaborates_with: fromRelation,
    has_collaboration_with: fromRelation,
    has_administrators: fromRelation,
    is_administrator: fromRelation,
    has_account_manager: fromRelation,
    is_account_manager_of: fromRelation,
    spinoffs: fromRelation,
    spinoff_from: fromRelation,
    has_founded: fromRelation,
    is_founded_by: fromRelation,
    acquired: fromRelation,
    acquired_by: fromRelation,
    is_employee_of: fromRelation,
    has_employee: fromRelation,
    domain: fromArray,
    domains: fromDomains,
    industries: fromIndustries,
    subIndustries: fromIndustries,
    category: fromSimpleSingleTaxonomy,
    membership: fromSimpleTaxonomy,
    stage: fromSimpleSingleTaxonomy,
    funnel_stage: fromSimpleSingleTaxonomy,
    technology: fromSimpleTaxonomy,
    activities: fromSimpleTaxonomy,
    expertises: fromSimpleTaxonomy,
    business_aspects: fromSimpleTaxonomy,
    motivations: fromSimpleTaxonomy,
    readiness_levels: fromSimpleTaxonomy,
    process_steps: fromSimpleTaxonomy,
    tags: fromSimpleTaxonomy,
    // aspects: fromAspects,
  }

  const toActorPropertyMapping = {
    activities: toArray,
    domain: toArray,
    industries: toArray,
    subIndustries: toArray,
    tags: toArray,
    private_tags: toArray,
    technology: toArray,
    expertises: toArray,
    business_aspects: toArray,
    motivations: toArray,
    readiness_levels: toArray,
    process_steps: toArray,
    membership: toArray,
  }

  function toActorProperty (prop, value) {
    return toActorPropertyMapping[prop] ? toActorPropertyMapping[prop](value) : value
  }

  function fromActorProperty (prop, value) {
    return fromActorPropertyMapping[prop] ? fromActorPropertyMapping[prop](value) : value
  }

  // Columns
  const hiddenProperties = ['id']

  function toObject (arr) {
    const obj = {}
    for (let i = 0; i < arr.length - 1; i++) {
      obj[arr[i]] = null
    }
    return obj
  }

  function compactChanges (changes) {
    const mapping = {}
    for (let i = 0; i < changes.length; i++) {
      if (!mapping[changes[i].id]) {
        mapping[changes[i].id] = inert(changes[i])
      } else {
        Object.assign(mapping[changes[i].id], changes[i])
      }
      delete mapping[changes[i].id].revision
      delete mapping[changes[i].id].col
      delete mapping[changes[i].id].row
    }
    return Object.values(mapping)
  }
  export default defineComponent({
    components: {
      DraggableColumnPicker,
      Modal,
      TopFilters,
      FormGroup,
      Checkbox,
      Badge,
      WithConfigurationTabs,
    },
    data () {
      return {
        show: { columns: false, export: false, exportStatus: 'idle', financialExport: false, financialExportStatus: 'idle' },
        selection: [],
        actorsLength: 0,
        actors: [],
        changes: [],
        revision: 0,
        isSaving: false,
        busy: false,
        total: 0,
        limit: 50,
        offset: 0,
        errors: {},
        undoing: false,
      }
    },
    computed: {
      hasEstimatedRevenue () {
        return this.$store.getters.averageRevenuePerFTE && this.$store.getters.averageRevenuePerFTE > 0
      },
      filterableRelationships () {
        return this.$store.getters.filterableRelationships
      },
      ecosystemRelationships () {
        return this.$store.getters.fullActorRelationships
      },
      reportsAreEnabled () {
        return this.$store.getters.reportsEnabled
      },
      isPublic () {
        return this.$store.getters.isPublic
      },
      exportableProperties () {
        var copyOfExportableProperties = exportableProperties.slice()
        copyOfExportableProperties.push('location')
        copyOfExportableProperties.push('logo')

        if (this.$store.getters.isOwner) {
          // Add the relationships to the exportable columns
          copyOfExportableProperties = copyOfExportableProperties.concat(this.filterableRelationships)
        }

        if (!this.$store.getters.hasCustomScores) {
          copyOfExportableProperties = copyOfExportableProperties.filter(c => c != 'custom_score')
        }

        if (this.reportsAreEnabled) {
          var properties = copyOfExportableProperties
          properties.push('Reports')

          // remove deprecated fields
          properties = properties.filter(item => item !== 'claimable')

          return properties
        }

        // remove deprecated fields
        copyOfExportableProperties = copyOfExportableProperties.filter(item => item !== 'claimable')

        return copyOfExportableProperties
      },
      exportProperties: {
        get () {
          var exportProperties = (this.$store.getters.userSettings.exportProperties || 'name,industries,subIndustries,email')
            .split(',')
            .filter(Boolean)

          // remove deprecated fields
          exportProperties = exportProperties.filter(item => item !== 'claimable')

          if (this.$store.getters.isUserOfDataScoutsTeam) {
            // exportProperties = exportProperties.concat('is_zombie');
            // exportProperties = exportProperties.concat('possible_duplicate')
          }

          return exportProperties
        },
        set (v) {
          this.$store.commit('USER/STORE_SETTINGS', { exportProperties: v.join(',') })
        },
      },
      spreadsheetProperties: {
        get () {
          var spreadsheetProperties = (this.$store.getters.userSettings.spreadsheetProperties || 'name,actor_type,category,industries')
            .split(',')
            .filter(Boolean)

          // Filter out report fields if knowledgeSharing is not enabled
          if (!this.$store.getters.reportsEnabled) {
            spreadsheetProperties = spreadsheetProperties
              .filter(column => !column.startsWith('report_field_'))
              .filter(Boolean)
          }

          return spreadsheetProperties
        },
        set (v) {
          if (!v.includes('name')) {
            v.unshift('name')
          }

          if (!v.includes('actor_type')) {
            v.unshift('actor_type')
          }

          this.$store.commit('USER/STORE_SETTINGS', { spreadsheetProperties: v.join(',') })

          this.resized(true)
          this.resetRows()
          this.fetchRows()
        },
      },
      columns () {
        var availableColumns = this.internalColumns.filter(col => this.spreadsheetProperties.includes(col.data))

        if (!this.$store.getters.hasCustomScores && !this.$store.getters.isDeveloper) {
          availableColumns.filter(c => c.data != 'custom_score')
        }

        // If the reporting is enabled, and it's part of the spreadsheetProperties, add them to the result
        if (this.$store.getters.isOwner && this.$store.getters.knowledgeSharing) {
          var reportColumns = getReportFieldsByTypes(['number', 'options', 'date'])

          if (reportColumns && reportColumns.length > 0) {
            for (var i = 0; i < reportColumns.length; i++) {
              var reportColumn = reportColumns[i]

              if (this.spreadsheetProperties.includes(reportColumn)) {
                var label = this.humanizeReportField(reportColumn)

                availableColumns.push({
                  data: reportColumn,
                  filter: false,
                  readOnly: true,
                  title: label,
                  columnSorting: {
                    indicator: false,
                    headerAction: false,
                  },
                })
              }
            }
          }
        }

        // If the field is read only we add a small text indication in the title property of the object
        availableColumns.map(column => {
          if (column.title.indexOf('Read only') < 0) {
            column.title = column.readOnly && column.readOnly == true ? `${column.title} (Read only)` : column.title
          }
          return column
        })

        // We assign the index order of each item based on the item order that comes from 'this.spreadsheetProperties'
        availableColumns.map(item => {
          return Object.assign(item, { index: this.spreadsheetProperties.indexOf(item.data) })
        })
        // We sort the columns based on the index
        availableColumns.sort(function (a, b) {
          return a.index - b.index
        })

        return availableColumns
      },
      colHeaders () {
        return this.columns.map(c => c.name ? c.name : c.data)
      },
      properties () {
        return this.colHeaders
      },
      allProperties () {
        var columns = this.internalColumnHeaders

        if (this.$store.getters.isOwner && this.$store.getters.knowledgeSharing) {
          var reportColumns = getReportFieldsByTypes(['number', 'options', 'date'])

          if (reportColumns && reportColumns.length > 0) {
            columns = columns.concat(reportColumns)
          }
        }

        if (!this.$store.getters.hasCustomScores && !this.$store.getters.isDeveloper) {
          columns = columns.filter(c => c != 'custom_score')
        }

        return columns
      },
      computedProperties () {
        return this.columns.filter(c => c.readOnly || c.editor).map(c => c.data)
      },
      filters () {
        return this.$store.state.filters
      },
      changedCells () {
        return _uniqBy(this.changes, c => c.row + ' ' + c.col)
      },
      selectionInfo () {
        const [a, , c] = this.selection

        return {
          min: Math.min(a, c),
          max: Math.min(Math.max(a, c), this.actorsLength - 1),
          count: Math.abs(a - c) + 1,
        }
      },
      selectedActors () {
        const sel = this.selectionInfo
        return this.actors.filter((_, i) => sel.min <= i && i <= sel.max)
      },
      domainsSelection () {
        const col = this.hot && this.hot.propToCol('domains')

        if ((this.selection[1] >= col && this.selection[3] <= col) || (this.selection[1] <= col && this.selection[3] >= col)) {
          return this.selectionInfo
        }
      },
      relationSelection () {
        if (this.selection[1] && this.isRelation(this.hot.colToProp(this.selection[1]))) {
          return this.selectionInfo
        }
      },
      keywords () {
        return this.$store.state.filters.keywords
      },
      actorTypes () {
        var types = [{ name: 'Organizations', value: 'LegalEntity' }, { name: 'People', value: 'Person' }, { name: 'Product', value: 'Product' }]

        // Keep only the ones that are allowed
        var r = types.filter((type) => this.$store.getters.viewActorTypes.includes(type.value))
        return r
      },
      activeActorTypes () {
        // Take a shallow copy of the state values as the checkbox component will change the value we pass to it
        // which is not allowed, vuex state can only be changed within the scope of the state
        return this.$store.state.filters.actorTypes.slice(0)
      },
      internalColumns () {
        return [{
          data: 'name',
          width: 200,
          columnSorting: {
            indicator: true,
            headerAction: true,
          },
        }, {
          data: 'founding_date',
        }, {
          data: 'actor_type',
          type: 'autocomplete',
          filter: false,
          source: ['LegalEntity', 'Product', 'Person'],
        }, {
          data: 'category',
          type: 'autocomplete',
          source: [].concat.apply([], Object.values(this.categoryLabels || {})),
          editor: 'text',
        }, {
          data: 'email',
        }, {
          data: 'membership',
          type: 'autocomplete',
          source: [].concat.apply([], this.membershipLabels),
          editor: 'text',
        }, {
          data: 'stage',
          type: 'autocomplete',
          source: [].concat.apply([], this.stageLabels),
          editor: 'text',
        }, {
          data: 'funnel_stage',
          type: 'autocomplete',
          source: [].concat.apply([], this.funnelStageLabels),
          editor: 'text',
        }, {
          data: 'maturity',
        }, {
          data: 'industries',
          width: 200,
          editor: 'array',
        }, {
          data: 'subIndustries',
          width: 200,
          editor: 'array',
        }, {
          data: 'technology',
          width: 200,
          editor: 'array',
        }, {
          data: 'activities',
          width: 200,
          editor: 'array',
        }, {
          data: 'sustainability_goal',
          width: 200,
          editor: 'array',
        }, {
          data: 'domains',
          editor: 'array',
          width: 200,
        }, {
          data: 'tags',
          width: 200,
          editor: 'array',
        }, {
          data: 'private_tags',
          width: 200,
          editor: 'array',
        }, {
          data: 'motivations',
          width: 200,
          editor: 'array',
        }, {
          data: 'expertises',
          width: 200,
          editor: 'array',
        },
        {
          data: 'employees',
          width: 100,
          readOnly: true,
        },
        {
          data: 'readiness_levels',
          width: 200,
          editor: 'array',
        }, {
          data: 'process_steps',
          width: 200,
          editor: 'array',
        }, {
          data: 'business_aspects',
          width: 200,
          editor: 'array',
        }, {
          data: 'type',
          type: 'autocomplete',
          filter: false,
          source: ['B2B', 'B2C', 'other'],
        }, {
          data: 'industry_codes',
          width: 200,
          readOnly: true,
        }, {
          data: 'total_funding',
        }, {
          data: 'custom_score',
          readOnly: true,
        }, {
          data: 'vacancy_count',
          readOnly: true,
        }, {
          data: 'zip',
          readOnly: true,
        }, {
          data: 'city',
          readOnly: true,
        }, {
            data: 'country',
            readOnly: true,
          }, {
            data: 'completeness',
            readOnly: true,
          }, {
            data: 'invested',
            readOnly: true,
          }, {
            data: 'invested_by',
            readOnly: true,
          }, {
            data: 'spinoffs',
            readOnly: true,
          }, {
            data: 'spinoff_from',
            readOnly: true,
          }, {
            data: 'accelerated',
            readOnly: true,
          }, {
            data: 'accelerated_by',
            readOnly: true,
          }, {
            data: 'acquired',
            readOnly: true,
          }, {
            data: 'acquired_by',
            readOnly: true,
          }, {
            data: 'member_of',
            readOnly: true,
          }, {
            data: 'members',
            readOnly: true,
          }, {
            data: 'is_account_manager_of',
            readOnly: true,
          }, {
            data: 'has_account_manager',
            readOnly: true,
          }, {
            data: 'has_employee',
            readOnly: true,
          }, {
            data: 'is_employee_of',
            readOnly: true,
          }, {
            data: 'has_founded',
            readOnly: true,
          }, {
            data: 'is_founded_by',
            readOnly: true,
          }, {
            data: 'has_collaboration_with',
            readOnly: true,
          }, {
            data: 'collaborates_with',
            readOnly: true,
          }, {
            data: 'zip',
            readOnly: true,
          }, {
            data: 'patent_count',
            readOnly: true,
          }].map(item => {
            return {
              ...item,
              title: humanize(item.data),
            }
          })
      },
      internalColumnHeaders () {
        return this.internalColumns.map(c => c.data)
      },
      internalProperties () {
        return this.internalColumnHeaders.concat(hiddenProperties)
      },
      internalComputedProperties () {
        return this.internalColumns.filter(c => c.readOnly || c.editor).map(c => c.data)
      },
      internalDataSchema () {
        return toObject(this.internalProperties)
      },
    },
    methods: {
      isRelation (prop) {
        return this.ecosystemRelationships.find(t => t.name === prop)
      },
      enableSubNavHover () {
        this.$store.commit(UI_MUTATION_TYPES.ENABLE_SUB_NAV_HOVER)
      },
      humanizeReportField (reportColumn) {
        if (reportColumn.indexOf('report_field_') >= 0) {
          var reportFieldId = reportColumn.replace('report_field_', '')
          var reportField = getReportFieldById(reportFieldId)

          if (!reportField) {
            return 'report'
          }

          return reportField.label
        }

        return ''
      },
      startExport () {
        trackMatomoEvent(MATOMO_EVENT_CATEGORIES.INTERACTIONS, MATOMO_EVENT_ACTIONS.EXPORT_ACTORS, `{userId: ${this.$store.getters.userId}}`)
        var filters = {}
        Object.assign(filters, { fields: this.exportProperties, format: 'csv' }, this.$store.getters.spreadsheetFilterObject)

        exportSpreadsheet(filters)
          .then(this.show.exportStatus = 'success')
          .catch(err => {
            this.show.exportStatus = 'failed'
          })
      },
      closeExportModal () {
        this.show.export = false
        this.show.exportStatus = 'idle'
      },
      startFinancialExport () {
        trackMatomoEvent(MATOMO_EVENT_CATEGORIES.INTERACTIONS, MATOMO_EVENT_ACTIONS.EXPORT_FINANCIALS, `{userId: ${this.$store.getters.userId}}`)
        var filters = {}

        Object.assign(filters, {
          fields: '',
          format: 'csv',
          exportType: 'financials',
          order: this.$store.state.filters.paging.order,
          actor_type: this.activeActorTypes,
        }, this.$store.getters.spreadsheetFilterObject)

        exportSpreadsheet(filters)
          .then(this.show.financialExportStatus = 'success')
          .catch(err => {
            this.show.financialExportStatus = 'failed'
          })
      },
      closeFinancialExportModal () {
        this.show.financialExport = false
        this.show.financialExportStatus = 'idle'
      },
      resetRows (offset) {
        // Scroll to top
        // this.hot && this.hot.scrollViewportTo(0)

        // Reset changes
        this.changes = []
        this.revision = 0

        // Remove rows
        this.actors = []
        this.actorsLength = 0
        this.total = 0
      },
      fetchRows (offset) {
        if (this.busy || this._isunmounted) {
          return
        }
        this.busy = true
        const params = Object.assign({
          fields: this.spreadsheetProperties,
          order: this.$store.state.filters.paging.order,
          limit: this.limit,
          offset: this.actorsLength,
          actor_type: this.activeActorTypes,
        }, this.$store.getters.spreadsheetFilterObject)

        return fetchActorsSpreadsheet(params).then(({ data, total }) => {
          data.forEach((actor, i) => {
            this.actors[params.offset + i] = this.textualActor(actor)
          })

          if (this.actors.length < total) {
            while (this.actors.length < total) {
              this.actors.push({})
            }
          }
          this.actorsLength += data.length
          this.total = total
          this.render()

          // Fetch more if needed
          if (data.length < this.limit && this.actorsLength < this.total) {
            return console.error('API sent', data.length, 'actors, while frontend expected', this.limit)
          }

          if (this.actorsLength === data.length) {
            this.hot && this.hot.updateSettings({
              data: this.actors,
            })
          }

          setTimeout(() => {
            this.afterScrollVertically()
          }, 100)
        }).catch(() => { /* Ignore errors */
        })
          .then(() => {
            this.busy = false
          })
      },
      render () {
        if (!this.hot) {
          loadChunk('Handsontable', this.actualRender)
        } else {
          this.hot.render()
        }
      },
      actualRender () {
        const Handsontable = window.Handsontable

        if (!this.$el || !Handsontable) {
          return console.warn('Tried but failed to render spreadsheet')
        }

        if (this.hot) {
          return
        }

        if (!Handsontable.editors.ArrayEditor) {
          Handsontable.editors.ArrayEditor = ArrayEditor()
          Handsontable.editors.registerEditor('array', Handsontable.editors.ArrayEditor)
        }

        this.hot = new Handsontable(this.$refs.hot, {
          afterContextMenuShow: this.afterContextMenuShow,
          afterScrollVertically: this.afterScrollVertically,
          afterSelectionEnd: this.afterSelectionEnd,
          afterSelection: this.afterSelection,
          afterDeselect: this.enableSubNavHover,
          colHeaders: this.colHeaders,
          columns: this.columns,
          columnSorting: true,
          // contextMenu: true,
          // sortIndicator: false,
          data: this.actors,
          dataSchema: this.internalDataSchema,
          formulas: true,
          manualColumnMove: true,
          manualColumnResize: true,
          minSpareRows: 0,
          rowHeaders: true,
          renderAllRows: false,
          stretchH: 'all',
          undo: true,
          viewportColumnRenderingOffset: 20,
        })

        Handsontable.hooks.add('afterChange', this.change, this.hot)
        Handsontable.hooks.add('beforeColumnSort', this.columnSort, this.hot)
        Handsontable.hooks.add('beforeUndo', this.beforeUndo, this.hot)
      },
      afterScrollVertically () {
        if (!this.hot || this._isunmounted) {
          return Promise.resolve()
        }

        const rowOffset = this.hot.rowOffset()
        const visibleRows = this.hot.countVisibleRows()
        const lastVisibleRow = rowOffset + visibleRows + (visibleRows / 2)

        if (!this.busy && lastVisibleRow > this.actorsLength && this.actorsLength < this.total) {
          this.fetchRows()
        }
      },
      afterSelection (a, b, c, d) {
        this.$store.commit(UI_MUTATION_TYPES.DISABLE_SUB_NAV_HOVER)
        this.selection = [a, b, c, d]
      },
      afterSelectionEnd (a, b, c, d) {
        const actors = this.selectedActors

        if (!actors.length) {
          return
        }

        const contextMenuItems = {}
        const prop = this.hot.colToProp(b)

        // Relations editor
        if (b === d && this.isRelation(prop)) {
          Object.assign(contextMenuItems, getRelationMenu(prop, actors))
        }

        // Domains editor
        if (this.domainsSelection) {
          Object.assign(contextMenuItems, getDomainsMenu(actors))
        }

        // Aspects editor
        // Commented for now as the aspects are not necessary
        // The aspects should work the same way as the domains when a cell is clicked
        // which is why we have a AspectsEditor file which is copy from the DomainsEditor
        // This file should help handle all the neccessary data that needs to be displayed in the dropdown menu that appears once the cell is clicked

        // This file and its subquential handling and functionality was never tested so further developement should be required in order to have properly working
        // and enable the user to edit the aspects from the spreadsheets dashboard
        /* if (prop == 'aspects') {
          Object.assign(contextMenuItems, getAspectsMenu(actors))
        } */

        if (!Object.keys(contextMenuItems).length) {
          return
        }

        // Enable context menu
        this.hot && this.hot.updateSettings({
          contextMenu: {
            items: contextMenuItems,
            callback: (key) => {
              if (key === 'add') {
                this.$store.commit('UI/SET_MODAL_CONTEXT', { actors, prop })
                this.$store.commit('UI/SHOW_MODAL', 'ADD_RELATION')
              } else if (key.startsWith('REMOVE_RELATION')) {
                this.$store.commit('UI/SET_MODAL_CONTEXT', { actors, prop, to: key.slice(15) })
                this.$store.commit('UI/SHOW_MODAL', 'REMOVE_RELATION')
              } else if (key.startsWith('REMOVE_DOMAIN')) {
                this.$store.commit('UI/SET_MODAL_CONTEXT', { actors, prop, id: key.slice(13) })
                this.$store.commit('UI/SHOW_MODAL', 'REMOVE_DOMAIN')
              }
            },
          },
        })

        // Try to trigger the menu asap
        // needs a mouse event to locate the cursor
        window.onmouseup = this.triggerRelationMenu
        window.onmousemove = this.triggerRelationMenu
      },
      triggerRelationMenu (evt) {
        window.onmouseup = null
        window.onmousemove = null
        evt.preventDefault()
        this.hot.getPlugin('ContextMenu').open(evt)
      },
      afterContextMenuShow (opt, b) {
        // Disable context menu
        setTimeout(() => {
          this.hot.getPlugin('ContextMenu').menu = false
          this.hot && this.hot.updateSettings({
            contextMenu: false,
          })
        }, 50)
      },
      addDomains (col) {
        const min = Math.min(this.selection[0], this.selection[2])
        const max = Math.min(Math.max(this.selection[0], this.selection[2]), this.actorsLength - 1)
        const actors = this.actors.filter((_, i) => min <= i && i <= max)
        this.$store.commit('UI/SET_MODAL_CONTEXT', { actors })
        this.$store.commit('UI/SHOW_MODAL', 'ADD_DOMAIN')
      },
      addRelations (col) {
        const min = Math.min(this.selection[0], this.selection[2])
        const max = Math.min(Math.max(this.selection[0], this.selection[2]), this.actorsLength - 1)
        const actors = this.actors.filter((_, i) => min <= i && i <= max)
        this.$store.commit('UI/SET_MODAL_CONTEXT', { actors, relation: 'accelerated_by' })
        this.$store.commit('UI/SHOW_MODAL', 'ADD_RELATION')
      },
      columnSort (col) {
        if (this.columns[col] && this.columns[col].data.startsWith('report_field_')) {
          return false
        }

        const prop = this.hot.colToProp(col)

        this.$store.commit('FILTERS/TOGGLE_ORDER', prop)
        return false
      },
      change (cells) {
        if (!cells) {
          // console.warn('No changes detected')
          return
        }
        if (this.undoing) {
          // Remove current revision
          this.revision--
          this.undoing = false
          this.changes = this.changes.filter(c => c.revision <= this.revision)
        } else {
          // Add new revision
          this.revision++
          cells.forEach(cell => {
            if (!cell) return
            const row = cell[0]
            const prop = cell[1]
            const col = this.internalColumnHeaders.indexOf(prop)
            const actor = this.actors[cell[0]] || { id: 'error' }
            const dateRegex = /^(19[5-9][0-9]|20[0-4][0-9]|2050)[-/](0?[1-9]|1[0-2])[-/](0?[1-9]|[12][0-9]|3[01])$/ // yyyy/mm/dd || yyyy-mm-dd
            const numberRegex = /^[0-9]*$/
            const change = {
              row,
              col,
              revision: this.revision,
              id: actor.id,
              actor_type: actor.actor_type,
            }
            change[prop] = toActorProperty(prop, cell[3] || '')

            // industries & subIndustries go together
            // If one is included and the other isn't. Add the missing one.
            if (['industries', 'subIndustries'].includes(prop)) {
              if (prop === 'industries') {
                change.subIndustries = toActorProperty(prop, actor.subIndustries || [])
              } else {
                change.industries = toActorProperty(prop, actor.industries || [])
              }
            }

            // We validate date value
            if (prop == 'founding_date' && !dateRegex.test(change[prop])) {
              this.errors = { message: 'Invalid date detected - Founding Date ' }
              return
            }

            // We validate funding value
            if (prop == 'total_funding' && !numberRegex.test(change[prop])) {
              this.errors = { message: 'Invalid funding value detected - Total Funding' }
              return
            }

            this.changes.push(change)
          })
        }

        this.render()
      },
      beforeUndo () {
        this.undoing = true

        // Fix undo plugin: it would remove random rows
        if (!this.revision) {
          return false
        }
      },
      undo () {
        if (this.hot) {
          this.errors = {}
          this.hot.undo()
        } else {
          Raven.captureMessage('User tried to undo before Handsontable was loaded')
        }
      },
      save () {
        this.isSaving = true
        this.errors = {}

        updateBulkActor(compactChanges(this.changes))
          .catch(errors => {
            this.errors = errors
            console.warn('did not expect error response from /actors/bulk-update')
          })
          .then(() => {
            this.isSaving = false
          })
          .then(this.actorUpdated)
      },
      actorUpdated () {
        this.resetRows()
        this.fetchRows()
      },
      resizeContainer () {
        if (navigator.appVersion.indexOf('Trident/') > -1) {
          const { bottom, left } = this.$refs.heading.getBoundingClientRect()
          const { width, height } = document.querySelector('.app-wrapper').getBoundingClientRect()
          this.$refs.hot.style.height = (height - bottom) + 'px'
        }
      },
      resized (destroy = false) {
        if (this.hot) {
          if (destroy) {
            // NOTE: Why was this necessary? This was the cause of the spreadsheet jumping to the top after loading data.
            //       An alternate solution would be to remove the this.resize() call after data fetch.
            this.hot.destroy()
            this.hot = null
          }
          this.resizeContainer()
          this.render()
        }
      },
      toggleFilter () {
        if (this.$store.state.filters.filter.visible) {
          this.$store.commit(FILTER_MUTATION_TYPES.TOGGLE_FILTER, false)
        } else {
          this.$store.commit(FILTER_MUTATION_TYPES.TOGGLE_FILTER, true)
        }
      },
      updateActiveActorTypes (actorTypes) {
        this.$store.commit(FILTER_MUTATION_TYPES.SET_ACTIVE_ACTOR_TYPES, actorTypes)
      },
      textualActor (actor) {
        // Replace non-textual properties with text
        // And backup their value (actor.tags => actor._tags)

        for (let i = 0; i < this.internalComputedProperties.length; i++) {
          actor['_' + this.internalComputedProperties[i]] = actor[this.internalComputedProperties[i]]
          actor[this.internalComputedProperties[i]] = fromActorProperty(this.internalComputedProperties[i], actor[this.internalComputedProperties[i]])
        }

        return actor
      },
    },
    mounted () {
      this.$store.commit('FILTERS/CLEAR_BY_FACET', 'actor_type')
      this.resizeContainer()
      this.render()
      this.fetchRows()
      this.$bus.on('actorUpdated', this.actorUpdated)
      this.$bus.on('resize300', this.resized)
    },
    beforeUnmount () {
      this.$bus.off('actorUpdated', this.actorUpdated)
      this.$bus.off('resize300', this.resized)

      this.$store.commit(UI_MUTATION_TYPES.ENABLE_SUB_NAV_HOVER)
    },
    watch: {
      filters: {
        deep: true,
        handler () {
          this.resetRows()
          this.fetchRows()
        },
      },
    },
    mixins: [ConfigMixin],
  })
</script>

<style>
  .spreadsheet-page {
    overflow: hidden;
  }

  .spreadsheet-hot {
    margin: -1px 0 0 -1px;
    flex: 1 1 100%;
    overflow: hidden;
    width: 100%;
    height: auto;
  }

  .spreadsheet .handsontable.listbox td {
    background: #eee;
  }

  .spreadsheet .handsontable.listbox tr td.current, .spreadsheet .handsontable.listbox tr:hover td {
    background: #ddd;
  }
</style>
