<template>
  <with-configuration-tabs>
    <div class="curation fixed-heading scrollable">
      <div class="heading">
        <h1>Curation</h1>
        <p class="guide_text">Add new actors, delete old actors and accept or reject new
          claims by clicking the links below.</p>
        <tabs :tabs="tabs" :saveToUrl="true" :tab-counters="tabCounters">
          <template v-slot:[tabNames.newActors]>
            <div>
              <p>
                Add new actors to list of actor profiles
              </p>
              <div class="row">
                <form-group class="col-sm-4 col-md-3" label="Filter by name">
                  <ds-input v-model="filters.name"/>
                </form-group>
              </div>
            </div>
          </template>
          <template v-slot:[tabNames.deletedActors]>
            <div>
              <p>
                Remove actors from list of actor profiles
              </p>
              <div class="row">
                <form-group class="col-sm-4 col-md-3" label="Filter by name">
                  <ds-input v-model="filters.name"/>
                </form-group>
              </div>
            </div>
          </template>
          <template v-slot:[tabNames.claims]>
            <div>
              <p>
                Accept or reject actor claims
              </p>
              <div class="row">
                <form-group class="col-sm-4 col-md-3" label="Filter by name">
                  <ds-input v-model="filters.name"/>
                </form-group>
              </div>
            </div>
          </template>
          <template v-slot:[tabNames.resources]>
            <div>
              <p>
                Accept or reject uploaded content
              </p>
              <div class="row">
                <form-group class="col-sm-4 col-md-3" label="Filter by title">
                  <ds-input v-model="filters.name"/>
                </form-group>
              </div>
            </div>
          </template>
        </tabs>
      </div>

      <div class="has-pagination" v-if="activeTab == 'Resources'">
        <table class="table table--fill table--hover" style="table-layout: fixed">
          <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th width="100">Created by</th>
            <th width="80">Created at</th>
            <th colspan="3" width="400">Actions</th>
          </tr>
          </thead>
          <tbody @click="$root.curating = true">
          <row-curation-file v-for="(entry, index) in content" :key="index"
            :content="entry" @ok="handleRemove(entry)"/>
          </tbody>
        </table>
      </div>

      <div class="has-pagination" v-else-if="activeTab === 'Challenges'">
        <table class="table table--fill table--hover" style="table-layout: fixed">
          <thead>
          <tr>
            <th width="300px">Title</th>
            <th width="150px">Created by</th>
            <th width="100px">Created at</th>
            <th colspan="4" width="500">Actions</th>
          </tr>
          </thead>
          <tbody @click="$root.curating = true">
          <row-curation-challenge
            v-for="(challenge, index) in challenges"
            :key="index"
            :challenge="challenge"
          />
          </tbody>
        </table>
      </div>

      <div class="has-pagination" v-else-if="activeTab === 'Deleted Actors'">
        <table class="table table--fill table--hover" style="table-layout: fixed">
          <thead>
          <tr>
            <th>Name</th>
            <th>Actor created at</th>
            <th>Claimed</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody @click="$root.curating = true">
          <row-curation v-for="(actor, index) in actors" :key="index" :actor="actor"
            @ok="handleRemove(actor)"/>
          </tbody>
        </table>
      </div>

      <div class="has-pagination" v-else-if="activeTab != 'Announcements'">
        <table class="table table--fill table--hover" style="table-layout: fixed">
          <thead>
          <tr>
            <th>Name</th>
            <th>Actor created at</th>
            <th>Claimed</th>
            <th colspan="3" width="300">Actions</th>
          </tr>
          </thead>
          <tbody @click="$root.curating = true">
          <row-curation v-for="(actor, index) in actors" :key="index" :actor="actor"
            @ok="handleRemove(actor)"/>
          </tbody>
        </table>
      </div>

      <div class="has-pagination" v-else>
        <table class="table table--fill table--hover" style="table-layout: fixed">
          <thead>
          <tr>
            <th width="300px">Title</th>
            <th width="150px">Created by</th>
            <th width="100px">Created at</th>
            <th colspan="4" width="500">Actions</th>
          </tr>
          </thead>
          <tbody @click="$root.curating = true">
          <row-curation-announcement v-for="(announcement, index) in announcements"
            :key="index" :announcement="announcement"/>
          </tbody>
        </table>
      </div>
      <pagination class="simple-pagination--bottom" :paging="paging" @change="setOffset"/>
    </div>
  </with-configuration-tabs>
</template>

<script lang="ts">
  import Checkbox from '../Form/Checkbox.vue'
  import DsInput from '../Form/DsInput.vue'
  import FormGroup from '../Form/FormGroup.vue'
  import Pagination from '../Pagination/SimplePagination.vue'
  import RowCuration from './RowCuration.vue'
  import RowCurationAnnouncement from './RowCurationAnnouncement.vue'
  import RowCurationChallenge from './RowCurationChallenge.vue'
  import RowCurationFile from './RowCurationFile.vue'
  import Tabs from '../Tabs/Tabs.vue'

  import { fetchCuration } from '../../api/curation.js'

  import WithConfigurationTabs from '../../pages/WithConfigurationTabs/WithConfigurationTabs.vue'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    components: {
      Checkbox,
      DsInput,
      FormGroup,
      Pagination,
      RowCuration,
      RowCurationAnnouncement,
      RowCurationChallenge,
      RowCurationFile,
      Tabs,
      WithConfigurationTabs,
    },
    props: ['company'],
    data () {
      return {
        actors: [],
        announcements: [],
        challenges: [],
        content: [],
        paging: null,
        filters: {
          name: null,
          type: 'draft',
          limit: 20,
          offset: 0,
        },
        curationCount: null,
        activeTab: 'New Actors',
        tabNames: {
          newActors: 'New Actors',
          deletedActors: 'Deleted Actors',
          claims: 'Claims',
          announcements: 'Announcements',
          resources: 'Resources',
          challenges: 'Challenges',
        },
      }
    },
    computed: {
      knowledgePanelEnabled () {
        return (this.$store.state.config.viewDashboard || []).includes('knowledge-base')
      },
      tabCounters () {
        if (!this.curationCount) {
          return null
        }
        const result = [this.curationCount.draft, this.curationCount.deleted, this.curationCount.claim, this.curationCount.announcements]
        if (this.knowledgePanelEnabled) {
          result.push(this.curationCount.content)
        }
        if (this.$store.state.config.challengesAreEnabled) {
          result.push(this.curationCount.challenges)
        }
        return result
      },
      tabs () {
        var tabs = [this.tabNames.newActors, this.tabNames.deletedActors, this.tabNames.claims, this.tabNames.announcements]

        if (this.knowledgePanelEnabled) {
          tabs = tabs.concat(this.tabNames.resources)
        }

        if (this.$store.state.config.challengesAreEnabled) {
          tabs = tabs.concat(this.tabNames.challenges)
        }

        return tabs
      },
    },
    methods: {
      fetchCurationCount () {
        fetchCuration(Object.assign({}, { type: 'count-all' }))
          .then(({ data }) => {
            this.curationCount = data
          }, this.fetchError)
      },
      fetch () {
        this.fetchCurationCount()
        let type = 'claim'

        switch (this.activeTab) {
          case this.tabNames.announcements:
            type = 'announcements'
            break
          case this.tabNames.challenges:
            type = 'challenges'
            break
          case this.tabNames.newActors:
            type = 'draft'
            break
          case this.tabNames.deletedActors:
            type = 'deleted'
            break
          case this.tabNames.resources:
            type = 'content'
            break
          default:
            break
        }

        if (type === 'announcements') {
          fetchCuration(Object.assign({}, { type: type }))
            .then(({ data, paging }) => {
              this.announcements = data
              this.paging = paging
            }, this.fetchError)
        } else if (type === 'challenges') {
          fetchCuration(Object.assign({}, { type: type }))
            .then(({ data, paging }) => {
              this.challenges = data
              this.paging = paging
            }, this.fetchError)
        } else if (type == 'content') {
          fetchCuration(Object.assign({}, { type: type }))
            .then(({ data, paging }) => {
              this.content = data
              this.paging = paging
            }, this.fetchError)
        } else {
          fetchCuration(Object.assign({}, this.filters, { type: type }))
            .then(({ data, paging }) => {
              this.actors = data
              this.paging = paging
            }, this.fetchError)
        }
      },
      fetchError (error) {
        console.log('fetched error', error)
      },
      setOffset (offset) {
        this.filters.offset = offset
      },
      handleRemove (actor) {
        if (this.activeTab === this.tabNames.deletedActors || this.activeTab === this.tabNames.newActors) {
          this.actors.splice(this.actors.indexOf(actor), 1)
          if (this.actors.length === 0) {
            this.fetch()
          }
        }
      },
    },
    created () {
      this.$bus.on('tab', (tab) => {
        this.activeTab = tab

        this.actors = []
        this.paging = null

        this.fetch()
      })

      this.$bus.on('editAndAcceptedAnnouncement', () => {
        this.fetch()
      })

      this.$bus.on('acceptedAnnouncement', () => {
        this.fetch()
      })

      this.$bus.on('deniedAnnouncement', () => {
        this.fetch()
      })

      this.$bus.on('challengeUpdated', () => {
        this.fetch()
      })

      this.$bus.on('acceptedChallenge', () => {
        this.fetch()
      })

      this.$bus.on('deniedChallenge', () => {
        this.fetch()
      })

      this.$bus.on('refreshCurationResults', () => {
        setTimeout(() => {
          this.fetch()
        }, 1500)
      })
    },
    beforeUnmount () {
      this.$bus.off('tab')
      this.$bus.off('editAndAcceptedAnnouncement')
      this.$bus.off('acceptedAnnouncement')
      this.$bus.off('deniedAnnouncement')
      this.$bus.off('challengeUpdated')
      this.$bus.off('acceptedChallenge')
      this.$bus.off('deniedChallenge')
      this.$bus.off('refreshCurationResults')
    },
    mixins: [TranslationsMixin],
    watch: {
      filters: {
        deep: true,
        handler (a, b) {
          this.fetch()
        },
      },
    }
  })
</script>

<style lang="scss">
  .curation {
    > .heading {
      padding-bottom: 0;
    }

    .tabs__navigation {
      padding-top: 1em;
      margin-bottom: 0;
    }
  }
</style>
